import * as Yup from 'yup'

export const sellerToBuyerTerminalSchema = Yup.object({
  sellerId: Yup.number()
    .required('Seller is required')
    .typeError('Seller is required'),
  buyerTerminalId: Yup.number()
    .required('Buyer Terminal is required')
    .typeError('Buyer Terminal is required'),
  qty: Yup.number()
    .min(0.01)
    .nullable()
    .transform(value => (value ? Number(value) : null)),
})
