import useBillLinesTable from './useBillLinesTable'

import {
  FlagIcon,
  ReusableTable,
  FilterIcon,
  ConcordToolbar,
  CommonDialogV2,
  ViewPDF,
  DialogHierarchyRowForm,
  ReusableButton,
  DraggableItemsOverlay,
  CogIcon,
  PlusIcon,
  CheckMarkIcon,
  DeleteIcon,
  AddColumnIcon,
  DropdownWithCustomChildren,
  GridIcon,
} from '~/components/shared'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { Alert, Badge, Button, ButtonGroup } from 'react-bootstrap'
import { IonSearchbar } from '@ionic/react'
import { BillLinesActionsButton } from './BillLinesActionsButton'
import { IBillLinesTableProps } from './type'
import BillLineOcrList from '../BillLineOcrList'
import { DialogPricingsTable } from './DialogPricingsTable'
import { LoadExtraTableForm } from '~/components/load/LoadExtra/LoadExtraTableform'
import { BillLinesSortOptions } from './BillLinesSortOptions'

import clsx from 'clsx'
import './styles.scss'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { MatchingBillLinesTable } from './MatchingBillLinesTable'
import { DialogAutoChargesForm } from '~/components/shared/ConcordForm/FormData/AutoChargesForm'
import buildObjectName from '~/utils/buildObjectName'
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { DialogPriceChange } from '~/containers/PricingsContainer/DialogPriceChange'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { produce } from 'immer'
import { BillLineTaxesTableDialog } from './BillLineTaxesTableDialog'

function BillLinesTable(props: IBillLinesTableProps) {
  const {
    className,
    globalFilter,
    billLineIdsSelected,
    flagsData,
    isShowFlags,
    flagsCounter,
    billLinesData,
    invoice,
    columnVisibility,
    columnOrder,
    billLinesActions,
    isShowFilter,
    windowSize,
    filterOptions,
    columns,
    billLinesHavingFlags,
    isTableLoading,
    rowSelection,
    tableState,
    tableProps,
    isShowPdfModal,
    isShowOcrModal,
    isShowMovingBillLines,
    invoicesData,
    isLoadingInvoicesData,
    invoiceRowSelection,
    invoicesColumns,
    invoiceIdSelected,
    areBillLinesMoving,
    canEditCell,
    filterData,
    pricingForm,
    pricingsListModal,
    loadExtrasModal,
    showExtraForm,
    isBillLinesEqualInvoice,
    tableRef,
    sortOptions,
    tableSorting,
    loadDetailsModal,
    grouping,
    totalBillLines,
    unmatchedBillLinesModal,
    autoChargeForm,
    priceChangeModal,
    billLinesCreateModal,
    isCreatingBillLine,
    deletingIds,
    billLineCreateColumns,
    countRawBillLines,
    countOfRawError,
    countCreatedBillLines,
    draftRowSelection,
    countDraftRowSelection,
    isCreatingMultiBillLines,
    headerOptions,
    draftColumnVisibility,
    draftColumnOrder,
    draftBannerError,
    draftBillLineIds,
    billLineTaxesModal,

    onCreateDraftBillLine,
    onCloseUnmatchedBillLinesModal,
    afterMatchingBillLines,
    setGlobalFilter,
    setIsShowMovingBillLines,
    setIsShowFlags,
    setIsShowFilter,
    setIsShowPdfModal,
    setIsShowOcrModal,
    setColumnVisibility,
    setRowSelection,
    setInvoiceRowSelection,
    setShowExtraForm,
    setSortOptions,
    setGrouping,
    renderRowActions,
    renderLoadDetailsSection,
    onDeleteBillLines,
    onCellEditEnd,
    onMoveBillLines,
    onClosePricingForm,
    afterCreatePricing,
    onClosePricings,
    onFilterChange,
    onCloseLoadDetailsModal,
    onCloseAutoChargeForm,
    onCloseLoadExtrasModal,
    refetchQueryBillLines,
    refetchInvoiceData,
    updateBillLine,
    updateBillLines,
    onClosePriceChangeModal,
    refetchPricingsData,
    onCreateBillLine,
    setBillLinesCreateModal,
    getErrorMessages,
    onDraftCellEditEnd,
    onAddDraftColumn,
    setCountRawBillLines,
    setDraftRowSelection,
    onCreateMultiDraftBillLines,
    onDeleteMultiDraftBillLines,
    setDraftColumnVisibility,
    onCloseBillLineTaxesModal,
  } = useBillLinesTable(props)

  return (
    <>
      <div className={clsx('BillLinesTable__container', className)}>
        <div className='BillLinesTable__header'>
          <IonSearchbar
            value={globalFilter}
            onIonInput={event => {
              const target = event.target as HTMLIonSearchbarElement
              setGlobalFilter(target.value || '')
            }}
          />

          {billLineIdsSelected.length > 0 && (
            <ButtonGroup style={{ marginRight: 6 }}>
              <Button
                style={{ minWidth: 105, fontSize: 13 }}
                variant='secondary'
                onClick={() => {
                  setIsShowMovingBillLines(true)
                }}
              >
                Move Bill Lines
              </Button>
              <Button
                variant='danger'
                onClick={onDeleteBillLines}
                style={{ minWidth: 115, fontSize: 13 }}
              >
                Delete Bill Lines
              </Button>
            </ButtonGroup>
          )}
          {flagsData.length > 0 && (
            <Button
              className='flagButton'
              variant={isShowFlags ? 'danger' : 'outline-danger'}
              onClick={() => {
                setIsShowFlags(prev => !prev)
              }}
            >
              <FlagIcon
                size={20}
                color={isShowFlags ? 'white' : 'var(--ion-color-danger)'}
              />
              <span style={{ margin: '0 8px', fontSize: 14 }}>FLAGS</span>
              <Badge bg='danger'>{`${flagsCounter} / ${billLinesData.length}`}</Badge>
            </Button>
          )}

          {!isBillLinesEqualInvoice && (
            <ReusableButton
              className='flagButton'
              variant='danger'
              onClick={() => {
                setIsShowFlags(prev => !prev)
              }}
              tooltipContent={
                <div>
                  <div>
                    The total of the bill lines does not match the invoice total
                  </div>
                  <div>
                    Bill lines total:{' '}
                    {formatCurrencyToDollar.format(totalBillLines || 0)}
                  </div>
                  <div>
                    Invoice total:{' '}
                    {formatCurrencyToDollar.format(Number(invoice?.total || 0))}
                  </div>

                  <div>
                    Difference:{' '}
                    {formatCurrencyToDollar.format(
                      Math.abs(
                        Number(invoice?.total || 0) -
                          Number(totalBillLines || 0),
                      ),
                    )}
                  </div>
                </div>
              }
            >
              <FlagIcon size={20} color='white' />
            </ReusableButton>
          )}

          <ButtonGroup>
            <Button
              variant='secondary'
              onClick={() => {
                setIsShowFilter(prev => !prev)
              }}
            >
              <FilterIcon color='white' />
            </Button>
            <BillLinesActionsButton items={billLinesActions} />

            <DraggableItemsOverlay
              items={columnVisibility}
              setItems={setColumnVisibility}
            >
              <CogIcon color='white' />
            </DraggableItemsOverlay>

            <BillLinesSortOptions
              sortOptions={sortOptions}
              onSortChange={setSortOptions}
            />
          </ButtonGroup>
        </div>

        {isShowFilter && (
          <div style={{ margin: '8px 4px' }}>
            <ConcordToolbar
              isHiddenSearchBar
              filterSectionWidth={windowSize.width - 236}
              filterOptions={filterOptions}
              filterData={filterData}
              onFilterChange={onFilterChange}
            />
          </div>
        )}

        <ReusableTable
          columns={columns}
          data={isShowFlags ? billLinesHavingFlags : billLinesData}
          tableHeight={windowSize.height - 190}
          enableRowSelection={canEditCell}
          enableRowActions
          enableColumnPinning
          enableGrouping
          // manualGrouping
          defaultValuesOnDraftRow={{
            buyerTerminalId: invoice?.buyerTerminalId as number,
          }}
          enableExpanding
          ref={tableRef}
          getRowClassName={({ row }) => {
            const flags = flagsData.filter(
              ({ flagableId }) => flagableId === row.original.id,
            )
            return clsx({ hasFlags: flags.length > 0 })
          }}
          state={{
            isLoading: isTableLoading,
            columnVisibility,
            columnOrder,
            globalFilter,
            rowSelection,
            sorting: tableSorting,
            grouping,
            isSaving: isCreatingBillLine,
            ..._.omit(tableState, ['isLoading']),
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.select, ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 135,
              Footer: ({ table }) => `${table.options.data.length} Bill lines`,
              enableGrouping: false,
              Header: ({ table }) => (
                <ReusableButton
                  tooltipContent='Create Bill line'
                  onClick={() => {
                    table.setCreatingRow(true)
                  }}
                >
                  <PlusIcon color='white' />
                </ReusableButton>
              ),
            },
            [ERTDisplayColumnId.select]: {
              size: 30,
              enableGrouping: false,
            },
          }}
          renderRowActions={renderRowActions}
          onRowSelectionChange={setRowSelection}
          onCellEditEnd={onCellEditEnd}
          onSortingChange={callback => {
            const sorting =
              typeof callback === 'function' ? callback(tableSorting) : callback
            const sortMap = new Map(
              sorting.map(({ id, desc }, index) => [
                id,
                { index, isAsc: !desc },
              ]),
            )
            const newSorting = [...sortOptions]
              .map(item => ({
                ...item,
                isAsc: sortMap.has(item.sortField)
                  ? sortMap.get(item.sortField)?.isAsc
                  : item.isAsc,
                sorted: sortMap.has(item.sortField) ? true : false,
              }))
              .sort((a, b) => {
                const aIndex = sortMap.has(a.sortField)
                  ? (sortMap.get(a.sortField)?.index as any)
                  : Infinity
                const bIndex = sortMap.has(b.sortField)
                  ? (sortMap.get(b.sortField)?.index as any)
                  : Infinity
                return aIndex - bIndex
              })
            setSortOptions(newSorting as any)
          }}
          onGroupingChange={callback => {
            const data =
              typeof callback === 'function' ? callback(grouping) : callback
            setGrouping(data)
          }}
          onCreatingRowSave={onCreateBillLine}
          // onColumnVisibilityChange={setColumnVisibility}
          {...tableProps}
        />
      </div>
      <CommonDialogV2
        isOpen={isShowPdfModal}
        size='lg'
        isHiddenHeader
        onClose={() => {
          setIsShowPdfModal(false)
        }}
      >
        <ViewPDF link={invoice?.proveUrl} />
      </CommonDialogV2>

      <CommonDialogV2
        isOpen={isShowOcrModal}
        isHiddenHeader
        onClose={() => {
          setIsShowOcrModal(false)
        }}
      >
        <BillLineOcrList ocrData={invoice?.ocrJson} />
      </CommonDialogV2>

      <CommonDialogV2
        isOpen={isShowMovingBillLines}
        isHiddenHeader
        size='lg'
        onClose={() => {
          setIsShowMovingBillLines(false)
        }}
      >
        <ReusableTable
          enableRowSelection
          enableMultiRowSelection={false}
          data={invoicesData}
          state={{
            isLoading: isLoadingInvoicesData,
            rowSelection: invoiceRowSelection,
          }}
          initialState={{
            sorting: [
              {
                id: 'num',
                desc: true,
              },
            ],
          }}
          columns={invoicesColumns}
          onRowSelectionChange={setInvoiceRowSelection}
        />
        {typeof invoiceIdSelected === 'number' && (
          <Button
            style={{ width: '100%', marginTop: 8, fontSize: 13, padding: 8 }}
            onClick={onMoveBillLines}
            disabled={areBillLinesMoving}
          >
            Confirm Moving Bill Lines To Selected Invoice
          </Button>
        )}
      </CommonDialogV2>

      <CommonDialogV2
        title={`Load # ${buildObjectName({
          code: loadExtrasModal.billLine?.ticketNum,
          name: loadExtrasModal.billLine?.loadId,
        })} Extras`}
        isOpen={loadExtrasModal.isOpen}
        onClose={onCloseLoadExtrasModal}
        isHiddenOkButton
      >
        {loadExtrasModal.billLine && (
          // onUpdateLoadExtra={onUpdateLoadExtra} if websocket update, lift up usequerystate
          // onCreateLoadExtra={onCreateLoadExtra}
          <LoadExtraTableForm
            buyerId={invoice.buyerId}
            sellerId={invoice.sellerId}
            loadId={loadExtrasModal.billLine.loadId}
            loadQty={loadExtrasModal.billLine.qty}
            existingIds={[loadExtrasModal.billLine.loadExtraId as number]}
            loadSellerProductId={
              loadExtrasModal.billLine.sellerProductId ?? undefined
            }
            showExtraForm={showExtraForm}
            setShowExtraForm={setShowExtraForm}
            shouldRunBillLine
            afterCreate={async extra => {
              refetchInvoiceData({ fetching: true })
              const response = await apiClient.billLines.get({
                filters: {
                  invoiceId: invoice.id,
                  loadExtraId: [extra.id],
                },
              })
              updateBillLines(response.billLines)
            }}
            afterUpdate={async extra => {
              refetchInvoiceData({ fetching: true })
              const response = await apiClient.billLines.get({
                filters: {
                  invoiceId: invoice.id,
                  loadExtraId: [extra.id],
                },
              })
              updateBillLines(response.billLines)
            }}
            afterDelete={async () => {
              const response = await apiClient.billLines.getById(
                loadExtrasModal.billLine?.id as number,
              )
              updateBillLine(response.id, response)
              refetchInvoiceData({ fetching: true })
              refetchQueryBillLines({ fetching: true })
            }}
          />
        )}
      </CommonDialogV2>

      <DialogHierarchyRowForm
        isOpen={pricingForm.isOpen}
        formData={pricingForm.formData}
        billLineData={pricingForm.billLineData}
        onClose={onClosePricingForm}
        afterCreate={afterCreatePricing}
      />
      <DialogPricingsTable
        isOpen={pricingsListModal.isOpen}
        billLine={pricingsListModal.billLine}
        invoice={invoice}
        onClose={onClosePricings}
        includeInvoiceId
        afterCreate={() => {
          refetchQueryBillLines()
          refetchInvoiceData()
        }}
        afterUpdate={() => {
          refetchQueryBillLines()
          refetchInvoiceData()
        }}
      />
      <CommonDialogV2
        isOpen={loadDetailsModal.isOpen}
        isHiddenOkButton
        onClose={onCloseLoadDetailsModal}
        size='lg'
      >
        {renderLoadDetailsSection()}
      </CommonDialogV2>

      <CommonDialogV2
        size='xl'
        isOpen={unmatchedBillLinesModal.isOpen}
        onClose={onCloseUnmatchedBillLinesModal}
        isHiddenOkButton
      >
        {unmatchedBillLinesModal.billLine && (
          <MatchingBillLinesTable
            invoice={invoice}
            billLine={unmatchedBillLinesModal.billLine}
            afterMatchingBillLines={afterMatchingBillLines}
          />
        )}
      </CommonDialogV2>

      <DialogAutoChargesForm
        isOpen={autoChargeForm.isOpen}
        formData={autoChargeForm.formData}
        onClose={onCloseAutoChargeForm}
        afterUpdate={() => {
          onCloseAutoChargeForm()
        }}
      />

      <DialogPriceChange
        isOpen={priceChangeModal.isOpen}
        formData={priceChangeModal.formData}
        onClose={onClosePriceChangeModal}
        pricing={priceChangeModal.pricing}
        useLocalHierarchiesData
        afterPriceChanges={async () => {
          refetchPricingsData()
          onClosePriceChangeModal()
          const billLinesHavingSameProduct = billLinesData.filter(
            ({ sellerProductId }) =>
              sellerProductId === priceChangeModal.pricing?.sellerProductId,
          )
          if (billLinesHavingSameProduct.length > 0) {
            refetchPricingsData()
            const { billLines } = await apiClient.billLines.multipleUpdate({
              billLine: {
                runInline: true,
              },
              ids: billLinesHavingSameProduct.map(({ id }) => id),
            })

            billLines.forEach(billLine => {
              updateBillLine(billLine.id, billLine)
            })
          }
        }}
      />

      <CommonDialogV2
        isOpen={billLinesCreateModal.isOpen}
        onClose={() =>
          setBillLinesCreateModal({ isOpen: false, data: [], columns: [] })
        }
        isHiddenOkButton
        title={
          <div>
            <span>Bill Lines</span>
            <Badge bg='secondary' style={{ marginRight: 4 }}>
              Raw Data: {countRawBillLines}
            </Badge>
            <Badge bg='success' style={{ marginRight: 4 }}>
              Created: {countCreatedBillLines}
            </Badge>
            <Badge bg='danger' style={{ marginRight: 4 }}>
              Errors: {countOfRawError}
            </Badge>

            {countDraftRowSelection ? (
              <>
                <ReusableButton
                  style={{ marginRight: 4, fontSize: 13 }}
                  onClick={onCreateMultiDraftBillLines}
                  isLoading={isCreatingMultiBillLines}
                >
                  Create {countDraftRowSelection} Bill Lines
                </ReusableButton>

                <ReusableButton
                  style={{ marginRight: 4, fontSize: 13 }}
                  variant='danger'
                  onClick={onDeleteMultiDraftBillLines}
                >
                  Delete {countDraftRowSelection} Bill Lines
                </ReusableButton>
              </>
            ) : null}

            <DropdownWithCustomChildren
              options={headerOptions}
              onChange={onAddDraftColumn}
              className='make-custom-dropdown-inline no-hover'
            >
              <ReusableButton
                tooltipContent='Add Column'
                tooltipPlacement='right'
              >
                <AddColumnIcon color='white' />
              </ReusableButton>
            </DropdownWithCustomChildren>

            <DraggableItemsOverlay
              items={draftColumnVisibility}
              setItems={setDraftColumnVisibility}
              style={{ marginLeft: 4 }}
              disabledFields={[
                ERTDisplayColumnId.select,
                ERTDisplayColumnId.actions,
              ]}
            >
              <GridIcon color='white' />
            </DraggableItemsOverlay>
          </div>
        }
        fullscreen
      >
        {draftBannerError && (
          <Alert variant='danger' style={{ fontSize: 13 }}>
            {draftBannerError}
          </Alert>
        )}

        <ReusableTable
          data={billLinesCreateModal.data}
          columns={billLineCreateColumns}
          enableRowActions
          enableSorting={false}
          enableRowSelection
          tableHeight={windowSize.height - 70}
          getRowId={row => row.id + ''}
          getRowClassName={({ row }) => {
            const rowError = getErrorMessages(row.original.id)
            return clsx({ ReusableTable__rowError: rowError.length > 0 })
          }}
          state={{
            columnOrder: draftColumnOrder,
            rowSelection: draftRowSelection,
            columnVisibility: draftColumnVisibility,
          }}
          onRowSelectionChange={setDraftRowSelection}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 140,
              // Header() {
              //   return (
              //     <ReusableButton onClick={onAddDraftColumn}>
              //       <PlusIcon color='white' />
              //     </ReusableButton>
              //   )
              // },
            },
          }}
          renderRowActions={({ row }) => {
            const rowError = getErrorMessages(row.original.id)
            const originalIndex = draftBillLineIds.indexOf(row.original.id)
            return [
              {
                icon: <CheckMarkIcon color='white' />,
                onClick: onCreateDraftBillLine(row.original),
                color: 'success',
                isLoading: deletingIds.includes(row.original.id),
                isDisabled: rowError.length > 0,
              },
              {
                render() {
                  return (
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Popover
                          id='popover-basic'
                          style={{
                            maxWidth: 500,
                            fontSize: 12,
                          }}
                        >
                          <Popover.Header as='h3'>{`${rowError.length} flags`}</Popover.Header>
                          <Popover.Body
                            style={{
                              maxWidth: 500,
                              fontSize: 12,
                            }}
                          >
                            {
                              <div>
                                {rowError.map((error, index) => (
                                  <div key={index}>
                                    <p
                                      style={{
                                        color: '#d33939',
                                        display: 'inline-block',
                                        fontSize: '15px',
                                        marginBottom: 1,
                                        maxWidth: 490,
                                      }}
                                    >
                                      <strong>{index + 1}: </strong>
                                      {error}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            }
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <ReusableButton variant='danger'>
                        <FlagIcon color='white' />
                      </ReusableButton>
                    </OverlayTrigger>
                  )
                },
                isHidden: rowError.length === 0,
              },
              {
                icon: <DeleteIcon color='white' />,
                color: 'danger',
                onClick() {
                  const newData = billLinesCreateModal.data.filter(
                    ({ id }: any) => id !== row.original.id,
                  )
                  setCountRawBillLines(prev => prev - 1)
                  setBillLinesCreateModal({
                    ...billLinesCreateModal,
                    data: newData,
                  })
                  setDraftRowSelection(prev =>
                    produce(prev, draft => {
                      if (draft[row.original.id]) {
                        delete draft[row.original.id]
                      }
                    }),
                  )
                },
              },
              {
                render() {
                  return (
                    <ReusableButton className='ml-2' variant='dark'>
                      #{originalIndex + 1}
                    </ReusableButton>
                  )
                },
              },
            ]
          }}
          onCellEditEnd={onDraftCellEditEnd}
        />
      </CommonDialogV2>

      <BillLineTaxesTableDialog
        isOpen={billLineTaxesModal.isOpen}
        billLineId={billLineTaxesModal.billLineId as number}
        invoiceId={invoice?.id}
        onClose={onCloseBillLineTaxesModal}
      />
    </>
  )
}

export default BillLinesTable
