import { useEffect, useMemo, useState } from 'react'
import {
  eyeOffOutline,
  eyeOutline,
  listOutline,
  readerOutline,
} from 'ionicons/icons'

import { CommonDialogV2, CompanyInfo } from '~/components/shared'
import SellerProductForm from './SellerProductForm'
import type { IDialogSellerProductFormProps } from './type'

import './styles.scss'
import { EScope } from '~/types/enums/ECommonEnum'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { KeyValuePairItem } from './KeyValuePairItem'
import { apiClient } from '~/api/ApiClient'
import clsx from 'clsx'

function DialogSellerProductForm(props: IDialogSellerProductFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    afterCreateMixDetail,
    afterUpdateMixDetail,
    afterUpdateProductInCostTab,
    shouldHideOptionalFields,
    isOpen,
    mixDetailData,
    defaultValues,
    defaultTab,
    ...modalProps
  } = props
  const [isHideOptionalFields, setIsHideOptionalFields] = useState(
    shouldHideOptionalFields || false,
  )
  const [isShowFormFields, setIsShowFormFields] = useState(false)
  const [modalIsExpanded, setModalIsExpanded] = useState(false)

  const { allCompaniesWithCurrentCompany } = useQueryBuyerSellers()

  const hideOptionalTooltip = useMemo(
    () =>
      isHideOptionalFields ? 'Show optional fields' : 'Hide optional fields',
    [isHideOptionalFields],
  )
  useEffect(() => {
    shouldHideOptionalFields &&
      setIsHideOptionalFields(shouldHideOptionalFields ? true : false)
  }, [shouldHideOptionalFields])

  const extraButtons = useMemo(
    () => [
      {
        icon: isShowFormFields ? listOutline : readerOutline,
        onClick: () => setIsShowFormFields(prev => !prev),
        isHidden: !formData,
      },
      {
        icon: isHideOptionalFields ? eyeOutline : eyeOffOutline,
        tooltipProps: {
          content: hideOptionalTooltip,
          placement: 'top',
        },
        onClick: () => setIsHideOptionalFields(!isHideOptionalFields),
      },
    ],
    [formData, hideOptionalTooltip, isHideOptionalFields, isShowFormFields],
  )

  const renderSeller = () => {
    if (formData?.id) {
      const seller = allCompaniesWithCurrentCompany.find(
        ({ id }) => formData.sellerId === id,
      )

      return (
        <div className='DialogSellerProductForm__headerContainer'>
          <CompanyInfo
            company={{
              label: `${seller?.code} - ${seller?.name}`,
              name: seller?.name,
              value: seller?.id,
              image: seller?.logo,
              code: seller?.code,
            }}
            hideAnchor
            companyType={EScope.seller}
          />

          <div style={{ marginLeft: 8 }}>
            <KeyValuePairItem
              label='Code'
              value={formData.code}
              name='code'
              onUpdate={async (name, value) => {
                const response = await apiClient.sellerProducts.update(
                  formData.id as number,
                  {
                    [name]: value,
                  } as any,
                )
                afterUpdate &&
                  afterUpdate({ ...formData, ...response } as any, true)
              }}
              width={200}
            />
            <KeyValuePairItem
              label='Name'
              value={formData.name}
              name='name'
              width={200}
              onUpdate={async (name, value) => {
                const response = await apiClient.sellerProducts.update(
                  formData.id as number,
                  {
                    [name]: value,
                  } as any,
                )
                afterUpdate &&
                  afterUpdate({ ...formData, ...response } as any, true)
              }}
            />
          </div>
        </div>
      )
    }

    return null
  }

  // useEffect(() => {
  //   if (isOpen) {
  //     setIsShowFormFields(!formData?.id)
  //   } else {
  //     setIsShowFormFields(false)
  //   }
  // }, [isOpen, formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      isOpen={isOpen}
      title={
        <div>{formData?.id ? renderSeller() : <span>Seller Product</span>}</div>
      }
      isHiddenOkButton
      extraButtons={extraButtons}
      className={clsx('DialogSellerProductForm__container', {
        modalIsExpanded: modalIsExpanded,
      })}
      fullscreen={modalIsExpanded}
    >
      <SellerProductForm
        formData={formData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        afterCreateMixDetail={afterCreateMixDetail}
        afterUpdateMixDetail={afterUpdateMixDetail}
        afterUpdateProductInCostTab={afterUpdateProductInCostTab}
        isHideOptionalFields={isHideOptionalFields}
        isShowFormFields={isShowFormFields}
        mixDetailData={mixDetailData}
        defaultValues={defaultValues}
        defaultTab={defaultTab}
        modalIsExpanded={modalIsExpanded}
        setModalIsExpanded={setModalIsExpanded}
      />
    </CommonDialogV2>
  )
}

export default DialogSellerProductForm
