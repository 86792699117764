import React from 'react'
import { When } from 'react-if'
import { Modal } from 'react-bootstrap'

import type { IDriverCardProps } from './type'

import useDriverCard from './useDriverCard'
import {
  CloseIcon,
  DialogOffDayForm,
  ToolTipOverlay,
} from '~/components/shared'
import { TerminalForm } from '~/components/onboarding'
import getColor from '~/utils/getColor'

import clsx from 'clsx'
import './styles.scss'

const DriverCard = React.forwardRef<HTMLDivElement, IDriverCardProps>(
  (props: IDriverCardProps, ref) => {
    const { renderExtraElements } = props
    const {
      driverFleet,
      createTerminalDialogState,
      onCloseTerminalDialog,
      dragRef,
      onClickCloseIcon,
      isDraggable,
      isCloseable,
      className,
      measureRef,
      hasOffday,
      index,
      indexBadgeStyle,
      extraIndexTooltipContent,
      startTime,
      offdayForm,
      onCloseOffdayForm,
      afterUpdateOffday,
      afterCreateOffday,
      style,
      renderDriverData,
    } = useDriverCard(props)

    return (
      <>
        <div
          className={clsx('DriverCard__container', className, {
            isDraggable,
            isDisabled: hasOffday,
          })}
          ref={node => {
            dragRef(node)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref && (ref as any)(node)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            measureRef(node as any)
          }}
          style={{
            borderColor: startTime?.color
              ? getColor(startTime.color)
              : '#e5e5e5',
            borderWidth: startTime ? 2 : 1,
            borderStyle: 'solid',
            ...style,
          }}
        >
          <When condition={typeof index === 'number' && driverFleet?.rank}>
            <ToolTipOverlay
              content={
                <div>
                  <div>Driver Rank: {driverFleet?.rank || '-'}</div>
                  {extraIndexTooltipContent}
                </div>
              }
              placement='right'
            >
              <div className='indexBadge' style={indexBadgeStyle}>
                {driverFleet?.rank}
              </div>
            </ToolTipOverlay>
          </When>
          {isCloseable && (
            <span className='closeIcon' onClick={onClickCloseIcon}>
              <CloseIcon color='var(--ion-color-danger)' />
            </span>
          )}
          {renderDriverData}
          {renderExtraElements && renderExtraElements()}
        </div>

        <Modal
          show={createTerminalDialogState.isOpen}
          onHide={onCloseTerminalDialog}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <TerminalForm currentRowData={createTerminalDialogState.terminal} />
          </Modal.Body>
        </Modal>
        {afterUpdateOffday && afterCreateOffday ? (
          <DialogOffDayForm
            isOpen={offdayForm.isOpen}
            defaultValues={offdayForm.formData}
            onClose={onCloseOffdayForm}
            offdaysData={[]}
            afterUpdate={offday => {
              afterUpdateOffday(offday)
              onCloseOffdayForm()
            }}
            afterCreate={offday => {
              afterCreateOffday(offday)
              onCloseOffdayForm()
            }}
          />
        ) : null}
      </>
    )
  },
)

export default DriverCard
