import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const FolderOpenIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        d='M0 1h5l3 2h5v2H3.746L2.03 11h2.08l1.143-4H16l-2 7H0V1Z'
      />
    </svg>
  )
}

export default FolderOpenIcon
