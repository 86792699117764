import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const LeafIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = 'black', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        d='M12 1C6.477 1 2 5.03 2 10v6h2v-5.414l4.293-4.293 1.414 1.414L6 11.414v2.542c5.053-.452 9-4.29 9-8.956V1h-3Z'
      />
    </svg>
  )
}
export default LeafIcon
