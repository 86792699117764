import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'

import {
  IBTSPGetListParams,
  IBuyerTerminalSellerProduct,
} from '~/types/models/IBuyerTerminalSellerProduct'

const useModifyBTSPs = (params: IBTSPGetListParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'buyerTerminalSellerProducts',
      sessionUser?.id,
      buildGetUrl(apiClient.buyerTerminalSellerProducts.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    [params, sessionUser?.id],
  )

  const addBtsp = useCallback(
    (data: IBuyerTerminalSellerProduct) => {
      queryClient.setQueryData<IBuyerTerminalSellerProduct[]>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateBtsp = useCallback(
    (id: number, data: IBuyerTerminalSellerProduct) => {
      queryClient.setQueryData<IBuyerTerminalSellerProduct[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(g => g.id === id)
              draft[index] = data
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeBtsp = useCallback(
    (id: number) => {
      queryClient.setQueryData<IBuyerTerminalSellerProduct[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )
  return {
    addBtsp,
    updateBtsp,
    removeBtsp,
  }
}

export default useModifyBTSPs
