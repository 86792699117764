import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Alert, Container, Form, Row } from 'react-bootstrap'
import { IonButton, IonButtons, IonIcon } from '@ionic/react'
import { useSelector } from 'react-redux'

import {
  useQueryEmails,
  useQueryPhoneNumbers,
  useQuerySchedule,
  useQueryUoms,
  useQueryCompanies,
  useQueryTerminals,
  useQueryUsers,
  useQueryCompanyAddresses,
  useQueryTruckFleets,
  useCustomQueryDataOfDriver,
  useQueryMixDetails,
  useQueryEvents,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'

import {
  selectMyCurrentCompany,
  selectDriverDetails,
  selectCurrentScope,
  selectSessionUser,
} from '~/redux/selectors'
import { toast } from 'react-toastify'
import {
  CheckMarkIcon,
  CloseIcon,
  ConcordFormTextField,
  DialogSellerProductForm,
  SensorsList,
  SignatureModal,
  WeightsList,
} from '~/components/shared'
import { OrderFormDialog } from '~/components/shared/SchedulesTable/OrderFormDialog'
import clsx from 'clsx'
import { isToday, isSameYear } from '~/utils/getDay'
import { apiClient } from '~/api/ApiClient'
import buildFullName from '~/utils/buildFullName'
import moment from 'moment'
import { EPhoneableType, EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import { EEmailTypes } from '~/types/enums/EEmail'
import { EAddressType, EAddressableType } from '~/types/enums/EAddress'
import { ENoteableType } from '~/types/enums/ENote'
import NoteDropdown from '../shared/NoteButton/NoteDropdown'
import NoteEditDropdown from '../shared/NoteButton/NoteEditDropdown'
import NoteConfirmDelete from '../shared/NoteButton/NoteConfirmDelete'
import { getInitName } from '~/utils'
import { When } from 'react-if'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { addCircleOutline } from 'ionicons/icons'
import { QRCode } from 'react-qrcode-logo'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import getFilteredData from '~/utils/getFilteredData'
import buildFullAddress from '~/utils/buildFullAddress'
import { useQueryLoadTimes } from '~/hooks/useQueryData/useQueryLoadTimes'
import EditableTimeCell from './EditableTimeCell'
import { LoadExtraTableForm } from '../load/LoadExtra/LoadExtraTableform'
import { EScope, EYesNo } from '~/types/enums/ECommonEnum'
import NumericFormat from 'react-number-format'

import './LoadPrint.scss'
import { toastMessages } from '~/constants/toast-status-text'
import Skeleton from 'react-loading-skeleton'
import { EEventCategory, EEventSourceType } from '~/types/enums/EEvent'
import useGeolocation from '~/hooks/useGeolocation'
import { useConfirmationProvider } from '~/contexts'

function isDateInLastMinute(isoDate) {
  if (isoDate) {
    const givenDate = moment(isoDate)
    const now = moment()

    return now.diff(givenDate, 'seconds') <= 60 && givenDate.isBefore(now)
  }
  return false
}

const LoadPrint = props => {
  const {
    load,
    onClose,
    canEdit,
    afterUpdateload,
    showLineGraph,
    setShowLineGraph,
  } = props

  const [isShowSignatureModal, setIsShowSignatureModal] = useState(false)
  const [signalName, setSignalName] = useState('')
  const [signature, setSignature] = useState('')
  const [isUpdatingSignatureName, setIsUpdatingSignatureName] = useState(false)
  const [notes, setNotes] = useState([])
  const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
  const [defaultTab, setDefaultTab] = useState(undefined)
  const [hasImageError, setHasImageError] = useState(false)
  const [showExtraForm, setShowExtraForm] = useState(false)
  const [isUpdatingQty, setIsUpdatingQty] = useState(false)
  const [loadQty, setLoadQty] = useState(0)
  const [backendError, setBackendError] = useState('')
  const [loadingLoadQty, setLoadingLoadQty] = useState(false)
  const [sellerProdForm, setSellerProdForm] = useState({
    isOpen: false,
    formData: undefined,
  })

  const currentCompany = useSelector(selectMyCurrentCompany)
  const driverDetails = useSelector(selectDriverDetails)
  const currentScope = useSelector(selectCurrentScope)
  const currentUser = useSelector(selectSessionUser)

  const { eventsData, addEvent, updateEvent } = useQueryEvents(
    {
      filters: {
        sourceType: EEventSourceType.Load,
        sourceId: load?.id,
      },
    },
    { enabled: Boolean(load?.id) },
  )

  const buyerSignature = useMemo(
    () =>
      eventsData.find(
        ({ category }) => category === EEventCategory.buyerSignature,
      ),
    [eventsData],
  )

  const { findTruckFleetById } = useQueryTruckFleets()
  const { locationAttributes } = useGeolocation()

  const { userInfo } = useCustomQueryDataOfDriver()
  const { findUserByDriverFleetId } = useQueryUsers(
    {},
    {
      enabled: currentScope !== EScope.driverFleet,
    },
  )

  const isLoadCreatedInLastMinute = isDateInLastMinute(load?.createdAt)

  const isMobile = useIsMobile()

  const styles = useMemo(
    () => ({
      printLoadRow: {
        marginLeft: '10px !important',
        marginRight: '10px !important',
        marginBottom: '10px !important',
        fontSize: '15px',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
      },
      table: {
        border: '1px solid #e5e5e5',
        width: '100%',
      },
      tableHeader: {
        borderBottom: '1px solid #e5e5e5',
      },
      tableRow: {
        borderBottom: '1px solid #e5e5e5',
        // fontFamily: 'Arial, Helvetica, sans-serif'
      },
      tableRowNote: {
        borderBottom: '1px solid #e5e5e5',
      },
      tableCell: {
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
      },
      tableCellValue: {
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
        paddingTop: 2,
        paddingBottom: 2,
      },
      tableNoteCell: {
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      tableCellHeader: {
        borderBottom: '1px solid #e5e5e5',
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '120px',
        border: '1px solid grey',
        borderRadius: '8px',
        padding: '12px',
      },
      cardColumn: {
        width: '50%',
      },
      header: {
        marginBottom: 8,
        display: 'flex',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: isMobile ? 'flex-start ' : 'space-between',
        flexDirection: isMobile ? 'column' : 'row',
      },
      companyInfo: {
        display: 'flex',
        alignItems: 'center',
      },
      companyAvatar: {
        width: 60,
        height: 60,
      },
      companyNameCode: {
        fontSize: 16,
        marginLeft: 8,
      },
      companyName: {
        fontWeight: 'bold',
      },
      companyCode: {
        fontSize: 14,
      },
      tableExtraHeader: {
        width: '100%',
        fontSize: 14,
        fontWeight: '600',
        paddingLeft: 8,
        fontStyle: 'italic',
        borderBottom: '1px solid #e5e5e5',
      },
      signatureContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        marginBottom: 16,
      },
      signatureBox: {
        minWidth: 200,
        minHeight: 125,
        cursor: 'pointer',
        marginTop: 4,
      },
      actionNote: {},
      noteAvatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      noteAvatarInitName: {
        width: 25,
        height: 25,
        background: '#2dd36f',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 4,
        color: 'white',
      },
      noteAvatarFullname: {
        color: '#0008',
        fontSize: 13,
      },
    }),
    [isMobile],
  )

  const user = useMemo(() => {
    if (currentScope === EScope.driverFleet) {
      return userInfo
    }
    return findUserByDriverFleetId(load.driverFleetId)
  }, [currentScope, findUserByDriverFleetId, load.driverFleetId, userInfo])

  const truckFleet = useMemo(
    () => findTruckFleetById(load.truckFleetId),
    [findTruckFleetById, load.truckFleetId],
  )

  const truck = truckFleet?.truck

  const { currentUom } = useQueryUoms()

  const { getTimeFormat, getDateTimeFormat, getTimeFormatType } =
    useDateTimeFormat()

  const { confirmation } = useConfirmationProvider()

  const { loadTimesMapped, refetchLoadTimesData, removeLoadTime } =
    useQueryLoadTimes({
      filters: {
        loadId: load?.id,
      },
    })

  const ticketTime = useMemo(
    () => load.ticketTime || load.createdAt,
    [load.createdAt, load.ticketTime],
  )

  const printRef = React.createRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: false,
    documentTitle: 'Ticket',
  })

  const { scheduleData, refetchScheduleData } = useQuerySchedule(
    load?.scheduleId,
  )

  const buyerId = load.buyerId || scheduleData?.order?.buyerId
  const sellerId = load.sellerId || scheduleData?.order?.sellerId
  const buyerTerminalId =
    load.buyerTerminalId || scheduleData?.order?.buyerTerminalId
  const sellerTerminalId =
    load.sellerTerminalId || scheduleData?.sellerTerminalId

  const { findCompanyById } = useQueryCompanies({})
  const { findTerminalById } = useQueryTerminals()
  const { emailsData } = useQueryEmails()
  const { phoneNumbersData } = useQueryPhoneNumbers()
  const { addressesData } = useQueryCompanyAddresses()
  const { findUserByUserAccessId } = useQueryUsers()
  const buyerCompany = findCompanyById(buyerId)
  const sellerCompany = findCompanyById(sellerId)
  const buyerTerminal = findTerminalById(buyerTerminalId)
  const sellerTerminal = findTerminalById(sellerTerminalId)

  const { findSellerProductById, updateSellerProduct } = useQuerySellerProducts()

  const sellerProduct = findSellerProductById(load.sellerProductId)

  const {
    mixDetailsData: [mixDetailData],
    isLoadingMixDetails,
  } = useQueryMixDetails(
    {
      filters: {
        sellerProductId: load.sellerProductId,
      },
    },
    { enabled: Boolean(load.sellerProductId) },
  )

  const operationsOrGeneralEmail = getFilteredData(emailsData, {
    emailTypes: [EEmailTypes.operations, EEmailTypes.general],
    emailableId: currentCompany.id,
  })[0]

  const operationsOrGeneralPhoneNumber = getFilteredData(phoneNumbersData, {
    phoneTypes: [EPhoneNumberTypes.operations, EPhoneNumberTypes.general],
    phoneableId: currentCompany.id,
    phoneableType: EPhoneableType.company,
  })[0]

  const officeOrMailingAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.office, EAddressType.mailing],
    addressableId: currentCompany.id,
    addressableType: EAddressableType.company,
  })[0]

  const buyerAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.office, EAddressType.mailing],
    addressableId: load.buyerId,
    addressableType: EAddressableType.company,
  })[0]

  const sellerAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.office, EAddressType.mailing],
    addressableId: load.sellerId,
    addressableType: EAddressableType.company,
  })[0]

  const buyerTerminalAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.location],
    addressableId: load.buyerTerminalId,
    addressableType: EAddressableType.terminal,
  })[0]

  const sellerTerminalAddress = getFilteredData(addressesData, {
    addressTypes: [EAddressType.location],
    addressableId: load.sellerTerminalId,
    addressableType: EAddressableType.terminal,
  })[0]

  const renderNoteUserAccess = useCallback(
    userAccessId => {
      const user = findUserByUserAccessId(userAccessId)
      const fullName = buildFullName(user?.person)
      const initialName = getInitName(fullName)

      return (
        <div style={styles.noteAvatarContainer}>
          <div style={styles.noteAvatarInitName}>{initialName}</div>
          <When condition={fullName !== 'Unknown'}>
            <div style={styles.noteAvatarFullname}>{fullName}</div>
          </When>
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findUserByUserAccessId],
  )

  const onDeletLoadTime = async (loadTime, isLastOne) => {
    const result = await confirmation({
      message: 'Are you sure you want to delete this load status',
    })
    if (result === EYesNo.Yes) {
      const payload = isLastOne ? { revertStatus: true } : undefined
      apiClient.loadTimes.delete(loadTime.id, payload)
      removeLoadTime(loadTime.id)
    }
  }

  const createOrUpdateBuyerSignature = async signature => {
    if (buyerSignature) {
      const response = await apiClient.events.update(buyerSignature.id, {
        image: signature,
        fileType: 'base64image',
      })
      updateEvent(response.id, response)
    } else {
      const payload = {
        event: {
          type: 'LoadEvent',
          category: EEventCategory.buyerSignature,
          timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          userAccessId: currentUser.userAccess.id,
          sourceType: 'Load',
          sourceId: load.id,
          locationAttributes,
        },
        image: signature,
        fileType: 'base64image',
      }
      const response = await apiClient.events.create(payload)
      addEvent(response)
    }
  }

  const onOpenSellerProdForm = sellerProductId => event => {
    event.preventDefault()
    if (sellerProductId) {
      const sellerProd = findSellerProductById(sellerProductId)
      setSellerProdForm({
        isOpen: true,
        formData: sellerProd,
      })
    } else {
      toast.error("Can't find seller product")
    }
  }

  const onCloseSellerProdForm = () => {
    setSellerProdForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onUpdateLoadQty = async () => {
    setLoadingLoadQty(true)
    try {
      const response = await apiClient.loads.update(load.id, {
        qty: loadQty,
      })
      if (response.errors?.length) {
        setBackendError(response.errors[0])
      } else {
        refetchScheduleData()
        afterUpdateload && afterUpdateload(response)
        setIsUpdatingQty(false)
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    } finally {
      setLoadingLoadQty(false)
    }
  }

  const renderNoteCreatedAt = useCallback(
    createdAt => {
      let format = `MMM-DD, YYYY ${getTimeFormatType}`
      if (isToday(createdAt)) {
        format = `${getTimeFormatType}`
      } else if (isSameYear(createdAt)) {
        format = `MMM-DD, ${getTimeFormatType}`
      }
      return moment(createdAt).format(format)
    },
    [getTimeFormatType],
  )

  const renderNotesTable = useMemo(
    () => (
      <div
        style={{
          width: isMobile ? '100%' : 300,
          marginTop: isMobile ? 12 : 0,
        }}
      >
        <div style={{ ...styles.printLoadRow }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h2 style={{ marginRight: 8 }}>Notes</h2>
            <NoteDropdown
              notesData={notes}
              noteableType={ENoteableType.Load}
              afterCreateNote={() => loadNotes(load?.id)}
              tooltipProps={{
                placement: 'bottom',
              }}
              noteableId={load?.id}
            />
          </div>
        </div>
        <Row style={{ ...styles.printLoadRow }}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th
                  style={{
                    ...styles.tableCellHeader,
                    textAlign: 'left',
                    paddingLeft: 8,
                  }}
                >
                  Note
                </th>
                <th
                  style={{
                    ...styles.tableCellHeader,
                  }}
                >
                  User
                </th>
                <th
                  style={{
                    ...styles.tableCellHeader,
                    borderRightWidth: 0,
                    textAlign: 'left',
                    paddingLeft: 8,
                  }}
                >
                  Time
                </th>
                <th style={{ ...styles.tableCellHeader, borderRight: 0 }}></th>
              </tr>
            </thead>
            <tbody>
              {notes.map(extra => (
                <tr style={styles.tableRowNote} key={extra.id}>
                  <td
                    style={{
                      ...styles.tableCellValue,
                      width: '45%',
                      textAlign: 'left',
                      paddingLeft: 8,
                    }}
                  >
                    {extra.note}
                  </td>
                  <td
                    style={{
                      ...styles.tableCellValue,
                      width: '25%',
                    }}
                  >
                    {renderNoteUserAccess(extra.userAccessId)}
                  </td>
                  <td
                    style={{
                      ...styles.tableCellValue,
                      width: '20%',
                      textAlign: 'left',
                      paddingLeft: 8,
                      borderRightWidth: 0,
                      fontSize: 13,
                    }}
                  >
                    {renderNoteCreatedAt(extra.createdAt)}
                  </td>
                  <td style={styles.actionNote}>
                    <NoteEditDropdown
                      noteData={extra}
                      afterUpdateNote={() => loadNotes(load?.id)}
                    />
                    <NoteConfirmDelete
                      onConfirm={() => loadNotes(load?.id)}
                      noteData={extra}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </div>
    ),
    [
      isMobile,
      load?.id,
      loadNotes,
      notes,
      renderNoteCreatedAt,
      renderNoteUserAccess,
      styles.actionNote,
      styles.printLoadRow,
      styles.table,
      styles.tableCellHeader,
      styles.tableCellValue,
      styles.tableRowNote,
    ],
  )

  const updateLoadSigner = async () => {
    try {
      const response = await apiClient.loads.update(load.id, {
        signer: signalName,
      })
      afterUpdateload && afterUpdateload(response)
    } catch (error) {
      console.log('error', error)
    } finally {
      //
    }
  }

  const renderCompanyCard = (company, address) => {
    return (
      <div style={styles.card}>
        <b>{company?.code}</b>
        <b>{company?.name}</b>
        <div style={{ fontSize: 13, fontStyle: 'italic' }}>
          {buildFullAddress(address)}
        </div>
      </div>
    )
  }

  const getPageMargins = () => {
    return '@page {margin: 48px 48px 48px 48px !important; }'
  }

  const onOpenSignatureModal = useCallback(() => {
    setIsShowSignatureModal(true)
  }, [])

  const onCloseSignatureModal = useCallback(() => {
    setIsShowSignatureModal(false)
  }, [])

  const loadNotes = useCallback(async id => {
    try {
      const { notes: dataNotes } = await apiClient.notes.get({
        filters: {
          noteableId: id,
          noteableType: 'Load',
        },
      })
      setNotes(dataNotes)
    } catch (error) {
      toast.error(error.message)
    }
  }, [])

  const onOpenOrderForm = useCallback(
    defaultTab => () => {
      if (currentScope !== EScope.driverFleet) {
        setIsOpenOrderForm(true)
        if (defaultTab !== undefined) {
          setDefaultTab(defaultTab)
        }
      }
    },
    [currentScope],
  )

  const onCloseOrderForm = useCallback(() => {
    setIsOpenOrderForm(false)
    setDefaultTab(undefined)
  }, [])

  useEffect(() => {
    load?.id && loadNotes(load.id)
  }, [load?.id, loadNotes])

  useEffect(() => {
    setLoadQty(load?.qty || 0)
  }, [load?.qty])

  useEffect(() => {
    setSignalName(load?.signer)
  }, [load?.signer])

  useEffect(() => {
    if (buyerSignature) {
      setSignature(buyerSignature.image)
    }
  }, [buyerSignature])

  return (
    <>
      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}
      <Container
        ref={printRef}
        style={{
          marginTop: '20px',
          fontFamily:
            'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        }}
      >
        <style>{getPageMargins()}</style>
        <div style={styles.header}>
          <div>
            <div style={styles.companyInfo}>
              {hasImageError ? (
                <div
                  style={{
                    ...styles.companyAvatar,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 20,
                    fontWeight: 600,
                    backgroundColor: '#1090ff',
                    color: 'white',
                    borderRadius: 12,
                  }}
                >
                  {getInitName(currentCompany.name)}
                </div>
              ) : (
                <img
                  style={styles.companyAvatar}
                  src={currentCompany.logo || currentCompany.avatar}
                  onError={() => {
                    setHasImageError(true)
                  }}
                />
              )}

              <div style={styles.companyNameCode}>
                <div style={styles.companyName}>{currentCompany.name}</div>
                <div style={styles.companyCode}>{currentCompany.code}</div>
              </div>
            </div>
            <div style={{ fontSize: 15 }}>
              <span style={{ fontWeight: 700 }}>Address</span>:&nbsp;
              <span style={{ fontWeight: 400 }}>
                {buildFullAddress(officeOrMailingAddress)}
              </span>
            </div>
          </div>
          <div>
            <div style={{ fontSize: 15 }}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right', paddingRight: 8 }}>
                      <span style={{ fontWeight: 700 }}>Email:</span>
                    </td>
                    <td>
                      <span style={{ fontWeight: 400 }}>
                        {operationsOrGeneralEmail?.email}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', paddingRight: 8 }}>
                      <span style={{ fontWeight: 700 }}>Phone:</span>
                    </td>
                    <td>
                      <span style={{ fontWeight: 400 }}>
                        {formatPhoneNumber(
                          operationsOrGeneralPhoneNumber?.number || '',
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Row style={styles.printLoadRow}>
          <table style={styles.table}>
            <thead>
              <tr style={styles.tableHeader}>
                <th style={styles.tableCellHeader}>Ticket #</th>
                <th style={styles.tableCellHeader}>Date</th>
                <th style={styles.tableCellHeader}>Time</th>
                <th style={styles.tableCellHeader}>Truck</th>
                <th style={{ ...styles.tableCellHeader, borderRightWidth: 0 }}>
                  Driver
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={styles.tableRow}>
                <td style={styles.tableCell}>{load?.num}</td>
                <td style={styles.tableCell}>
                  {getDateTimeFormat(ticketTime, 'YYYY-MM-DD')}
                </td>
                <td style={styles.tableCell}>{getTimeFormat(ticketTime)}</td>
                <td style={styles.tableCell}>
                  {truckFleet?.truck?.name || '-'}
                </td>
                <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                  {buildFullName(user?.person, '-')}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
        <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
          <table style={styles.table}>
            <thead>
              <tr style={styles.tableHeader}>
                <th style={{ ...styles.tableCellHeader, width: 150 }}>
                  Load Size {currentUom ? `(${currentUom?.code})` : ''}
                </th>
                <th style={styles.tableCellHeader}>Running Total</th>
                <th style={styles.tableCellHeader}>Order #</th>
                <th style={{ ...styles.tableCellHeader, borderRightWidth: 0 }}>
                  Order Load #
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={styles.tableRow}>
                <td style={styles.tableCell}>
                  {isUpdatingQty ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <NumericFormat
                        value={loadQty}
                        customInput={Form.Control}
                        onValueChange={({ value }) => {
                          setLoadQty(value)
                        }}
                        autoFocus
                        style={{ fontSize: 13, borderWidth: 0 }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          onClick={onUpdateLoadQty}
                          className={clsx({ isDisabled: loadingLoadQty })}
                        >
                          <CheckMarkIcon
                            className='clickable'
                            color='var(--bs-success)'
                          />
                        </span>
                        <span
                          onClick={() => {
                            setLoadQty(load?.qty || 0)
                            setIsUpdatingQty(false)
                          }}
                          className={clsx({ isDisabled: loadingLoadQty })}
                        >
                          <CloseIcon
                            size={15}
                            className='clickable'
                            color='var(--bs-danger)'
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (canEdit) {
                          setIsUpdatingQty(true)
                        }
                      }}
                    >
                      {Number(loadQty)}
                    </div>
                  )}
                </td>
                <td style={styles.tableCell}>
                  {`${load?.orderQty ? Number(load?.orderQty) : 0} / ${Number(
                    scheduleData?.qty || 0,
                  )}`}
                </td>
                <td style={styles.tableCell}>
                  <span
                    className={clsx({
                      hyperLink: currentScope !== EScope.driverFleet,
                    })}
                    onClick={onOpenOrderForm()}
                  >
                    {scheduleData?.order?.num}&nbsp;
                  </span>

                  <span
                    className={clsx({
                      hyperLink: currentScope !== EScope.driverFleet,
                    })}
                    onClick={onOpenOrderForm(scheduleData?.scheduleNumber)}
                  >
                    {scheduleData?.scheduleNumber
                      ? `- ${scheduleData?.scheduleNumber}`
                      : ''}
                  </span>
                </td>

                <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                  {load?.orderLoad}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>

        {isLoadingMixDetails && (
          <div style={{ marginTop: 8 }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}

        {mixDetailData && (
          <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
            <table style={styles.table}>
              <thead>
                <tr style={styles.tableHeader}>
                  <th style={styles.tableCellHeader}>Slump</th>
                  <th style={styles.tableCellHeader}>Air</th>
                  <th style={styles.tableCellHeader}>Temp</th>
                  <th
                    style={{ ...styles.tableCellHeader, borderRightWidth: 0 }}
                  >
                    Gravity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={styles.tableRow}>
                  <td style={styles.tableCell}>{mixDetailData.slump}</td>
                  <td style={styles.tableCell}>{mixDetailData.air}</td>
                  <td style={styles.tableCell}>
                    {[
                      { value: mixDetailData.minTemp, label: 'Min' },
                      { value: mixDetailData.maxTemp, label: 'Max' },
                    ]
                      .filter(({ value }) => value)
                      .map(({ value, label }) => `${label}: ${value}`)
                      .join(' | ')}
                  </td>
                  <td style={{ ...styles.tableCell, borderRightWidth: 0 }}>
                    {mixDetailData.specificGravity}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
        )}

        <div style={{ ...styles.printLoadRow, ...styles.row }}>
          <div style={{ ...styles.cardColumn, marginRight: 12 }}>
            <h2>Buyer</h2>
            {renderCompanyCard(buyerCompany, buyerAddress)}
          </div>
          <div style={{ ...styles.cardColumn, marginLeft: 12 }}>
            <h2>Deliver To:</h2>
            {renderCompanyCard(buyerTerminal, buyerTerminalAddress)}
          </div>
        </div>
        <div style={{ ...styles.printLoadRow, ...styles.row }}>
          <div style={{ ...styles.cardColumn, marginRight: 12 }}>
            <h2>Seller</h2>
            {renderCompanyCard(sellerCompany, sellerAddress)}
          </div>
          <div style={{ ...styles.cardColumn, marginLeft: 12 }}>
            <h2>Deliver From:</h2>
            {renderCompanyCard(sellerTerminal, sellerTerminalAddress)}
          </div>
        </div>

        <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
          <h2 className='d-flex align-items-center'>
            Products
            {currentScope !== EScope.driverFleet ? (
              <span
                style={{ color: 'var(--ion-color-concord)' }}
                onClick={() => setShowExtraForm(!showExtraForm)}
                className='d-flex align-items-center'
              >
                <IonIcon
                  icon={addCircleOutline}
                  style={{ width: 18, height: 18 }}
                />
              </span>
            ) : null}
          </h2>
        </Row>
        <Row style={{ ...styles.printLoadRow, marginTop: 16 }} className='p-3'>
          <LoadExtraTableForm
            loadId={load.id}
            buyerId={buyerId}
            sellerId={sellerId}
            showExtraForm={showExtraForm}
            setShowExtraForm={setShowExtraForm}
            loadSellerProductId={load.sellerProductId}
            loadQty={load.qty}
            hideAddBtn
            onContextMenu={(event, sellerProduct) =>
              onOpenSellerProdForm(sellerProduct?.id)(event)
            }
          >
            <tr>
              <td onContextMenu={onOpenSellerProdForm(load.sellerProductId)}>
                {sellerProduct?.code || '-'}
              </td>
              <td onContextMenu={onOpenSellerProdForm(sellerProduct?.id)}>
                {sellerProduct?.name || '-'}
              </td>
              <td>{Number(load?.qty)}</td>
              <td>{currentUom?.code || '-'}</td>
            </tr>
          </LoadExtraTableForm>

          {loadTimesMapped.length > 1 && !isLoadCreatedInLastMinute ? (
            <>
              <Row style={{ ...styles.printLoadRow, marginTop: 16 }}>
                <h2>Times</h2>
              </Row>

              <table style={styles.table}>
                <thead>
                  <tr>
                    {loadTimesMapped.map(({ name }) => (
                      <th key={name} style={styles.tableCellHeader}>
                        {name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr style={styles.tableRow}>
                    {loadTimesMapped.map((loadTime, index) => (
                      <EditableTimeCell
                        key={loadTime.name}
                        loadTime={loadTime}
                        onUpdate={() => {
                          refetchLoadTimesData()
                        }}
                        canDelete
                        onDelete={loadTime =>
                          onDeletLoadTime(
                            loadTime,
                            index + 1 === loadTimesMapped.length,
                          )
                        }
                      />
                    ))}
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}

          {isMobile && renderNotesTable}

          <div style={styles.signatureContainer}>
            {!isMobile && renderNotesTable}
            <div>
              <QRCode
                value={JSON.stringify({
                  id: load.id,
                  idDriverOpeningTicket: driverDetails?.id,
                })}
                size={150}
                logoImage='https://workloadnode.s3.amazonaws.com/Logos/Concord+Main+Logo+Black+BG+square+cropped.png'
                logoWidth={30}
                qrStyle='dots'
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 12,
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Signature</span>
              <div
                className={clsx('LoadPrint__signatureBox', {
                  hasSignature: signature,
                })}
                onClick={onOpenSignatureModal}
              >
                <div style={styles.signatureBox}>
                  {signature && (
                    <img src={signature} width={200} height={125} />
                  )}
                </div>
              </div>
              {isUpdatingSignatureName ? (
                <ConcordFormTextField
                  autoFocus
                  value={signalName}
                  className='LoadPrint__signalNameTextbox'
                  onChange={(event, { value }) => {
                    setSignalName(value)
                  }}
                  onBlur={async () => {
                    setIsUpdatingSignatureName(false)
                    updateLoadSigner()
                  }}
                  onKeyPress={e => {
                    if (e.which === 13) {
                      setIsUpdatingSignatureName(false)
                      updateLoadSigner()
                    }
                  }}
                />
              ) : (
                <div
                  onClick={() => {
                    setIsUpdatingSignatureName(true)
                  }}
                  style={{
                    minHeight: 29.5,
                    minWidth: 161,
                    textAlign: 'center',
                    fontSize: 16,
                    marginTop: 8,
                  }}
                >
                  {signalName || 'Name'}
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>

      <div>
        <IonButtons>
          <IonButton
            style={{ width: '100%' }}
            expand='block'
            color='primary'
            onClick={handlePrint}
            fill='solid'
          >
            Print
          </IonButton>

          {onClose && (
            <IonButton
              style={{ width: '100%' }}
              expand='block'
              color='medium'
              onClick={onClose}
              fill='solid'
            >
              Close
            </IonButton>
          )}
        </IonButtons>
        {truck?.sensorUid ? (
          <SensorsList
            truck={truckFleet?.truck}
            load={load}
            showLineGraph={showLineGraph}
            setShowLineGraph={setShowLineGraph}
          />
        ) : null}
        <WeightsList load={load} />
      </div>
      <SignatureModal
        isOpen={isShowSignatureModal}
        onClose={onCloseSignatureModal}
        onConfirm={signature => {
          createOrUpdateBuyerSignature(signature)
          setSignature(signature)
        }}
      />
      <OrderFormDialog
        isOpen={isOpenOrderForm}
        onClose={onCloseOrderForm}
        orderData={scheduleData?.order}
        defaultTab={defaultTab}
        className='LoadPrint__orderFormDialog'
        afterUpdate={() => {
          refetchScheduleData()
        }}
      />

      <DialogSellerProductForm
        formData={sellerProdForm.formData}
        isOpen={sellerProdForm.isOpen}
        onClose={onCloseSellerProdForm}
        afterUpdate={sellerProd => {
          onCloseSellerProdForm()
          updateSellerProduct(sellerProd.id, sellerProd)
        }}
      />
    </>
  )
}

export default LoadPrint
