import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { IUser } from '~/types/models/IUser'
import {
  IGetUserTableConfigurationsParams,
  IGetUserTableConfigurationsResponse,
} from '~/types/models/IUserTableConfiguration'

const useQueryUseTableConfigurations = (
  params: IGetUserTableConfigurationsParams,
  options?: Partial<UseQueryOptions<IGetUserTableConfigurationsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryClient = useQueryClient()

  const queryKey = [
    'user_table_configurations',
    sessionUser?.id,
    params.tableName,
  ]

  const { data, isFetching, isFetched } = useQuery({
    queryKey,
    queryFn() {
      return apiClient.userTableConfigurations.get(params)
    },
    staleTime: undefined,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const filterConfigurations = data?.filterConfigurations

  const updateUserTableConfiguration = (
    data: IGetUserTableConfigurationsResponse,
  ) => {
    queryClient.setQueryData<IGetUserTableConfigurationsResponse | undefined>(
      queryKey,
      () => data,
    )
  }

  return {
    userTableConfigurations: data,
    filterConfigurations,
    updateUserTableConfiguration,
    isUserTableConfigurationsFetching: isFetching,
    isUserTableConfigurationsFetched: isFetched,
  }
}

export default useQueryUseTableConfigurations
