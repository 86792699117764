import { useEffect, useState } from 'react'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { DateRangeDropdownPicker } from '~/components/shared'
import getDateRange from '~/utils/getDateRange'

export interface IRTEditCellTimeFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  type?: string
}

const RTEditCellDateRangeField = <TData extends IReusableTableRowData>(
  props: IRTEditCellTimeFieldProps<TData>,
) => {
  const { cell, table } = props

  const {
    getState,
    options: { onCellEditEnd },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()

  const cellValue = cell.getValue<any>()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const [value, setValue] = useState(() => cell.getValue<any>())

  const dateFieldProps =
    typeof column.columnDef.editDateRangeFieldProps === 'function'
      ? column.columnDef.editDateRangeFieldProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const onChange = (dateRange: any) => {
    saveInputValueToRowCache(dateRange || '')
    setEditingCell(null)
    if (!isCreating) {
      onCellEditEnd && onCellEditEnd(value, cell)
    }
  }

  useEffect(() => {
    setValue(getDateRange(cellValue))
  }, [cellValue])

  return (
    <DateRangeDropdownPicker
      {...dateFieldProps}
      className='RTEditCellDateRangeField__container'
      date={value || {}}
      onChange={onChange}
    />
  )
}

export default RTEditCellDateRangeField
