import React, { useCallback, useEffect, useState } from 'react'
import useInvoiceContainer from '~/hooks/useInvoice/useInvoiceContainer'
import './styles.scss'
import Skeleton from 'react-loading-skeleton'
import {
  BillLineDetailsModal,
  InvoiceBodyHeader,
  InvoiceBodyPanels,
} from '~/components/invoice'
import InvoiceAction from '../InvoiceActions'
import {
  CommonDialog,
  DialogInvoicePayments,
  PdfViewer,
} from '~/components/shared'
import { Viewer } from '@react-pdf-viewer/core'
import { useDeepCompareEffect } from 'react-use'
import { useQueryClient } from 'react-query'
import { useRouter } from '~/hooks/useRouter'

function InvoiceDetailsSection(props, ref) {
  const { invoiceId, onChangeInvoice, invoice: invoicePassed } = props

  const [openDialogPayments, setOpenDialogPayments] = useState(false)

  const {
    approvalControlsClick,
    invoice,
    invoiceStatus,
    isResolved,
    isSplitView,
    onGetParamsFilter,
    onSaveBillLine,
    onShowBillLineDetail,
    saveInvoice,
    setShowPdfViewer,
    showPdfViewer,
    splitCompareRef,
    splitRef,
    onPreviewPdf,
    onClosePdfPreview,
    pdfFile,
    isInvoicesDataFetching,
    updateInvoice,
    invoicesQueryKey,
  } = useInvoiceContainer(invoiceId)

  const queryClient = useQueryClient()

  const router = useRouter()

  useEffect(() => {
    if (invoicePassed) {
      queryClient.setQueryData(invoicesQueryKey, () => ({
        invoices: [invoicePassed],
      }))
    } else if (router.location?.state?.invoice?.id == invoiceId) {
      queryClient.setQueryData(invoicesQueryKey, () => ({
        invoices: [router.location?.state?.invoice],
      }))
    }

    return () => {
      window.history.replaceState({}, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicePassed, router.location?.state?.invoice])

  const handleCloseDialogPayments = useCallback(() => {
    setOpenDialogPayments(false)
  }, [])

  useDeepCompareEffect(() => {
    onChangeInvoice && onChangeInvoice(invoice)
  }, [invoice])

  useEffect(() => {
    if (typeof ref === 'function') {
      ref({
        updateInvoice,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return (
    <div>
      {isInvoicesDataFetching ? (
        <div style={{ marginTop: 8 }}>
          <Skeleton width='100%' height={20} />
          <Skeleton width='100%' height={20} />
          <Skeleton width='100%' height={20} />
        </div>
      ) : (
        <InvoiceBodyHeader
          invoice={invoice}
          isResolved={isResolved}
          subComponent={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <InvoiceAction
                invoice={invoice}
                approvalControlsClick={approvalControlsClick}
                id={invoiceId}
                invoiceStatus={invoiceStatus}
                onGetParamsFilter={onGetParamsFilter}
                saveInvoice={saveInvoice}
                onPreviewPdf={onPreviewPdf}
                // onViewPayments={onViewPayments}
              />
            </div>
          }
        />
      )}

      <div>
        <InvoiceBodyPanels
          invoice={invoice}
          isResolved={isResolved}
          isSplitView={isSplitView}
          splitCompareRef={splitCompareRef}
          onShowBillLineDetail={onShowBillLineDetail}
          saveBillLine={onSaveBillLine}
          className='split'
          isLoading={isInvoicesDataFetching}
        />
      </div>

      {invoice && (
        <>
          <BillLineDetailsModal
            invoice={invoice}
            saveBillLine={onSaveBillLine}
            splitRef={splitRef}
            splitCompareRef={splitCompareRef}
          />
          <PdfViewer
            value={true}
            document={invoice?.proveUrl}
            showPopOver={showPdfViewer}
            setShowPopOver={setShowPdfViewer}
          />
        </>
      )}

      <DialogInvoicePayments
        open={openDialogPayments}
        onClose={handleCloseDialogPayments}
        invoice={invoice}
      />
      <CommonDialog
        open={pdfFile != null}
        title={'PDF Preview'}
        className={'InvoiceContainer__pdfFile'}
        onClose={onClosePdfPreview}
      >
        {pdfFile && <Viewer fileUrl={pdfFile} />}
      </CommonDialog>
    </div>
  )
}

export default React.forwardRef(InvoiceDetailsSection)
