import {
  CommonDialogV2,
  ICommonDialogProps,
  ReusableButton,
} from '~/components/shared'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import HierarchyForm, { IHierarchyFormProps } from './HierarchyForm'
import { useRef, useState } from 'react'

export interface IDialogHierarchiesFormProps extends ICommonDialogProps {
  hierarchableType?: EHierarchableType
  hierarchableId?: number
  afterCreate?: () => void
  hierarchyItem?: IHierarchyFormProps['item']
}

function DialogHierarchiesForm(props: IDialogHierarchiesFormProps) {
  const {
    hierarchableId,
    hierarchableType,
    afterCreate,
    hierarchyItem,
    ...dialogProps
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const formRef = useRef<any>()

  const onSubmit = () => {
    formRef.current?.handleSubmit(async () => {
      setIsLoading(true)

      try {
        const res = await formRef.current?.createOrUpdateHierarchy({
          hierarchableType: hierarchableType,
          hierarchableId: hierarchableId,
        })
        if (res.backendErrors?.length === 0) {
          afterCreate && afterCreate()
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    })()
  }

  return (
    <CommonDialogV2
      size='md'
      isHiddenOkButton
      {...dialogProps}
      title='Create Hierarchies'
    >
      <HierarchyForm ref={formRef} item={hierarchyItem} />
      <ReusableButton
        style={{ marginTop: 8, fontSize: 13, width: '100%' }}
        onClick={onSubmit}
        isLoading={isLoading}
      >
        Create
      </ReusableButton>
    </CommonDialogV2>
  )
}

export default DialogHierarchiesForm
