import useQueryEnums from './useQueryEnums'

const useQueryInvoicesEnums = () => {
  const { enumsData, getOptionsFromEnum } = useQueryEnums({
    model: 'invoices',
    field: '',
  })

  const invoiceCategoryEnums = enumsData?.category || {}
  const invoiceHitlStatusEnums = enumsData?.hitlStatus || {}
  const invoicePrimaryParsingStatusEnums = enumsData?.primaryParsingStatus || {}
  const invoiceSellerStatusEnums = enumsData?.sellerStatus || {}
  const invoiceSourceEnums = enumsData?.source || {}
  const invoiceStatusEnums = enumsData?.status || {}

  const invoiceCategoryOptions = getOptionsFromEnum(invoiceCategoryEnums)
  const invoiceHitlStatusOptions = getOptionsFromEnum(invoiceHitlStatusEnums)
  const invoicePrimaryParsingStatusOptions = getOptionsFromEnum(
    invoicePrimaryParsingStatusEnums,
  )
  const invoiceSellerStatusOptions = getOptionsFromEnum(
    invoiceSellerStatusEnums,
  )
  const invoiceSourceOptions = getOptionsFromEnum(invoiceSourceEnums)
  const invoiceStatusOptions = getOptionsFromEnum(invoiceStatusEnums)

  return {
    invoiceCategoryEnums,
    invoiceHitlStatusEnums,
    invoicePrimaryParsingStatusEnums,
    invoiceSellerStatusEnums,
    invoiceSourceEnums,
    invoiceStatusEnums,
    invoiceCategoryOptions,
    invoiceHitlStatusOptions,
    invoicePrimaryParsingStatusOptions,
    invoiceSellerStatusOptions,
    invoiceSourceOptions,
    invoiceStatusOptions,
  }
}

export default useQueryInvoicesEnums
