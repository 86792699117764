import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IonCol, IonGrid, IonProgressBar, IonRow } from '@ionic/react'
import { apiClient } from '~/api/ApiClient'
import {
  DialogDocumentForm,
  ReusableButton,
  ViewPDF,
} from '~/components/shared'

export interface IEpdTabProps {
  sellerProductId: number
  modalIsExpanded?: boolean
  setModalIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>
}

function EpdTab(props: IEpdTabProps) {
  const { sellerProductId, modalIsExpanded, setModalIsExpanded } = props

  const [data, setData] = useState<string[]>([])
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEpd, setSelectedEpd] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
  const [isFetched, setIsFetched] = useState(false)
  const [documentForm, setDocumentForm] = useState({
    isOpen: false,
  })

  const pdfViewerRef = useRef<any>(null) // Ref for the ViewPDF component

  const showAdditionalButtons = useMemo(
    () => Boolean(selectedEpd),
    [selectedEpd],
  )

  const fetchEpd = async () => {
    setIsLoading(true)
    setError('')
    try {
      const response = await apiClient.sellerProducts.getEpd({
        sellerProductId,
        type: 'seller_product',
      })
      const data = JSON.parse(response.body)
      setData(data)
      if (data.length === 1) {
        setSelectedEpd(data[0])
      }
      setIsFetched(true)
    } catch (error) {
      console.log('error', error)
      setData([])
      setError('Error fetching EPD data')
    } finally {
      setIsLoading(false)
    }
  }

  const onUpdateEpd = async () => {
    setIsUpdating(true)
    setError('')
    try {
      const response = await apiClient.sellerProducts.updateEpd({
        sellerProductId,
        type: 'seller_product',
      })
      if (response.status === 200) {
        setLastUpdated(new Date())
        fetchEpd()
      }
    } catch (error) {
      setError('Error updating EPD')
    } finally {
      setIsUpdating(false)
    }
  }

  const onOpenDocumentForm = () => () => {
    setDocumentForm({
      isOpen: true,
    })
  }

  const onCloseDocumentForm = () => {
    setDocumentForm({
      isOpen: false,
    })
  }

  const renderEpd = useMemo(() => {
    if (data.length > 1) {
      return (
        <div>
          <IonGrid>
            <IonRow>
              {data.map((pdfUrl, index) => (
                <IonCol
                  size='4'
                  key={index}
                  style={{
                    padding: 8,
                    border: '1px solid #e5e5e5',
                    borderColor:
                      selectedEpd === pdfUrl
                        ? 'var(--bs-primary)'
                        : 'var(--bs-gray-500)',
                  }}
                  onClick={() => {
                    setSelectedEpd(prev => {
                      if (prev === pdfUrl) {
                        return ''
                      }
                      return pdfUrl
                    })
                  }}
                >
                  <embed
                    src={pdfUrl}
                    style={{ width: '100%', height: '100%' }}
                  />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
      )
    }
  }, [data, selectedEpd])

  useEffect(() => {
    if (pdfViewerRef.current) {
      pdfViewerRef.current.setZoom(modalIsExpanded ? 2 : 1)
    }
  }, [modalIsExpanded])

  useEffect(() => {
    fetchEpd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {isLoading && <IonProgressBar type='indeterminate' />}

      <div style={{ margin: '8px 0' }}>
        <ReusableButton
          isLoading={isUpdating}
          isDisabled={Boolean(lastUpdated) || !isFetched}
          onClick={onUpdateEpd}
        >
          Update EPD
        </ReusableButton>

        {showAdditionalButtons && (
          <>
            <ReusableButton
              style={{ marginLeft: 8 }}
              onClick={() => {
                if (setModalIsExpanded) {
                  setModalIsExpanded(prev => !prev)
                }
              }}
            >
              {modalIsExpanded ? 'Collapse' : 'Expand'}
            </ReusableButton>

            <ReusableButton
              style={{ marginLeft: 8 }}
              onClick={onOpenDocumentForm()}
            >
              Save PDF to Document
            </ReusableButton>
          </>
        )}
      </div>

      {error && <div style={{ color: 'red', fontSize: 13 }}>{error}</div>}

      {renderEpd}

      {selectedEpd && (
        <div
          style={{
            marginTop: 8,
            paddingTop: 8,
            borderTop: '1px dashed var(--bs-gray-500)',
          }}
        >
          <ViewPDF ref={pdfViewerRef} link={selectedEpd} defaultZoom={1} />
        </div>
      )}

      <DialogDocumentForm
        isOpen={documentForm.isOpen}
        formData={
          {
            inputType: 'url',
            url: [selectedEpd],
            docType: 'SellerProduct',
            docTypeId: sellerProductId,
          } as any
        }
        onClose={onCloseDocumentForm}
        afterCreate={() => {
          onCloseDocumentForm()
        }}
      />
    </div>
  )
}

export default EpdTab
