import type { IBundleItemCardProps } from './type'

import './styles.scss'
import { useQuerySellerProducts } from '~/hooks/useQueryData'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { IonIcon, IonSpinner } from '@ionic/react'
import { checkmark, close, closeOutline } from 'ionicons/icons'
import { useDrop, XYCoord, useDrag } from 'react-dnd'
import {
  AutoWidthInput,
  DialogSellerProductForm,
  DragIcon,
  DropdownWithCustomChildren,
  ToolTipOverlay,
} from '~/components/shared'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { Unless, When } from 'react-if'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { ESellerProductUsage } from '~/types/enums/ESellerProduct'
import _ from 'lodash'

interface DragItem {
  index: number
  id: string
  type: string
  bundleId: number
}

const ItemTypes = {
  CARD: 'card',
}

function BundleItemCard(props: IBundleItemCardProps) {
  const {
    bundleItem,
    index,
    sellerProductId,
    canDrag,
    defaultRank,
    defaultFormOpen = false,
    bundleId,
    existingSellerProductIds = [],
    afterUpdate,
    afterCreate,
    onCmdEnter,
    onRemove,
    onDrag,
  } = props

  const ref = useRef<HTMLDivElement>(null)

  const [bundleItemFormValues, setBundleItemFormValues] = useState({
    amount: null as null | number,
    code: '' as null | string,
    sellerProductId: null as null | number,
    rank: null as null | number,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isUpdatingAmount, setIsUpdatingAmount] = useState(false)
  const [isUpdatingCode, setIsUpdatingCode] = useState(false)

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const amountRef = useRef<HTMLInputElement>(null)
  const codeRef = useRef<HTMLInputElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ handlerId }, drop] = useDrop<any, any, any>(
    {
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      // canDrop: item => item.bundleId == bundleId,
      hover(item: DragItem, monitor) {
        // if (!monitor.canDrop()) return
        if (item.bundleId !== bundleId) return
        if (!ref.current) return

        const dragIndex = item.index
        const hoverIndex = index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        // Time to actually perform the action
        onDrag(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    },
    [bundleId, index, onDrag],
  )

  const [{ isDragging }, drag] = useDrag(
    {
      type: ItemTypes.CARD,
      item: () => ({ id: bundleItem?.id, bundleId: bundleId, index }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [bundleItem, bundleId, index],
  )

  const { sellerProductOptions, updateSellerProduct, deleteSellerProduct } =
    useQuerySellerProducts({
      filters: {
        sellerId:
          currentScope === EScope.seller ? [currentCompany.id] : undefined,
        productUsage: [
          ESellerProductUsage.bundleItem,
          ESellerProductUsage.both,
        ],
      },
    })

  const sellerProductOptsFiltered = sellerProductOptions.filter(
    ({ value }) =>
      value !== sellerProductId && !existingSellerProductIds.includes(value),
  )

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const findSellerProduct = useCallback(
    (prodId: number | null) =>
      sellerProductOptions.find(({ value }) => value === prodId),
    [sellerProductOptions],
  )

  const onClickRemove = () => {
    onRemove && onRemove(bundleItem)
  }

  const createBundleItem = useCallback(async () => {
    setIsLoading(true)
    try {
      const { errors, ...response } = await apiClient.bundleItems.create({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        bundleItem: { ...bundleItemFormValues, bundleId } as any,
      })
      if (errors.length) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        setIsUpdatingAmount(false)
        setIsUpdatingCode(false)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }, [bundleItemFormValues, bundleId, afterCreate])

  const onCreateBundleItem = () => {
    const { amount, sellerProductId } = bundleItemFormValues
    if (!amount) {
      setError('Amount is required!')
    } else if (!sellerProductId) {
      setError('Product is required!')
    } else {
      setError('')
      createBundleItem()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateBundleItem = useCallback(
    async (field: string, value: any) => {
      setIsLoading(true)
      try {
        const { errors, ...response } = await apiClient.bundleItems.update(
          bundleItem?.id as number,
          {
            bundleItem: {
              [field]: value,
            },
          },
        )
        if (errors.length) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          if (field === 'amount') {
            setIsUpdatingAmount(false)
          } else if (field === 'code') {
            setIsUpdatingCode(false)
          }
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [afterUpdate, bundleItem?.id],
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSellerProduct = async (event: any, { selectedOption }: any) => {
    if (bundleItem) {
      await updateBundleItem('sellerProductId', selectedOption.value)
    }
    setBundleItemFormValues(prev => ({
      ...prev,
      sellerProductId: selectedOption.value,
    }))
    amountRef.current?.focus()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFormValues = (event: any) => {
    const { name, value } = event.target
    setBundleItemFormValues(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    setBundleItemFormValues({
      amount: bundleItem?.amount || null,
      code: bundleItem?.code || '',
      sellerProductId: bundleItem?.sellerProductId || null,
      rank: bundleItem?.rank || defaultRank || 0,
    })

    if (!bundleItem) {
      setIsUpdatingAmount(true)
      setIsUpdatingCode(true)
    }
  }, [bundleItem, defaultRank])

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (bundleItem) {
          updateBundleItem('amount', bundleItemFormValues.amount)
          setIsUpdatingAmount(false)
          setIsUpdatingCode(false)
        } else {
          if (
            !bundleItemFormValues.amount ||
            !bundleItemFormValues.sellerProductId
          ) {
            setError('Please fill in all required fields.')
            return
          }
          await createBundleItem()
          if (event.metaKey) {
            setBundleItemFormValues({
              amount: null,
              code: '',
              sellerProductId: null,
              rank: 0,
            })
            onCmdEnter && onCmdEnter()
          }
        }
      }
    }

    const inputElement = codeRef.current
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [
    bundleItem,
    bundleItemFormValues,
    codeRef,
    createBundleItem,
    onCmdEnter,
    updateBundleItem,
  ])

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (bundleItem) {
          updateBundleItem('amount', bundleItemFormValues.amount)
          setIsUpdatingAmount(false)
          setIsUpdatingCode(false)
        } else {
          if (
            !bundleItemFormValues.amount ||
            !bundleItemFormValues.sellerProductId
          ) {
            setError('Please fill in all required fields.')
            return
          }
          await createBundleItem()
          if (event.metaKey) {
            onCmdEnter && onCmdEnter()
          }
        }
      }
    }

    const inputElement = amountRef.current
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [
    bundleItem,
    bundleItemFormValues,
    amountRef,
    createBundleItem,
    onCmdEnter,
    updateBundleItem,
  ])

  const renderProduct = useMemo(() => {
    const product = findSellerProduct(bundleItemFormValues.sellerProductId)
    if (product?.label) {
      const [text, toolTip] = product.label.split('(')
      const content = toolTip ? _.startCase(toolTip.slice(0, -1)) : ''
      return (
        <ToolTipOverlay content={content} placement='top'>
          <span>{text}</span>
        </ToolTipOverlay>
      )
    }
    return '-'
  }, [bundleItemFormValues.sellerProductId, findSellerProduct])

  return (
    <div
      className='BundleItemCard__container'
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {canDrag && <DragIcon className='dragIcon icon' />}
      <div>
        <div className='itemLine'>
          <Badge bg='secondary'>{bundleItemFormValues.rank}</Badge>{' '}
        </div>
        <div className='itemLine dropdown'>
          <span className='value'>
            <DropdownWithCustomChildren
              options={sellerProductOptsFiltered}
              className='no-hover'
              onChange={onChangeSellerProduct}
              showUpdateButtons
              showDeleteButtons
              isLoading={isLoading}
              defaultFocused={defaultFormOpen}
              autoFocus={true}
              onDeleteOption={async opt => {
                setIsLoading(true)
                try {
                  const response = await apiClient.sellerProducts.delete(
                    opt.value,
                  )
                  if (response.errors?.length > 0) {
                    toast.error(response.errors[0])
                  } else {
                    deleteSellerProduct(response.id)
                    toast.success(toastMessages.deleteSuccess)
                  }
                } catch (error) {
                  console.log('error', error)
                  toast.error(toastMessages.deleteError)
                } finally {
                  setIsLoading(false)
                }
              }}
              renderForm={({ optionSelected, isOpen, setFormState }) => {
                return (
                  <DialogSellerProductForm
                    isOpen={isOpen}
                    formData={optionSelected?.item}
                    onClose={() => {
                      setFormState({ isOpen: false, optionSelected: null })
                    }}
                    afterUpdate={item => {
                      updateSellerProduct(item.id as number, item)
                    }}
                  />
                )
              }}
            >
              <div
                className={clsx({
                  noValue: !bundleItemFormValues.sellerProductId,
                })}
              >
                {renderProduct}
              </div>
            </DropdownWithCustomChildren>
          </span>
        </div>

        <div className='itemLine'>
          <span className='labelItem'>Qty</span>:&nbsp;&nbsp;
          <Unless condition={isUpdatingAmount}>
            <span
              className='value'
              onClick={() => {
                setIsUpdatingAmount(true)
              }}
            >
              {Number(bundleItemFormValues.amount)}
            </span>
          </Unless>
          <When condition={isUpdatingAmount}>
            <form
              className='formContainer'
              onSubmit={e => {
                e.preventDefault()
                if (bundleItem) {
                  updateBundleItem('amount', bundleItemFormValues.amount)
                } else {
                  setIsUpdatingAmount(false)
                  codeRef.current?.focus()
                }
              }}
            >
              {isLoading && (
                <IonSpinner name='dots' className='loadingSpinnerForm' />
              )}
              <AutoWidthInput
                value={bundleItemFormValues.amount || ''}
                className='inputContainer'
                name='amount'
                onChange={onChangeFormValues}
                ref={amountRef}
              />

              {!isLoading && bundleItem && (
                <>
                  <span
                    className='icon'
                    onClick={() => {
                      if (bundleItem) {
                        updateBundleItem('amount', bundleItemFormValues.amount)
                      } else {
                        setIsUpdatingAmount(false)
                      }
                    }}
                  >
                    <IonIcon icon={checkmark} color='success' />
                  </span>
                  <span
                    className='icon'
                    onClick={() => {
                      setBundleItemFormValues(prev => ({
                        ...prev,
                        amount: bundleItem?.amount as number,
                      }))
                      setIsUpdatingAmount(false)
                    }}
                  >
                    <IonIcon icon={closeOutline} color='danger' />
                  </span>
                </>
              )}
            </form>
          </When>
        </div>

        <div className='itemLine'>
          <span className='labelItem'>Code</span>:&nbsp;&nbsp;
          <Unless condition={isUpdatingCode}>
            <span
              className='value'
              onClick={() => {
                setIsUpdatingCode(true)
              }}
            >
              {bundleItemFormValues.code || '-'}
            </span>
          </Unless>
          <When condition={isUpdatingCode}>
            <form className='formContainer' onSubmit={e => e.preventDefault()}>
              <AutoWidthInput
                value={bundleItemFormValues.code || ''}
                className='inputContainer'
                name='code'
                onChange={onChangeFormValues}
                ref={codeRef}
              />
              {isLoading && (
                <IonSpinner name='dots' className='loadingSpinnerForm' />
              )}

              {!isLoading && bundleItem ? (
                <>
                  <span
                    className='icon'
                    onClick={() => {
                      if (bundleItem) {
                        updateBundleItem('code', bundleItemFormValues.code)
                      } else {
                        setIsUpdatingCode(false)
                      }
                    }}
                  >
                    <IonIcon icon={checkmark} color='success' />
                  </span>
                  <span
                    className='icon'
                    onClick={() => {
                      setBundleItemFormValues(prev => ({
                        ...prev,
                        code: bundleItem?.code || '',
                      }))
                      setIsUpdatingCode(false)
                    }}
                  >
                    <IonIcon icon={closeOutline} color='danger' />
                  </span>
                </>
              ) : null}
            </form>
          </When>
        </div>

        {!bundleItem && (
          <ToolTipOverlay content='Create' placement='top'>
            <Badge
              bg='success'
              className='icon itemLineIcon'
              onClick={onCreateBundleItem}
            >
              <IonIcon icon={checkmark} />
            </Badge>
          </ToolTipOverlay>
        )}
        <Badge
          bg='danger'
          className='icon itemLineIcon'
          onClick={onClickRemove}
        >
          <IonIcon icon={close} />
        </Badge>

        {error && (
          <span className='error' style={{ marginLeft: 4 }}>
            {error}
          </span>
        )}
      </div>
    </div>
  )
}

export default BundleItemCard
