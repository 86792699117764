import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { getTitleRange } from '~/utils'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetInvoicesParams,
  IGetInvoicesResponse,
  IInvoice,
} from '~/types/models/IInvoice'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { produce } from 'immer'

const useQueryInvoices = (
  params: Partial<IGetInvoicesParams> & { id?: number },
  options?: Partial<UseQueryOptions<IGetInvoicesResponse>>,
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'invoices',
      sessionUser?.id,
      buildGetUrl(apiClient.invoices.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    [params, sessionUser?.id],
  )

  const { data, isLoading, refetch, isFetched, isFetching } = useQuery({
    queryKey,
    async queryFn() {
      if (params.id) {
        const response = await apiClient.invoices.getById(params.id)
        return {
          count: 1,
          countOnPage: 1,
          endRange: 1,
          invoices: [response],
          page: 1,
          pageCount: 1,
          perPage: 25,
          startRange: 1,
        }
      }
      return apiClient.invoices.get(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const invoicesData = useMemo(() => data?.invoices || [], [data])
  const totalCount = data?.count || 0

  const invoiceOptions = useMemo(
    () =>
      invoicesData.map(invoice => {
        let label = invoice.num ? `#${invoice.num} ` : ''
        label += getTitleRange(invoice)

        return {
          value: invoice.id,
          label: label,
          total: invoice.total,
          tax: invoice.tax,
          sub: invoice.sub,
        }
      }),
    [invoicesData],
  )

  const updateInvoice = useCallback(
    (id: number, data: Partial<IInvoice>) => {
      queryClient.setQueryData<IGetInvoicesResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.invoices.findIndex(item => item.id === id)
              if (draft.invoices[index]) {
                draft.invoices[index] = {
                  ...draft.invoices[index],
                  ...data,
                }
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeInvoice = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGetInvoicesResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.invoices.findIndex(item => item.id === id)
              if (draft.invoices[index]) {
                draft.invoices.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    invoicesData,
    invoiceOptions,
    isLoadingInvoicesData: isLoading,
    refetchQueryInvoices: refetch,
    isInvoicesFetched: isFetched,
    updateInvoice,
    invoicesQueryKey: queryKey,
    isInvoicesDataFetching: isFetching,
    totalCount,
    removeInvoice,
  }
}

export default useQueryInvoices
