import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'
import useFuzzy from '~/hooks/useFuzzy'
import { useSelector } from 'react-redux'

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ContainerSearchBar,
  SortIcon,
  ToolTipOverlay,
} from '~/components/shared'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { CompanyMenuItem } from '../components'

import { selectMyCurrentCompany } from '~/redux/selectors'

import './styles.scss'
import _ from 'lodash'

const CompanyHomeContainer = props => {
  const { onClickSubpage, onClose, isShowCreateButton } = props

  const [sortingDirection, setSortingDirection] = useState('')

  const { myCompanyMenu } = useNavigatedMenuItems()

  const subtabs = useMemo(() => {
    if (sortingDirection) {
      return _.orderBy(myCompanyMenu.subtabs, ['label'], [sortingDirection])
    }

    return myCompanyMenu.subtabs
  }, [myCompanyMenu.subtabs, sortingDirection])

  const { seachedList, searchValue, onSearch } = useFuzzy(subtabs, {
    keys: ['label'],
  })

  const currentCompany = useSelector(selectMyCurrentCompany)

  const onClickItem = useCallback(
    (event, item) => {
      onClickSubpage && onClickSubpage(event, item)
    },
    [onClickSubpage],
  )

  const renderSortingDirection = useMemo(() => {
    if (sortingDirection) {
      if (sortingDirection === 'desc') {
        return <ArrowDownIcon className='icon' size={20} />
      }
      return <ArrowUpIcon className='icon' size={20} />
    }

    return <SortIcon className='icon' size={22} color='#7d7d7d' />
  }, [sortingDirection])

  const onChangeSortingDirection = useCallback(() => {
    setSortingDirection(prev => {
      if (!prev) {
        return 'desc'
      }
      if (prev === 'desc') {
        return 'asc'
      }
      return ''
    })
  }, [])

  const renderCompanySubmenu = useMemo(() => {
    return seachedList
      .filter(
        ({ isHiddenOnHomePage, isHidden }) => !isHiddenOnHomePage && !isHidden,
      )
      .map(item => {
        return (
          <IonCol
            key={item.value}
            sizeXl='4'
            sizeLg='4'
            sizeMd='6'
            sizeSm='6'
            sizeXs='12'
          >
            <CompanyMenuItem
              icon={item.icon}
              label={item.label}
              value={item.value}
              description={item.description}
              className='CompanyHomeContainer__menuItem'
              href={`/companies/${currentCompany.id}?view=${item.value}`}
              DialogComponent={item.DialogComponent}
              onClick={onClickItem}
              isShowCreateButton={isShowCreateButton}
              onClose={onClose}
            />
          </IonCol>
        )
      })
  }, [seachedList, currentCompany.id, onClickItem, isShowCreateButton, onClose])

  useLayoutEffect(() => {
    const searchElement = document.querySelector(
      'div.CompanyHomeContainer input',
    )
    if (searchElement) {
      searchElement.focus()
    }
  }, [])

  return (
    <div className='CompanyHomeContainer__container'>
      <div className='CompanyHomeContainer__searchBar'>
        <ContainerSearchBar
          searchBarValue={searchValue}
          onSearchBarChange={onSearch}
          searchBarPlaceholder='Search...'
          searchBarParent='CompanyHomeContainer'
        />

        <div className='CompanyHomeContainer__sortIcons'>
          <ToolTipOverlay placement='top' content='Sort'>
            <span style={{ marginLeft: 8 }} onClick={onChangeSortingDirection}>
              {renderSortingDirection}
            </span>
          </ToolTipOverlay>
        </div>
      </div>
      <div className='CompanyHomeContainer__itemsContainer'>
        <IonGrid>
          <IonRow>{renderCompanySubmenu}</IonRow>
        </IonGrid>
      </div>
    </div>
  )
}

export default CompanyHomeContainer
