import { useState, useCallback, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useConfirmationProvider } from '~/contexts'

import {
  INVOICE_PANELS,
  INVOICE_SOURCE,
  INVOICE_STATUS,
} from '~/constants/invoice-type'
import {
  selectInvoiceUiState,
  selectMyCurrentCompany,
  selectCurrentScope,
  selectBillLinesFilters,
} from '~/redux/selectors'
import { toast } from 'react-toastify'
import { pdf } from '@react-pdf/renderer'
import { PdfInvoicePage } from '~/containers/invoices/PdfInvoicePage'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { toBase64 } from '~/utils'
import { EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { EInvoiceStatus } from '~/types/enums/EInvoice'
import {
  useQueryBillLines,
  useQueryCompanies,
  useQueryInvoices,
  useQuerySellerProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'

const useInvoiceContainer = id => {
  const { currentPanels } = useSelector(selectInvoiceUiState)
  const currentScope = useSelector(selectCurrentScope)

  const statusField = currentScope === EScope.buyer ? 'status' : 'sellerStatus'

  const currentCompany = useSelector(selectMyCurrentCompany)
  const filterData = useSelector(selectBillLinesFilters)

  const { terminalsData } = useQueryTerminals()

  const { sellerCompanyOptions } = useQueryCompanies({})

  const {
    invoicesData: [invoice],
    isLoadingInvoicesData,
    updateInvoice,
    invoicesQueryKey,
    isInvoicesDataFetching,
  } = useQueryInvoices(
    {
      id,
    },
    { enabled: Boolean(id) },
  )

  const { billLinesData, updateBillLine } = useQueryBillLines({
    filters: {
      ...filterData,
      invoiceId: id,
    },
    perPage: 1000,
  })
  const { sellerProducts } = useQuerySellerProducts()

  const [showPdfViewer, setShowPdfViewer] = useState(false)
  const [pdfFile, setPdfFile] = useState(null)

  const splitRef = useRef()
  const splitCompareRef = useRef()

  const { confirmation } = useConfirmationProvider()

  const invoiceSource = useMemo(() => invoice?.source, [invoice])
  const invoiceStatus = useMemo(() => invoice?.status, [invoice])
  const sellerStatus = useMemo(() => invoice?.sellerStatus, [invoice])

  const isResolved = useMemo(() => {
    invoiceSource === INVOICE_SOURCE.invoice &&
      invoiceStatus &&
      (invoiceStatus === INVOICE_STATUS.approved ||
        invoiceStatus === INVOICE_STATUS.rejected ||
        invoiceStatus === INVOICE_STATUS.exported)
  }, [invoiceStatus, invoiceSource])

  const isSplitView = useMemo(
    () =>
      currentPanels.includes(INVOICE_PANELS.INVOICE) &&
      currentPanels.includes(INVOICE_PANELS.TICKETS),
    [currentPanels],
  )

  const saveInvoice = useCallback(
    payload => {
      apiClient.invoices
        .update(payload.id, payload)
        .then(response => {
          updateInvoice(response.id, response)
          toast.success(toastMessages.updateSuccess)
        })
        .catch(err => {
          toast.error(toastMessages.updateError)
          console.log(err)
        })
    },
    [updateInvoice],
  )

  const approvalControlsClick = useCallback(
    async (status, revert = false) => {
      let alertString = `Are you sure you want to change the status of this invoice to ${status}?`
      if (revert) {
        alertString = `Are you sure you want to revert this invoice to ${status} status?`
      }

      const result = await confirmation({
        message: alertString,
      })
      if (result === EYesNo.Yes) {
        approvalControlsConfirm(status)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [approvalControlsConfirm],
  )

  const createPDF = useCallback(
    () => (
      <PdfInvoicePage
        invoice={invoice}
        billLines={billLinesData}
        sellerProducts={sellerProducts}
        terminals={terminalsData}
        commonSellerOptions={sellerCompanyOptions}
        currentCompany={currentCompany}
      />
    ),
    [
      sellerProducts,
      currentCompany,
      invoice,
      sellerCompanyOptions,
      terminalsData,
      billLinesData,
    ],
  )

  const approvalControlsConfirm = useCallback(
    async status => {
      if (status === 'Approved') {
        const PDF = createPDF()
        const pdfBase64 = await pdf(PDF).toBlob()
        const dataFile = await toBase64(pdfBase64)
        const files = []
        const today = new Date().toISOString()
        files.push({
          file: dataFile,
          fileName: `CONCORD_INVOICE_${id}_${today}`,
          fileTye: 'pdf',
          field: 'invoice_prove',
        })
        const payload = { id, files, status }
        saveInvoice(payload)
      } else {
        saveInvoice({ id, status })
      }
    },
    [createPDF, id, saveInvoice],
  )

  const onSaveBillLine = useCallback(
    payload => updateBillLine(payload),
    [updateBillLine],
  )

  const onShowBillLineDetail = useCallback(
    billLine => {
      splitRef && splitRef.current.onShowBillLine(billLine)
    },
    [splitRef],
  )

  const onGetParamsFilter = useCallback(() => {
    apiClient.invoices.update(id, {
      [statusField]: EInvoiceStatus.Exported,
    })
    updateInvoice(id, { [statusField]: EInvoiceStatus.Exported })
    return `&filters[id]=${id}`
  }, [id, statusField, updateInvoice])

  const onPreviewPdf = useCallback(async () => {
    const PDF = createPDF()
    const pdfBase64 = await pdf(PDF).toBlob()

    const url = URL.createObjectURL(pdfBase64)

    setPdfFile(url)
  }, [createPDF])

  const onClosePdfPreview = useCallback(() => {
    setPdfFile(null)
  }, [])

  return {
    approvalControlsClick,
    invoice,
    invoiceStatus,
    isResolved,
    isSplitView,
    onGetParamsFilter,
    onSaveBillLine,
    onShowBillLineDetail,
    saveInvoice,
    sellerStatus,
    setShowPdfViewer,
    showPdfViewer,
    splitCompareRef,
    splitRef,
    onPreviewPdf,
    onClosePdfPreview,
    pdfFile,
    isLoadingInvoicesData,
    updateInvoice,
    invoicesQueryKey,
    isInvoicesDataFetching,
  }
}

export default useInvoiceContainer
