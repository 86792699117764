import React, { useState, useEffect, useMemo, useRef } from 'react'
import { selectMyCurrentCompany, selectIsScopeSeller } from '~/redux/selectors'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import SelectItemId from './SelectItemId'
import { useQueryCompanies } from '~/hooks/useQueryData'

const ITEM_TYPES = [
  { value: 'Company', label: 'Seller' },
  { value: 'BuyerTerminal', label: 'Buyer Terminal' },
  { value: 'SellerTerminal', label: 'Seller Terminal' },
  { value: 'SellerProduct', label: 'Seller Product' },
]

const ReferenceNamesForm = ({ onSubmit, defaultReferenceName, onClose }) => {
  const [form, setForm] = useState({
    buyerId: defaultReferenceName?.buyerId,
    sellerId: defaultReferenceName?.sellerId,
    itemType: defaultReferenceName?.itemType,
    itemId: defaultReferenceName?.itemId,
  })

  const myCompany = useSelector(selectMyCurrentCompany)
  const isSeller = useSelector(selectIsScopeSeller)
  const { sellerCompanyOptions, buyerCompanyOptions } = useQueryCompanies({})

  const formRef = useRef(null)

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const myCompanyOption = useMemo(
    () => [
      {
        label: `${myCompany.code} - ${myCompany.name}`,
        value: myCompany.id,
      },
    ],
    [myCompany],
  )

  const handleSubmit = e => {
    e.preventDefault()

    const values = {
      buyer_id: form.buyerId,
      seller_id: form.sellerId,
      item_type: form.itemType,
      item_id: form.itemId,
      reference_name: form.referenceName,
      reference_code: form.referenceCode,
    }

    onSubmit && onSubmit(values)
  }

  useEffect(() => {
    setForm({
      buyerId: defaultReferenceName.buyerId,
      sellerId: defaultReferenceName.sellerId,
      itemType: defaultReferenceName.itemType,
      itemId: defaultReferenceName.itemId,
      referenceName: defaultReferenceName.referenceName,
      referenceCode: defaultReferenceName.referenceCode,
    })
  }, [defaultReferenceName])

  const onClickClose = () => {
    onClose && onClose()
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Form.Group size='lg' className='m-3'>
        <Form.Label>Item Type</Form.Label>
        <Form.Select
          name='itemType'
          placeholder='Enter item type'
          size='lg'
          className='mb-2'
          value={form.itemType}
          onChange={e => setField('itemType', e.target.value)}
        >
          <option value={null} key={'item-type--option-null'}>
            {' '}
            -
          </option>
          {ITEM_TYPES.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <SelectItemId
        itemType={_.startCase(form.itemType)}
        itemId={form.itemId}
        // disabled={defaultReferenceName.itemId}
        onChange={value => setField('itemId', value)}
      />

      <Form.Group className='m-3'>
        <Form.Label>Buyer</Form.Label>
        <Form.Select
          as='select'
          name='buyerId'
          placeholder='Select Buyer'
          size='lg'
          value={form.buyerId}
          onChange={e => setField('buyerId', e.target.value)}
          disabled={defaultReferenceName?.buyerId}
        >
          <option value={null} key={'buyer-option-null'}>
            -
          </option>
          {(isSeller ? buyerCompanyOptions : myCompanyOption).map(o => (
            <option value={o.value} key={`buyer-option-${o.value}`}>
              {o.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className='m-3' controlId='seller_id'>
        <Form.Label>Seller</Form.Label>
        <Form.Select
          as='select'
          name='sellerId'
          placeholder='Select Seller'
          size='lg'
          value={form.sellerId}
          onChange={e => setField('sellerId', e.target.value)}
          disabled={defaultReferenceName?.sellerId}
        >
          <option value={null} key={'seller-option-null'}>
            -
          </option>
          {(isSeller ? myCompanyOption : sellerCompanyOptions).map(o => (
            <option value={o.value} key={`seller-option-${o.value}`}>
              {o.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className='m-3' controlId='reference_name'>
        <Form.Label>Reference Name</Form.Label>
        <Form.Control
          name='referenceName'
          type='text'
          placeholder='Enter reference name'
          size='lg'
          value={form.referenceName}
          onChange={e => setField('referenceName', e.target.value)}
        />
      </Form.Group>

      <Form.Group className='m-3' controlId='reference_code'>
        <Form.Label>Reference Code</Form.Label>
        <Form.Control
          name='referenceCode'
          type='text'
          placeholder='Enter reference code'
          size='lg'
          value={form.referenceCode}
          onChange={e => setField('referenceCode', e.target.value)}
        />
      </Form.Group>

      <div className='m-3 d-grid gap-2'>
        <Button variant='primary' type='submit' size='lg'>
          Submit
        </Button>
      </div>
      <div className='m-3 d-grid gap-2'>
        <Button onClick={onClickClose} variant='danger' size='lg'>
          Close
        </Button>
      </div>
    </Form>
  )
}

export default ReferenceNamesForm
