import { useController } from 'react-hook-form'

import { ConcordFormDatePicker } from '~/components/shared'

function ConcordTextFieldWithFormControl(props) {
  const { name, control, onChange, ...datePickerProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  return (
    <ConcordFormDatePicker
      {...datePickerProps}
      name={name}
      date={field.value}
      onChange={selectedDate => {
        field.onChange(selectedDate)
        onChange && onChange(selectedDate)
      }}
      error={fieldState.error?.message}
    />
  )
}

ConcordTextFieldWithFormControl.defaultProps = {
  portalId: 'root-portal',
}

export default ConcordTextFieldWithFormControl
