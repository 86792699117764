import clsx from 'clsx'
import React from 'react'
import { EditIcon, PlusIcon, ToolTipOverlay } from '~/components/shared'

export interface ICreateOrEditButtonProps {
  canEdit: boolean
  className?: string
  tooltipContent?: string
  style?: React.CSSProperties
  onClickEdit?: () => void
  onClickCreate?: () => void
}

function CreateOrEditButton(props: ICreateOrEditButtonProps) {
  const {
    canEdit,
    className,
    style,
    tooltipContent,
    onClickEdit,
    onClickCreate,
  } = props
  return (
    <ToolTipOverlay content={tooltipContent} placement='top'>
      <span
        className={clsx('clickable', className)}
        style={style}
        onClick={canEdit ? onClickEdit : onClickCreate}
      >
        {canEdit ? (
          <EditIcon color='var(--bs-warning)' />
        ) : (
          <PlusIcon color='var(--bs-primary)' />
        )}
      </span>
    </ToolTipOverlay>
  )
}

export default CreateOrEditButton
