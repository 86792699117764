import { useCallback, useState, useEffect } from 'react'
import useFuzzy from '~/hooks/useFuzzy'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { produce } from 'immer'
import ReferenceNamesForm from './ReferenceNamesForm'
// import { getObjectKeyFromColumn } from '~/utils'
import Loading from '../Loading'
import ReferenceNameCard from './ReferenceNameCard'
import { ContainerSearchBar, Button } from '~/components/shared'

import './ReferenceNamesDisplay.scss'
import { apiClient } from '~/api/ApiClient'

const EMPTY_REFERENCE = {
  id: null,
  buyerId: null,
  sellerId: null,
  itemId: null,
  itemType: null,
  referenceCode: null,
  referenceName: null,
  showForm: true,
}

// const REFERENCE_NAME_TYPES = [
//   'seller',
//   'BuyerTerminal',
//   'sellerTerminal',
//   'SellerProduct',
// ]

const ReferenceNamesDisplay = ({ doc }) => {
  // const populateReferenceName = useCallback(() => {
  //   const key = getObjectKeyFromColumn(fileType, columnName, 'idKey')
  //   const itemType = REFERENCE_NAME_TYPES.find(r => r == key.slice(0, -2))

  //   return {
  //     id: doc.id,
  //     buyerId: doc.buyerId,
  //     sellerId: doc.sellerId,
  //     itemType: itemType,
  //     itemId: doc[key],
  //     showForm: true,
  //   }
  // }, [columnName, fileType, doc])
  const [loading, setLoading] = useState(false)
  const [referenceNames, setReferenceNames] = useState([])
  const [selectedReferenceName, setSelectedReferenceName] = useState({})

  const {
    seachedList: searchedReferences,
    onSearch,
    searchValue,
  } = useFuzzy(referenceNames, {
    keys: ['referenceCode', 'referenceName'],
  })

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const req = {
          filters: {
            buyerId: doc.buyerId,
            sellerId: doc.sellerId,
          },
        }
        const res = await apiClient.referenceNames.get(req)
        setReferenceNames([EMPTY_REFERENCE, ...res.referenceNames])
      } catch {
        console.log('error')
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [doc])

  const updateReferenceName = useCallback(
    async formValues => {
      try {
        const { referenceName, errors } = selectedReferenceName?.id
          ? await apiClient.referenceNames.update(selectedReferenceName.id, {
              referenceName: formValues,
            })
          : await apiClient.referenceNames.create({
              referenceName: formValues,
            })
        if (_.size(errors) > 0) {
          toast.error(toastMessages.updateError)
        } else {
          setReferenceNames(prev =>
            produce(prev, draft => {
              const { id } = referenceName
              const index = draft.findIndex(refName => refName.id === id)
              if (index !== -1) {
                draft[index] = referenceName
              } else {
                draft.push(referenceName)
                setSelectedReferenceName({ showForm: false })
              }
            }),
          )
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        toast.success(toastMessages.updateSuccess)
      }
    },
    [selectedReferenceName.id],
  )

  const deleteReferenceName = useCallback(async id => {
    try {
      const { errors } = await apiClient.referenceNames.delete(id)
      if (_.size(errors) > 0) {
        toast.error(toastMessages.deleteError)
      } else {
        setSelectedReferenceName({ showForm: false })
        setReferenceNames(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(refName => refName.id === id)
            if (index !== -1) {
              draft.splice(index, 1)
              toast.success(toastMessages.deleteSuccess)
            } else {
              toast.error(toastMessages.deleteError)
            }
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.deleteError)
    }
  }, [])

  const closeForm = useCallback(() => {
    setSelectedReferenceName({ showForm: false })
  }, [])

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div>
              <ContainerSearchBar
                containerClassName='ReferenceNamesDisplay__containerSearchbar'
                searchBarValue={searchValue}
                onSearchBarChange={onSearch}
              />
              <Button
                onClick={() => setSelectedReferenceName(EMPTY_REFERENCE)}
                label='Create new reference'
                expand='full'
                size='small'
                loading={loading}
              />
            </div>

            {loading && <Loading />}

            {searchedReferences &&
              searchedReferences.map(
                (rn, index) =>
                  (rn.referenceCode || rn.referenceName) && (
                    <ReferenceNameCard
                      key={index}
                      referenceName={rn}
                      editReferenceName={() =>
                        setSelectedReferenceName({ ...rn, showForm: true })
                      }
                      deleteReferenceName={() => deleteReferenceName(rn.id)}
                      editing={selectedReferenceName.id == rn.id}
                    />
                  ),
              )}
          </Col>
          {selectedReferenceName.showForm && (
            <Col>
              <ReferenceNamesForm
                onSubmit={updateReferenceName}
                defaultReferenceName={selectedReferenceName}
                onClose={closeForm}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default ReferenceNamesDisplay
