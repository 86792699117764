import React from 'react'
import usePricingsTable from './usePricingsTable'

import {
  BranchCreateIcon,
  DeleteIcon,
  DialogHierarchyRowForm,
  DialogPricingForm,
  EditIcon,
  RefundIcon,
  ReusableTable,
} from '~/components/shared'

import type { IPricingsTableProps } from './type'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { moduleName } from '~/utils/constants'
import { DialogPriceChange } from '../DialogPriceChange'
import DialogHierarchiesForm from '~/containers/invoices/AutomaticChargesDialog/DialogHierarchiesForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'

function PricingsTable(props: IPricingsTableProps, ref: any) {
  const {
    columns,
    formModalState,
    windowSize,
    pricingsData,
    isLoadingPricings,
    filterOptions,
    columnFilters,
    className,
    toolbarProps,
    billLineData,
    invoiceData,
    updateForm,
    includeInvoiceId,
    pricingChangeModal,
    hierarchyRowsData,
    hierarchiesData,
    hierarchiesForm,
    onOpenCreateFormModal,
    onCloseFormModal,
    afterCreate,
    afterUpdate,
    onRemove,
    onCellEditEnd,
    setColumnFilters,
    onShowUpdateForm,
    onClosePricingUpdateForm,
    setPricingChangeModal,
    afterPriceChanges,
    onOpenHierarchiesForm,
    onCloseHierarchiesForm,
    afterCreateHierarchies,
  } = usePricingsTable(props)

  return (
    <>
      <div style={{ margin: 12 }} className={className}>
        <ReusableTable
          columns={columns}
          data={pricingsData}
          enableTopToolbar
          tableHeight={windowSize.height - 220}
          enableCompanyView
          enableRowActions
          manualFiltering
          ref={ref}
          companyViewProps={{
            name: moduleName.company.pricings,
            onClickTopButton: onOpenCreateFormModal,
          }}
          toolbarProps={toolbarProps}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 140,
            },
          }}
          state={{
            isLoading: isLoadingPricings,
            columnFilters,
            defaultColumnFilters: [
              {
                id: 'sellerProductId',
                value: billLineData?.sellerProductId,
              },
            ],
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
            sorting: [
              {
                id: 'amount',
                desc: true,
              },
              {
                id: 'startDate',
                desc: true,
              },
            ],
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
              onClick: onShowUpdateForm(row.original),
            },
            {
              icon: <BranchCreateIcon color='white' />,
              tooltipProps: {
                content: 'Create Hierarchy Row',
                placement: 'top',
              },
              color: 'purple',

              onClick: onOpenHierarchiesForm(row.original),
            },
            {
              icon: <RefundIcon />,
              tooltipProps: {
                content: 'Price Change',
              },
              color: 'info',
              onClick() {
                setPricingChangeModal({
                  isOpen: true,
                  pricing: row.original,
                })
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: onRemove(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
          onCellEditEnd={onCellEditEnd}
          filterOptions={filterOptions}
          onColumnFiltersChange={setColumnFilters}
        />
      </div>

      <DialogHierarchyRowForm
        isOpen={formModalState.isOpen}
        formData={formModalState.formData}
        onClose={onCloseFormModal}
        afterCreate={afterCreate}
        billLineData={billLineData}
        invoiceData={invoiceData}
        includeInvoiceId={includeInvoiceId}
      />

      <DialogPricingForm
        isOpen={updateForm.isOpen}
        formData={updateForm.formData}
        onClose={onClosePricingUpdateForm}
        afterUpdate={afterUpdate}
        includeInvoiceId={includeInvoiceId}
        invoiceId={invoiceData?.id}
        hierarchyItem={
          billLineData
            ? {
                sellerProductId: billLineData?.sellerProductId,
                lineDate: billLineData?.lineDate,
                sellerId: billLineData?.sellerId,
                sellerTerminalId: billLineData?.sellerTerminalId,
                buyerId: billLineData?.buyerId,
                buyerTerminalId: billLineData?.buyerTerminalId,
              }
            : undefined
        }
      />

      <DialogPriceChange
        isOpen={pricingChangeModal.isOpen}
        onClose={() => {
          setPricingChangeModal({
            isOpen: false,
            pricing: undefined,
          })
        }}
        pricing={pricingChangeModal.pricing}
        hierarchyRowsData={hierarchyRowsData}
        hierarchiesData={hierarchiesData}
        invoiceId={invoiceData?.id}
        afterPriceChanges={afterPriceChanges}
      />

      <DialogHierarchiesForm
        isOpen={hierarchiesForm.isOpen}
        onClose={onCloseHierarchiesForm}
        hierarchableType={EHierarchableType.Pricing}
        hierarchableId={hierarchiesForm.formData?.id}
        afterCreate={afterCreateHierarchies}
      />
    </>
  )
}

export default React.forwardRef(PricingsTable)
