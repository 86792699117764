import { useRef } from 'react'
import { MenuItem } from '@szhsin/react-menu'
import { Form } from 'react-bootstrap'
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import { DragIcon } from '~/components/shared'

export interface IColumnItemProps {
  checked: boolean
  label: string
  index: number
  canDrag?: boolean
  onChange: () => void
  onReorder: (currentIndex: number, nextIndex: number) => void
}

function ColumnItem(props: IColumnItemProps) {
  const { checked, label, onChange, index, onReorder, canDrag } = props
  const ref = useRef<HTMLDivElement>(null)

  const allowToDrag = canDrag && checked

  const [{ handlerId }, drop] = useDrop({
    accept: 'COLUMN_ITEM',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor) {
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect: any = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      onReorder(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_ITEM',
    item: () => {
      return { index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <MenuItem
      ref={allowToDrag ? ref : undefined}
      style={{ fontSize: 14, justifyContent: 'space-between', opacity }}
      data-handler-id={handlerId}
    >
      <Form>
        <Form.Check checked={checked} label={label} onChange={onChange} />
      </Form>
      {allowToDrag ? <DragIcon /> : null}
    </MenuItem>
  )
}

export default ColumnItem
