import moment from 'moment'

function isDateInRange(date, startTime, endTime) {
  const momentDate = moment(date)

  const momentStartTime = startTime
    ? moment(startTime).format('YYYY-MM-DD')
    : null
  const momentEndTime = endTime ? moment(endTime).format('YYYY-MM-DD') : null

  if (momentStartTime && momentEndTime) {
    return (
      momentDate.isBetween(momentStartTime, momentEndTime) ||
      momentDate.format('YYYY-MM-DD') === momentStartTime ||
      momentDate.format('YYYY-MM-DD') === momentEndTime
    )
  }

  if (momentStartTime) {
    return momentDate.isSameOrAfter(momentStartTime)
  }

  if (momentEndTime) {
    return momentDate.isSameOrBefore(momentEndTime)
  }

  return true
}

export default isDateInRange
