import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifySettings from './useModifySettings'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IGetSettingsParams, ISetting } from '~/types/models/ISetting'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQuerySettings = (
  params: Partial<IGetSettingsParams> = {},
  options?: Partial<UseQueryOptions<ISetting[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'settings',
      sessionUser?.id,
      buildGetUrl(apiClient.settings.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.settings.get(params)
      return response.settings
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const settingsData = useMemo(() => data || [], [data])

  const { addSetting, updateSetting, removeSetting } = useModifySettings(params)

  return {
    settingsData,
    isLoadingSettings: isLoading,
    addSetting,
    updateSetting,
    removeSetting,
  }
}

export default useQuerySettings
