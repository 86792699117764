import { useMemo } from 'react'
import { ConcordToolbar } from '~/components/shared'

import _ from 'lodash'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import './styles.scss'
import { EFieldType, ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import moment from 'moment'
import getDateLabelWhenExport from '~/utils/getDateLabelWhenExport'

export interface IRTTopToolbarProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
}

const RTTopToolbar = <TData extends IReusableTableRowData>(
  props: IRTTopToolbarProps<TData>,
) => {
  const { table } = props
  const { options } = table

  const state = table.getState()
  const columns = table.getAllColumns()
  const enableSection = options.enableRowSelection
  const columnVisibility = state.columnVisibility
  const enableSorting = options.enableSorting
  const overriddenSortOptions = options.overriddenSortOptions
  const defaultColumnFilters = state.defaultColumnFilters
  const currentPage =
    typeof state.pagination.pageIndex === 'number'
      ? state.pagination.pageIndex + 1
      : undefined
  const pageSize =
    typeof state.pagination.pageSize === 'number'
      ? state.pagination.pageSize
      : undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsExcepting = [
    ERTDisplayColumnId.select,
    ERTDisplayColumnId.actions,
    ERTDisplayColumnId.drag,
    ERTDisplayColumnId.expand,
  ]
  const columnOrder = state.columnOrder
  const grouping = useMemo(() => state.grouping || [], [state.grouping])

  const columnsId = columns.map(({ id }) => id)

  const groupingValues = useMemo(() => {
    const values: Record<string, boolean> = {}
    columnsId
      .filter(id => !id.includes('mrt'))
      .forEach(id => {
        values[id] = grouping.includes(id) ? true : false
      })

    return values
  }, [columnsId, grouping])

  const filterData = useMemo(() => {
    const filters: Record<string, any> = {}
    state.columnFilters.map(({ id, value }) => (filters[id] = value))
    return filters
  }, [state.columnFilters])

  const defaultFilterData = useMemo(() => {
    if (defaultColumnFilters?.length > 0) {
      const filters: Record<string, any> = {}
      defaultColumnFilters.map(({ id, value }) => (filters[id] = value))
      return filters
    }
    return undefined
  }, [defaultColumnFilters])

  const dateRangeOptionInFilter = useMemo(() => {
    if (options.filterOptions) {
      const endDate = options.filterOptions.find(
        ({ field }) => field === 'endDate',
      )
      if (endDate) {
        return endDate
      }
      return options.filterOptions.find(
        ({ type }) => type === EFieldType.dateRange,
      )
    }
    return undefined
  }, [options.filterOptions])

  const dateRangeValueInFilter = useMemo(() => {
    if (dateRangeOptionInFilter) {
      const { field } = dateRangeOptionInFilter
      const filter = filterData[field]
      if (filter) {
        return {
          startDate: filter.startDate,
          endDate: filter.endDate,
        }
      }
    }
    return undefined
  }, [dateRangeOptionInFilter, filterData])

  const dateFilterLabel = getDateLabelWhenExport(dateRangeValueInFilter)

  const csvFileName = useMemo(() => {
    const name = options.companyViewProps?.name || 'concord'
    const label = [
      _.snakeCase(name),
      dateFilterLabel,
      moment().format('YYYY-MM-DDTHH:mm:ss'),
      currentPage ? `page_${currentPage}` : '',
      pageSize ? `per_${pageSize}` : '',
    ]
      .filter(Boolean)
      .join('_')
    return label
  }, [currentPage, dateFilterLabel, options.companyViewProps?.name, pageSize])

  const filterOptions = useMemo(() => {
    const opts = columns
      .filter(({ columnDef }) => columnDef.filterVariant !== EFieldType.text)
      .map(({ columnDef }) => {
        return {
          field: columnDef.id,
          label: columnDef.header,
          type: columnDef.filterVariant,
          options: columnDef.filterSelectOptions,
        }
      })
    if (options.filterOptions) {
      return [...options.filterOptions, ...opts]
    }
    return opts
  }, [columns, options.filterOptions])

  const sortOptions = useMemo(
    () => {
      if (enableSorting) {
        return (
          overriddenSortOptions ||
          columns
            .filter(
              ({ columnDef }) =>
                (columnDef.enableSorting === undefined ||
                  columnDef.enableSorting) &&
                !columnsExcepting.includes(columnDef.id as ERTDisplayColumnId),
            )
            .map(({ columnDef }) => {
              const colSorted = state.sorting.find(
                ({ id }) => id === columnDef.accessorKey,
              )

              return {
                label: columnDef.header,
                sortField: columnDef.accessorKey,
                isAsc: !colSorted?.desc,
                sorted: Boolean(colSorted),
              }
            })
        )
      }
      return []
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, state.sorting, overriddenSortOptions],
  )

  const columnOptions = useMemo(
    () =>
      _(columns)
        .filter(
          ({ columnDef }) =>
            !columnsExcepting.includes(columnDef.id as ERTDisplayColumnId),
        )
        .map(({ columnDef }) => {
          let isHidden = false

          const order = columnOrder.findIndex(ord => ord === columnDef.id)

          if ((columnVisibility as any)[columnDef.accessorKey] === false) {
            isHidden = true
          }

          return {
            label: columnDef.header,
            field: columnDef.accessorKey,
            hide: isHidden,
            order,
          }
        })
        .orderBy(['order', 'asc'])
        .value(),
    [columnOrder, columnVisibility, columns, columnsExcepting],
  )

  const dataExported = useMemo(() => {
    try {
      if (options.data.length > 0) {
        const result: any[] = []
        options.data.forEach(item => {
          const obj: Record<string, any> = {}
          columns
            .filter(
              ({ columnDef }) =>
                !columnsExcepting.includes(columnDef.id as ERTDisplayColumnId),
            )
            .map(({ columnDef }) => {
              let value: any = _.get(item, columnDef.id)
              if (columnDef.accessorFn) {
                value = columnDef.accessorFn(item)
              }
              obj[columnDef.header] = value
            })
          result.push(obj)
        })
        return result
      }
      return []
    } catch (error) {
      console.log('error', error)
      return []
    }
  }, [columns, columnsExcepting, options.data])

  const onFilterChange = (filters: Record<string, any>) => {
    const newFilters = Object.keys(filters).map(field => ({
      id: field,
      value: filters[field],
    }))
    table.setColumnFilters(newFilters)
  }

  const onSortChange = (sortOpts: any) => {
    const newSortOpts = sortOpts
      .filter(({ sorted }: any) => sorted)
      .map((opt: any) => ({
        id: opt.sortField,
        desc: !opt.isAsc,
      }))
    table.setSorting(newSortOpts)
  }

  const onSearchBarChange = _.debounce((searchKey: string) => {
    table.setGlobalFilter(searchKey)
  }, 600)

  const onColumnChange = (newColumns: any[]) => {
    const newColumnOpts: Record<string, boolean> = {}
    newColumns.forEach(({ field, hide }) => {
      newColumnOpts[field] = !hide
    })
    const newColumnOrder = newColumns.map(({ field }) => field)
    if (enableSection) {
      newColumnOrder.unshift(ERTDisplayColumnId.select)
    }
    table.setColumnOrder(newColumnOrder)
    table.setColumnVisibility(newColumnOpts)
  }

  return (
    <ConcordToolbar
      className='RTTopToolbar__container'
      filterOptions={filterOptions}
      filterData={filterData}
      searchBarValue={state.globalFilter}
      onFilterChange={onFilterChange}
      onSearchBarChange={onSearchBarChange}
      sortOptions={sortOptions}
      onSortChange={onSortChange}
      columnOptions={columnOptions}
      onColumnChange={onColumnChange}
      exportingData={dataExported}
      filterSectionWidth={options.toolbarProps?.filterSectionWidth}
      isHiddenSearchBar={options?.toolbarProps?.isHiddenSearchBar}
      csvFileName={csvFileName}
      defaultFilterData={defaultFilterData}
      canGroup={options.enableGrouping}
      groupValues={groupingValues}
      setGroupValues={(callback: any) => {
        const newValues =
          typeof callback === 'function' ? callback(groupingValues) : callback
        const newGroupingValues = Object.keys(newValues)
          .filter(key => newValues[key])
          .map(key => key)
        table.setGrouping(newGroupingValues)
      }}
    />
  )
}

export default RTTopToolbar
