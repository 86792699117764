import { DEFAULT_ICON_SIZE } from './constants'
import type { ISvgIconProps } from './type'

const AddColumnIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      width={size}
      height={size}
      viewBox='0 0 48 48'
      fill={color}
      {...svgProps}
    >
      <path d='M42 22h-4v-4c0-.5-.2-1.1-.6-1.4-.4-.4-.9-.6-1.4-.6-1.1 0-2 .9-2 2v4h-4c-.5 0-1 .2-1.4.6-.4.3-.6.9-.6 1.4 0 1.1.9 2 2 2h4v4c0 .5.2 1.1.6 1.4.4.4.9.6 1.4.6 1.1 0 2-.9 2-2v-4h4c.5 0 1-.2 1.4-.6.4-.3.6-.9.6-1.4 0-1.1-.9-2-2-2zm-6 14h-3c-2.2 0-4-1.8-4-4v-2h-1c-1.2 0-2.4-.5-3.1-1.5-.2-.2-.5-.3-.7-.1-.1.1-.2.2-.2.4V44h11c1.6.1 2.9-1.2 3-2.8V38c0-1.1-.9-2-2-2zM35 4H24v15.2c0 .3.2.5.5.5.2 0 .3-.1.4-.2.7-1 1.9-1.5 3.1-1.5h2v-2c0-2.2 1.8-4 4-4h2c1.1 0 2-.9 2-2V7c.1-1.6-1.2-2.9-2.8-3H35zM6 7v34c-.1 1.6 1.2 2.9 2.8 3H20V4H9c-1.6-.1-2.9 1.2-3 2.8V7z' />
    </svg>
  )
}
export default AddColumnIcon
