import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyHierarchyRowsMatches from './useModifyHierarchyRowsMatches'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetHierarchyRowMatchesParams,
  IHierarchyRowMatch,
} from '~/types/models/IHierarchyRow'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { useQuerySellerProducts } from '../useQuerySellerProducts'

const useQueryHierarchyRowsMatches = (
  params: IGetHierarchyRowMatchesParams,
  options?: Partial<UseQueryOptions<IHierarchyRowMatch[]>> & {
    includeSellerProducts?: boolean
    additionalKeys?: string[]
  },
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched, isFetching } = useQuery({
    queryKey: [
      'hierarchyRowsMatches',
      sessionUser?.id,
      buildGetUrl(`${apiClient.hierarchyRows.endpoint}/matches`, params, {
        includeUndefined: true,
      }),
      ...(options?.additionalKeys || []),
    ],
    async queryFn() {
      const response = await apiClient.hierarchyRows.getMatches(params)

      if (params.hierarchyRow.sellerProductIds) {
        const results = (response as any).flatMap((item: any) => {
          const key = Object.keys(item)[0]
          return item[key].matches
        })

        return results
      }

      return response.matches.filter(Boolean)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { updateHierarchyRowMatched } = useModifyHierarchyRowsMatches(
    params,
    options,
  )

  const hierarchyRowMatchesData = useMemo(() => data || [], [data])

  const hierarchableMatches = hierarchyRowMatchesData.map(
    ({ hierarchable, ...item }) => (hierarchable ? hierarchable : item),
  )

  const sellerProductIds = hierarchyRowMatchesData
    .map(({ hierarchable }) => {
      return hierarchable?.sellerProductId
    })
    .filter(Boolean)

  const { sellerProducts, isLoadingSellerProducts, updateSellerProduct } =
    useQuerySellerProducts(
      {
        filters: {
          id: sellerProductIds,
        },
      },
      {
        enabled:
          sellerProductIds.length > 0 &&
          options?.includeSellerProducts &&
          isFetched,
        additionalKeys: options?.additionalKeys,
      },
    )

  return {
    hierarchyRowMatchesData,
    isLoadingHierarchyRowMatches: isLoading,
    sellerProducts,
    isLoadingSellerProducts,
    hierarchableMatches,
    isHierarchyRowMatchesFetched: isFetched,
    isHierarchyRowMatchesFetching: isFetching,
    updateHierarchyRowMatched,
    refetchHierarchyRowsMatches: refetch,
    updateSellerProduct,
  }
}

export default useQueryHierarchyRowsMatches
