import { createSlice } from '@reduxjs/toolkit'
import { BILL_LINES_COLUMNS } from '~/constants/invoice-type'

export const billLinesSlice = createSlice({
  name: 'billLines',
  initialState: {
    columns: BILL_LINES_COLUMNS,
    filters: {
      lineDate: {},
      buyerTerminalId: [],
      sellerProductId: [],
      qty: {},
      price: {},
      total: {},
      tax: {},
      materialExtPrice: {},
      tagId: [],
      unmatchedFilter: 'null',
    },
  },
  reducers: {
    updateUiColumn: (state, action) => {
      let columns = state['columns']
      let { column } = action.payload
      // Object.entries(action.payload).forEach((column) => {
      let updateIndex = columns.findIndex(
        columnState => columnState.columnName == column.columnName,
      )
      state['columns'][updateIndex] = {
        ...state['columns'][updateIndex],
        ...column,
      }
    },
    updateUiColumns: (state, action) => {
      let columns = state['columns']
      action.payload.forEach(column => {
        let updateIndex = columns.findIndex(
          columnState => column.field == columnState.field,
        )
        if (updateIndex === -1) {
          state['columns'].push({ ...column })

          return
        }

        state['columns'][updateIndex] = {
          ...state['columns'][updateIndex],
          ...column,
        }
      })
    },
    updateUiFilter: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state['filters'][`${key}`] = value
      }
      state['dataLoaded'] = false
    },
    updateUiPerPage: (state, action) => {
      let maxPage = Math.ceil(state['count'] / action.payload)
      if (state['page'] > maxPage) {
        state['page'] = maxPage
        state['pageCount'] = maxPage
      }
      state['perPage'] = action.payload
      state['dataLoaded'] = false
    },
  },
})

export default billLinesSlice
