import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import InvoiceStatusChip from '~/components/invoices/InvoiceStatusChip'
import { selectCurrentScope } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { useQueryFlags, useQueryInvoices } from '~/hooks/useQueryData'
import { EFlagFlagableType } from '~/types/enums/EFlag'
import { DropdownWithCustomChildren } from '~/components/shared'
import { EInvoiceStatus } from '~/types/enums/EInvoice'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import './InvoiceBodyHeaderStatusTerminalCol.scss'
import { InvoiceEmailButton } from '~/components/invoices/Table/InvoiceEmailButton'

const InvoiceBodyHeaderStatusTerminalCol = ({ invoice }) => {
  const [isLoading, setIsLoading] = useState(false)

  const currentScope = useSelector(selectCurrentScope)
  const statusField = currentScope === EScope.buyer ? 'status' : 'sellerStatus'

  const { flagsData } = useQueryFlags(
    {
      filters: {
        flagableType: [EFlagFlagableType.Invoice],
        flagableId: invoice?.id,
      },
    },
    { enabled: Boolean(invoice?.id) },
  )

  const { updateInvoice } = useQueryInvoices(
    {
      id: invoice?.id,
    },
    { enabled: Boolean(invoice?.id) },
  )

  const status = invoice ? invoice[statusField] : null

  const statusOptions = useMemo(() => {
    if (currentScope === EScope.buyer) {
      return [
        EInvoiceStatus.Progress,
        EInvoiceStatus.Uploaded,
        EInvoiceStatus.Processed,
        EInvoiceStatus.Approved,
        EInvoiceStatus.Rejected,
        EInvoiceStatus.Paid,
        EInvoiceStatus.Locked,
      ].map(val => ({
        label: val,
        value: val,
      }))
    }
    let options = []
    const statusVal = invoice?.sellerStatus

    if (statusVal === EInvoiceStatus.Progress) {
      options = [EInvoiceStatus.Approved, EInvoiceStatus.Locked]
    } else if (statusVal === EInvoiceStatus.Approved) {
      options = [EInvoiceStatus.Progress, EInvoiceStatus.Exported]
    } else if (statusVal === EInvoiceStatus.Exported) {
      options = [EInvoiceStatus.Approved]
    } else if (statusVal === EInvoiceStatus.Locked) {
      options = [EInvoiceStatus.Progress, EInvoiceStatus.Approved]
    }

    return options.map(val => ({
      label: val,
      value: val,
    }))
  }, [currentScope, invoice?.sellerStatus])

  const onUpdateInvoiceStatus = async newStatus => {
    setIsLoading(true)
    try {
      const res = await apiClient.invoices.update(invoice.id, {
        [statusField]: newStatus,
      })
      updateInvoice(res.id, res)
      toast.success(toastMessages.updateSuccess)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ display: 'flex', marginTop: 6, alignItems: 'center' }}>
      <DropdownWithCustomChildren
        options={statusOptions}
        className='make-custom-dropdown-inline no-hover'
        onChange={(event, { value }) => {
          onUpdateInvoiceStatus(value)
        }}
        isLoading={isLoading}
      >
        <InvoiceStatusChip
          status={status}
          invoice={invoice}
          flagsData={flagsData}
          hideEmailButton
        />
      </DropdownWithCustomChildren>

      {invoice && (
        <InvoiceEmailButton
          invoice={invoice}
          style={{ marginLeft: 4, height: 23.75 }}
          variant={invoice.emailAt ? 'success' : 'dark'}
        />
      )}
    </div>
  )
}

export default InvoiceBodyHeaderStatusTerminalCol
