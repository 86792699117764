import { useCallback } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { apiClient } from '~/api/ApiClient'

const BillLinesTableRowModal = props => {
  const { showRefTerminalModal, modalMessage, setRefTerminalModal } = props

  const billLine = modalMessage.billLine

  const handleUpdateCurrentBillLine = useCallback(() => {
    setRefTerminalModal(false)
  }, [setRefTerminalModal])

  const handleUpdateAllBillLines = useCallback(() => {
    let referenceType
    switch (modalMessage.field) {
      case 'Buyer Terminal':
        referenceType = 'BuyerTerminal'
        break
      case 'Seller Terminal':
        referenceType = 'SellerTerminal'
        break
      case 'Buyer Seller Product':
        referenceType = 'Product'
        break
      default:
        throw new Error('Invalid bill line field')
    }

    const referenceSellerId = billLine.sellerId
      ? billLine.sellerId
      : modalMessage.selectedId

    apiClient.billLines
      .update(billLine.id, {
        referenceUpdate: {
          seller_id: referenceSellerId,
          reference_name: modalMessage.currentLabel,
          reference_code: modalMessage.currentCode,
          // reference_code: modalMessage.selected_code,
          item_id: modalMessage.selectedId,
          item_type: referenceType,
        },
      })
      .then(() => {
        setRefTerminalModal(false)
      })
  }, [
    billLine?.id,
    billLine?.sellerId,
    modalMessage.currentCode,
    modalMessage.currentLabel,
    modalMessage.field,
    modalMessage.selectedId,
    setRefTerminalModal,
  ])

  const ModalBody = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Correcting data!</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '13px' }}>
        Our system read <b>{modalMessage.currentLabel}</b> from the ticket.
        Would you like to refer this to the following{' '}
        <b>{modalMessage.field}</b> for all future cases? <br /> <br />
        <Row>
          <div style={{ display: 'inline-block' }}>
            Code: <b>{modalMessage.selected_code}</b>
          </div>
        </Row>
        <Row>
          <div style={{ display: 'inline-block' }}>
            Name: <b>{modalMessage.selected_name}</b>
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={handleUpdateCurrentBillLine}>
          {' '}
          Correct this bill line only
        </Button>
        <Button
          variant='primary'
          onClick={handleUpdateAllBillLines}
          style={{ marginRight: '10px' }}
        >
          {' '}
          Correct this and future bill lines
        </Button>
      </Modal.Footer>
    </>
  )

  return (
    <Modal
      className='reference-modal'
      show={showRefTerminalModal}
      backdrop={false}
      onHide={() => setRefTerminalModal(false)}
    >
      <ModalBody />
    </Modal>
  )
}

export default BillLinesTableRowModal
