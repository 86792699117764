import { useCallback, useMemo } from 'react'

import { Page, View, Document, StyleSheet } from '@react-pdf/renderer'
import { PDFDataTable, PDFInvoiceHeader } from '~/components/shared'

import moment from 'moment'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: window.innerHeight,
    paddingBottom: 60,
  },
  page: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingTop: 8,
  },
  container: {
    fontSize: 11,
  },
})

function PdfInvoicePage(props) {
  const {
    invoice,
    billLines,
    sellerProducts,
    terminals,
    commonSellerOptions,
    currentCompany,
  } = props

  const sellerCompany = useMemo(() => {
    const company = (commonSellerOptions || []).find(
      ({ value }) => value === invoice.sellerId,
    )

    return company
  }, [commonSellerOptions, invoice?.sellerId])

  const sellerTerminal = useMemo(() => {
    return terminals.find(({ id }) => id === invoice.sellerTerminalId)
  }, [invoice?.sellerTerminalId, terminals])

  const buyerTerminal = useMemo(() => {
    return terminals.find(({ id }) => id === invoice.buyerTerminalId)
  }, [invoice?.buyerTerminalId, terminals])

  const totalQty = useMemo(() => {
    return billLines
      .reduce((total, { qty }) => total + Number(qty), 0)
      .toFixed(2)
  }, [billLines])

  const totalPrice = useMemo(() => {
    const total = billLines
      .reduce((total, item) => total + Number(item.total), 0)
      .toFixed(4)

    return formatCurrencyToDollar.format(total)
  }, [billLines])

  const formatDataWithoutYear = useCallback(date => {
    if (date) {
      return moment(date).format('M-D')
    }

    return '  '
  }, [])

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.container}>
          <PDFInvoiceHeader
            seller={{
              name: sellerCompany?.name,
            }}
            buyer={{
              name: currentCompany?.name,
            }}
            sellerTerminal={sellerTerminal}
            buyerTerminal={buyerTerminal}
            rawSellerTerminal={
              invoice?.sellerTerminalCode || invoice?.sellerTerminalName
            }
            rawBuyerTerminal={
              invoice?.buyerTerminalCode || invoice?.buyerTerminalName
            }
            totalQty={totalQty}
            totalPrice={totalPrice}
            startDate={invoice.startDate}
            endDate={invoice.endDate}
            dueDate={invoice.dueDate}
          />

          <View>
            <PDFDataTable
              data={billLines}
              isHiddenFooter={false}
              renderFooterContent={() => {
                return `Total: ${totalPrice}`
              }}
              columns={[
                {
                  Header: '#',
                  accessor: 'index',
                  align: 'center',
                  valueGetter({ index }) {
                    return index + 1
                  },
                  width: 35,
                },
                {
                  Header: 'Date',
                  accessor: 'lineDate',
                  align: 'center',
                  width: 60,
                  valueGetter({ rowData }) {
                    return formatDataWithoutYear(rowData.lineDate)
                  },
                },
                {
                  Header: 'Ticket #',
                  accessor: 'ticketNum',
                  width: 90,
                  align: 'right',
                },
                {
                  Header: 'Product',
                  accessor: 'sellerProductId',
                  width: 150,
                  valueGetter({ rowData }) {
                    const { sellerProductId } = rowData
                    const sellerProduct = sellerProducts.find(
                      ({ value }) => value === sellerProductId,
                    )
                    return sellerProduct?.label
                  },
                },
                {
                  Header: 'Qty',
                  accessor: 'qty',
                  width: 80,
                  align: 'right',
                },
                {
                  Header: 'Buyer Terminal',
                  accessor: 'buyerTerminalId',
                  align: 'center',
                  width: 100,
                  valueGetter({ rowData }) {
                    const { buyerTerminalId } = rowData
                    const terminal = terminals.find(
                      ({ id }) => id === buyerTerminalId,
                    )
                    return terminal?.code
                  },
                },
                {
                  Header: 'Total',
                  accessor: 'total',
                  align: 'right',
                  width: 100,
                  valueGetter({ rowData }) {
                    return formatCurrencyToDollar.format(rowData.total)
                  },
                },
              ]}
            />
          </View>
        </View>
      </Page>
    </Document>
  )
}

PdfInvoicePage.propTypes = {}

export default PdfInvoicePage
