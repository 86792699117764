import React, { useImperativeHandle, useEffect } from 'react'
import { pdfjs } from 'react-pdf'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import clsx from 'clsx'

import './ViewPDF.scss'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/zoom/lib/styles/index.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'

const ViewPDF = (props, ref) => {
  const {
    link,
    className,
    fileName = 'concord_document',
    defaultZoom = 1,
    ...restProps
  } = props

  const zoomPluginInstance = zoomPlugin() // Initialize the zoom plugin
  const { zoomTo } = zoomPluginInstance // Extract the zoomTo method

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator() {
          return `${fileName}`
        },
      },
    },
  })

  useImperativeHandle(ref, () => ({
    setZoom: zoomValue => {
      zoomTo(zoomValue) // Use the zoomTo method from the zoom plugin
    },
  }))

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [])

  return (
    <div className={clsx('ViewPDF__root', className)} {...restProps}>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`}
      >
        <Viewer
          fileUrl={link}
          plugins={[defaultLayoutPluginInstance, zoomPluginInstance]} // Add the zoom plugin
          defaultScale={defaultZoom} // Set the default zoom level
        />
      </Worker>
    </div>
  )
}

export default React.forwardRef(ViewPDF)
