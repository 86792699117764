import { useEffect, useState } from 'react'

import type { ISalesProposal } from '~/types/models/ISalesProposal'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { CheckMarkIcon, CloseIcon } from '~/components/shared/SvgIcons'
import { produce } from 'immer'
import { ESalesProposalStatus } from '~/types/enums/ESalesProposal'
import _ from 'lodash'
import { ICommonOption } from '~/types/models/ICommonModel'
import { apiClient } from '~/api/ApiClient'
import { Alert, Button } from 'react-bootstrap'
import { ISellerToBuyerTerminal } from '~/types/models/ISellerToBuyerTerminal'
import { toastMessages } from '~/constants/toast-status-text'
import { useQueryEnums, useQueryTerminals } from '~/hooks/useQueryData'

export interface ISalesProposalCardProps {
  salesProposal?: ISalesProposal
  salesBacklog?: ISellerToBuyerTerminal
  afterUpdate?: (data: ISalesProposal) => void
  afterCreate?: (data: ISalesProposal) => void
}

function SalesProposalCard(props: ISalesProposalCardProps) {
  const { salesProposal, afterUpdate, salesBacklog, afterCreate } = props

  const [formData, setFormData] = useState<Partial<ISalesProposal>>({})
  const [editingFields, setEditingFields] = useState<string[]>([])
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { typeEnums } = useQueryEnums({
    model: 'sales_proposals',
    field: 'status',
  })

  const { terminalsData } = useQueryTerminals()

  const fields = [
    {
      label: 'Status',
      value: formData.status,
      type: EFieldType.singleSelect,
      name: 'status',
      isHidden: !salesProposal,
      options: Object.keys(ESalesProposalStatus).map(field => ({
        label: _.startCase(field),
        value: (ESalesProposalStatus as any)[field],
      })),
    },
  ]

  const onToggleField = (name: string) => () => {
    if (salesProposal) {
      setEditingFields(prev =>
        produce(prev, draft => {
          const index = draft.indexOf(name)
          if (index !== -1) {
            draft.splice(index, 1)
          } else {
            draft.push(name)
          }
        }),
      )
    }
  }

  const onSubmitChange = (name: string) => async () => {
    onToggleField(name)()

    if (salesProposal) {
      const response = await apiClient.salesProposals.update(salesProposal.id, {
        [name]: (formData as any)[name],
      })
      afterUpdate && afterUpdate(response)
    }
  }

  const onCreate = async () => {
    setIsLoading(true)
    try {
      if (salesBacklog) {
        const buyerTerminal = terminalsData.find(
          ({ id }) => id === salesBacklog.buyerTerminalId,
        )
        const response = await apiClient.salesProposals.create({
          salesProposal: {
            bidderId: buyerTerminal?.companyId as number,
            status: typeEnums.inProgress,
          },
        })
        afterCreate && afterCreate(response)
      }
    } catch (error) {
      console.log('error', error)
      setError(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
    setError('')
  }

  const renderEditField = (
    type: EFieldType,
    value: any,
    name: string,
    options?: ICommonOption[],
  ) => {
    switch (type) {
      case EFieldType.singleSelect:
        return (
          <select
            className='SalesProposalCard__input'
            value={value}
            onChange={event => {
              setFormData(prev => ({
                ...prev,
                [name]: event.target.value,
              }))
            }}
          >
            <option>Select one</option>
            {options?.map(option => (
              <option
                selected={option.value === value}
                value={option.value}
                key={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
        )

      default:
        return (
          <input
            type={type}
            value={value}
            className='SalesProposalCard__input'
            onChange={event => {
              setFormData(prev => ({
                ...prev,
                [name]: event.target.value,
              }))
            }}
          />
        )
    }
  }

  useEffect(() => {
    if (salesProposal) {
      setFormData(salesProposal)
      setEditingFields([])
    } else {
      setEditingFields(['attention', 'date', 'status'])
    }
  }, [salesProposal])

  return (
    <div className='SalesProposalCard__container'>
      {error && (
        <Alert style={{ fontSize: 12, marginBottom: 4 }} variant='danger'>
          {error}
        </Alert>
      )}
      {fields.map(({ label, value, name, type, options, isHidden }) =>
        isHidden ? null : (
          <div key={name} style={{ marginBottom: 4 }}>
            <span>{label}: </span>
            {editingFields.includes(name) ? (
              <>
                {renderEditField(type, value, name, options)}

                {salesProposal && (
                  <>
                    <span
                      style={{ marginLeft: 4 }}
                      className='icon'
                      onClick={onSubmitChange(name)}
                    >
                      <CheckMarkIcon color='var(--ion-color-success)' />
                    </span>
                    <span
                      style={{ marginLeft: 4 }}
                      className='icon'
                      onClick={() => {
                        onToggleField(name)()
                        setFormData(prev => ({
                          ...prev,
                          [name]: (salesProposal as any)[name],
                        }))
                      }}
                    >
                      <CloseIcon color='var(--ion-color-danger)' />
                    </span>
                  </>
                )}
              </>
            ) : (
              <span style={{ cursor: 'pointer' }} onClick={onToggleField(name)}>
                {options
                  ? options.find(opt => opt.value === value)?.label || '-'
                  : value || '-'}
              </span>
            )}
          </div>
        ),
      )}
      {!salesProposal && (
        <Button
          style={{ fontSize: 13, width: '100%' }}
          onClick={onCreate}
          disabled={isLoading}
        >
          Submit
        </Button>
      )}
    </div>
  )
}

export default SalesProposalCard
