import React, { useState, useCallback } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { toast } from 'react-toastify'

import { ConcordFormDropdown } from '~/components/shared'
import TerminalCustomOption from '../ConcordLoadTerminalDropdown/TerminalCustomOption'
import BuyerSellerDropdownForm from './SellerProductDropdownForm'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'
import { EYesNo } from '~/types/enums/ECommonEnum'
import useQuerySellerProductsNew from '~/hooks/useQueryData/useQuerySellerProductsNew/useQuerySellerProductsNew'

function ConcordLoadSellerProductDropdown(props) {
  const {
    className,
    onCreate,
    onUpdate,
    onDelete,
    onOpenDropdown,
    loadSellerProductId,
    sellerId,
    sellerTerminalId,
    buyerTerminalId,
    buyerId,
    ...dropdownProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const [initialFormValues, setInitialFormValues] = useState({})
  const [shouldFetchSellerProducts, setShouldFetchSellerProducts] =
    useState(false)

  const { confirmation } = useConfirmationProvider()

  const { sellerProductOptions, addSellerProduct, deleteSellerProduct } =
    useQuerySellerProductsNew(
      {
        buyerId,
        sellerId,
      },
      { enabled: shouldFetchSellerProducts },
    )

  const handleOpenDropdown = useCallback(() => {
    setShouldFetchSellerProducts(true)
    onOpenDropdown && onOpenDropdown()
  }, [onOpenDropdown])

  const handleOpenForm = useCallback(() => {
    setMenuIsOpen(false)
    setIsOpenForm(true)
  }, [])

  const handleCloseForm = useCallback(() => {
    setMenuIsOpen(true)
    setIsOpenForm(false)
    setInitialFormValues({})
  }, [])

  const handleEdit = useCallback(
    option => {
      const { code, name, value, productGroupId } = option
      setInitialFormValues({
        code,
        name,
        id: value,
        productGroupId,
      })
      handleOpenForm()
    },
    [handleOpenForm],
  )

  const handleCreateProduct = useCallback(
    createdProduct => {
      addSellerProduct(createdProduct)
      onCreate && onCreate(createdProduct)
    },
    [addSellerProduct, onCreate],
  )

  const handleUpdateProduct = useCallback(
    updatedProduct => {
      addSellerProduct(updatedProduct)
      onUpdate && onUpdate(updatedProduct)
    },
    [addSellerProduct, onUpdate],
  )

  const handleDelete = useCallback(
    async option => {
      const result = await confirmation({
        message: `Are you sure you want to delete product #${option.value}?`,
      })

      if (result === EYesNo.Yes) {
        setIsLoading(true)
        try {
          const response = await apiClient.sellerProducts.delete(option.value)
          if (response.id) {
            toast.success(toastMessages.deleteSuccess)
            deleteSellerProduct(option.value)
            onDelete && onDelete(response)
          } else {
            toast.error(toastMessages.deleteError)
          }
        } catch (error) {
          toast.error(toastMessages.deleteError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [confirmation, deleteSellerProduct, onDelete],
  )

  const renderElementBelowDropdown = useCallback(
    () => (
      <BuyerSellerDropdownForm
        isLoading={isLoading}
        isOpenForm={isOpenForm}
        onClose={handleCloseForm}
        onOpen={handleOpenForm}
        sellerId={sellerId}
        buyerId={buyerId}
        initialFormValues={initialFormValues}
        onCreate={handleCreateProduct}
        onUpdate={handleUpdateProduct}
      />
    ),
    [
      buyerId,
      handleCloseForm,
      handleCreateProduct,
      handleOpenForm,
      handleUpdateProduct,
      initialFormValues,
      isLoading,
      isOpenForm,
      sellerId,
    ],
  )

  return (
    <ConcordFormDropdown
      className={className}
      options={sellerProductOptions}
      isLoading={isLoading}
      menuIsOpen={menuIsOpen}
      onOpenDropdown={handleOpenDropdown}
      onCloseDropdown={() => setShouldFetchSellerProducts(false)}
      onEdit={handleEdit}
      onDelete={handleDelete}
      styles={{
        menuList: provided => ({
          ...provided,
          maxHeight: 150,
        }),
      }}
      renderElementBelowDropdown={renderElementBelowDropdown}
      components={{
        Option: TerminalCustomOption,
      }}
      {...dropdownProps}
    />
  )
}

export default React.memo(ConcordLoadSellerProductDropdown)
