import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  useQueryCompanies,
  useQueryHierarchies,
  useQueryHierarchyRows,
} from '~/hooks/useQueryData'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import HierarchyForm from '~/containers/invoices/AutomaticChargesDialog/HierarchyForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import './styles.scss'
import { IPriceEscalationFormProps } from './type'
import { IPriceEscalationFormData } from '~/types/models/IPriceEscalation'
import {
  PRICE_ESCALATION_CADENCE_OPTIONS,
  PRICE_ESCALATION_TYPE_OPTIONS,
} from '~/types/enums/EPriceEscalation'

const PriceEscalationForm = (props: IPriceEscalationFormProps, ref: any) => {
  const { afterCreate, afterUpdate, formData, hierarchyItem, ...formProps } =
    props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const formRef = useRef<any>()
  const hierarchyFormRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { companyOptions } = useQueryCompanies({})

  const { hierarchyRowsData } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.PriceEscalation],
        hierarchableId: formData?.id as number,
      },
    },
    { enabled: isUpdating, refetchOnMount: true },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, isHierarchiesDataFetched } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    { enabled: hierarchyIds.length > 0, refetchOnMount: true },
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Escalation Type',
        name: 'escalationType',
        type: EFieldType.singleSelect,
        options: PRICE_ESCALATION_TYPE_OPTIONS,
        size: 6,
        isRequired: true,
      },
      {
        label: 'Escalation Cadence',
        name: 'escalationCadence',
        type: EFieldType.singleSelect,
        options: PRICE_ESCALATION_CADENCE_OPTIONS,
        size: 6,
        isRequired: true,
      },
      {
        label: 'Date',
        name: 'date',
        type: EFieldType.date,
        isRequired: true,
        size: 6,
      },
      {
        label: 'Amount',
        name: 'amount',
        type: EFieldType.number,
        isRequired: true,
        size: 6,
      },
      {
        label: 'Company',
        name: 'companyId',
        type: EFieldType.singleSelect,
        options: companyOptions,
      },
      {
        name: 'hierarchyAttributes',
        render() {
          return (
            <HierarchyForm
              item={hierarchyItem}
              ref={hierarchyFormRef}
              className='AutoExtraForm__hierarchyForm'
              isUpdating={isUpdating}
            />
          )
        },
      },
    ],
    [companyOptions, hierarchyItem, isUpdating],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        escalationCadence: Yup.string()
          .required('This field is required')
          .typeError('This field is required'),
        escalationType: Yup.string()
          .required('This field is required')
          .typeError('This field is required'),
        date: Yup.string()
          .required('This field is required')
          .typeError('This field is required'),
        amount: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
      }),
    [],
  )

  const defaultValues = useMemo<IPriceEscalationFormData>(
    () => ({
      escalationCadence: null,
      amount: null,
      date: null,
      escalationType: null,
      companyId: currentCompany.id,
    }),
    [currentCompany.id],
  )

  const onCreate = useCallback(
    async (payload: IPriceEscalationFormData) => {
      const { errors, ...res } = await apiClient.priceEscalations.create({
        priceEscalation: payload,
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
          hierarchableType: EHierarchableType.PriceEscalation,
          hierarchableId: res.id,
        })
        const keepOpenForm = res2.backendErrors.length > 0
        afterCreate && afterCreate(res, keepOpenForm)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: IPriceEscalationFormData) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.priceEscalations.update(
          formData.id,
          {
            priceEscalation: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
            hierarchableType: EHierarchableType.PriceEscalation,
            hierarchableId: response.id,
          })
          const keepOpenForm = res2.backendErrors.length > 0
          afterUpdate && afterUpdate(response, keepOpenForm)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IPriceEscalationFormData) => {
      hierarchyFormRef.current?.handleSubmit(async () => {
        setIsLoading(true)
        setError('')
        try {
          if (isUpdating) {
            await onUpdate(formValues)
          } else {
            await onCreate(formValues)
          }
        } catch (error) {
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      })()
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isHierarchiesDataFetched && isUpdating && hierarchiesData.length > 0) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        hierarchiesData,
      )
    }
  }, [isHierarchiesDataFetched, hierarchiesData, isUpdating])

  useEffect(() => {
    if (formData?.hierarchiesAttributes) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        formData?.hierarchiesAttributes,
      )
    }
  }, [formData?.hierarchiesAttributes])

  return (
    <ConcordFormStructure
      error={error}
      isLoading={isLoading}
      ref={node => {
        formRef.current = node
        if (ref) {
          ref.current = {
            ...node,
            onSubmitForm: handleSubmit,
          }
        }
      }}
      formData={formData}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
      {...formProps}
    />
  )
}

export default React.forwardRef(PriceEscalationForm)
