import React, { PropsWithChildren, useState } from 'react'
import {
  ISellerProduct,
  ISpCostingBreakdown,
} from '~/types/models/ISellerProduct'
import RCTooltip from 'rc-tooltip'
import { ReusableTable } from '../ReusableTable'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { ReusableButton } from '../ReusableButton'
import { CopyIcon, EditIcon } from '../SvgIcons'
import { ButtonGroup } from 'react-bootstrap'

import './styles.scss'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import { DialogSellerProductForm } from '../ConcordForm'

export interface ICostingBreakdownOverlayProps {
  breakdownData: ISpCostingBreakdown[]
  placement?: string
  afterUpdateProduct?: (product: ISellerProduct) => void
}

function CostingBreakdownOverlay(
  props: PropsWithChildren<ICostingBreakdownOverlayProps>,
) {
  const {
    breakdownData,
    placement = 'top',
    children,
    afterUpdateProduct,
  } = props

  const [isFetchingProduct, setIsFetchingProduct] = useState(false)
  const [spForm, setSpForm] = useState({
    isOpen: false,
    formData: undefined as ISellerProduct | undefined,
  })
  const [visible, setVisible] = useState(false)

  const handleCopyToClipboard = () => {
    const tableHeaders = ['Code', 'Name', 'Cost']
    const tableRows = breakdownData.map(row => [row.code, row.name, row.cost])

    const tableString = [
      tableHeaders.join('\t'),
      ...tableRows.map(row => row.join('\t')),
    ].join('\n')

    navigator.clipboard
      .writeText(tableString)
      .then(() => {
        toast.success('Data copied')
      })
      .catch(err => {
        toast.error('Failed to copy data to clipboard:', err)
      })
  }

  const fetchSellerProduct = async (id: number) => {
    setIsFetchingProduct(true)
    try {
      const response = await apiClient.sellerProducts.getById(id)
      setSpForm({
        isOpen: true,
        formData: response.sellerProduct,
      })
      setVisible(false)
    } catch (error: any) {
      toast.error('Failed to fetch seller product')
    } finally {
      setIsFetchingProduct(false)
    }
  }

  const onCloseSpForm = () => {
    setSpForm({
      isOpen: false,
      formData: undefined,
    })
  }

  return (
    <>
      <RCTooltip
        overlayClassName='CostingBreakdownOverlay__overlay'
        visible={visible}
        onVisibleChange={setVisible}
        overlay={
          <div className='w-100'>
            <ButtonGroup style={{ marginBottom: 8 }}>
              <ReusableButton onClick={handleCopyToClipboard}>
                <CopyIcon color='white' />
              </ReusableButton>
            </ButtonGroup>

            <ReusableTable
              enableRowActions
              columns={[
                {
                  header: 'Code',
                  accessorKey: 'code',
                  maxSize: 80,
                },
                {
                  header: 'Name',
                  accessorKey: 'name',
                  maxSize: 120,
                },
                {
                  header: 'Qty',
                  accessorKey: 'qty',
                  maxSize: 80,
                  align: 'right',
                },
                {
                  header: 'Cost',
                  accessorKey: 'cost',
                  align: 'right',
                  maxSize: 80,
                  Cell({ cell }) {
                    const value = cell.getValue<any>()
                    return formatCurrencyToDollar.format(value)
                  },
                },
                {
                  header: 'Total Cost',
                  accessorKey: 'totalCost',
                  align: 'right',
                  maxSize: 130,
                  Cell({ cell }) {
                    const value = cell.getValue<any>()
                    return formatCurrencyToDollar.format(value)
                  },
                },
                {
                  header: 'End Date',
                  accessorKey: 'endDate',
                  align: 'center',
                  maxSize: 110,
                },
              ]}
              data={breakdownData}
              tableHeight={250}
              renderRowActions={({ row }) => [
                {
                  icon: <EditIcon color='white' />,
                  color: 'warning',
                  isLoading: isFetchingProduct,
                  onClick: () => fetchSellerProduct(row.original.id),
                },
              ]}
            />
          </div>
        }
        placement={placement}
      >
        <div>{children}</div>
      </RCTooltip>
      <DialogSellerProductForm
        isOpen={spForm.isOpen}
        formData={spForm.formData}
        onClose={onCloseSpForm}
        afterUpdate={(product: ISellerProduct) => {
          afterUpdateProduct && afterUpdateProduct(product)
          onCloseSpForm()
        }}
      />
    </>
  )
}

export default CostingBreakdownOverlay
