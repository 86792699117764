import _ from 'lodash'
import { useMemo, useRef } from 'react'
import { Badge } from 'react-bootstrap'
import {
  CommonDialogV2,
  ICommonDialogProps,
  PlusIcon,
  ReusableButton,
} from '~/components/shared'
import { PricingsTable } from '~/containers/PricingsContainer/PricingsTable'
import { useQuerySellerProducts } from '~/hooks/useQueryData'
import { IBillLine } from '~/types/models/IBillLine'
import buildObjectName from '~/utils/buildObjectName'
import './styles.scss'
import { IInvoice } from '~/types/models/IInvoice'

export interface IDialogPricingsModalProps extends ICommonDialogProps {
  billLine?: IBillLine
  invoice?: IInvoice
  includeInvoiceId?: boolean
  afterCreate?: () => void
  afterUpdate?: () => void
}

function DialogPricingsTable(props: IDialogPricingsModalProps) {
  const {
    billLine,
    isOpen,
    invoice,
    includeInvoiceId,
    afterCreate,
    afterUpdate,
    ...dialogProps
  } = props

  const tableRef = useRef<any>()

  const { findSellerProductById } = useQuerySellerProducts()

  const sellerProd = findSellerProductById(billLine?.sellerProductId)

  const defaultColumnFilters = useMemo(() => {
    if (isOpen) {
      return [
        {
          id: 'forDate',
          value: billLine?.lineDate,
        },
        {
          id: 'sellerProductId',
          value: billLine?.sellerProductId,
        },
        // {
        //   id: 'hierarchyScope.sellerSideType',
        //   value: 'SellerTerminal',
        // },
        // {
        //   id: 'hierarchyScope.sellerSideId',
        //   value: billLine?.sellerTerminalId,
        // },
        // {
        //   id: 'hierarchyScope.buyerSideType',
        //   value: billLine?.buyerTerminalId ? 'BuyerTerminal' : 'Buyer',
        // },
        // {
        //   id: 'hierarchyScope.buyerSideId',
        //   value: billLine?.buyerTerminalId || billLine?.buyerId,
        // },
      ]
    }
    return undefined
  }, [billLine?.lineDate, billLine?.sellerProductId, isOpen])

  const onOpenPricingCreateForm = () => {
    tableRef.current?.options?.companyViewProps.onClickTopButton()
  }

  return (
    <>
      <CommonDialogV2
        {...dialogProps}
        isOpen={isOpen}
        isHiddenOkButton
        title={
          <div>
            <span>Pricing</span>
            <Badge style={{ marginLeft: 4 }}>
              {buildObjectName({
                name: sellerProd?.name,
                code: sellerProd?.code,
              })}
            </Badge>
            {sellerProd?.qtyType && (
              <Badge style={{ marginLeft: 4 }}>
                {_.startCase(sellerProd.qtyType)}
              </Badge>
            )}
            <ReusableButton
              variant='primary'
              style={{ marginLeft: 4 }}
              tooltipContent='Add Pricing'
              onClick={onOpenPricingCreateForm}
            >
              <PlusIcon color='white' />
            </ReusableButton>
          </div>
        }
        size='xl'
      >
        <PricingsTable
          queryOptions={{
            enabled: Boolean(billLine && isOpen),
          }}
          defaultColumnFilters={defaultColumnFilters}
          className='DialogPricingsTable__table'
          ref={tableRef}
          toolbarProps={{
            filterSectionWidth: 720,
          }}
          billLineData={billLine}
          invoiceData={invoice}
          includeInvoiceId={includeInvoiceId}
          afterCreate={afterCreate}
          afterUpdate={afterUpdate}
        />
      </CommonDialogV2>
    </>
  )
}

export default DialogPricingsTable
