import type { ISvgIconProps } from './type'

const CalculatorIcon = (props: ISvgIconProps) => {
  const { size = 16, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M2 0h12v16H2V0Zm2 2h8v4H4V2Zm1 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-4 3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H8Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default CalculatorIcon
