/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { EFieldType } from '~/types/enums/ECommonEnum'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IAllowedHierarchyFormProps } from './type'
import { useQueryAllowedHierarchiesNew } from '~/hooks/useQueryData'
import { IAllowedHierarchyFormValues } from '~/types/models/IAllowedHierarchy'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

const AllowedHierarchyForm = React.forwardRef<any, IAllowedHierarchyFormProps>(
  (props, ref) => {
    const { afterCreate, afterUpdate, formData, ...formProps } = props

    const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { ahSellerSideOptions, ahBuyerSideOptions } =
      useQueryAllowedHierarchiesNew()

    const fields = useMemo<IConcordFormField[]>(
      () => [
        {
          name: 'sellerSideType',
          label: 'Seller Side Type',
          isRequired: true,
          type: EFieldType.singleSelect,
          options: ahSellerSideOptions,
        },
        {
          name: 'buyerSideType',
          label: 'Buyer Side Type',
          isRequired: true,
          type: EFieldType.singleSelect,
          options: ahBuyerSideOptions,
        },
      ],
      [ahBuyerSideOptions, ahSellerSideOptions],
    )

    const defaultValues = useMemo<IAllowedHierarchyFormValues>(
      () => ({
        sellerSideType: '',
        buyerSideType: '',
      }),
      [],
    )

    const schema = useMemo(
      () =>
        Yup.object({
          // sellerSideType: Yup.string().required('This field is required!'),
        }),
      [],
    )

    const onCreate = useCallback(
      async (formValues: IAllowedHierarchyFormValues) => {
        const { errors, ...response } =
          await apiClient.allowedHierarchies.create({
            allowedHierarchy: {
              sellerSideType: formValues.sellerSideType,
              buyerSideType: formValues.buyerSideType,
            },
          })
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterCreate && afterCreate(response)
          toast.success(toastMessages.createSuccess)
        }
      },
      [afterCreate],
    )

    const onUpdate = useCallback(
      async (formValues: IAllowedHierarchyFormValues) => {
        if (formValues.id) {
          const payload = _.pick(formValues, [
            'buyerSideType',
            'sellerSideType',
          ])
          const { errors, ...response } =
            await apiClient.allowedHierarchies.update(formValues.id, {
              allowedHierarchy: payload,
            })
          if (errors.length > 0) {
            setError(errors[0])
          } else {
            afterUpdate && afterUpdate(response)
            toast.success(toastMessages.createSuccess)
          }
        } else {
          setError('Id is not found')
        }
      },
      [afterUpdate],
    )

    const handleSubmit = useCallback(
      async (formValues: IAllowedHierarchyFormValues) => {
        setIsLoading(true)
        setError('')
        try {
          if (isUpdating) {
            await onUpdate(formValues)
          } else {
            await onCreate(formValues)
          }
        } catch (error) {
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      },
      [isUpdating, onCreate, onUpdate],
    )

    useEffect(() => {
      if (ref !== null) {
        // eslint-disable-next-line no-extra-semi
        ;(ref as any).current = {
          ...(ref as any).current,
          onSubmitForm: handleSubmit,
        }
      }
    }, [handleSubmit, ref])

    return (
      <div>
        <When condition={Boolean(error)}>
          <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
            {error}
          </Alert>
        </When>
        <ConcordFormStructure
          {...formProps}
          isLoading={isLoading}
          ref={ref}
          defaultValues={defaultValues}
          formData={formData}
          fields={fields}
          isHiddenCancelButton
          isHiddenSearch
          onSubmit={handleSubmit}
          schema={schema}
          submitText={isUpdating ? 'Update' : 'Create'}
        />
      </div>
    )
  },
)

export default AllowedHierarchyForm
