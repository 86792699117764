import { InvoiceTable } from '..'
import useInvoiceBodyPanels from '~/hooks/useInvoice/useInvoiceBodyPanels'

const InvoiceBodyPanels = ({
  invoice,
  isSplitView,
  splitCompareRef,
  className,
  isLoading,
}) => {
  useInvoiceBodyPanels(invoice, isSplitView, splitCompareRef)

  return (
    <>
      <div
        className={
          className ? className : isSplitView ? 'SplitView' : 'FullView'
        }
      >
        <InvoiceTable invoice={invoice} isLoading={isLoading} />
      </div>
    </>
  )
}

export default InvoiceBodyPanels
