import moment from 'moment'
import {
  CommonDialogV2,
  ICommonDialogProps,
  IRTColumnDef,
  ReusableTable,
} from '~/components/shared'
import { useQueryBillLineTaxes, useQueryTaxes } from '~/hooks/useQueryData'
import { IBilllineTax } from '~/types/models/IBillLineTax'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'

export interface IBillLineTaxesTableDialog extends ICommonDialogProps {
  billLineId: number
  invoiceId: number
}

function BillLineTaxesTableDialog(props: IBillLineTaxesTableDialog) {
  const { billLineId, invoiceId, ...dialogProps } = props

  const { billLineTaxes, isBillLineTaxesFetching } = useQueryBillLineTaxes(
    {
      filters: {
        billLineId,
      },
    },
    { enabled: Boolean(billLineId) },
  )

  const taxIds = billLineTaxes.map(tax => tax.taxId).filter(Boolean)

  const { findTaxById } = useQueryTaxes(
    {
      filters: {
        id: taxIds,
      },
    },
    { enabled: taxIds.length > 0 },
  )

  const columns: IRTColumnDef<IBilllineTax>[] = [
    {
      header: 'Amount',
      accessorKey: 'amount',
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        return formatCurrencyToDollar.format(cellValue || 0)
      },
    },
    {
      header: 'Tax Name',
      accessorKey: 'taxName',
      Cell({ row }) {
        const { taxId } = row.original
        const tax = findTaxById(taxId)
        return tax?.name || ''
      },
    },
    {
      header: 'Gl Code',
      accessorKey: 'glCode',
    },
    {
      header: 'Updated At',
      accessorKey: 'updatedAt',
      Cell({ cell }) {
        const cellValue = cell.getValue<string>()
        return moment(cellValue).format('LLL')
      },
    },
  ]

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Bill Line Taxes'
      size='lg'
      isHiddenOkButton
    >
      <ReusableTable
        data={billLineTaxes}
        columns={columns}
        state={{
          isLoading: isBillLineTaxesFetching,
        }}
      />
    </CommonDialogV2>
  )
}

export default BillLineTaxesTableDialog
