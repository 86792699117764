import { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import {
  useQueryBuyerSellerRegexpsNew,
  useQueryCompanies,
} from '~/hooks/useQueryData'

import {
  ConcordFormStructure,
  DialogBuyerSellerForm,
  DialogCompanyForm,
  IConcordFormField,
} from '~/components/shared'

import * as Yup from 'yup'
import { EFieldType, EYesNo } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'

import type { IBuyerSellerRegexpFormProps } from './type'

import './styles.scss'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { IBuyerSellerRegexpFormData } from '~/types/models/IBuyerSellerRegexp'
import {
  buyerSellerRegexpModelOptions,
  EBuyerSellerRegexpModel,
} from '~/types/enums/EBuyerSellerRegexp'
import { REGEX_SPLIT_REGEX_TO_STRING } from '~/utils/constants'
import { useConfirmationProvider } from '~/contexts'

const DEFAULT_FORM_DATA: IBuyerSellerRegexpFormData = {
  regexp: '',
  sellerId: null,
  buyerId: null,
  model: [], // enum
  valueId: null,
  field: null, // enum
  regexpFlag: -1,
}

const BuyerSellerRegexpForm = (props: IBuyerSellerRegexpFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    hiddenFields,
    className,
    hiddenFieldOptions = [],
    ...formProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()
  const { confirmation } = useConfirmationProvider()

  const {
    isLoadingCompaniesData,
    sellerCompanyOptions,
    buyerCompanyOptions,
    findCompanyById,
    addCompany,
    updateCompany,
  } = useQueryCompanies({})

  const { regexpFieldOptions, isLoadingNewRegexps, isRegexpNewDataFetched } =
    useQueryBuyerSellerRegexpsNew()

  const modelOptions = Object.keys(EBuyerSellerRegexpModel).map(key => ({
    label: key,
    value: (EBuyerSellerRegexpModel as any)[key],
  }))

  const fields: IConcordFormField[] = [
    {
      name: 'buyerId',
      label: 'Buyer',
      isRequired: true,
      type: EFieldType.singleSelect,
      options: buyerCompanyOptions,
      isLoading: isLoadingCompaniesData,
      size: 6,
      isReadOnly: Boolean(formData?.buyerId),
      showCreateButton: true,
      showUpdateButtons: true,
      renderForm({ isOpen, setFormState, optionSelected }) {
        const formData = findCompanyById(optionSelected?.value)
        const dialogProps = {
          isOpen,
          onClose() {
            setFormState({ isOpen: false, optionSelected: null })
          },
          formData: formData as any,
        }

        if (optionSelected) {
          return (
            <DialogCompanyForm
              {...dialogProps}
              afterUpdate={item => {
                updateCompany(item.id, item)
                setFormState({ isOpen: false, optionSelected: null })
              }}
            />
          )
        } else {
          return (
            <DialogBuyerSellerForm
              {...dialogProps}
              afterCreate={async company => {
                addCompany(company)
                setFormState({ isOpen: false, optionSelected: null })
                const result = await confirmation({
                  message: 'Do you want to set this value to Seller?',
                })
                if (result === EYesNo.Yes) {
                  formRef.current?.setValue('sellerId', company.id)
                }
              }}
            />
          )
        }
      },
    },
    {
      name: 'sellerId',
      label: 'Seller',
      isRequired: true,
      type: EFieldType.singleSelect,
      options: sellerCompanyOptions,
      isLoading: isLoadingCompaniesData,
      size: 6,
      isReadOnly: Boolean(formData?.sellerId),
      onChange({ value }) {
        formRef.current?.setValue('valueId', value)
      },
      showCreateButton: true,
      showUpdateButtons: true,
      renderForm({ isOpen, setFormState, optionSelected }) {
        const formData = findCompanyById(optionSelected?.value)
        const dialogProps = {
          isOpen,
          onClose() {
            setFormState({ isOpen: false, optionSelected: null })
          },
          formData: formData as any,
        }

        if (optionSelected) {
          return (
            <DialogCompanyForm
              {...dialogProps}
              afterUpdate={item => {
                updateCompany(item.id, item)
                setFormState({ isOpen: false, optionSelected: null })
              }}
            />
          )
        } else {
          return (
            <DialogBuyerSellerForm
              {...dialogProps}
              afterCreate={async company => {
                addCompany(company)
                setFormState({ isOpen: false, optionSelected: null })
                const result = await confirmation({
                  message: 'Do you want to set this value to Seller?',
                })
                if (result === EYesNo.Yes) {
                  formRef.current?.setValue('sellerId', company.id)
                }
              }}
            />
          )
        }
      },
    },
    {
      name: 'model',
      label: 'Model',
      size: hiddenFields?.includes('field') ? 12 : 8,
      isRequired: true,
      type: EFieldType.multipleSelect,
      options: modelOptions,
    },
    {
      name: 'field',
      label: 'Field',
      size: 4,
      isRequired: true,
      type: EFieldType.singleSelect,
      options: regexpFieldOptions.filter(
        ({ field }) => !hiddenFieldOptions.includes(field),
      ),
      isLoading: isLoadingNewRegexps,
    },
    {
      name: 'regexp',
      label: 'Regexp',
      isRequired: true,
      size: 7,
    },
    {
      name: 'regexpFlag',
      label: 'Match Criteria',
      size: 5,
      type: EFieldType.singleSelect,
      options: [
        {
          value: -1,
          label: 'Case Sensitive',
        },
        {
          value: 'i',
          label: 'Case Insensitive',
        },
        {
          value: 'm',
          label: 'Make dot match newlines',
        },
        // {
        //   value: 'x',
        //   label: 'Ignore whitespace',
        // },
      ],
    },
  ]

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const schema = useMemo(
    () =>
      Yup.object({
        buyerId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        sellerId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        model: Yup.array().min(1, 'This field is required!'),
        field: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        regexp: Yup.string().required('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IBuyerSellerRegexpFormData) => {
      const { errors, ...response } = await apiClient.buyerSellerRegexps.create(
        {
          ...formValues,
          valueId: formValues.sellerId,
        },
      )
      if (errors.length) {
        setBackendError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IBuyerSellerRegexpFormData) => {
      const { errors, ...response } = await apiClient.buyerSellerRegexps.update(
        formData?.id as number,
        formValues,
      )
      if (errors.length) {
        setBackendError(errors[0])
      } else {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IBuyerSellerRegexpFormData) => {
      setIsLoading(true)
      setBackendError('')
      const [, , regexStr = ''] =
        (formValues.regexp || '').match(REGEX_SPLIT_REGEX_TO_STRING) ||
        ([] as string[])
      const payload = {
        ...formValues,
        regexp: `/${regexStr}/${
          formValues.regexpFlag === -1 ? '' : formValues.regexpFlag
        }`,
      }

      try {
        if (isUpdating) {
          await onUpdate(payload)
        } else {
          await onCreate(payload)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (formData?.regexp) {
      const [, , regexStr, flags] = formData.regexp.match(
        REGEX_SPLIT_REGEX_TO_STRING,
      ) as string[]
      formRef.current?.setValue('regexp', regexStr)
      formRef.current?.setValue('regexpFlag', flags || -1)
    }
    if (formData?.model) {
      const model = formData.model
        .map(val => {
          if (typeof val === 'number') {
            return buyerSellerRegexpModelOptions.find(
              ({ value }) => value === val,
            )?.label
          }
          return val
        })
        .filter(val => val) as EBuyerSellerRegexpModel[]
      formRef.current?.setValue('model', model)
    }
  }, [formData?.model, formData?.regexp])

  useEffect(() => {
    if (typeof formData?.field === 'string' && isRegexpNewDataFetched) {
      const opt = regexpFieldOptions.find(
        ({ field }) => field === formData?.field,
      )
      formRef.current?.setValue('field', opt?.value)
    }
  }, [formData?.field, isRegexpNewDataFetched, regexpFieldOptions])

  return (
    <div>
      <ConcordFormStructure
        {...formProps}
        ref={formRef}
        fields={fields}
        formData={{ ...DEFAULT_FORM_DATA, ...formData }}
        cancelText='Close'
        submitText={isUpdating ? 'Update' : 'Create'}
        onSubmit={handleSubmit}
        schema={schema}
        isLoading={isLoading || formProps.isLoading}
        error={backendError}
        hiddenFields={hiddenFields}
        isHiddenCancelButton
        className={className}
      />
    </div>
  )
}

export default BuyerSellerRegexpForm
