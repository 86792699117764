import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifySellerTerminalProducts from './useModifySellerTerminalProducts'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetSellerTerminalProduct,
  ISellerTerminalProduct,
} from '~/types/models/ISellerTerminalProduct'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQuerySellerTerminalProducts = (
  params: IGetSellerTerminalProduct,
  options?: Partial<UseQueryOptions<ISellerTerminalProduct[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'sellerTerminalProducts',
      sessionUser?.id,
      buildGetUrl('/seller_terminal_products', params, {
        includeUndefined: true,
      }),
    ],
    [params, sessionUser?.id],
  )

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey,
    async queryFn() {
      const response = await apiClient.sellerTerminalProducts.get(params)
      return response.sellerTerminalProducts
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const {
    addSellerTerminalProduct,
    updateSellerTerminalProduct,
    removeSellerTerminalProduct,
  } = useModifySellerTerminalProducts(params)

  const sellerTerminalProductsData = useMemo(() => data || [], [data])

  return {
    sellerTerminalProductsData,
    refecthSellerTerminalProducts: refetch,
    addSellerTerminalProduct,
    updateSellerTerminalProduct,
    removeSellerTerminalProduct,
    isLoadingSellerTerminalProducts: isLoading,
    isSellerTerminalProductsFetched: isFetched,
  }
}

export default useQuerySellerTerminalProducts
