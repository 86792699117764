function findMinMaxByField(arr, field) {
  if (!arr.length || !field) return { min: null, max: null }

  let minValue = arr[0][field]
  let maxValue = arr[0][field]

  for (let i = 1; i < arr.length; i++) {
    if (arr[i][field] < minValue) minValue = arr[i][field]
    if (arr[i][field] > maxValue) maxValue = arr[i][field]
  }

  return { minDate: minValue, maxDate: maxValue }
}

export default findMinMaxByField
