import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IHierarchyRow,
  IGetHierarchyRowMatchesParams,
  IHierarchyRowMatch,
} from '~/types/models/IHierarchyRow'

const useModifyHierarchyRowsMatches = (
  params: IGetHierarchyRowMatchesParams,
  options?: {
    additionalKeys?: string[]
  },
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'hierarchyRowsMatches',
      sessionUser?.id,
      buildGetUrl(`${apiClient.hierarchyRows.endpoint}/matches`, params, {
        includeUndefined: true,
      }),
      ...(options?.additionalKeys || []),
    ],
    [options?.additionalKeys, params, sessionUser?.id],
  )

  const addHierarchyRow = useCallback(
    (data: IHierarchyRow) => {
      queryClient.setQueryData<IHierarchyRow[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateHierarchyRowMatched = useCallback(
    (id: number, data: any) => {
      queryClient.setQueryData<IHierarchyRowMatch[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(
                ({ hierarchable }) => hierarchable.id === id,
              )
              if (draft[index]) {
                draft[index].hierarchable = data
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeHierarchyRow = useCallback(
    (id: number) => {
      queryClient.setQueryData<IHierarchyRow[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const renewAllHierarchyRows = useCallback(
    (data: IHierarchyRow[]) => {
      queryClient.setQueryData<IHierarchyRow[] | undefined>(queryKey, () => {
        return data
      })
    },
    [queryClient, queryKey],
  )

  return {
    addHierarchyRow,
    updateHierarchyRowMatched,
    removeHierarchyRow,
    renewAllHierarchyRows,
  }
}

export default useModifyHierarchyRowsMatches
