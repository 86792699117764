import { createSlice } from '@reduxjs/toolkit'

const lastFetchedAtSlice = createSlice({
  name: 'lastFetchedAt',
  initialState: {
    billLines: null,
    buyerSellers: null,
    companies: null,
    flags: null,
    invoices: null,
    loads: null,
    orders: null,
    products: null,
    productGroups: null,
    sellerProducts: null,
    tags: null,
    terminals: null,
    userGroups: null,
    userTableConfigurations: {
      documents: null,
      loads: null,
      invoices: null,
      company_buyer_seller_products: null,
      company_buyer_terminal_references: null,
      company_buyers: null,
      company_email_parsers: null,
      company_fixed_prices: null,
      company_fleets: null,
      company_parser_column_mappings: null,
      company_parsers: null,
      company_products: null,
      company_reference_names: null,
      company_seller_products: null,
      company_sellers: null,
      company_setup: null,
      company_tags: null,
      company_terminals: null,
      company_users: null,
    },
  },
  reducers: {
    updateLastFetchedAt: (state, { payload }) => {
      state[payload.model] = payload.lastFetchedAt
    },
  },
})

export default lastFetchedAtSlice
