export enum EInvoiceStatus {
  Progress = 'Progress',
  Uploaded = 'Uploaded',
  Processed = 'Processed',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Paid = 'Paid',
  Locked = 'Locked',
  Exported = 'Exported',
}

export enum EInvoiceSellerStatus {
  Approved = 'Approved',
  Exported = 'Exported',
}

export enum EInvoiceParserStatus {
  notSent,
  waiting,
  done,
  updated,
  noCollection,
}

export enum EInvoiceParserStatusLabel {
  notSent = 'Not Sent',
  waiting = 'Waiting',
  done = 'Done',
  updated = 'Updated',
  noCollection = 'No Collection',
}

export enum EInvoiceHitlStatus {
  notSent,
  waiting,
  done,
}
export enum EInvoiceHitlStatusLabel {
  notSent = 'Not Sent',
  waiting = 'Waiting',
  done = 'Done',
}

export enum EInvoiceType {
  material,
  delivery,
  deliveredMaterial,
  multiLineDeliveredMaterial,
  notSent = 'null',
}
