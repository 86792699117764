import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'

import { apiClient } from '~/api/ApiClient'
import './styles.scss'
import { ISellerProduct, ISpCosting } from '~/types/models/ISellerProduct'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import {
  CostingBreakdownOverlay,
  PlusIcon,
  ReusableButton,
  ReusableDatePicker,
  ReusableTable,
} from '~/components/shared'
import { useQueryTerminals } from '~/hooks/useQueryData'
import buildObjectName from '~/utils/buildObjectName'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { INullOrUndefined } from '~/types/models/ICommonModel'

export interface ICostTabProps {
  sellerProductId: number
  sellerTerminalProductsData: ISellerTerminalProduct[]
  canFetchCostingData?: boolean
  afterUpdateProduct?: (product: ISellerProduct) => void
}

function CostTab(props: ICostTabProps) {
  const {
    sellerProductId,
    sellerTerminalProductsData,
    canFetchCostingData,
    afterUpdateProduct,
  } = props

  const [costingData, setCostingData] = useState<Record<string, ISpCosting[]>>(
    {},
  )
  const [selectedDates, setSelectedDates] = useState<string[]>([
    moment().format('YYYY-MM-DD'),
  ])
  const [isFetchingCost, setIsFetchingCost] = useState(false)

  const { findTerminalById } = useQueryTerminals()

  const fetchCostingData = async (date: string) => {
    try {
      if (!canFetchCostingData) {
        return
      }
      setIsFetchingCost(true)
      const costingPayload = sellerTerminalProductsData.map(
        ({ terminalId }) => ({
          terminalId,
          id: sellerProductId,
          date,
        }),
      )

      const response = await apiClient.sellerProducts.postCosting({
        costing: costingPayload,
      })

      setCostingData(prev => ({
        ...prev,
        [date]: response,
      }))
    } catch (error) {
      console.error('Error fetching costing data:', error)
      toast.error('Failed to fetch costing data')
    } finally {
      setIsFetchingCost(false)
    }
  }

  const columns = [
    { header: 'Terminal', accessorKey: 'terminal', minSize: 120, maxSize: 140 },
    ...selectedDates.map(date => ({
      header: date === moment().format('YYYY-MM-DD') ? 'Today' : date,
      accessorKey: date,
      maxSize: 120,
      minSize: 120,
      enableSorting: false,
      Cell({ cell }: any) {
        const value = cell.getValue()
        const costing = costingData[cell.column.columnDef.accessorKey] || []

        const [costValue, terminalId] = value.split('-')
        const costingItem = costing.find(
          item => Number(terminalId) === item.terminalId,
        )
        const el = isNaN(Number(costValue))
          ? '$0.00'
          : formatCurrencyToDollar.format(costValue)

        if (costingItem?.breakdown?.length) {
          return (
            <CostingBreakdownOverlay
              breakdownData={costingItem.breakdown}
              placement='top'
              afterUpdateProduct={product => {
                fetchCostingData(date)
                afterUpdateProduct && afterUpdateProduct(product)
              }}
            >
              <span>{el}</span>
            </CostingBreakdownOverlay>
          )
        }

        return el
      },
    })),
  ]

  const tableData = sellerTerminalProductsData.map(({ terminalId }) => {
    const terminal = findTerminalById(terminalId)
    const row: Record<string, string | INullOrUndefined> = {
      terminal: buildObjectName(terminal),
    }
    selectedDates.forEach(date => {
      const costs = costingData[date] || []
      const costData = costs.find(
        (item: ISpCosting) => item.terminalId === terminalId,
      )
      row[date] = `${costData?.cost}-${costData?.terminalId}-${costData?.id}`
    })
    return row
  })

  useEffect(() => {
    fetchCostingData(moment().format('YYYY-MM-DD'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerTerminalProductsData, canFetchCostingData])

  const handleAddDate = (date: string | null) => {
    if (date) {
      const dateFormatted = moment(date).format('YYYY-MM-DD')
      if (!selectedDates.includes(dateFormatted)) {
        setSelectedDates([...selectedDates, dateFormatted])
        fetchCostingData(dateFormatted)
      }
    }
  }

  return (
    <div className='CostTab'>
      <div className='CostTab__controls'>
        <ReusableDatePicker
          isDisabled={sellerTerminalProductsData.length === 0}
          showIcon={false}
          customInput={
            <ReusableButton isLoading={isFetchingCost}>
              <PlusIcon color='white' />
            </ReusableButton>
          }
          onChange={handleAddDate}
          dayClassName={date => {
            if (selectedDates.includes(moment(date).format('YYYY-MM-DD'))) {
              return 'color-red'
            }
          }}
        />
      </div>
      <ReusableTable
        columns={columns as any}
        data={tableData}
        state={{
          showProgressBars: isFetchingCost,
        }}
        initialState={{
          sorting: [
            {
              id: 'terminal',
              desc: false,
            },
          ],
          columnPinning: {
            left: ['terminal'],
          },
        }}
      />
    </div>
  )
}

export default CostTab
