import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import type { IUser } from '~/types/models/IUser'
import useModifyWorkForces from './useModifyWorkForces'
import { IWorkType } from '~/types/models/IWorkType'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryWorkForces = (
  _params = {},
  options?: Partial<UseQueryOptions<IWorkType[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(() => ['workForces', sessionUser?.id], [sessionUser])

  const { addWorkForce, updateWorkForce, removeWorkForce } =
    useModifyWorkForces()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const { workForces } = await apiClient.workForces.get()
      return workForces
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const workForcesData = useMemo(() => data || [], [data])

  const workForceOptions = useMemo(
    () =>
      workForcesData.map(item => {
        const { id, name, code } = item
        return {
          value: id,
          label: `${code} - ${name}`,
          item,
        }
      }),
    [workForcesData],
  )

  return {
    workForcesData,
    workForceOptions,
    isWorkForcesLoading: isLoading,
    addWorkForce,
    updateWorkForce,
    removeWorkForce,
  }
}

export default useQueryWorkForces
