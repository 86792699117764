import { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import useTableCompanySellerProductSubTab from './useTableCompanySellerProductSubTab'
import { useMount, useWindowSize } from 'react-use'
import {
  useQueryCompanies,
  useQueryHierarchies,
  useQueryHierarchyRows,
  useQueryMixDetails,
  useQuerySellerProducts,
  useQuerySellerTerminalProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import {
  BranchCreateIcon,
  DeleteIcon,
  DialogSellerProductForm,
  EditIcon,
  InfoIcon,
  LeafIcon,
  ReusableTable,
  sellerProductDefaultValues,
} from '~/components/shared'

import _ from 'lodash'
import { companyTabQuery } from '~/utils/constants'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { USER_SCOPE } from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
  EYesNo,
} from '~/types/enums/ECommonEnum'

import './styles.scss'
import DialogHierarchiesForm from '~/containers/invoices/AutomaticChargesDialog/DialogHierarchiesForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'

function CompanySellerProductSubTab() {
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [currentRowData, setCurrentRowData] = useState(
    sellerProductDefaultValues,
  )
  const [currentFormTab, setCurrentFormTab] = useState('')
  const [columnFilters, setColumnFilters] = useState([])
  const [hierarchiesForm, setHierarchiesForm] = useState({
    isOpen: false,
    formData: undefined,
  })

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()
  const { sellerCompanies } = useQueryCompanies({})

  const currentScope = useSelector(selectCurrentScope)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const filterData = useMemo(() => {
    const data = {}
    columnFilters.forEach(({ id, value }) => {
      data[id] = value
    })
    return data
  }, [columnFilters])

  const apiParams = useMemo(() => {
    const filters = _.pick(filterData, [
      'materialType',
      'qtyType',
      'productUsage',
    ])

    if (
      filterData.view === companyTabQuery.sellers &&
      currentScope === USER_SCOPE.buyer
    ) {
      const sellerIds = sellerCompanies.map(seller => seller?.id)
      filters.sellerId = sellerIds
    } else if (
      filterData.view === companyTabQuery.myCompany &&
      currentScope === USER_SCOPE.seller
    ) {
      filters.sellerId = [currentCompany.id]
    }

    return { filters }
  }, [currentCompany.id, currentScope, filterData, sellerCompanies])

  const {
    sellerProducts,
    isLoadingSellerProducts,
    isSellerProductsFetched,
    addSellerProduct,
    updateSellerProduct,
    deleteSellerProduct,
    refetchSellerProductsData,
  } = useQuerySellerProducts(apiParams)

  const sellerProductIds = sellerProducts.map(({ id }) => id)

  const { hierarchyRowsData, refetchHierarchyRows } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.SellerProduct],
        hierarchableId: sellerProductIds,
      },
    },
    { enabled: sellerProductIds.length > 0 },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, refetchHierarchies } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    { enabled: hierarchyIds.length > 0 },
  )

  const { terminalsData } = useQueryTerminals()

  const { sellerTerminalProductsData } = useQuerySellerTerminalProducts(
    {
      filters: {
        sellerProductId: sellerProducts.map(({ id }) => id),
      },
    },
    {
      enabled: Boolean(isSellerProductsFetched && sellerProducts.length > 0),
    },
  )

  const onOpenHierarchiesForm = formData => () => {
    setHierarchiesForm({
      isOpen: true,
      formData,
    })
  }

  const onCloseHierarchiesForm = () => {
    setHierarchiesForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const afterCreateHierarchies = () => {
    refetchHierarchyRows()
    onCloseHierarchiesForm()
  }

  const isLoadingTable = isLoadingSellerProducts

  const viewOptions = useMemo(() => {
    const opts = [
      {
        label: 'No Product',
        value: companyTabQuery.noProduct,
      },
    ]

    if (currentScope === EScope.buyer) {
      opts.push({
        label: 'Seller',
        value: companyTabQuery.sellers,
      })
    } else {
      opts.push({
        label: 'My Company',
        value: companyTabQuery.myCompany,
      })
    }

    return opts
  }, [currentScope])

  const { mixDetailsData, addMixDetail, updateMixDetail } = useQueryMixDetails(
    {
      filters: {
        sellerProductId: sellerProductIds,
      },
    },
    { enabled: sellerProductIds.length > 0 },
  )

  const mixDetailData = useMemo(() => {
    if (currentRowData) {
      const objects = mixDetailsData.filter(
        ({ sellerProductId }) => currentRowData.id === sellerProductId,
      )
      return _.last(objects)
    }
    return undefined
  }, [currentRowData, mixDetailsData])

  const handleToggleForm = useCallback(nextValue => {
    setIsOpenForm(nextValue)
    if (!nextValue) {
      setCurrentRowData()
    }
  }, [])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete seller product #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        try {
          const { errors } = await apiClient.sellerProducts.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            deleteSellerProduct(rowData.id, apiParams)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        }
      }
    },
    [apiParams, confirmation, deleteSellerProduct],
  )

  const handleOpenUpdateForm = useCallback(async rowData => {
    setIsOpenForm(true)
    setCurrentRowData(rowData)
  }, [])

  const handleEditCell = useCallback(
    async (value, cell) => {
      try {
        const { column, row } = cell
        const columnField = column.id
        const rowId = row.original.id

        const formData = { [columnField]: value }
        const { errors } = await apiClient.sellerProducts.update(
          rowId,
          formData,
        )
        if (_.size(errors) > 0) {
          toast.error(toastMessages.updateError)
        } else {
          updateSellerProduct(rowId, { [columnField]: value }, apiParams)
          toast.success(toastMessages.updateSuccess)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    [apiParams, updateSellerProduct],
  )

  const onCloseSellerProductForm = useCallback(() => {
    setIsOpenForm(false)
    setCurrentRowData()
    setCurrentFormTab('')
  }, [])

  const afterCreate = useCallback(
    (sellerProduct, keepForm) => {
      addSellerProduct(sellerProduct, apiParams)
      if (keepForm) {
        setCurrentRowData(sellerProduct)
      } else {
        onCloseSellerProductForm()
      }
    },
    [addSellerProduct, apiParams, onCloseSellerProductForm],
  )

  const afterCreateMixDetail = item => {
    addMixDetail(item)
  }

  const afterUpdateMixDetail = item => {
    updateMixDetail(item.id, item)
  }

  const afterUpdate = useCallback(
    ({ id, ...sellerProduct }, keepForm) => {
      updateSellerProduct(id, sellerProduct, apiParams)
      refetchHierarchies()
      refetchHierarchyRows()
      if (keepForm) {
        setCurrentRowData({ id, ...sellerProduct })
      } else {
        onCloseSellerProductForm()
      }
    },
    [
      apiParams,
      onCloseSellerProductForm,
      refetchHierarchyRows,
      updateSellerProduct,
      refetchHierarchies,
    ],
  )

  const { columns } = useTableCompanySellerProductSubTab({
    sellerTerminalProductsData,
    terminalsData,
    hierarchyRowsData,
    hierarchiesData,
  })

  useMount(() => {
    if (currentScope === EScope.buyer) {
      setColumnFilters([
        {
          id: 'view',
          value: companyTabQuery.sellers,
        },
      ])
    } else {
      setColumnFilters([
        {
          id: 'view',
          value: companyTabQuery.myCompany,
        },
      ])
    }
  })

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableCompanyView
          enableTopToolbar
          enableColumnPinning
          enableRowActions
          companyViewProps={{
            name: moduleName.company.sellerProducts,
            description: 'View products that sellers are selling to buyers.',
            onClickTopButton() {
              handleToggleForm(true)
            },
          }}
          columns={columns}
          data={sellerProducts}
          tableHeight={windowSize.height - 220}
          state={{
            isLoading: isLoadingTable,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
              right: windowSize.width <= 480 ? [] : ['materialType', 'status'],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 165,
            },
          }}
          renderRowActions={({ row }) => {
            const mixDetails = mixDetailsData.filter(
              ({ sellerProductId }) => row.original.id === sellerProductId,
            )
            return [
              {
                icon: <EditIcon color='white' />,
                onClick: () => handleOpenUpdateForm(row.original),
                tooltipProps: {
                  content: 'Edit',
                  placement: 'top',
                },
              },
              {
                icon: <BranchCreateIcon color='white' />,
                tooltipProps: {
                  content: 'Create Hierarchy Row',
                  placement: 'top',
                },
                color: 'purple',
                onClick: onOpenHierarchiesForm(row.original),
              },
              {
                icon: <DeleteIcon color='white' />,
                onClick: () => handleDeleteRow(row.original),
                color: 'danger',
                tooltipProps: {
                  content: 'Remove',
                  placement: 'top',
                },
              },
              {
                icon: <InfoIcon color='white' />,
                onClick: () => handleOpenUpdateForm(row.original),
                color: 'info',
                tooltipProps: {
                  content: 'Mix Detail',
                  placement: 'top',
                },
                isHidden: mixDetails.length === 0,
              },
              {
                icon: <LeafIcon color='white' />,
                onClick: () => {
                  handleOpenUpdateForm(row.original)
                  setCurrentFormTab('epd')
                },
                color: 'success',
                tooltipProps: {
                  content: 'EPD',
                  placement: 'top',
                },
              },
            ]
          }}
          filterOptions={[
            {
              label: 'View',
              field: 'view',
              type: EFieldType.singleSelect,
              options: viewOptions,
            },
          ]}
          onCellEditEnd={handleEditCell}
        />
      </div>
      <DialogSellerProductForm
        isOpen={isOpenForm}
        onClose={onCloseSellerProductForm}
        formData={currentRowData}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        afterCreateMixDetail={afterCreateMixDetail}
        afterUpdateMixDetail={afterUpdateMixDetail}
        afterUpdateProductInCostTab={() => {
          refetchSellerProductsData()
        }}
        mixDetailData={mixDetailData}
        defaultTab={currentFormTab}
      />
      <DialogHierarchiesForm
        // hierarchyItem={formProps?.hierarchyItem}
        isOpen={hierarchiesForm.isOpen}
        onClose={onCloseHierarchiesForm}
        hierarchableType={EHierarchableType.SellerProduct}
        hierarchableId={hierarchiesForm.formData?.id}
        afterCreate={afterCreateHierarchies}
      />
    </>
  )
}

export default CompanySellerProductSubTab
