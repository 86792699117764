import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { IBTSPFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { IBTSPFormData } from '~/types/models/IBuyerTerminalSellerProduct'
import { useQuerySellerProducts, useQueryTerminals } from '~/hooks/useQueryData'
import { apiClient } from '~/api/ApiClient'
import _ from 'lodash'

const BTSPForm = (props: IBTSPFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const { buyerTerminalOptions, isLoadingTerminals } = useQueryTerminals()
  const { sellerProductOptions, isLoadingSellerProducts } =
    useQuerySellerProducts()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Buyer Terminal',
        name: 'buyerTerminalId',
        type: EFieldType.singleSelect,
        options: buyerTerminalOptions,
        isLoading: isLoadingTerminals,
        isRequired: true,
      },
      {
        label: 'Seller Product',
        name: 'sellerProductId',
        type: EFieldType.singleSelect,
        options: sellerProductOptions,
        isLoading: isLoadingSellerProducts,
        isRequired: true,
      },
    ],
    [
      buyerTerminalOptions,
      isLoadingSellerProducts,
      isLoadingTerminals,
      sellerProductOptions,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        buyerTerminalId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
        sellerProductId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
      }),
    [],
  )

  const defaultValues = useMemo<IBTSPFormData>(
    () => ({
      sellerProductId: null,
      buyerTerminalId: null,
    }),
    [],
  )

  const onCreate = useCallback(
    async (payload: IBTSPFormData) => {
      console.log('payload', payload)
      const { errors, ...res } =
        await apiClient.buyerTerminalSellerProducts.create({
          buyerTerminalSellerProduct: payload,
        })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: IBTSPFormData) => {
      if (formData?.id) {
        const { errors, ...response } =
          await apiClient.buyerTerminalSellerProducts.update(formData.id, {
            buyerTerminalSellerProduct: _.pick(payload, [
              'buyerTerminalId',
              'sellerProductId',
            ]),
          })
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IBTSPFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <ConcordFormStructure
      isLoading={isLoading}
      ref={formRef}
      formData={formData}
      error={error}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
      {...formProps}
    />
  )
}

export default BTSPForm
