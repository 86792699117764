import { IGetSentEmailsParams } from '~/types/models/IInvoice'
import { CommonDialogV2, ICommonDialogProps } from '../CommonDialogV2'
import {
  useQueryCompanies,
  useQueryEmails,
  useQuerySentEmails,
} from '~/hooks/useQueryData'
import SentEmailCard from './SentEmailCard'
import { Link } from 'react-router-dom'
import { Badge, ButtonGroup } from 'react-bootstrap'
import { EEmailableType, EEmailTypes } from '~/types/enums/EEmail'
import { useCallback, useMemo, useState } from 'react'
import { ToggleSection } from '../ToggleSection'
import { EditIcon, PlusIcon } from '../SvgIcons'
import { IEmail } from '~/types/models/IEmail'
import { ReusableButton } from '../ReusableButton'
import { apiClient } from '~/api/ApiClient'
import { useCompanyFormContext } from '~/contexts'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { DialogEmailForm } from '../ConcordForm'

import './styles.scss'
import buildObjectName from '~/utils/buildObjectName'

interface ISentEmailsDialogProps extends ICommonDialogProps {
  emailableType: IGetSentEmailsParams['filters']['emailableType']
  emailableId: IGetSentEmailsParams['filters']['emailableId']
  companyId?: number
}

function SentEmailsDialog(props: ISentEmailsDialogProps) {
  const { emailableId, emailableType, isOpen, companyId, ...dialogProps } =
    props

  const [emailForm, setEmailForm] = useState({
    isOpen: false,
    formData: undefined as Partial<IEmail> | undefined,
  })
  const [isFetchingCompany, setIsFetchingCompany] = useState(false)

  const { onOpenCompanyForm } = useCompanyFormContext()

  const { findCompanyById } = useQueryCompanies({})

  const company = findCompanyById(companyId)

  const { sentEmailsData, isSentEmailsDataLoading } = useQuerySentEmails(
    {
      filters: {
        emailableId,
        emailableType,
      },
    },
    { enabled: Boolean(emailableId && isOpen) },
  )

  const { emailsData, isEmailsFetching, refetchEmailsData, updateEmail } =
    useQueryEmails(
      {
        filters: {
          emailableId: companyId,
          emailableType: EEmailableType.company,
        },
      },
      { enabled: Boolean(companyId) },
    )

  const apEmail = emailsData.find(({ emailTypes }) =>
    (emailTypes as EEmailTypes[]).includes(EEmailTypes.ap),
  )

  const otherEmails = emailsData.filter(({ id }) => id !== apEmail?.id)

  const emailsDataOrdered = useMemo(() => {
    if (apEmail) {
      return [apEmail, ...otherEmails]
    }
    return otherEmails
  }, [apEmail, otherEmails])

  const onCloseEmailForm = useCallback(() => {
    setEmailForm({ isOpen: false, formData: undefined })
  }, [])

  const onOpenEmailForm = useCallback(
    (formData?: Partial<IEmail>) => () => {
      setEmailForm({ isOpen: true, formData })
    },
    [],
  )

  const onOpenCompanyUpdateForm = useCallback(
    (extraProps?: any) => async () => {
      setIsFetchingCompany(true)
      try {
        const response = await apiClient.companies.getById(companyId as number)
        onOpenCompanyForm({
          formData: response,
          groupsShownByDefault: ['contactInfo'],
          afterModifyEmails() {
            refetchEmailsData()
          },
          ...extraProps,
        })
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsFetchingCompany(false)
      }
    },
    [companyId, onOpenCompanyForm, refetchEmailsData],
  )

  return (
    <>
      <CommonDialogV2
        title={
          <div>
            <span>
              Emails:{' '}
              <Badge style={{ fontSize: 9, verticalAlign: 'middle' }}>
                {sentEmailsData.length}
              </Badge>
              <Link
                to={`${emailableType}s/${emailableId}`}
                style={{ marginLeft: 8 }}
              >
                {emailableType} - {emailableId}
              </Link>
            </span>
          </div>
        }
        size='lg'
        isOpen={isOpen}
        isHiddenOkButton
        isLoading={isSentEmailsDataLoading}
        {...dialogProps}
      >
        <ToggleSection
          label={company ? `Emails of ${buildObjectName(company)}` : 'Emails'}
          badges={[
            {
              label: emailsData.length,
              isHidden: emailsData.length === 0,
            },
          ]}
        >
          {!isEmailsFetching && (
            <ButtonGroup>
              <ReusableButton
                onClick={onOpenCompanyUpdateForm({
                  emailForm: {
                    show: true,
                  },
                })}
                size='sm'
                isDisabled={isFetchingCompany}
              >
                <PlusIcon color='white' />
              </ReusableButton>

              <ReusableButton
                variant='warning'
                onClick={onOpenCompanyUpdateForm()}
                size='sm'
              >
                <EditIcon color='white' size={12} />
              </ReusableButton>
            </ButtonGroup>
          )}
          <div style={{ marginTop: 8, marginRight: 8 }}>
            {emailsDataOrdered.map(email => (
              <div className='SentEmailsDialog__emailItem' key={email.id}>
                <a href={`mailto:${email.email}`}>{email.email}</a>
                <div>
                  {(email.emailTypes as EEmailTypes[]).map(type => (
                    <Badge key={type} style={{ marginRight: 4 }}>
                      {EEmailTypes[type]}
                    </Badge>
                  ))}
                  <span className='clickable' onClick={onOpenEmailForm(email)}>
                    <EditIcon color='var(--bs-orange)' />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </ToggleSection>

        {sentEmailsData.map((sentEmail, index) => (
          <SentEmailCard
            key={sentEmail.id}
            sentEmail={sentEmail}
            index={index}
          />
        ))}
      </CommonDialogV2>

      <DialogEmailForm
        isOpen={emailForm.isOpen}
        formData={emailForm.formData}
        afterUpdate={(email: IEmail) => {
          updateEmail(email.id, email)
          onCloseEmailForm()
        }}
        onClose={onCloseEmailForm}
      />
    </>
  )
}

export default SentEmailsDialog
