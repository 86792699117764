import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBTSPs from './useModifyBTSPs'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import {
  IBTSPGetListParams,
  IBuyerTerminalSellerProduct,
} from '~/types/models/IBuyerTerminalSellerProduct'
// import { ESalesProposalStatus } from '~/types/enums/ESalesProposal'
// import sleep from '~/utils/sleep'

const useQueryBTSPs = (
  params: IBTSPGetListParams,
  options?: Partial<UseQueryOptions<IBuyerTerminalSellerProduct[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addBtsp, updateBtsp, removeBtsp } = useModifyBTSPs(params)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'buyerTerminalSellerProducts',
      sessionUser?.id,
      buildGetUrl(apiClient.buyerTerminalSellerProducts.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    async queryFn() {
      const response = await apiClient.buyerTerminalSellerProducts.get(params)
      return response.buyerTerminalSellerProducts
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const btspsData = data || []

  return {
    btspsData,
    isBtspsDataLoading: isLoading,
    refetchBtspsData: refetch,
    addBtsp,
    updateBtsp,
    removeBtsp,
  }
}

export default useQueryBTSPs
