import React from 'react'

import { CommonDialogV2 } from '~/components/shared'
import AddressForm from './AddressForm'

import './DialogAddressForm.scss'

function DialogAddressForm(props) {
  const {
    addressableId,
    addressableType,
    addressData,
    afterCreate,
    afterUpdate,
    formData,
    onClose,
    hiddenFields,
    ...dialogProps
  } = props

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Address'
      isHiddenOkButton
      onClose={onClose}
    >
      <AddressForm
        isHiddenCancelButton
        hiddenFields={hiddenFields}
        addressableId={addressableId}
        addressableType={addressableType}
        addressData={addressData}
        afterCreate={data => {
          afterCreate && afterCreate(data)
          onClose && onClose()
        }}
        afterUpdate={data => {
          afterUpdate && afterUpdate(data)
          onClose && onClose()
        }}
        formData={formData}
      />
    </CommonDialogV2>
  )
}

export default React.memo(DialogAddressForm)
