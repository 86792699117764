import { CheckMarkIcon, CloseIcon } from '~/components/shared/SvgIcons'
import type {
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import { ReusableButton } from '~/components/shared/ReusableButton'
import { ButtonGroup, Spinner } from 'react-bootstrap'

export interface IRTEditActionButtonsProps<
  TData extends IReusableTableRowData,
> {
  row: RTRow<TData>
  table: ReusableTableInstance<TData>
  variant?: 'icon' | 'text'
}

export const RTEditActionButtons = <TData extends IReusableTableRowData>({
  row,
  table,
  variant = 'icon',
}: IRTEditActionButtonsProps<TData>) => {
  const {
    getState,
    options: {
      onCreatingRowCancel,
      onCreatingRowSave,
      onEditingRowCancel,
      onEditingRowSave,
    },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingRow,
  } = table
  const { creatingRow, editingRow, isSaving } = getState()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const handleCancel = () => {
    if (isCreating) {
      onCreatingRowCancel?.({ row, table })
      setCreatingRow(null)
    } else if (isEditing) {
      onEditingRowCancel?.({ row, table })
      setEditingRow(null)
    }
    row._valuesCache = {} as any //reset values cache
  }

  const handleSubmitRow = () => {
    //look for auto-filled input values
    Object.values(editInputRefs?.current)
      .filter(inputRef => row.id === inputRef?.name?.split('_')?.[0])
      ?.forEach(input => {
        if (
          input.value !== undefined &&
          Object.hasOwn(row?._valuesCache as object, input.name)
        ) {
          // @ts-ignore
          row._valuesCache[input.name] = input.value
        }
      })
    if (isCreating)
      onCreatingRowSave?.({
        exitCreatingMode: () => setCreatingRow(null),
        row,
        table,
        values: row._valuesCache,
        defaultValues: table.options.defaultValuesOnDraftRow,
      })
    else if (isEditing) {
      onEditingRowSave?.({
        exitEditingMode: () => setEditingRow(null),
        row,
        table,
        values: row?._valuesCache,
      })
    }
  }

  return (
    <ButtonGroup onClick={e => e.stopPropagation()}>
      {variant === 'icon' ? (
        <>
          <ReusableButton
            aria-label='cancel'
            onClick={handleCancel}
            style={{ fontSize: 13 }}
            variant='danger'
            tooltipContent='Cancel'
          >
            {isSaving ? (
              <Spinner
                style={{ verticalAlign: 'middle', width: 16, height: 16 }}
              />
            ) : (
              <CloseIcon color='white' />
            )}
          </ReusableButton>
          {((isCreating && onCreatingRowSave) ||
            (isEditing && onEditingRowSave)) && (
            <ReusableButton
              aria-label='save'
              color='info'
              isDisabled={isSaving}
              onClick={handleSubmitRow}
              style={{ fontSize: 13 }}
              variant='success'
              tooltipContent='Submit'
            >
              {isSaving ? (
                <Spinner
                  style={{ verticalAlign: 'middle', width: 16, height: 16 }}
                />
              ) : (
                <CheckMarkIcon color='white' />
              )}
            </ReusableButton>
          )}
        </>
      ) : (
        <>
          <button onClick={handleCancel} style={{ minWidth: '100px' }}>
            cancel
          </button>
          <button
            disabled={isSaving}
            onClick={handleSubmitRow}
            style={{ minWidth: '100px' }}
          >
            {isSaving && <div>circle progress</div>}
            save
          </button>
        </>
      )}
    </ButtonGroup>
  )
}
