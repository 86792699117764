import { useCallback, useMemo, useState } from 'react'
import { useDrag } from 'react-dnd'
import { useMeasure } from 'react-use'
import { IonIcon } from '@ionic/react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { colord } from 'colord'
import * as IonIcons from 'ionicons/icons'
import { useDispatch } from 'react-redux'
import { Badge } from 'react-bootstrap'

import {
  ToolTipOverlay,
  CompanyAvatar,
  TruckNumberSection,
  DropdownWithCustomChildren,
  IConcordFormOnChangeParams,
  MarkerPlusIcon,
  CustomTerminalOption,
  PlusIcon,
  DialogTerminalForm,
  DriverAvatar,
  DragIcon,
  DocumentPlusIcon,
} from '~/components/shared'

import buildFullName from '~/utils/buildFullName'
import { EScope } from '~/types/enums/ECommonEnum'
import { toastMessages } from '~/constants/toast-status-text'
import { DRIVER_TYPES } from '~/containers/OrderTracking/Utils/driverTypes'
import { apiClient } from '~/api/ApiClient'
import type {
  IDriverCardOnChangeParams,
  IDriverCardProps,
  IDriverDragItem,
} from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { ITruckFleet } from '~/types/models/ITruckFleet'
import type { ITerminal } from '~/types/models/ITerminal'
import type { IDriver } from '~/types/models/IDriver'
import {
  useQueryCompanies,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import { driverFleetsSlice } from '~/redux/reducers/data/driverFleets'
import buildObjectName from '~/utils/buildObjectName'
import { IOffday } from '~/types/models/IOffday'
import getColor from '~/utils/getColor'
import { useQuerySettings } from '~/hooks/useQueryData/useQuerySettings'
import { showOffDayRange } from '~/utils/offdayFormattedDays'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'

const SHOW_ELEMENTS_DEFAULT = [
  { name: 'driver', show: true },
  { name: 'driverName', show: true },
  { name: 'driverTerminal', show: true },
  { name: 'truckParkTerminal', show: true },
  { name: 'truckFleetTerminal', show: true },
]

const useDriverCard = (props: IDriverCardProps) => {
  const {
    driverFleetId,
    dragType = DRIVER_TYPES.UNASSIGNED,
    onDrag,
    onClose,
    isShowingDriverName,
    isDraggable = true,
    isCloseable = false,
    className,
    isHiddenStatusTime,
    onChangeNotes,
    isHiddenNotesButton = true,
    notes,
    isDriverLeft,
    index,
    indexBadgeStyle,
    extraIndexTooltipContent,
    loadsData = [],
    offdaysData,
    renderNoteTooltip,
    startTimesData,
    afterUpdateOffday,
    afterCreateOffday,
    overridenTruckFleetId,
    overridenWorkerTerminalId,
    onChangeTruckFleet: onChangeTruckFleetProp,
    onChangeWorkerTerminal,
    showParkTerminalIfDifferent,
    hideWorkerTerminal,
    hideTruckFleet,
    showStartTimeBadges,
    style,
  } = props

  const [createTerminalDialogState, setCreateTerminalDialogState] = useState({
    isOpen: false,
    terminal: undefined as ITerminal | undefined,
  })
  const [offdayForm, setOffdayForm] = useState({
    isOpen: false,
    formData: undefined as Partial<IOffday> | undefined,
  })

  const dispatch = useDispatch()

  const { driverFleetUsers, updateWorker, assignTruckFleetToDriverFleet } =
    useQueryUsers()

  const { truckFleetsData, currentTruckFleetOptions, updateTruckFleet } =
    useQueryTruckFleets()
  const {
    terminalsData,
    companyTerminalOptions,
    findTerminalById,
    updateTerminal,
  } = useQueryTerminals()
  const { getLoadStatus } = useQueryLoadStatuses()
  const { findCompanyById } = useQueryCompanies({})
  const { settingsData } = useQuerySettings({
    filters: { settingField: ['driver_card_view'] },
  })

  const viewSettings = useMemo(() => {
    if (!settingsData || !settingsData.length) return SHOW_ELEMENTS_DEFAULT
    return settingsData[0].settingValue.split('&').map((setting: string): {
      name: string
      show: boolean
    } => {
      const [name, val] = setting.split('=')
      return { name: name || '', show: val === 'on' || false }
    })
  }, [settingsData])

  const startTime = (startTimesData || []).find(
    st => st.driverFleetId === driverFleetId,
  )

  const startTimes = (startTimesData || []).filter(
    st => driverFleetId === st.driverFleetId,
  )

  const [measureRef, { width }] = useMeasure()

  const smallWidth = useMemo(() => width <= 80, [width])

  const user = useMemo(
    () =>
      driverFleetUsers.find(
        ({ driverFleet }) => driverFleet?.id === driverFleetId,
      ),
    [driverFleetId, driverFleetUsers],
  )

  const driverFleet = useMemo(() => user?.driverFleet, [user?.driverFleet])
  const worker = user?.worker

  const workerTerminalId =
    overridenWorkerTerminalId !== undefined
      ? overridenWorkerTerminalId
      : worker?.terminalId

  const workerTerminal = findTerminalById(workerTerminalId)

  const currentLoadOfDriverFleet = useMemo(() => {
    if (loadsData?.length > 0) {
      return loadsData.find(({ id }) => driverFleet?.loadId === id)
    }
  }, [driverFleet?.loadId, loadsData])

  const offday = useMemo(() => {
    return (offdaysData || []).find(
      ({ workerId }) => workerId === user?.worker?.id,
    )
  }, [offdaysData, user?.worker?.id])

  const hasOffday = useMemo(() => Boolean(offday), [offday])

  const truckFleetId = useMemo(
    () =>
      overridenTruckFleetId !== undefined
        ? overridenTruckFleetId
        : driverFleet?.currentTruckFleetId,
    [driverFleet?.currentTruckFleetId, overridenTruckFleetId],
  )

  const truckFleet = useMemo<ITruckFleet | undefined>(
    () => truckFleetsData.find(({ id }) => id === truckFleetId),
    [truckFleetId, truckFleetsData],
  )

  const truckFleetTerminal = useMemo(() => {
    if (truckFleet?.terminalId) {
      return terminalsData.find(({ id }) => id === truckFleet.terminalId)
    }
    return null
  }, [terminalsData, truckFleet?.terminalId])

  const parkTerminal = useMemo(
    () => findTerminalById(truckFleet?.truck?.parkTerminalId),
    [findTerminalById, truckFleet?.truck?.parkTerminalId],
  )

  const isParkTerminalDiff = truckFleetTerminal?.id !== parkTerminal?.id

  const workerTerminalCompany = useMemo(() => {
    if (workerTerminal) {
      return findCompanyById(workerTerminal.companyId)
    }

    return null
  }, [findCompanyById, workerTerminal])

  const [, dragRef] = useDrag<IDriverDragItem>(
    () => ({
      type: dragType as string,
      item: {
        driverFleet: driverFleet as IDriverFleet,
        truckFleet: truckFleet,
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        const dragItem: IDriverCardOnChangeParams = {
          dropResult,
          item,
        }
        onDrag && onDrag(dragItem)
      },
    }),
    [driverFleet, dragType, truckFleet],
  )

  const fullName = useMemo(() => buildFullName(user?.person), [user?.person])

  const onSaveNotes = useCallback(
    (notes: string) => {
      if (driverFleet) {
        onChangeNotes && onChangeNotes(driverFleet, notes)
      }
    },
    [driverFleet, onChangeNotes],
  )

  const renderLoadStatus = useMemo(() => {
    if (!isHiddenStatusTime) return null

    if (currentLoadOfDriverFleet && driverFleet) {
      const start = moment(currentLoadOfDriverFleet.updatedAt)
      const end = moment()
      const duration = moment.duration(end.diff(start))

      let toolTipText = `${currentLoadOfDriverFleet.status} @ ${moment(
        currentLoadOfDriverFleet.updatedAt,
      ).format('H:mm')}`

      const status = getLoadStatus(currentLoadOfDriverFleet.status)
      if (status?.code === 'DCO') {
        return null
      }
      return (
        <ToolTipOverlay content={toolTipText} placement='top'>
          <h4 className='align-self-center mb-0 me-1 statusBadge'>
            <Badge
              className='d-flex align-items-center shimmer-div'
              style={{ backgroundColor: status?.color }}
            >
              <IonIcon icon={(IonIcons as any)[status?.icon || '']} />
              &nbsp;{parseInt(duration.asMinutes().toString()) + 'm'}
            </Badge>
          </h4>
        </ToolTipOverlay>
      )
    }

    return null
  }, [currentLoadOfDriverFleet, driverFleet, getLoadStatus, isHiddenStatusTime])

  const renderNotes = useMemo(() => {
    if (isHiddenNotesButton) return null
    return (
      <NoteButton
        note={notes}
        onSave={onSaveNotes}
        renderNoteTooltip={renderNoteTooltip}
      />
    )
  }, [isHiddenNotesButton, notes, onSaveNotes, renderNoteTooltip])

  const onChangeTerminal = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: any,
      { value }: IConcordFormOnChangeParams,
    ) => {
      if (onChangeWorkerTerminal) {
        onChangeWorkerTerminal(value)
      } else {
        try {
          await apiClient.workers.update(driverFleet?.worker.id as number, {
            terminalId: value as number,
          })
          updateWorker(user?.worker?.id as number, {
            ...user?.worker,
            terminalId: value as number,
          })
          toast.success(toastMessages.updateSuccess)
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.updateError)
        }
      }
    },
    [
      driverFleet?.worker.id,
      onChangeWorkerTerminal,
      updateWorker,
      user?.worker,
    ],
  )

  const renderStartTimeBadges = useMemo(() => {
    if (showStartTimeBadges && startTimes.length) {
      return (
        <div>
          {startTimes.map(({ startTime, id, color }) => {
            const stColor = getColor(color)
            const isDark = colord(stColor).isDark()

            return (
              <span
                key={id}
                style={{
                  backgroundColor: stColor,
                  padding: 3,
                  borderRadius: 3,
                  color: isDark ? 'white' : 'black',
                  fontWeight: 600,
                }}
                className='me-1'
              >
                {moment(startTime).format('HH:mm')}
              </span>
            )
          })}
        </div>
      )
    }

    return null
  }, [showStartTimeBadges, startTimes])

  const renderWorkerTerminal = useMemo(() => {
    if (hideWorkerTerminal) {
      return null
    }
    return (
      <DropdownWithCustomChildren
        noHover
        options={companyTerminalOptions}
        onChange={onChangeTerminal}
        className='DriverCard__terminalCreateButton'
        components={{
          Option: CustomTerminalOption,
        }}
        value={workerTerminalId}
        showUpdateButtons
        renderForm={({ isOpen, optionSelected, onCloseForm }) => (
          <DialogTerminalForm
            formData={optionSelected?.item}
            isOpen={isOpen}
            onClose={onCloseForm}
            afterUpdate={(item: ITerminal) => {
              updateTerminal(item.id, item)
              onCloseForm()
            }}
          />
        )}
        getOptionLabel={opt => {
          const company = findCompanyById(opt.companyId)
          const labels = [company?.name, company?.code, opt.label]
            .filter(Boolean)
            .join(' - ')
          return labels
        }}
      >
        <ToolTipOverlay
          content={
            workerTerminal
              ? `Worker primary workplace: ${buildObjectName(workerTerminal)}`
              : "Add worker's terminal"
          }
          placement='top'
          allowToShow={Boolean(workerTerminal)}
        >
          <Badge
            pill
            bg=''
            style={{ backgroundColor: 'var(--ion-color-concord)' }}
            className='terminalSection'
          >
            {workerTerminal && (
              <CompanyAvatar
                width={20}
                height={20}
                company={{
                  value: workerTerminal?.id as number,
                  label: workerTerminalCompany?.name as string,
                }}
                companyType={EScope.seller}
                color={
                  getColor(workerTerminal?.color) ||
                  'var(--ion-color-cloudgrey)'
                }
                className='me-1'
              />
            )}

            {workerTerminal && <div>{workerTerminal?.code}</div>}

            {!workerTerminal && (
              <ToolTipOverlay content="Add worker's terminal" placement='top'>
                <span>
                  <MarkerPlusIcon color='white' size={18} />
                </span>
              </ToolTipOverlay>
            )}
          </Badge>
        </ToolTipOverlay>
      </DropdownWithCustomChildren>
    )
  }, [
    companyTerminalOptions,
    findCompanyById,
    onChangeTerminal,
    updateTerminal,
    workerTerminal,
    workerTerminalCompany?.name,
    workerTerminalId,
    hideWorkerTerminal,
  ])

  const onClickCloseIcon = useCallback(() => {
    onClose &&
      onClose({
        driver: driverFleet?.driver as IDriver,
        driverFleet: driverFleet as IDriverFleet,
        truckFleet,
      })
  }, [driverFleet, onClose, truckFleet])

  const onChangeTruckFleetTerminal = useCallback(
    async (event: any, { value }: any) => {
      try {
        const res = await apiClient.truckFleets.update(
          truckFleet?.id as number,
          {
            truckFleet: {
              terminalId: value,
            },
          },
        )
        updateTruckFleet(res.id, res)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateSuccess)
      }
    },
    [truckFleet?.id, updateTruckFleet],
  )

  const onRightClickTerminal = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      setCreateTerminalDialogState({
        isOpen: true,
        terminal: truckFleetTerminal as ITerminal,
      })
    },
    [truckFleetTerminal],
  )

  const onCloseTerminalDialog = useCallback(() => {
    setCreateTerminalDialogState({
      isOpen: false,
      terminal: undefined,
    })
  }, [])

  const onChangeTruckFleet = useCallback(
    (event: any, { value }: any) => {
      if (onChangeTruckFleetProp) {
        onChangeTruckFleetProp(value)
      } else {
        apiClient.driverFleets.update(driverFleet?.id as number, {
          currentTruckFleetId: value,
        })
        assignTruckFleetToDriverFleet(value, driverFleet?.id as any)
        dispatch(
          driverFleetsSlice.actions.update({
            id: driverFleet?.id,
            currentTruckFleetId: value,
          }),
        )
        toast.success(toastMessages.updateSuccess)
      }
    },
    [
      assignTruckFleetToDriverFleet,
      dispatch,
      driverFleet?.id,
      onChangeTruckFleetProp,
    ],
  )

  const renderTruck = useMemo(() => {
    if (hideTruckFleet) return null

    const badgeColor = getColor(truckFleet?.color) || '#e5e5e5'
    const avatarColor = colord(badgeColor).isDark() ? '#fffffe' : 'black'
    return (
      <Badge pill bg='' style={{ backgroundColor: badgeColor }}>
        {truckFleet && (
          <DropdownWithCustomChildren
            className='no-hover DriverCard__truckSelector'
            options={companyTerminalOptions}
            value={truckFleetTerminal?.id}
            onChange={onChangeTruckFleetTerminal}
            showUpdateButtons
            renderForm={({ isOpen, optionSelected, onCloseForm }) => (
              <DialogTerminalForm
                formData={optionSelected?.item}
                isOpen={isOpen}
                onClose={onCloseForm}
                afterUpdate={(item: ITerminal) => {
                  updateTerminal(item.id, item)
                  onCloseForm()
                }}
              />
            )}
            isReadOnly={!truckFleet}
            components={{
              Option: CustomTerminalOption,
            }}
            getOptionLabel={opt => {
              const company = findCompanyById(opt.companyId)
              const labels = [company?.name, company?.code, opt.label]
                .filter(Boolean)
                .join(' - ')
              return labels
            }}
          >
            <CompanyAvatar
              color={avatarColor}
              company={{
                value: truckFleetTerminal?.id as number,
                label: truckFleetTerminal?.name || '-',
              }}
              className='me-1'
              width={20}
              height={20}
              onContextMenu={onRightClickTerminal}
              tooltipMessage={
                truckFleetTerminal?.id
                  ? 'Next pickup terminal: ' + truckFleetTerminal?.name
                  : 'Set next pickup terminal'
              }
            />
          </DropdownWithCustomChildren>
        )}

        <DropdownWithCustomChildren
          className='no-hover DriverCard__truckSelector'
          options={currentTruckFleetOptions}
          value={truckFleet?.id}
          onChange={onChangeTruckFleet}
        >
          {truckFleet ? (
            <TruckNumberSection
              style={{ color: colord(badgeColor).isDark() ? 'white' : 'black' }}
              truckId={truckFleet?.truckId}
            />
          ) : (
            <ToolTipOverlay
              placement='top'
              content='Click to assign a truck to this driver'
            >
              <span>
                <PlusIcon size={22} color='white' />
              </span>
            </ToolTipOverlay>
          )}
        </DropdownWithCustomChildren>
      </Badge>
    )
  }, [
    truckFleet,
    companyTerminalOptions,
    truckFleetTerminal?.id,
    truckFleetTerminal?.name,
    onChangeTruckFleetTerminal,
    onRightClickTerminal,
    currentTruckFleetOptions,
    onChangeTruckFleet,
    updateTerminal,
    findCompanyById,
    hideTruckFleet,
  ])

  const renderDriverAvatar = useMemo(
    () => (
      <div className='driverAvatar me-1'>
        <DriverAvatar
          driverFleetId={driverFleetId}
          isHiddenInfo
          width={20}
          height={20}
          className='DriverCard__avatar'
        />
      </div>
    ),
    [driverFleetId],
  )

  const renderDriverName = useMemo(
    () => <span className='driverFullName me-1'>{fullName}</span>,
    [fullName],
  )

  const renderParkTerminal = useMemo(() => {
    if (!isParkTerminalDiff || !showParkTerminalIfDifferent || !parkTerminal)
      return null

    return (
      <ToolTipOverlay placement='top' content='Park Terminal'>
        <Badge
          style={{
            background:
              getColor(parkTerminal?.color) ||
              colord('dark').lighten(0.2).toRgbString(),
            color: 'white',
          }}
          className='me-1'
        >
          {parkTerminal?.name}
        </Badge>
      </ToolTipOverlay>
    )
  }, [isParkTerminalDiff, parkTerminal, showParkTerminalIfDifferent])

  const renderOffDay = useMemo(() => {
    if (hasOffday)
      return (
        <Badge className='me-1' bg='info'>
          <span style={{ verticalAlign: 'middle' }}>
            <IonIcon icon={IonIcons.calendar} />
          </span>
          <span style={{ verticalAlign: 'middle', marginLeft: 4 }}>
            {showOffDayRange(offday)}
          </span>
        </Badge>
      )

    const onShowOffdayForm = (event: any) => {
      event.stopPropagation()
      setOffdayForm({
        isOpen: true,
        formData: {
          workerId: worker?.id,
        },
      })
    }

    if (afterCreateOffday)
      return (
        <ToolTipOverlay content='Add off day' placement='top'>
          <span className='clickable' onClick={onShowOffdayForm}>
            <DocumentPlusIcon size={20} />
          </span>
        </ToolTipOverlay>
      )
    return null
  }, [afterCreateOffday, hasOffday, offday, worker?.id])

  const onCloseOffdayForm = () => {
    setOffdayForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const renderDriverData = useMemo(() => {
    return (
      <>
        {isDraggable && (
          <span>
            <DragIcon className='fs-2 flex-shrink-0 me-2' />
          </span>
        )}
        {viewSettings.map((setting: { name: string; show: boolean }) => {
          const { name, show } = setting
          if (!show) return null
          switch (name) {
            case 'driver':
              return renderDriverAvatar
            case 'driverName':
              return renderDriverName
            case 'driverTerminal':
              return renderWorkerTerminal
            case 'truckParkTerminal':
              return renderParkTerminal
            case 'truckFleetTerminal':
              return renderTruck
            default:
              return null
          }
        })}
        {renderNotes}
        {renderStartTimeBadges}
        {renderOffDay}
      </>
    )
  }, [
    isDraggable,
    renderDriverAvatar,
    renderDriverName,
    renderNotes,
    renderOffDay,
    renderParkTerminal,
    renderStartTimeBadges,
    renderTruck,
    renderWorkerTerminal,
    viewSettings,
  ])

  return {
    renderLoadStatus,
    renderTruck,
    driverFleet,
    createTerminalDialogState,
    onCloseTerminalDialog,
    dragRef,
    fullName,
    onClickCloseIcon,
    driverFleetId,
    isShowingDriverName,
    isDraggable,
    isCloseable,
    workerTerminal,
    workerTerminalCompany,
    className,

    measureRef,
    width,
    smallWidth,
    renderWorkerTerminal,
    onSaveNotes,

    notes,
    isDriverLeft,
    hasOffday,
    offday,
    index,
    indexBadgeStyle,
    extraIndexTooltipContent,
    renderNoteTooltip,
    startTime,
    offdayForm,
    onCloseOffdayForm,
    afterUpdateOffday,
    afterCreateOffday,
    isParkTerminalDiff,
    parkTerminal,
    showParkTerminalIfDifferent,
    hideWorkerTerminal,
    hideTruckFleet,
    showStartTimeBadges,
    startTimes,
    renderStartTimeBadges,
    style,
    renderDriverData,
  }
}

export default useDriverCard
