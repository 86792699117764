import { sortDataFromSortList, getLocalSortFields } from '~/utils/sortTables'

export const getBillLines = (billLine, invoice) => {
  const { billLines, compareInvoice } = invoice
  const sameLoadLines = billLines.filter(
    b => b.load && b.loadId == billLine.loadId,
  )
  if (sameLoadLines.length == 0) {
    sameLoadLines.push(billLine)
  }
  const newList = sameLoadLines.map(line => {
    const compareLines =
      compareInvoice && compareInvoice.billLines
        ? compareInvoice.billLines?.filter(
            cBillLine => cBillLine.id == line.billLineId,
          )
        : []

    return {
      ...line,
      compareLines,
    }
  })

  return newList
}

export const groupBillLines = (billLines = []) => {
  const loadOrders = []
  billLines.forEach(line => {
    if (!line.loadId) {
      //load is empty
      loadOrders.push({
        //add new group
        loadId: line.loadId,
        billLineId: line.id,
        billLines: [line],
      })
    } else {
      const index = loadOrders.findIndex(row => row.loadId == line.loadId)
      if (index > -1) {
        //existed
        loadOrders[index].billLines.push(line)
      } else {
        //add new group when unexisted load
        loadOrders.push({
          loadId: line.loadId,
          billLines: [line],
        })
      }
    }
  })

  return loadOrders
}

export const getNextLine = (currentBillLine, groupedBillLines) => {
  let loadIndex = 0
  if (!currentBillLine.loadId) {
    //load is empty => get via bill line id
    loadIndex = groupedBillLines.findIndex(
      row => row.billLineId == currentBillLine.id,
    )
  } else {
    loadIndex = groupedBillLines.findIndex(
      row => row.loadId == currentBillLine.loadId,
    )
  }

  return {
    actions: {
      canNext: loadIndex < groupedBillLines.length - 2,
      canBack: true,
    },
    //get first line
    billLine: groupedBillLines[loadIndex + 1].billLines[0],
  }
}

export const getPreviousLine = (currentBillLine, groupedBillLines) => {
  let loadIndex = 0
  if (!currentBillLine.loadId) {
    //load is empty => get via bill line id
    loadIndex = groupedBillLines.findIndex(
      row => row.billLineId == currentBillLine.id,
    )
  } else {
    loadIndex = groupedBillLines.findIndex(
      row => row.loadId == currentBillLine.loadId,
    )
  }

  return {
    actions: {
      canBack: loadIndex > 1,
      canNext: true,
    },
    //get first line
    billLine: groupedBillLines[loadIndex - 1].billLines[0],
  }
}

export const getCurrentLine = (currentBillLine, groupedBillLines) => {
  let loadIndex = 0
  if (!currentBillLine.loadId) {
    //load is empty => get via bill line id
    loadIndex = groupedBillLines.findIndex(
      row => row.billLineId == currentBillLine.id,
    )
  } else {
    loadIndex = groupedBillLines.findIndex(
      row => row.loadId == currentBillLine.loadId,
    )
  }

  return {
    actions: {
      canBack: loadIndex > 1,
      canNext: loadIndex < groupedBillLines.length - 2,
    },
    //get first line
    billLine: groupedBillLines[loadIndex].billLines[0],
  }
}

export const getBillLinesByKey = (
  billLine,
  invoice,
  byKey = 'loadId',
  billLines,
) => {
  const { compareInvoice } = invoice
  const sameLines = billLines.filter(
    b => b[byKey] && b[byKey] == billLine[byKey],
  )
  if (sameLines.length == 0) {
    sameLines.push(billLine)
  }
  const newList = sameLines.map(line => {
    const compareLines =
      compareInvoice && compareInvoice.billLines
        ? compareInvoice.billLines?.filter(
            cBillLine => cBillLine.id == line.billLineId,
          )
        : []

    return {
      ...line,
      compareLines,
    }
  })

  return newList
}

export const getLoadImage = (billLine, invoice, isCompareInvoice) => {
  if (!isCompareInvoice) {
    return billLine.load?.loadImage
  }
  const { compareInvoice } = invoice
  if (compareInvoice && compareInvoice.billLines) {
    const compareBillLine = compareInvoice.billLines?.find(
      cBillLine => cBillLine.id == billLine.billLineId,
    )

    return compareBillLine?.load?.loadImage
  }

  return undefined
}

export const groupBillLinesByKey = (
  billLines = [],
  byKey = 'loadId',
  sortOptions = [],
) => {
  const loadOrders = []
  if (sortOptions) {
    billLines = sortDataFromSortList(billLines, getLocalSortFields(sortOptions))
  }
  if (billLines.length > 0) {
    billLines.forEach(line => {
      if (!line.primaryKey) {
        //load is empty
        loadOrders.push({
          //add new group
          primaryKey: line[byKey],
          secondaryKey: line.id,
          billLines: [line],
        })
      } else {
        const index = loadOrders.findIndex(
          row => row.primaryKey == line.primaryKey,
        )
        if (index > -1) {
          //existed
          loadOrders[index].billLines.push(line)
        } else {
          //add new group when unexisted load
          loadOrders.push({
            primaryKey: line[byKey],
            billLines: [line],
          })
        }
      }
    })
  }

  return loadOrders
}

export const getNextLineByKey = (
  currentBillLine,
  groupedBillLines,
  byKey = 'loadId',
  isNextLoad = false,
  browsedIds = [],
) => {
  let loadIndex = 0
  if (isNextLoad) {
    if (currentBillLine[byKey]) {
      const firstIndex = groupedBillLines.findIndex(
        billLine => billLine.primaryKey == currentBillLine[byKey],
      )
      for (let i = firstIndex; i < groupedBillLines.length; i++) {
        if (
          groupedBillLines[i].primaryKey != currentBillLine[byKey] &&
          !browsedIds.includes(groupedBillLines[i].primaryKey)
        ) {
          loadIndex = i - 1
          break
        }
      }
    } else {
      loadIndex = groupedBillLines.findIndex(
        row => row.secondaryKey == currentBillLine.id,
      )
    }
  } else {
    loadIndex = groupedBillLines.findIndex(
      row => row.secondaryKey == currentBillLine.id,
    )
  }

  return {
    actions: {
      canNext: loadIndex < groupedBillLines.length - 2,
      canBack: true,
    },
    //get first line
    billLine: groupedBillLines[loadIndex + 1].billLines[0],
    loadIndex: loadIndex + 1,
  }
}

export const getPreviousLineByKey = (
  currentBillLine,
  groupedBillLines,
  isPreviousLoad = false,
  browsedIds = [],
) => {
  let loadIndex = 0
  if (isPreviousLoad) {
    if (browsedIds.length > 0) {
      if (browsedIds[browsedIds.length - 1]) {
        const firstIndex = groupedBillLines.findIndex(
          billLine => billLine.primaryKey == browsedIds[browsedIds.length - 1],
        )
        if (firstIndex > 1) {
          loadIndex = firstIndex
        } else {
          loadIndex = 1 // Return the start list
        }
      } else {
        loadIndex = groupedBillLines.findIndex(
          row => row.secondaryKey == currentBillLine.id,
        )
      }
    }
  } else {
    loadIndex = groupedBillLines.findIndex(
      row => row.secondaryKey == currentBillLine.id,
    )
  }

  return {
    actions: {
      canBack: loadIndex > 1,
      canNext: true,
    },
    //get first line
    billLine: groupedBillLines[loadIndex - 1].billLines[0],
    loadIndex: loadIndex - 1,
  }
}

export const getCurrentLineByKey = (currentBillLine, groupedBillLines) => {
  let loadIndex = 0
  loadIndex = groupedBillLines.findIndex(
    row => row.secondaryKey == currentBillLine.id,
  )

  return {
    actions: {
      canBack: loadIndex > 0,
      canNext: loadIndex < groupedBillLines.length - 2,
    },
    //get first line
    billLine: groupedBillLines[loadIndex].billLines[0],
    loadIndex,
  }
}
