import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import useModifyBidders from './useModifyBidders'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { IBidder, IBidderGettingListParams } from '~/types/models/IBidder'
import { useQueryCompanies } from '../useQueryCompanies'
import _ from 'lodash'

const useQueryBidders = (
  params: IBidderGettingListParams,
  options?: Partial<UseQueryOptions<IBidder[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched, refetch, isFetching } = useQuery({
    queryKey: [
      'bidders',
      sessionUser?.id,
      buildGetUrl(apiClient.bidders.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    async queryFn() {
      const response = await apiClient.bidders.get(params)
      return response.bidders
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { findCompanyById } = useQueryCompanies({})

  const biddersData = useMemo(() => data || [], [data])

  const findBidderById = (id: number | undefined | null) =>
    biddersData.find(i => i.id === id)

  const { updateBidder, addBidder, removeBidder } = useModifyBidders(params)

  const bidderOptions = useMemo(
    () =>
      _.orderBy(
        biddersData.map(item => {
          const buyer = findCompanyById(item.buyerId)
          const label = [
            item.rank ? `Rank #${item.rank}` : undefined,
            buyer?.code,
            buyer?.name,
            _.startCase(item.status),
          ]
            .filter(Boolean)
            .join(' - ')

          return {
            value: item.id,
            label,
            item,
          }
        }),
        'item.rank',
        'asc',
      ),
    [biddersData, findCompanyById],
  )

  return {
    biddersData,
    isBiddersDataLoading: isLoading,
    isBiddersDataFetching: isFetching,
    isBiddersDataFetched: isFetched,
    bidderOptions,
    findBidderById,
    updateBidder,
    addBidder,
    removeBidder,
    refetchBidders: refetch,
  }
}

export default useQueryBidders
