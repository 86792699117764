import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'
import { IDialogBTSPFormProps } from './type'
import BTSPForm from './BTSPForm'

function DialogBTSPForm(props: IDialogBTSPFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    disabledFields,
    hiddenFields,
    ...modalProps
  } = props
  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Seller Product'
      isHiddenOkButton
      onClose={onClose}
    >
      <BTSPForm
        formData={formData}
        disabledFields={disabledFields}
        hiddenFields={hiddenFields}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}
export default DialogBTSPForm
