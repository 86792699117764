import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useQueryAllowedHierarchiesNew,
  useQueryHierarchies,
  useQueryHierarchyRows,
  useQueryPricings,
  useQuerySellerProducts,
  useQueryUoms,
} from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import { apiClient } from '~/api/ApiClient'
import { EFieldType, EYesNo } from '~/types/enums/ECommonEnum'

import type { IPricingsTableProps } from './type'
import { IRTColumnDef, RTCell } from '~/components/shared'
import { useWindowSize } from 'react-use'
import { IPricing } from '~/types/models/IPricing'
import { toast } from 'react-toastify'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { EPricingType } from '~/types/enums/EPricing'
import { getPricingDate } from '~/utils/formatPricingDate'
import { Badge } from 'react-bootstrap'
import { toastMessages } from '~/constants/toast-status-text'
import moment from 'moment'
import { ColumnFiltersState } from '@tanstack/react-table'
import { produce } from 'immer'
import mergeArrayById from '~/utils/mergeArrayById'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import useGetHierarchyLabel from '~/hooks/useGetHierarchyLabel'

const usePricingsTable = (props: IPricingsTableProps) => {
  const {
    className,
    queryOptions,
    defaultColumnFilters,
    toolbarProps,
    billLineData,
    invoiceData,
    includeInvoiceId,
    afterCreate: afterCreatePricing,
    afterUpdate: afterUpdatePricing,
  } = props

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const [formModalState, setFormModalState] = useState({
    isOpen: false,
    formData: undefined as IPricing | undefined,
  })
  const [updateForm, setUpdateForm] = useState({
    isOpen: false,
    formData: undefined as IPricing | undefined,
  })
  const [hierarchiesForm, setHierarchiesForm] = useState({
    isOpen: false,
    formData: undefined as IPricing | undefined,
  })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'hierarchyScope.sellerSideType',
      value: null,
    },
    {
      id: 'hierarchyScope.sellerSideId',
      value: null,
    },
    {
      id: 'sellerProductId',
      value: undefined,
    },
    {
      id: 'forDate',
      value: undefined,
    },
    {
      id: 'amount',
      value: {},
    },
    {
      id: 'searchForBillLine',
      value: EYesNo.Yes,
    },
  ])
  const [pricingChangeModal, setPricingChangeModal] = useState({
    isOpen: false,
    pricing: undefined as IPricing | undefined,
  })

  const filterData = useMemo(() => {
    const result = columnFilters.reduce((acc: any, item) => {
      const [mainKey, subKey] = item.id.split('.')

      if (subKey) {
        // If it's a nested key, ensure the mainKey is an object
        acc[mainKey] = acc[mainKey] || {}
        acc[mainKey][subKey] = item.value
      } else {
        // Otherwise, directly assign the value
        acc[item.id] = item.value
      }

      return acc
    }, {})

    return produce(result, (draft: any) => {
      if (draft.hierarchyScope) {
        if (draft.hierarchyScope.sellerSideType === null) {
          delete draft.hierarchyScope.sellerSideId
          delete draft.hierarchyScope.sellerSideType
        }
      }
      if (!billLineData) {
        delete draft.searchForBillLine
      }
    }) as any
  }, [billLineData, columnFilters])

  const { findSellerProductById } = useQuerySellerProducts()
  const { findUomById } = useQueryUoms()

  const {
    pricingsData,
    isLoadingPricings,
    removePricing,
    updatePricing,
    refetchPricingsData,
  } = useQueryPricings(
    {
      filters: produce(filterData, (draft: any) => {
        if (draft.searchForBillLine === EYesNo.Yes) {
          draft.billLineId = billLineData?.id
        }
        delete draft.searchForBillLine
      }) as any,
    },
    queryOptions,
  )

  const { refetchHierarchyRows, hierarchyRowsData } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableId: pricingsData.map(({ id }) => id),
        hierarchableType: EHierarchableType.Pricing,
      },
    },
    { enabled: pricingsData.length > 0 },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { refetchHierarchies, hierarchiesData } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    {
      enabled: pricingsData.length > 0 && hierarchyIds.length > 0,
    },
  )

  const { getHierarchyLabel } = useGetHierarchyLabel()

  const {
    ahSellerSideOptions,
    ahBuyerSideOptions,
    getSellerSideIdOptions,
    getBuyerSideIdOptions,
  } = useQueryAllowedHierarchiesNew()

  const filterOptions = [
    {
      label: 'Date',
      field: 'forDate',
      type: EFieldType.date,
    },
    {
      label: 'Amount',
      field: 'amount',
      type: EFieldType.number,
    },
    {
      label: 'Bill Line Filter',
      field: 'searchForBillLine',
      type: EFieldType.singleSelect,
      isHidden: !billLineData,
      options: [
        {
          label: 'Yes',
          value: EYesNo.Yes,
        },
        {
          label: 'No',
          value: EYesNo.No,
        },
      ],
    },
    {
      label: 'Seller Side Type',
      field: 'hierarchyScope.sellerSideType',
      type: EFieldType.singleSelect,
      options: ahSellerSideOptions,
    },
    {
      label: 'Seller Side #',
      field: 'hierarchyScope.sellerSideId',
      type: EFieldType.singleSelect,
      options: getSellerSideIdOptions(
        filterData.hierarchyScope?.sellerSideType,
      ),
    },
    {
      label: 'Buyer Side Type',
      field: 'hierarchyScope.buyerSideType',
      type: EFieldType.singleSelect,
      options: ahBuyerSideOptions,
    },
    {
      label: 'Buyer Side #',
      field: 'hierarchyScope.buyerSideId',
      type: EFieldType.singleSelect,
      options: getBuyerSideIdOptions(filterData.hierarchyScope?.buyerSideType),
    },
  ]

  const onOpenHierarchiesForm = (autoCharge: IPricing) => () => {
    setHierarchiesForm({
      isOpen: true,
      formData: autoCharge,
    })
  }

  const onCloseHierarchiesForm = () => {
    setHierarchiesForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onCloseFormModal = useCallback(() => {
    setFormModalState({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const onOpenCreateFormModal = useCallback(() => {
    setFormModalState({
      isOpen: true,
      formData: undefined,
    })
  }, [])

  const onShowUpdateForm = useCallback(
    (pricing: IPricing) => () => {
      setUpdateForm({
        isOpen: true,
        formData: pricing,
      })
    },
    [],
  )
  const afterCreateHierarchies = () => {
    refetchHierarchyRows()
    onCloseHierarchiesForm()
  }

  const afterCreate = useCallback(() => {
    refetchPricingsData()
    refetchHierarchyRows()
    refetchHierarchies()
    afterCreatePricing && afterCreatePricing()
  }, [
    afterCreatePricing,
    refetchHierarchies,
    refetchHierarchyRows,
    refetchPricingsData,
  ])

  const afterUpdate = useCallback(
    (pricing: IPricing) => {
      updatePricing(pricing.id, pricing)
      refetchHierarchyRows()
      refetchHierarchies()
      afterUpdatePricing && afterUpdatePricing()
    },
    [
      afterUpdatePricing,
      refetchHierarchies,
      refetchHierarchyRows,
      updatePricing,
    ],
  )

  const afterPriceChanges = () => {
    refetchPricingsData()
    refetchHierarchyRows()
    refetchHierarchies()
    afterCreatePricing && afterCreatePricing()
    setPricingChangeModal({
      isOpen: false,
      pricing: undefined,
    })
  }

  const onCellEditEnd = async (value: any, cell: RTCell<IPricing>) => {
    try {
      const { column, row } = cell
      let columnField = column.id
      const rowId = row.original.id
      let newValue = value

      if (['startDate', 'endDate'].includes(columnField)) {
        const pricingDate = getPricingDate(row.original.dateRange)
        const newDateRange = {
          ...pricingDate,
          [columnField]: newValue,
        }
        const startDate = newDateRange.startDate
          ? moment(newDateRange.startDate).format('YYYY-MM-DD')
          : 'Infinity'
        const endDate = newDateRange.endDate
          ? moment(newDateRange.endDate).format('YYYY-MM-DD')
          : '-Infinity'
        newValue = `${startDate}...${endDate}`
        columnField = 'dateRange'
      }
      const response = await apiClient.pricings.update(rowId, {
        pricing: {
          [columnField]: newValue,
          invoiceId: includeInvoiceId ? invoiceData?.id : undefined,
        },
      })
      if (response.errors[0]) {
        toast.error(response.errors[0])
      } else {
        updatePricing(response.id, response)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    }
  }

  const onOpenEditDialogForm = useCallback(
    (rowData: IPricing) => () => {
      setFormModalState({
        isOpen: true,
        formData: rowData,
      })
    },
    [],
  )

  const onClosePricingUpdateForm = useCallback(() => {
    setUpdateForm({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const onRemove = useCallback(
    (rowData: IPricing) => async () => {
      const result = await confirmation({
        message: 'Are you sure you want to delete this data',
      })
      if (result === EYesNo.Yes) {
        apiClient.pricings.delete(rowData.id, {
          invoiceId: includeInvoiceId ? invoiceData?.id : undefined,
        })
        removePricing(rowData.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const columns = useMemo<IRTColumnDef<IPricing>[]>(
    () => [
      {
        header: 'Amount',
        accessorKey: 'amount',
        align: 'right',
        minSize: 100,
        maxSize: 100,
        enableEditing: true,
        editVariant: EFieldType.number,
        editNumberFieldProps: () => ({
          prefix: '$',
          decimalScale: 2,
          fixedDecimalScale: true,
        }),
        Cell({ cell }) {
          const cellValue = cell.getValue<any>()
          return formatCurrencyToDollar.format(cellValue)
        },
      },
      {
        header: 'Pricing Type',
        accessorKey: 'pricingType',
        minSize: 140,
        maxSize: 140,
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions({ row }) {
          const rowData = row.original
          const sellerProduct = findSellerProductById(rowData.sellerProductId)
          const prodUom = findUomById(sellerProduct?.uomId)

          return [
            {
              label: prodUom ? `Per Qty (${prodUom.code})` : 'Per Qty',
              value: EPricingType.perQty,
            },
            {
              label: 'Per Load',
              value: EPricingType.perLoad,
            },
            {
              label: 'Flat Fee',
              value: EPricingType.flatFee,
            },
            {
              label: 'Percentage',
              value: EPricingType.percentage,
            },
          ]
        },
      },
      {
        header: 'Start date',
        id: 'startDate',
        accessorFn: row => {
          const dateRange = getPricingDate(row.dateRange)
          return dateRange?.startDate
        },
        enableEditing: true,
        editVariant: EFieldType.date,
        editDateFieldProps({ row }) {
          const endDate = row.getValue<string | undefined>('endDate')
          return {
            maxDate: endDate,
          }
        },
      },
      {
        header: 'End date',
        id: 'endDate',
        accessorFn: row => {
          const dateRange = getPricingDate(row.dateRange)
          return dateRange?.endDate
        },
        enableEditing: true,
        editVariant: EFieldType.date,
        editDateFieldProps({ row }) {
          const startDate = row.getValue<string | undefined>('startDate')
          return {
            minDate: startDate,
          }
        },
      },
      {
        header: 'Hierarchies',
        accessorKey: 'hierarchies',
        minSize: 500,
        Cell({ row }) {
          const rowData = row.original
          const hierarchyRows = hierarchyRowsData
            .filter(({ hierarchableId }) => hierarchableId === rowData.id)
            .map(({ hierarchyId }) => hierarchyId)
          const hierarchies = hierarchiesData.filter(({ id }) =>
            hierarchyRows.includes(id),
          )
          const labels = hierarchies.map(item => getHierarchyLabel(item))
          return (
            <div>
              {labels.map((label, index) => (
                <div key={index}>
                  <Badge style={{ marginRight: 4 }}>{index + 1}</Badge>
                  {label}
                </div>
              ))}
            </div>
          )
        },
      },
    ],
    [
      findSellerProductById,
      findUomById,
      getHierarchyLabel,
      hierarchiesData,
      hierarchyRowsData,
    ],
  )

  useEffect(() => {
    if (defaultColumnFilters) {
      setColumnFilters(prev => {
        const result = mergeArrayById(prev, defaultColumnFilters)
        return result
      })
    }
  }, [defaultColumnFilters])

  return {
    columns,
    formModalState,
    className,
    windowSize,
    pricingsData,
    isLoadingPricings,
    filterOptions,
    columnFilters,
    toolbarProps,
    billLineData,
    invoiceData,
    updateForm,
    includeInvoiceId,
    pricingChangeModal,
    hierarchyRowsData,
    hierarchiesData,
    hierarchiesForm,
    onCellEditEnd,
    afterCreate,
    afterUpdate,
    onCloseFormModal,
    onOpenCreateFormModal,
    onOpenEditDialogForm,
    onRemove,
    setColumnFilters,
    onShowUpdateForm,
    onClosePricingUpdateForm,
    setPricingChangeModal,
    afterPriceChanges,
    onOpenHierarchiesForm,
    onCloseHierarchiesForm,
    afterCreateHierarchies,
  }
}

export default usePricingsTable
