import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryHierarchies,
  useQueryHierarchyRows,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import useQueryAutoCharges from '~/hooks/useQueryData/useQueryAutoCharges/useQueryAutoCharges'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EFieldType, EScope, EYesNo } from '~/types/enums/ECommonEnum'
import {
  IAutoCharge,
  IUpdateAutoChargePayload,
} from '~/types/models/IAutoCharge'
import { EAutoChargeDateRangeType } from '~/types/models/IAutoCharge'
import { ICompany } from '~/types/models/ICompany'
import { ACTIVE_OPTIONS } from '~/utils/constants'
import { makeOptions } from '~/utils/utils'
import { useConfirmationProvider } from '~/contexts'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { useWindowSize } from 'react-use'
import { IRTColumnDef, RTCell } from '~/components/shared'
import { ColumnFiltersState } from '@tanstack/react-table'
import { getPricingDate } from '~/utils/formatPricingDate'
import moment from 'moment'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import useGetHierarchyLabel from '~/hooks/useGetHierarchyLabel'
import { IAutoChargesTableProps } from './type'
import mergeArrayById from '~/utils/mergeArrayById'
import { Badge } from 'react-bootstrap'

export const useAutoChargesTable = (props: IAutoChargesTableProps) => {
  const {
    columnFilters: columnFiltersProp,
    formProps,
    toolbarProps,
    style,
  } = props

  const [formModalState, setFormModalState] = useState({
    isOpen: false,
    formData: undefined as IAutoCharge | undefined,
  })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [hierarchiesForm, setHierarchiesForm] = useState({
    isOpen: false,
    formData: undefined as IAutoCharge | undefined,
  })

  const filterData = useMemo(() => {
    const filter = {} as Record<string, any>
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  const {
    autoCharges,
    isLoadingAutoCharges: isLoading,
    addAutoCharge,
    updateAutoCharge,
    removeAutoCharge,
  } = useQueryAutoCharges({
    filters: {
      ...filterData,
      searchWord: globalFilter,
    },
  })

  const autoChargeIds = autoCharges.map(({ id }) => id)

  const { hierarchyRowsData, refetchHierarchyRows } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.AutoCharge],
        hierarchableId: autoChargeIds,
      },
    },
    { enabled: autoChargeIds.length > 0 },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, refetchHierarchies } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    { enabled: hierarchyIds.length > 0 },
  )

  const { getHierarchyLabel } = useGetHierarchyLabel()

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const dateRangeTypeOptions = useMemo(
    () => makeOptions(EAutoChargeDateRangeType),
    [],
  )

  const filterOptions = [
    {
      field: 'qty',
      label: 'QTY',
      type: EFieldType.number,
    },
    {
      field: 'sellerProductId',
      label: 'Seller Product',
      type: EFieldType.multipleSelect,
      options: sellerProductOptions,
    },
    {
      field: 'dateRangeType',
      label: 'Date Range Type',
      type: EFieldType.multipleSelect,
      options: dateRangeTypeOptions,
    },
    {
      field: 'dateRange',
      label: 'Date',
      type: EFieldType.dateRange,
    },
  ]

  const onCloseFormModal = useCallback(() => {
    setFormModalState({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const onOpenCreateFormModal = useCallback(() => {
    setFormModalState({
      isOpen: true,
      formData: undefined,
    })
  }, [])

  const afterCreate = useCallback(
    (formData: IAutoCharge, keepOpenForm: boolean) => {
      addAutoCharge(formData)
      if (keepOpenForm) {
        setFormModalState({
          isOpen: true,
          formData: formData,
        })
      }
    },
    [addAutoCharge],
  )

  const afterUpdate = useCallback(
    (formData: IAutoCharge) => {
      updateAutoCharge(formData.id, formData)
      refetchHierarchyRows()
      refetchHierarchies()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateAutoCharge],
  )

  const onOpenEditDialogForm = useCallback(
    (rowData: IAutoCharge) => () => {
      setFormModalState({
        isOpen: true,
        formData: {
          ...rowData,
          dateRangeStart: getPricingDate(rowData.dateRange)?.startDate,
          dateRangeEnd: getPricingDate(rowData.dateRange)?.endDate,
        } as any,
      })
    },
    [],
  )

  const onRemove = useCallback(
    (rowData: IAutoCharge) => async () => {
      const result = await confirmation({
        message: 'Are you sure you want to delete this data',
      })
      if (result === EYesNo.Yes) {
        apiClient.autoCharges.delete(rowData.id) //await?
        removeAutoCharge(rowData.id)
      }
    },
    [confirmation, removeAutoCharge],
  )

  const onOpenHierarchiesForm = (autoCharge: IAutoCharge) => () => {
    setHierarchiesForm({
      isOpen: true,
      formData: autoCharge,
    })
  }

  const onCloseHierarchiesForm = () => {
    setHierarchiesForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const afterCreateHierarchies = () => {
    refetchHierarchyRows()
    onCloseHierarchiesForm()
  }

  const columns = useMemo<IRTColumnDef<IAutoCharge>[]>(
    () => [
      {
        header: 'Product',
        accessorKey: 'sellerProductId',
        size: 200,
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: sellerProductOptions,
      },
      {
        id: 'dateRangeStart',
        header: 'Date Range Start',
        accessorFn: ({ dateRange }) => getPricingDate(dateRange)?.startDate,
        editVariant: EFieldType.date,
        enableEditing: true,
      },
      {
        accessorKey: 'dateRangeEnd',
        header: 'Date Range End',
        accessorFn: ({ dateRange }) => getPricingDate(dateRange)?.endDate,
        editVariant: EFieldType.date,
        enableEditing: true,
      },
      {
        header: 'Date Range Type',
        accessorKey: 'dateRangeType',
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: dateRangeTypeOptions,
      },
      {
        header: 'Active',
        accessorKey: 'active',
        size: 100,
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: ACTIVE_OPTIONS,
      },
      {
        header: 'Qty',
        accessorKey: 'qty',
        size: 100,
        enableEditing: true,
        editVariant: EFieldType.number,
      },
      {
        header: 'Hierarchies',
        accessorKey: 'hierarchies',
        minSize: 500,
        Cell({ row }) {
          const rowData = row.original
          const hierarchyRows = hierarchyRowsData
            .filter(({ hierarchableId }) => hierarchableId === rowData.id)
            .map(({ hierarchyId }) => hierarchyId)
          const hierarchies = hierarchiesData.filter(({ id }) =>
            hierarchyRows.includes(id),
          )
          const labels = hierarchies.map(item => getHierarchyLabel(item))
          return (
            <div>
              {labels.map((label, index) => (
                <div key={index}>
                  <Badge style={{ marginRight: 4 }}>{index + 1}</Badge>
                  {label}
                </div>
              ))}
            </div>
          )
        },
      },
    ],
    [
      dateRangeTypeOptions,
      getHierarchyLabel,
      hierarchiesData,
      hierarchyRowsData,
      sellerProductOptions,
    ],
  )

  const onCellEditEnd = useCallback(
    async (value: any, cell: RTCell<IAutoCharge>) => {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      const dateRange = row.original.dateRange

      let payload = { [columnField]: value }

      if (columnField.includes('date')) {
        const [start, end] = dateRange
          ? dateRange.split('...')
          : ['-infinity', 'infinity']
        if (columnField === 'dateRangeStart')
          payload.dateRange = `${moment(value).format('YYYY-MM-DD')}..${end}`
        if (columnField === 'dateRangeEnd')
          payload.dateRange = `${start}..${moment(value).format('YYYY-MM-DD')}`
      }

      try {
        const { errors, ...autoCharge } = await apiClient.autoCharges.update(
          rowId,
          payload as IUpdateAutoChargePayload,
        )
        if (errors.length > 0) {
          throw new Error(errors.join(','))
        } else {
          updateAutoCharge(rowId, autoCharge)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },

    [updateAutoCharge],
  )

  useEffect(() => {
    if (columnFiltersProp) {
      setColumnFilters(prev => mergeArrayById(prev, columnFiltersProp))
    }
  }, [columnFiltersProp])

  return {
    columns,
    formModalState,
    isLoading,
    onOpenCreateFormModal,
    onCloseFormModal,
    columnFilters,
    filterOptions,
    setColumnFilters,
    onCellEditEnd,
    windowSize,
    autoCharges,
    addAutoCharge,
    updateAutoCharge,
    onOpenEditDialogForm,
    onRemove,
    globalFilter,
    formProps,
    toolbarProps,
    style,
    setGlobalFilter,
    afterCreate,
    afterUpdate,
    hierarchiesForm,
    setHierarchiesForm,
    onOpenHierarchiesForm,
    onCloseHierarchiesForm,
    afterCreateHierarchies,
  }
}
