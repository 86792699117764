import { EInvoiceSellerStatus, EInvoiceStatus } from '~/types/enums/EInvoice'
import { IInvoice } from '~/types/models/IInvoice'

const getIsInvoiceEditingDisabled = (invoice: IInvoice) => {
  const isInvoiceApproved = [
    EInvoiceStatus.Rejected,
    EInvoiceStatus.Approved,
    EInvoiceStatus.Paid,
    EInvoiceStatus.Locked,
  ].includes(invoice.status)

  const isSellerStatusApproved =
    invoice.sellerStatus !== null &&
    [EInvoiceSellerStatus.Approved, EInvoiceSellerStatus.Exported].includes(
      invoice.sellerStatus,
    )

  return isInvoiceApproved || isSellerStatusApproved
}

export default getIsInvoiceEditingDisabled
