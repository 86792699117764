import { useCallback, useState } from 'react'

import { IonButtons, IonIcon } from '@ionic/react'
import { Button } from '~/components'

import clsx from 'clsx'
import { addCircleOutline, openOutline } from 'ionicons/icons'

import type { ICompanyMenuItemProps } from './type'

import './styles.scss'
import { Link } from 'react-router-dom'
import { When } from 'react-if'
import { useRouter } from '~/hooks/useRouter'

function CompanyMenuItem(props: ICompanyMenuItemProps) {
  const {
    icon,
    label,
    description,
    className,
    href,
    value,
    onClick,
    DialogComponent,
    isShowCreateButton,
    onClose,
  } = props

  const router = useRouter()

  const [isOpen, setIsOpen] = useState(false)

  const onClickPlusButton = useCallback(
    (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      setIsOpen(true)
    },
    [],
  )

  const onClickItem = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      onClick && onClick(event, { label, description, value })
    },
    [description, label, value, onClick],
  )

  const onCloseDialog = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <Link
        to={href || ''}
        className='CompanyHomeContainer__anchor'
        onClick={onClickItem}
      >
        <div className={clsx('CompanyMenuItem__container', className)}>
          <div className='CompanyMenuItem__icon'>
            <IonIcon icon={icon} />
          </div>
          <div className='CompanyMenuItem__titleContainer'>
            <p className='CompanyMenuItem__label'>{label}</p>
            <p className='CompanyMenuItem__description'>{description}</p>
          </div>

          <When condition={Boolean(isShowCreateButton)}>
            <IonButtons className='CompanyMenuItem__plusButton'>
              <Button
                icon={openOutline}
                tooltipProps={{
                  content: 'Navigate to this page',
                  placement: 'top',
                }}
                onClick={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  router.push(href as string)
                  onClose && onClose()
                }}
              />
              {DialogComponent && (
                <Button
                  icon={addCircleOutline}
                  color='fleet'
                  onClick={onClickPlusButton}
                />
              )}
            </IonButtons>
          </When>
        </div>
      </Link>
      {DialogComponent && (
        <DialogComponent
          isOpen={isOpen}
          onClose={onCloseDialog}
          afterCreate={onCloseDialog}
        />
      )}
    </>
  )
}
export default CompanyMenuItem
