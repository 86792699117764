import { CommonDialogV2, CommonTab, ICommonTabItem } from '~/components/shared'
import { IAutomaticChargesDialogProps } from './type'
import AutoExtrasTable from '~/containers/AutoExtrasContainer/AutoExtrasTable/AutoExtrasTable'
import AutoChargesTable from '~/containers/AutoChargesContainer/AutoChargesTable/AutoChargesTable'

import './styles.scss'

function AutomaticChargesDialog(props: IAutomaticChargesDialogProps) {
  const { invoice, ...dialogProps } = props

  const tabs: ICommonTabItem[] = [
    {
      name: 'AutoExtra',
      label: 'Add to Loads when Dispatching',
      render() {
        return (
          <AutoExtrasTable
            formProps={{
              formData: {
                companyId: invoice?.sellerId,
              },
              hierarchyItem: invoice as any,
            }}
            columnFilters={[
              {
                id: 'companyId',
                value: invoice?.sellerId,
              },
            ]}
            toolbarProps={{
              filterSectionWidth: 600,
            }}
          />
        )
      },
    },
    {
      name: 'AutoCharge',
      label: 'Add to Invoice during Billing',
      render() {
        return (
          <AutoChargesTable
            formProps={{
              formData: {
                companyId: invoice?.sellerId,
              },
              hierarchyItem: invoice as any,
            }}
            columnFilters={[
              {
                id: 'companyId',
                value: invoice?.sellerId,
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <CommonDialogV2
      {...dialogProps}
      size='xl'
      title='Automatic Charges'
      isHiddenOkButton
    >
      <CommonTab tabs={tabs} />
    </CommonDialogV2>
  )
}

export default AutomaticChargesDialog
