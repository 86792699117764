import { Button, Collapse } from 'react-bootstrap'
import { useFieldArray } from 'react-hook-form'
import { ExtrasFormRow } from './ExtrasFormRow'
import { useEffect } from 'react'
import _ from 'lodash'
import { PlusIcon, ToolTipOverlay } from '~/components/shared'

export const ExtrasFormFieldArray = ({
  prefix = '',
  control,
  autoExtras,
  buyerId,
  sellerId,
  loading,
  name,
  show,
  hideHeader,
  className,
  ...props
}) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `${prefix}orderExtrasAttributes`,
  })

  const appendExtra = () => {
    append({ sellerProductId: null })
  }

  useEffect(() => {
    if (autoExtras.length > 0 && fields) {
      const sortedExtras = Object.values(
        autoExtras.reduce((acc, item) => {
          const [start, end] = item.applicationRange.split('...').map(Number)
          item.start = start
          item.end = end

          const key = `${item.start}-${item.sellerProductId}`

          if (
            !acc[key] ||
            item.start < acc[key].start ||
            (item.start === acc[key].start && item.end > acc[key].end) ||
            (item.start === acc[key].start &&
              item.end === acc[key].end &&
              item.qty > acc[key].qty)
          ) {
            acc[key] = item
          }

          return acc
        }, {}),
      )
        .map(({ start, end, ...rest }) => ({
          ...rest,
          range: `${start}...${end}`,
        }))
        .map(({ sellerProductId: sp, id }) => {
          const ranges = autoExtras
            .filter(ae => ae.sellerProductId === sp)
            .map(range => {
              const [start, end] = range.applicationRange.split('...')
              return {
                qty: range.qty,
                startLoad: Number(start) || 1,
                endLoad: Number(end) || null,
              }
            })
          return {
            sellerProductId: sp,
            orderExtraRangesAttributes: ranges,
            autoExtraId: id,
          }
        })

      sortedExtras.forEach(extra => {
        const existingExtra = fields.findIndex(
          ({ sellerProductId }) => sellerProductId === extra.sellerProductId,
        )

        if (existingExtra === -1) {
          const nullExtra = fields.findIndex(
            ({ sellerProductId }) => sellerProductId === null,
          )
          if (nullExtra !== -1) {
            update(nullExtra, extra)
          } else {
            append(extra)
          }
        }
      })
    }
  }, [append, autoExtras, update, fields])

  return (
    <Collapse in={show}>
      <div className={className}>
        {!hideHeader && (
          <h3 className='d-flex align-items-center p-2 pb-0'>
            {_.startCase(name)} Extras:{' '}
            <ToolTipOverlay content='Add extra'>
              <Button onClick={appendExtra} className='ms-auto'>
                <PlusIcon color='#fff' />
              </Button>
            </ToolTipOverlay>
          </h3>
        )}

        <div className='p-2'>
          {fields.map((field, index) => (
            <ExtrasFormRow
              key={field.id}
              index={index}
              remove={remove}
              prefix={prefix}
              control={control}
              buyerId={buyerId}
              sellerId={sellerId}
              {...props}
            />
          ))}
        </div>
      </div>
    </Collapse>
  )
}
