import { SplitBillLine } from '~/components/invoice'
import { useSelector } from 'react-redux'

const BillLineDetailsModal = ({
  invoice,
  isResolved,
  saveBillLine,
  splitRef,
  splitCompareRef,
}) => {
  const { billLinesSort } = useSelector(state => state.app)
  const compareInvoice = invoice ? invoice.compareInvoice : undefined

  return (
    <>
      <SplitBillLine
        splitRef={splitRef}
        invoice={invoice}
        saveBillLine={saveBillLine}
        isResolved={isResolved}
        billLinesSort={billLinesSort}
      />
      <SplitBillLine
        splitRef={splitCompareRef}
        invoice={{
          ...compareInvoice,
          compareInvoice: {
            billLines: invoice.billLines,
          },
          proveUrl: invoice.proveUrl,
        }}
        saveBillLine={saveBillLine}
        isResolved={isResolved}
        isCompareInvoice={true}
        billLinesSort={billLinesSort}
      />
    </>
  )
}

export default BillLineDetailsModal
