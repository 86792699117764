import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ITaxFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  useQueryGlCodes,
  useQueryHierarchies,
  useQueryHierarchyRows,
} from '~/hooks/useQueryData'
import { TAX_TYPE_OPTIONS } from '~/utils/constants'
import { ITaxFormValues } from '~/types/models/ITax'
import HierarchyForm from '~/containers/invoices/AutomaticChargesDialog/HierarchyForm'
import './styles.scss'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'

const TaxForm = (props: ITaxFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const formRef = useRef<any>()
  const hierarchyFormRef = useRef<any>()

  const { glCodeOptions } = useQueryGlCodes()

  const { hierarchyRowsData, isHierarchyRowsFetched } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.Tax],
        hierarchableId: formData?.id as number,
      },
    },
    { enabled: isUpdating, refetchOnMount: true },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, isHierarchiesDataFetched } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds.length > 0 ? hierarchyIds : undefined,
      },
    },
    {
      enabled: hierarchyIds.length > 0 && isHierarchyRowsFetched,
      refetchOnMount: true,
    },
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Name',
        name: 'name',
        isRequired: true,
      },
      {
        label: 'Tax Type',
        name: 'taxType',
        type: EFieldType.singleSelect,
        options: TAX_TYPE_OPTIONS,
        size: 6,
        isRequired: true,
      },
      {
        label: 'Tax Value',
        name: 'taxValue',
        type: EFieldType.number,
        size: 6,
        isRequired: true,
      },
      {
        label: 'Tax #',
        name: 'taxNumber',
        type: EFieldType.number,
        size: 6,
      },
      {
        label: 'GL Code',
        name: 'glCodeId',
        options: glCodeOptions,
        type: EFieldType.singleSelect,
        size: 6,
      },
      {
        name: 'hierarchyAttributes',
        render() {
          return (
            <HierarchyForm
              ref={hierarchyFormRef}
              className='TaxForm__hierarchyForm'
              isUpdating={isUpdating}
            />
          )
        },
      },
    ],
    [glCodeOptions, isUpdating],
  )

  const defaultValues = useMemo<ITaxFormValues>(
    () => ({
      name: '',
      taxType: null,
      taxValue: null,
      taxNumber: null,
      glCodeId: null,
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        taxType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        taxValue: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: ITaxFormValues) => {
      const { errors, ...res } = await apiClient.taxes.create({
        tax: {
          name: formValues.name,
          taxType: formValues.taxType,
          taxValue: formValues.taxValue,
          taxNumber: formValues.taxNumber,
          glCodeId: formValues.glCodeId,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
          hierarchableType: EHierarchableType.Tax,
          hierarchableId: res.id,
        })
        const keepOpenForm = res2.backendErrors.length > 0
        afterCreate && afterCreate(res, keepOpenForm)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: ITaxFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'name',
          'taxType',
          'taxValue',
          'taxNumber',
          'glCodeId',
        ])
        const { errors, ...response } = await apiClient.taxes.update(
          formValues.id,
          {
            tax: payload,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
            hierarchableType: EHierarchableType.Tax,
            hierarchableId: response.id,
          })
          const keepOpenForm = res2.backendErrors.length > 0
          afterUpdate && afterUpdate(response, keepOpenForm)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: ITaxFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isHierarchiesDataFetched && isUpdating && hierarchiesData.length > 0) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        hierarchiesData,
      )
    }
  }, [isHierarchiesDataFetched, hierarchiesData, isUpdating])

  useEffect(() => {
    if (formData?.hierarchiesAttributes) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        formData?.hierarchiesAttributes,
      )
    }
  }, [formData?.hierarchiesAttributes])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default TaxForm
