import { useMemo, useCallback } from 'react'

import { IRTColumnDef } from '~/components/shared'
import { ADDRESS_TYPE_OPTIONS } from '~/utils/constants'
import { IAddress } from '~/types/models/IAddress'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { useQueryEnums } from '~/hooks/useQueryData'

export interface IUseTableAddressColumnsProps {
  addressesData: IAddress[]
}

const useTableAddressColumns = (props: IUseTableAddressColumnsProps) => {
  const { addressesData } = props

  const getCellValueWithParentAddress = useCallback(
    (rowData: IAddress, field: keyof IAddress) => {
      if (rowData.addressId) {
        const address = addressesData.find(({ id }) => id === rowData.addressId)

        return address?.[field]
      }

      return rowData[field]
    },
    [addressesData],
  )

  const { renderTypesInBadges } = useQueryEnums({
    model: 'addresses',
    field: 'addressTypes',
  })

  const columns = useMemo<IRTColumnDef<IAddress>[]>(
    () => [
      {
        header: 'Street',
        id: 'street',
        size: 300,
        accessorFn: rowData => getCellValueWithParentAddress(rowData, 'street'),
      },
      {
        header: 'Line 2',
        id: 'address2',
        size: 200,
        accessorFn: rowData =>
          getCellValueWithParentAddress(rowData, 'address2'),
      },
      {
        header: 'City',
        id: 'city',
        size: 120,
        accessorFn: rowData => getCellValueWithParentAddress(rowData, 'city'),
      },
      {
        header: 'Country',
        id: 'country',
        size: 120,
        accessorFn: rowData =>
          getCellValueWithParentAddress(rowData, 'country'),
      },
      {
        header: 'State',
        id: 'state',
        accessorFn: rowData => getCellValueWithParentAddress(rowData, 'state'),
      },
      {
        header: 'Zip',
        id: 'zip',
        accessorFn: rowData => getCellValueWithParentAddress(rowData, 'zip'),
      },
      {
        header: 'Address Types',
        accessorKey: 'addressTypes',
        filterVariant: EFieldType.multipleSelect,
        filterSelectOptions: ADDRESS_TYPE_OPTIONS,
        Cell({ cell }) {
          const cellValue = cell.getValue<number[]>()
          return renderTypesInBadges(cellValue)
        },
      },
    ],
    [getCellValueWithParentAddress, renderTypesInBadges],
  )

  return { columns }
}

export default useTableAddressColumns
