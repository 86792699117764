import { moduleName } from '~/utils/constants'

export const DEFAULT_DOCUMENTS_SORT = [
  {
    label: 'Id',
    value: 'id',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'id',
    isHidden: false,
  },
  {
    label: 'Created Date',
    value: 'created_at',
    type: 'date',
    sorted: true,
    isAsc: false,
    sortField: 'createdAt',
    isHidden: false,
  },
  {
    label: 'Company Name',
    value: 'name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'name',
    isHidden: false,
  },
  {
    label: 'Status',
    value: 'status',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'status',
    isHidden: false,
  },
  {
    label: 'File Type',
    value: 'file_type',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'fileType',
    isHidden: false,
  },
  {
    label: 'Load / Invoice',
    value: 'doc_type',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'docType',
    isHidden: false,
  },
  {
    label: '# of Pages',
    value: 'total_pages',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'totalPages',
    isHidden: false,
  },
]

export const DEFAULT_INVOICES_SORT = [
  {
    label: 'Invoice #',
    value: 'num',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'num',
    isHidden: false,
  },
  {
    label: 'Seller',
    value: 'seller_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'seller_id',
    isHidden: false,
  },
  {
    label: 'Buyer',
    value: 'buyer_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'buyer_id',
    isHidden: false,
  },
  {
    label: 'Buyer Terminal',
    value: 'buyer_terminal_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'buyer_terminal_id',
    isHidden: false,
  },
  {
    label: 'Quantity',
    value: 'qty',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'qty',
    isHidden: false,
  },
  {
    label: 'Loads',
    value: 'load_count',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'load_count',
    isHidden: false,
  },
  {
    label: 'Invoice Period',
    value: 'end_date',
    type: 'number',
    sorted: false,
    isAsc: false,
    sortField: 'end_date',
    isHidden: false,
  },
  {
    label: 'Due Date',
    value: 'due_date',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'due_date',
    isHidden: false,
  },
  {
    label: 'Status',
    value: 'status',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'status',
    isHidden: false,
  },
  {
    label: 'Type',
    value: 'category',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'category',
    isHidden: false,
  },
  {
    label: 'Created At',
    value: 'created_at',
    type: 'number',
    sorted: true,
    isAsc: false,
    sortField: 'created_at',
    isHidden: false,
  },
  {
    label: 'Start Date',
    value: 'start_date',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'start_date',
    isHidden: false,
  },
]

export const DEFAULT_LOAD_SORTS = [
  {
    label: 'ID',
    value: 'id',
    type: 'number',
    sorted: true,
    isAsc: true,
    sortField: 'id',
    isHidden: false,
  },
  {
    label: 'Num',
    value: 'num',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'num',
    isHidden: false,
  },
  {
    label: 'Qty',
    value: 'qty',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'qty',
    isHidden: false,
  },
  {
    label: 'Seller Name',
    value: 'seller_name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'seller_name',
    isHidden: false,
  },
  {
    label: 'Product Name',
    value: 'product_name',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'product_name',
    isHidden: false,
  },
]

export const DEFAULT_COMPANY_SORTS = {
  users: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Profile',
      value: 'firstName',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'firstName',
      isHidden: false,
    },
    {
      label: 'Phone',
      value: 'phoneNumber',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'phoneNumber',
      isHidden: false,
    },
    {
      label: 'Color',
      value: 'color',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'color',
      isHidden: false,
    },
    {
      label: 'Status',
      value: 'userAccess.status',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'userAccess.status',
      isHidden: false,
    },
    {
      label: 'Default',
      value: 'userAccess.default',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'userAccess.default',
      isHidden: false,
    },
  ],
  parsers: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Company',
      value: 'company.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'company.name',
      isHidden: false,
    },
    {
      label: 'Collection',
      value: 'collection',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'collection',
      isHidden: false,
    },
    {
      label: 'Document Type',
      value: 'documentType',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'documentType',
      isHidden: false,
    },
    {
      label: 'Parser',
      value: 'parser',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'parser',
      isHidden: false,
    },
  ],
  buyers: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Buyer',
      value: 'buyer.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer.name',
      isHidden: false,
    },
    {
      label: 'Name',
      value: 'buyerName',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyerName',
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'buyerCode',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyerCode',
      isHidden: false,
    },
    {
      label: 'Status',
      value: 'buyerStatus',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyerStatus',
      isHidden: false,
    },
  ],
  sellers: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'seller.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
    {
      label: 'Name',
      value: 'sellerName',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'sellerName',
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'sellerCode',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'sellerCode',
      isHidden: false,
    },
    {
      label: 'Status',
      value: 'sellerStatus',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'sellerStatus',
      isHidden: false,
    },
  ],
  fleets: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Company',
      value: 'company.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'company.name',
      isHidden: false,
    },
    {
      label: 'Fleet',
      value: 'fleet.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'fleet.name',
      isHidden: false,
    },
    {
      label: 'Name',
      value: 'name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'name',
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'code',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'code',
      isHidden: false,
    },
    {
      label: 'Rank',
      value: 'rank',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'rank',
      isHidden: false,
    },
  ],
  emailParsers: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'seller.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
    {
      label: 'Buyer',
      value: 'buyer.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer.name',
      isHidden: false,
    },
    {
      label: 'Seller Regrex',
      value: 'sellerRegex',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'sellerRegex',
      isHidden: false,
    },
    {
      label: 'Buyer Regex',
      value: 'buyerRegex',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyerRegex',
      isHidden: false,
    },
    {
      label: 'DocType Regex',
      value: 'docTypeRegex',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'docTypeRegex',
      isHidden: false,
    },
    {
      label: 'DocType Value',
      value: 'docTypeValue',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'docTypeValue',
      isHidden: false,
    },
  ],
  parserColumnMappings: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Company',
      value: 'company.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'company.name',
      isHidden: false,
    },
    {
      label: 'Parsing Column',
      value: 'parsingColumn',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'parsingColumn',
      isHidden: false,
    },
    {
      label: 'Assign Column',
      value: 'assignColumn',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'assignColumn',
      isHidden: false,
    },
    {
      label: 'Type',
      value: 'model',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'model',
      isHidden: false,
    },
    {
      label: 'Regexes',
      value: 'regexp',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'regexp',
      isHidden: false,
    },
  ],
  products: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'seller.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
    {
      label: 'Buyer',
      value: 'buyer.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer.name',
      isHidden: false,
    },
    {
      label: 'Product Code',
      value: 'code',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'code',
      isHidden: false,
    },
    {
      label: 'Product Name',
      value: 'name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'name',
      isHidden: false,
    },
    {
      label: 'Product Desc.',
      value: 'description',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'description',
      isHidden: false,
    },
    {
      label: 'Price',
      value: 'price',
      type: 'int',
      sorted: false,
      isAsc: true,
      sortField: 'price',
      isHidden: false,
    },
  ],
  autoCharges: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'seller.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
    {
      label: 'Buyer',
      value: 'buyer.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer.name',
      isHidden: false,
    },
    {
      label: 'Application Level',
      value: 'applicationLevel',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'applicationLevel',
      isHidden: false,
    },
    {
      label: 'Allocated Per',
      value: 'allocatedPer',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'allocatedPer',
      isHidden: false,
    },
    {
      label: 'Product',
      value: 'sellerProduct.name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'sellerProduct.name',
      isHidden: false,
    },
  ],
  fixedPrices: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'seller_id',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller_id',
      isHidden: false,
    },
    {
      label: 'Seller Terminal',
      value: 'seller_terminal_id',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller_terminal_id',
      isHidden: false,
    },
    {
      label: 'Buyer Terminal',
      value: 'buyer_terminal_id',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer_terminal_id',
      isHidden: false,
    },
    {
      label: 'Item Type',
      value: 'item_type',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'item_type',
      isHidden: false,
    },
    {
      label: 'Product',
      value: 'buyer_seller_product_id',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer_seller_product_id',
      isHidden: false,
    },
    {
      label: 'Price',
      value: 'price',
      type: 'number',
      sorted: false,
      isAsc: true,
      sortField: 'price',
      isHidden: false,
    },
    {
      label: 'Flat Rate',
      value: 'flat_rate',
      type: 'number',
      sorted: false,
      isAsc: true,
      sortField: 'flat_rate',
      isHidden: false,
    },
  ],
  sellerProducts: [
    {
      label: 'Seller',
      value: 'sellerName',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
    {
      label: 'Name',
      value: 'name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'name',
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'code',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'code',
      isHidden: false,
    },
    {
      label: 'Description',
      value: 'description',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'description',
      isHidden: false,
    },
    {
      label: 'Product',
      value: 'product',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'product.name',
      isHidden: false,
    },
    {
      label: 'Status',
      value: 'status',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'status',
      isHidden: false,
    },
  ],
  terminals: [
    {
      label: 'Company',
      value: 'companyName',
      sortField: 'company.name',
      type: 'string',
      sorted: true,
      isAsc: true,
      isHidden: false,
    },
    {
      label: 'Name',
      value: 'name',
      sortField: 'name',
      type: 'string',
      sorted: true,
      isAsc: true,
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'code',
      sortField: 'code',
      type: 'string',
      sorted: false,
      isAsc: true,
      isHidden: false,
    },
    {
      label: 'Time Zone',
      value: 'timeZone',
      sortField: 'timeZone',
      type: 'string',
      sorted: false,
      isAsc: true,
      isHidden: false,
    },
    {
      label: 'Phone #',
      value: 'callPhoneNumber',
      sortField: 'callPhoneNumber',
      type: 'string',
      sorted: false,
      isAsc: true,
      isHidden: false,
    },
    {
      label: 'Status',
      value: 'status',
      sortField: 'status',
      type: 'string',
      sorted: false,
      isAsc: true,
      isHidden: false,
    },
  ],
  buyerTerminalReferences: [
    {
      label: 'Name',
      value: 'name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'name',
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'code',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'code',
      isHidden: false,
    },
    {
      label: 'Buyer Terminal',
      value: 'terminal',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyerTerminal.code',
      isHidden: false,
    },
    {
      label: 'Buyer',
      value: 'buyerName',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'buyer.name',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'sellerName',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
  ],
  companyTags: [
    {
      label: 'Rank',
      value: 'rank',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'rank',
      isHidden: false,
    },
    {
      label: 'Color',
      value: 'color',
      type: 'string',
      sorted: true,
      isAsc: true,
      sortField: 'color',
      isHidden: false,
    },
    {
      label: 'Code',
      value: 'code',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'code',
      isHidden: false,
    },
    {
      label: 'Name',
      value: 'name',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'name',
      isHidden: false,
    },
    {
      label: 'Description',
      value: 'description',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'description',
      isHidden: false,
    },
  ],
  referenceNames: [
    {
      label: 'Id',
      value: 'id',
      type: 'string',
      sorted: true,
      isAsc: false,
      sortField: 'id',
      isHidden: false,
    },
    {
      label: 'Buyer',
      value: 'buyerId',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'buyer.name',
      isHidden: false,
    },
    {
      label: 'Seller',
      value: 'sellerId',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'seller.name',
      isHidden: false,
    },
    {
      label: 'Item ID',
      value: 'itemId',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'itemId',
      isHidden: false,
    },
    {
      label: 'Item Type',
      value: 'itemType',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'itemType',
      isHidden: false,
    },
    {
      label: 'Reference Code',
      value: 'referenceCode',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'referenceCode',
      isHidden: false,
    },
    {
      label: 'Reference Name',
      value: 'referenceName',
      type: 'string',
      sorted: false,
      isAsc: true,
      sortField: 'referenceName',
      isHidden: false,
    },
  ],
}

const DEFAULT_START_TIMES_SORT = [
  {
    label: 'Start Date',
    value: 'startTime',
    type: 'string',
    sorted: true,
    isAsc: true,
    sortField: 'startTime',
  },
  {
    label: 'Driver',
    value: 'driverId',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'driverId',
  },
  {
    label: 'Terminal',
    value: 'terminalId',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'terminalId',
  },
  {
    label: 'Notes',
    value: 'notes',
    type: 'string',
    sorted: false,
    isAsc: true,
    sortField: 'notes',
  },
]

const defaultSorts = {
  documents: DEFAULT_DOCUMENTS_SORT,
  invoices: DEFAULT_INVOICES_SORT,
  loads: DEFAULT_LOAD_SORTS,
  [moduleName.startTimes]: DEFAULT_START_TIMES_SORT,
  [moduleName.company.users]: DEFAULT_COMPANY_SORTS.users,
  [moduleName.company.parsers]: DEFAULT_COMPANY_SORTS.parsers,
  [moduleName.company.buyers]: DEFAULT_COMPANY_SORTS.buyers,
  [moduleName.company.sellers]: DEFAULT_COMPANY_SORTS.sellers,
  [moduleName.company.fleets]: DEFAULT_COMPANY_SORTS.fleets,
  [moduleName.company.emailParsers]: DEFAULT_COMPANY_SORTS.emailParsers,
  [moduleName.company.parserColumnMappings]:
    DEFAULT_COMPANY_SORTS.parserColumnMappings,
  [moduleName.company.products]: DEFAULT_COMPANY_SORTS.products,
  [moduleName.company.autoCharges]: DEFAULT_COMPANY_SORTS.autoCharges,
  [moduleName.company.fixedPrices]: DEFAULT_COMPANY_SORTS.fixedPrices,
  [moduleName.company.sellerProducts]: DEFAULT_COMPANY_SORTS.sellerProducts,
  [moduleName.company.terminals]: DEFAULT_COMPANY_SORTS.terminals,
  [moduleName.company.buyerTerminalReferences]:
    DEFAULT_COMPANY_SORTS.buyerTerminalReferences,
  [moduleName.company.referenceNames]: DEFAULT_COMPANY_SORTS.referenceNames,
  [moduleName.company.tags]: DEFAULT_COMPANY_SORTS.companyTags,
  default: [],
}

export default defaultSorts
