import { useState } from 'react'
import { ConcordFormDropdownV2 } from '~/components/shared'

import './styles.scss'
import { COLOR_OPTIONS } from './colorOptions'
import CustomColorOption from './CustomColorOption'
import CustomColorSingleValue from './CustomColorSingleValue'
import { NOT_SET_OPTION } from '~/utils/constants'
import CustomColorMenu from './CustomColorMenu'
import CustomColorMenuList from './CustomColorMenuList'
import ColorPickerDialog from './ColorPickerDialog'
import clsx from 'clsx'

function ColorSelector(props) {
  const {
    label,
    value,
    onChange,
    error,
    isRequired,
    isDisabled,
    hint,
    isReadOnly,
    className,
    styles,
  } = props

  const [showColorPicker, setShowColorPicker] = useState(false)
  const [onChangeParams, setOnChangeParams] = useState({
    action: undefined,
    selection: undefined,
  })

  const handleChange = (action, selection) => {
    if (selection.value === 'custom') {
      setShowColorPicker(true)
      setOnChangeParams({
        action,
        selection,
      })
    } else {
      onChange && onChange(action, selection)
    }
  }

  return (
    <>
      <ConcordFormDropdownV2
        label={label}
        value={value}
        options={[NOT_SET_OPTION, ...COLOR_OPTIONS]}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        className={clsx(className, 'ColorSelector__container')}
        error={error}
        hint={hint}
        onChange={handleChange}
        styles={styles}
        components={{
          Option: CustomColorOption,
          SingleValue: CustomColorSingleValue,
          Menu: CustomColorMenu,
          MenuList: CustomColorMenuList,
        }}
      />
      <ColorPickerDialog
        isOpen={showColorPicker}
        onClose={() => {
          setShowColorPicker(false)
        }}
        onSelectColor={color => {
          onChange &&
            onChange(onChangeParams.action, {
              value: color,
              label: color,
              selectedOption: {
                value: color,
                label: color,
              },
            })
        }}
      />
    </>
  )
}

export default ColorSelector
