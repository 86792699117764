import DialogAutoExtraForm from '~/components/shared/ConcordForm/FormData/AutoExtraForm/DialogAutoExtraForm'
import useAutoExtrasTable from './useAutoExtrasTable'
import {
  DeleteIcon,
  EditIcon,
  ReusableTable,
  BranchCreateIcon,
} from '~/components/shared'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { IAutoExtrasTableProps } from './type'
import DialogHierarchiesForm from '~/containers/invoices/AutomaticChargesDialog/DialogHierarchiesForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'

const AutoExtrasTable = (props: IAutoExtrasTableProps) => {
  const {
    columns,
    autoExtras,
    formModalState,
    isLoading,
    onOpenCreateFormModal,
    onCloseFormModal,
    filterOptions,
    columnFilters,
    windowSize,
    globalFilter,
    formProps,
    toolbarProps,
    style,
    setColumnFilters,
    setGlobalFilter,
    onCellEditEnd,
    onOpenEditDialogForm,
    onRemove,
    afterCreate,
    afterUpdate,
    hierarchiesForm,
    onOpenHierarchiesForm,
    onCloseHierarchiesForm,
    afterCreateHierarchies,
  } = useAutoExtrasTable(props)

  return (
    <>
      <div style={{ margin: 12, ...style }}>
        <ReusableTable
          data={autoExtras}
          columns={columns}
          tableHeight={windowSize.height - 220}
          enableCompanyView
          enableColumnPinning
          enableRowActions
          enableTopToolbar
          manualFiltering
          toolbarProps={toolbarProps}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 120,
              maxSize: 120,
            },
          }}
          companyViewProps={{
            name: 'auto_extras',
            description: 'Manage your Auto extras.',
            onClickTopButton: onOpenCreateFormModal,
          }}
          state={{
            isLoading,
            columnFilters,
            globalFilter,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          onCellEditEnd={onCellEditEnd}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: onOpenEditDialogForm(row.original),
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: onRemove(row.original),
              color: 'danger',
            },
            {
              icon: <BranchCreateIcon color='white' />,
              tooltipProps: {
                content: 'Create Hierarchy Row',
                placement: 'top',
              },
              onClick: onOpenHierarchiesForm(row.original),
              color: 'purple',
            },
          ]}
          filterOptions={filterOptions}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>
      <DialogAutoExtraForm
        {...(formProps as any)}
        isOpen={formModalState.isOpen}
        formData={{
          ...formModalState.formData,
          ...formProps?.formData,
        }}
        onClose={onCloseFormModal}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
      />
      <DialogHierarchiesForm
        hierarchyItem={formProps?.hierarchyItem}
        isOpen={hierarchiesForm.isOpen}
        onClose={onCloseHierarchiesForm}
        hierarchableType={EHierarchableType.AutoExtra}
        hierarchableId={hierarchiesForm.formData?.id}
        afterCreate={afterCreateHierarchies}
      />
    </>
  )
}

export default AutoExtrasTable
