import { useMemo, useState } from 'react'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { ConcordFormDropdownV2 } from '~/components/shared/ConcordForm'
import { ICommonGroupOption, ICommonOption } from '~/types/models/ICommonModel'
import clsx from 'clsx'

export interface IRTEditCellSingleSelectFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  isMulti?: boolean
  className?: string
  cellValue?: any
  onChange?: () => void
  options?: ICommonOption[] | ICommonGroupOption[]
}

const RTEditCellSingleSelectField = <TData extends IReusableTableRowData>(
  props: IRTEditCellSingleSelectFieldProps<TData>,
) => {
  const {
    cell,
    table,
    isMulti,
    className,
    onChange: onExternalChange,
    cellValue: externalCellValue,
    options: externalOptions,
  } = props

  const {
    getState,
    options: { onCellEditEnd },
    // refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()
  const { editSelectOptions } = column.columnDef
  const [, setCount] = useState(0) // force UI to re-render

  const selectOptions = useMemo(() => {
    if (externalOptions) {
      return externalOptions
    }
    if (editSelectOptions) {
      if (typeof editSelectOptions === 'function') {
        return editSelectOptions({ cell, column, row, table })
      }
      return editSelectOptions
    }
    return []
  }, [cell, column, editSelectOptions, row, table, externalOptions])

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const cellValue = cell.getValue()
  const defaultValue = isCreating
    ? table.options.defaultValuesOnDraftRow?.[column.id]
    : undefined

  const dropdownFieldProps =
    typeof column.columnDef.editDropdownFieldProps === 'function'
      ? column.columnDef.editDropdownFieldProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCount(prev => prev + 1)
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const onChange = (event: any, { value, selectedOption }: any) => {
    if (value !== cell.getValue()) {
      onExternalChange && onExternalChange()
      if (!isCreating) {
        onCellEditEnd && onCellEditEnd(value, cell, selectedOption)
      }
      saveInputValueToRowCache(value)
      setEditingCell(null)
      // setValue(value)
    }
  }

  return (
    <ConcordFormDropdownV2
      className={clsx('RTEditCellSingleSelectField__container', className)}
      options={selectOptions as ICommonOption[] | ICommonGroupOption[]}
      value={externalCellValue || (cellValue as any)}
      defaultValue={defaultValue}
      onChange={onChange}
      isMulti={isMulti}
      {...dropdownFieldProps}
    />
  )
}

export default RTEditCellSingleSelectField
