import * as Yup from 'yup'

const billLineSchema = Yup.object({
  qty: Yup.number().required('Qty is required').typeError('Qty is required'),
  lineDate: Yup.string()
    .typeError('Line date is required')
    .required('Line date is required'),
})

export default billLineSchema
