import { ToastContentProps } from 'react-toastify'
import { ReusableButton } from '../ReusableButton'

function CustomToastMessage({
  closeToast,
  data,
}: ToastContentProps<{
  onClickButton: () => void
  message: string
  buttonText: string
}>) {
  const onClick = () => {
    data?.onClickButton()
    closeToast && closeToast()
  }

  return (
    <div
      className='d-flex align-items-center w-100'
      style={{ justifyContent: 'space-between' }}
    >
      <span>{data?.message}</span>{' '}
      <ReusableButton style={{ fontSize: 13 }} onClick={onClick}>
        {data?.buttonText}
      </ReusableButton>
    </div>
  )
}

export default CustomToastMessage
