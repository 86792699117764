import moment from 'moment'
import { useMemo } from 'react'
import { IRTColumnDef } from '~/components/shared'
import { useQueryEnums } from '~/hooks/useQueryData'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { IEmail } from '~/types/models/IEmail'

const useTableCompanyEmails = () => {
  const { typeEnumsOptions, renderTypesInBadges } = useQueryEnums({
    model: 'emails',
    field: 'emailTypes',
  })

  const columns = useMemo<IRTColumnDef<IEmail>[]>(
    () => [
      {
        header: 'Email',
        accessorKey: 'email',
        size: 300,
      },
      {
        header: 'Email Types',
        accessorKey: 'emailTypes',
        size: 200,
        filterSelectOptions: typeEnumsOptions,
        filterVariant: EFieldType.multipleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<number[]>()
          return renderTypesInBadges(cellValue)
        },
      },
      {
        header: 'Created At',
        id: 'createdAt',
        size: 180,
        accessorFn(rowData) {
          return moment(rowData.createdAt).format('lll')
        },
      },
      {
        header: 'Updated At',
        id: 'updatedAt',
        accessorFn(rowData) {
          return moment(rowData.updatedAt).format('lll')
        },
      },
    ],
    [renderTypesInBadges, typeEnumsOptions],
  )

  return { columns }
}

export default useTableCompanyEmails
