import { ColumnFiltersState, RowSelectionState } from '@tanstack/react-table'
import { produce } from 'immer'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useDeepCompareEffect, useLocalStorage, useWindowSize } from 'react-use'
import { apiClient } from '~/api/ApiClient'
import {
  CompanyInfo,
  DialogCompanyForm,
  DialogTerminalForm,
  IRTColumnDef,
  IRTFooterActionItem,
  OpenIcon,
  PdfIcon,
  ReusableTable,
  RTActionButtonMenu,
  RTActionNoteButton,
  RTCell,
  TagIcon,
  ToolTipOverlay,
  useModalDownloadProgress,
} from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import { InvoiceDetailsSection } from '~/containers/invoices/InvoiceDetailsSection'
import { useConfirmationProvider } from '~/contexts'
import {
  useQueryCompanies,
  useQueryFlags,
  useQueryInvoices,
  useQueryInvoicesEnums,
  useQueryNotes,
  useQueryTags,
  useQueryTerminals,
  useQueryUoms,
} from '~/hooks/useQueryData'
import { selectCurrentScope } from '~/redux/selectors'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { ENoteableType } from '~/types/enums/ENote'
import { ETagModels } from '~/types/enums/ETag'
import { IInvoice } from '~/types/models/IInvoice'
import getFileNameFromAWSLink from '~/utils/getFileNameFromAWSLink'
import { components } from 'react-select'
import { Badge, Modal } from 'react-bootstrap'
import { FLAG_FIELDS } from '~/constants/loads/filters'

import _ from 'lodash'
import moment from 'moment'
import { format } from 'date-fns'
import { ICompany } from '~/types/models/ICompany'
import clsx from 'clsx'
import { ITerminal } from '~/types/models/ITerminal'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { IonChip, IonIcon, IonText } from '@ionic/react'
import InvoiceStatusChip from '~/components/invoices/InvoiceStatusChip'
import { EFlagFlagableType } from '~/types/enums/EFlag'
import { icons, typesColor } from '~/constants/invoice-type'
import Thumbnail from '~/components/shared/Thumbnail'
import { ITag } from '~/types/models/ITag'
import { EInvoiceStatus } from '~/types/enums/EInvoice'
import {
  cloudDownloadOutline,
  nuclearOutline,
  refreshCircleOutline,
  trashOutline,
} from 'ionicons/icons'
import { useShowingPdfViewer } from '~/hooks/useShowingPdfViewer'
import DataParsersForm from '../DataParsersForm'
import mergeArrayById from '~/utils/mergeArrayById'
import roundNumber from '~/utils/roundNumber'

export interface IReusableInvoicesTableProps {
  filterData?: Record<string, any>
  enableEditing?: boolean
}

function ReusableInvoicesTable(props: IReusableInvoicesTableProps) {
  const { filterData: externalFilterData, enableEditing = true } = props

  const {
    invoiceStatusOptions,
    invoiceSellerStatusOptions,
    invoicePrimaryParsingStatusOptions,
    invoiceHitlStatusOptions,
    invoiceCategoryOptions,
  } = useQueryInvoicesEnums()

  const [modalState, setModalState] = useState({
    show: false,
    invoice: undefined as IInvoice | undefined,
  })
  const [isEmailing, setIsEmailing] = useState(false)
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 24,
  })
  const [loadingButtons, setLoadingButtons] = useState<string[]>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'endDate',
      value: {
        startDate: new Date(),
        endDate: new Date(),
      },
    },
    {
      id: 'source',
      value: 'Load',
    },
  ])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [sortingOptions, setSortingOptions] = useState([
    {
      label: 'Number',
      sortField: 'number',
      isAsc: true,
      sorted: true,
    },
    {
      label: 'Buyer Name',
      sortField: 'buyer.name',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Buyer Code',
      sortField: 'buyer.code',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Seller Name',
      sortField: 'seller.name',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Seller Code',
      sortField: 'seller.code',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Total',
      sortField: 'total',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Qty',
      sortField: 'qty',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Loads',
      sortField: 'loadCount',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Invoice Period',
      sortField: 'endDate',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Due Date',
      sortField: 'dueDate',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Created At',
      sortField: 'createdAt',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Status',
      sortField: 'status',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Seller Status',
      sortField: 'sellerStatus',
      isAsc: false,
      sorted: false,
    },
  ])
  const [companyForm, setCompanyForm] = useState({
    isOpen: false,
    formData: undefined as ICompany | undefined,
  })

  const orderByCompanyStrs = useMemo(() => ['buyer', 'seller'], [])

  const orderParams = useMemo(() => {
    const order: any = {}
    sortingOptions.forEach(st => {
      const [splittedField] = st.sortField.split('.')
      if (st.sorted && !orderByCompanyStrs.includes(splittedField)) {
        order[st.sortField] = st.isAsc ? 'asc' : 'desc'
      }
    })

    return order
  }, [orderByCompanyStrs, sortingOptions])

  const filterParams = useMemo(() => {
    const filters: Record<string, any> = {}
    columnFilters.forEach(({ id, value }) => {
      filters[id] = value
      if (id === 'endDate') {
        filters[id] = {
          ...filters[id],
          useDateWithoutTime: true,
        }
      }
    })
    return filters
  }, [columnFilters])

  const currentScope: EScope = useSelector(selectCurrentScope)
  const statusField = currentScope === EScope.seller ? 'sellerStatus' : 'status'

  const expandedSectionsRef = useRef<any>({})
  const windowSize = useWindowSize()
  const {
    isLoadingInvoicesData,
    isInvoicesDataFetching,
    invoicesData,
    totalCount,
    updateInvoice,
    refetchQueryInvoices,
    removeInvoice,
  } = useQueryInvoices({
    filters: _.omit(filterParams, ['source']),
    source: filterParams.source,
    outstanding: true,
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    order: orderParams,
  })
  const { flagsData } = useQueryFlags(
    {
      filters: {
        flagableType: [EFlagFlagableType.Invoice],
        flagableId: invoicesData.map(({ id }) => id),
      },
    },
    { enabled: invoicesData.length > 0 },
  )

  const { downloadMultipleFiles, downloadFile } = useModalDownloadProgress()
  const { confirmation } = useConfirmationProvider()
  const { onOpenPdfViewer } = useShowingPdfViewer()

  const [
    hideConfirmationWhenDeleteInvoice,
    setHideConfirmationWhenDeleteInvoice,
  ] = useLocalStorage('concord.showConfirmationWhenDeleteInvoice', false)

  const invoiceIds = invoicesData.map(({ id }) => id)

  const { currentUom } = useQueryUoms()
  const {
    getRowClassNameBasedOnTagIds,
    findTagsByModels,
    getTagOptionsByModels,
  } = useQueryTags()

  const tagOptions = findTagsByModels([ETagModels.invoice]).map(
    ({ name, id }) => ({
      name,
      id,
    }),
  )

  const { notesData, addNote, updateNote, removeNote } = useQueryNotes(
    {
      filters: {
        noteableId: invoiceIds,
        noteableType: ENoteableType.Invoice,
      },
    },
    { enabled: invoiceIds.length > 0 },
  )

  const {
    sellerCompanyOptions,
    buyerCompanyOptions,
    findCompanyById,
    updateCompany,
  } = useQueryCompanies({})
  const { buyerTerminalOptions, updateTerminal } = useQueryTerminals()

  const invoiceIdsSelected = Object.keys(rowSelection).map(id => +id)

  const filterOptions = [
    {
      label: 'Source',
      field: 'source',
      type: EFieldType.singleSelect,
      options: [
        {
          label: 'AP (Uploaded)',
          value: 'Invoice',
        },
        {
          label: 'AR (Loads)',
          value: 'Load',
        },
      ],
    },
    {
      label: 'Created At',
      field: 'createdAt',
      type: EFieldType.dateRange,
    },
    {
      label: 'End Date',
      field: 'endDate',
      type: EFieldType.dateRange,
    },
    {
      label: 'Seller',
      field: 'sellerId',
      type: EFieldType.multipleSelect,
      options: sellerCompanyOptions,
    },
    {
      label: 'Buyer',
      field: 'buyerId',
      type: EFieldType.multipleSelect,
      options: buyerCompanyOptions,
    },
    {
      label: 'Buyer Terminal',
      field: 'buyerTerminalId',
      type: EFieldType.multipleSelect,
      options: buyerTerminalOptions,
      customOption: (optProps: any) => {
        const { data } = optProps
        const company = findCompanyById(data?.companyId)
        return (
          <components.Option {...optProps}>
            {company && (
              <Badge style={{ marginRight: 4 }}>{company.code}</Badge>
            )}
            <span>{optProps.data.label}</span>
          </components.Option>
        )
      },
    },
    {
      label: 'Flag Status',
      field: 'flags',
      type: EFieldType.singleSelect,
      options: FLAG_FIELDS,
    },
    {
      label: 'Status',
      field: 'status',
      type: EFieldType.multipleSelect,
      options: invoiceStatusOptions,
      isHidden: currentScope === EScope.seller,
    },
    {
      label: 'Status',
      field: 'sellerStatus',
      type: EFieldType.multipleSelect,
      options: invoiceSellerStatusOptions,
      isHidden: currentScope !== EScope.seller,
    },
    {
      label: 'Type',
      field: 'type',
      type: EFieldType.multipleSelect,
      options: invoiceCategoryOptions,
    },
    {
      label: 'Parser Status',
      field: 'parserStatus',
      type: EFieldType.multipleSelect,
      options: invoicePrimaryParsingStatusOptions,
    },
    {
      label: 'HITL Status',
      field: 'hitlStatus',
      type: EFieldType.multipleSelect,
      options: invoiceHitlStatusOptions,
    },
    {
      label: 'Total',
      field: 'total',
      type: EFieldType.number,
    },
    {
      label: 'Quantity',
      field: 'qty',
      type: EFieldType.number,
    },
    {
      label: 'ASP',
      field: 'asp',
      type: EFieldType.number,
    },
    {
      label: 'Load Count',
      field: 'loadCount',
      type: EFieldType.number,
    },
    {
      label: 'Tag',
      field: 'tagIds',
      type: EFieldType.multipleSelect,
      options: tagOptions,
    },
  ]

  const openDataRulesModal = useCallback((invoice: IInvoice) => {
    setModalState({ show: true, invoice: invoice })
  }, [])

  const updateInvoiceTagIds = useCallback(
    async (invoice: IInvoice, tag: ITag) => {
      if (invoice.tagIds.includes(tag.id)) {
        const [tagLink] = await apiClient.tagLinks.get({
          filters: {
            tagId: tag.id,
          },
        })
        if (tagLink) {
          await apiClient.tagLinks.delete(tagLink.id)
        }
      } else {
        await apiClient.tagLinks.create({
          taggableId: invoice.id,
          taggableType: 'Invoice',
          tagId: tag.id,
        })
      }
      const newTagIds = produce(invoice.tagIds, draft => {
        const index = draft.indexOf(tag.id)
        if (index === -1) {
          draft.push(tag.id)
        } else {
          draft.splice(index, 1)
        }
      })
      updateInvoice(invoice.id, { tagIds: newTagIds })
    },
    [updateInvoice],
  )

  const getShowSendEmailBtn = (invoice: IInvoice) => {
    return [EInvoiceStatus.Approved, EInvoiceStatus.Exported].includes(
      invoice?.sellerStatus as any,
    )
  }

  const onSendEmail = async (invoice: IInvoice, query?: any) => {
    try {
      const res = await apiClient.emailInvoices.create(
        {
          invoiceIds: [invoice.id],
        },
        query,
      )
      if (res.message === 'Email sent') {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    }
  }

  const handleDownloadFile = useCallback(
    (invoice: IInvoice) => async () => {
      const { url } = await apiClient.url.getById(invoice.id, {
        model: 'Invoice',
        urlName:
          currentScope === EScope.seller ? 'generated_invoice' : 'prove_url',
      })
      if (url) {
        const filename = getFileNameFromAWSLink(url, 'concord_invoice')
        downloadFile(url, filename)
      } else {
        toast.error("Can't find any invoice files")
      }
    },
    [currentScope, downloadFile],
  )

  const onExportInvoice = useCallback(
    (invoice: IInvoice) => () => {
      apiClient.invoices.exportCsv(`&invoice_id=${invoice.id}`)
    },
    [],
  )

  const deleteInvoice = useCallback(async (invoice: IInvoice) => {
    const response = await apiClient.invoices.delete(invoice.id)
    if (response.deletedAt) {
      removeInvoice(response.id)
      toast.success(toastMessages.deleteSuccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRemoveInvoice = useCallback(
    (invoice: IInvoice) => async () => {
      if (!hideConfirmationWhenDeleteInvoice) {
        const result = await confirmation({
          message: 'Do you want to delete this invoice',
          showCheckbox: true,
          checkboxLabel: "Don't show again",
          onChangeCheckbox(event, checked) {
            setHideConfirmationWhenDeleteInvoice(checked)
          },
        })
        if (result === EYesNo.Yes) {
          await deleteInvoice(invoice)
        }
      } else {
        await deleteInvoice(invoice)
      }
    },
    [
      confirmation,
      deleteInvoice,
      hideConfirmationWhenDeleteInvoice,
      setHideConfirmationWhenDeleteInvoice,
    ],
  )

  const handleRerunOCRJson = useCallback(
    (invoiceId: number) => {
      apiClient.invoice
        .rerunOcrJson(invoiceId)
        .then(response => {
          updateInvoice(response.id, response)
          toast.success(toastMessages.updateSuccess)
        })
        .catch(err => {
          console.log('error', err)
          toast.error(toastMessages.serverError)
        })
        .finally(() => console.log('Finally'))
    },
    [updateInvoice],
  )

  const getInvoicesContextMenu = useCallback(
    (invoice: IInvoice) => {
      const menu = [
        {
          label: 'Tags',
          icon: <TagIcon />,
          subMenu: getTagOptionsByModels({
            models: [ETagModels.invoice],
            onClick: tag => {
              updateInvoiceTagIds(invoice, tag)
            },
            hasCheckbox: true,
            isChecked: tag => invoice.tagIds.includes(tag.id),
          }),
        },
        {
          label: 'Email',
          isHidden: !getShowSendEmailBtn(invoice),
          onClick() {
            onSendEmail(invoice)
          },
          subMenu: [
            {
              label: 'Email with Default Settings',
              onClick: () => onSendEmail(invoice),
            },
            {
              label: 'Email Invoice Only',
              onClick: () =>
                onSendEmail(invoice, { documentsToInclude: 'invoice' }),
            },
            {
              label: 'Email Tickets Only',
              onClick: () =>
                onSendEmail(invoice, { documentsToInclude: 'tickets' }),
            },
            {
              label: 'Email Invoice & Tickets (Combined File)',
              onClick: () =>
                onSendEmail(invoice, { documentsToInclude: 'combined' }),
            },
            {
              label: 'Email Invoice & Tickets (Separate Files)',
              onClick: () =>
                onSendEmail(invoice, { documentsToInclude: 'separate' }),
            },
          ],
        },
        {
          label: 'Export to CSV',
          onClick: onExportInvoice(invoice),
          icon: <IonIcon icon={cloudDownloadOutline} />,
        },
        {
          label: 'View PDF',
          icon: <PdfIcon />,
          onClick: () =>
            onOpenPdfViewer({
              id: invoice.id,
              model: 'Invoice',
              urlName:
                currentScope === EScope.seller
                  ? 'generated_invoice'
                  : 'prove_url',
            }),
        },
        {
          label: 'Download PDF',
          icon: <IonIcon color='export' icon={cloudDownloadOutline} />,
          onClick: handleDownloadFile(invoice),
          color: 'export',
          isHidden: !invoice.proveUrl,
        },
        {
          label: 'Data Rules',
          icon: <IonIcon color='export' icon={nuclearOutline} />,
          onClick: () => openDataRulesModal(invoice),
          color: 'export',
          isHidden: !invoice.proveUrl,
        },
        {
          label: 'Re-run OCR Json',
          icon: <IonIcon icon={refreshCircleOutline} />,
          onClick: () => handleRerunOCRJson(invoice.id),
        },
        {
          label: 'Delete',
          icon: <IonIcon icon={trashOutline} />,
          onClick: onRemoveInvoice(invoice),
          color: 'danger',
          isHidden: (invoice.sellerStatus as any) !== EInvoiceStatus.Progress,
        },
      ]

      return menu
    },
    [
      getTagOptionsByModels,
      handleDownloadFile,
      handleRerunOCRJson,
      onExportInvoice,
      onOpenPdfViewer,
      openDataRulesModal,
      updateInvoiceTagIds,
      currentScope,
      onRemoveInvoice,
    ],
  )

  const onOpenCompanyForm = useCallback((company: ICompany | undefined) => {
    setCompanyForm({
      formData: company,
      isOpen: true,
    })
  }, [])

  const onCloseCompanyForm = useCallback(() => {
    setCompanyForm({
      formData: undefined,
      isOpen: false,
    })
  }, [])

  const onCellEditEnd = useCallback(
    (value: any, cell: RTCell<IInvoice>) => {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id

      updateInvoice(rowId, { [columnField]: value })
      expandedSectionsRef.current[rowId]?.updateInvoice(rowId, {
        [columnField]: value,
      })
    },
    [updateInvoice],
  )

  const downloadInvoicePdfs = useCallback(
    (urlName: string) => async () => {
      setIsDownloadingFiles(true)
      try {
        const apiCalls = invoiceIdsSelected.map(id =>
          apiClient.url.getById(id, {
            model: 'Invoice',
            urlName,
          }),
        )
        const urlsResponse = await Promise.all(apiCalls)
        const urls = urlsResponse.map(({ url }) => url).filter(Boolean)
        const files = urls.map(url => {
          const filename = getFileNameFromAWSLink(url, 'concord_invoice')
          return {
            name: filename,
            url,
          }
        })
        if (files.length > 0) {
          downloadMultipleFiles(files)
          setRowSelection({})
        } else {
          toast.error("Can't find any invoice files")
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsDownloadingFiles(false)
      }
    },
    [downloadMultipleFiles, invoiceIdsSelected],
  )
  const toggleLoadingButtons = useCallback((buttonName: string) => {
    setLoadingButtons(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(buttonName)
        if (index === -1) {
          draft.push(buttonName)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const deleteInvoices = async () => {
    const ids = invoiceIdsSelected.join(', ')
    const result = await confirmation({
      message: `Are you sure you want to delete invoices: ${ids}`,
    })
    if (result === EYesNo.Yes) {
      const apis: Promise<any>[] = []
      invoiceIdsSelected.forEach(selectedId => {
        apis.push(apiClient.invoices.delete(selectedId))
      })
      toggleLoadingButtons('delete')
      try {
        await Promise.all(apis)
        setRowSelection({})
        refetchQueryInvoices()
        toast.success(toastMessages.deleteSuccess)
      } catch (error: any) {
        console.log('error.message', error?.message)
        toast.error(toastMessages.serverError)
      } finally {
        toggleLoadingButtons('delete')
      }
    }
  }

  const onShowEmailInvoices = useCallback(async () => {
    setIsEmailing(true)
    try {
      const res = await apiClient.emailInvoices.create({
        invoiceIds: invoiceIdsSelected,
      })
      if (res.message === 'Email sent') {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsEmailing(false)
    }
  }, [invoiceIdsSelected])

  const footerButtonActions: IRTFooterActionItem[] = [
    {
      label: `Selected: ${
        invoiceIdsSelected.length ? invoiceIdsSelected.length : 0
      }`,
      variant: 'outline-dark',
      isDisabled: true,
      className: 'rounded-pill border-0 fw-bolder',
    },
    {
      label: 'Download Invoices',
      onClick: downloadInvoicePdfs(
        currentScope === EScope.seller ? 'generated_invoice' : 'prove_url',
      ),
      isLoading: isDownloadingFiles,
    },
    {
      label: 'Download Tickets',
      onClick: downloadInvoicePdfs('tickets'),
      isLoading: isDownloadingFiles,
    },
    {
      label: 'Delete',
      variant: 'danger',
      onClick: deleteInvoices,
      isLoading: loadingButtons.includes('delete'),
    },
    {
      label: 'Email Invoices',
      onClick: onShowEmailInvoices,
      isLoading: isEmailing,
    },
  ]

  const columns: IRTColumnDef<IInvoice>[] = [
    {
      header: 'Inv #',
      id: 'number',
      align: 'center',
      accessorFn: row => row.number || row.num || row.id,
      maxSize: 80,
      minSize: 80,
      enableEditing: enableEditing,
    },
    {
      header: 'Dates',
      accessorKey: 'endDate',
      size: 160,
      Cell: ({ row }) => {
        const { endDate, startDate } = row.original

        if (!startDate) {
          return <span>No date</span>
        }
        const formatDate = 'MMM-d yyyy (eee)'
        const start = format(new Date(startDate), formatDate)
        if (!endDate) {
          return <span>{`${start} - ONGOING`}</span>
        }
        const end = format(new Date(endDate), formatDate)

        if (start === end) {
          return start
        }

        return <span>{`${start} - ${end}`}</span>
      },
    },
    {
      header: 'Seller',
      accessorKey: 'sellerId',
      size: 200,
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        const company = findCompanyById(cellValue)

        return (
          <CompanyInfo
            searchableGoogle={false}
            company={
              {
                value: company?.id,
                name: company?.name,
                image: company?.avatar,
                code: company?.code,
              } as any
            }
            companyType={EScope.seller}
            hideAnchor
            onContextMenu={event => {
              event.preventDefault()
              onOpenCompanyForm(company)
            }}
          />
        )
      },
      Footer({ table }) {
        const countUniq = _.uniqBy(table.options.data, 'sellerId').length
        return (
          <div>
            Seller:
            <Badge style={{ fontSize: 12 }}> {countUniq}</Badge>
          </div>
        )
      },
    },
    {
      header: 'Buyer',
      accessorKey: 'buyerId',
      size: 220,
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        const company = findCompanyById(cellValue)

        return (
          <div className='d-flex align-items-center'>
            <CompanyInfo
              hideAnchor
              searchableGoogle={false}
              company={
                {
                  value: company?.id,
                  name: company?.name,
                  image: company?.avatar,
                  code: company?.code,
                } as any
              }
              onContextMenu={event => {
                event.preventDefault()
                onOpenCompanyForm(company)
              }}
            />
          </div>
        )
      },
      Footer({ table }) {
        const countUniq = _.uniqBy(table.options.data, 'buyerId').length
        return (
          <div>
            Buyer:
            <Badge style={{ fontSize: 12 }}> {countUniq}</Badge>
          </div>
        )
      },
    },
    {
      header: 'Buyer Terminal',
      accessorKey: 'buyerTerminalId',
      className: clsx({ isReadOnly: !enableEditing }),
      size: 180,
      editVariant: EFieldType.singleSelect,
      editSelectOptions({ row: { original } }) {
        return buyerTerminalOptions.filter(
          ({ companyId }) => companyId === original.buyerId,
        )
      },
      editDropdownFieldProps() {
        return {
          showUpdateButtons: true,
          renderForm({ isOpen, onCloseForm, optionSelected }: any) {
            return (
              <DialogTerminalForm
                isOpen={isOpen}
                formData={optionSelected?.item}
                onClose={onCloseForm}
                afterUpdate={(terminal: ITerminal) => {
                  updateTerminal(terminal.id, terminal)
                  onCloseForm()
                }}
              />
            )
          },
        }
      },
      enableEditing: true,
      Footer({ table }) {
        const countUniq = _.uniqBy(table.options.data, 'buyerTerminalId').length
        return (
          <div>
            Buyer Terminals:
            <Badge style={{ fontSize: 12 }}> {countUniq}</Badge>
          </div>
        )
      },
    },
    {
      header: 'Total',
      accessorKey: 'total',
      size: 150,
      align: 'center',
      Cell: ({ cell, row }) => {
        const cellData = cell.getValue<number>()
        const rowData = row.original
        const perQty = Math.ceil(
          Number(cellData || 0) / Number(rowData.qty || 0),
        )
        const perLd = Math.ceil(
          Number(cellData || 0) / Number(rowData.loadCount || 0),
        )

        return (
          <>
            <ToolTipOverlay
              placement='top'
              className='pull-content-in-tooltip-left'
              content={
                <ul
                  style={{
                    marginBottom: 0,
                    listStyleType: 'none',
                    paddingLeft: 0,
                  }}
                >
                  <li>Total: {formatCurrencyToDollar.format(cellData)}</li>
                  <li>
                    Per {currentUom?.code}:{' '}
                    {isNaN(perQty) || perQty === Infinity
                      ? '$0.00'
                      : formatCurrencyToDollar.format(perQty)}
                  </li>
                  <li>
                    Per LD:&nbsp;
                    {isNaN(perLd) || perLd === Infinity
                      ? '$0.00'
                      : formatCurrencyToDollar.format(perLd)}
                  </li>
                </ul>
              }
            >
              <IonChip className='InvoiceTableGrid__invoiceBillItem'>
                <IonText color='light'>
                  {formatCurrencyToDollar.format(cellData)}
                </IonText>
              </IonChip>
            </ToolTipOverlay>
          </>
        )
      },
      Footer({ table }) {
        const result = table.options.data.reduce((sum, { total }) => {
          return (sum += Number(total))
        }, 0)
        return (
          <span style={{ color: '#1090FF' }}>
            {formatCurrencyToDollar.format(result)}
          </span>
        )
      },
    },
    {
      header: 'Quantity',
      accessorKey: 'qty',
      size: 150,
      align: 'right',
      Cell: ({ cell }) => {
        const cellData = cell.getValue()

        return (
          <>
            <ToolTipOverlay content='Quantity'>
              <IonChip className='InvoiceTableGrid__invoiceBillItem'>
                <IonText color='light'>
                  {Number(cellData)} {currentUom?.code}
                </IonText>
              </IonChip>
            </ToolTipOverlay>
          </>
        )
      },
      Footer({ table }) {
        const total = table.options.data.reduce((sum, { qty }) => {
          return (sum += Number(qty))
        }, 0)
        return <span style={{ color: '#1090FF' }}>{roundNumber(total)}</span>
      },
    },
    {
      header: 'perQty',
      Header() {
        return `Per Qty (${currentUom?.code})`
      },
      accessorKey: 'perQty',
      align: 'right',
      Cell({ row }) {
        const rowData = row.original
        const total = rowData.total
        const perQty = Math.ceil(Number(total || 0) / Number(rowData.qty || 0))
        return (
          <div>
            {isNaN(perQty) || perQty === Infinity
              ? '$0.00'
              : formatCurrencyToDollar.format(perQty)}
          </div>
        )
      },
    },
    {
      header: 'Per LD',
      accessorKey: 'perLd',
      align: 'right',
      Cell({ row }) {
        const rowData = row.original
        const total = rowData.total
        const perLd = Math.ceil(
          Number(total || 0) / Number(rowData.loadCount || 0),
        )
        return (
          <div>
            {isNaN(perLd) || perLd === Infinity
              ? '$0.00'
              : formatCurrencyToDollar.format(perLd)}
          </div>
        )
      },
    },

    {
      header: 'Balance',
      accessorKey: 'outstanding.outstanding',
      size: 140,
      align: 'right',
      Cell({ row }) {
        return formatCurrencyToDollar.format(
          row.original.outstanding?.outstanding || 0,
        )
      },
    },
    {
      header: 'Payments',
      accessorKey: 'outstanding.payments',
      size: 110,
      align: 'right',
      Cell({ row }) {
        return formatCurrencyToDollar.format(
          row.original.outstanding?.payments || 0,
        )
      },
    },
    {
      header: 'Due Date',
      accessorKey: 'dueDate',
      size: 130,
      Cell: ({ row }) => {
        const rowData = row.original
        if (rowData?.dueDate) {
          const dueDate = moment(rowData?.dueDate).format('MMM DD')
          return <IonText>{dueDate}</IonText>
        }

        return 'No Date'
      },
    },
    {
      header: 'Adjustment',
      accessorKey: 'outstanding.adjustment',
      size: 120,
      align: 'right',
      Cell({ row }) {
        return formatCurrencyToDollar.format(
          row.original.outstanding?.adjustment || 0,
        )
      },
    },
    {
      header: 'Tax',
      accessorKey: 'tax',
      size: 120,
      align: 'right',
      Cell({ row }) {
        return formatCurrencyToDollar.format((row.original.tax as any) || 0)
      },
    },
    {
      header: 'Sub total',
      accessorKey: 'sub',
      size: 120,
      align: 'right',
      Cell({ row }) {
        return formatCurrencyToDollar.format((row.original.sub as any) || 0)
      },
    },
    {
      header: 'Loads',
      accessorKey: 'loadCount',
      size: 100,
      Cell: ({ row }) => {
        const rowData = row.original
        return (
          <>
            <ToolTipOverlay content='Loads'>
              <IonChip className='InvoiceTableGrid__invoiceBillItem'>
                <IonText color='light'>{rowData.loadCount}&nbsp;LD</IonText>
              </IonChip>
            </ToolTipOverlay>
          </>
        )
      },
      Footer({ table }) {
        const total = table.options.data.reduce((sum, { loadCount }) => {
          return (sum += Number(loadCount))
        }, 0)
        return <span style={{ color: '#1090FF' }}>{Number(total)}</span>
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      size: 160,
      Cell: ({ row }) => {
        const rowData = row.original
        return (
          <>
            <InvoiceStatusChip
              status={rowData[statusField]}
              invoice={rowData}
              flagsData={flagsData.filter(
                ({ flagableId, flagableType }) =>
                  flagableId === rowData.id &&
                  flagableType === EFlagFlagableType.Invoice,
              )}
              afterSendEmail={(invoice: IInvoice) => {
                updateInvoice(invoice.id, invoice)
              }}
            />
          </>
        )
      },
    },
    {
      header: 'Type',
      accessorKey: 'category',
      size: 130,
      Cell: ({ cell }) => {
        const cellData = cell.getValue<any>()
        const icon = (icons as any)[`${cellData}`.toLowerCase()]
        const isEmptyIcon = _.isEmpty(icon)

        return (
          <>
            {cellData && !isEmptyIcon && (
              <IonIcon
                color={(typesColor as any)[cellData]}
                className='mt-2'
                icon={icon}
              />
            )}
            &nbsp;
            <IonText color={(typesColor as any)[cellData]}>{cellData}</IonText>
          </>
        )
      },
    },
    {
      header: 'Thumbnail',
      accessorKey: 'thumbnail',
      size: 90,
      Cell: ({ row }) => {
        const rowData = row.original
        const { imgThumbnailUrl } = rowData
        if (!imgThumbnailUrl) {
          return null
        }

        return (
          <div style={{ marginRight: 0 }}>
            <Thumbnail
              style={{ width: '100%' }}
              image={imgThumbnailUrl}
              slot='start'
              enablePopupImage={true}
            />
          </div>
        )
      },
    },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      size: 130,
      Cell: ({ row }) => {
        const rowData = row.original
        if (rowData?.createdAt) {
          return format(new Date(rowData.createdAt), 'yyyy-MM-dd')
        }

        return ''
      },
    },
  ]

  useDeepCompareEffect(() => {
    if (externalFilterData) {
      setColumnFilters(prev => mergeArrayById(prev, externalFilterData))
    }
  }, [externalFilterData])

  return (
    <>
      <ReusableTable
        enableCompanyView
        companyViewProps={{
          name: 'invoices',
          description: 'Manage your company invoices',
          isHiddenTopButton: true,
        }}
        data={invoicesData}
        columns={columns}
        tableHeight={windowSize.height - 265}
        enableTopToolbar
        enablePagination
        enableBottomToolbar
        enableRowActions
        enableRowSelection
        enableFooterActions={invoiceIdsSelected.length > 0}
        manualPagination
        manualFiltering
        manualSorting
        enableGrouping
        overriddenSortOptions={sortingOptions}
        rowCount={totalCount}
        getRowClassName={getRowClassNameBasedOnTagIds}
        state={{
          showProgressBars: isInvoicesDataFetching,
          columnFilters,
          rowSelection,
          isLoading: isLoadingInvoicesData,
          pagination,
        }}
        initialState={{
          columnPinning: {
            left: [
              ERTDisplayColumnId.select,
              ERTDisplayColumnId.actions,
              ERTDisplayColumnId.expand,
            ],
            right: ['status'],
          },
        }}
        displayColumnDefOptions={{
          [ERTDisplayColumnId.actions]: {
            maxSize: 100,
            minSize: 100,
          },
          [ERTDisplayColumnId.select]: {
            maxSize: 30,
            minSize: 30,
          },
          [ERTDisplayColumnId.expand]: {
            maxSize: 40,
            minSize: 40,
          },
        }}
        renderCellActionMenuItems={({ row }) => {
          const menuItems = getInvoicesContextMenu(row.original)
          return menuItems
        }}
        renderRowActions={({ row, table, cell }) => {
          const rowData = row.original

          return [
            {
              icon: <OpenIcon color='white' />,
              href: `/invoices/${rowData.id}`,
              state: {
                invoice: rowData,
              },
              color: 'dark',
            },
            {
              render: () => {
                const notes = notesData.filter(
                  ({ noteableId }) => noteableId === rowData.id,
                )
                return (
                  <RTActionNoteButton
                    notesData={notes}
                    noteableId={rowData.id}
                    noteableType={ENoteableType.Invoice}
                    afterCreateNote={addNote}
                    afterUpdateNote={note => {
                      updateNote(note.id, note)
                    }}
                    afterDeleteNote={note => {
                      removeNote(note.id)
                    }}
                  />
                )
              },
            },
            {
              render: () => <RTActionButtonMenu table={table} cell={cell} />,
            },
          ]
        }}
        renderDetailPanel={({ row }) => (
          <InvoiceDetailsSection
            invoiceId={row.original.id}
            onChangeInvoice={(invoice: IInvoice) => {
              updateInvoice(invoice.id, invoice)
            }}
            ref={node => {
              expandedSectionsRef.current[row.original.id] = node
            }}
            invoice={row.original}
          />
        )}
        renderFooterActions={() => footerButtonActions}
        filterOptions={filterOptions as any}
        onRowSelectionChange={setRowSelection}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={callback => {
          const sorting =
            typeof callback === 'function'
              ? callback(
                  sortingOptions
                    .filter(({ sorted }) => sorted)
                    .map(({ sortField, isAsc }) => ({
                      id: sortField,
                      desc: !isAsc,
                    })),
                )
              : callback

          const sortMap = new Map(
            sorting.map(({ id, desc }, index) => [id, { index, isAsc: !desc }]),
          )

          const newSorting = [...sortingOptions]
            .map(item => ({
              ...item,
              isAsc: sortMap.has(item.sortField)
                ? sortMap.get(item.sortField)?.isAsc
                : item.isAsc,
              sorted: sortMap.has(item.sortField) ? true : false,
            }))
            .sort((a, b) => {
              const aIndex = sortMap.has(a.sortField)
                ? (sortMap.get(a.sortField)?.index as any)
                : Infinity
              const bIndex = sortMap.has(b.sortField)
                ? (sortMap.get(b.sortField)?.index as any)
                : Infinity
              return aIndex - bIndex
            })

          // const newSorting = sortingOptions.map(st => {
          //   const sorted = sorting.find(({ id }) => id === st.sortField)
          //   const isAsc =
          //     typeof sorted?.desc === 'boolean' ? !sorted.desc : st.isAsc
          //   return {
          //     ...st,
          //     sorted: Boolean(sorted),
          //     isAsc,
          //   }
          // })
          setSortingOptions(newSorting as any)
        }}
        onCellEditEnd={onCellEditEnd}
      />
      <DialogCompanyForm
        formData={companyForm.formData as any}
        isOpen={companyForm.isOpen}
        onClose={onCloseCompanyForm}
        afterUpdate={company => {
          updateCompany(company.id, company)
          onCloseCompanyForm()
        }}
      />
      <Modal
        show={modalState.show}
        onHide={() => setModalState({ show: false, invoice: undefined })}
      >
        <DataParsersForm
          // columnName=''
          type='Invoice'
          doc={modalState.invoice}
        />
      </Modal>
    </>
  )
}

export default ReusableInvoicesTable
