import { IInvoice } from '~/types/models/IInvoice'
import BillLinesTable from '../BillLinesTable'
import { useQueryBillLines } from '~/hooks/useQueryData'
import getIsInvoiceEditingDisabled from '~/utils/getIsInvoiceEditingDisabled'

import './styles.scss'

export interface IMatchedBillLineRowProps {
  invoice: IInvoice
  billLineId: number
}

function MatchedBillLineRow(props: IMatchedBillLineRowProps) {
  const { invoice, billLineId } = props

  const isInvoiceApproved = getIsInvoiceEditingDisabled(invoice)

  const { billLinesData, isLoadingBillLinesData } = useQueryBillLines({
    id: billLineId,
  })

  return (
    <BillLinesTable
      isUseQueryDisabled
      queryParams={{}}
      invoice={invoice}
      data={billLinesData}
      enableRowSelection={isInvoiceApproved ? false : () => false}
      enableTableFooter={false}
      className='MatchedBillLineRow__container'
      tableHeight={51.5}
      renderRowActions={() => []}
      state={{
        isLoading: isLoadingBillLinesData,
      }}
    />
  )
}

export default MatchedBillLineRow
