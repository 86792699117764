import { useRef } from 'react'
import { ReorderCheckbox, ReusableButton, SortIcon } from '~/components/shared'
import { ControlledMenu, useClick, useMenuState } from '@szhsin/react-menu'

import './styles.scss'

export interface IBillLinesSortOptionsProps {
  sortOptions: {
    label: string
    sortField: string
    sorted: boolean
    isAsc: boolean
  }[]
  onSortChange: (sortOptions: IBillLinesSortOptionsProps['sortOptions']) => void
}

function BillLinesSortOptions(props: IBillLinesSortOptionsProps) {
  const { sortOptions, onSortChange } = props

  const buttonRef = useRef(null)
  const [menuState, toggleMenu] = useMenuState({ transition: true })
  const anchorProps = useClick(menuState.state, toggleMenu)

  return (
    <>
      <ReusableButton variant='secondary' ref={buttonRef} {...anchorProps}>
        <SortIcon color='white' />
      </ReusableButton>
      <ControlledMenu
        {...menuState}
        anchorRef={buttonRef}
        onClose={() => toggleMenu(false)}
      >
        <ReorderCheckbox
          className='BillLinesSortOptions__sortOptions'
          canReorder
          hasDirection
          onSortChange={onSortChange}
          sortOptions={sortOptions}
        />
      </ControlledMenu>
    </>
  )
}

export default BillLinesSortOptions
