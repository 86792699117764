import { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { IonButton } from '@ionic/react'
import { Card } from 'react-bootstrap'
import { ToolTipOverlay } from '~/components/shared'

import _ from 'lodash'
import loadable from '@loadable/component'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { useQueryProductGroups } from '~/hooks/useQueryData'

const ConcordFormTextField = loadable(() =>
  import('~/components/shared/ConcordForm/TextField'),
)

const ConcordFormDropdown = loadable(() =>
  import('~/components/shared/ConcordForm/Dropdown/Dropdown'),
)

function SellerProductDropdownForm(props) {
  const {
    isOpenForm: isOpenFormProps,
    isLoading: isLoadingProps,
    onOpen,
    onClose,
    initialFormValues,
    onCreate,
    onUpdate,
    buyerId,
    sellerId,
  } = props

  const { productGroupOptions } = useQueryProductGroups()

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)

  const schema = Yup.object({
    name: Yup.string()
      .max(255, 'Maximum character is 255!')
      .required('This field is required!'),
    code: Yup.string()
      .max(255, 'Maximum character is 255!')
      .required('This field is required!'),
  })

  const { setValue, watch, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      name: '',
      code: '',
      id: null,
      productGroupId: null,
    },
    resolver: yupResolver(schema),
  })
  const watchName = watch('name', '')
  const watchCode = watch('code', '')
  const watchProductGroupId = watch('productGroupId', null)
  const watchId = watch('id', null)

  const handleOpenForm = useCallback(() => {
    setIsOpenForm(true)
    onOpen && onOpen()
  }, [onOpen])

  const handleCloseForm = useCallback(() => {
    setIsOpenForm(false)
    onClose && onClose()
    reset()
  }, [onClose, reset])

  const handleChangeValues = useCallback(
    event => {
      const { value, name } = event.target
      setValue(name, value)
    },
    [setValue],
  )

  const handleChangeOption = useCallback(
    selectedOption => {
      setValue('productGroupId', selectedOption.value)
    },
    [setValue],
  )

  const updateProduct = useCallback(
    async formData => {
      setIsLoading(true)
      try {
        const payload = _.pick(formData, ['code', 'name', 'productGroupId'])
        const response = await apiClient.sellerProducts.update(
          formData.id,
          payload,
        )
        if (_.size(response.errors) === 0) {
          onUpdate && onUpdate(response)
          toast.success(toastMessages.updateSuccess)
          handleCloseForm()
        } else {
          toast.error(toastMessages.updateError)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setIsLoading(false)
      }
    },
    [handleCloseForm, onUpdate],
  )

  const createProduct = useCallback(
    async formData => {
      setIsLoading(true)
      try {
        const { code, name, productGroupId } = formData
        const response = await apiClient.sellerProducts.create({
          code,
          name,
          productGroupId,
          buyerId,
          sellerId,
        })
        if (response.id) {
          const { errors, ...sellerProduct } = response
          onCreate && onCreate(sellerProduct)
          toast.success(toastMessages.createSuccess)
          handleCloseForm()
        } else {
          toast.error(toastMessages.createError)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.createError)
      } finally {
        setIsLoading(false)
      }
    },
    [buyerId, handleCloseForm, onCreate, sellerId],
  )

  const handleSubmitForm = handleSubmit(formData => {
    if (formData.id) {
      updateProduct(formData)
    } else {
      createProduct(formData)
    }
  })

  useEffect(() => {
    if (_.isBoolean(isLoadingProps)) {
      setIsLoading(isLoadingProps)
    }
  }, [isLoadingProps])

  useEffect(() => {
    if (_.isBoolean(isOpenFormProps)) {
      setIsOpenForm(isOpenFormProps)
    }
  }, [isOpenFormProps])

  useEffect(() => {
    reset(initialFormValues)
  }, [initialFormValues, reset])

  if (!isOpenForm) {
    return (
      <ToolTipOverlay
        content='Set Seller / Buyer before creating product'
        allowToShow={!(sellerId && buyerId)}
        placement='bottom'
      >
        <div>
          <IonButton
            style={{ width: '100%' }}
            onClick={handleOpenForm}
            disabled={isLoading || !(sellerId && buyerId)}
          >
            Add new product
          </IonButton>
        </div>
      </ToolTipOverlay>
    )
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Card style={{ marginTop: 8 }}>
        <Card.Body>
          <ConcordFormTextField
            label='Product Code'
            name='code'
            error={formState?.errors?.code?.message}
            value={watchCode}
            onChange={handleChangeValues}
          />
          <ConcordFormTextField
            label='Product Name'
            name='name'
            error={formState?.errors?.name?.message}
            value={watchName}
            onChange={handleChangeValues}
          />
          <ConcordFormDropdown
            label='Product Group'
            name='productGroupId'
            value={watchProductGroupId}
            options={productGroupOptions}
            onChange={handleChangeOption}
          />
        </Card.Body>
        <Card.Footer>
          <IonButton color='primary' type='submit' disabled={isLoading}>
            {watchId ? 'Update' : 'Create'}
          </IonButton>
          <IonButton color='danger' onClick={handleCloseForm}>
            Close
          </IonButton>
        </Card.Footer>
      </Card>
    </form>
  )
}

SellerProductDropdownForm.propTypes = {}

export default SellerProductDropdownForm
