import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  CloseIcon,
  ConcordFormStructure,
  IConcordFormField,
  PlusIcon,
  WorkTypeForm,
  WorkForceForm,
  ConcordFormLayout,
  TerminalForm,
  GroupForm,
  CompanyForm,
  ConcordFormMultipleDropdownSelector,
  ConcordFormCheckbox,
  DialogCompanyForm,
} from '~/components/shared'

import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ISellerToBuyerTerminalFormProps } from './type'
import { EFieldType, EScope, EYesNo } from '~/types/enums/ECommonEnum'
import {
  useQueryTerminals,
  useQueryGroups,
  useQueryWorkTypes,
  useQueryWorkForces,
  useQuerySalesProposals,
  useQueryCompanies,
  useQueryUsers,
  useQueryBidders,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import {
  ESellerToBuyerTerminalStatus,
  ISellerToBuyerTerminal,
  ISellerToBuyerTerminalFormValues,
} from '~/types/models/ISellerToBuyerTerminal'
import moment from 'moment'
import { SalesProposalCard } from './SalesProposalCard'
import { ITerminal } from '~/types/models/ITerminal'
import { ICommonOption } from '~/types/models/ICommonModel'
import { IUser } from '~/types/models/IUser'
import { IBidder } from '~/types/models/IBidder'
import { useDeepCompareEffect } from 'react-use'
import { produce } from 'immer'
import { components } from 'react-select'
import { sellerToBuyerTerminalSchema } from './utils'

const SellerToBuyerTerminalForm = (props: ISellerToBuyerTerminalFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isWorkTypeFormOpen, setIsWorkTypeFormOpen] = useState(false)
  const [isWorkForceFormOpen, setIsWorkForceFormOpen] = useState(false)
  const [isSalesProposalCreating, setIsSalesProposalCreating] = useState(false)
  const [showBuyerTerminal, setShowBuyerTerminal] = useState(false)
  const [showSellerTerminalForm, setShowSellerTerminalForm] = useState(false)
  const [showGroupForm, setShowGroupForm] = useState(false)
  const [showCompanyForm, setShowCompanyForm] = useState(false)
  const [localBiddersData, setLocalBiddersData] = useState<IBidder[]>([])
  const [removingBidderIds, setRemovingBidderIds] = useState<number[]>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentUser: IUser = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const existingBidders = localBiddersData.filter(({ id }) => id)
  const createdBidders = localBiddersData.filter(({ id }) => !id)

  const bidderBuyerIds = localBiddersData.map(({ buyerId }) => buyerId)
  const createdBidderBuyerIds = createdBidders.map(({ buyerId }) => buyerId)

  const getExistingBidder = (buyerId: number) =>
    existingBidders.find(item => item.buyerId === buyerId)

  const {
    sellerCompanyOptions,
    buyerCompanyOptions,
    isLoadingCompaniesData,
    findCompanyById,
    addCompany,
  } = useQueryCompanies({})

  const { biddersData, isBiddersDataFetched, addBidder, removeBidder } =
    useQueryBidders(
      {
        filters: {
          sellerToBuyerTerminalId: formData?.id as number,
        },
      },
      { enabled: Boolean(formData?.id) },
    )

  const { salesProposalsData, updateSalesProposal, addSalesProposal } =
    useQuerySalesProposals(
      {
        filters: {
          sellerToBuyerTerminalId: formData?.id as number,
        },
      },
      { enabled: Boolean(formData?.id) },
    )

  const { workTypeOptions, isWorkTypesLoading, addWorkType } =
    useQueryWorkTypes()

  const { workForceOptions, isWorkForcesLoading, addWorkForce } =
    useQueryWorkForces()

  const { groupOptions, isLoadingGroups, addGroup } = useQueryGroups()
  const { terminalOptions, isLoadingTerminals, addTerminal, updateTerminal } =
    useQueryTerminals()

  const { userAccessOptions, isLoadingUsersData } = useQueryUsers()

  const renderTerminalForm = (options: {
    show: boolean
    formData?: Partial<ITerminal>
    afterUpdate?: (terminal: ITerminal) => void
    afterCreate?: (terminal: ITerminal) => void
    onClose: () => void
    companyOptions?: ICommonOption[]
    readOnlyFields?: string[]
  }) => {
    const { show, onClose, ...formProps } = options
    if (show) {
      return (
        <div style={{ border: '1px solid var(--bs-gray-500)' }}>
          <TerminalForm
            {...formProps}
            isHiddenCancelButton={false}
            cancelText='Close'
            onCancel={onClose}
          />
        </div>
      )
    }
    return null
  }

  const onCloseSellerTerminalForm = () => {
    setShowSellerTerminalForm(false)
  }

  const onCloseBuyerTerminalForm = () => {
    setShowBuyerTerminal(false)
  }

  const onCloseGroupForm = () => {
    setShowGroupForm(false)
  }

  const onCloseCompanyForm = () => {
    setShowCompanyForm(false)
  }

  const onToggleBidderLoading = (id: number) => {
    setRemovingBidderIds(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(id)
        if (index === -1) {
          draft.push(id)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }

  const onDeleteExistingBidder = async (bidder: IBidder) => {
    onToggleBidderLoading(bidder.buyerId)
    setError('')
    try {
      const response = await apiClient.bidders.remove(bidder.id)
      if (response.deletedAt) {
        removeBidder(response.id)
      } else {
        setError(toastMessages.deleteError)
      }
    } catch (error) {
      console.log('error', error)
      setError(toastMessages.deleteError)
    } finally {
      onToggleBidderLoading(bidder.buyerId)
    }
  }

  const fields: IConcordFormField[] = [
    {
      label: 'Seller',
      name: 'sellerId',
      options: sellerCompanyOptions,
      type: EFieldType.singleSelect,
      size: 6,
      isRequired: true,
      isReadOnly: currentScope === EScope.seller,
      isLoading: isLoadingCompaniesData,
    },
    {
      label: 'Seller Terminal',
      name: 'sellerTerminalId',
      extraIcons: [
        {
          Icon: isWorkForceFormOpen ? (
            <CloseIcon color='var(--ion-color-danger)' />
          ) : (
            <PlusIcon color='var(--ion-color-concord)' />
          ),
          onClick() {
            setIsWorkForceFormOpen(prev => !prev)
          },
        },
      ],
      options({ watch }) {
        const sellerId = watch('sellerId', null)
        return terminalOptions.filter(({ companyId }) => companyId === sellerId)
      },
      type: EFieldType.singleSelect,
      size: 6,
      isDisabled: ({ watch }) => !watch('sellerId', null),
      hint({ watch }) {
        if (!watch('sellerId', null)) {
          return 'Select Seller first'
        }
      },
    },
    {
      label: '',
      name: 'sellerTerminalForm',
      render({ watch }) {
        const sellerId = watch('sellerId')

        return renderTerminalForm({
          show: showSellerTerminalForm,
          afterCreate(terminal) {
            formRef.current?.setValue('sellerId', terminal.companyId)
            formRef.current?.setValue('sellerTerminalId', terminal.id)
            onCloseSellerTerminalForm()
            addTerminal(terminal)
          },
          onClose: onCloseSellerTerminalForm,
          formData: {
            companyId: sellerId,
          },
          readOnlyFields: ['companyId'],
        })
      },
    },
    {
      label: 'Bidders',
      name: 'biddersAttributes',
      render({ label, watch, setValue }) {
        const buyerId = watch('buyerId', null)

        return (
          <ConcordFormLayout>
            <ConcordFormMultipleDropdownSelector
              showCreateButton
              options={buyerCompanyOptions}
              label={label}
              isLoading={isLoadingCompaniesData}
              value={bidderBuyerIds}
              loadingIds={removingBidderIds}
              styles={{
                multiValue: (provided: any, { data }: any) => {
                  return {
                    ...provided,
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.675,
                    },
                    border:
                      data.value === buyerId ? '1px solid red' : undefined,
                  }
                },
              }}
              components={{
                MultiValueLabel: (cprops: any) => {
                  const { data } = cprops

                  return (
                    <div
                      onMouseDown={e => e.stopPropagation()}
                      onClick={() => {
                        setValue('buyerTerminalId', null)
                        if (buyerId === data.value) {
                          setValue('buyerId', null)
                          setValue('wasThisJobAwarded', false)
                        } else {
                          setValue('buyerId', data.value)
                          setValue('wasThisJobAwarded', true)
                        }
                      }}
                    >
                      <components.MultiValueLabel {...cprops} />
                    </div>
                  )
                },
              }}
              onChange={async (
                { action, removedValue }: any,
                { value }: any,
              ) => {
                if (action === 'remove-value') {
                  const existingBidder = getExistingBidder(removedValue.value)
                  if (existingBidder) {
                    onDeleteExistingBidder(existingBidder)
                  } else {
                    const newBidders: IBidder[] = value.map(
                      (buyerId: number) => ({
                        buyerId,
                        sellerToBuyerTerminalId: formData?.id,
                      }),
                    )
                    setLocalBiddersData(newBidders)
                  }
                } else {
                  const newBidders: IBidder[] = value.map(
                    (buyerId: number) => ({
                      buyerId,
                      sellerToBuyerTerminalId: formData?.id,
                    }),
                  )
                  setLocalBiddersData(newBidders)
                }
              }}
              renderForm={({
                isOpen,
                optionSelected,
                onCloseForm,
                prevInputValue,
              }: any) => {
                return (
                  <DialogCompanyForm
                    isOpen={isOpen}
                    onClose={onCloseForm}
                    formData={
                      optionSelected?.item || {
                        name: prevInputValue,
                        legalName: prevInputValue,
                        buyer: EYesNo.Yes,
                      }
                    }
                    afterCreate={async company => {
                      addCompany(company)
                      onCloseForm()
                      setLocalBiddersData(prev => [
                        ...prev,
                        {
                          buyerId: company.id,
                          sellerToBuyerTerminalId: formData?.id,
                        } as any,
                      ])
                    }}
                  />
                )
              }}
            />
          </ConcordFormLayout>
        )
      },
    },
    {
      label: 'Was this job awarded yet?',
      name: 'wasThisJobAwarded',
      isHidden: localBiddersData.length === 0,
      render({ label, setValue, watch }) {
        const wasThisJobAwarded = watch('wasThisJobAwarded', false)
        return (
          <ConcordFormCheckbox
            label={label}
            onChange={(event: any, checked: boolean) => {
              setValue('wasThisJobAwarded', checked)
              if (!checked) {
                setValue('buyerId', null)
                setValue('buyerTerminalId', null)
              } else if (checked && localBiddersData.length === 1) {
                setValue('buyerId', localBiddersData[0].buyerId)
              }
            }}
            value={wasThisJobAwarded}
          />
        )
      },
    },
    {
      label: 'Bid Winner',
      name: 'buyerId',
      options() {
        const bidderIds = localBiddersData.map(({ buyerId }) => buyerId)
        const bidderOptions = buyerCompanyOptions.filter(({ value }) =>
          bidderIds.includes(value),
        )
        const otherBuyerOptions = buyerCompanyOptions.filter(
          ({ value }) => !bidderIds.includes(value),
        )
        return [
          {
            label: 'Bidders',
            options: bidderOptions,
          },
          {
            label: 'Others',
            options: otherBuyerOptions,
          },
        ] as any
      },
      type: EFieldType.singleSelect,
      size: 6,
      isLoading: isLoadingCompaniesData,
      onChange({ value }) {
        const bidderIds = localBiddersData.map(({ buyerId }) => buyerId)
        const isInBidder = bidderIds.includes(value)
        if (!isInBidder) {
          setLocalBiddersData(prev =>
            produce(prev, draft => {
              draft.push({
                buyerId: value,
                sellerToBuyerTerminalId: undefined,
              } as any)
            }),
          )
        }
        formRef.current?.setValue('buyerTerminalId', null)
        formRef.current?.setFocus('buyerTerminalId')
        formRef.current?.setValue('wasThisJobAwarded', true)
      },
      extraIcons: [
        {
          Icon: showCompanyForm ? (
            <CloseIcon color='var(--ion-color-danger)' />
          ) : (
            <PlusIcon color='var(--ion-color-concord)' />
          ),
          onClick() {
            setShowCompanyForm(prev => !prev)
          },
        },
      ],
    },
    {
      label: 'Buyer Terminal',
      name: 'buyerTerminalId',
      options({ watch }) {
        const buyerId = watch('buyerId', null)
        if (buyerId) {
          const result = terminalOptions.filter(
            ({ companyId, terminalType }) =>
              companyId === buyerId && ['buyer', 'both'].includes(terminalType),
          )
          return result
        }
        const sellerId = watch('sellerId', null)
        const result = terminalOptions.filter(
          t => t.companyId === sellerId && t.terminalType === 'buyer',
        )
        return result
      },
      type: EFieldType.singleSelect,
      size: 6,
      isRequired: true,
      isLoading: isLoadingTerminals,
      extraIcons: [
        {
          Icon: showBuyerTerminal ? (
            <CloseIcon color='var(--bs-danger)' />
          ) : (
            <PlusIcon color='var(--bs-primary)' />
          ),
          onClick() {
            setShowBuyerTerminal(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'companyForm',
      isHidden: !showCompanyForm,
      render() {
        return (
          <div style={{ border: '1px solid var(--bs-gray-500)' }}>
            <CompanyForm
              isHiddenCancelButton={false}
              cancelText='Close'
              onCancel={onCloseCompanyForm}
              formData={
                {
                  buyer: EYesNo.Yes,
                } as any
              }
              afterCreate={data => {
                addCompany(data)
                onCloseCompanyForm()
                formRef.current?.setValue('buyerId', data.id)
              }}
            />
          </div>
        )
      },
    },
    {
      label: '',
      name: 'buyerTerminalForm',
      render({ watch }) {
        const buyerId = watch('buyerId', null)
        const sellerId = watch('sellerId', null)

        return renderTerminalForm({
          show: showBuyerTerminal,
          afterCreate(terminal) {
            formRef.current?.setValue('buyerId', terminal.companyId)
            formRef.current?.setValue('buyerTerminalId', terminal.id)
            onCloseBuyerTerminalForm()
            addTerminal(terminal)
          },
          onClose: onCloseBuyerTerminalForm,
          formData: {
            companyId: buyerId || sellerId,
            terminalType: 'buyer',
          },
        })
      },
    },
    {
      label: 'Start Date',
      name: 'startDate',
      type: EFieldType.date,
      size: 6,
      maxDate({ watch }) {
        const endDate = watch('endDate', undefined)
        if (endDate) {
          return moment(endDate).format('YYYY-MM-DD')
        }
        return undefined
      },
    },
    {
      label: 'End Date',
      name: 'endDate',
      type: EFieldType.date,
      size: 6,
      minDate({ watch }) {
        const startDate = watch('startDate', undefined)
        if (startDate) {
          return moment(startDate).format('YYYY-MM-DD')
        }
        return undefined
      },
    },
    {
      label: 'Qty',
      name: 'qty',
      type: EFieldType.number,
      size: 6,
    },
    {
      label: 'Group',
      name: 'groupId',
      type: EFieldType.singleSelect,
      size: 6,
      options: groupOptions,
      isLoading: isLoadingGroups,
      extraIcons: [
        {
          Icon: showGroupForm ? (
            <CloseIcon color='var(--bs-danger)' />
          ) : (
            <PlusIcon color='var(--bs-primary)' />
          ),
          onClick() {
            setShowGroupForm(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'groupForm',
      isHidden: !showGroupForm,
      render() {
        return (
          <div style={{ border: '1px solid var(--bs-gray-500)' }}>
            <GroupForm
              isHiddenCancelButton={false}
              cancelText='Close'
              onCancel={onCloseGroupForm}
              afterCreate={group => {
                addGroup(group)
                onCloseGroupForm()
                formRef.current?.setValue('groupId', group.id)
              }}
            />
          </div>
        )
      },
    },
    {
      label: 'Work Type',
      name: 'workTypeId',
      type: EFieldType.singleSelect,
      options: workTypeOptions,
      isLoading: isWorkTypesLoading,
      size: 6,
      extraIcons: [
        {
          Icon: isWorkTypeFormOpen ? (
            <CloseIcon color='var(--ion-color-danger)' />
          ) : (
            <PlusIcon color='var(--ion-color-concord)' />
          ),
          onClick() {
            setIsWorkTypeFormOpen(prev => !prev)
          },
        },
      ],
    },
    {
      label: 'Work Force',
      name: 'workForceId',
      type: EFieldType.singleSelect,
      options: workForceOptions,
      isLoading: isWorkForcesLoading,
      size: 6,
      extraIcons: [
        {
          Icon: isWorkForceFormOpen ? (
            <CloseIcon color='var(--ion-color-danger)' />
          ) : (
            <PlusIcon color='var(--ion-color-concord)' />
          ),
          onClick() {
            setIsWorkForceFormOpen(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'workTypeForm',
      isHidden: !isWorkTypeFormOpen,
      render({ setValue }) {
        return (
          <WorkTypeForm
            style={{ border: '1px solid #e5e5e5' }}
            isHiddenCancelButton={false}
            onCancel={() => {
              setIsWorkTypeFormOpen(false)
            }}
            afterCreate={newData => {
              addWorkType(newData)
              setIsWorkTypeFormOpen(false)
              setValue('workTypeId', newData.id)
            }}
          />
        )
      },
    },
    {
      label: '',
      name: 'workForceForm',
      isHidden: !isWorkForceFormOpen,
      render({ setValue }) {
        return (
          <WorkForceForm
            style={{ border: '1px solid #e5e5e5' }}
            isHiddenCancelButton={false}
            onCancel={() => {
              setIsWorkForceFormOpen(false)
            }}
            afterCreate={newData => {
              addWorkForce(newData)
              setIsWorkForceFormOpen(false)
              setValue('workForceId', newData.id)
            }}
          />
        )
      },
    },
    {
      label: '',
      name: 'salesProposals',
      isHidden: !isUpdating,
      render() {
        return (
          <ConcordFormLayout
            label='Sales Proposals'
            extraIcons={[
              {
                Icon: isSalesProposalCreating ? (
                  <CloseIcon color='var(--ion-color-danger)' />
                ) : (
                  <PlusIcon color='var(--ion-color-concord)' />
                ),
                onClick() {
                  setIsSalesProposalCreating(prev => !prev)
                },
              },
            ]}
          >
            <div>
              {isSalesProposalCreating && (
                <div>
                  <SalesProposalCard
                    salesBacklog={formData as ISellerToBuyerTerminal}
                    afterCreate={data => {
                      addSalesProposal(data)
                      setIsSalesProposalCreating(false)
                    }}
                  />
                </div>
              )}

              {salesProposalsData.map(item => (
                <SalesProposalCard
                  key={item.id}
                  salesProposal={item}
                  afterUpdate={data => {
                    updateSalesProposal(data.id, data)
                  }}
                />
              ))}
            </div>
          </ConcordFormLayout>
        )
      },
    },
    {
      label: 'Status',
      name: 'status',
      type: EFieldType.singleSelect,
      size: 6,
      options: Object.keys(ESellerToBuyerTerminalStatus)
        .filter(field => isNaN(+field))
        .map(field => ({
          label: _.startCase(field),
          value: (ESellerToBuyerTerminalStatus as any)[field],
        })),
      isLoading: isLoadingGroups,
    },
    {
      label: 'User',
      name: 'userAccessId',
      type: EFieldType.singleSelect,
      isLoading: isLoadingUsersData,
      options: userAccessOptions,
      size: 6,
    },
    {
      label: 'Attention',
      name: 'attention',
    },
  ]

  const defaultValues = useMemo<ISellerToBuyerTerminalFormValues>(
    () => ({
      buyerTerminalId: null,
      qty: null,
      sellerId: null,
      sellerTerminalId: null,
      startDate: null,
      endDate: null,
      status: ESellerToBuyerTerminalStatus.active,
      groupId: null,
      workForceId: null,
      workTypeId: null,
      attention: '',
      userAccessId: currentUser.userAccess?.id,
    }),
    [currentUser.userAccess?.id],
  )

  const onCreate = useCallback(
    async (formValues: ISellerToBuyerTerminalFormValues) => {
      const payload = _.pick(formValues, [
        'buyerTerminalId',
        'qty',
        'sellerId',
        'sellerTerminalId',
        'startDate',
        'endDate',
        'status',
        'groupId',
        'workForceId',
        'workTypeId',
        'attention',
        'userAccessId',
        'buyerId',
      ])
      const { errors, ...response } =
        await apiClient.sellerToBuyerTerminals.create({
          sellerToBuyerTerminal: payload,
        })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        if (createdBidderBuyerIds.length > 0) {
          const apiCalls = createdBidderBuyerIds.map(buyerId =>
            apiClient.bidders.create({
              bidder: {
                buyerId,
                sellerToBuyerTerminalId: response.id,
              },
            }),
          )
          const responses = await Promise.all(apiCalls)
          responses.forEach(bidder => {
            if (bidder.id) {
              addBidder(bidder)
            }
          })
        }
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [addBidder, afterCreate, createdBidderBuyerIds],
  )

  const onUpdate = useCallback(
    async (formValues: ISellerToBuyerTerminalFormValues) => {
      if (formData?.id) {
        const payload = _.pick(formValues, [
          'buyerTerminalId',
          'qty',
          'sellerId',
          'sellerTerminalId',
          'startDate',
          'endDate',
          'status',
          'groupId',
          'workForceId',
          'workTypeId',
          'attention',
          'userAccessId',
          'buyerId',
        ])
        const { errors, ...response } =
          await apiClient.sellerToBuyerTerminals.update(formData.id, {
            sellerToBuyerTerminal: payload,
          })
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          if (createdBidderBuyerIds.length > 0) {
            const apiCalls = createdBidderBuyerIds.map(buyerId =>
              apiClient.bidders.create({
                bidder: {
                  buyerId,
                  sellerToBuyerTerminalId: response.id,
                },
              }),
            )
            const responses = await Promise.all(apiCalls)
            responses.forEach(bidder => {
              if (bidder.id) {
                addBidder(bidder)
              }
            })
          }
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
        if (formValues.wasThisJobAwarded && formValues.buyerId) {
          const response2 = await apiClient.terminals.update(
            payload.buyerTerminalId as number,
            {
              terminal: {
                companyId: formValues.buyerId,
              },
            },
          )
          if (response2.id) {
            updateTerminal(response2.id, response2)
            toast.success(toastMessages.updateSuccess)
          }
        }
      } else {
        setError('Id is not found')
      }
    },
    [
      addBidder,
      afterUpdate,
      createdBidderBuyerIds,
      formData?.id,
      updateTerminal,
    ],
  )

  const handleSubmit = useCallback(
    async (formValues: ISellerToBuyerTerminalFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isUpdating && formData) {
      if (formData.status) {
        formRef.current?.setValue(
          'status',
          ESellerToBuyerTerminalStatus[formData.status],
        )
      }
      if (formData?.buyerId) {
        formRef.current?.setValue('wasThisJobAwarded', true)
      }
    } else {
      if (currentScope === EScope.seller) {
        formRef.current?.setValue('sellerId', currentCompany.id)
      } else {
        formRef.current?.setValue('buyerId', currentCompany.id)
      }
    }
  }, [
    findCompanyById,
    currentCompany.id,
    currentScope,
    formData,
    isUpdating,
    terminalOptions,
  ])

  useDeepCompareEffect(() => {
    if (isUpdating && isBiddersDataFetched) {
      setLocalBiddersData(biddersData)
    }
  }, [biddersData, isBiddersDataFetched])

  return (
    <div>
      <ConcordFormStructure
        {...formProps}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={sellerToBuyerTerminalSchema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default SellerToBuyerTerminalForm
