import { DialogAutoChargesForm } from '~/components/shared/ConcordForm/FormData/AutoChargesForm'
import { useAutoChargesTable } from './useAutoChargesTable'
import {
  BranchCreateIcon,
  DeleteIcon,
  EditIcon,
  ReusableTable,
} from '~/components/shared'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

import { IAutoChargesTableProps } from './type'
import DialogHierarchiesForm from '~/containers/invoices/AutomaticChargesDialog/DialogHierarchiesForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'

const AutoChargesTable = (props: IAutoChargesTableProps) => {
  const {
    columns,
    formModalState,
    isLoading,
    onOpenCreateFormModal,
    onCloseFormModal,
    columnFilters,
    filterOptions,
    setColumnFilters,
    onCellEditEnd,
    windowSize,
    autoCharges,
    onOpenEditDialogForm,
    onRemove,
    afterCreate,
    afterUpdate,
    globalFilter,
    formProps,
    toolbarProps,
    setGlobalFilter,
    style,
    hierarchiesForm,
    onOpenHierarchiesForm,
    onCloseHierarchiesForm,
    afterCreateHierarchies,
  } = useAutoChargesTable(props)

  return (
    <>
      <div style={{ margin: 12, ...style }}>
        <ReusableTable
          tableHeight={windowSize.height - 165}
          columns={columns}
          data={autoCharges}
          enableCompanyView
          enableColumnPinning
          enableRowActions
          toolbarProps={toolbarProps}
          state={{
            isLoading,
            columnFilters,
            globalFilter,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 120,
              maxSize: 120,
            },
          }}
          companyViewProps={{
            name: 'auto_charges',
            description: 'Manage your Auto charges.',
            onClickTopButton: onOpenCreateFormModal,
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: onOpenEditDialogForm(row.original),
            },
            {
              icon: <BranchCreateIcon color='white' />,
              tooltipProps: {
                content: 'Create Hierarchy Row',
                placement: 'top',
              },
              color: 'purple',
              onClick: onOpenHierarchiesForm(row.original),
            },
            {
              icon: <DeleteIcon color='white' />,
              color: 'danger',
              onClick: onRemove(row.original),
            },
          ]}
          filterOptions={filterOptions}
          onCellEditEnd={onCellEditEnd}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>
      <DialogAutoChargesForm
        {...(formProps as any)}
        onClose={onCloseFormModal}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        formData={{
          ...formModalState.formData,
          ...formProps?.formData,
        }}
        isOpen={formModalState.isOpen}
      />
      <DialogHierarchiesForm
        hierarchyItem={formProps?.hierarchyItem}
        isOpen={hierarchiesForm.isOpen}
        onClose={onCloseHierarchiesForm}
        hierarchableType={EHierarchableType.AutoCharge}
        hierarchableId={hierarchiesForm.formData?.id}
        afterCreate={afterCreateHierarchies}
      />
    </>
  )
}

export default AutoChargesTable
