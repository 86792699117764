import React, { useMemo, useState } from 'react'
import {
  CommonDialogV2,
  ICommonDialogProps,
} from '~/components/shared/CommonDialogV2'
import { ConcordFormMultipleDropdownSelector } from '../../Dropdown'
import { useQueryCompanies } from '~/hooks/useQueryData'
import { IBidder } from '~/types/models/IBidder'
import { ReusableButton } from '~/components/shared/ReusableButton'
import { INullOrUndefined } from '~/types/models/ICommonModel'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

export interface IDialogBiddersFormProps extends ICommonDialogProps {
  opportunityId?: number | INullOrUndefined
  existingBidders?: IBidder[]
  afterCreate?: (response: IBidder[]) => void
}

function DialogBiddersForm(props: IDialogBiddersFormProps) {
  const { opportunityId, existingBidders, afterCreate, ...dialogProps } = props

  const { buyerCompanyOptions } = useQueryCompanies({})

  const [buyerIds, setBuyerIds] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const buyerOptions = useMemo(() => {
    if (existingBidders) {
      const ids = existingBidders.map(({ buyerId }) => buyerId)
      return buyerCompanyOptions.filter(({ value }) => !ids.includes(value))
    }
    return buyerCompanyOptions
  }, [buyerCompanyOptions, existingBidders])

  const onCreateBidders = async () => {
    setIsLoading(true)
    try {
      const apiCalls = buyerIds.map(id =>
        apiClient.bidders.create({
          bidder: {
            buyerId: id,
            sellerToBuyerTerminalId: opportunityId as number,
          },
        }),
      )
      const response = await Promise.all(apiCalls)
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CommonDialogV2 isHiddenOkButton title='Create Bidders' {...dialogProps}>
      <ConcordFormMultipleDropdownSelector
        label='Buyers'
        isMulti
        options={buyerOptions}
        onChange={(event: any, { value }: any) => {
          setBuyerIds(value)
        }}
        value={buyerIds}
      />
      <div style={{ marginTop: 8 }}>
        <ReusableButton
          style={{ fontSize: 13, width: '100%' }}
          isDisabled={!opportunityId || buyerIds.length === 0}
          onClick={onCreateBidders}
          isLoading={isLoading}
        >
          Create
        </ReusableButton>
      </div>
    </CommonDialogV2>
  )
}

export default DialogBiddersForm
