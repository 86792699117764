import useNoteButton from './useNoteButton'

import Tooltip from 'rc-tooltip'

import type { INoteButtonProps } from './type'

import { Badge, Button } from 'react-bootstrap'
import { NoteIcon } from '~/components/shared'

import './styles.scss'

function RTActionNoteButton(props: INoteButtonProps) {
  const { Overlay, tooltipProps, notesData } = useNoteButton(props)

  const getContainer = (triggerNode: HTMLElement) => {
    const modal = document.querySelector('.modal') // Get modal element
    return modal && modal.contains(triggerNode) ? modal : document.body
  }

  return (
    <Tooltip
      placement='top'
      overlay={Overlay}
      overlayClassName='RTActionNoteButton__tooltip'
      destroyTooltipOnHide
      trigger={['click']}
      getTooltipContainer={getContainer as any}
      {...tooltipProps}
    >
      <Button
        className='RTActionNoteButton__button'
        variant='secondary'
        style={{
          fontSize: 13,
          background: 'var(--bs-gray-500)',
          borderColor: 'var(--bs-gray-500)',
        }}
      >
        <NoteIcon color='white' />
        {notesData?.length ? (
          <Badge bg='danger' className='badgeButton'>
            {notesData.length}
          </Badge>
        ) : null}
      </Button>
    </Tooltip>
  )
}
export default RTActionNoteButton
