import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useConfirmationProvider } from '~/contexts'

import {
  IonIcon,
  IonButtons,
  IonButton,
  IonText,
  IonCheckbox,
} from '@ionic/react'
import {
  Loading,
  CompanyInfo,
  useModalDownloadProgress,
  ToolbarSelectedRows,
} from '~/components/shared'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons'
import {
  selectCompanyTimeZone,
  selectCommonTagsOptions,
} from '~/redux/selectors'
import {
  formatDateFromISO,
  DATE_TIME_FORMAT,
  DATE_FORMAT_TOOLTIP,
} from '~/utils/convertDate'
import clsx from 'clsx'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import loadsSlice from '~/redux/reducers/data/loadsSlice'
import getFileNameFromAWSLink from '~/utils/getFileNameFromAWSLink'
import getClassNameByLoadStatus from '~/utils/getClassNameByLoadStatus'

import './LoadGridCard.scss'
import { apiClient } from '~/api/ApiClient'
import { EScope, EYesNo } from '~/types/enums/ECommonEnum'
import {
  useQueryBuyerSellers,
  useQuerySellerProducts,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import buildFullName from '~/utils/buildFullName'

const LoadGridCard = props => {
  const {
    load,
    expandGrid,
    style,
    className,
    isSelecting,
    isSelected,
    onSelect,
  } = props

  const [loadDetails, setLoadDetails] = useState({})
  const [shouldExpandGrid, setShouldExpandGrid] = useState(false)
  const [isLoading, setIsLoading] = useState()
  const filters = useSelector(state => state.load.get('filters'))
  const [loadingButtons, setLoadingButtons] = useState([])

  const { findTerminalById } = useQueryTerminals()
  const { findCompanyById } = useQueryBuyerSellers()
  const { findUserByDriverFleetId } = useQueryUsers()
  const { findTruckFleetById } = useQueryTruckFleets()

  const { findSellerProductById } = useQuerySellerProducts()

  const seller = findCompanyById(load.sellerId)
  const buyer = findCompanyById(load.buyerId)
  const fleet = findCompanyById(load.fleetId)
  const user = findUserByDriverFleetId(load.driverFleetId)
  const truckFleet = findTruckFleetById(load.truckFleetId)
  const sellerProduct = findSellerProductById(load.sellerProductId)
  const sellerTerminal = findTerminalById(load.sellerTerminalId)
  const buyerTerminal = findTerminalById(load.buyerTerminalId)

  const history = useHistory()
  const { confirmation } = useConfirmationProvider()
  const { downloadFile } = useModalDownloadProgress()

  const dispatch = useDispatch()
  const companyTimeZone = useSelector(selectCompanyTimeZone)
  const commonTagsOptions = useSelector(selectCommonTagsOptions)

  const initialLoad = useRef(true)

  useEffect(() => {
    if ((shouldExpandGrid || expandGrid) && initialLoad.current) {
      setIsLoading(true)
      apiClient.loads.getById(load.id).then(response => {
        setLoadDetails(response)
        setIsLoading(false)
        initialLoad.current = false

        return
      })
    }
  }, [shouldExpandGrid, load, setLoadDetails, expandGrid])

  // Resets a card's details + UI on filter change
  useEffect(() => {
    setLoadDetails({})
    setShouldExpandGrid(false)
    initialLoad.current = true
  }, [filters])

  const viewLoad = useCallback(() => {
    history.push(`/loads/${load.id}`)
  }, [history, load.id])

  const expandGridCard = () => {
    setShouldExpandGrid(prev => !prev)
  }

  const renderTooltip = props => {
    return (
      <Tooltip {...props}>
        {formatDateFromISO(load.date, DATE_FORMAT_TOOLTIP, companyTimeZone)}
      </Tooltip>
    )
  }

  const handleClickCheckbox = useCallback(() => {
    onSelect && onSelect(load.id)
  }, [load.id, onSelect])

  const toggleLoadingButtons = useCallback(buttonName => {
    setLoadingButtons(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(buttonName)
        if (index === -1) {
          draft.push(buttonName)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const downloadPdf = useCallback(() => {
    const filename = getFileNameFromAWSLink(
      load.ticketPdf || load.ticketImage,
      `Load__${load.id}`,
    )
    downloadFile(load.ticketPdf || load.ticketImage, filename)
  }, [downloadFile, load.id, load.ticketImage, load.ticketPdf])

  const deleteLoad = useCallback(async () => {
    const result = await confirmation({
      message: `Are you sure you want to delete load #${load.id}`,
    })

    if (result === EYesNo.Yes) {
      toggleLoadingButtons('delete')
      try {
        await apiClient.loads.delete(load.id)
        dispatch(loadsSlice.actions.deleteLoad(load.id))
        toast.success('Delete loads successfully')
      } catch (error) {
        console.log('error.message', error.message)
        toast.error(toastMessages.serverError)
      } finally {
        toggleLoadingButtons('delete')
      }
    }
  }, [confirmation, dispatch, load.id, toggleLoadingButtons])

  const sendLoadsToHuman = useCallback(async () => {
    toggleLoadingButtons('sendToHuman')
    try {
      await apiClient.load.submitHitl({
        load: [load.id],
      })
      toast.success('Request initiated to HITL')
    } catch (error) {
      console.log('error.message', error.message)
      toast.error(toastMessages.serverError)
    } finally {
      toggleLoadingButtons('sendToHuman')
    }
  }, [load.id, toggleLoadingButtons])

  const refetchLoad = useCallback(async () => {
    toggleLoadingButtons('refetchLoad')
    try {
      await apiClient.loads.bulkRerunOcrJson({
        ids: [load.id],
      })
    } catch (error) {
      console.log('error.message', error.message)
      toast.error(toastMessages.serverError)
    } finally {
      toggleLoadingButtons('refetchLoad')
    }
  }, [toggleLoadingButtons, load.id])

  const changeLoadTag = useCallback(
    async selectedTag => {
      toggleLoadingButtons('addTag')
      try {
        const tagId = selectedTag.value
        if (load.tagIds.includes(tagId)) {
          const [tag] = await apiClient.tagLinks.get({
            filters: {
              tagId,
              taggableId: load.id,
            },
          })
          const response = await apiClient.tagLinks.delete(tag.id)
          dispatch(
            loadsSlice.actions.updateLoad({
              ...load,
              tagIds: load.tagIds.filter(i => i !== response.tagId),
            }),
          )
        } else {
          const response = await apiClient.tagLinks.create({
            tagId,
            taggableType: 'Load',
            taggableId: load.id,
          })
          dispatch(
            loadsSlice.actions.updateLoad({
              ...load,
              tagIds: [...load.tagIds, response.tagId],
            }),
          )
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        toggleLoadingButtons('addTag')
      }
    },
    [dispatch, load, toggleLoadingButtons],
  )

  const rerunOcrJson = useCallback(async () => {
    toggleLoadingButtons('rerunOcrJson')
    try {
      await apiClient.load.rerunOcrJson(load.id)
      toast.success('Rerun successfully')
    } catch (error) {
      console.log('error.message', error.message)
      toast.error(toastMessages.serverError)
    } finally {
      toggleLoadingButtons('rerunOcrJson')
    }
  }, [load.id, toggleLoadingButtons])

  const actionButtons = useMemo(
    () => [
      {
        label: 'View Load Detail',
        color: 'concord',
        onClick: viewLoad,
      },
      {
        label: 'Send to HITL',
        onClick: sendLoadsToHuman,
        color: 'success',
        loading: loadingButtons.includes('sendToHuman'),
      },
      {
        label: 'Refetch Loads',
        onClick: refetchLoad,
        loading: loadingButtons.includes('refetchLoad'),
        color: 'warning',
        hide: !load.uid,
      },
      {
        label: 'Add Tag',
        type: 'dropdown',
        options: commonTagsOptions,
        onChange: changeLoadTag,
        loading: loadingButtons.includes('addTag'),
      },
      {
        label: 'Download',
        onClick: downloadPdf,
        color: 'export',
      },
      {
        label: 'Rerun OCR Json',
        onClick: rerunOcrJson,
        loading: loadingButtons.includes('rerunOcrJson'),
      },
      {
        label: 'Delete',
        color: 'crimson',
        onClick: deleteLoad,
        loading: loadingButtons.includes('delete'),
      },
    ],
    [
      changeLoadTag,
      commonTagsOptions,
      deleteLoad,
      downloadPdf,
      load.uid,
      loadingButtons,
      refetchLoad,
      rerunOcrJson,
      sendLoadsToHuman,
      viewLoad,
    ],
  )

  return (
    <div
      className={clsx(
        {
          'load-details-card': expandGrid,
        },
        className,
      )}
      style={style}
    >
      <div
        className={
          shouldExpandGrid ? 'load-grid-card-expanded' : 'load-grid-card'
        }
      >
        <div className='LoadGridCard__header'>
          {isSelecting && (
            <div className='LoadGridCard__checkbox'>
              <IonCheckbox
                mode='md'
                onClick={handleClickCheckbox}
                checked={isSelected}
              />
            </div>
          )}
          <ToolbarSelectedRows
            buttons={actionButtons}
            // style={{ maxWidth: width }}
            className='LoadGridCard__buttonActions'
          />
        </div>
        <div className='LoadGridCard__companyTopSection'>
          <CompanyInfo
            company={{
              value: seller?.id,
              name: seller?.name,
              image: seller?.logo,
            }}
            defaultCompanyCode={
              findTerminalById(load.sellerTerminalId)?.name ||
              'Seller terminal missing'
            }
            companyType={EScope.seller}
            searchableGoogle={false}
          />
          <CompanyInfo
            company={{
              value: buyer?.id,
              name: buyer?.name,
              image: buyer?.logo,
            }}
            defaultCompanyCode={
              findTerminalById(load.buyerTerminalId)?.name ||
              'Buyer terminal missing'
            }
            companyType={EScope.buyer}
            searchableGoogle={false}
          />
          {/* {!expandGrid && (
            <div className='loadCard-viewButton-container' onClick={viewLoad}>
              <IonIcon
                style={{
                  display: 'inline-block',
                  height: '90%',
                  minWidth: '90%',
                }}
                color='primary'
                icon={openOutline}
              />
            </div>
          )} */}
        </div>
        <div className='loadGrid-bottom'>
          <div className='loadCard-bottomLeft'>
            <div className='loadCard-bottom-row'>
              <p className={load.num ? 'row-text' : 'row-text missing'}>
                <strong>Ticket #: </strong>
                {load.num}
              </p>
            </div>
            <div className='loadCard-bottom-row'>
              <p className={sellerProduct ? 'row-text' : 'row-text missing'}>
                <strong>Product: </strong>
                {sellerProduct ? sellerProduct.name : ''}
              </p>
            </div>
            <div className='loadCard-bottom-row'>
              <p className={sellerProduct ? 'row-text' : 'row-text missing'}>
                <strong>Product Code: </strong>
                {sellerProduct ? sellerProduct.name : ''}
              </p>
            </div>
          </div>
          <div className='loadCard-bottomRight'>
            <div className='loadCard-bottom-row'>
              <OverlayTrigger
                style={{ backgroundColor: 'white' }}
                placement='right'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <p className={load.date ? 'row-text' : 'row-text missing'}>
                  <strong>Load date: </strong>
                  {load.date}
                </p>
              </OverlayTrigger>
            </div>
            <div className='loadCard-bottom-row'>
              <p className={load.qty ? 'row-text' : 'row-text missing'}>
                <strong>Quantity: </strong>
                {load.qty ? load.qty : ''}
              </p>
            </div>
            <div
              className='loadCard-bottom-row'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <p
                className={load.qty ? 'row-text' : 'row-text missing'}
                style={{ marginBottom: 0 }}
              >
                <strong>Status: </strong>
              </p>
              <div style={{ marginLeft: 5 }}>
                <IonText style={{ whiteSpace: null, fontSize: 12 }}>
                  <span
                    className={`table-pill ${getClassNameByLoadStatus(
                      load.primaryParserStatus,
                    )}`}
                    style={{ maxWidth: '15rem', height: 28, opacity: 1 }}
                  >
                    <span>&bull;</span>
                    {load.primaryParserStatus ? load.primaryParserStatus : ''}
                  </span>
                </IonText>
              </div>
            </div>
          </div>
        </div>
        {(shouldExpandGrid || expandGrid) && (
          <div className='loadCard-expanded-body' style={{ marginTop: 15 }}>
            <div className='loadCard-expanded-left'>
              <div className='loadCard-expanded-leftColumn'>
                <div className='loadCard-expanded-leftRow'>
                  <p
                    className={
                      fleet?.name
                        ? 'expanded-row-text'
                        : 'expanded-row-text missing'
                    }
                  >
                    <strong>Fleet: </strong>
                    {fleet?.name ? fleet?.name : ''}
                  </p>
                </div>
                <div className='loadCard-expanded-leftRow'>
                  <p
                    className={
                      user ? 'expanded-row-text' : 'expanded-row-text missing'
                    }
                  >
                    <strong>Driver name: </strong>
                    {user ? buildFullName(user.person) : ''}
                  </p>
                </div>
                <div className='loadCard-expanded-leftRow'>
                  <p
                    className={
                      truckFleet
                        ? 'expanded-row-text'
                        : 'expanded-row-text missing'
                    }
                  >
                    <strong>Truck #: </strong>
                    {truckFleet ? (
                      <Badge style={{ fontSize: 13 }}>
                        {truckFleet.truck?.name}
                      </Badge>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
                <div className='loadCard-expanded-leftRow'>
                  <p
                    className={
                      loadDetails.checkinTime
                        ? 'expanded-row-text'
                        : 'expanded-row-text missing'
                    }
                  >
                    <strong>Check-in: </strong>
                    {formatDateFromISO(
                      loadDetails.checkinTime,
                      DATE_TIME_FORMAT,
                      companyTimeZone,
                    )}
                  </p>
                </div>
                <div className='loadCard-expanded-leftRow'>
                  <p
                    className={
                      loadDetails.checkoutTime
                        ? 'expanded-row-text'
                        : 'expanded-row-text missing'
                    }
                  >
                    <strong>Check-out: </strong>
                    {formatDateFromISO(
                      loadDetails.checkoutTime,
                      DATE_TIME_FORMAT,
                      companyTimeZone,
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='loadCard-expanded-right'>
              <div className='loadCard-table'>
                <div className='loadCard-table-label-column'>
                  <div className='loadCard-table-blank-corner'></div>
                  <div className='loadCard-table-label-cell'>
                    <p className='loadCard-table-cell-text'>
                      <strong>Gross:</strong>
                    </p>
                  </div>
                  <div className='loadCard-table-label-cell'>
                    <p className='loadCard-table-cell-text'>
                      <strong>Tare:</strong>
                    </p>
                  </div>
                  <div className='loadCard-table-label-cell'>
                    <p className='loadCard-table-cell-text'>
                      <strong>Net:</strong>
                    </p>
                  </div>
                </div>
                <div className='loadCard-table-amount-column'>
                  <div className='loadCard-table-amount-cell'>
                    <p
                      className={
                        sellerTerminal
                          ? 'table-header-text'
                          : 'table-header-text missing'
                      }
                    >
                      <strong>
                        {sellerTerminal
                          ? sellerTerminal.name
                          : 'Seller terminal'}
                      </strong>
                    </p>
                  </div>
                  <div className='loadCard-table-amount-cell'>
                    <p className='loadCard-table-cell-text'>
                      {loadDetails.postLoadQty ? loadDetails.postLoadQty : ''}
                    </p>
                  </div>
                  <div className='loadCard-table-amount-cell'>
                    <p className='loadCard-table-cell-text'>
                      {loadDetails.preLoadQty ? loadDetails.preLoadQty : ''}
                    </p>
                  </div>
                  <div className='loadCard-table-amount-cell'>
                    <p className='loadCard-table-cell-text'>
                      {loadDetails.shipQty ? loadDetails.qty : ''}
                    </p>
                  </div>
                </div>
                <div className='loadCard-table-amount-column'>
                  <div className='loadCard-table-amount-cell'>
                    <p
                      className={
                        buyerTerminal
                          ? 'table-header-text'
                          : 'table-header-text missing'
                      }
                    >
                      <strong>
                        {buyerTerminal ? buyerTerminal.name : 'Buyer terminal'}
                      </strong>
                    </p>
                  </div>
                  <div className='loadCard-table-amount-cell'>
                    <p className='loadCard-table-cell-text'>
                      {loadDetails.postLoadQty ? loadDetails.dumpQty : ''}
                    </p>
                  </div>
                  <div className='loadCard-table-amount-cell'>
                    <p className='loadCard-table-cell-text'>
                      {loadDetails.preLoadQty ? loadDetails.postDumpQty : ''}
                    </p>
                  </div>
                  <div className='loadCard-table-amount-cell'>
                    <p className='loadCard-table-cell-text'>
                      {loadDetails.shipQty ? loadDetails.preDumpQty : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!expandGrid && (
          <div className='loadCard-footer' style={{ marginTop: 15 }}>
            <div className='loadCard-footer-buffer'></div>
            <IonButtons className='loadCard-button-container'>
              <IonButton
                className={
                  shouldExpandGrid ? 'expand-button open' : 'expand-button'
                }
                onClick={expandGridCard}
              >
                <IonIcon
                  className='buttonIcon'
                  icon={
                    !shouldExpandGrid ? addCircleOutline : removeCircleOutline
                  }
                />
                {!shouldExpandGrid ? 'More details' : 'Fewer details'}
              </IonButton>
            </IonButtons>
          </div>
        )}
        {isLoading && (
          <div>
            <Loading />
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadGridCard
