import { useCallback, useMemo } from 'react'
import useQuerySellerProducts from '../useQuerySellerProducts/useQuerySellerProducts'
import useQuerySellerProductsNew from './useQuerySellerProductsNew'
import {
  INewSellerProductParams,
  INewSellerProductResponse,
  ISellerProduct,
} from '~/types/models/ISellerProduct'
import { ICommonGroupOption } from '~/types/models/ICommonModel'
import _ from 'lodash'
import { UseQueryOptions } from 'react-query'

export const useSellerProductsHierarchyOptions = (
  params: INewSellerProductParams = {},
  options?: Partial<
    UseQueryOptions<INewSellerProductResponse> & { additionalKeys?: string[] }
  >,
) => {
  const {
    sellerProducts: sellerProductsNew,
    updateSellerProduct: updateNewEndpointSellerProduct,
    // findSellerProductById: findNewEndpointSellerProductById,
    isLoadingSellerProducts: isLoadingSellerProductsNew,
    deleteSellerProduct: deleteNewEndpointSellerProduct,
    refetchSellerProductsData,
  } = useQuerySellerProductsNew(params, options)

  const {
    sellerProducts: allowToAllTerminalSellerProducts,
    updateSellerProduct: updateAllTerminalSellerProduct,
    findSellerProductById: findAllTerminalSellerProductById,
    addSellerProduct,
    deleteSellerProduct: deleteAllTerminalSellerProduct,
    isLoadingSellerProducts: isLoadingSellerProducts,
    isSellerProductsFetched,
    sellerProductQueryKey,
  } = useQuerySellerProducts({
    filters: { allowToAllTerminals: true },
  })

  const mergedData = useMemo(() => {
    return {
      seller: sellerProductsNew?.seller || [],
      sellerTerminalToBuyerTerminal:
        sellerProductsNew?.sellerTerminalToBuyerTerminal || [],
      sellerToBuyer: sellerProductsNew?.sellerToBuyer || [],
      sellerToBuyerTerminal: sellerProductsNew?.sellerToBuyerTerminal || [],
      allowToAllTerminals: allowToAllTerminalSellerProducts,
    }
  }, [sellerProductsNew, allowToAllTerminalSellerProducts])

  const sellerProducts = useMemo<ISellerProduct[]>(
    () => [
      ...mergedData.seller,
      ...mergedData.sellerTerminalToBuyerTerminal,
      ...mergedData.sellerToBuyer,
      ...mergedData.sellerToBuyerTerminal,
      ...mergedData.allowToAllTerminals,
    ],
    [mergedData],
  )

  // const findSellerProductsBySellerId = useCallback(
  //   (sellerId: number) =>
  //     sellerProducts.filter(item => item.sellerId === sellerId),
  //   [sellerProducts],
  // )

  const findSellerProductById = useCallback(
    (id: number | null | undefined) =>
      sellerProducts.find(item => item.id === id),
    [sellerProducts],
  )

  const sellerProductOptions = useMemo<ICommonGroupOption[]>(() => {
    const order = [
      'sellerTerminalToBuyerTerminal',
      'sellerToBuyerTerminal',
      'sellerToBuyer',
      'seller',
      'allowToAllTerminals',
    ]

    return order
      .map(key => {
        const sps = mergedData[key as keyof typeof mergedData] || []
        const options = sps.map(item => {
          const { id, name, code } = item
          const label = `${code} - ${name}`
          return {
            value: id,
            label,
          }
        }) as { label: string; value: any }[]
        return {
          label: _.startCase(key),
          options,
        }
      })
      .filter(group => group.options.length > 0) as ICommonGroupOption[]
  }, [mergedData])

  const isLoading = useMemo(
    () => isLoadingSellerProductsNew || isLoadingSellerProducts,
    [isLoadingSellerProductsNew, isLoadingSellerProducts],
  )

  const updateSellerProduct = useCallback(
    (id: number, sp: Partial<ISellerProduct>) => {
      if (findAllTerminalSellerProductById(id)) {
        updateAllTerminalSellerProduct(id, sp)
      } else {
        updateNewEndpointSellerProduct(id, sp)
      }
    },
    [
      updateAllTerminalSellerProduct,
      updateNewEndpointSellerProduct,
      findAllTerminalSellerProductById,
    ],
  )

  const deleteSellerProduct = useCallback(
    (id: number) => {
      if (findAllTerminalSellerProductById(id)) {
        deleteAllTerminalSellerProduct(id)
      } else {
        deleteNewEndpointSellerProduct(id)
      }
    },
    [
      deleteAllTerminalSellerProduct,
      deleteNewEndpointSellerProduct,
      findAllTerminalSellerProductById,
    ],
  )

  const addAllTerminalsSellerProduct = useCallback(
    (sp: ISellerProduct) => {
      if (sp.allowToAllTerminals) {
        addSellerProduct(sp)
      }
    },
    [addSellerProduct],
  )

  return {
    sellerProducts,
    sellerProductOptions,
    isSellerProductsFetched,
    // findSellerProductsBySellerId,
    findSellerProductById,
    isLoading,
    sellerProductQueryKey,
    updateSellerProduct,
    deleteSellerProduct,
    addAllTerminalsSellerProduct,
    refetch: refetchSellerProductsData,
  }
}
