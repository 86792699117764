import { useRef } from 'react'
import { Button } from 'react-bootstrap'
import { VerticalThreedotsIcon } from '~/components/shared'
import { ISvgIconProps } from '~/components/shared/SvgIcons/type'
import {
  ControlledMenu,
  MenuItem,
  useClick,
  useMenuState,
} from '@szhsin/react-menu'

export interface IBillLinesActionsButtonProps {
  items: {
    label: string
    icon?: (props: ISvgIconProps) => JSX.Element
    onClick: () => void
    isHidden?: boolean
    color?: string // hex color
  }[]
}

function BillLinesActionsButton(props: IBillLinesActionsButtonProps) {
  const { items } = props

  const ref = useRef(null)
  const [menuState, toggleMenu] = useMenuState({ transition: true })
  const anchorProps = useClick(menuState.state, toggleMenu)

  return (
    <>
      <Button variant='secondary' ref={ref} {...anchorProps}>
        <VerticalThreedotsIcon color='white' />
      </Button>

      <ControlledMenu
        {...menuState}
        anchorRef={ref}
        onClose={() => toggleMenu(false)}
      >
        {items
          .filter(({ isHidden }) => !isHidden)
          .map(({ icon: Icon, label, color, onClick }, index) => (
            <MenuItem key={index} style={{ fontSize: 14 }} onClick={onClick}>
              {Icon && (
                <div style={{ marginRight: 6, height: 23 }}>
                  <Icon color={color} />
                </div>
              )}
              <span style={{ color }}>{label}</span>
            </MenuItem>
          ))}
      </ControlledMenu>
    </>
  )
}

export default BillLinesActionsButton
