import React, { useCallback, useState } from 'react'
import useInvoicePanelControls from '~/hooks/useInvoice/useInvoicePanelControls'
import { HeaderContent } from '~/components/shared'
import { settingsOutline } from 'ionicons/icons'
import {
  IonCard,
  IonItem,
  IonLabel,
  IonToggle,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { INVOICE_PANELS } from '~/constants/invoice-type'

const InvoiceMainHeader = props => {
  const { invoice } = props

  const { currentPanels, isActualInvoice, onChangeCurrentPanels } =
    useInvoicePanelControls()

  const [toggleSettingMenu, setToggleSettingMenu] = useState(false)
  const onToggleSettingMenu = useCallback(
    () => setToggleSettingMenu(!toggleSettingMenu),
    [toggleSettingMenu],
  )

  const renderSettingMenu = useCallback(
    open => {
      return (
        open && (
          <IonCard className='right-menu-popup'>
            <IonItem>
              <IonLabel>
                {' '}
                {isActualInvoice
                  ? 'Seller Invoice'
                  : 'Projected Invoice from Loads'}{' '}
              </IonLabel>
              <IonToggle
                checked={currentPanels.includes(INVOICE_PANELS.TICKETS)}
                value={INVOICE_PANELS.TICKETS}
                onIonChange={onChangeCurrentPanels}
              />
            </IonItem>
            <IonItem>
              <IonLabel>
                {' '}
                {isActualInvoice
                  ? 'Unmatched from Loads'
                  : 'Seller Invoice'}{' '}
              </IonLabel>
              <IonToggle
                checked={currentPanels.includes(INVOICE_PANELS.INVOICE)}
                value={INVOICE_PANELS.INVOICE}
                onIonChange={onChangeCurrentPanels}
                disabled={!invoice?.compareInvoiceId}
              />
            </IonItem>
          </IonCard>
        )
      )
    },
    [
      currentPanels,
      invoice?.compareInvoiceId,
      onChangeCurrentPanels,
      isActualInvoice,
    ],
  )

  const renderHeader = useCallback(
    () => ({
      backButton: true,
      headerTitle: `Invoice - ${
        invoice?.number || invoice?.num?.presence || invoice?.id || ''
      }`,
      rightButton: (
        <IonButton
          slot='end'
          size='small'
          onClick={onToggleSettingMenu}
          title='Setting'
        >
          <IonIcon icon={settingsOutline} />
          &nbsp;
        </IonButton>
      ),
    }),
    [invoice, onToggleSettingMenu],
  )

  return (
    <>
      <HeaderContent renderContent={renderHeader} />
      {renderSettingMenu(toggleSettingMenu)}
    </>
  )
}

export default InvoiceMainHeader
