import { useState, useRef, useCallback, memo } from 'react'
import { useSelector } from 'react-redux'
import { useQueryCostCodes } from '~/hooks/useQueryData'

import { IonIcon, IonText } from '@ionic/react'
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons'
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ConcordFormDropdownV2 } from '~/components/shared'

import { moduleName } from '~/utils/constants'

import './styles.scss'

const CostCodeOverlay = props => {
  const {
    children,
    onChangeDropdown,
    buyerTerminals,
    billLine,
    sellerProducts,
    productGroupsList,
    invoice,
    canEdit = true,
  } = props

  const [show, setShow] = useState(false)
  const companyId = useSelector(state => state.session.user.company.id)

  const { costCodeOptions } = useQueryCostCodes({
    filters: {
      companyId: invoice?.buyerId,
    },
  })

  const handleOnMouseEnter = () => {
    setShow(true)
  }
  const handleOnMouseLeave = () => {
    setShow(false)
  }
  const ref = useRef(null)

  const handleChangeBuyerTerminal = useCallback(
    selectedBuyerTerminal => {
      onChangeDropdown &&
        onChangeDropdown({
          name: 'buyerTerminalId',
          selectedValue: selectedBuyerTerminal,
        })
    },
    [onChangeDropdown],
  )

  const handleChangeProduct = useCallback(
    selectedProduct => {
      onChangeDropdown &&
        onChangeDropdown({
          name: 'sellerProductId',
          selectedValue: selectedProduct,
        })
    },
    [onChangeDropdown],
  )

  const handleChangeProductGroup = useCallback(
    selectedProduct => {
      onChangeDropdown &&
        onChangeDropdown({
          name: 'productGroup',
          selectedValue: selectedProduct,
        })
    },
    [onChangeDropdown],
  )

  const popover = (
    <Popover
      className='CostCodeOverlay__rootPopover'
      //id='popover-positioned-bottom'
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Popover.Header>
        {"Cost code couldn't be automatically set"}
      </Popover.Header>
      <Popover.Body>
        <ListGroup variant='flush' style={{ fontSize: 14 }}>
          <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
            <div className='CostCodeOverlay__labelRoot'>
              <IonIcon
                icon={
                  billLine?.buyerTerminalId
                    ? checkmarkCircleOutline
                    : closeCircleOutline
                }
                color={billLine.buyerTerminalId ? 'primary' : 'danger'}
                style={{ verticalAlign: 'baseline' }}
              />
              <IonText>Buyer Terminal: </IonText>
            </div>
            <ConcordFormDropdownV2
              options={buyerTerminals}
              onChange={(event, { selectedOption }) => {
                handleChangeBuyerTerminal(selectedOption)
              }}
              value={billLine.buyerTerminalId}
              styles={{
                menu: provided => ({
                  ...provided,
                  minWidth: 200,
                }),
                control: provided => ({
                  ...provided,
                  border: 'none',
                }),
              }}
            />
          </ListGroup.Item>
          <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
            <div className='CostCodeOverlay__labelRoot'>
              <IonIcon
                icon={
                  billLine?.sellerProductId
                    ? checkmarkCircleOutline
                    : closeCircleOutline
                }
                color={billLine?.sellerProductId ? 'primary' : 'danger'}
                style={{ verticalAlign: 'baseline' }}
              />
              <IonText>Product: </IonText>
            </div>
            <ConcordFormDropdownV2
              options={sellerProducts}
              onChange={(event, { selectedOption }) => {
                handleChangeProduct(selectedOption)
              }}
              value={billLine.sellerProductId}
              styles={{
                menu: provided => ({
                  ...provided,
                  minWidth: 200,
                }),
                control: provided => ({
                  ...provided,
                  border: 'none',
                }),
              }}
            />
          </ListGroup.Item>

          {billLine.sellerProductId && (
            <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
              <div className='CostCodeOverlay__labelRoot'>
                <IonIcon
                  icon={
                    billLine?.productGroupId
                      ? checkmarkCircleOutline
                      : closeCircleOutline
                  }
                  color={billLine?.productGroupId ? 'primary' : 'danger'}
                  style={{ verticalAlign: 'baseline' }}
                />
                <IonText>Product Category: </IonText>
              </div>
              <ConcordFormDropdownV2
                options={productGroupsList}
                onChange={(event, { selectedOption }) => {
                  handleChangeProductGroup(selectedOption)
                }}
                value={billLine.productGroupId}
                styles={{
                  menu: provided => ({
                    ...provided,
                    minWidth: 200,
                  }),
                  control: provided => ({
                    ...provided,
                    border: 'none',
                  }),
                }}
              />
            </ListGroup.Item>
          )}

          <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
            <div className='CostCodeOverlay__labelRoot'>
              <IonIcon
                icon={
                  billLine?.costCodeId
                    ? checkmarkCircleOutline
                    : closeCircleOutline
                }
                color={billLine?.costCodeId ? 'primary' : 'danger'}
                style={{ verticalAlign: 'baseline' }}
              />
              <IonText>Cost Code: </IonText>
            </div>
            <ConcordFormDropdownV2
              options={costCodeOptions}
              onChange={(event, { selectedOption }) => {
                onChangeDropdown({
                  name: 'costCodeId',
                  selectedValue: selectedOption,
                })
              }}
              value={billLine.costCodeId}
              styles={{
                menu: provided => ({
                  ...provided,
                  minWidth: 200,
                }),
                control: provided => ({
                  ...provided,
                  border: 'none',
                }),
              }}
            />
          </ListGroup.Item>

          {billLine?.buyerTerminalId &&
            billLine?.sellerProductId &&
            billLine?.productGroupId &&
            !billLine.costCode && (
              <ListGroup.Item>
                <div className='CostCodeOverlay__labelRoot'>
                  <IonIcon
                    icon={
                      billLine?.costCodeId
                        ? checkmarkCircleOutline
                        : closeCircleOutline
                    }
                    color={billLine?.costCodeId ? 'primary' : 'danger'}
                    style={{ verticalAlign: 'sub' }}
                  />
                  <IonText>Cost Code not found: </IonText>
                </div>
                <Link
                  to={`/companies/${companyId}?view=${moduleName.company.costCodes}`}
                >
                  Click to set.
                </Link>
              </ListGroup.Item>
            )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  )

  if (canEdit) {
    return (
      <>
        <OverlayTrigger
          show={show}
          //container={ref}
          overlay={popover}
        >
          <div
            style={{ width: '100%' }}
            ref={ref}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            {children}
          </div>
        </OverlayTrigger>
      </>
    )
  }

  return (
    <div style={{ width: '100%' }} ref={ref}>
      {children}
    </div>
  )
}

export default memo(CostCodeOverlay)
