import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordFormStructure,
  IConcordFormField,
  CustomToastMessage,
  PlusIcon,
  DialogCompanyForm,
  DialogBuyerSellerForm,
  DialogTerminalForm,
} from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IInvoiceFormProps } from './type'
import { EFieldType, EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { IInvoiceFormValues } from '~/types/models/IInvoice'
import { IUser } from '~/types/models/IUser'
import { useRouter } from '~/hooks/useRouter'
import { useConfirmationProvider } from '~/contexts'
import { ITerminal } from '~/types/models/ITerminal'

const InvoiceForm = (props: IInvoiceFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [companyCreateForm, setCompanyCreateForm] = useState({
    isOpen: false,
    formData: undefined as Partial<ICompany> | undefined,
  })
  const [terminalForm, setTerminalForm] = useState({
    isOpen: false,
    formData: undefined as Partial<ITerminal> | undefined,
  })
  const [currentCompanyForm, setCurrentCompanyForm] =
    useState<'buyer' | 'seller'>('buyer')

  const router = useRouter()
  const { confirmation } = useConfirmationProvider()

  const formRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentUser: IUser = useSelector(selectSessionUser)

  const {
    buyerCompanyOptions,
    sellerCompanyOptions,
    fleetCompanyOptions,
    addCompany,
    updateCompany,
    findCompanyById,
  } = useQueryCompanies({})
  const { terminalOptions, addTerminal, updateTerminal } = useQueryTerminals()

  const onOpenCompanyCreateForm = (formData?: Partial<ICompany>) => () => {
    setCompanyCreateForm({
      formData,
      isOpen: true,
    })
  }

  const onCloseCompanyCreateForm = () => {
    setCompanyCreateForm({
      formData: undefined,
      isOpen: false,
    })
  }

  const onOpenTerminalForm = (formData?: Partial<ITerminal>) => () => {
    setTerminalForm({
      formData,
      isOpen: true,
    })
  }

  const onCloseTerminalForm = () => {
    setTerminalForm({
      formData: undefined,
      isOpen: false,
    })
  }

  const fields: IConcordFormField[] = [
    {
      name: 'buyerId',
      label: 'Buyer',
      type: EFieldType.singleSelect,
      options: buyerCompanyOptions,
      size: 6,
      isRequired: true,
      onChange() {
        formRef.current?.setValue('buyerTerminalId', null)
      },
      extraIcons: [
        {
          Icon: <PlusIcon color='var(--bs-primary)' />,
          onClick() {
            onOpenCompanyCreateForm({
              buyer: EYesNo.Yes,
            })()
            setCurrentCompanyForm('buyer')
          },
        },
      ],
      showUpdateButtons: true,
      renderForm({ isOpen, setFormState, optionSelected }) {
        const formData = findCompanyById(optionSelected?.value)
        return (
          <DialogCompanyForm
            isOpen={isOpen}
            formData={formData as any}
            onClose={() => {
              setFormState({ isOpen: false, optionSelected: null })
            }}
            afterUpdate={company => {
              updateCompany(company.id, company)
              setFormState({ isOpen: false, optionSelected: null })
            }}
          />
        )
      },
    },
    {
      name: 'buyerTerminalId',
      label: 'Buyer Terminal',
      type: EFieldType.singleSelect,
      showUpdateButtons: true,
      extraIcons({ watch }) {
        const buyerId = watch('buyerId', null)
        return [
          {
            Icon: <PlusIcon color='var(--bs-primary)' />,
            onClick() {
              onOpenTerminalForm({
                companyId: buyerId,
                terminalType: 'buyer',
              })()
              setCurrentCompanyForm('buyer')
            },
            isHidden: !buyerId,
          },
        ]
      },
      options({ watch }) {
        const companyId = watch('buyerId', null)
        if (companyId) {
          return terminalOptions.filter(t => t.companyId === companyId)
        }
        return []
      },
      renderForm({ isOpen, setFormState, optionSelected }) {
        return (
          <DialogTerminalForm
            isOpen={isOpen}
            formData={optionSelected?.item as any}
            onClose={() => {
              setFormState({ isOpen: false, optionSelected: null })
            }}
            afterUpdate={(terminal: ITerminal) => {
              updateTerminal(terminal.id, terminal)
              setFormState({ isOpen: false, optionSelected: null })
            }}
          />
        )
      },
      size: 6,
    },
    {
      name: 'sellerId',
      label: 'Seller',
      type: EFieldType.singleSelect,
      options: sellerCompanyOptions,
      size: 6,
      isRequired: true,
      showUpdateButtons: true,
      renderForm({ isOpen, setFormState, optionSelected }) {
        const formData = findCompanyById(optionSelected?.value)
        return (
          <DialogCompanyForm
            isOpen={isOpen}
            formData={formData as any}
            onClose={() => {
              setFormState({ isOpen: false, optionSelected: null })
            }}
            afterUpdate={company => {
              updateCompany(company.id, company)
              setFormState({ isOpen: false, optionSelected: null })
            }}
          />
        )
      },
      onChange() {
        formRef.current?.setValue('sellerTerminalId', null)
      },
      extraIcons: [
        {
          Icon: <PlusIcon color='var(--bs-primary)' />,
          onClick() {
            onOpenCompanyCreateForm({
              seller: EYesNo.Yes,
            })()
            setCurrentCompanyForm('seller')
          },
        },
      ],
    },
    {
      name: 'sellerTerminalId',
      label: 'Seller Terminal',
      showUpdateButtons: true,
      type: EFieldType.singleSelect,
      extraIcons({ watch }) {
        const sellerId = watch('sellerId', null)
        return [
          {
            Icon: <PlusIcon color='var(--bs-primary)' />,
            onClick() {
              onOpenTerminalForm({
                companyId: sellerId,
                terminalType: 'seller',
              })()
              setCurrentCompanyForm('seller')
            },
            isHidden: !sellerId,
          },
        ]
      },
      options({ watch }) {
        const companyId = watch('sellerId', null)
        if (companyId) {
          return terminalOptions.filter(t => t.companyId === companyId)
        }
        return []
      },
      renderForm({ isOpen, setFormState, optionSelected }) {
        return (
          <DialogTerminalForm
            isOpen={isOpen}
            formData={optionSelected?.item as any}
            onClose={() => {
              setFormState({ isOpen: false, optionSelected: null })
            }}
            afterUpdate={(terminal: ITerminal) => {
              updateTerminal(terminal.id, terminal)
              setFormState({ isOpen: false, optionSelected: null })
            }}
          />
        )
      },
      size: 6,
    },
    {
      name: 'num',
      label: 'Num',
      size: 6,
    },
    {
      name: 'color',
      label: 'Color',
      type: EFieldType.color,
      size: 6,
    },
    {
      name: 'startDate',
      label: 'Start Date',
      size: 4,
      type: EFieldType.date,
      isRequired: true,
    },
    {
      name: 'endDate',
      label: 'End Date',
      size: 4,
      type: EFieldType.date,
      isRequired: true,
    },
    {
      name: 'dueDate',
      label: 'Due Date',
      size: 4,
      type: EFieldType.date,
    },
    {
      name: 'arNotes',
      label: 'Ar Notes',
      size: 6,
    },
    {
      name: 'fleetId',
      label: 'Fleet',
      type: EFieldType.singleSelect,
      options: fleetCompanyOptions,
      size: 6,
    },
  ]

  const schema = useMemo(
    () =>
      Yup.object({
        sellerId: Yup.number()
          .required('Seller is required!')
          .typeError('Seller is required'),
        buyerId: Yup.number()
          .required('Buyer is required!')
          .typeError('Buyer is required'),
        startDate: Yup.string()
          .required('Start Date is required!')
          .typeError('Start Date is required'),
        endDate: Yup.string()
          .required('End Date is required!')
          .typeError('End Date is required'),
      }),
    [],
  )

  const defaultValues = useMemo<IInvoiceFormValues>(
    () => ({
      sellerId: null,
      buyerId: null,
      buyerTerminalId: null,
      sellerTerminalId: null,
      num: null,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      separate: true,
      userAccessId: currentUser.userAccess?.id,
    }),
    [currentUser.userAccess?.id],
  )

  const onCreate = useCallback(
    async (formValues: IInvoiceFormValues) => {
      const { errors, ...response } = await apiClient.invoices.create(
        formValues,
      )
      if (errors?.length > 0) {
        setErrors(errors)
      } else {
        afterCreate && afterCreate(response)
        toast.success(CustomToastMessage as any, {
          data: {
            onClickButton: () => {
              router.push(`/invoices/${response.id}`)
            },
            message: toastMessages.createSuccess,
            buttonText: 'View',
          },
        })
      }
    },
    [afterCreate, router],
  )

  const onUpdate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (formValues: IInvoiceFormValues) => {
      // if (formValues.id) {
      //   const payload = _.pick(formValues, ['settingField', 'settingValue'])
      //   const { errors, ...response } = await apiClient.settings.update(
      //     formValues.id,
      //     {
      //       setting: payload,
      //     },
      //   )
      //   if (errors.length > 0) {
      //     setErrors([errors[0]])
      //   } else {
      //     afterUpdate && afterUpdate(response)
      //     toast.success(toastMessages.updateSuccess)
      //   }
      // } else {
      //   setErrors(['Id is not found'])
      // }
    },
    [],
  )

  const handleSubmit = useCallback(
    async (formValues: IInvoiceFormValues) => {
      setIsLoading(true)
      setErrors([])
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (!isUpdating) {
      if (currentScope === EScope.seller) {
        formRef.current?.setValue('sellerId', currentCompany.id)
      } else {
        formRef.current?.setValue('buyerId', currentCompany.id)
      }
    }
  }, [currentCompany.id, currentScope, isUpdating])

  return (
    <>
      {errors.length > 0 ? (
        <Alert style={{ fontSize: 13 }} variant='danger'>
          <ul style={{ marginBottom: 0 }}>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </Alert>
      ) : null}
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />

      <DialogBuyerSellerForm
        isOpen={companyCreateForm.isOpen}
        onClose={onCloseCompanyCreateForm}
        formData={companyCreateForm.formData as any}
        afterCreate={async company => {
          addCompany(company)
          onCloseCompanyCreateForm()
          const result = await confirmation({
            message: `Do you want to use this ${currentCompanyForm}?`,
          })
          if (result === EYesNo.Yes) {
            formRef.current?.setValue(
              `${currentCompanyForm}Id` as any,
              company.id,
            )
          }
        }}
      />

      <DialogTerminalForm
        isOpen={terminalForm.isOpen}
        formData={terminalForm.formData}
        onClose={onCloseTerminalForm}
        afterCreate={async (terminal: ITerminal) => {
          addTerminal(terminal)
          onCloseTerminalForm()
          const result = await confirmation({
            message: `Do you want to use this ${currentCompanyForm}?`,
          })
          if (result === EYesNo.Yes) {
            formRef.current?.setValue(
              `${currentCompanyForm}TerminalId` as any,
              terminal.id,
            )
          }
        }}
      />
    </>
  )
}

export default InvoiceForm
