import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { IonSpinner } from '@ionic/react'
import {
  ConcordFormDropdownV2,
  IConcordFormOnChangeParams,
} from '~/components/shared'
import { ActionMeta, InputProps, components } from 'react-select'

import clsx from 'clsx'

import type { IDropdownWithCustomChildrenProps } from './type'

import './styles.scss'
import CustomValueContainer from './components/CustomValueContainer'

const Input = React.memo<
  PropsWithChildren<InputProps & { isLoading?: boolean }>
>(props => {
  const { selectProps } = props
  return (
    <div style={{ cursor: 'pointer' }} className='wrapChildren'>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(selectProps as any).childrenComponent}
      {selectProps.isLoading && (
        <IonSpinner name='lines-sharp-small' className='avatarSpinner' />
      )}
      {!selectProps.isLoading && (
        <>
          <input type='password' style={{ display: 'none' }} />
          <components.Input {...props} autoComplete='false' type='search' />
        </>
      )}
    </div>
  )
})

function DropdownWithCustomChildren(props: IDropdownWithCustomChildrenProps) {
  const {
    children,
    isLoading,
    className,
    noHover,
    isReadOnly,
    styles,
    showSearchField = true,
    defaultFocused = false,
    onChange,
    menuPortalTarget = null,
    canSelect = true,
    ...dropdownProps
  } = props
  const containerRef = useRef<any>()
  const [isFocused, setIsFocused] = useState(defaultFocused)
  const [inputValue, setInputValue] = useState('')

  const onDomClick = (e: any) => {
    if (containerRef.current) {
      let menu = containerRef.current.querySelector('.select__menu')
      if (
        !containerRef.current.contains(e.target) ||
        !menu ||
        !menu.contains(e.target)
      ) {
        setIsFocused(false)
        setInputValue('')
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick)

    return () => {
      document.removeEventListener('mousedown', onDomClick)
    }
  }, [])

  const changeAndClose = (
    event: ActionMeta<any>,
    params: IConcordFormOnChangeParams,
  ) => {
    onChange && onChange(event, params)

    setIsFocused(false)
  }

  if (canSelect) {
    return (
      <ConcordFormDropdownV2
        {...dropdownProps}
        onChange={changeAndClose}
        ref={containerRef}
        showSearchField={showSearchField}
        menuPortalTarget={menuPortalTarget}
        className={clsx('DropdownWithCustomChildren__container', className, {
          noHover,
          isReadOnly,
        })}
        styles={{
          container: provider => ({
            ...provider,
            maxWidth: 250,
            height: '100%',
          }),
          control: provider => ({
            ...provider,
            border: 'none',
            boxShadow: 'none',
          }),
          singleValue: provider => ({
            ...provider,
            display: 'none',
          }),
          indicatorsContainer: provider => ({
            ...provider,
            display: 'none',
          }),
          menu: provider => ({
            ...provider,
            minWidth: 220,
          }),
          input: provider => ({
            ...provider,
            opacity: 0,
          }),
          ...styles,
        }}
        isLoading={isLoading}
        childrenComponent={children}
        onMenuInputFocus={() => setIsFocused(true)}
        onInputChange={val => setInputValue(val)}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
        inputValue={inputValue}
        components={{
          ...dropdownProps.components,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Input: Input as any,
          ValueContainer: CustomValueContainer,
        }}
      />
    )
  }

  return children
}
export default DropdownWithCustomChildren
