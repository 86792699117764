import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IPaymentType } from '~/types/models/IPaymentType'
import useModifyPaymentTypes from './useModifyPaymentType'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryPaymentTypes = (
  options?: Partial<UseQueryOptions<IPaymentType[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['paymentTypes', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.paymentTypes.get()
      return response.paymentTypes
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const paymentTypes = useMemo(() => data || [], [data])

  const paymentTypeOptions = useMemo(
    () =>
      paymentTypes.map(item => {
        const { name, id } = item
        return {
          value: id,

          label: name,
          item,
        }
      }),
    [paymentTypes],
  )

  const findPaymentTypeById = useCallback(
    (id: number) => paymentTypes.find(item => item.id === id),
    [paymentTypes],
  )

  const { addPaymentType, updatePaymentType, removePaymentType } =
    useModifyPaymentTypes()

  return {
    paymentTypes,
    paymentTypeOptions,
    isLoadingPaymentTypesData: isLoading,
    addPaymentType,
    updatePaymentType,
    removePaymentType,
    findPaymentTypeById,
  }
}

export default useQueryPaymentTypes
