import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Loading from '../Loading'

const ReferenceNameCard = ({
  referenceName,
  editReferenceName,
  deleteReferenceName,
  editing,
}) => {
  const [loading, setLoading] = useState(false)

  const onClickEdit = () => {
    editReferenceName && editReferenceName()
  }

  const onClickDelete = () => {
    setLoading(true)
    deleteReferenceName && deleteReferenceName()
    setLoading(false)
  }
  return (
    <Card
      key={referenceName.id}
      onClick={() => onClickEdit(referenceName)}
      className='m-2'
      border={editing && 'primary'}
      style={{ cursor: 'pointer' }}
    >
      {loading && <Loading />}
      <Card.Body>
        <Card.Title>
          <Button
            className='float-end'
            variant='danger'
            onClick={() => onClickDelete(referenceName.id)}
          >
            Delete
          </Button>
          <Button
            className='me-3 float-end'
            variant='primary'
            onClick={() => onClickEdit(referenceName)}
          >
            Edit
          </Button>
        </Card.Title>
        <Card.Text>
          <p>{referenceName.referenceName}</p>
          <p>Code: {referenceName.referenceCode || '-'}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ReferenceNameCard
