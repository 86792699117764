const DEFAULT_COMPANY_FIXED_PRICE_COLUMNS = [
  {
    displayIndex: 0,
    sortIndex: 0,
    label: 'ID',
    field: 'id',
    show: true,
    value: 'id',
    type: 'string',
    sorted: true,
    sortable: true,
    isAsc: false,
    sortField: 'id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 1,
    sortIndex: 1,
    label: 'Seller',
    field: 'seller_id',
    show: true,
    value: 'seller_id',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'seller_id',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 2,
    sortIndex: 2,
    label: 'Seller Terminal',
    field: 'sellerTerminalId',
    show: true,
    value: 'seller_terminal_id',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'sellerTerminalId',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 3,
    sortIndex: 3,
    label: 'Buyer Terminal',
    field: 'buyerTerminalId',
    show: true,
    value: 'buyer_terminal_id',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'buyerTerminalId',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 4,
    sortIndex: 4,
    label: 'Item Type',
    field: 'itemType',
    show: true,
    value: 'item_type',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'itemType',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 5,
    sortIndex: 5,
    label: 'Product Name',
    field: 'sellerProduct.name',
    show: true,
    value: 'buyer_seller_product_id',
    type: 'string',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'sellerProduct.name',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 6,
    sortIndex: 6,
    label: 'Price',
    field: 'price',
    show: true,
    value: 'price',
    type: 'number',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'price',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 7,
    sortIndex: 7,
    label: 'Flat Rate',
    field: 'flatRate',
    show: true,
    value: 'flat_rate',
    type: 'number',
    sorted: false,
    sortable: true,
    isAsc: true,
    sortField: 'flatRate',
    hideable: true,
    isHidden: false,
  },
  {
    displayIndex: 8,
    label: 'Menu',
    field: 'menu',
    show: true,
    value: 'menu',
    type: '',
    sorted: false,
    sortable: false,
    isAsc: true,
    sortField: 'menu',
    hideable: true,
    isHidden: false,
  },
]

export default DEFAULT_COMPANY_FIXED_PRICE_COLUMNS
