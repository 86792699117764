import React from 'react'

const styles = {
  flagText: {
    color: '#d33939',
    display: 'inline-block',
    fontSize: '15px',
    marginBottom: 1,
    maxWidth: 490,
  },
}

export const renderFlags = billLine => {
  if (!billLine || !billLine.flags || billLine.flags.length == 0) {
    return undefined
  }

  return billLine.flags.map((flag, index) => (
    <div key={index}>
      <p style={styles.flagText}>
        <strong>{index + 1}: </strong>
        {`${flag.field}: ${flag.message}${
          flag.difference ? ' - Difference: ' + flag.difference : ''
        }`}
      </p>
    </div>
  ))
}

export const renderFlagsFromDataStore = flags => {
  return flags.map(([, flag], index) => {
    return (
      <div key={index}>
        <p style={styles.flagText}>
          <strong>{index + 1}: </strong>
          {`${flag.field}: ${flag.message}${
            flag.difference ? ' - Difference: ' + flag.difference : ''
          }`}
        </p>
      </div>
    )
  })
}

export const returnMatchedFieldsFromEnums = matchedEnums => {
  const matchedFields = []
  matchedEnums.map(field => {
    if (BILL_LINE_MATCHING_FIELDS_ENUMS[field]) {
      matchedFields.push(BILL_LINE_MATCHING_FIELDS_ENUMS[field])
    }
  })

  return matchedFields
}

const BILL_LINE_MATCHING_FIELDS_ENUMS = {
  0: 'ticketNum',
  1: 'lineDate',
  2: 'secondaryNum',
  3: 'productName',
  4: 'qty',
  5: 'category',
  6: 'sellerProductId',
  7: 'sellerTerminalId',
}
