import Tooltip from 'rc-tooltip'
import ParserDataTooltipContent from '~/components/load/ParserDataTooltipContent'
import { IonChip, IonIcon } from '@ionic/react'

import { statusClassNames } from '~/constants/invoice-type'

import './InvoiceStatusChip.scss'
import clsx from 'clsx'
import { flag } from 'ionicons/icons'
import { InvoiceEmailButton } from '../Table/InvoiceEmailButton'

function InvoiceStatusChip(props) {
  const {
    status,
    invoice,
    title,
    flagsData = [],
    afterSendEmail,
    hideEmailButton,
  } = props

  return (
    <>
      <div
        style={{
          whiteSpace: 'normal',
          // width: '100px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip
          placement='left'
          overlay={
            <div>
              <h4>{title}</h4>
              <ParserDataTooltipContent
                idKeyLabel='Invoice ID'
                data={invoice}
                flags={flagsData}
                title={title}
              />
            </div>
          }
          overlayClassName='InvoiceStatusChip__statusOverlay'
        >
          <IonChip
            className={clsx(
              'InvoiceStatusChip__status',
              statusClassNames[status],
            )}
          >
            {flagsData.length > 0 ? (
              <span style={{ fontSize: 12, paddingRight: 4, paddingTop: 4 }}>
                <IonIcon
                  style={{ color: 'red' }}
                  icon={flag}
                  className={'icon-inner'}
                />
              </span>
            ) : (
              <span className='InvoiceStatusChip__dotStatus' />
            )}

            <span className='InvoiceStatusChip__labelStatus'>{status}</span>
          </IonChip>
        </Tooltip>
        {!hideEmailButton && (
          <InvoiceEmailButton
            invoice={invoice}
            style={{ marginLeft: 4 }}
            variant={invoice.emailAt ? 'success' : 'dark'}
            afterSendEmail={afterSendEmail}
          />
        )}
      </div>
    </>
  )
}

export default InvoiceStatusChip
