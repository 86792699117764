import moment from 'moment'
import { Dropdown } from 'react-bootstrap'

import './styles.scss'

const SelectorComponent = (props: { options: any[] }) => {
  const { options } = props
  return (
    <div className='SelectorComponent__container'>
      {options.map(({ label, options }) => (
        <Dropdown key={label} style={{ marginRight: 4 }}>
          <Dropdown.Toggle>{label}</Dropdown.Toggle>
          <Dropdown.Menu>
            {options.map(({ label, value, onClick }: any) => (
              <Dropdown.Item
                key={value}
                onClick={() => {
                  if (onClick) {
                    onClick()
                  }
                }}
              >
                {label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </div>
  )
}

export const renderQuickStartDateChangeOptions = (options: {
  onChange: (date: string) => void
  currentDay?: string
}) => {
  const { onChange, currentDay = moment().format('YYYY-MM-DD') } = options

  const quickChangeDateButtons = [
    {
      label: 'Start Of',
      options: [
        {
          label: 'Year',
          value: 'year',
          onClick() {
            const year = moment(currentDay).year()
            onChange(`${year}-01-01`)
          },
        },
        {
          label: 'Quarter',
          value: 'quarter',
          onClick() {
            const result = moment(currentDay)
              .startOf('quarter')
              .format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Month',
          value: 'month',
          onClick() {
            const result = moment(currentDay)
              .startOf('month')
              .format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Week',
          value: 'week',
          onClick() {
            const result = moment(currentDay)
              .startOf('week')
              .format('YYYY-MM-DD')
            onChange(result)
          },
        },
      ],
    },
    {
      label: 'From Today',
      options: [
        {
          label: 'Today',
          value: 'today',
          onClick() {
            const result = moment().format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Yesterday',
          value: 'yesterday',
          onClick() {
            const result = moment().subtract(1, 'days').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '1 Week Ago',
          value: '1w',
          onClick() {
            const result = moment().subtract(1, 'weeks').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Month Ago',
          value: '1m',
          onClick() {
            const result = moment().subtract(1, 'months').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '3 Months Ago',
          value: '3m',
          onClick() {
            const result = moment().subtract(3, 'months').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '6 Months Ago',
          value: '6m',
          onClick() {
            const result = moment().subtract(6, 'months').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '1 Year Ago',
          value: '1y',
          onClick() {
            const result = moment().subtract(1, 'years').format('YYYY-MM-DD')
            onChange(result)
          },
        },
      ],
    },
  ]

  return <SelectorComponent options={quickChangeDateButtons} />
}

export const renderQuickEndDateChangeOptions = (options: {
  onChange: (date: string) => void
  currentDay?: string
}) => {
  const { onChange, currentDay = moment().format('YYYY-MM-DD') } = options

  const quickChangeDateButtons = [
    {
      label: 'End Of',
      options: [
        {
          label: 'Year',
          value: 'year',
          onClick() {
            const year = moment(currentDay).year()
            onChange(`${year}-12-31`)
          },
        },
        {
          label: 'Quarter',
          value: 'quarter',
          onClick() {
            const result = moment(currentDay)
              .endOf('quarter')
              .format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Month',
          value: 'month',
          onClick() {
            const result = moment(currentDay)
              .endOf('month')
              .format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Week',
          value: 'week',
          onClick() {
            const result = moment(currentDay).endOf('week').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Today',
          value: 'today',
          onClick() {
            const result = moment(currentDay).format('YYYY-MM-DD')
            onChange(result)
          },
        },
      ],
    },
    {
      label: 'From Today',
      options: [
        {
          label: 'Today',
          value: 'today',
          onClick() {
            const result = moment().format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: 'Tomorrow',
          value: 'tomorrow',
          onClick() {
            const result = moment().add(1, 'days').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '1 Week From Now',
          value: '1w',
          onClick() {
            const result = moment().add(1, 'weeks').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '1 Month',
          value: '1m',
          onClick() {
            const result = moment().add(1, 'months').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '3 Months',
          value: '3m',
          onClick() {
            const result = moment().add(3, 'months').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '6 Months',
          value: '6m',
          onClick() {
            const result = moment().add(6, 'months').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '1 Year',
          value: '1y',
          onClick() {
            const result = moment().add(1, 'years').format('YYYY-MM-DD')
            onChange(result)
          },
        },
        {
          label: '2 Years',
          value: '2y',
          onClick() {
            const result = moment().add(2, 'years').format('YYYY-MM-DD')
            onChange(result)
          },
        },
      ],
    },
  ]

  return <SelectorComponent options={quickChangeDateButtons} />
}
