// import queryString from 'query-string'
// import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import _ from 'lodash'
import {
  checkmarkCircleOutline,
  alertCircleOutline,
  bookmarkOutline,
  documentTextOutline,
  imageOutline,
  apertureOutline,
  caretForwardCircleOutline,
} from 'ionicons/icons'

export const parseInputErrors = error => {
  if (!error) {
    return
  }
  if (Array.isArray(error)) {
    return error[0]
  }

  return error
}

// export const applyQueryParams = (url, params) => {
//   let urlWithQuery = url;
//   const validParams = params.filter((param) => Boolean(param.value));
//   if (!isEmpty(validParams)) {
//     const first = validParams.shift();
//     urlWithQuery = `${urlWithQuery}?${first.prop}=${first.value}`;
//     validParams.forEach(({ prop, value }) => {
//       const query = {};
//       query[prop] = value;
//       urlWithQuery = `${urlWithQuery}&${queryString.stringify(query)}`;
//     });
//   }

//   return urlWithQuery;
// };

export const addQueryParams = (props, queryHash) => {
  const pathname = props.location.pathname
  const searchParams = new URLSearchParams(props.location.search)

  for (const param in queryHash) {
    searchParams.set(param, queryHash[param])
  }

  props.history.push({
    pathname,
    search: searchParams.toString(),
  })
}

export const searchWords = (text, searchKey) => {
  if (!searchKey) return true
  if (!text) return false

  return text.toLowerCase().includes(searchKey.toLowerCase())
}

export const generateSearchParams = params => {
  if (!params) return ''

  return Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
}

export const readFile = file =>
  new Promise(resolve => {
    try {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    } catch (err) {
      console.log('err', err)
    }
  })

export const objectSendTime = object => {
  const now = moment()
  const createdAt = object.createdAt
    ? moment(object.createdAt)
    : moment(object.created_at)
  const timeFromNow = moment.duration(now.diff(createdAt))
  if (timeFromNow.asHours() < 24) return createdAt.format('H:mm')
  return moment(createdAt).format('MMM-D H:mm')
}

export const dollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const isSmallDevice = () => window.innerWidth < 768

export const sortData = (list, sortField, type, ascendingSort) => {
  const newList = [...list]
  if (type == 'date') {
    return newList.sort((a, b) => {
      if (ascendingSort) {
        return moment(a[sortField]) < moment(b[sortField]) ? 1 : -1
      }
      return moment(a[sortField]) > moment(b[sortField]) ? 1 : -1
    })
  }

  if (type == 'string') {
    return newList.sort((a, b) => {
      if (ascendingSort) {
        return a[sortField].localeCompare(b[sortField])
      }
      return b[sortField].localeCompare(a[sortField])
    })
  }

  return newList.sort((a, b) => {
    if (ascendingSort) {
      return a[sortField] > b[sortField] ? -1 : 1
    }
    return b[sortField] > a[sortField] ? -1 : 1
  })
}

export const isExpiredAWSLink = url => {
  if (!url) return false

  try {
    const parseUrl = new URL(url)
    const requestDate = parseUrl.searchParams.get('X-Amz-Date')
    const expiredTime = parseUrl.searchParams.get('X-Amz-Expires')

    if (!requestDate || !expiredTime) return false

    const expirationMoment = moment(requestDate).add(parseInt(expiredTime), 's')

    return moment().isAfter(expirationMoment)
  } catch (error) {
    return false
  }
}

export const getCoordinates = (coordinates, geofence) => {
  if (coordinates && coordinates.length > 0) {
    return coordinates.map(({ lat, lng }) => ({ lat, lng }))
  } else if (geofence) {
    return [
      { lat: parseFloat(geofence.latN), lng: parseFloat(geofence.longW) },
      { lat: parseFloat(geofence.latN), lng: parseFloat(geofence.longE) },
      { lat: parseFloat(geofence.latS), lng: parseFloat(geofence.longE) },
      { lat: parseFloat(geofence.latS), lng: parseFloat(geofence.longW) },
    ]
  }
  return []
}

export const toFixed = (number, precision) =>
  (+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(
    precision,
  )

export const toFixedWithLocaleString = (number, precision) => {
  const fixedText = _.round(parseFloat(number), precision).toFixed(precision)
  const numbers = fixedText.split('.')
  const localeString = parseFloat(numbers[0]).toLocaleString()

  return `${localeString}.${numbers[1]}`
}

export const statusColor = status => {
  switch (status) {
    case 'New':
      return 'primary'
    case 'Used':
      return 'fleet'
    case 'Issue':
      return 'danger'
    case 'Pending':
      return 'orange'
    default:
      return 'primary'
  }
}

export const statusIcon = status => {
  switch (status) {
    case 'New':
      return bookmarkOutline
    case 'Used':
      return checkmarkCircleOutline
    case 'Issue':
      return alertCircleOutline
    case 'Pending':
      return caretForwardCircleOutline
    default:
      return bookmarkOutline
  }
}

export const fileTypeColor = filetype => {
  switch (filetype) {
    case 'jpeg':
      return 'success'
    case 'jpg':
      return 'success'
    case 'png':
      return 'danger'
    case 'pdf':
      return 'dark'
    case 'octet-stream':
      return 'success'
    default:
      return 'primary'
  }
}

export const fileTypeIcon = filetype => {
  switch (filetype) {
    case 'octet-stream':
      return apertureOutline
    case 'pdf':
      return documentTextOutline
    default:
      return imageOutline
  }
}

export const isInfinity = number => number === Infinity || number === -Infinity

export const floatRegExp = number =>
  new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$').test(number)

export const KEYBOARD_CODE = {
  TAB: 9,
  ENTER: 13,
  CTRL: 17,
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  A: 65,
  B: 66,
  D: 68,
  E: 69,
  J: 74,
  K: 75,
  L: 76,
  O: 79,
  P: 80,
  S: 83,
  U: 85,
}

export const getNumberValue = value =>
  !value || _.isNaN(value) || isInfinity(value)
    ? toFixedWithLocaleString(0, 2)
    : toFixedWithLocaleString(value, 2)

export const humanizeKey = (str = '') => {
  let i
  const frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }

  return frags.join(' ')
}

export const replaceDataWithUpdated = (updatedData, originalData) => {
  const finalDataAfterChanges = originalData.map(data => {
    const filter = updatedData.filter(val => val.id === data.id)
    if (filter.length > 1) return filter[0]
    return data
  })

  return finalDataAfterChanges
}

export const deepCopy = structure => JSON.parse(JSON.stringify(structure))

export const formatPhoneNumber = number => {
  if (!number) {
    return
  }
  const validNumberLengths = [7, 10, 11]

  if (!validNumberLengths.includes(number.length)) {
    return number
  }

  const lastFourDigits = number.slice(-4)
  const nextThreeDigits = number.slice(-7, -4)
  const sevenDigitNumber = `${nextThreeDigits}-${lastFourDigits}`

  if (number.length == 7) {
    return sevenDigitNumber
  }

  const areaCode = number.slice(number.length - 10, number.length - 7)
  const tenDigitNumber = `(${areaCode})${sevenDigitNumber}`

  if (number.length == 10) {
    return tenDigitNumber
  }

  return `${number.charAt(0)}${tenDigitNumber}`
}

export const handleDisplayTagColor = (tags, load) => {
  try {
    if (!tags || !load) {
      return ''
    }

    const colors = (load.tagIds || []).map(id => {
      const foundTag = tags.find(tag => tag.id == id)

      return foundTag?.color
    })

    return colors.length > 0 ? colors : []
  } catch (error) {
    // console.log('error', error)

    return []
  }
}

export const handleReturnColors = colors =>
  colors.length === 1 ? `6px solid ${colors[0]}` : '6px solid'

export const handleColorSplit = colors => {
  const gradientArray = ['to bottom']

  const percentage = 100 / colors.length
  colors.forEach((color, index) => {
    const startingPoint = index * percentage + '%'
    const endingPoint = (index + 1) * percentage + '%'

    gradientArray.push(`${color} ${startingPoint}`)
    gradientArray.push(`${color} ${endingPoint}`)
  })

  const gradientString = gradientArray.join(', ')

  return `linear-gradient(${gradientString}) ${colors.length}`
}

export const dateRangeComparison = (selectedDate, dateRange) => {
  const { startDate, endDate } = dateRange
  if (!startDate && !endDate) {
    return true
  }

  const startOfDay = moment(startDate).startOf('day')
  const endOfDay = moment(endDate).endOf('day')
  const compareDate = moment(selectedDate).startOf('day')

  const sameAsAfter = moment(compareDate).isSameOrAfter(moment(startOfDay))
  const sameAsBefore = moment(compareDate).isSameOrBefore(moment(endOfDay))

  return sameAsAfter && sameAsBefore
}

export const flagHashToFlagableIds = flags => {
  const list = Object.values(flags)

  const flagableIdHash = {}

  list.map(flag => {
    if (!flagableIdHash[flag.flagableId]) {
      flagableIdHash[flag.flagableId] = []
    }
    flagableIdHash[flag.flagableId].push(flag)
  })

  return flagableIdHash
}

// METHOD USED TO RE-ADD THE MISSING COMMONDATA FIELDS BACK TO EACH LOAD
export const matchObjectById = (list, commonData, flagableIds) => {
  if (list.length == 0) {
    return []
  }

  const loadFieldsNeeded = [
    'buyer',
    'buyerTerminal',
    'sellerProduct',
    'flags',
    'fleet',
    'seller',
    'sellerTerminal',
  ]

  return list.map(item => {
    loadFieldsNeeded.forEach(model => {
      if (item[`${model}Id`]) {
        switch (model) {
          case 'buyer':
          case 'seller':
            if (commonData.companies[item[`${model}Id`]]) {
              item[model] = commonData.companies[item[`${model}Id`]]
            }
            break

          case 'buyerTerminal':
          case 'sellerTerminal':
            if (commonData.terminals[item[`${model}Id`]]) {
              item[model] = commonData.terminals[item[`${model}Id`]]
            }
            break

          default:
            if (commonData[model][item[`${model}Id`]]) {
              item[model] = commonData[model][item[`${model}Id`]]
            }
            break
        }
      } else if (model == 'flags') {
        if (flagableIds[item.id]) {
          item.flags = flagableIds[item.id]
        } else {
          item.flags = []
        }
      } else {
        item[model] = {}
      }
    })

    return item
  })
}

export const addFlags = (list, flagableIds) => {
  if (list.length === 0) {
    return []
  }

  return list.map(item => {
    if (flagableIds[item.id]) {
      item.flags = flagableIds[item.id]
    } else {
      item.flags = []
    }

    return item
  })
}

export const arrayToObjectById = array => {
  const hash = {}

  array.forEach(item => (hash[item.id] = item))

  return hash
}

export const titleCaseFromSnakeCase = s =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase(),
  )

export const useEmployeeOrContractor = workerUid => {
  if (_.startsWith(workerUid, 'emp_')) {
    return {
      employee: workerUid,
    }
  }

  return {
    contractor: workerUid,
  }
}

export const makeOptions = enumObj =>
  Object.keys(enumObj).map(key => ({
    label: _.startCase(key),
    value: enumObj[key],
  })) || []
