import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { CalendarIcon } from '~/components/shared'
import { DatePickerHeader } from './DatePickerHeader'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'
import moment from 'moment'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { getYear } from 'date-fns'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import formatDateBasedOnYear from '~/utils/formatDateBasedOnYear'

export type IDateRange = {
  startDate: string | Date | null | undefined
  endDate: string | Date | null | undefined
}

export interface IReusableDatePickerProps {
  value?: string | Date | null
  rangeValue?: IDateRange
  dateFormat?: string
  onChange?: (date: string | null) => void
  onChangeRange?: (dateRange: IDateRange) => void
  minDate?: Date | string | null
  maxDate?: Date | string | null
  isDisabled?: boolean
  isReadOnly?: boolean
  placeholder?: string
  className?: string
  inputClassName?: string
  onBlur?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  portalId?: string
  customInput?: JSX.Element
  showIcon?: boolean
  yearRange?: number[]
  showDateRange?: boolean
  defaultValue?: string | Date | null
  dayClassName?: (date: Date) => string | undefined
}

function ReusableDatePicker(props: IReusableDatePickerProps, ref: any) {
  const {
    value,
    dateFormat,
    onChange,
    minDate,
    maxDate,
    isDisabled,
    isReadOnly,
    placeholder,
    className,
    inputClassName,
    portalId,
    onBlur,
    onKeyDown,
    customInput,
    showIcon = true,
    yearRange = [1950, getYear(new Date())],
    showDateRange,
    rangeValue,
    onChangeRange,
    dayClassName,
  } = props

  const [date, setDate] = useState<Date | null | undefined>(null)
  const [dateRange, setDateRange] = useState<
    Record<'startDate' | 'endDate', Date | null | undefined>
  >({
    startDate: null,
    endDate: null,
  })
  const [showCalendar, setShowCalendar] = useState(false)

  const dateRangeProps = useMemo(() => {
    if (showDateRange) {
      return {
        selectsRange: true,
        selectsDisabledDaysInRange: true,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }
    }
    return {}
  }, [dateRange.endDate, dateRange.startDate, showDateRange])

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const companyDateFormat = useMemo(
    () =>
      formatDateBasedOnYear(
        dateFormat || currentCompany.dateFormat,
        showDateRange ? dateRange.startDate : date,
      ),
    [
      currentCompany.dateFormat,
      date,
      dateFormat,
      dateRange.startDate,
      showDateRange,
    ],
  )

  const onToggleOpenSelector = () => {
    setShowCalendar(prev => !prev)
  }

  useEffect(() => {
    if (typeof value === 'string' && value.length > 0) {
      try {
        setDate(moment(value).toDate())
      } catch (error) {
        console.log('error', error)
        toast.error('Invalid date format')
        setDate(null)
      }
    } else {
      setDate(null)
    }
  }, [value])

  useEffect(() => {
    if (rangeValue) {
      setDateRange({
        startDate:
          typeof rangeValue.startDate === 'string'
            ? moment(rangeValue.startDate).toDate()
            : rangeValue.startDate,
        endDate:
          typeof rangeValue.endDate === 'string'
            ? moment(rangeValue.endDate).toDate()
            : rangeValue.endDate,
      })
    } else {
      setDateRange({
        startDate: null,
        endDate: null,
      })
    }
  }, [rangeValue])

  return (
    <ReactDatePicker
      portalId={portalId}
      selected={date}
      onChange={(date: any) => {
        if (Array.isArray(date)) {
          const startDate =
            getYear(date[0]) >= yearRange[1]
              ? moment(date[0]).set({ years: yearRange[1] }).toISOString()
              : date[0]
              ? date[0].toISOString()
              : null
          const endDate =
            getYear(date[1]) >= yearRange[1]
              ? moment(date[1]).set({ years: yearRange[1] }).toISOString()
              : date[1]
              ? date[1].toISOString()
              : null

          onChangeRange && onChangeRange({ startDate, endDate })
        } else {
          if (date && getYear(date) >= yearRange[1]) {
            onChange &&
              onChange(moment(date).set({ years: yearRange[1] }).toISOString())
          } else {
            onChange && onChange(date ? date.toISOString() : null)
          }
        }
        onToggleOpenSelector()
      }}
      ref={ref}
      dateFormat={companyDateFormat}
      showMonthDropdown
      showYearDropdown
      showIcon={showIcon}
      onBlur={() => {
        onBlur && onBlur()
        setShowCalendar(false)
      }}
      onFocus={() => {
        setShowCalendar(true)
      }}
      onClickOutside={() => {
        setShowCalendar(false)
      }}
      onInputClick={() => {
        setShowCalendar(true)
      }}
      onKeyDown={onKeyDown}
      wrapperClassName={clsx('ReusableDatePicker__datePicker', className)}
      className={clsx('ReusableDatePicker__dateInput', inputClassName, {
        isDisabled,
        isReadOnly,
      })}
      placeholderText={
        typeof placeholder === 'string' ? placeholder : companyDateFormat
      }
      popperClassName='ReusableDatePicker__popperClassName'
      showPopperArrow={false}
      minDate={typeof minDate === 'string' ? moment(minDate).toDate() : minDate}
      maxDate={typeof maxDate === 'string' ? moment(maxDate).toDate() : maxDate}
      customInput={customInput || <Form.Control />}
      icon={
        <CalendarIcon
          className='clickable'
          onClick={event => {
            event.stopPropagation()
            onToggleOpenSelector()
          }}
        />
      }
      renderCustomHeader={headerProps => (
        <DatePickerHeader {...headerProps} yearRange={yearRange} />
      )}
      open={showCalendar}
      dayClassName={dayClassName}
      {...(dateRangeProps as any)}
    >
      <div
        className='clickable'
        onClick={() => {
          onChange && onChange(null)
        }}
      >
        Clear
      </div>

      {yearRange[1] >= getYear(new Date()) && (
        <div
          className='clickable'
          onClick={() => {
            onChange && onChange(new Date().toISOString())
          }}
        >
          Today
        </div>
      )}
    </ReactDatePicker>
  )
}

export default React.forwardRef(ReusableDatePicker)
