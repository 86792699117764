import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BookIcon,
  DeleteIcon,
  FlagIcon,
  IRTColumnDef,
  ListSearchIcon,
  TooltipIcon,
  DownloadIcon,
  OpenIcon,
  InfoIcon,
  CsvIcon,
  useModalDownloadProgress,
  ToolTipOverlay,
  RTCell,
  RTRow,
  TicketView,
  LinkIcon,
  IRTRowActionItem,
  PlusIcon,
  DollarIcon,
  CartIcon,
  ReusableTableInstance,
  CheckMarkIcon,
  DropdownWithCustomChildren,
  ClockIcon,
  DialogSellerProductForm,
  ArrowForwardIcon,
  EyeIcon,
} from '~/components/shared'
import {
  useQueryBillLines,
  useQueryFlags,
  useQueryInvoices,
  useQueryPaymentPayables,
  useQueryPricings,
  useQueryProductGroups,
  useQuerySellerProducts,
  useSellerProductsHierarchyOptions,
  useQueryTags,
  useQueryTerminals,
  useQueryUseTableConfigurations,
} from '~/hooks/useQueryData'
import { selectBillLinesFilters, selectCurrentScope } from '~/redux/selectors'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
  ETableName,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { IBillLine } from '~/types/models/IBillLine'
import { IInvoice } from '~/types/models/IInvoice'
import { EFlagFlagableType } from '~/types/enums/EFlag'
import { Button } from 'react-bootstrap'
import clsx from 'clsx'

import './styles.scss'
import { ICommonOption } from '~/types/models/ICommonModel'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import _ from 'lodash'
import { useWindowSize } from 'react-use'
import { apiClient } from '~/api/ApiClient'
import { isParserButler, isParserImpra } from '~/utils/checkParser'
import { createInvoiceParserUrl, getTitle } from '~/utils'
import { BUTLER_URL, MATCH_OPTIONS } from '~/utils/constants'
import getFileNameFromAWSLink from '~/utils/getFileNameFromAWSLink'
import { useConfirmationProvider } from '~/contexts'
import { useRouter } from '~/hooks/useRouter'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import {
  ColumnOrderState,
  GroupingState,
  RowSelectionState,
  VisibilityState,
} from '@tanstack/react-table'
import { EInvoiceStatus } from '~/types/enums/EInvoice'
import { ISellerProduct } from '~/types/models/ISellerProduct'
import { MatchedBillLineRow } from './MatchedBillLineRow'
import { IBillLinesTableProps } from './type'
import { CostCodeOverlay } from './CostCodeOverlay'
import { RTEditCellNumberField } from '~/components/shared/ReusableTable/components/inputs/RTEditCellNumberField'
import { IPricing } from '~/types/models/IPricing'
import { IHierarchyRow } from '~/types/models/IHierarchyRow'
import billLinesSlice from '~/redux/reducers/ui/billLines'
import buildObjectName from '~/utils/buildObjectName'
import { IAutoCharge } from '~/types/models/IAutoCharge'
import { RTEditCellTextField } from '~/components/shared/ReusableTable/components/inputs/RTEditCellTextField'
import { produce } from 'immer'
import roundNumber from '~/utils/roundNumber'
import { RTEditCellSingleSelectField } from '~/components/shared/ReusableTable/components/inputs/RTEditCellSingleSelectField'
import { format } from 'date-fns'
import billLineSchema from './schema'
import moment from 'moment'
import findMinMaxByField from '~/utils/findMinMaxByField'
import getUniqueCountByField from '~/utils/getUniqueCountByField'
import pluralize from 'pluralize'

const useBillLinesTable = (props: IBillLinesTableProps) => {
  const {
    invoice,
    queryParams,
    className,
    state: tableState,
    isUseQueryDisabled = false,
    ...tableProps
  } = props

  const currentScope: EScope = useSelector(selectCurrentScope)
  const isSeller = currentScope === EScope.seller

  const [isShowFlags, setIsShowFlags] = useState(false)
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [isShowPdfModal, setIsShowPdfModal] = useState(false)
  const [isShowOcrModal, setIsShowOcrModal] = useState(false)
  const [isShowMovingBillLines, setIsShowMovingBillLines] = useState(false)
  const [loadExtrasModal, setLoadExtrasModal] = useState({
    isOpen: false,
    billLine: undefined as IBillLine | undefined,
  })
  const [unmatchedBillLinesModal, setUnmatchedBillLinesModal] = useState({
    isOpen: false,
    billLine: undefined as undefined | IBillLine,
  })
  const [deletingIds, setDeletingIds] = useState<number[]>([])
  const [, setForceRender] = useState(0)
  const [isCreatingBillLine, setIsCreatingBillLine] = useState(false)
  const [billLinesCreateModal, setBillLinesCreateModal] = useState({
    isOpen: false,
    data: [] as any,
    columns: [] as any,
  })
  const [countRawBillLines, setCountRawBillLines] = useState(0)
  const [countCreatedBillLines, setCountCreatedBillLines] = useState(0)
  const [error, setError] = useState<Record<string | number, string[]>>({})
  const [draftRowSelection, setDraftRowSelection] = useState<RowSelectionState>(
    {},
  )
  const [draftBillLineIds, setDraftBillLineIds] = useState<string[]>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    [ERTDisplayColumnId.expand]: false,
    lineDate: true,
    ticketNum: true,
    productCode: true,
    productName: true,
    buyerTerminalId: true,
    qty: true,
    price: true,
    netPrice: true,
    total: true,
    paidAmt: true,
    balance: true,
    tax: true,
    costCodeId: !isSeller,
    materialExtPrice: false,
    otherCharges: false,
    discount: false,
  })
  const [draftBannerError, setDraftBannerError] = useState('')

  const [draftColumnVisibility, setDraftColumnVisibility] =
    useState<VisibilityState>({})
  const [draftColumnOrder, setDraftColumnOrder] = useState<ColumnOrderState>([])
  const [sortOptions, setSortOptions] = useState([
    {
      label: 'Date',
      sortField: 'lineDate',
      sorted: true,
      isAsc: true,
    },
    {
      label: 'Ticket #',
      sortField: 'ticketNum',
      sorted: true,
      isAsc: true,
    },
    {
      label: 'Ext',
      sortField: 'materialExtPrice',
      sorted: true,
      isAsc: false,
    },
    {
      label: 'Price',
      sortField: 'price',
      sorted: true,
      isAsc: false,
    },
    {
      label: 'Qty',
      sortField: 'qty',
      sorted: false,
      isAsc: false,
    },
    {
      label: 'Net Price',
      sortField: 'netPrice',
      sorted: false,
      isAsc: false,
    },
    {
      label: 'Total',
      sortField: 'total',
      sorted: false,
      isAsc: false,
    },
    {
      label: 'Paid Amt',
      sortField: 'paidAmt',
      sorted: false,
      isAsc: false,
    },
    {
      label: 'Balance',
      sortField: 'balance',
      sorted: false,
      isAsc: false,
    },
    {
      label: 'Discount',
      sortField: 'discount',
      sorted: false,
      isAsc: false,
    },
  ])
  const [grouping, setGrouping] = useState<GroupingState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [invoiceRowSelection, setInvoiceRowSelection] =
    useState<RowSelectionState>({})
  const [areBillLinesMoving, setAreBillLinesMoving] = useState(false)
  const [pricingForm, setPricingForm] = useState({
    isOpen: false,
    formData: undefined as IPricing | undefined,
    billLineData: undefined as IBillLine | undefined,
  })
  const [pricingsListModal, setPricingsListModal] = useState({
    isOpen: false,
    billLine: undefined as IBillLine | undefined,
  })
  const [showExtraForm, setShowExtraForm] = useState(false)
  const [loadDetailsModal, setLoadDetailsModal] = useState({
    isOpen: false,
    billLine: undefined as IBillLine | undefined,
  })
  const [isFetchingAutoCharge, setIsFetchingAutoCharge] = useState(false)
  const [autoChargeForm, setAutoChargeForm] = useState({
    isOpen: false,
    formData: undefined as IAutoCharge | undefined,
  })
  const [priceChangeModal, setPriceChangeModal] = useState({
    isOpen: false,
    formData: undefined as any,
    pricing: undefined as IPricing | undefined,
  })

  const [isCreatingMultiBillLines, setIsCreatingMultiBillLines] =
    useState(false)

  const [billLineTaxesModal, setBillLineTaxesModal] = useState({
    isOpen: false,
    billLineId: undefined as number | undefined,
  })

  const countDraftRowSelection = Object.keys(draftRowSelection).length

  const availableDraftFields = useMemo(
    () =>
      Object.keys(draftColumnVisibility).filter(
        key =>
          draftColumnVisibility[key] &&
          ![ERTDisplayColumnId.select, ERTDisplayColumnId.actions].includes(
            key as any,
          ),
      ),
    [draftColumnVisibility],
  )

  const invoiceIdSelected = Object.keys(invoiceRowSelection).map(id => +id)[0]
  const filterData: any = useSelector(selectBillLinesFilters)

  const changingPriceTypeRef = useRef('only')

  const productFieldsUpdate = useRef<string[]>(['productCode', 'productName'])

  const columnOrder = useMemo(
    () => Object.keys(columnVisibility),
    [columnVisibility],
  )

  const billLineIdsSelected = useMemo(
    () => Object.keys(rowSelection).map(id => +id),
    [rowSelection],
  )

  const canEditCell = useMemo(() => {
    if (!invoice) {
      return false
    }

    if (currentScope === EScope.seller) {
      return (invoice as any).sellerStatus === EInvoiceStatus.Progress
    }

    const isSellerStatusNotProgress =
      (invoice as any).sellerStatus !== EInvoiceStatus.Progress
    const allowEdit = [
      EInvoiceStatus.Progress,
      EInvoiceStatus.Uploaded,
      EInvoiceStatus.Processed,
    ].includes(invoice?.status)

    return isSellerStatusNotProgress && allowEdit
  }, [currentScope, invoice])

  const windowSize = useWindowSize()
  const router = useRouter()
  const { downloadFile } = useModalDownloadProgress()
  const { confirmation } = useConfirmationProvider()
  const dispatch = useDispatch()

  const tableRef = useRef<ReusableTableInstance<IBillLine>>()

  const tableSorting = sortOptions
    .filter(({ sorted }) => sorted)
    .map(({ sortField, isAsc }) => ({
      id: sortField,
      desc: !isAsc,
    }))

  const { filterConfigurations } = useQueryUseTableConfigurations({
    tableName: ETableName.groupingBillLineFields,
  })

  const { invoicesData, isLoadingInvoicesData, refetchQueryInvoices } =
    useQueryInvoices(
      {
        page: 1,
        perPage: 24,
        filters: {
          status: [
            EInvoiceStatus.Progress,
            EInvoiceStatus.Processed,
            EInvoiceStatus.Uploaded,
          ],
          buyerId: invoice?.buyerId,
          sellerId: invoice?.sellerId,
        },
      },
      { enabled: isShowMovingBillLines && Boolean(invoice) },
    )

  const { refetchQueryInvoices: refetchInvoiceData } = useQueryInvoices(
    {
      id: invoice?.id,
    },
    { enabled: Boolean(invoice) },
  )

  const {
    billLinesData,
    isBillLinesFetching,
    isBillLinesFetched,
    refetchQueryBillLines,
    updateBillLine,
    removeBillLine,
    updateBillLines,
    addBillLine,
  } = useQueryBillLines(
    {
      ...queryParams,
      filters: {
        ...filterData,
        ...queryParams?.filters,
      },
    },
    {
      enabled: !isUseQueryDisabled && Boolean(queryParams?.filters?.invoiceId),
    },
  )

  const totalBillLines = useMemo(
    () =>
      billLinesData.reduce(
        (total, item) => (total += Number(item.total || 0)),
        0,
      ),
    [billLinesData],
  )

  const isBillLinesEqualInvoice = useMemo(() => {
    return Number(totalBillLines.toFixed(2)) === Number(invoice?.total || 0)
  }, [invoice?.total, totalBillLines])

  const { findPricingDataById, refetchPricingsData } = useQueryPricings()

  const { paymentPayablesData } = useQueryPaymentPayables(
    {
      filters: {
        billLineId: billLinesData.map(({ id }) => id),
      },
    },
    { enabled: billLinesData.length > 0 && isBillLinesFetched },
  )

  const { flagsData, refetchFlagsData } = useQueryFlags(
    {
      filters: {
        flagableType: EFlagFlagableType.BillLine,
        flagableId: billLinesData.map(({ id }) => id),
      },
    },
    { enabled: isBillLinesFetched && billLinesData.length > 0 },
  )

  const { tagOptions } = useQueryTags()

  const { productGroupOptions } = useQueryProductGroups()

  const billLinesHavingFlags = useMemo(() => {
    const flagableIds = flagsData.map(({ flagableId }) => flagableId)
    return billLinesData.filter(({ id }) => flagableIds.includes(id))
  }, [billLinesData, flagsData])

  const countOfRawError = useMemo(() => {
    return Object.values(error).reduce(
      (count, arr) => count + (arr.length > 0 ? 1 : 0),
      0,
    )
  }, [error])

  const flagsCounter = useMemo(() => {
    const flagsDataGroupped = _.groupBy(flagsData, 'flagableId')
    return Object.keys(flagsDataGroupped).length
  }, [flagsData])

  const { sellerProducts, sellerProductOptions, findSellerProductById } =
    useQuerySellerProducts()
  const { terminalsData, buyerTerminalOptions, sellerTerminalOptions } =
    useQueryTerminals()

  const calcPaidAmt = useCallback(
    (billLineId: number) => {
      const paymentPayables = paymentPayablesData.filter(
        item => billLineId === item.id,
      )
      const result = paymentPayables.reduce(
        (total, { amount }) => (total += Math.abs(Number(amount))),
        0,
      )
      return result
    },
    [paymentPayablesData],
  )

  const isTableLoading = isBillLinesFetching

  const onCloseBillLineTaxesModal = () => {
    setBillLineTaxesModal({
      isOpen: false,
      billLineId: undefined,
    })
  }

  const onShowBillLineTax = (billLineId: number) => () => {
    setBillLineTaxesModal({
      isOpen: true,
      billLineId,
    })
  }

  const onShowPricingCreateForm = (billLine: IBillLine) => () => {
    setPricingForm({
      isOpen: true,
      formData: undefined,
      billLineData: billLine,
    })
  }

  const onClosePricingForm = () => {
    setPricingForm({
      isOpen: false,
      formData: undefined,
      billLineData: undefined,
    })
  }

  const onShowPricings = (billLine: IBillLine) => () => {
    setPricingsListModal({
      isOpen: true,
      billLine,
    })
  }

  const onCloseLoadExtrasModal = () => {
    setLoadExtrasModal({
      isOpen: false,
      billLine: undefined,
    })
  }

  const onClosePricings = () => {
    setPricingsListModal({
      isOpen: false,
      billLine: undefined,
    })
  }

  const onClosePriceChangeModal = () => {
    setPriceChangeModal(prev => ({
      isOpen: false,
      formData: undefined,
      pricing: prev.pricing,
    }))
  }

  const onOpenPriceChangeModal = (formData?: any, pricing?: IPricing) => {
    setPriceChangeModal({
      isOpen: true,
      formData,
      pricing,
    })
  }

  const filterOptions = useMemo(
    () => [
      {
        field: 'lineDate',
        label: 'Date',
        type: EFieldType.dateRange,
      },
      {
        field: 'buyerTerminalId',
        label: 'Buyer Terminal',
        type: EFieldType.multipleSelect,
        options: buyerTerminalOptions,
      },
      {
        field: 'sellerProductId',
        label: 'Product',
        type: EFieldType.multipleSelect,
        options: sellerProductOptions,
      },
      {
        field: 'qty',
        label: 'Quantity',
        type: EFieldType.number,
      },
      {
        field: 'price',
        label: 'Price',
        type: EFieldType.number,
      },
      {
        field: 'total',
        label: 'Total',
        type: EFieldType.number,
      },
      {
        field: 'tax',
        label: 'Tax',
        type: EFieldType.number,
      },
      {
        label: 'Ext. Price',
        field: 'materialExtPrice',
        type: EFieldType.number,
      },
      {
        field: 'tagId',
        label: 'Tag',
        type: EFieldType.multipleSelect,
        options: tagOptions,
      },
      {
        field: 'unmatchedFilter',
        label: 'Match',
        type: EFieldType.singleSelect,
        options: MATCH_OPTIONS,
      },
    ],
    [buyerTerminalOptions, sellerProductOptions, tagOptions],
  )

  const updatePricing = async (
    rowId: number,
    value: any,
    billLine: IBillLine,
  ) => {
    try {
      const updateAllBillLines = changingPriceTypeRef.current === 'all'
      if (updateAllBillLines) {
        const billLine = billLinesData.find(({ id }) => id === rowId)
        const billLinesWithSameProduct = billLinesData.filter(
          ({ sellerProductId, id }) =>
            sellerProductId === billLine?.sellerProductId &&
            id !== billLine?.id,
        )
        const apis = billLinesWithSameProduct.map(({ id }) =>
          apiClient.billLines.update(id, {
            manualPrice: value,
            runInline: true,
          }),
        )
        if (billLine) {
          apis.push(
            apiClient.billLines.update(billLine.id, {
              manualPrice: value,
              runInline: true,
            }),
          )
        }
        await Promise.all(apis)
      } else if (changingPriceTypeRef.current === 'updatePricing') {
        const pricing = findPricingDataById(billLine?.priceableId)
        onOpenPriceChangeModal(
          {
            value,
          },
          pricing,
        )
      } else if (changingPriceTypeRef.current === 'createPricing') {
        setPricingForm({
          isOpen: true,
          formData: {
            hierarchableAttributes: {
              amount: value,
            },
          } as any,
          billLineData: billLine,
        })
      } else {
        await apiClient.billLines.update(rowId, {
          manualPrice: value,
          runInline: true,
        })
      }

      //reset value when the process is done
      changingPriceTypeRef.current = 'only'
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }

  const invoiceDate = useMemo(() => {
    if (invoice?.startDate && invoice?.endDate) {
      if (invoice?.startDate === invoice?.endDate) {
        return invoice?.startDate
      }
      return undefined
    }
  }, [invoice])

  const onCellEditEnd = async (
    value: any,
    cell: RTCell<IBillLine>,
    opt: ICommonOption,
  ) => {
    try {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id

      if (opt) {
        const response = await apiClient.billLines.update(rowId, {
          [columnField]: opt.value,
          runInline: true,
        })
        updateBillLine(response.billLine.id, response.billLine)
      } else if (columnField === 'price') {
        const billLineWithTheSameProd = billLinesData.filter(
          ({ sellerProductId }) =>
            sellerProductId === row.original.sellerProductId,
        )
        await confirmation({
          message: 'Change this price for: ',
          onChangeRadio(event, value) {
            changingPriceTypeRef.current = value
          },
          showRadio: true,
          radioName: 'changeBillLinePrice',
          radioOptions: [
            {
              label: 'Only this bill line',
              value: 'only',
              defaultChecked: true,
            },
            {
              label: (
                <div>
                  All bill lines with product:{' '}
                  <div>
                    <strong>
                      {buildObjectName({
                        code: row.original.productCode,
                        name: row.original.productName,
                      })}
                    </strong>
                  </div>
                </div>
              ),
              value: 'all',
              hide: billLineWithTheSameProd.length <= 1,
            },
            {
              label: 'Update Pricing',
              value: 'updatePricing',
              hide: !row.original.priceableId,
            },
            {
              label: 'Create Pricing',
              value: 'createPricing',
              hide: Boolean(row.original.priceableId),
            },
          ],
          buttons: [
            {
              action: EYesNo.Yes,
              text: 'Submit',
              color: 'primary',
            },
          ],
        })
        await updatePricing(rowId, value, row.original)
      } else {
        let field = columnField
        if (columnField === 'qty') {
          field = 'manualQty'
        }
        const response = await apiClient.billLines.update(rowId, {
          [field]: value,
          runInline: true,
        })
        updateBillLine(response.billLine.id, response.billLine)
      }
      refetchFlagsData()
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    }
  }

  const updateProductCodeAndName = useCallback(
    async (billLine: IBillLine, product: ISellerProduct) => {
      if (!billLine.sellerProductId) {
        await apiClient.billLines.update(billLine.id, {
          productCode: product.code,
          productName: product.name,
        })
      } else {
        await confirmation({
          message: 'Do you want to update:',
          onSelectCheckbox(event, value: any) {
            productFieldsUpdate.current = produce(
              productFieldsUpdate.current,
              draft => {
                const index = draft.indexOf(value)
                if (index === -1) {
                  draft.push(value)
                } else {
                  draft.splice(index, 1)
                }
              },
            )
          },
          showCheckboxes: true,
          checkboxOptions: [
            {
              label: (
                <div>
                  Product Code to <strong>{product.code}</strong>
                </div>
              ),
              value: 'productCode',
              defaultChecked: true,
            },
            {
              label: (
                <div>
                  Product Name to <strong>{product.name}</strong>
                </div>
              ),
              value: 'productName',
              defaultChecked: true,
            },
          ],
        })

        const payload: Partial<IBillLine> = {}
        if (productFieldsUpdate.current.includes('productCode')) {
          payload.productCode = product.code
        }

        if (productFieldsUpdate.current.includes('productName')) {
          payload.productName = product.name
        }

        if (Object.keys(payload).length) {
          await apiClient.billLines.update(billLine.id, payload as any)
        }

        productFieldsUpdate.current = ['productCode', 'productName']
      }
    },
    [confirmation],
  )

  const onChangeBillLineProduct = useCallback(
    (cell: RTCell<IBillLine>) =>
      async (event: any, { value }: any) => {
        const { row } = cell
        const rowData = row.original

        if (rowData.loadExtraId) {
          await apiClient.loadExtras.update(rowData.loadExtraId, {
            sellerProductId: value,
            runInline: true,
          })
        } else if (rowData.autoChargeId) {
          await apiClient.billLines.update(rowData.id, {
            sellerProductId: value,
            runInline: true,
          })
        } else {
          await apiClient.loads.update(rowData.loadId, {
            sellerProductId: value,
          })
          const sp = sellerProducts.find(({ id }) => id === value)
          if (sp) {
            if (rowData.loadId) {
              await apiClient.loads.update(rowData.loadId, {
                sellerProductId: sp.id,
              })
            } else {
              await apiClient.billLines.update(rowData.id, {
                sellerProductId: sp.id,
              })
              await updateProductCodeAndName(rowData, sp)
            }
          }
        }

        const billLine = await apiClient.billLines.getById(rowData.id)
        updateBillLine(billLine.id, billLine)
      },
    [sellerProducts, updateBillLine, updateProductCodeAndName],
  )

  const columns: IRTColumnDef<IBillLine>[] = [
    {
      header: 'Date',
      accessorKey: 'lineDate',
      size: 150,
      align: 'center',
      enableEditing: canEditCell,
      editVariant: EFieldType.date,
      editDateFieldProps({ row }) {
        if (row.id === ERTDisplayColumnId.create) {
          return {
            value: invoiceDate,
          }
        }
        return {}
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const ids = _.uniq(
          subRows.map(({ original }) => original.lineDate).filter(Boolean),
        ).length
        return ids
      },
      Footer({ table }) {
        const { minDate, maxDate } = findMinMaxByField(
          table.options.data,
          'lineDate',
        )
        const label =
          minDate === maxDate
            ? minDate
            : getTitle({
                startDate: minDate,
                endDate: maxDate,
              })
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            <div style={{ display: 'inline-block' }}>{label}</div>
          </div>
        )
      },
    },
    {
      header: 'Ticket #',
      accessorKey: 'ticketNum',
      size: 120,
      align: 'center',
      enableEditing: canEditCell,
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const ids = _.uniq(
          subRows.map(({ original }) => original.ticketNum).filter(Boolean),
        ).length
        return ids
      },
      Footer({ table }) {
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getUniqueCountByField(table.options.data, 'ticketNum')}{' '}
              {pluralize(
                'Ticket',
                getUniqueCountByField(table.options.data, 'ticketNum'),
              )}
            </div>
          </div>
        )
      },
    },
    {
      header: 'Product Code',
      accessorKey: 'productCode',
      className: canEditCell ? 'canEdit' : '',
      size: 200,
      editDropdownFieldProps() {
        return {
          formatOptionLabel: ({ item }: any) => item?.code,
        }
      },
      Cell({ cell, table, row }) {
        const cellValue = cell.getValue<string>()
        const sellerProductId = row.original.sellerProductId
        const sellerProd = findSellerProductById(sellerProductId)
        const [shouldFetch, setShouldFetch] = useState(false)

        const {
          sellerProductOptions: options,
          isLoading,
          addAllTerminalsSellerProduct,
          updateSellerProduct,
        } = useSellerProductsHierarchyOptions(
          {
            buyerId: invoice?.buyerId,
            buyerTerminalId: invoice?.buyerTerminalId ?? undefined,
            sellerId: invoice?.sellerId,
            sellerTerminalId: invoice?.sellerTerminalId ?? undefined,
          },
          { enabled: Boolean(shouldFetch) },
        )

        if (row.id === ERTDisplayColumnId.create) {
          return (
            <RTEditCellSingleSelectField
              cell={cell}
              table={table}
              options={options}
              className='w-100'
              onChange={() => {
                setForceRender(prev => prev + 1)
              }}
            />
          )
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {canEditCell ? (
              <RTEditCellTextField cell={cell} table={table} />
            ) : (
              <span>{cellValue}</span>
            )}
            <DropdownWithCustomChildren
              className='make-custom-dropdown-inline no-hover'
              options={options}
              value={sellerProductId}
              isLoading={isLoading}
              onChange={onChangeBillLineProduct(cell)}
              menuPortalTarget={document.body}
              canSelect={canEditCell}
              showCreateButton
              showUpdateButtons
              onMenuOpen={() => setShouldFetch(true)}
              onMenuClose={() => setShouldFetch(false)}
              renderForm={({ isOpen, onCloseForm, optionSelected }) => (
                <DialogSellerProductForm
                  isOpen={isOpen}
                  onClose={onCloseForm}
                  formData={optionSelected?.item}
                  afterCreate={item => {
                    addAllTerminalsSellerProduct(item)
                  }}
                  afterUpdate={item => {
                    updateSellerProduct(item.id, item)
                  }}
                />
              )}
            >
              <ToolTipOverlay
                content={buildObjectName(sellerProd)}
                placement='top'
              >
                <span
                  style={{
                    marginLeft: 6,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                >
                  <CheckMarkIcon
                    color={
                      sellerProd
                        ? sellerProd.code === cellValue
                          ? 'var(--bs-success)'
                          : 'var(--bs-orange)'
                        : 'var(--bs-gray-500)'
                    }
                  />
                </span>
              </ToolTipOverlay>
            </DropdownWithCustomChildren>
          </div>
        )
      },
      Footer({ table }) {
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getUniqueCountByField(table.options.data, 'productCode')}{' '}
              {pluralize(
                'Product Code',
                getUniqueCountByField(table.options.data, 'productCode'),
              )}
            </div>
          </div>
        )
      },
    },
    {
      header: 'Product Name',
      accessorKey: 'productName',
      className: canEditCell ? 'canEdit' : '',
      size: 180,
      Cell({ cell, row, table }) {
        const cellValue = cell.getValue<string>()
        const sellerProductId = row.original.sellerProductId
        const sellerProd = findSellerProductById(sellerProductId)
        const [shouldFetch, setShouldFetch] = useState(false)

        const {
          sellerProductOptions: options,
          isLoading,
          addAllTerminalsSellerProduct,
          updateSellerProduct,
        } = useSellerProductsHierarchyOptions(
          {
            buyerId: invoice?.buyerId,
            buyerTerminalId: invoice?.buyerTerminalId ?? undefined,
            sellerId: invoice?.sellerId,
            sellerTerminalId: invoice?.sellerTerminalId ?? undefined,
          },
          { enabled: Boolean(shouldFetch) },
        )

        if (row.id === ERTDisplayColumnId.create) {
          const prod = sellerProducts.find(
            ({ code }) => code === row._valuesCache.productCode,
          )
          return <div>{prod?.name}</div>
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {canEditCell ? (
              <RTEditCellTextField cell={cell} table={table} />
            ) : (
              <span>{cellValue}</span>
            )}
            <DropdownWithCustomChildren
              className='make-custom-dropdown-inline no-hover'
              options={options}
              isLoading={isLoading}
              value={sellerProductId}
              onChange={onChangeBillLineProduct(cell)}
              menuPortalTarget={document.body}
              canSelect={canEditCell}
              onMenuOpen={() => setShouldFetch(true)}
              onMenuClose={() => setShouldFetch(false)}
              showCreateButton
              showUpdateButtons
              renderForm={({ isOpen, onCloseForm, optionSelected }) => (
                <DialogSellerProductForm
                  isOpen={isOpen}
                  onClose={onCloseForm}
                  formData={optionSelected?.item}
                  afterCreate={item => {
                    addAllTerminalsSellerProduct(item)
                  }}
                  afterUpdate={item => {
                    updateSellerProduct(item.id, item)
                  }}
                />
              )}
            >
              <ToolTipOverlay
                content={buildObjectName(sellerProd)}
                placement='top'
              >
                <span
                  style={{
                    marginLeft: 6,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                >
                  <CheckMarkIcon
                    color={
                      sellerProd
                        ? sellerProd.name === cellValue
                          ? 'var(--bs-success)'
                          : 'var(--bs-orange)'
                        : 'var(--bs-gray-500)'
                    }
                  />
                </span>
              </ToolTipOverlay>
            </DropdownWithCustomChildren>
          </div>
        )
      },
      Footer({ table }) {
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getUniqueCountByField(table.options.data, 'productName')}{' '}
              {pluralize(
                'Product Name',
                getUniqueCountByField(table.options.data, 'productName'),
              )}
            </div>
          </div>
        )
      },
    },
    {
      header: 'qty',
      accessorKey: 'qty',
      size: 90,
      align: 'center',
      enableEditing: canEditCell,
      editVariant: EFieldType.number,
      editNumberFieldProps: () => ({
        decimalScale: 2,
        fixedDecimalScale: true,
        onChange: () => {
          setForceRender(prev => prev + 1)
        },
      }),
      Cell({ cell }) {
        const cellValue = cell.getValue<string>()
        return Number(cellValue)
      },
      Footer: ({ table }) => {
        const totalQty = table.options.data.reduce(
          (total, { qty }) => (total += Number(qty)),
          0,
        )
        return <span>{Number(totalQty)}</span>
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.qty)),
          0,
        )

        return result
      },
    },
    {
      header: 'Price',
      accessorKey: 'price',
      size: 110,
      className: canEditCell ? 'canEdit' : '',
      editNumberFieldProps: () => ({
        decimalScale: 2,
        fixedDecimalScale: true,
      }),
      Cell({ cell, table, row: { original, id } }) {
        const cellValue = cell.getValue<string>()
        const pricing = findPricingDataById(original.priceableId)
        const isPricingDiffPrice = pricing
          ? Number(pricing.amount) != Number(cellValue)
          : false
        const priceIsZero = Number(cellValue) === 0 && !original.priceableId

        if (id === ERTDisplayColumnId.create) {
          return (
            <RTEditCellNumberField
              cell={cell}
              table={table}
              onChange={() => {
                setForceRender(prev => prev + 1)
              }}
            />
          )
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <ToolTipOverlay
              placement='top'
              allowToShow={Boolean(pricing)}
              content={`Pricing: ${formatCurrencyToDollar.format(
                pricing?.amount ? Number(pricing?.amount) : 0,
              )}`}
            >
              <div>
                <RTEditCellNumberField
                  cell={cell}
                  table={table}
                  prefix='$'
                  className={clsx({
                    isReadOnly: !canEditCell,
                    'color-red': isPricingDiffPrice || priceIsZero,
                    bold: priceIsZero,
                  })}
                />
              </div>
            </ToolTipOverlay>
            {original.priceableId ? null : (
              <span
                className='clickable'
                onClick={onShowPricingCreateForm(original)}
              >
                <PlusIcon />
              </span>
            )}
            <ToolTipOverlay placement='top' content='View Pricings'>
              <span className='clickable' onClick={onShowPricings(original)}>
                <DollarIcon />
              </span>
            </ToolTipOverlay>
          </div>
        )
      },
      Footer: ({ table }) => {
        const data = table.options.data
        const totalPrice = data
          .filter(({ priceableId }) => !priceableId)
          .reduce((total, { price }) => (total += Number(price)), 0)
        const totalPrice2 = data
          .filter(({ priceableId }) => priceableId)
          .reduce((total, { priceableId }) => {
            const pricing = findPricingDataById(priceableId)
            if (pricing) {
              return (total += Number(pricing.amount))
            }
            return total
          }, 0)
        return formatCurrencyToDollar.format(totalPrice + totalPrice2)
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const countQty = subRows.reduce(
          (sum, { original }) => (sum += Number(original.qty)),
          0,
        )
        const countTotal = subRows.reduce(
          (sum, { original }) => (sum += Number(original.total)),
          0,
        )
        const result = countTotal / countQty
        return formatCurrencyToDollar.format(result)
      },
    },
    {
      header: 'Net Price',
      accessorKey: 'netPrice',
      size: 120,
      align: 'right',
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        return formatCurrencyToDollar.format(cellValue)
      },
      Footer: ({ table }) => {
        const totalValue = table.options.data.reduce(
          (sum, { netPrice }) => (sum += Number(netPrice)),
          0,
        )
        return formatCurrencyToDollar.format(totalValue)
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.netPrice)),
          0,
        )
        return formatCurrencyToDollar.format(result)
      },
    },
    {
      header: 'Total',
      accessorKey: 'total',
      size: 100,
      align: 'right',
      Cell({ cell, row }) {
        if (row.id === ERTDisplayColumnId.create) {
          const result = row._valuesCache.qty * row._valuesCache.price
          return formatCurrencyToDollar.format(result)
        }
        const cellValue = cell.getValue<number>()
        return formatCurrencyToDollar.format(cellValue)
      },
      Footer: ({ table }) => {
        const totalValue = table.options.data.reduce(
          (sum, { total }) => (sum += Number(total)),
          0,
        )
        return formatCurrencyToDollar.format(totalValue)
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.total)),
          0,
        )
        return formatCurrencyToDollar.format(result)
      },
    },
    {
      header: 'Paid Amt',
      accessorKey: 'paidAmt',
      Cell({ row: { original } }) {
        const result = calcPaidAmt(original.id)
        return formatCurrencyToDollar.format(result)
      },
      size: 120,
      align: 'right',
      Footer({ table }) {
        const val = table.options.data.reduce((total, item) => {
          const paidAmt = calcPaidAmt(item.id)

          return (total += paidAmt)
        }, 0)
        return formatCurrencyToDollar.format(val)
      },
    },
    {
      header: 'Balance',
      accessorKey: 'balance',
      size: 120,
      align: 'right',
      Cell({ row: { original } }) {
        const paidAmt = calcPaidAmt(original.id)
        const result = Number(original.total) - paidAmt
        return formatCurrencyToDollar.format(result)
      },
      Footer({ table }) {
        const val = table.options.data.reduce((total, item) => {
          const paidAmt = calcPaidAmt(item.id)
          const result = Number(item.total) - paidAmt

          return (total += result)
        }, 0)
        return formatCurrencyToDollar.format(val)
      },
    },
    {
      header: 'Discount',
      accessorKey: 'discount',
      size: 120,
      align: 'right',
      enableEditing: canEditCell,
      editVariant: EFieldType.number,
      editNumberFieldProps: () => ({
        prefix: '$',
      }),
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        return formatCurrencyToDollar.format(cellValue)
      },
      Footer: ({ table }) => {
        const totalPrice = table.options.data.reduce(
          (total, { discount }) => (total += Number(discount)),
          0,
        )
        return formatCurrencyToDollar.format(totalPrice)
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.discount)),
          0,
        )
        return formatCurrencyToDollar.format(result)
      },
    },
    {
      header: 'Cost Code',
      accessorKey: 'costCodeId',
      size: 150,
      Cell({ row }) {
        const rowData = row.original

        return (
          <CostCodeOverlay
            billLine={rowData}
            invoice={invoice}
            canEdit={!isSeller && canEditCell}
            onChangeDropdown={async ({ name, selectedValue }: any) => {
              if (name === 'productGroup') {
                await apiClient.sellerProducts.update(rowData.sellerProductId, {
                  productGroupId: selectedValue.value,
                })
                refetchQueryBillLines()
              } else {
                const response = await apiClient.billLines.update(rowData.id, {
                  [name]: selectedValue.value,
                  runInline: true,
                })
                updateBillLine(response.billLine.id, response.billLine)
              }
            }}
            buyerTerminals={buyerTerminalOptions}
            sellerProducts={sellerProducts.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            productGroupsList={productGroupOptions}
          >
            {rowData.costCode || <span className='error'>Not set</span>}
          </CostCodeOverlay>
        )
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const ids = _.uniq(
          subRows.map(({ original }) => original.costCodeId).filter(Boolean),
        ).length
        return ids
      },
    },
    {
      header: 'Buyer Terminal',
      accessorKey: 'buyerTerminalId',
      size: 165,
      enableEditing: canEditCell,
      editVariant: EFieldType.singleSelect,
      editSelectOptions: buyerTerminalOptions,
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const ids = _.uniq(
          subRows
            .map(({ original }) => original.buyerTerminalId)
            .filter(Boolean),
        ).length
        return ids
      },
      Footer({ table }) {
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getUniqueCountByField(table.options.data, 'buyerTerminalId')}{' '}
              {pluralize(
                'Buyer Terminal',
                getUniqueCountByField(table.options.data, 'buyerTerminalId'),
              )}
            </div>
          </div>
        )
      },
    },
    {
      header: 'Ext. Price',
      accessorKey: 'materialExtPrice',
      size: 170,
      align: 'right',
      enableEditing: canEditCell,
      editVariant: EFieldType.number,
      editNumberFieldProps: () => ({
        prefix: '$',
      }),
      Cell({ cell }) {
        const cellValue = cell.getValue<number>()
        return formatCurrencyToDollar.format(cellValue)
      },
      Footer: ({ table }) => {
        const totalValue = table.options.data.reduce(
          (sum, { materialExtPrice }) => (sum += Number(materialExtPrice)),
          0,
        )
        return formatCurrencyToDollar.format(totalValue)
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.materialExtPrice)),
          0,
        )
        return formatCurrencyToDollar.format(result)
      },
    },
    {
      header: 'Other Charges',
      accessorKey: 'otherCharges',
      size: 155,
      align: 'right',
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.otherCharges)),
          0,
        )
        return result
      },
    },
    {
      header: 'Tax',
      accessorKey: 'tax',
      size: 90,
      Cell({ cell, row }) {
        const cellValue = cell.getValue<number>()
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span>{formatCurrencyToDollar.format(cellValue)}</span>
            <ToolTipOverlay placement='top' content='View Bill Line Tax'>
              <span
                className='clickable'
                onClick={onShowBillLineTax(row.original.id)}
              >
                <EyeIcon />
              </span>
            </ToolTipOverlay>
          </div>
        )
      },
      Footer: ({ table }) => {
        const totalValue = table.options.data.reduce(
          (sum, { tax }) => (sum += Number(tax)),
          0,
        )
        return formatCurrencyToDollar.format(totalValue)
      },
      AggregatedCell({ row }) {
        const { subRows = [] } = row
        const result = subRows.reduce(
          (sum, { original }) => (sum += Number(original.tax)),
          0,
        )
        return formatCurrencyToDollar.format(result)
      },
    },
  ]

  const invoicesColumns = useMemo<IRTColumnDef<IInvoice>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'num',
        size: 80,
      },
      {
        header: 'Buyer Terminal',
        accessorKey: 'buyerTerminalId',
        size: 200,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const terminal = terminalsData.find(({ id }) => id === cellValue)
          return (
            <ToolTipOverlay content={terminal?.name} placement='top'>
              <span>{terminal?.code}</span>
            </ToolTipOverlay>
          )
        },
      },
      {
        header: 'Qty',
        id: 'qty',
        align: 'right',
        size: 80,
      },
      {
        header: 'Total',
        id: 'total',
        align: 'right',
        size: 80,
        accessorFn(originalRow) {
          return formatCurrencyToDollar.format(+originalRow.total)
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 100,
      },
    ],
    [terminalsData],
  )

  const getExpandedRowState = useCallback((rowData: IBillLine) => {
    const isCompareBillLine = rowData.invoiceType == 'Invoice' ? false : true

    const match = isCompareBillLine ? 'bill_line_compared' : 'matched'

    return match
  }, [])

  const getMatchIconAndColor = useCallback(
    (state: ReturnType<typeof getExpandedRowState>, rowData: IBillLine) => {
      let Icon: any
      let color: any
      let tooltip: any
      switch (state) {
        case 'bill_line_compared':
          Icon = BookIcon
          color = 'secondary'
          tooltip = rowData.loadId ? `View LD #${rowData.loadId}` : ''
          break
        case 'matched':
          Icon = LinkIcon
          color = 'success'
          tooltip = 'Matched'
          break
      }
      return { Icon, color, tooltip }
    },
    [],
  )

  const onCreateBillLine = async ({
    exitCreatingMode,
    values,
    defaultValues,
  }: {
    exitCreatingMode: () => void
    values?: any
    defaultValues?: any
  }) => {
    setIsCreatingBillLine(true)
    try {
      const lineDate = values.lineDate || invoiceDate
      await billLineSchema.validate({
        ...values,
        lineDate,
        buyerTerminalId:
          values.buyerTerminalId || defaultValues?.buyerTerminalId,
      })
      const product = sellerProducts.find(
        ({ code }) => code === values.productCode,
      ) as ISellerProduct

      const response = await apiClient.billLines.create({
        billLine: {
          qty: values.qty,
          price: values.price,
          productCode: product.code,
          productName: product.name,
          invoiceId: invoice.id,
          lineDate: format(new Date(lineDate), 'yyyy-MM-dd'),
          buyerTerminalId:
            values.buyerTerminalId || defaultValues?.buyerTerminalId,
          sellerTerminalId: invoice?.buyerTerminalId,
          ticketNum: values.ticketNum,
          sellerProductId: product.id,
        },
      })
      if (response.errors?.length > 0) {
        toast.error(response.errors[0].message)
      } else {
        addBillLine(response)
        refetchInvoiceData()
      }
      exitCreatingMode()
    } catch (error: any) {
      if (error.message) {
        toast.error(error.message)
      } else {
        toast.error(toastMessages.createError)
      }
    } finally {
      setIsCreatingBillLine(false)
    }
  }

  const onMoveBillLines = async () => {
    setAreBillLinesMoving(true)
    try {
      const apis = billLineIdsSelected.map(id =>
        apiClient.billLines.update(id, {
          invoiceId: invoiceIdSelected,
          runInline: true,
        }),
      )
      if (apis.length > 0) {
        await Promise.all(apis)
      }
      refetchQueryInvoices()
      refetchQueryBillLines()
      setIsShowMovingBillLines(false)
      setRowSelection({})
      toast.success(toastMessages.updateSuccess)
      const result = await confirmation({
        message: 'Open new invoice?',
      })

      if (result === EYesNo.Yes) {
        window.location.href = `/invoices/${invoiceIdSelected}`
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setAreBillLinesMoving(false)
    }
  }

  const onDeleteBillLines = async () => {
    try {
      const apis = billLineIdsSelected.map(id => apiClient.billLines.delete(id))
      if (apis.length > 0) {
        await Promise.all(apis)
      }
      refetchQueryInvoices()
      refetchQueryBillLines()
      setRowSelection({})
      toast.success(toastMessages.deleteSuccess)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    }
  }

  const onToggleLoading = useCallback((id: number) => {
    setDeletingIds(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(id)
        if (index === -1) {
          draft.push(id)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const onDeleteBillLine = useCallback(
    async (rowData: IBillLine) => {
      onToggleLoading(rowData.id)
      try {
        const result = await confirmation({
          message: 'Are you sure you want to delete this bill line?',
        })
        if (result === EYesNo.Yes) {
          const response = await apiClient.billLines.delete(rowData.id, {
            runInline: true,
          })
          if (response.deletedAt) {
            refetchInvoiceData({ fetching: true })
            removeBillLine(response.id)
            toast.success(toastMessages.deleteSuccess)
          } else {
            toast.error(toastMessages.deleteError)
          }
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        onToggleLoading(rowData.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeBillLine],
  )

  const onFilterChange = (filter: any) => {
    dispatch(billLinesSlice.actions.updateUiFilter(filter))
  }

  const afterMatchingBillLines = useCallback(
    (billLine: IBillLine) => {
      updateBillLine(billLine.id, billLine)
    },
    [updateBillLine],
  )

  const onCloseUnmatchedBillLinesModal = useCallback(() => {
    setUnmatchedBillLinesModal({
      isOpen: false,
      billLine: undefined,
    })
  }, [])

  const renderLoadDetailsSection = useCallback(() => {
    if (loadDetailsModal.billLine) {
      const data = loadDetailsModal.billLine

      const expandedRowState = getExpandedRowState(data)
      if (expandedRowState === 'bill_line_compared' && data.loadId) {
        return <TicketView loadId={data.loadId} />
      }

      if (expandedRowState === 'matched' && data.billLineId) {
        return (
          <MatchedBillLineRow invoice={invoice} billLineId={data.billLineId} />
        )
      }

      return undefined
    }

    return undefined
  }, [getExpandedRowState, invoice, loadDetailsModal.billLine])

  const billLinesActions = useMemo(
    () => [
      {
        label: 'Export to CSV',
        icon: CsvIcon,
        onClick() {
          apiClient.invoices.exportCsv(`&invoice_id=${invoice.id}`)
        },
      },
      {
        label: 'View Custom Model in Parser',
        icon: OpenIcon,
        onClick() {
          const url = createInvoiceParserUrl(invoice.uid)
          window.open(url)
        },
        isHidden: !invoice?.uid || !isParserImpra(invoice.parserName),
      },
      {
        label: 'View Custom Model in Butler',
        icon: OpenIcon,
        onClick() {
          const url = `${BUTLER_URL}/${invoice.modelId}`
          window.open(url)
        },
        isHidden: !isParserButler(invoice?.parserName),
      },
      {
        label: 'View OCR JSON',
        isHidden:
          typeof invoice?.ocrJson === 'string'
            ? invoice?.ocrJson === '{}'
            : Object.keys(invoice?.ocrJson || {}).length === 0,
        icon: InfoIcon,
        onClick() {
          setIsShowOcrModal(true)
        },
      },
      {
        label: 'Download File',
        icon: DownloadIcon,
        onClick() {
          const filename = getFileNameFromAWSLink(
            invoice?.proveUrl,
            `Invoice_${invoice.id}`,
          )
          downloadFile(invoice?.proveUrl, filename || '')
        },
        isHidden: !invoice?.proveUrl,
      },
      {
        label: 'Delete Invoice',
        icon: DeleteIcon,
        isHidden: !isSeller || !canEditCell,
        async onClick() {
          try {
            const action = await confirmation({
              message: `Do you want to delete invoice #${invoice.id}?`,
            })
            if (action === EYesNo.Yes) {
              apiClient.invoices.delete(invoice.id)
              router.push('/invoices')
            }
          } catch (error) {
            console.log('error', error)
            toast.error(toastMessages.createError)
          }
        },
        color: 'var(--ion-color-danger)',
      },
    ],
    [confirmation, downloadFile, invoice, canEditCell, isSeller, router],
  )

  const afterCreatePricing = async (
    hierarchyRow: IHierarchyRow,
    pricing: IPricing,
  ) => {
    const billLinesHavingSameProduct = billLinesData.filter(
      ({ sellerProductId }) => sellerProductId === pricing.sellerProductId,
    )
    if (billLinesHavingSameProduct.length > 0) {
      refetchPricingsData()
      const { billLines } = await apiClient.billLines.multipleUpdate({
        billLine: {
          runInline: true,
        },
        ids: billLinesHavingSameProduct.map(({ id }) => id),
      })

      billLines.forEach(billLine => {
        updateBillLine(billLine.id, billLine)
      })
      onClosePricingForm()
    }
  }

  const onCloseLoadDetailsModal = () => {
    setLoadDetailsModal({
      isOpen: false,
      billLine: undefined,
    })
  }

  const onOpenAutoChargeForm = (autoChargeId: number | null) => async () => {
    setIsFetchingAutoCharge(true)
    try {
      if (autoChargeId) {
        const response = await apiClient.autoCharges.getById(autoChargeId)
        setAutoChargeForm({
          isOpen: true,
          formData: response,
        })
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsFetchingAutoCharge(false)
    }
  }

  const onCloseAutoChargeForm = () => {
    setAutoChargeForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const renderRowActions = useCallback(
    ({ row }: { row: RTRow<IBillLine> }) => {
      const rowData = row.original

      const flags = flagsData.filter(
        ({ flagableId }) => flagableId === rowData.id,
      )
      const billLineTotal = Number(rowData.total || 0)

      const ext = roundNumber(
        Number(rowData.qty || 0) * Number(rowData.price || 0),
      )

      const isExtDiff = ext !== Number(rowData.ext || 0)

      const sumExtTax = roundNumber(ext + Number(rowData.tax || 0))

      const isTotalDiff = sumExtTax !== billLineTotal

      const flagOverlay = flags.map((flag, index) => (
        <div key={flag.id}>
          <p
            style={{
              color: '#d33939',
              display: 'inline-block',
              fontSize: '15px',
              marginBottom: 1,
              maxWidth: 490,
            }}
          >
            <strong>{index + 1}: </strong>
            {`${flag.field}: ${flag.message}${
              flag.difference ? ' - Difference: ' + flag.difference : ''
            }`}
          </p>
        </div>
      ))

      if (isExtDiff) {
        flagOverlay.push(
          <div key='concord'>
            <p
              style={{
                color: '#d33939',
                display: 'inline-block',
                fontSize: '15px',
                marginBottom: 1,
                maxWidth: 490,
              }}
            >
              <div>Qty x Price does not equal Ext.</div>
              <ul style={{ marginBottom: 0 }}>
                <li>Qty x Price: {ext}</li>
                <li>Ext: {rowData.ext}</li>
              </ul>
            </p>
          </div>,
        )
      }

      if (isTotalDiff) {
        flagOverlay.push(
          <div key='concord'>
            <p
              style={{
                color: '#d33939',
                display: 'inline-block',
                fontSize: '15px',
                marginBottom: 1,
                maxWidth: 490,
              }}
            >
              <div>Ext. + Tax does not equal Total.</div>
              <ul style={{ marginBottom: 0 }}>
                <li>Ext + Tax: {sumExtTax}</li>
                <li>Total: {billLineTotal}</li>
              </ul>
            </p>
          </div>,
        )
      }

      const expandedRowState = getExpandedRowState(rowData)
      const { Icon, color, tooltip } = getMatchIconAndColor(
        expandedRowState,
        rowData,
      )

      return [
        {
          icon: <Icon color='white' />,
          color: color,
          tooltipProps: {
            content: tooltip,
            placement: 'top',
          },
          isHidden: !rowData.loadId,
          onClick() {
            setLoadDetailsModal({
              isOpen: true,
              billLine: rowData,
            })
          },
        },
        {
          icon: <ClockIcon size={14} color='white' />,
          color: 'dark',
          isHidden: !rowData.autoChargeId,
          tooltipProps: {
            content: 'Auto Charge',
            placement: 'top',
          },
          onClick: onOpenAutoChargeForm(rowData.autoChargeId),
          isLoading: isFetchingAutoCharge,
        },
        {
          icon: <ListSearchIcon color='white' />,
          color: 'secondary',
          tooltipProps: {
            content: 'Search For Matches',
            placement: 'top',
          },
          isHidden: currentScope !== EScope.buyer,
          onClick() {
            setUnmatchedBillLinesModal({
              isOpen: true,
              billLine: rowData,
            })
          },
        },
        {
          color: 'danger',
          isHidden: flagOverlay.length <= 0,
          render: () => (
            <TooltipIcon
              iconStyle={{ color: 'red' }}
              toolTip={flagOverlay}
              billLine={rowData}
              count={flagOverlay.length}
            >
              <Button variant='danger'>
                <FlagIcon color='white' />
              </Button>
            </TooltipIcon>
          ),
        },
        {
          onClick: () => {
            setLoadExtrasModal({
              isOpen: true,
              billLine: rowData,
            })
          },
          icon: <CartIcon color='white' />,
          color: rowData.loadExtraId ? 'success' : 'secondary',
          tooltipProps: {
            content: rowData.loadExtraId
              ? `Load Extra #${rowData.loadExtraId}`
              : 'Load Extras',
            placement: 'top',
          },
          isHidden: !rowData.loadId && !rowData.loadExtraId,
        },
        {
          icon: <DeleteIcon color='white' />,
          color: 'danger',
          tooltipProps: {
            content: 'Delete',
            placement: 'top',
          },
          onClick: () => onDeleteBillLine(rowData),
          isHidden: !isSeller || !canEditCell,
          isLoading: deletingIds.includes(rowData.id),
        },
      ] as IRTRowActionItem[]
    },
    [
      flagsData,
      getExpandedRowState,
      getMatchIconAndColor,
      canEditCell,
      isSeller,
      onDeleteBillLine,
      currentScope,
      isFetchingAutoCharge,
      deletingIds,
    ],
  )

  const accessorKeyMappings: Record<string, string> = {
    'ticket number': 'ticketNum',
    dates: 'lineDate',
    'buyer terminal': 'buyerTerminalId',
    'seller terminal': 'sellerTerminalId',
    quantity: 'qty',
    'product name': 'productName',
    'product code': 'productCode',
  }

  const headerMappings: Record<string, string> = useMemo(
    () => ({
      lineDate: 'Line Date',
      ticketNum: 'Ticket Number',
      buyerTerminalId: 'Buyer Terminal',
      sellerTerminalId: 'Seller Terminal',
      qty: 'Qty',
      productName: 'Product Name',
      productCode: 'Product Code',
    }),
    [],
  )

  const defaultHeaders = Object.keys(headerMappings).map(header => ({
    label: headerMappings[header],
    value: header,
  }))

  const existingHeader = billLinesCreateModal.columns.map(
    ({ accessorKey }: any) => accessorKey,
  )

  const headerOptions = useMemo(() => {
    return [
      {
        label: 'Not Used',
        options: defaultHeaders.filter(
          ({ value }) => !existingHeader.includes(value),
        ),
      },
      {
        label: 'Used',
        options: defaultHeaders.filter(({ value }) =>
          existingHeader.includes(value),
        ),
      },
    ]
  }, [defaultHeaders, existingHeader])

  const billLineCreateColumns = useMemo(
    () =>
      billLinesCreateModal.columns.map((col: any) => {
        const { accessorKey } = col
        let editVariant
        let editSelectOptions: any[] = []
        if (accessorKey === 'lineDate') {
          editVariant = EFieldType.date
        } else if (
          ['buyerTerminalId', 'sellerTerminalId'].includes(accessorKey)
        ) {
          editVariant = EFieldType.singleSelect
          if (accessorKey === 'buyerTerminalId') {
            editSelectOptions = buyerTerminalOptions
          } else {
            editSelectOptions = sellerTerminalOptions
          }
        } else {
          editVariant = EFieldType.text
        }

        return {
          ...col,
          minSize: 250,
          onChangeHeader(event: any, { value }: any) {
            const newColumns = produce(
              billLinesCreateModal.columns,
              (draft: any) => {
                const index = draft.findIndex(
                  ({ accessorKey: key }: any) => key === accessorKey,
                )
                if (index !== -1) {
                  draft[index].accessorKey = value
                  draft[index].header = headerMappings[value]
                }
              },
            )
            const newData = produce(billLinesCreateModal.data, (draft: any) => {
              draft.forEach((row: any) => {
                row[value] = row[accessorKey]
                delete row[accessorKey]
              })
            })
            setBillLinesCreateModal(prev => ({
              ...prev,
              columns: newColumns,
              data: newData,
            }))
          },
          enableEditing: true,
          editVariant,
          editSelectOptions,
          headerOptions,
        }
      }),
    [
      billLinesCreateModal.columns,
      billLinesCreateModal.data,
      buyerTerminalOptions,
      headerOptions,
      sellerTerminalOptions,
      headerMappings,
    ],
  )

  const onAddDraftColumn = (event: any, { value }: any) => {
    setBillLinesCreateModal(prev => ({
      ...prev,
      columns: [
        {
          header: headerMappings[value],
          accessorKey: value,
        },
        ...prev.columns,
      ],
    }))
  }

  const mapHeaders = (data: string[][]) => {
    if (!data.length) return []

    const toCamelCase = (str: string) =>
      str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .split(' ')
        .map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join('')

    return data[0].map(key => {
      const accessorKey =
        accessorKeyMappings[key.toLowerCase()] || toCamelCase(key)
      const header = headerMappings[accessorKey]

      const fieldConfig: any = {
        header: `${header} - ${key}`,
        Header: ({ column }: any) => {
          const [mappedHeader, originalHeader] =
            column.columnDef.header.split(' - ')
          const isHeaderDiff = originalHeader
            ? mappedHeader !== originalHeader
            : false
          return (
            <div>
              <span>
                {isHeaderDiff ? (
                  <span className=''>
                    <span style={{ color: 'var(--bs-black)' }}>
                      {mappedHeader}
                    </span>{' '}
                    <span style={{ color: 'var(--bs-gray-600)' }}>
                      ({originalHeader})
                    </span>
                  </span>
                ) : (
                  <span style={{ color: 'var(--bs-black)' }}>
                    {mappedHeader}
                  </span>
                )}
              </span>
              <DropdownWithCustomChildren
                options={column.columnDef.headerOptions || []}
                className='make-custom-dropdown-inline no-hover'
                value={column.id}
                styles={{
                  option: provided => ({
                    ...provided,
                    fontSize: 12,
                    textTransform: 'none',
                  }),
                }}
                onChange={column.columnDef.onChangeHeader}
              >
                <span
                  className='icon'
                  style={{ transform: 'rotate(90deg)', marginLeft: 8 }}
                >
                  <ArrowForwardIcon size={14} />
                </span>
              </DropdownWithCustomChildren>
            </div>
          )
        },
        accessorKey,
        enableEditing: true,
      }
      return fieldConfig
    })
  }

  const transformData = (
    data: string[][],
    mappedHeaders: { accessorKey: string }[],
  ) => {
    if (data.length < 2) return []

    return data.slice(1).map(row => {
      return mappedHeaders.reduce(
        (acc, { accessorKey }, index) => {
          let value = row[index]

          if (accessorKey === 'lineDate') {
            if (value) {
              acc[accessorKey] = moment(value).format('YYYY-MM-DD')
            } else {
              acc[accessorKey] = null
            }
          } else if (
            ['buyerTerminalId', 'sellerTerminalId'].includes(accessorKey)
          ) {
            const terminalCode = value?.split(' - ')[0]
            acc[accessorKey] =
              terminalsData.find(({ code }) => code === terminalCode)?.id ||
              null
          } else {
            acc[accessorKey] = value || null
          }

          return acc
        },
        {
          id: _.uniqueId('draft_'),
        } as Record<string, any>,
      )
    })
  }

  const getErrorMessages = (id: string | number) => {
    return error[id] || []
  }

  const onCreateDraftBillLine = (formData: any) => async () => {
    onToggleLoading(formData.id)
    try {
      const payload: any = {}
      availableDraftFields.forEach(field => {
        payload[field] = formData[field]
      })
      await billLineSchema.validate(
        {
          ...payload,
        },
        {
          abortEarly: false,
        },
      )
      const response = await apiClient.billLines.create({
        billLine: {
          ..._.omit(payload, 'id'),
          lineDate: moment(payload.lineDate).format('YYYY-MM-DD'),
          invoiceId: invoice?.id,
          sellerTerminalId:
            payload?.sellerTerminalId || invoice?.sellerTerminalId,
          buyerTerminalId: payload?.buyerTerminalId || invoice?.buyerTerminalId,
        },
      })
      if (response.errors?.length > 0) {
        //
      } else {
        // setBillLinesCreateModal({ ...billLinesCreateModal, data: [...newData] })
        // refetchQueryBillLines()
        setBillLinesCreateModal(prev =>
          produce(prev, draft => {
            const index = draft.data.findIndex(
              ({ id }: any) => id === formData.id,
            )
            draft.data.splice(index, 1)
          }),
        )
        setCountCreatedBillLines(prev => prev + 1)
        setCountRawBillLines(prev => prev - 1)
        refetchQueryBillLines()

        setDraftRowSelection(prev =>
          produce(prev, draft => {
            if (draft[formData.id]) {
              delete draft[formData.id]
            }
          }),
        )
      }
    } catch (error: any) {
      console.log('error', error)
      setError(prev =>
        produce(prev, draft => {
          draft[formData.id] = error.errors || []
        }),
      )
    } finally {
      onToggleLoading(formData.id)
    }
  }

  const getBannerError = () => {
    const selectedIds = Object.keys(draftRowSelection)
    const errorIndices: number[] = []

    selectedIds.forEach(id => {
      const rowIndex = draftBillLineIds.indexOf(id)
      if (error[id] && error[id].length > 0 && rowIndex !== -1) {
        errorIndices.push(rowIndex + 1)
      }
    })

    if (errorIndices.length === 0) {
      return ''
    }

    return `Can't create bill lines. Please fix the errors in rows ${_.orderBy(
      errorIndices,
    ).join(', ')}`
  }

  const onCreateMultiDraftBillLines = async () => {
    setIsCreatingMultiBillLines(true)
    setDraftBannerError('')
    try {
      const newBannerError = getBannerError()
      if (newBannerError) {
        setDraftBannerError(newBannerError)
        return
      }
      const payload = Object.keys(draftRowSelection)
        .map(id => {
          const data = billLinesCreateModal.data.find(
            (row: any) => row.id === id,
          )
          if (data) {
            return apiClient.billLines.create({
              billLine: {
                ..._.omit(data, 'id'),
                lineDate: moment(data.lineDate).format('YYYY-MM-DD'),
                invoiceId: invoice?.id,
                sellerTerminalId:
                  data?.sellerTerminalId || invoice?.sellerTerminalId,
                buyerTerminalId:
                  data?.buyerTerminalId || invoice?.buyerTerminalId,
              },
            })
          }
          return undefined
        })
        .filter(Boolean)

      await Promise.all(payload)
      setBillLinesCreateModal(prev =>
        produce(prev, draft => {
          Object.keys(draftRowSelection).forEach(id => {
            const index = draft.data.findIndex(
              (formData: any) => id === formData.id,
            )
            if (index !== -1) {
              draft.data.splice(index, 1)
            }
          })
        }),
      )
      setCountCreatedBillLines(
        prev => prev + Object.keys(draftRowSelection).length,
      )
      setCountRawBillLines(prev => prev - Object.keys(draftRowSelection).length)
      refetchQueryBillLines()
      setDraftRowSelection({})
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsCreatingMultiBillLines(false)
    }
  }

  const onDeleteMultiDraftBillLines = () => {
    setBillLinesCreateModal(prev =>
      produce(prev, draft => {
        Object.keys(draftRowSelection).forEach(id => {
          const index = draft.data.findIndex(
            (formData: any) => id === formData.id,
          )
          if (index !== -1) {
            draft.data.splice(index, 1)
          }
        })
      }),
    )
    setDraftRowSelection({})
  }

  const onDraftCellEditEnd = (value: any, cell: RTCell<any>) => {
    const { column, row } = cell
    const columnField = column.id
    const rowId = row.original.id
    const newData: any = produce(billLinesCreateModal.data, (draft: any) => {
      const index = draft.findIndex(({ id }: any) => id === rowId)
      if (index !== -1) {
        draft[index][columnField] = value
      }
    })
    setBillLinesCreateModal({ ...billLinesCreateModal, data: [...newData] })
  }

  useEffect(() => {
    if (!isShowMovingBillLines) {
      setInvoiceRowSelection({})
    }
  }, [isShowMovingBillLines])

  useEffect(() => {
    if (grouping.length > 0) {
      setColumnVisibility(prev => ({
        [ERTDisplayColumnId.expand]: true,
        ..._.omit(prev, [ERTDisplayColumnId.expand]),
      }))
    } else {
      setColumnVisibility(prev => ({
        ...prev,
        [ERTDisplayColumnId.expand]: false,
      }))
    }
  }, [grouping])

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const text = event.clipboardData?.getData('text')
      if (!text) return

      const rows = text
        .trim()
        .split('\n')
        .map(row => row.split('\t'))

      if (rows.length > 0) {
        const columns = mapHeaders(rows)
        const data = transformData(rows, columns)
        setDraftBillLineIds(data.map(({ id }) => id))
        const visibleColumns: VisibilityState = {
          [ERTDisplayColumnId.select]: true,
          [ERTDisplayColumnId.actions]: true,
        }
        columns.forEach(({ accessorKey }) => {
          visibleColumns[accessorKey] = true
        })
        setDraftColumnVisibility(visibleColumns)
        if (columns.length && data.length) {
          setCountRawBillLines(data.length)
          setBillLinesCreateModal({ isOpen: true, columns, data })
          setCountCreatedBillLines(0)
        }
      }
    }

    window.addEventListener('paste', handlePaste)
    return () => window.removeEventListener('paste', handlePaste)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerTerminalOptions, sellerTerminalOptions])

  useEffect(() => {
    billLinesCreateModal.data.map((row: any) => {
      try {
        billLineSchema.validateSync(row, {
          abortEarly: false,
        })
        setError(prev =>
          produce(prev, draft => {
            draft[row.id] = []
          }),
        )
      } catch (error: any) {
        setError(prev =>
          produce(prev, draft => {
            draft[row.id] = error.errors || []
          }),
        )
      }
    })
  }, [billLinesCreateModal.data, availableDraftFields])

  useEffect(() => {
    const columns = Object.keys(draftColumnVisibility)
    setDraftColumnOrder(columns)
  }, [draftColumnVisibility])

  useEffect(() => {
    if (Object.keys(draftRowSelection).length === 0) {
      setDraftBannerError('')
    }
  }, [draftRowSelection])

  useEffect(() => {
    if (filterConfigurations?.fields) {
      setGrouping(filterConfigurations?.fields)
    }
  }, [filterConfigurations?.fields])

  return {
    className,
    globalFilter,
    billLineIdsSelected,
    flagsData,
    isShowFlags,
    flagsCounter,
    billLinesData,
    invoice,
    columnVisibility,
    columnOrder,
    billLinesActions,
    isShowFilter,
    windowSize,
    filterOptions,
    columns,
    billLinesHavingFlags,
    isTableLoading,
    rowSelection,
    tableState,
    tableProps,
    isShowPdfModal,
    isShowOcrModal,
    isShowMovingBillLines,
    invoicesData,
    isLoadingInvoicesData,
    invoiceRowSelection,
    invoicesColumns,
    invoiceIdSelected,
    areBillLinesMoving,
    canEditCell,
    filterData,
    pricingForm,
    pricingsListModal,
    loadExtrasModal,
    showExtraForm,
    isBillLinesEqualInvoice,
    tableRef,
    sortOptions,
    tableSorting,
    loadDetailsModal,
    grouping,
    totalBillLines,
    unmatchedBillLinesModal,
    autoChargeForm,
    priceChangeModal,
    isCreatingBillLine,
    billLinesCreateModal,
    getErrorMessages,
    confirmation,
    downloadFile,
    deletingIds,
    billLineCreateColumns,
    countRawBillLines,
    error,
    countOfRawError,
    countCreatedBillLines,
    draftRowSelection,
    countDraftRowSelection,
    isCreatingMultiBillLines,
    headerOptions,
    draftColumnVisibility,
    draftColumnOrder,
    draftBannerError,
    draftBillLineIds,
    billLineTaxesModal,

    setDraftColumnVisibility,
    setGlobalFilter,
    setIsShowMovingBillLines,
    setIsShowFlags,
    setIsShowFilter,
    setIsShowPdfModal,
    setIsShowOcrModal,
    setColumnVisibility,
    setRowSelection,
    setInvoiceRowSelection,
    setLoadExtrasModal,
    setShowExtraForm,
    setGrouping,

    renderRowActions,
    renderLoadDetailsSection,

    onDeleteBillLines,
    onCellEditEnd,
    onMoveBillLines,
    onShowPricingCreateForm,
    onClosePricingForm,
    onOpenPriceChangeModal,
    onClosePriceChangeModal,
    afterCreatePricing,
    onClosePricings,
    onFilterChange,
    setSortOptions,
    onCloseLoadDetailsModal,
    onCloseUnmatchedBillLinesModal,
    afterMatchingBillLines,
    onCloseAutoChargeForm,
    onCloseLoadExtrasModal,
    refetchQueryBillLines,
    refetchInvoiceData,
    updateBillLine,
    updateBillLines,
    refetchPricingsData,
    onCreateBillLine,
    setBillLinesCreateModal,
    onCreateDraftBillLine,
    onDraftCellEditEnd,
    onAddDraftColumn,
    setCountRawBillLines,
    setCountCreatedBillLines,
    setDraftRowSelection,
    onCreateMultiDraftBillLines,
    onDeleteMultiDraftBillLines,
    onCloseBillLineTaxesModal,
  }
}

export default useBillLinesTable
