import { useCallback, useState } from 'react'
import { useQueryCompanies, useQueryInvoices } from '~/hooks/useQueryData'
import {
  CompanyAvatar,
  DialogCompanyForm,
  DropdownWithCustomChildren,
} from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import { useCompanyFormContext } from '~/contexts'

const InvoiceBodyHeaderBuyerCol = ({ invoice }) => {
  const [companyForm, setCompanyForm] = useState({
    isOpen: false,
    formData: undefined,
  })

  const { findCompanyById, buyerCompanyOptions, addCompany, updateCompany } =
    useQueryCompanies({})
  const { updateInvoice } = useQueryInvoices(
    {
      id: invoice?.id,
    },
    { enabled: Boolean(invoice) },
  )
  const { onOpenCompanyForm } = useCompanyFormContext()

  const buyer = findCompanyById(invoice.buyerId)

  const onCloseCompanyForm = () => {
    setCompanyForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onChangeBuyer = useCallback(
    async (event, { value }) => {
      try {
        const response = await apiClient.invoices.update(invoice.id, {
          buyerId: value,
        })
        updateInvoice(response.id, response)
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    [invoice.id, updateInvoice],
  )

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }} className='h-100'>
        <DropdownWithCustomChildren
          value={invoice.buyerId}
          onChange={onChangeBuyer}
          options={buyerCompanyOptions}
          className='no-hover make-custom-dropdown-inline h-auto'
          showCreateButton
          showUpdateButtons
          renderForm={({ isOpen, optionSelected, onCloseForm }) => {
            return (
              <DialogCompanyForm
                isOpen={isOpen}
                onClose={onCloseForm}
                formData={optionSelected?.item}
                afterCreate={company => {
                  addCompany(company)
                  onCloseCompanyForm()
                }}
                afterUpdate={company => {
                  updateCompany(company.id, company)
                  onCloseForm()
                }}
              />
            )
          }}
        >
          <div className='InvoiceBodyHeader__sellerCol'>
            <CompanyAvatar
              companyType='buyer'
              className='InvoiceBodyHeader__sellerAvatar'
              company={buyer}
              isChip
              onContextMenu={event => {
                event.preventDefault()
                setCompanyForm({
                  isOpen: true,
                  formData: buyer,
                })
              }}
            />
          </div>
        </DropdownWithCustomChildren>
        <div className='InvoiceBodyHeader__sellerInfo'>
          {buyer?.id && (
            <span
              className='hyperLink'
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                onOpenCompanyForm({
                  formData: buyer,
                  afterUpdate(company) {
                    updateCompany(company.id, company)
                  },
                })
              }}
            >
              {buyer?.name}
            </span>
          )}
        </div>
      </div>

      <DialogCompanyForm
        isOpen={companyForm.isOpen}
        formData={companyForm.formData}
        onClose={onCloseCompanyForm}
        afterCreate={company => {
          addCompany(company)
          onCloseCompanyForm()
        }}
        afterUpdate={company => {
          updateCompany(company.id, company)
          onCloseCompanyForm()
        }}
      />
    </>
  )
}
export default InvoiceBodyHeaderBuyerCol
