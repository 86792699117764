import React, { useState, useCallback } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import EditFieldValidationsForm from '~/components/company/EditFieldValidationsForm'
import { DOCUMENT_SOURCES } from '~/components/shared/DocumentPicker/_helper'
import ReferenceNamesDisplay from './ReferenceNamesDisplay'
import SellerRegexForm from './SellerRegexForm'
import clsx from 'clsx'
import { toBase64 } from '~/utils'
import { CompanySettingAutomationForm } from '../ConcordForm'

const DATA_PARSERS = [
  { label: 'Data Presence' },
  { label: 'Edit Seller' },
  { label: 'Reference Name Matching' },
  { label: 'Auto Detect Seller' },
  //{ label: 'Custom Flag Creation' },
]

const DataParsersForm = props => {
  const { doc, type = 'Load', className } = props

  const [selected, setSelected] = useState({ label: DATA_PARSERS[0].label })

  const onEditCompany = useCallback(async params => {
    const _params = {
      name: params.name,
      legalName: params.legalName,
      code: params.code,
      buyer: params.buyer,
      seller: params.seller,
      fleet: params.fleet,
      status: params.status,
      parentCompanyId:
        params.parentCompanyId == -1 ? null : params.parentCompanyId,
      invoiceInterval: params.invoiceInterval,
      dateFormat: params.dateFormat,
      invoiceCollection: params.invoiceCollection,
      ticketCollection: params.ticketCollection,
      parserType: params.parserType,
      invoiceDateFormat: params.invoiceDateFormat,
      firstNumberOfPages: params.firstNumberOfPages,
      lastNumberOfPages: params.lastNumberOfPages,
      removePageRanges: JSON.parse('[' + params.removePageRanges + ']'),
      regexToSkipBillLine: params.regexToSkipBillLine,
      billLineSortingFields: params.billLineSortingFields,
      autoCreateLoad: params.autoCreateLoad,
      autoSendToHitl: params.autoCreateLoad,
      billLineMatchingFields: params.billLineMatchingFields,
      invoiceHeaderOffset: params.invoiceHeaderOffset,
      calcDifferenceThreshhold: params.calcDifferenceThreshhold,
      blDateFormat: params.blDateFormat,
      requiredTicketActions: params.requiredTicketActions,
      useFuzzyMatching: params.useFuzzyMatching,
      createRelationsOnTheFly: params.createRelationsOnTheFly,
      autoCreateLoadMatching: params.autoCreateLoadMatching,
      timeZone: params.timeZone,
      checkForSplit: params.checkForSplit,
      excludedBillLineFields: params.excludedBillLineFields,
      expectedLoadFields: params.expectedLoadFields,
    }
    if (params.image && params.image.length > 0) {
      const avatar = params.image[0]
      if (avatar.source != DOCUMENT_SOURCES.STORAGE) {
        //DOCUMENT_SOURCES.STORAGE is original avatar => change
        if (avatar.source == DOCUMENT_SOURCES.PHOTO_LIBRARY) {
          const result = await toBase64(avatar.file)
          _params['image'] = result
        } else {
          _params['image'] = avatar.image
        }
      }
    }

    _params.invoiceExamples = await Promise.all(
      (params.invoiceExamples || []).map(async ({ file, image }) => {
        if (file instanceof File) {
          const result = await toBase64(file)
          return { url: result, trainingUse: true }
        } else {
          return { url: image }
        }
      }),
    )

    _params.loadExamples = await Promise.all(
      (params.loadExamples || []).map(async ({ file, image }) => {
        if (file instanceof File) {
          const result = await toBase64(file)
          return { url: result, trainingUse: true }
        } else {
          return { url: image }
        }
      }),
    )

    if (params.invoiceTemplate && params.invoiceTemplate.length > 0) {
      const file = params.invoiceTemplate[0]
      if (file.source == 'photoLibrary') {
        const result = await toBase64(file.file)
        _params.invoiceTemplate = result
      }
    }
    if (params.loadTemplate && params.loadTemplate.length > 0) {
      const file = params.loadTemplate[0]
      if (file.source == 'photoLibrary') {
        const result = await toBase64(file.file)
        _params.loadTemplate = result
      }
    }
  }, [])

  return (
    <Container className={clsx('DataParsersForm__root', className)}>
      <Row className='m-2 DataParsersForm__row'>
        <ButtonGroup>
          {DATA_PARSERS.map((dp, index) => (
            <Button
              key={index}
              size='lg'
              variant='outline-primary'
              onClick={() => setSelected(dp)}
              active={selected.label == dp.label}
            >
              {dp.label}
            </Button>
          ))}
        </ButtonGroup>
      </Row>
      <Row className='DataParsersForm__containerFields'>
        <Col>
          {selected.label == DATA_PARSERS[0].label && (
            <EditFieldValidationsForm companyId={doc.sellerId} type={type} />
          )}
          {selected.label == DATA_PARSERS[1].label && (
            <CompanySettingAutomationForm onSubmit={onEditCompany} />
          )}
          {selected.label == DATA_PARSERS[2].label && (
            <ReferenceNamesDisplay doc={doc} fileType={type} />
          )}
          {selected.label == DATA_PARSERS[3].label && (
            <SellerRegexForm doc={doc} />
          )}
          {/* {selected.label == DATA_PARSERS[4].label && (
            <div>Custom Flag Creation</div>
          )} */}
        </Col>
      </Row>
    </Container>
  )
}

export default DataParsersForm
