import { BillLinesTable } from '../BillLinesTable'

import PropTypes from 'prop-types'

const InvoiceTable = ({ invoice, isLoading }) => {
  return (
    <div className='invoiceTable'>
      <BillLinesTable
        invoice={invoice}
        queryParams={{
          filters: {
            invoiceId: invoice?.id,
          },
          perPage: 1000,
        }}
        state={{
          isLoading,
        }}
      />
    </div>
  )
}

InvoiceTable.propTypes = {
  billLinesColumnsCheckBox: PropTypes.arrayOf(
    PropTypes.shape({
      dataClass: PropTypes.string,
      field: PropTypes.string,
      header: PropTypes.string,
      hideable: PropTypes.bool,
      hidden: PropTypes.bool,
      label: PropTypes.string,
      size: PropTypes.string,
    }),
  ),
  invoice: PropTypes.object,
}

export default InvoiceTable
