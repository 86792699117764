import moment from 'moment'
import { ELoadFilterVisiableData } from '~/types/enums/ELoad'
import { moduleName } from '~/utils/constants'
import { dashboardDateRange } from '~/utils/dashboardUtils'

const DEFAULT_DOCUMENTS_FILTERS = {
  searchWord: '',
  status: [],
  sellerId: [],
  accessType: 'All',
  fileType: [],
  docType: [],
  createdAt: { startDate: null, endDate: null },
  date: {},
  buyerTerminalId: [],
  tagIds: [],
  flagFilters: [],
  qty: { lower: null, upper: null },
  productId: [],
}

export const DEFAULT_LOADS_FILTERS = {
  sellerProductId: [],
  buyerTerminalId: [],
  createdAt: {
    startDate: new Date(),
    endDate: new Date(),
  },
  flagFilters: [],
  fleetId: [],
  hitlStatus: [],
  loadCount: {},
  date: {},
  primaryParserStatus: [],
  sellerProductFilter: [],
  qty: {},
  searchWord: '',
  sellerId: [],
  sellerTerminalId: [],
  status: [],
  tagIds: [],
  visibleData: ELoadFilterVisiableData.rawDataIfNoMatch,
}

const DEFAULT_INVOICES_FILTER = {
  asp: null,
  buyerTerminalId: [],
  createdAt: {},
  date: {},
  hitlStatus: [],
  loadCount: null,
  parserStatus: [],
  perPage: 24,
  qty: null,
  sellerId: [],
  status: [],
  tagIds: [],
  flagFilters: null,
  endDate: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
}

const DEFAULT_INVOICE_FILTERS = {
  sellerProductId: [],
  buyerTerminalId: [],
  date: {
    endDate: null,
    startDate: null,
  },
  extPrice: {},
  price: {},
  qty: {},
  tagIds: [],
  tax: {},
  total: {},
}

const DEFAULT_BILL_LINE_SEARCH_FILTERS = {
  buyerTerminalId: [],
  sellerProductId: [],
  date: {
    startDate: null,
    endDate: null,
  },
  tagIds: [],
}

const DEFAULT_DASHBOARD_FILTERS = {
  createdAt: dashboardDateRange,
  modelType: 'document',
}

const DEFAULT_ORDER_TRACKING_FILTERS = {
  startTime: {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  },
  buyerTerminalId: [],
  sellerTerminalId: [],
  fleetId: [],
  buyerId: [],
  sellerId: [],
  status: [],
  qty: {},
  plus: [],
  sellerProductId: [],
  scheduleNumber: {},
  color: [],
  spacing: {},
}

const DEFAULT_COMPANY_PARSER_FILTER = {
  documentType: [],
  parser: [],
  internalUseOnly: null,
}

const DEFAULT_COMPANY_AUTO_CHARGE_FILTER = {
  allocatedPer: [],
  applicationLevel: [],
  sellerId: [],
}

const DEFAULT_COMPANY_BTF_STPS_FILTER = {
  sellerId: [],
}

const DEFAULT_COMPANY_EMAIL_PARSERS_FILTER = {
  docTypeValue: [],
  sellerId: [],
}

const DEFAULT_COMPANY_DATA_CORRECTION_FILTER = {
  companyId: [],
}

const DEFAULT_COMPANY_PARSER_COLUMN_MAPPING_FILTER = {
  model: [],
  companyId: [],
}

const DEFAULT_COMPANY_FIXED_PRICE_FILTER = {
  sellers: [],
  itemType: [],
  price: {},
  flatRate: {},
  buyerTerminal: [],
  sellerTerminal: [],
  buyerProduct: [],
  dateRange: {},
}

const DEFAULT_COMPANY_PRODUCT_FILTER = {
  sellerId: [],
}

const DEFAULT_COMPANY_SELLER_PRODUCT_FILTER = {
  sellerId: [],
}

const DEFAULT_COMPANY_TERMINALS_FILTER = {
  companyId: [],
}

const DEFAULT_COMPANY_REFERENCE_NAMES_FILTER = {
  buyerId: [], // number[]
  sellerId: [], // number[]
  itemType: [], // number[]
  itemId: [], // number []
  referenceName: [], // string[]
  referenceCode: [], // string[]
}

const DEFAULT_SALES_PROPOSALS_FILTERS = {
  sellerId: [],
  buyerId: [],
  sellerTerminalId: [],
  buyerTerminalId: [],
  sellerProductId: [],
  fixedPrice: {},
}

export const DEFAULT_START_TIMES_FILTERS = {
  driverId: [],
  terminalId: [],
  color: [],
  seenAt: {},
  confirmedAt: {},
  inService: 'all', // boolean | 'all'
}

export const DEFAULT_ZONES_FILTERS = {
  createdDate: {},
}

export const DEFAULT_SELLER_TO_BUYER_TERMINALS_FILTERS = {
  workTypeId: [],
  date: null,
  workForceId: [],
  areaId: [],
}

export const DEFAULT_SHIFTS_FILTERS = {
  clockIn: {},
  clockOut: {},
  driverFleetId: null,
}

const defaultModuleFilters = {
  documents: DEFAULT_DOCUMENTS_FILTERS,
  loads: DEFAULT_LOADS_FILTERS,
  invoices: DEFAULT_INVOICES_FILTER,
  billLines: DEFAULT_INVOICE_FILTERS,
  billLineSearch: DEFAULT_BILL_LINE_SEARCH_FILTERS,
  dashboard: DEFAULT_DASHBOARD_FILTERS,
  orderTracking: DEFAULT_ORDER_TRACKING_FILTERS,
  salesProposals: DEFAULT_SALES_PROPOSALS_FILTERS,
  startTimes: DEFAULT_START_TIMES_FILTERS,
  zones: DEFAULT_ZONES_FILTERS,
  sellerToBuyerTerminals: DEFAULT_SELLER_TO_BUYER_TERMINALS_FILTERS,

  [moduleName.company.parsers]: DEFAULT_COMPANY_PARSER_FILTER,
  [moduleName.company.autoCharges]: DEFAULT_COMPANY_AUTO_CHARGE_FILTER,
  [moduleName.company.btfstps]: DEFAULT_COMPANY_BTF_STPS_FILTER,
  [moduleName.company.emailParsers]: DEFAULT_COMPANY_EMAIL_PARSERS_FILTER,
  [moduleName.company.dataCorrectionMethods]:
    DEFAULT_COMPANY_DATA_CORRECTION_FILTER,
  [moduleName.company.parserColumnMappings]:
    DEFAULT_COMPANY_PARSER_COLUMN_MAPPING_FILTER,
  [moduleName.company.fixedPrices]: DEFAULT_COMPANY_FIXED_PRICE_FILTER,
  [moduleName.company.products]: DEFAULT_COMPANY_PRODUCT_FILTER,
  [moduleName.company.sellerProducts]: DEFAULT_COMPANY_SELLER_PRODUCT_FILTER,
  [moduleName.company.terminals]: DEFAULT_COMPANY_TERMINALS_FILTER,
  [moduleName.company.referenceNames]: DEFAULT_COMPANY_REFERENCE_NAMES_FILTER,
  shifts: DEFAULT_SHIFTS_FILTERS,
  default: {},
}

export default defaultModuleFilters
