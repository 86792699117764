import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { selectSessionUser } from '~/redux/selectors'
import { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { useMemo } from 'react'
import _ from 'lodash'
import isNumber from '~/utils/isNumber'
import apiService from '~/api/apiService'
import { Badge } from 'react-bootstrap'

interface IProps {
  model: string
  field: string
}

const useQueryEnums = ({ field, model }: IProps) => {
  const sessionUser: IUser = useSelector(selectSessionUser)

  const { data, isFetched, isFetching } = useQuery({
    queryKey: ['enums', sessionUser.id, field, model],
    async queryFn() {
      const response = await apiService.get(`/${model}/new`)
      return response as Record<string, Record<string, number>>
    },
    ...DEFAULT_QUERY_OPTIONS,
  })

  const typeEnums = useMemo(
    () => (data ? ((data[field] || {}) as Record<string, number>) : {}),
    [data, field],
  )

  const typeEnumsOptions = Object.keys(typeEnums).map(key => ({
    label: _.startCase(key),
    value: typeEnums[key],
  }))

  const typeEnumsOptionsWithStringValues = Object.keys(typeEnums).map(key => ({
    label: _.startCase(key),
    value: key,
  }))

  const findTypeEnum = (input: number | string) => {
    if (typeof input === 'string' && _.camelCase(input) in typeEnums) {
      return { key: _.camelCase(input), value: typeEnums[_.camelCase(input)] }
    }

    if (isNumber(input)) {
      const key = Object.keys(typeEnums).find(k => {
        return typeEnums[k].toString() === input
      })
      if (key) {
        return { key, value: input }
      }
      return {}
    }

    return {}
  }
  const getOptionsFromEnum = (
    enumInput: Record<string, number> | undefined | null,
  ) => {
    if (enumInput) {
      return Object.keys(enumInput).map(key => ({
        label: _.startCase(key),
        value: enumInput[key],
      }))
    }

    return []
  }

  const convertTypesToNumber = (
    inputs: (string | number)[] | null | undefined,
  ) => {
    if (inputs) {
      return inputs
        .map(input => {
          if (typeof input === 'string' && input in typeEnums) {
            return typeEnums[input]
          }
          const num = Number(input)
          return !isNaN(num) && Object.values(typeEnums).includes(num)
            ? num
            : -1
        })
        .filter(value => value !== -1)
    }
    return []
  }

  const convertTypesToString = (
    inputs: (string | number)[] | null | undefined,
  ) => {
    if (Array.isArray(inputs)) {
      return inputs
        .map(input => {
          if (typeof input === 'string' && input in typeEnums) {
            return input
          }
          const num = Number(input)
          return !isNaN(num)
            ? Object.keys(typeEnums).find(key => typeEnums[key] === num) || ''
            : ''
        })
        .filter(key => key !== '')
    }
    return []
  }

  const getTypeLabels = (inputs: (string | number)[]) => {
    const types = convertTypesToString(inputs)
    return types.map(type => _.startCase(type)).join(', ')
  }

  const renderTypesInBadges = (inputs: (string | number)[]) => {
    const types = convertTypesToString(inputs)
    if (types.length) {
      return (
        <div>
          {types.map(type => (
            <Badge key={type} style={{ marginRight: 4 }}>
              {_.startCase(type)}
            </Badge>
          ))}
        </div>
      )
    }
    return null
  }

  return {
    enumsData: data,
    typeEnums,
    typeEnumsOptions,
    isEnumsDataFetched: isFetched,
    isEnumsDataFetching: isFetching,
    typeEnumsOptionsWithStringValues,
    findTypeEnum,
    convertTypesToNumber,
    convertTypesToString,
    renderTypesInBadges,
    getTypeLabels,
    getOptionsFromEnum,
  }
}

export default useQueryEnums
