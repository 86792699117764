import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyNotes from './useModifyNotes'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

import type { IGetNotesParams, INote } from '~/types/models/INote'
import type { IUser } from '~/types/models/IUser'

const useQueryNotes = (
  params: IGetNotesParams,
  options?: Partial<UseQueryOptions<INote[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addNote, updateNote, removeNote } = useModifyNotes(params)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'notes',
      sessionUser?.id,
      buildGetUrl(apiClient.notes.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    async queryFn() {
      const response = await apiClient.notes.get(params)
      return response.notes
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const notesData = useMemo(() => data || [], [data])

  return {
    notesData,
    isNotesDataLoading: isLoading,
    refetchNotesData: refetch,
    addNote,
    updateNote,
    removeNote,
  }
}

export default useQueryNotes
