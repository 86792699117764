import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useQueryBundles,
  useQuerySellerTerminalProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'

import { BundleBadge } from '../BundleBadge'

import { apiClient } from '~/api/ApiClient'

import type { IBundleFormWithBadgesProps } from './type'
import type { IBundle } from '~/types/models/IBundle'
import type { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'

const useBundleFormWithBadges = (props: IBundleFormWithBadgesProps) => {
  const { sellerProductId } = props

  const [isCreatingBundle, setIsCreatingBundle] = useState(false)
  const [sellerTerminalProdState, setSellerTerminalProdState] = useState({
    isOpen: false,
    formData: undefined as ISellerTerminalProduct | undefined,
  })

  const {
    bundlesData,
    addBundle,
    updateBundle,
    removeBundle,
    isBundlesFetched,
  } = useQueryBundles({
    filters: {
      sellerProductId,
    },
  })

  const { sellerTerminalProductsData, refecthSellerTerminalProducts } =
    useQuerySellerTerminalProducts(
      { filters: { sellerProductId } },
      { enabled: Boolean(sellerProductId) },
    )
  const { sellerTerminalOptions } = useQueryTerminals()

  const onOpenCreateForm = useCallback(() => {
    setIsCreatingBundle(true)
  }, [])

  const onOpenSellerTerProdCreateForm = useCallback(() => {
    setSellerTerminalProdState({
      isOpen: true,
      formData: undefined,
    })
  }, [])

  const onCloseForm = useCallback(() => {
    setIsCreatingBundle(false)
  }, [])

  const onCloseSellerTerProdForm = useCallback(() => {
    setSellerTerminalProdState({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const afterCreateBundle = useCallback(
    (newBundle: IBundle) => {
      addBundle(newBundle)
      refecthSellerTerminalProducts()
      onCloseForm()
    },
    [addBundle, onCloseForm, refecthSellerTerminalProducts],
  )

  const afterUpdateBundle = useCallback(
    (bundleUpdated: IBundle) => {
      updateBundle(bundleUpdated.id, bundleUpdated)
      onCloseForm()
    },
    [onCloseForm, updateBundle],
  )

  const onRemoveBundle = useCallback(
    (bundle: IBundle) => {
      apiClient.bundles.delete(bundle.id)
      removeBundle(bundle.id)
    },
    [removeBundle],
  )

  const availableSellerTerminalOptions = useMemo(() => {
    const bundleIds = bundlesData.map(bundle => bundle.id)
    const usedTerminalIds = bundleIds
      .map(bundleId => {
        return sellerTerminalProductsData.find(stp => stp.bundleId === bundleId)
          ?.terminalId
      })
      .filter(Boolean) as number[]

    return sellerTerminalOptions.filter(
      terminal => !usedTerminalIds.includes(terminal.value),
    )
  }, [sellerTerminalOptions, sellerTerminalProductsData, bundlesData])

  const renderBundleBadges = useMemo(
    () =>
      bundlesData.map(bundle => {
        return (
          <BundleBadge
            key={bundle.id}
            bundleData={bundle}
            sellerProductId={sellerProductId}
            sellerTerminalOptions={availableSellerTerminalOptions}
            onRemove={onRemoveBundle}
            afterUpdateBundle={afterUpdateBundle}
          />
        )
      }),
    [
      bundlesData,
      sellerProductId,
      availableSellerTerminalOptions,
      onRemoveBundle,
      afterUpdateBundle,
    ],
  )

  useEffect(() => {
    if (isBundlesFetched && bundlesData.length === 0) {
      onOpenCreateForm()
    }
  }, [bundlesData.length, isBundlesFetched, onOpenCreateForm])

  return {
    isCreatingBundle,
    sellerProductId,
    sellerTerminalProdState,
    renderBundleBadges,
    availableSellerTerminalOptions,
    onOpenCreateForm,
    onCloseForm,
    afterCreateBundle,
    afterUpdateBundle,
    onCloseSellerTerProdForm,
    onOpenSellerTerProdCreateForm,
  }
}

export default useBundleFormWithBadges
