import { useContext } from 'react'

import ModalDownloadProgressContext from './Context'

const useModalDownloadProgress = () => {
  const context = useContext(ModalDownloadProgressContext)

  return context
}

export default useModalDownloadProgress
