import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IAutoCharge, IGetAutoChargesParams } from '~/types/models/IAutoCharge'

const useModifyAutoCharges = (params: Partial<IGetAutoChargesParams> = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'autoCharges',
      sessionUser?.id,
      buildGetUrl(apiClient.autoCharges.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addAutoCharge = useCallback(
    (data: IAutoCharge) => {
      queryClient.setQueryData<IAutoCharge[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateAutoCharge = useCallback(
    (id: number, data: IAutoCharge) => {
      queryClient.setQueryData<IAutoCharge[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeAutoCharge = useCallback(
    (id: number) => {
      queryClient.setQueryData<IAutoCharge[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addAutoCharge,
    updateAutoCharge,
    removeAutoCharge,
  }
}

export default useModifyAutoCharges
