import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import {
  BranchCreateIcon,
  CalculatorIcon,
  CheckMarkIcon,
  CircleSyncIcon,
  CloseIcon,
  CommonDialogV2,
  DeleteIcon,
  DialogSellerToBuyerTerminalForm,
  DownloadIcon,
  EmailIcon,
  InvoiceIcon,
  LoadIcon,
  LockIcon,
  PdfIcon,
  ProgressIcon,
  RefreshIcon,
  ReusableButton,
  ReusableInvoicesTable,
  TagIcon,
  ViewPDF,
} from '~/components/shared'

// import PdfExportButton from '~/components/shared/PdfExportButton'
import { toastMessages } from '~/constants/toast-status-text'

import './InvoiceActions.scss'
import { apiClient } from '~/api/ApiClient'
import { EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { selectBillLinesFilters, selectCurrentScope } from '~/redux/selectors'
import {
  useQueryBillLines,
  useQueryInvoices,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { EInvoiceStatus } from '~/types/enums/EInvoice'
import queryString from 'query-string'
import { AutomaticChargesDialog } from '../AutomaticChargesDialog'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import { useConfirmationProvider } from '~/contexts'
import { useLocalStorage } from 'react-use'
import { useRouter } from '~/hooks/useRouter'
import { useQuerySellerToBuyerTerminals } from '~/hooks/useQueryData/useQuerySellerToBuyerTerminals'
import SaleBacklogContainer from '~/containers/SaleBacklogContainer'
import moment from 'moment'

const InvoiceAction = ({ invoice, id, onGetParamsFilter, saveInvoice }) => {
  const [isRecalculating, setIsRecalculating] = useState(false)
  const [isFetchingPdf, setIsFetchingPdf] = useState(false)
  const [pdfView, setPdfView] = useState({
    isOpen: false,
    link: '',
    filename: '',
  })
  const [automaticChargesModal, setAutomaticChargesModal] = useState({
    isOpen: false,
  })
  const [emailing, setEmailing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showActionMenu, setShowActionMenu] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [opportunityModal, setOpportunityModal] = useState({
    isOpen: false,
    formData: undefined,
  })
  const [showOpportunityPageModal, setShowOpportunityPageModal] =
    useState(false)
  const [showInvoicesModal, setShowInvoicesModal] = useState(false)

  const filterData = useSelector(selectBillLinesFilters)
  const currentScope = useSelector(selectCurrentScope)

  const statusField = currentScope === EScope.buyer ? 'status' : 'sellerStatus'
  const statusValue = invoice?.[statusField]

  const isProcessed = useMemo(
    () => invoice && invoice[statusField] === EInvoiceStatus.Progress,
    [invoice, statusField],
  )

  const isFinalized = useMemo(
    () =>
      invoice &&
      (invoice[statusField] === 'Approved' ||
        invoice[statusField] === 'Rejected' ||
        invoice[statusField] === 'Exported' ||
        invoice[statusField] === 'Locked'),
    [invoice, statusField],
  )

  const {
    sellerToBuyerTerminals: [sellerToBuyerTerminal],
    isSellerToBuyerTerminalsFetching,
    addSellerToBuyerTerminals,
  } = useQuerySellerToBuyerTerminals({
    filters: {
      sellerId: invoice.sellerId,
      buyerTerminalId: invoice.buyerTerminalId,
    },
  })

  const { findTerminalById } = useQueryTerminals()

  const { billLinesData, refetchQueryBillLines, isBillLinesFetching } =
    useQueryBillLines(
      {
        filters: {
          ...filterData,
          invoiceId: invoice?.id,
        },
        perPage: 1000,
      },
      { enabled: Boolean(invoice?.id) },
    )

  const { updateInvoice } = useQueryInvoices(
    {
      id: invoice?.id,
    },
    { enabled: Boolean(invoice?.id) },
  )

  const isBillLinesDiffInvoice = useMemo(() => {
    const totalBillLines = billLinesData.reduce(
      (total, item) => (total += Number(item.total || 0)),
      0,
    )

    return totalBillLines !== Number(invoice?.total || 0)
  }, [billLinesData, invoice?.total])

  const router = useRouter()
  const { confirmation } = useConfirmationProvider()

  const [
    hideConfirmationWhenDeleteInvoice,
    setHideConfirmationWhenDeleteInvoice,
  ] = useLocalStorage('concord.showConfirmationWhenDeleteInvoice', false)

  const onRecalculateInvoice = async () => {
    setIsRecalculating(true)
    try {
      const response = await apiClient.invoice.recalculate(invoice.id)
      refetchQueryBillLines()
      updateInvoice(response.id, response)
    } catch (error) {
      toast.error(toastMessages.updateError)
      console.log('error', error)
    } finally {
      setIsRecalculating(false)
    }
  }

  const onOpenPdfView = urlName => async () => {
    setIsFetchingPdf(true)
    try {
      const response = await apiClient.url.getById(invoice.id, {
        model: 'Invoice',
        urlName: urlName,
      })
      if (response.url) {
        const { query } = queryString.parseUrl(response.url)
        const filenameHash = query['response-content-disposition']
        let filename = `invoice_${invoice.number || invoice.num || invoice.id}`
        const regex = /filename\*?=.*?'?([^';]+)\.pdf(?:;|$)/
        const match = filenameHash.match(regex)
        if (match[1]) {
          filename = match[1]
        }

        setPdfView({
          isOpen: true,
          link: response.url,
          filename,
        })
      } else {
        toast.error("Can't find pdf!")
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsFetchingPdf(false)
    }
  }

  const onClosePdfView = () => {
    setPdfView({
      isOpen: false,
      link: '',
      filename: '',
    })
  }

  const onOpenAutomaticaCharges = () => {
    setAutomaticChargesModal({
      isOpen: true,
    })
    setShowActionMenu(false)
  }

  const onCloseAutomaticCharges = () => {
    setAutomaticChargesModal({
      isOpen: false,
    })
  }

  const onSendEmail = async query => {
    setEmailing(true)
    try {
      const res = await apiClient.emailInvoices.create(
        {
          invoiceIds: [invoice.id],
        },
        query,
      )
      if (res.message === 'Email sent') {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setEmailing(false)
    }
  }
  const onChangeStatus = useCallback(
    status => async () => {
      setIsLoading(true)
      try {
        const response = await apiClient.invoices.update(invoice.id, {
          [statusField]: status,
        })
        updateInvoice(response.id, response)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setIsLoading(false)
      }
    },
    [invoice?.id, statusField, updateInvoice],
  )

  const deleteInvoice = useCallback(async () => {
    const response = await apiClient.invoices.delete(invoice.id)
    console.log('response', response)
    if (response.deletedAt) {
      toast.success(toastMessages.deleteSuccess)
      router.push('/invoices')
    } else {
      toast.error(response.errors[0])
    }
  }, [invoice.id, router])

  const onRemoveInvoice = useCallback(async () => {
    setIsDeleting(true)
    try {
      if (!hideConfirmationWhenDeleteInvoice) {
        const result = await confirmation({
          message: 'Do you want to delete this invoice',
          showCheckbox: true,
          checkboxLabel: "Don't show again",
          onChangeCheckbox(event, checked) {
            setHideConfirmationWhenDeleteInvoice(checked)
          },
        })
        if (result === EYesNo.Yes) {
          await deleteInvoice(invoice)
        }
      } else {
        await deleteInvoice(invoice)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.deleteError)
    } finally {
      setIsDeleting(false)
    }
  }, [
    confirmation,
    deleteInvoice,
    hideConfirmationWhenDeleteInvoice,
    invoice,
    setHideConfirmationWhenDeleteInvoice,
  ])

  const showSendEmailBtn = useMemo(() => {
    return [EInvoiceStatus.Approved, EInvoiceStatus.Exported].includes(
      invoice?.sellerStatus,
    )
  }, [invoice?.sellerStatus])

  const onShowOpportunityModal = async () => {
    if (sellerToBuyerTerminal) {
      setShowOpportunityPageModal(true)
    } else {
      const result = await confirmation({
        message: 'Do you want to create an opportunity?',
      })
      if (result === EYesNo.Yes) {
        setOpportunityModal({
          isOpen: true,
          formData: undefined,
        })
      }
    }
  }

  const onCloseOpportunityModal = () => {
    setOpportunityModal({
      isOpen: false,
      formData: undefined,
    })
  }

  const actionButtons = [
    {
      label: 'Progress',
      icon: <ProgressIcon color='var(--bs-primary)' />,
      color: 'primary',
      isHidden: statusValue === EInvoiceStatus.Progress,
      onClick: onChangeStatus(EInvoiceStatus.Progress),
      isLoading,
    },
    {
      label: 'Approve',
      icon: <CheckMarkIcon color='var(--bs-success)' />,
      color: 'success',
      isHidden:
        statusValue !== EInvoiceStatus.Progress ||
        statusValue === EInvoiceStatus.Approved,
      onClick: onChangeStatus(EInvoiceStatus.Approved),
      isLoading,
    },
    {
      label: 'Lock',
      icon: <LockIcon color='var(--bs-secondary)' />,
      color: 'secondary',
      isHidden:
        statusValue === EInvoiceStatus.Locked ||
        statusValue !== EInvoiceStatus.Progress,
      onClick: onChangeStatus(EInvoiceStatus.Locked),
      isLoading,
    },
    {
      label: 'Reject',
      icon: <CloseIcon color='var(--bs-danger)' />,
      color: 'danger',
      isHidden:
        statusValue === EInvoiceStatus.Rejected ||
        currentScope !== EScope.buyer,
      onClick: onChangeStatus(EInvoiceStatus.Rejected),
      isLoading,
    },
    {
      label: 'Revert',
      icon: <RefreshIcon color='var(--bs-dark)' />,
      color: 'dark',
      isHidden: !isFinalized,
      onClick() {
        if (statusValue === 'Exported') {
          onChangeStatus(EInvoiceStatus.Approved)()
        } else {
          onChangeStatus(EInvoiceStatus.Progress)()
        }
      },
      isLoading,
    },
    {
      label: 'Export to Xml',
      icon: <DownloadIcon color='var(--bs-secondary)' />,
      color: 'secondary',
      onClick() {
        saveInvoice({ id, [statusField]: 'Exported' })
        apiClient.invoices.exportXml(onGetParamsFilter())
      },
    },
    {
      label: 'Invoice PDF',
      icon: <PdfIcon color='var(--bs-secondary)' />,
      color: 'secondary',
      isHidden: invoice?.sellerStatus === EInvoiceStatus.Progress,
      onClick: onOpenPdfView('generated_invoice'),
      isLoading: isFetchingPdf,
    },
    {
      label: 'Invoice Tickets',
      icon: <LoadIcon color='var(--bs-secondary)' />,
      color: 'secondary',
      onClick: onOpenPdfView('tickets'),
      isLoading: isFetchingPdf,
    },
    {
      label: 'Refresh Data',
      icon: <CircleSyncIcon color='var(--bs-warning)' />,
      isLoading: isBillLinesFetching,
      onClick: refetchQueryBillLines,
      color: 'warning',
    },
    {
      label: 'Re-calculate',
      icon: <CalculatorIcon color='var(--bs-orange)' />,
      color: isBillLinesDiffInvoice ? 'danger' : 'secondary',
      isHidden: !isProcessed,
      isLoading: isRecalculating,
      onClick: onRecalculateInvoice,
    },
    {
      label: 'Automatic Charges',
      icon: <BranchCreateIcon color='var(--bs-secondary)' />,
      color: 'secondary',
      onClick: onOpenAutomaticaCharges,
    },
    {
      label: 'Send Email',
      icon: <EmailIcon color='var(--bs-primary)' />,
      onClick: () => onSendEmail(),
      isLoading: emailing,
      isHidden: !showSendEmailBtn,
      subMenu: [
        {
          label: 'Email Invoice Only',
          onClick: () => onSendEmail({ documentsToInclude: 'invoice' }),
        },
        {
          label: 'Email Tickets Only',
          onClick: () => onSendEmail({ documentsToInclude: 'tickets' }),
        },
        {
          label: 'Email Invoice & Tickets (Combined File)',
          onClick: () => onSendEmail({ documentsToInclude: 'combined' }),
        },
        {
          label: 'Email Invoice & Tickets (Separate Files)',
          onClick: () => onSendEmail({ documentsToInclude: 'separate' }),
        },
      ],
    },
    {
      label: 'Sales Proposal',
      isLoading: isSellerToBuyerTerminalsFetching,
      onClick: onShowOpportunityModal,
      icon: <TagIcon color='var(--bs-info)' />,
      color: 'info',
    },
    {
      label: 'Recent Invoices',
      onClick: () => {
        setShowInvoicesModal(true)
      },
      icon: <InvoiceIcon color='var(--bs-primary)' />,
    },
    {
      label: 'Delete Invoice',
      color: 'danger',
      icon: <DeleteIcon color='var(--bs-danger)' />,
      onClick: onRemoveInvoice,
      isLoading: isDeleting,
      isHidden:
        currentScope === EScope.buyer
          ? invoice !== 'Invoice'
          : invoice?.sellerStatus === EInvoiceStatus.Approved,
    },
  ]

  return (
    <>
      <Dropdown
        show={showActionMenu}
        className='InvoiceActions__dropdown'
        onToggle={(show, { source }) => {
          if (source !== 'select') {
            setShowActionMenu(show)
          }
        }}
      >
        <Dropdown.Toggle
          variant='primary'
          id='invoice-actions'
          as={ReusableButton}
        >
          Invoice Actions
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {actionButtons
            .filter(({ isHidden }) => !isHidden)
            .map(
              ({ label, subMenu, icon, color, onClick, isLoading }, index) => (
                <Dropdown.Item key={index} as='div'>
                  {subMenu ? (
                    <Dropdown as={ButtonGroup}>
                      <ReusableButton
                        variant={color}
                        onClick={onClick}
                        isLoading={isLoading}
                      >
                        {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
                        {label}
                      </ReusableButton>

                      <Dropdown.Toggle split variant={color} />
                      <Dropdown.Menu>
                        {subMenu.map(({ label, onClick }, index, data) => (
                          <>
                            <Dropdown.Item
                              key={index}
                              as={ReusableButton}
                              onClick={onClick}
                              variant='dark'
                              className='clickable'
                              style={{ boxShadow: 'none' }}
                            >
                              {label}
                            </Dropdown.Item>
                            {data.length - 1 > index && <Dropdown.Divider />}
                          </>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <ReusableButton
                      variant={color}
                      onClick={onClick}
                      isLoading={isLoading}
                    >
                      {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
                      {label}
                    </ReusableButton>
                  )}
                </Dropdown.Item>
              ),
            )}
        </Dropdown.Menu>
      </Dropdown>

      <CommonDialogV2
        size='xl'
        isHiddenOkButton
        isHiddenHeader
        isOpen={pdfView.isOpen}
        onClose={onClosePdfView}
        className='InvoiceAction__invoicePdf'
      >
        {pdfView.link && (
          <ViewPDF link={pdfView.link} fileName={pdfView.filename} />
        )}
      </CommonDialogV2>

      <AutomaticChargesDialog
        invoice={invoice}
        isOpen={automaticChargesModal.isOpen}
        onClose={onCloseAutomaticCharges}
      />

      <DialogSellerToBuyerTerminalForm
        isOpen={opportunityModal.isOpen}
        formData={
          opportunityModal.formData || {
            sellerId: invoice.sellerId,
            sellerTerminalId: invoice.sellerTerminalId,
            buyerTerminalId: invoice.buyerTerminalId,
            buyerId: findTerminalById(invoice.buyerTerminalId)?.companyId,
          }
        }
        onClose={onCloseOpportunityModal}
        afterCreate={formData => {
          addSellerToBuyerTerminals(formData)
          onCloseOpportunityModal()
        }}
      />

      <CommonDialogV2
        isOpen={showOpportunityPageModal}
        fullscreen
        isHiddenOkButton
        onClose={() => {
          setShowOpportunityPageModal(false)
        }}
      >
        <SaleBacklogContainer hideBody id={sellerToBuyerTerminal?.id} />
      </CommonDialogV2>

      <CommonDialogV2
        isOpen={showInvoicesModal}
        isHiddenOkButton
        fullscreen
        onClose={() => {
          setShowInvoicesModal(false)
        }}
      >
        <ReusableInvoicesTable
          hideHeader
          hideBody
          filterData={[
            {
              id: 'buyerTerminalId',
              value: [invoice.buyerTerminalId],
            },
            {
              id: 'endDate',
              value: {
                startDate: moment().add({ days: -30 }).format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
              },
            },
          ]}
        />
      </CommonDialogV2>
    </>
  )
}

export default InvoiceAction
