import { useMemo } from 'react'
import {
  IReusableTableRowData,
  ReusableTableInstance,
  RTCell,
} from '../../../types'
import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { RTEditCellTextField } from '../RTEditCellTextField'
import { RTEditCellSingleSelectField } from '../RTEditCellSingleSelectField'
import { RTEditCellNumberField } from '../RTEditCellNumberField'
import { RTEditCellDateField } from '../RTEditCellDateField'
import { RTEditCellTimeField } from '../RTEditCellTimeField'
import { RTEditCellDateRangeField } from '../RTEditCellDateRangeField'
import { RTEditCellColorSelectorField } from '../RTEditCellColorSelectorField'

export interface IRTEditCellFieldProps<TData extends IReusableTableRowData> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  className?: string
}

const RTEditCellField = <TData extends IReusableTableRowData>(
  props: IRTEditCellFieldProps<TData>,
) => {
  const { cell, table, className } = props

  const renderField = useMemo(() => {
    switch (cell.column.columnDef.editVariant) {
      case EFieldType.singleSelect:
        return <RTEditCellSingleSelectField cell={cell} table={table} />
      case EFieldType.multipleSelect:
        return <RTEditCellSingleSelectField cell={cell} table={table} isMulti />
      case EFieldType.number:
        return <RTEditCellNumberField cell={cell} table={table} />
      case EFieldType.date:
        return <RTEditCellDateField cell={cell} table={table} />
      case EFieldType.time:
        return <RTEditCellTimeField cell={cell} table={table} />
      case EFieldType.dateRange:
        return <RTEditCellDateRangeField cell={cell} table={table} />
      case EFieldType.color:
        return <RTEditCellColorSelectorField cell={cell} table={table} />

      default:
        return (
          <RTEditCellTextField
            cell={cell}
            table={table}
            type={cell.column.columnDef.editVariant}
          />
        )
    }
  }, [cell, table])

  return (
    <div className={className} style={{ width: '100%' }}>
      {renderField}
    </div>
  )
}

export default RTEditCellField
