import { useMemo, useState } from 'react'

import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'

import type { IBreadcrumbDotProps } from './type'

import './styles.scss'
import moment from 'moment'

function BreadcrumbDot(props: IBreadcrumbDotProps) {
  const { breadcrumbLoad } = props

  const [showInfoBox, setShowInfoBox] = useState(false)

  const onToggleInfoBox = () => {
    setShowInfoBox(prev => !prev)
  }

  const position = useMemo(
    () =>
      new google.maps.LatLng(
        Number(breadcrumbLoad.lat || 0),
        Number(breadcrumbLoad.lng || 0),
      ),
    [breadcrumbLoad.lat, breadcrumbLoad.lng],
  )

  const items = [
    {
      label: 'Speed',
      value: (
        <span>
          {breadcrumbLoad.speed} | <strong>Bearing</strong>:{' '}
          {breadcrumbLoad.bearing} | <strong>Source</strong>:{' '}
          {breadcrumbLoad.source}
        </span>
      ),
    },
    {
      label: 'Address',
      value: breadcrumbLoad.address,
      hide: !breadcrumbLoad.address,
    },
    {
      label: 'Event Type',
      value: breadcrumbLoad.eventType,
      hide: !breadcrumbLoad.eventType,
    },
    {
      label: 'Time',
      value: moment(breadcrumbLoad.createdAt).format('MMM-D (ddd), H:m'),
    },
  ]

  if (breadcrumbLoad.lat && breadcrumbLoad.lng) {
    return (
      <InfoBox
        zIndex={1000}
        position={position}
        options={{
          closeBoxURL: '',
          enableEventPropagation: true,
          alignBottom: true,
        }}
      >
        <div>
          <div className='BreadcrumbDot__container' onClick={onToggleInfoBox} />

          {showInfoBox && (
            <InfoBox
              zIndex={1000}
              position={position}
              options={{
                closeBoxURL: '',
                enableEventPropagation: true,
                alignBottom: true,
                pixelOffset: new window.google.maps.Size(18, 0),
                boxStyle: {
                  minWidth: '45px',
                },
                isHidden: false,
              }}
            >
              <div className='BreadcrumbDot__infoBox'>
                <ul style={{ marginBottom: 0 }}>
                  {items
                    .filter(({ hide }) => !hide)
                    .map(({ label, value }, index) => (
                      <li key={index} style={{ marginBottom: 4 }}>
                        <strong>{label}</strong>: {value}
                      </li>
                    ))}
                </ul>
              </div>
            </InfoBox>
          )}
        </div>
      </InfoBox>
    )
  }

  return null
}
export default BreadcrumbDot
