import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetNotesParams, INote } from '~/types/models/INote'

const useModifyNotes = (params: IGetNotesParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'notes',
      sessionUser?.id,
      buildGetUrl(apiClient.notes.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    [params, sessionUser?.id],
  )

  const addNote = useCallback(
    (data: INote) => {
      queryClient.setQueryData<INote[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateNote = useCallback(
    (id: number, data: INote) => {
      queryClient.setQueryData<INote[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeNote = useCallback(
    (id: number) => {
      queryClient.setQueryData<INote[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addNote,
    updateNote,
    removeNote,
  }
}

export default useModifyNotes
