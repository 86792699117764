import type { ISvgIconProps } from './type'

const CircleSyncIcon = (props: ISvgIconProps) => {
  const { size = 16, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 48 48'
      fill={color}
      {...svgProps}
    >
      <g data-name='Layer 2'>
        <path fill='none' d='M0 0h48v48H0z' data-name='invisible box' />
        <g data-name='icons Q2'>
          <path d='M44 5.1V14a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2.3 2.1 2.1 0 0 1 2.1-1.7h4.3A18 18 0 0 0 6.1 22.2a2 2 0 0 1-2 1.8 2 2 0 0 1-2-2.2A22 22 0 0 1 40 8.9V5a2 2 0 0 1 2.3-2A2.1 2.1 0 0 1 44 5.1ZM4 42.9V34a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2.3 2.1 2.1 0 0 1-2.1 1.7h-4.3a18 18 0 0 0 31.3-10.2 2 2 0 0 1 2-1.8 2 2 0 0 1 2 2.2A22 22 0 0 1 8 39.1V43a2 2 0 0 1-2.3 2A2.1 2.1 0 0 1 4 42.9Z' />
        </g>
      </g>
    </svg>
  )
}

export default CircleSyncIcon
