import { useCallback, useMemo, useRef, useState } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SplitPane from 'react-split-pane'
import { useIsMobile } from '~/hooks/useIsMobile'

import './styles.scss'
import { Alert, Button, ButtonGroup } from 'react-bootstrap'
import {
  CalendarIcon,
  ClockPlusIcon,
  CloseIcon,
  CustomTerminalOption,
  DropdownWithCustomChildren,
  GraphIcon,
  LineChart,
  LocationIcon,
  ReusableButton,
  ReusableDatePicker,
  ScheduleIcon,
  ToolTipOverlay,
  TruckIcon,
} from '~/components/shared'
import {
  useQueryCompanies,
  useQueryOffdays,
  useQueryScheduleLoads,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import moment from 'moment'
import {
  START_TIME_INTERVAL_OPTIONS,
  START_TIME_SHORTCUT_OPTIONS,
} from '~/utils/constants'
import { ICommonOption } from '~/types/models/ICommonModel'
import { colord } from 'colord'
import clsx from 'clsx'
import { produce } from 'immer'
import _ from 'lodash'
import { DriverFleetList } from './DriverFleetList'
import { TimeSlotCard } from './TimeSlotCard'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'
import { apiClient } from '~/api/ApiClient'
import { IStartTime } from '~/types/models/IStartTime'
import { toast } from 'react-toastify'
import { calculateDriversNeededByScheduleId } from '~/utils/calculateDriversNeeded'
import { IonLabel } from '@ionic/react'
import { EColor, ERoundingOption } from '~/types/enums/ECommonEnum'
import { IScheduleLoad } from '~/types/models/IScheduleLoad'
import { useMeasure } from 'react-use'
import pluralize from 'pluralize'
import { ControlledMenu, MenuItem } from '@szhsin/react-menu'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import getColor from '~/utils/getColor'
import { EDriverVisibilityState } from './type'

function randomHexColor() {
  const randomColor = Math.floor(Math.random() * 16777215)
  return '#' + randomColor.toString(16).padStart(6, '0') // Ensure it always has 6 digits
}

export interface INewStartTime {
  color: string
  driverFleets: {
    id: number
    notes?: string
    truckFleetId?: number | null
    sellerTerminalId?: number | null
    terminalId?: number | null
  }[]
  notes?: string
  terminalId?: number
  sellerTerminalId?: number
  time: string // HH:mm
}

export interface IStartTimeFormProps {
  afterCreateStartTimes?: (newStartTimes: IStartTime[]) => void
}

function StartTimeForm(props: IStartTimeFormProps) {
  const { afterCreateStartTimes } = props

  const isMobile = useIsMobile()

  const [buyerTerminalIdSelected, setBuyerTerminalIdSelected] =
    useState<undefined | number>(undefined)
  const [sellerTerminalIdSelected, setSellerTerminalIdSelected] =
    useState<undefined | number>(undefined)
  const [date, setDate] = useState<Date | undefined | string>(new Date())
  const [interval, setInterval] = useState(15)
  const [newStartTimes, setNewStartTimes] = useState<INewStartTime[]>([])
  const [notesValues, setNotesValues] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [isChartShown, setIsChartShown] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [roundingInterval, setRoundingInterval] = useState(10)
  const [roundingOption, setRoundingOption] = useState(ERoundingOption.nearest)
  const [sortOptions, setSortOptions] = useState([
    {
      value: 'driverFleet.rank',
      label: 'Rank',
      sortField: 'driverFleet.rank',
      sorted: true,
      isAsc: true,
    },
    {
      value: 'person.firstName',
      label: 'First name',
      sortField: 'person.firstName',
      sorted: false,
      isAsc: true,
    },
    {
      value: 'person.lastName',
      label: 'Last name',
      sortField: 'person.lastName',
      sorted: false,
      isAsc: true,
    },
    {
      value: 'person.initials',
      label: 'Initials',
      sortField: 'person.initials',
      sorted: false,
      isAsc: true,
    },
  ])
  const [driverFilterData, setDriverFilterData] = useState<Record<string, any>>(
    {
      'worker.terminalId': [],
      offday: 'showOffDay',
      driver: EDriverVisibilityState.disable,
    },
  )

  const popoverRef = useRef<any>()

  const hasDriverFleet = useMemo(
    () => newStartTimes.every(startTime => startTime.driverFleets.length > 0),
    [newStartTimes],
  )

  const driverFleetsInStartTimes = useMemo(() => {
    return newStartTimes.reduce(
      (count, item) => count + item.driverFleets.length,
      0,
    )
  }, [newStartTimes])

  const [measureRef, { width }] = useMeasure()

  const isSmallSection = useMemo(() => width <= 536, [width])

  const newStartTimesSorted = useMemo(() => {
    return _.orderBy(newStartTimes, [
      item => moment(item.time, 'H:mm').toDate(),
    ])
  }, [newStartTimes])

  const { findCompanyById } = useQueryCompanies({})

  const { findTruckFleetById } = useQueryTruckFleets()

  const {
    companyTerminalOptions,
    findTerminalById,
    otherSellerTerminalOptions,
  } = useQueryTerminals()

  const terminalsOptions = [
    {
      label: 'My Terminals',
      options: companyTerminalOptions,
    },
    {
      label: 'Seller Terminals',
      options: otherSellerTerminalOptions,
    },
  ]

  const { driverFleetUsers } = useQueryUsers()

  const { scheduleLoadsData } = useQueryScheduleLoads({
    filters: {
      ticketTime: {
        startDate: moment(date).startOf('day').toISOString(),
        endDate: moment(date).endOf('day').toISOString(),
      },
      // beginSellerTerminalId: buyerTerminalIdSelected,
    },
  })

  const { findOffDayByWorkerId } = useQueryOffdays({
    filters: {
      startDate: {
        startDate: moment(date).startOf('day').toISOString(),
        endDate: moment(date).endOf('day').toISOString(),
      },
    },
  })

  const labelsInChart = useMemo(() => {
    const ticketTimeData = scheduleLoadsData.map(({ ticketTime }) => ticketTime)
    const sorted = ticketTimeData.sort((a, b) => {
      const dateA: any = new Date(a)
      const dateB: any = new Date(b)

      return dateA - dateB
    })
    return _.uniq(sorted)
    // .map(time => moment(time).format('HH:mm'))
  }, [scheduleLoadsData])

  const roundingOptions = useMemo(() => {
    const options = [
      {
        label: 'Closest Slot',
        value: ERoundingOption.nearest,
      },
      {
        label: 'Next Upcoming Slot',
        value: ERoundingOption.forceUp,
      },
      {
        label: 'Closest Earlier Slot',
        value: ERoundingOption.forceDown,
      },
    ]
    return options.map(({ label, value }) => (
      <option key={value} value={value} selected={roundingOption === value}>
        {label}
      </option>
    ))
  }, [roundingOption])

  const dataOnXAxisOfChart = useMemo(() => {
    const driversNeeded: any =
      calculateDriversNeededByScheduleId(scheduleLoadsData)
    const dataset: any = []
    Object.keys(driversNeeded).forEach(scheduleId => {
      const calculatedTime = driversNeeded[scheduleId]
      const data: any = []
      labelsInChart.forEach(label => {
        let numberOfDrivers = 0
        if (calculatedTime[label]) {
          numberOfDrivers = calculatedTime[label]
        }
        data.push(numberOfDrivers)
      })
      const color = randomHexColor()
      dataset.push({
        data,
        backgroundColor: color,
        borderColor: color,
      })
    })
    return dataset
  }, [labelsInChart, scheduleLoadsData])

  const intervalOptions = useMemo(() => {
    const options = [
      {
        label: '1 min',
        value: 1,
      },
      {
        label: '2 min',
        value: 2,
      },
      {
        label: '5 min',
        value: 5,
      },
      {
        label: '10 min',
        value: 10,
      },
      {
        label: '15 min',
        value: 15,
      },
      {
        label: '30 min',
        value: 30,
      },
      {
        label: '60 min',
        value: 60,
      },
    ]
    return options.map(({ label, value }) => (
      <option key={value} value={value} selected={roundingInterval === value}>
        {label}
      </option>
    ))
  }, [roundingInterval])

  const allDriverFleetIds = useMemo(
    () =>
      newStartTimesSorted.flatMap(({ driverFleets }) =>
        driverFleets.map(({ id }) => id),
      ),
    [newStartTimesSorted],
  )

  const startTimesSelected = useMemo(
    () => newStartTimes.map(({ time }) => time),
    [newStartTimes],
  )

  const dateLabel = useMemo(() => {
    if (!date) return 'Select Date'
    return moment(date).format('ddd, MMM-D')
  }, [date])

  const buyerTerminal = useMemo(
    () => findTerminalById(buyerTerminalIdSelected),
    [buyerTerminalIdSelected, findTerminalById],
  )

  const sellerTerminal = useMemo(
    () => findTerminalById(sellerTerminalIdSelected),
    [findTerminalById, sellerTerminalIdSelected],
  )

  const onOpenSetupStartTime = () => {
    setPopoverOpen(prev => !prev)
  }

  const onCloseSetupStartTime = () => {
    setPopoverOpen(false)
  }

  const timeSlotOptions = useMemo(() => {
    const arr: ICommonOption[] = []
    const spacing = interval || 60
    for (let i = 0; i < 1440 / spacing; i++) {
      arr.push({
        label: moment(date)
          .startOf('day')
          .add(i * spacing, 'minutes')
          .format('H:mm'),
        value: moment(date)
          .startOf('day')
          .add(i * spacing, 'minutes')
          .format('H:mm'),
      })
    }
    return arr
  }, [date, interval])

  const onAddTimeSlot = useCallback(
    (timeSlot: string, color?: string) => {
      setNewStartTimes(prev => [
        ...prev,
        {
          color: color || 'var(--ion-color-medium)',
          driverFleets: [],
          notes: '',
          terminalId: buyerTerminalIdSelected,
          sellerTerminalId: sellerTerminalIdSelected,
          time: timeSlot,
        },
      ])
    },
    [buyerTerminalIdSelected, sellerTerminalIdSelected],
  )

  const onUpdateTimeSlot = useCallback(
    (newValue: any, time: string, field: keyof INewStartTime) => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === time)
          if (index !== -1) {
            ;(draft[index] as any)[field] = newValue
          }
        }),
      )
    },
    [setNewStartTimes],
  )

  const onDeleteStartTime = useCallback(
    (startTime: INewStartTime) => () => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === startTime.time)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }),
      )
    },
    [setNewStartTimes],
  )

  const onAssignDriver = useCallback(
    (time: string, driverFleet: IDriverFleet) => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === time)
          if (index !== -1) {
            const truckFleet = findTruckFleetById(
              driverFleet?.currentTruckFleetId,
            )
            draft[index].driverFleets.push({
              id: driverFleet.id,
              notes: '',
              truckFleetId: driverFleet?.currentTruckFleetId,
              terminalId:
                draft[index].terminalId ||
                buyerTerminalIdSelected ||
                truckFleet?.terminalId,
              sellerTerminalId:
                draft[index].sellerTerminalId ||
                sellerTerminalIdSelected ||
                truckFleet?.terminalId,
            })
          }
        }),
      )
    },
    [buyerTerminalIdSelected, findTruckFleetById, sellerTerminalIdSelected],
  )

  const onChangeStartTimeDriver = useCallback(
    (
      startTime: INewStartTime,
      driverFleetId: number,
      payload: Partial<{
        sellerTerminalId: number
        truckFleetId: number
        notes: string
      }>,
    ) => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === startTime.time)
          if (index !== -1) {
            const driverFleetIndex = draft[index].driverFleets.findIndex(
              ({ id }) => id === driverFleetId,
            )
            if (driverFleetIndex !== -1) {
              draft[index].driverFleets[driverFleetIndex] = {
                ...draft[index].driverFleets[driverFleetIndex],
                ...payload,
              }
            }
          }
        }),
      )
    },
    [],
  )

  const onSubmitAssignments = useCallback(async () => {
    setIsSubmitting(true)
    setBackendError('')
    try {
      const payload: any = []

      newStartTimes.forEach(item => {
        const [hours, minutes] = item.time.split(':')
        const startTime = moment(date)
          .set({
            hours: Number(hours),
            minutes: Number(minutes),
            seconds: 0,
          })
          .toISOString()
        item.driverFleets.forEach(driverFleet => {
          const userFound = driverFleetUsers.find(
            user => driverFleet?.id === user.driverFleet?.id,
          )
          payload.push({
            color: item.color,
            driverId: userFound?.driver?.id,
            driverFleetId: driverFleet.id,
            fleetId: userFound?.driverFleet?.fleetId,
            startTime,
            terminalId: driverFleet.terminalId || buyerTerminalIdSelected,
            truckFleetId: driverFleet.truckFleetId,
            sellerTerminalId:
              driverFleet.sellerTerminalId ||
              item.sellerTerminalId ||
              sellerTerminalIdSelected,
            notes: driverFleet.notes || item.notes || notesValues,
            workerId: userFound?.worker?.id,
          })
        })
      })
      const response = await apiClient.startTimes.create(payload)
      afterCreateStartTimes && afterCreateStartTimes(response.startTimes)
      setNewStartTimes([])
      setNotesValues('')
      setBuyerTerminalIdSelected(undefined)
      setSellerTerminalIdSelected(undefined)
      setInterval(10)
      toast.success('Start times created successfully!')
    } catch (error) {
      console.log('error', error)
      setBackendError('An error has occurred')
    } finally {
      setIsSubmitting(false)
    }
  }, [
    afterCreateStartTimes,
    buyerTerminalIdSelected,
    date,
    driverFleetUsers,
    newStartTimes,
    notesValues,
    sellerTerminalIdSelected,
  ])

  const mergeDriverSchedule = useCallback((assigments: any) => {
    const mergedSchedule: any = []

    assigments.forEach((item: any) => {
      const existingItem = mergedSchedule.find(
        (mergedItem: any) => mergedItem.time === item.time,
      )

      if (existingItem) {
        existingItem.driverFleets = [
          ...new Set(existingItem.driverFleets.concat(item.driverFleets)),
        ]
      } else {
        mergedSchedule.push({
          ...item,
          time: item.time,
          driverFleets: [...item.driverFleets],
        })
      }
    })

    return mergedSchedule
  }, [])

  const sortDriverFleetsByRank = useMemo(() => {
    const driverFleetsInTerminalSelected = driverFleetUsers.filter(
      ({ worker }) => worker?.terminalId === buyerTerminalIdSelected,
    )
    const otherDriverFleets = driverFleetUsers.filter(
      ({ worker }) => worker?.terminalId !== buyerTerminalIdSelected,
    )
    const sortedDriverFleetsInTerminal = _.orderBy(
      driverFleetsInTerminalSelected,
      ['rank'],
      ['asc'],
    )
    return [...sortedDriverFleetsInTerminal, ...otherDriverFleets]
  }, [buyerTerminalIdSelected, driverFleetUsers])

  const driverFleetIds = useMemo(
    () =>
      sortDriverFleetsByRank
        .filter(({ worker }) => !findOffDayByWorkerId(worker?.id as any))
        .map(({ driverFleet }) => driverFleet?.id as number),
    [findOffDayByWorkerId, sortDriverFleetsByRank],
  )

  function findNextItem(arr: any, currentItems: any) {
    const result = _.difference(arr, currentItems)
    return result[0]
  }

  const assignDrivers = useCallback(
    (scheduleLoads: IScheduleLoad[], driverIds: number[]) => {
      const driverAssigned: any = []
      const assignments: any = {}

      scheduleLoads.forEach(({ ticketTime }) => {
        const nextDriverId = findNextItem(driverIds, driverAssigned)
        if (assignments[ticketTime]) {
          assignments[ticketTime].push(nextDriverId)
        } else {
          assignments[ticketTime] = [nextDriverId]
        }
        driverAssigned.push(nextDriverId)
      })
      return assignments
    },
    [],
  )

  const roundTime = useCallback(
    (roundingOption: ERoundingOption, interval: number, isoDate: string) => {
      if (roundingOption === ERoundingOption.exact) {
        return isoDate
      }
      const time = new Date(isoDate)
      const roundedTime = new Date(time) // Create a copy of the original time

      switch (roundingOption) {
        case ERoundingOption.nearest:
          roundedTime.setMinutes(
            Math.round(time.getMinutes() / interval) * interval,
          )
          break
        case ERoundingOption.forceUp:
          roundedTime.setMinutes(
            Math.ceil(time.getMinutes() / interval) * interval,
          )
          break
        case ERoundingOption.forceDown:
          roundedTime.setMinutes(
            Math.floor(time.getMinutes() / interval) * interval,
          )
          break
        default:
          return 'Invalid rounding option'
      }

      return roundedTime.toISOString()
    },
    [],
  )

  const onSetupStartTimeData = useCallback(
    (roundedOption: ERoundingOption) => async () => {
      // if (!buyerTerminalIdSelected) {
      //   await confirmation({
      //     message: 'Select Truck pickup terminal',
      //     buttons: [
      //       {
      //         text: 'Ok',
      //         action: 'Ok',
      //       },
      //     ],
      //   })
      //   return
      // }
      const assignments = assignDrivers(scheduleLoadsData, driverFleetIds)

      const result: any = []

      Object.keys(assignments).forEach(time => {
        const timeRounded = roundTime(roundedOption, roundingInterval, time)
        const timeslot: any = {
          time: moment(timeRounded).format('H:mm'),
          driverFleets: assignments[time],
          color: EColor.black,
        }

        if (buyerTerminalIdSelected) {
          timeslot.terminalId = buyerTerminalIdSelected
        }

        if (sellerTerminalIdSelected) {
          timeslot.sellerTerminalId = sellerTerminalIdSelected
        }

        result.push(timeslot)
      })
      const uniqTime = mergeDriverSchedule(result)
      const timeWithDriverNote = uniqTime.map((item: any) => ({
        ...item,
        driverFleets: item.driverFleets.map((id: number) => ({
          id,
          notes: '',
        })),
      }))
      setNewStartTimes(timeWithDriverNote)
      onCloseSetupStartTime()
    },
    [
      assignDrivers,
      buyerTerminalIdSelected,
      driverFleetIds,
      mergeDriverSchedule,
      roundTime,
      roundingInterval,
      scheduleLoadsData,
      sellerTerminalIdSelected,
    ],
  )

  const onSaveRounding = useCallback(
    (event: any) => {
      event.preventDefault()
      onSetupStartTimeData(roundingOption)()
    },
    [onSetupStartTimeData, roundingOption],
  )

  const onChangeRoundingOpt = useCallback((event: any) => {
    const value = event.target.value
    setRoundingOption(value)
  }, [])

  const onChangeRoundingInterval = useCallback((event: any) => {
    const value = event.target.value
    setRoundingInterval(value)
  }, [])

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <SplitPane
          style={{ position: 'static' }}
          defaultSize='60%'
          minSize={200}
          split={isMobile ? 'horizontal' : 'vertical'}
          className='StartTimeForm__splitPaneContainer'
        >
          <div
            className='StartTimeForm__createForm'
            style={{
              width: isMobile ? '100%' : undefined,
              marginRight: isMobile ? 0 : 8,
            }}
            ref={measureRef as any}
          >
            {backendError && (
              <Alert
                variant='danger'
                style={{ fontSize: 13, margin: '0 8px 8px' }}
              >
                {backendError}
              </Alert>
            )}

            <div className='StartTimeForm__header'>
              <div
                style={{
                  marginBottom: 4,
                  display: isSmallSection ? 'block' : 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Button
                    style={{ fontSize: 14, marginRight: 8 }}
                    className={clsx({
                      isDisabled:
                        isSubmitting ||
                        newStartTimes.length === 0 ||
                        !hasDriverFleet,
                    })}
                    onClick={onSubmitAssignments}
                    variant={
                      !hasDriverFleet || newStartTimes.length === 0
                        ? 'secondary'
                        : 'primary'
                    }
                  >
                    {newStartTimes.length
                      ? `${pluralize(
                          `Submit ${driverFleetsInStartTimes} Driver Assignment`,
                          driverFleetsInStartTimes,
                        )} for ${newStartTimes.length} ${pluralize(
                          'Start Time',
                          newStartTimes.length,
                        )}`
                      : 'Submit Driver Assignment'}
                  </Button>

                  <ButtonGroup style={{ marginRight: 8 }}>
                    <ReusableButton
                      variant={isChartShown ? 'danger' : 'warning'}
                      onClick={() => {
                        setIsChartShown(prev => !prev)
                      }}
                      isDisabled={scheduleLoadsData.length === 0}
                      tooltipContentWhenDisabled='At least one schedule load data to see line graph'
                      tooltipContent={
                        isChartShown ? 'Close line graph' : 'Open line graph'
                      }
                    >
                      {isChartShown ? (
                        <CloseIcon color='white' />
                      ) : (
                        <GraphIcon />
                      )}
                    </ReusableButton>

                    <ToolTipOverlay placement='top' content='Setup Start Times'>
                      <ReusableButton
                        variant='warning'
                        onClick={onOpenSetupStartTime}
                        id='setup-start-time'
                        isDisabled={scheduleLoadsData.length === 0}
                        tooltipContentWhenDisabled='At least one schedule load data to setup start times'
                        tooltipContent='Setup Start times'
                        ref={popoverRef as any}
                      >
                        <ScheduleIcon />
                      </ReusableButton>
                    </ToolTipOverlay>
                  </ButtonGroup>

                  <ButtonGroup>
                    <ReusableDatePicker
                      className='StartTimeForm__datePicker'
                      showIcon={false}
                      value={date}
                      onChange={newDate => {
                        setDate(newDate as string)
                      }}
                      customInput={
                        <Button variant='secondary'>
                          <CalendarIcon color='white' />
                          <span
                            style={{
                              fontSize: 12,
                              marginLeft: 2,
                            }}
                          >
                            {dateLabel}
                          </span>
                        </Button>
                      }
                    />

                    <DropdownWithCustomChildren
                      options={timeSlotOptions}
                      className='StartTimeForm__dropdown timeCreateButton'
                      onChange={(event, { value }) => {
                        onAddTimeSlot(value)
                      }}
                      isOptionDisabled={option =>
                        startTimesSelected.includes(option.value)
                      }
                    >
                      <ToolTipOverlay placement='top' content='Add Start Time'>
                        <Button
                          variant='secondary'
                          className='StartTimeForm__button'
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            background: 'var(--bs-gray-500)',
                            borderColor: 'var(--bs-gray-500)',
                          }}
                        >
                          <ClockPlusIcon color='white' />
                        </Button>
                      </ToolTipOverlay>
                    </DropdownWithCustomChildren>

                    <DropdownWithCustomChildren
                      options={START_TIME_INTERVAL_OPTIONS}
                      className='StartTimeForm__dropdown'
                      onChange={(event, { value }) => {
                        setInterval(value)
                      }}
                      value={interval}
                    >
                      <ToolTipOverlay placement='top' content='Add Start Time'>
                        <Button
                          variant='secondary'
                          className='StartTimeForm__button'
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                        >
                          <span className='StartTimeForm__buttonLabel'>
                            {interval} mins
                          </span>
                        </Button>
                      </ToolTipOverlay>
                    </DropdownWithCustomChildren>
                  </ButtonGroup>
                </div>
              </div>

              {isChartShown && (
                <div style={{ marginBottom: 8 }}>
                  <LineChart
                    labels={labelsInChart.map(time =>
                      moment(time).format('H:mm'),
                    )}
                    datasets={dataOnXAxisOfChart}
                    isStacked
                  />
                </div>
              )}

              <div style={{ marginBottom: 6 }}>
                <div style={{ marginTop: isSmallSection ? 4 : 0 }}>
                  {START_TIME_SHORTCUT_OPTIONS.map(option => (
                    <div
                      key={option.value}
                      className={clsx(
                        'StartTimeForm__shortcutBadge clickable',
                        {
                          isDisabled: startTimesSelected.includes(option.value),
                        },
                      )}
                      style={{
                        background: getColor(option.color),
                        color: colord(getColor(option.color)).isDark()
                          ? 'white'
                          : 'black',
                      }}
                      onClick={() => {
                        onAddTimeSlot(option.value, option.color)
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: isSmallSection ? 'block' : 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', marginRight: 8 }}>
                  <NoteButton
                    note={notesValues}
                    onSave={setNotesValues}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    renderNoteTooltip={() =>
                      "Add note for all drivers' start times"
                    }
                  />

                  <DropdownWithCustomChildren
                    options={terminalsOptions}
                    className='StartTimeForm__dropdown'
                    onChange={(event, { value }) => {
                      setBuyerTerminalIdSelected(value)
                    }}
                    value={buyerTerminalIdSelected}
                    components={{
                      Option: CustomTerminalOption,
                    }}
                    getOptionLabel={opt => {
                      const company = findCompanyById(opt.companyId)
                      const labels = [company?.name, company?.code, opt.label]
                        .filter(Boolean)
                        .join(' - ')
                      return labels
                    }}
                  >
                    <ToolTipOverlay
                      placement='top'
                      content='Clockin and Truck Pickup Location'
                    >
                      <Button
                        variant='secondary'
                        className='StartTimeForm__button'
                        style={{
                          borderRadius: 0,
                        }}
                      >
                        <TruckIcon color='white' />
                        <span className='StartTimeForm__buttonLabel'>
                          {buyerTerminal?.name || 'Truck pickup terminal'}
                        </span>
                      </Button>
                    </ToolTipOverlay>
                  </DropdownWithCustomChildren>

                  <DropdownWithCustomChildren
                    options={terminalsOptions}
                    className='StartTimeForm__dropdown'
                    onChange={(event, { value }) => {
                      setSellerTerminalIdSelected(value)
                    }}
                    value={sellerTerminalIdSelected}
                    components={{
                      Option: CustomTerminalOption,
                    }}
                    getOptionLabel={opt => {
                      const company = findCompanyById(opt.companyId)
                      const labels = [company?.name, company?.code, opt.label]
                        .filter(Boolean)
                        .join(' - ')
                      return labels
                    }}
                  >
                    <ToolTipOverlay
                      placement='top'
                      content='Seller Terminal for first Load Pickup'
                    >
                      <Button
                        variant='dark'
                        className='StartTimeForm__button'
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        <LocationIcon color='white' />
                        <span className='StartTimeForm__buttonLabel'>
                          {sellerTerminal?.name || 'First load terminal'}
                        </span>
                      </Button>
                    </ToolTipOverlay>
                  </DropdownWithCustomChildren>
                </div>
              </div>
            </div>

            <div className='StartTimeForm__driversContainer'>
              {newStartTimesSorted.map(startTime => (
                <TimeSlotCard
                  key={startTime.time}
                  startTime={startTime}
                  timeSlotOptions={timeSlotOptions}
                  onUpdateTimeSlot={onUpdateTimeSlot}
                  onDeleteStartTime={onDeleteStartTime}
                  startTimesSelected={startTimesSelected}
                  onAssignDriver={onAssignDriver}
                  assignedDriverFleetIds={allDriverFleetIds}
                  sortDriverFleetOptions={sortOptions}
                  driverFilterData={driverFilterData}
                  buyerTerminalIdSelected={buyerTerminalIdSelected}
                  sellerTerminalIdSelected={sellerTerminalIdSelected}
                  onChangeStartTimeDriver={onChangeStartTimeDriver}
                />
              ))}
            </div>
          </div>
          {!isMobile && (
            <div>
              <DriverFleetList
                assignedDriverFleetIds={allDriverFleetIds}
                isBannerShown={newStartTimes.length <= 0}
                isListDisabled={newStartTimes.length <= 0}
                bannerText='Please add start times (e.g. 7:00 am) before selecting a driver'
                date={date}
                sortOptions={sortOptions}
                filterData={driverFilterData}
                setFilterData={setDriverFilterData}
                setSortOptions={setSortOptions}
              />
            </div>
          )}
        </SplitPane>
      </DndProvider>

      <ControlledMenu
        anchorRef={popoverRef}
        state={popoverOpen ? 'open' : 'closed'}
      >
        <MenuItem style={{ display: 'block', fontSize: 13 }}>
          <div
            className='clickable'
            onClick={onSetupStartTimeData(ERoundingOption.exact)}
            style={{ marginBottom: 4 }}
          >
            <IonLabel>Exact Time</IonLabel>
          </div>
          <div>
            <form
              className='CreateStartTimeForm__roundingForm'
              onSubmit={onSaveRounding}
            >
              <div className='fieldsContainer'>
                <select
                  onChange={onChangeRoundingOpt}
                  required
                  value={roundingOption}
                  style={{ width: '70%' }}
                >
                  {roundingOptions}
                </select>

                <select
                  onChange={onChangeRoundingInterval}
                  required
                  value={roundingInterval}
                  style={{ width: '30%' }}
                >
                  {intervalOptions}
                </select>
              </div>
              <ReusableButton
                type='submit'
                style={{ marginTop: 4, fontSize: 13 }}
              >
                Save
              </ReusableButton>
            </form>
          </div>
        </MenuItem>
      </ControlledMenu>
    </>
  )
}

export default StartTimeForm
