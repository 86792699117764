import { useState, useCallback, useMemo } from 'react'
import { useBreakpoint } from '~/hooks/useBreakpoint'

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonButtons,
  IonPopover,
} from '@ionic/react'
import { BackButton, InternetIcon } from '~/components/shared'
import useAccesses from '~/hooks/useAccesses'
import HeaderPopOver from './PopOver'
import HeaderCompanyChip from './CompanyChip'
import HeaderCreateButton from '~/containers/OrderTracking/HeaderCreateButton'
import DriverChatButton from './DriverChatButton'
import { EScope } from '~/types/enums/ECommonEnum'
import { DriverBanner } from './DriverBanner'

import './Header.scss'
import { useQueryStripeAccount } from '~/hooks/useQueryData'
import { Alert, Badge, ListGroup } from 'react-bootstrap'
import { useWindowSize } from 'react-use'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useRouter } from '~/hooks/useRouter'
import { usePusherProvider } from '~/contexts'
import clsx from 'clsx'
import useGeolocation from '~/hooks/useGeolocation'
import { format } from 'date-fns'

const Header = props => {
  const {
    user,
    headerTitle,
    rightButton,
    backButton,
    onBack,
    onMenu,
    children,
    rightStyle,
    createButtons,
  } = props

  const { isMobileScreen } = useBreakpoint()
  const { pusherInstance } = usePusherProvider()
  const [showAccessList, setShowAccessList] = useState(false)
  const [popoverState, setPopoverState] = useState({
    event: null,
    isOpen: false,
  })
  const [internetPopover, setInternetPopover] = useState({
    event: null,
    isOpen: false,
  })
  const windowSize = useWindowSize()
  const isCollapsed = useSelector(state => state.sidebar.isCollapsed)
  const router = useRouter()

  const { isStripeAccountFetched, stripeAccount } = useQueryStripeAccount()

  const { geoLocation } = useGeolocation()

  const isOnboardingComplete = useMemo(
    () => stripeAccount?.payoutsEnabled,
    [stripeAccount?.payoutsEnabled],
  )

  const isSellerPaymentBannerShown = useMemo(
    () =>
      router.pathname === '/payments' &&
      isStripeAccountFetched &&
      !isOnboardingComplete,
    [isOnboardingComplete, isStripeAccountFetched, router.pathname],
  )

  const marginLeft = useMemo(() => {
    if (windowSize.width <= 960) {
      return 0
    }

    if (isCollapsed) {
      return 96
    }

    return 236
  }, [isCollapsed, windowSize.width])

  const hasIssueOnInternetIcon = useMemo(
    () => pusherInstance.connection.state !== 'connected' || !geoLocation,
    [geoLocation, pusherInstance.connection.state],
  )

  const onOpenPopover = useCallback(event => {
    setPopoverState({
      event,
      isOpen: true,
    })
    setShowAccessList(false)
  }, [])

  const onClosePopover = useCallback(() => {
    setPopoverState({
      event: null,
      isOpen: false,
    })
  }, [])

  const onOpenInternetPopover = event => {
    setInternetPopover({
      event,
      isOpen: true,
    })
  }

  const onCloseInternetPopover = () => {
    setInternetPopover({
      event: null,
      isOpen: false,
    })
  }

  const {
    session,
    currentAccess,
    accessesList,
    userAccess,
    onUpdateFilter,
    onToggleAccess,
  } = useAccesses({ onClosePopover })

  return (
    <>
      <IonHeader className='Header__container d-print-none'>
        <IonToolbar style={{ height: '100%' }}>
          <IonButtons slot='start'>
            {isMobileScreen && <IonMenuButton slot='start' onClick={onMenu} />}
            {!isMobileScreen && (
              <img
                className='header-logo'
                alt='Concord Materials'
                src='/logo.png'
                onClick={onOpenPopover}
              />
            )}
            {(backButton || (user && user.userType == 'Company')) && (
              <BackButton onBack={onBack} />
            )}
            <HeaderCompanyChip />
            {session?.user?.currentScope === EScope.driverFleet && (
              <div style={{ marginLeft: 4 }}>
                <DriverChatButton />
              </div>
            )}
          </IonButtons>
          {!children && <IonTitle>{headerTitle}</IonTitle>}
          {children}
          <IonButtons
            slot='end'
            style={
              rightStyle ||
              (!children ? { justifyContent: 'flex-end' } : undefined)
            }
          >
            {rightButton}

            {createButtons && <HeaderCreateButton options={createButtons} />}
            {/*{session?.user?.currentScope === EScope.driverFleet && (
              <DriverChatButton />
            )}*/}

            <span
              className={clsx('clickable Header__internetButton', {
                hasError: hasIssueOnInternetIcon,
              })}
              onClick={onOpenInternetPopover}
            >
              <InternetIcon
                size={20}
                color={
                  hasIssueOnInternetIcon
                    ? 'var(--bs-danger)'
                    : 'var(--bs-success)'
                }
              />
            </span>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <DriverBanner />

      {isSellerPaymentBannerShown ? (
        <div style={{ marginLeft }}>
          <Alert style={{ fontSize: 14, marginBottom: 0 }} variant='danger'>
            You can process payments but payouts setup not complete, click{' '}
            <Link to='/embedded_payments'>here</Link> to continue.
          </Alert>
        </div>
      ) : null}

      <IonPopover {...popoverState} mode='ios' onDidDismiss={onClosePopover}>
        <HeaderPopOver
          showOverlay={popoverState.isOpen}
          showAccessList={showAccessList}
          accessesList={accessesList}
          setShowAccessList={setShowAccessList}
          currentAccess={currentAccess}
          session={session}
          onUpdateFilter={onUpdateFilter}
          onToggleAccess={onToggleAccess}
          userAccess={userAccess}
        />
      </IonPopover>

      <IonPopover
        {...internetPopover}
        onDidDismiss={onCloseInternetPopover}
        mode='ios'
        side='bottom'
        alignment='start'
        className='Header__popover'
      >
        <ListGroup style={{ fontSize: 14 }}>
          <ListGroup.Item className='d-flex justify-content-between align-items-start'>
            <div className='ms-2 me-auto'>
              <div className='fw-bold'>Pusher</div>
            </div>
            <Badge
              bg={
                pusherInstance.connection.state === 'connected'
                  ? 'success'
                  : 'danger'
              }
              pill
            >
              {pusherInstance.connection.state}
            </Badge>
          </ListGroup.Item>
          <ListGroup.Item className=''>
            <div className='d-flex justify-content-between align-items-start ms-2 me-auto'>
              <div className='fw-bold'>Location</div>

              <Badge bg={geoLocation ? 'success' : 'danger'} pill>
                {geoLocation ? 'connected' : 'error'}
              </Badge>
            </div>
            {geoLocation && (
              <div className='ms-2'>
                {geoLocation?.coords ? (
                  <>
                    <Badge
                      bg='info'
                      style={{ fontSize: 10 }}
                    >{`${geoLocation.coords.latitude}, ${geoLocation.coords.longitude}`}</Badge>
                    <Badge
                      bg='secondary'
                      className='ms-2'
                      style={{ fontSize: 10 }}
                    >
                      {format(new Date(geoLocation.timestamp), 'MMM-d H:m:ss')}
                    </Badge>
                  </>
                ) : null}
              </div>
            )}
          </ListGroup.Item>
        </ListGroup>
      </IonPopover>
    </>
  )
}
export default Header
