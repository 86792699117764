import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IAutoCharge, IGetAutoChargesParams } from '~/types/models/IAutoCharge'
import useModifyAutoCharges from './useModifyAutoCharges'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryAutoCharges = (
  params: IGetAutoChargesParams = {},
  options?: Partial<UseQueryOptions<IAutoCharge[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'autoCharges',
      sessionUser?.id,
      buildGetUrl(apiClient.autoCharges.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.autoCharges.get(params)
      return response.autoCharges
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const autoCharges = useMemo(() => data || [], [data])

  const { addAutoCharge, updateAutoCharge, removeAutoCharge } =
    useModifyAutoCharges(params)

  return {
    autoCharges,
    isLoadingAutoCharges: isLoading,
    addAutoCharge,
    updateAutoCharge,
    removeAutoCharge,
  }
}

export default useQueryAutoCharges
