import React, { useEffect, useState } from 'react'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import clsx from 'clsx'
import { ReusableDatePicker } from '~/components/shared'

export interface IRTEditCellDateFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  type?: string
}

const RTEditCellTimeField = <TData extends IReusableTableRowData>(
  props: IRTEditCellDateFieldProps<TData>,
) => {
  const { cell, table } = props

  const {
    getState,
    options: { onCellEditEnd },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()

  const cellValue = cell.getValue<any>()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const [value, setValue] = useState(() => cell.getValue<string | null>())

  const dateFieldProps =
    typeof column.columnDef.editDateFieldProps === 'function'
      ? column.columnDef.editDateFieldProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const handleChange = (date: string | null) => {
    setValue(date)
  }

  const handleBlur = () => {
    if (value !== cell.getValue<string>()) {
      saveInputValueToRowCache(value || '')
      setEditingCell(null)
      if (!isCreating) {
        onCellEditEnd && onCellEditEnd(value, cell)
      }
    }
  }

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      editInputRefs.current[column.id]?.blur()
    }
  }

  useEffect(() => {
    setValue(cellValue)
  }, [cellValue])

  useEffect(() => {
    if (dateFieldProps?.value !== undefined) {
      setValue(dateFieldProps?.value as any)
    }
  }, [dateFieldProps?.value])

  return (
    <ReusableDatePicker
      {...dateFieldProps}
      showIcon={false}
      inputClassName={clsx(
        'RTEditCellDateField__container',
        column.columnDef.align,
      )}
      ref={(inputRef: any) => {
        if (inputRef) {
          editInputRefs.current[column.id] = inputRef
        }
      }}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleEnterKeyDown}
      portalId='root-portal'
    />
  )
}

export default RTEditCellTimeField
