import { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useTableWorkerColumns from './useTableWorkerColumns'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import _ from 'lodash'
import { produce } from 'immer'
import { toast } from 'react-toastify'

import {
  DialogWorkerForm,
  ReusableTable,
  EditIcon,
  CheckHqIcon,
  PayrollIcon,
  DeleteIcon,
  LinkIcon,
} from '~/components/shared'
import DialogCreateCheckEmployee from '../DialogCreateCheckEmployee'

import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { moduleName } from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'

import './WorkersContainerTable.scss'
import buildFullName from '~/utils/buildFullName'
import {
  EFieldType,
  ERTDisplayColumnId,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { CheckComponentsForWorker } from '~/containers/PayrollsContainer/components/molecule/CheckComponentsForWorker'
import {
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
  useQueryWorkPayTypes,
} from '~/hooks/useQueryData'
import WorkerLinkModal from './WorkerLinkModal'

export const WorkersContainerTable = props => {
  const {
    tableHeight,
    enableRowSelection,
    enableMultiRowSelection,
    onRowSelectionChange,
    state,
    afterCreateCheckEmployee: afterCreateCheckEmployeeProp,
    style,
  } = props

  const [expanded, setExpanded] = useState({})
  const [workerLinkModalState, setWorkerLinkModalState] = useState({
    isOpen: false,
    workerCheckUid: null,
  })

  const [workerFormState, setWorkerFormState] = useState({
    initialValues: {},
    open: false,
  })
  const [dialogCreateCheckEmployeeState, setDialogCreateCheckEmployeeState] =
    useState({
      isOpen: false,
      userData: null,
    })
  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'status',
      value: 'active',
    },
  ])

  const filterParams = useMemo(() => {
    const params = {}
    columnFilters.forEach(({ id, value }) => {
      params[id] = value
    })
    return params
  }, [columnFilters])

  const windowSize = useWindowSize()
  const { confirmation } = useConfirmationProvider()

  const {
    workerUsers,
    isLoadingUsersData,
    updateWorker,
    updateDriver,
    updatePerson,
    updateDriverFleet,
    removeWorker,
  } = useQueryUsers({
    workerParams: {
      filters: filterParams,
    },
  })

  const { isLoadingWorkPayTypes } = useQueryWorkPayTypes()
  const { isLoadingTruckFleetsData } = useQueryTruckFleets()
  const { isLoadingTerminals } = useQueryTerminals()

  const isTableLoading =
    isLoadingUsersData ||
    isLoadingWorkPayTypes ||
    isLoadingTruckFleetsData ||
    isLoadingTerminals

  const currentCompany = useSelector(selectMyCurrentCompany)

  const filterOptions = [
    {
      field: 'status',
      label: 'Status',
      type: EFieldType.multipleSelect,
      options: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Inactive',
          value: 'inactive',
        },
      ],
    },
  ]

  const handleOpenCreateForm = useCallback(() => {
    setWorkerFormState({
      initialValues: {},
      open: true,
    })
  }, [])

  const onCloseWorkerLink = () => [
    setWorkerLinkModalState({
      isOpen: false,
      workerCheckUid: null,
    }),
  ]

  const handleCloseForm = useCallback(() => {
    setWorkerFormState({
      initialValues: {},
      open: false,
    })
  }, [])

  const onCloseDialogCreateCheckEmployee = useCallback(() => {
    setDialogCreateCheckEmployeeState({
      isOpen: false,
      userData: null,
    })
  }, [])

  const onOpenCheckEmployeeDialog = useCallback(userData => {
    setDialogCreateCheckEmployeeState({
      userData,
      isOpen: true,
    })
  }, [])

  const onDeleteWorker = useCallback(
    async rowData => {
      const { worker, person } = rowData
      const fullName = buildFullName(person)
      const result = await confirmation({
        message: `Are you sure you want to delete worker #${fullName}`, //id?
      })

      if (result === EYesNo.Yes) {
        if (!worker.id) {
          toast.error(toastMessages.deleteError)
          return
        }
        try {
          const { errors } = await apiClient.workers.delete(worker.id)
          if (_.size(errors) > 0) {
            toast.error(errors[0])
          } else {
            removeWorker(worker.id)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(error.message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const afterCreateCheckEmployee = useCallback(
    async ({ worker }) => {
      if (worker.id) {
        try {
          onCloseDialogCreateCheckEmployee()
          const res = await apiClient.workers.getById(worker.id)
          updateWorker(res.id, res)
          afterCreateCheckEmployeeProp &&
            afterCreateCheckEmployeeProp({ worker })
        } catch (error) {
          toast.error('Worker is not found')
        }
      } else {
        toast.error('Worker is not found')
      }
    },
    [
      afterCreateCheckEmployeeProp,
      onCloseDialogCreateCheckEmployee,
      updateWorker,
    ],
  )

  const onOpenDialogEditWorker = useCallback(rowData => {
    const userPayload = {
      id: rowData.id,
      driver: rowData.driver,
      driverFleet: rowData.driverFleet,
      person: rowData.person,
      user: rowData,
      userAccess: rowData.userAccess,
      worker: rowData.worker,
    }

    setWorkerFormState({
      open: true,
      initialValues: userPayload,
    })
  }, [])

  const updateDriverData = async (rowData, payload) => {
    const driverId = rowData.driver?.id
    if (driverId) {
      await apiClient.drivers.update(driverId, {
        driver: payload,
      })
      updateDriver(driverId, payload)
    } else {
      toast.error('Driver fleet was not found!')
    }
  }

  const { columns } = useTableWorkerColumns({
    updateDriverData,
  })

  const updateWorkerData = async (value, cell) => {
    const [, field] = cell.column.id.split('.')
    const workerId = cell.row.original.worker.id
    await apiClient.workers.update(workerId, {
      [field]: value,
    })
    updateWorker(workerId, {
      [field]: value,
    })
  }

  const updateDriverFleetData = async (value, cell) => {
    const [, field] = cell.column.id.split('.')
    const driverFleetId = cell.row.original.driverFleet?.id
    if (driverFleetId) {
      await apiClient.driverFleets.update(driverFleetId, {
        [field]: value,
      })
      updateDriverFleet(driverFleetId, {
        [field]: value,
      })
    } else {
      toast.error('Driver fleet was not found!')
    }
  }

  const updatePersonData = async (value, cell) => {
    const [, field] = cell.column.id.split('.')
    const personId = cell.row.original.person?.id
    if (personId) {
      await apiClient.people.update(personId, {
        person: {
          [field]: value,
        },
      })
      updatePerson(personId, {
        [field]: value,
      })
    } else {
      toast.error('Person data was not found!')
    }
  }

  const onCellEditEnd = (value, cell) => {
    const field = cell.column.id
    if (field.includes('worker')) {
      updateWorkerData(value, cell)
    } else if (field.includes('driverFleet')) {
      updateDriverFleetData(value, cell)
    } else if (field.includes('person')) {
      updatePersonData(value, cell)
    } else {
      toast.error('No valid field was found!')
    }
  }

  return (
    <>
      <div style={{ margin: 12, ...style }}>
        <ReusableTable
          columns={columns}
          data={workerUsers}
          tableHeight={tableHeight || windowSize.height - 220}
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning={windowSize.width > 700}
          enableRowSelection={enableRowSelection}
          enableMultiRowSelection={enableMultiRowSelection}
          manualExpanding
          companyViewProps={{
            name: moduleName.company.workers,
            description: 'Manage your workers.',
            onClickTopButton: handleOpenCreateForm,
          }}
          state={{
            isLoading: isTableLoading,
            expanded,
            columnFilters,
            ...state,
          }}
          initialState={{
            columnPinning: {
              left:
                windowSize.width > 700
                  ? [ERTDisplayColumnId.actions, 'fullName']
                  : [],
            },
            columnVisibility: {
              [ERTDisplayColumnId.expand]: false,
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: currentCompany.checkUid ? 180 : 90,
            },
          }}
          getRowId={row => row.id}
          renderRowActions={({ row }) => {
            const isCheckEmployeeCreated = Boolean(row.original.worker.checkUid)

            return [
              {
                icon: <EditIcon name='EditIcon' color='white' />,
                onClick: () => onOpenDialogEditWorker(row.original),
                tooltipProps: {
                  content: 'Edit',
                  placement: 'top',
                },
              },
              {
                icon: <CheckHqIcon color='white' />,
                color: isCheckEmployeeCreated ? 'primary' : 'secondary',
                tooltipProps: {
                  content: isCheckEmployeeCreated
                    ? 'Edit Check Employee'
                    : 'Create Check Employee',
                  placement: 'top',
                },
                isHidden: !currentCompany.checkUid,
                onClick: () => onOpenCheckEmployeeDialog(row.original),
              },
              {
                icon: <LinkIcon color='white' />,
                tooltipProps: {
                  content: 'Generate Worker Link',
                  placement: 'top',
                },
                color: expanded[row.id] ? 'success' : 'secondary',
                onClick() {
                  if (row.original.worker?.id) {
                    setWorkerLinkModalState({
                      isOpen: true,
                      workerCheckUid: row.original.worker?.checkUid,
                    })
                  } else {
                    toast.error('Worker is not found!')
                  }
                },
                isHidden: !isCheckEmployeeCreated,
              },
              {
                icon: <PayrollIcon color='white' />,
                tooltipProps: {
                  content: 'View Payroll Info',
                  placement: 'top',
                },
                color: expanded[row.id] ? 'success' : 'secondary',
                onClick: () => {
                  setExpanded(prev =>
                    produce(prev, draft => {
                      draft[row.id] = !draft[row.id]
                    }),
                  )
                },
                isHidden: !isCheckEmployeeCreated,
              },
              {
                icon: <DeleteIcon color='white' />,
                onClick: () => onDeleteWorker(row.original),
                color: 'danger',
                tooltipProps: {
                  content: 'Remove',
                  placement: 'top',
                },
              },
            ]
          }}
          renderDetailPanel={({ row }) => (
            <CheckComponentsForWorker
              workerUid={row.original.worker.checkUid}
              style={{
                minWidth: 950,
              }}
            />
          )}
          onExpandedChange={setExpanded}
          onCellEditEnd={onCellEditEnd}
          onColumnFiltersChange={setColumnFilters}
          onRowSelectionChange={onRowSelectionChange}
          filterOptions={filterOptions}
        />
      </div>

      <DialogCreateCheckEmployee
        isOpen={dialogCreateCheckEmployeeState.isOpen}
        userData={dialogCreateCheckEmployeeState.userData}
        onClose={onCloseDialogCreateCheckEmployee}
        afterCreateCheckEmployee={afterCreateCheckEmployee}
      />

      <DialogWorkerForm
        isOpen={workerFormState.open}
        defaultValues={workerFormState.initialValues}
        onClose={handleCloseForm}
        afterCreateWorker={handleCloseForm}
        afterUpdateWorker={handleCloseForm}
      />

      <WorkerLinkModal
        isOpen={workerLinkModalState.isOpen}
        workerCheckUid={workerLinkModalState.workerCheckUid}
        onClose={onCloseWorkerLink}
        workerUsers={workerUsers}
      />
    </>
  )
}

export default WorkersContainerTable
