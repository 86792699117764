import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordDropdownV2WithFormControl,
  ConcordFormDropdownV2,
  ConcordFormStructure,
  CustomToastMessage,
  IConcordFormField,
  PlusIcon,
} from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { ISalesProposalFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ISalesProposalFormData } from '~/types/models/ISalesProposal'
import { useQueryBidders, useQueryEnums } from '~/hooks/useQueryData'
import { useQuerySellerToBuyerTerminals } from '~/hooks/useQueryData/useQuerySellerToBuyerTerminals'
import DialogBiddersForm from './DialogBiddersForm'
import { INullOrUndefined } from '~/types/models/ICommonModel'
import { useRouter } from '~/hooks/useRouter'

const SalesProposalForm = (props: ISalesProposalFormProps) => {
  const { afterCreate, afterUpdate, formData, opportunityId, ...formProps } =
    props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [opportunityIdSelected, setOpportunityIdSelected] =
    useState<number | INullOrUndefined>(null)
  const [biddersForm, setBiddersForm] = useState({
    isOpen: false,
  })
  const [dontRefetchBidder, setDontRefetchBidder] = useState(false)

  const router = useRouter()

  const { typeEnumsOptions, typeEnums, isEnumsDataFetched, findTypeEnum } =
    useQueryEnums({
      model: 'sales_proposals',
      field: 'status',
    })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const {
    isSellerToBuyerTerminalsLoading,
    sellerToBuyerTerminalOptions,
    sellerToBuyerTerminals,
  } = useQuerySellerToBuyerTerminals({
    filters: {
      status: [
        'future',
        'imminent',
        'bid',
        'won',
        'active',
        'complete',
        'lost',
        'null',
      ] as any,
    },
    perPage: 1000,
  } as any)

  const opportunitySelected = sellerToBuyerTerminals.find(
    ({ id }) => id === opportunityIdSelected,
  )

  const { bidderOptions, biddersData, isBiddersDataFetching, refetchBidders } =
    useQueryBidders(
      {
        filters: {
          sellerToBuyerTerminalId: opportunityIdSelected as number,
        },
      },
      { enabled: Boolean(opportunityIdSelected) },
    )

  const bidderOfOpportunity = biddersData.find(
    ({ buyerId }) => buyerId === opportunitySelected?.buyerId,
  )

  const fields: IConcordFormField[] = [
    {
      name: 'sellerToBuyerTerminalId',
      label: 'Opportunity',
      render({ error, clearErrors, name }) {
        return (
          <ConcordFormDropdownV2
            label='Opportunity'
            isRequired
            options={sellerToBuyerTerminalOptions}
            value={opportunityIdSelected}
            onChange={(event, { value }) => {
              setOpportunityIdSelected(value)
              clearErrors([name])
              setDontRefetchBidder(true)
            }}
            isLoading={isSellerToBuyerTerminalsLoading}
            error={error}
          />
        )
      },
    },
    {
      name: 'bidderId',
      label: 'Bidder',
      render({ name, control, label, error }) {
        return (
          <ConcordDropdownV2WithFormControl
            label={label}
            isRequired
            name={name}
            control={control}
            isReadOnly={!opportunityIdSelected}
            hint={opportunityIdSelected ? '' : 'Please select Opportunity'}
            isLoading={isBiddersDataFetching}
            options={bidderOptions}
            error={error}
            extraIcons={[
              {
                Icon: <PlusIcon color='var(--bs-primary)' />,
                onClick: onOpenBiddersForm,
                tooltipContent: 'Create Bidder',
                isHidden: !opportunityIdSelected,
              },
            ]}
          />
        )
      },
    },
    {
      name: 'status',
      label: 'Status',
      type: EFieldType.singleSelect,
      options: typeEnumsOptions,
    },
  ]

  const schema = useMemo(
    () =>
      Yup.object({
        sellerToBuyerTerminalId: Yup.mixed().test(
          'required-opportunity',
          'Opportunity is required',
          () => {
            return Boolean(opportunityIdSelected)
          },
        ),
        bidderId: Yup.number()
          .required('Bidder is required')
          .typeError('Bidder is required'),
      }),
    [opportunityIdSelected],
  )

  const defaultValues = useMemo<ISalesProposalFormData>(
    () => ({
      bidderId: null,
      status: typeEnums.inProgress,
    }),
    [typeEnums.inProgress],
  )

  const onCreate = useCallback(
    async (payload: ISalesProposalFormData) => {
      const { errors, ...res } = await apiClient.salesProposals.create({
        salesProposal: {
          status: payload.status,
          bidderId: payload.bidderId,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(CustomToastMessage as any, {
          data: {
            onClickButton: () => {
              router.push(
                `/opportunities/${opportunityIdSelected}?bidder=${res.bidderId}`,
              )
            },
            message: toastMessages.createSuccess,
            buttonText: 'View',
          },
        })
      }
    },
    [afterCreate, opportunityIdSelected, router],
  )

  const onUpdate = useCallback(
    async (payload: ISalesProposalFormData) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.salesProposals.update(
          formData.id,
          {
            salesProposal: {
              status: payload.status,
              bidderId: payload.bidderId,
            },
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: ISalesProposalFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  const onOpenBiddersForm = () => {
    setBiddersForm({
      isOpen: true,
    })
  }

  const onCloseBiddersForm = () => {
    setBiddersForm({
      isOpen: false,
    })
  }

  useEffect(() => {
    if (!isUpdating && isEnumsDataFetched) {
      formRef.current?.setValue('status', typeEnums.inProgress)
    }
  }, [isEnumsDataFetched, isUpdating, typeEnums.inProgress])

  useEffect(() => {
    if (formData?.bidderId && !dontRefetchBidder) {
      setIsLoading(true)
      apiClient.bidders
        .getById(formData?.bidderId as number)
        .then(res => {
          setOpportunityIdSelected(res.sellerToBuyerTerminalId)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      formRef.current?.setValue('bidderId', bidderOfOpportunity?.id)
    }
  }, [bidderOfOpportunity, formData?.bidderId, dontRefetchBidder, isUpdating])

  useEffect(() => {
    if (typeof formData?.status === 'string') {
      const status = findTypeEnum(formData?.status)
      formRef.current?.setValue('status', status.value)
    }
  }, [findTypeEnum, formData?.status])

  useEffect(() => {
    if (!isUpdating && opportunityId) {
      setOpportunityIdSelected(opportunityId)
    }
  }, [isUpdating, opportunityId])

  return (
    <div>
      <ConcordFormStructure
        {...formProps}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
      <DialogBiddersForm
        isOpen={biddersForm.isOpen}
        opportunityId={opportunityIdSelected}
        existingBidders={biddersData}
        onClose={onCloseBiddersForm}
        afterCreate={() => {
          refetchBidders()
          onCloseBiddersForm()
        }}
      />
    </div>
  )
}

export default SalesProposalForm
