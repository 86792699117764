import {
  BreadcrumbDot,
  CommonDialogV2,
  ConcordToolbar,
  DropdownWithCustomChildren,
  GoogleMap,
  MapDirection,
  ReusableButton,
  TerminalMarker1,
  ToolTipOverlay,
  TruckInfoBox,
  useDeliveryRoutes,
  ZoomIcon,
} from '~/components/shared'

import './AssignLoadsMap.scss'
import {
  useQueryLocations,
  useQuerySellerProducts,
  useQueryTerminals,
  useQueryTruckFleets,
} from '~/hooks/useQueryData'
import { ELocationableType } from '~/types/enums/ELocation'
import { Alert, Badge } from 'react-bootstrap'
import buildObjectName from '~/utils/buildObjectName'
import { useCallback, useEffect, useMemo, useState } from 'react'
import getGoogleLocation from '~/utils/getGoogleLocation'
import moment from 'moment'
import { OrderCardOnMap } from '~/components/maps/FleetMap/OrderCardOnMap'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import { useDeepCompareEffect } from 'react-use'
import findValidLatLng from '~/utils/findValidLatLng'

const AssignLoadsMap = ({
  load,
  truckFleet: truckFleetProp,
  showModal,
  onDismissModal,
}) => {
  const [pathInforFromTruckToPickUp, setPathInforFromTruckToPickUp] =
    useState(null)
  const [zoomTo, setZoomTo] = useState('allMarkers')
  const [showBreadcrumb, setShowBreadcrumb] = useState(false)
  const [loadingBreadcrumbs, setLoadingBreadcrumbs] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [breadcrumbLoads, setBreadcrumbLoads] = useState([])
  const [breadcrumFilterData, setBreadcrumFilterData] = useState({})

  const { findTruckFleetById, isLoadingTruckFleetsData } = useQueryTruckFleets()

  const isTruckOnLoad = Boolean(load)

  const breadcrumbWaypoints = useMemo(() => {
    if (breadcrumbLoads.length <= 1) {
      return null
    }
    const firstData = findValidLatLng(breadcrumbLoads, 'first')
    const lastData = findValidLatLng(breadcrumbLoads, 'last')

    const waypoints = breadcrumbLoads
      .filter(
        ({ id, lat, lng }) =>
          ![firstData?.id, lastData?.id].includes(id) && lat && lng,
      )
      .map(({ lat, lng }) => ({
        location: {
          lat: Number(lat || 0),
          lng: Number(lng || 0),
        },
      }))

    const startingPoint = new window.google.maps.LatLng(
      Number(firstData?.lat || 0),
      Number(firstData?.lng || 0),
    )
    const endingPoint = new window.google.maps.LatLng(
      Number(lastData?.lat || 0),
      Number(lastData?.lng || 0),
    )

    return {
      from: startingPoint,
      to: endingPoint,
      waypoints,
    }
  }, [breadcrumbLoads])

  const calculateTravelTime = useCallback(travelTime => {
    if (travelTime) {
      const result = Math.ceil(travelTime / 60)
      return result
    }
    return null
  }, [])

  const calculateDistance = useCallback(distance => {
    if (distance) {
      const result = distance / 1609.34
      return Number(result.toFixed(2))
    }
    return null
  }, [])

  const infoOfPathFromTruckToPickUp = useMemo(() => {
    if (pathInforFromTruckToPickUp) {
      const travelTime = calculateTravelTime(
        pathInforFromTruckToPickUp.travelTime,
      )
      const distance = calculateDistance(pathInforFromTruckToPickUp.distance)
      const eta = travelTime
        ? moment().add({ minute: travelTime }).format('HH:mm')
        : null

      const list = [
        {
          label: 'Travel time',
          value: `${travelTime} mins`,
          isHidden: !travelTime,
        },
        {
          label: 'ETA',
          value: eta,
          isHidden: !eta,
        },
        {
          label: 'Distance',
          value: `${distance} mi`,
          isHidden: !distance,
        },
      ]

      return list
        .filter(({ isHidden }) => !isHidden)
        .map(({ label, value }) => (
          <div key={label} style={{ marginTop: 4 }}>
            <span>{label}: </span>
            <span>{value}</span>
          </div>
        ))
    }
    return null
  }, [calculateDistance, calculateTravelTime, pathInforFromTruckToPickUp])

  const { findTerminalById } = useQueryTerminals(
    {},
    { enabled: !isTruckOnLoad },
  )

  const truckFleet = load
    ? findTruckFleetById(load?.truckFleetId)
    : truckFleetProp

  const { findSellerProductById, isLoadingSellerProducts } =
    useQuerySellerProducts()

  const sellerProduct = findSellerProductById(load.sellerProductId)

  const {
    locationsData: [truckLocation],
    isLocationsDataLoading,
  } = useQueryLocations(
    {
      filters: {
        locationableId: truckFleet?.truckId,
        locationableType: ELocationableType.Truck,
      },
    },
    {
      enabled: Boolean(truckFleet?.truckId && showModal),
      refetchInterval: 60000,
    },
  )
  const truckPickUpTerminal = findTerminalById(truckFleet?.terminalId)
  const truckParkTerminal = findTerminalById(truckFleet?.truck?.parkTerminalId)

  const truckPickUpTerminalLocation = getGoogleLocation(
    truckPickUpTerminal?.location?.lat,
    truckPickUpTerminal?.location?.lng,
  )
  const truckParkTerminalLocation = getGoogleLocation(
    truckParkTerminal?.location?.lat,
    truckPickUpTerminal?.location?.lng,
  )
  const truckGoogleLocation = getGoogleLocation(
    truckLocation?.lat,
    truckLocation?.lng,
  )

  const isPickUpTheSameParking =
    truckPickUpTerminal?.id === truckParkTerminal?.id

  const truckFleetBounding = useMemo(() => {
    const bounding = []
    const truck = getGoogleLocation(truckLocation?.lat, truckLocation?.lng)
    ;[truck, truckPickUpTerminalLocation, truckParkTerminalLocation]
      .filter(Boolean)
      .forEach(location => {
        bounding.push(location)
      })

    return bounding
  }, [
    truckLocation?.lat,
    truckLocation?.lng,
    truckParkTerminalLocation,
    truckPickUpTerminalLocation,
  ])

  const isModalLoading =
    isLoadingTruckFleetsData ||
    isLoadingSellerProducts ||
    isLocationsDataLoading

  const {
    renderDeliveryRoutes,
    truckGeofence,
    sellerTerminalLocation,
    buyerTerminalLocation,
    returnTerminalLocation,
    endLocation,
  } = useDeliveryRoutes({
    loadId: load?.id,
    isShow: Boolean(load?.id && truckLocation),
    truckLocation,
    load,
    showBreadcrumb: false,
  })

  const boundingDeliveryRoute = useMemo(() => {
    const bounding = []
    if (zoomTo === 'allMarkers') {
      if (truckGeofence) {
        bounding.push(truckGeofence)
      }
      if (sellerTerminalLocation) {
        bounding.push(sellerTerminalLocation)
      }
      if (buyerTerminalLocation) {
        bounding.push(buyerTerminalLocation)
      }
      if (returnTerminalLocation) {
        bounding.push(returnTerminalLocation)
      }
    } else if (zoomTo === 'truckAndDestination') {
      if (truckGeofence) {
        bounding.push(truckGeofence)
      }
      if (endLocation) {
        bounding.push(endLocation)
      }
    } else {
      if (truckGeofence) {
        bounding.push(truckGeofence)
      }
    }

    return bounding
  }, [
    buyerTerminalLocation,
    returnTerminalLocation,
    sellerTerminalLocation,
    truckGeofence,
    zoomTo,
    endLocation,
  ])

  const dateFilterInApiCall = useMemo(() => {
    const stHours = breadcrumFilterData.time?.startTime
      ? moment(breadcrumFilterData.time.startTime).get('hours')
      : undefined
    const stMinutes = breadcrumFilterData.time?.startTime
      ? moment(breadcrumFilterData.time.startTime).get('minutes')
      : undefined
    const etHours = breadcrumFilterData.time?.endTime
      ? moment(breadcrumFilterData.time.endTime).get('hours')
      : undefined
    const etMinutes = breadcrumFilterData.time?.endTime
      ? moment(breadcrumFilterData.time.endTime).get('minutes')
      : undefined
    let startTime = breadcrumFilterData.date?.startDate
    let endTime = breadcrumFilterData.date?.endDate
    if (stHours && startTime) {
      startTime = moment(startTime).set({ hours: stHours })
    }
    if (stMinutes && startTime) {
      startTime = moment(startTime).set({ minutes: stMinutes })
    }
    if (etHours && endTime) {
      endTime = moment(endTime).set({ hours: etHours })
    }
    if (etMinutes && endTime) {
      endTime = moment(endTime).set({ minutes: etMinutes })
    }
    return {
      startTime: startTime ? moment(startTime).toISOString() : undefined,
      endTime: endTime ? moment(endTime).toISOString() : undefined,
    }
  }, [breadcrumFilterData])

  const fetchBreadcrumbs = useCallback(async () => {
    setLoadingBreadcrumbs(true)
    setBackendError('')
    try {
      const response = await apiClient.breadcrumbLoads.get({
        filters: {
          loadId: load.id,
        },
      })
      if (response.breadcrumbLoads.length === 0) {
        const response2 = await apiClient.breadcrumbLoads.getById(
          truckFleet.id,
          {
            loadId: load.id,
            ...dateFilterInApiCall,
          },
        )
        if (response2.breadcrumbLoad?.error) {
          setBackendError(response2.breadcrumbLoad.error)
        } else if ((response2.breadcrumbLoad || []).length === 0) {
          setBackendError(
            `No Breadcumb Data found for Truck #${truckFleet?.truck?.name}`,
          )
        } else {
          setBreadcrumbLoads(response2.breadcrumbLoads)
        }
      } else {
        setBreadcrumbLoads(response.breadcrumbLoads)
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.createError)
    } finally {
      setLoadingBreadcrumbs(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load?.id, dateFilterInApiCall])

  useEffect(() => {
    return () => {
      setZoomTo('allMarkers')
    }
  }, [])

  useDeepCompareEffect(() => {
    if (showBreadcrumb) {
      fetchBreadcrumbs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBreadcrumb, dateFilterInApiCall])

  return (
    <CommonDialogV2
      isOpen={showModal}
      onClose={onDismissModal}
      isHiddenOkButton
      size='lg'
      isLoading={isModalLoading}
      title={
        <div>
          {load && <Badge style={{ fontSize: 13 }}>LD # {load?.num}</Badge>}
          {sellerProduct && (
            <ToolTipOverlay content='Product' placement='top'>
              <Badge style={{ fontSize: 13, marginLeft: 8 }} bg='danger'>
                {buildObjectName({
                  name: sellerProduct?.name,
                  code: sellerProduct?.code,
                })}
              </Badge>
            </ToolTipOverlay>
          )}
          {isTruckOnLoad && (
            <>
              <DropdownWithCustomChildren
                className='make-custom-dropdown-inline no-hover'
                showSearchField={false}
                options={[
                  {
                    label: 'Zoom to all markers',
                    value: 'allMarkers',
                  },
                  {
                    label: 'Zoom to truck and next destination',
                    value: 'truckAndDestination',
                  },
                  {
                    label: 'Zoom to Truck',
                    value: 'truck',
                  },
                ]}
                value={zoomTo}
                onChange={(event, { value }) => {
                  setZoomTo(value)
                }}
              >
                <ReusableButton style={{ marginLeft: 8 }}>
                  <ZoomIcon color='white' />
                </ReusableButton>
              </DropdownWithCustomChildren>

              <ReusableButton
                style={{ marginLeft: 8, fontSize: 12 }}
                onClick={async () => {
                  const nextShow = !showBreadcrumb
                  setShowBreadcrumb(nextShow)
                }}
                isLoading={loadingBreadcrumbs}
                variant={showBreadcrumb ? 'warning' : 'primary'}
              >
                {showBreadcrumb ? 'Hide' : 'Show'} Breadcrumbs
              </ReusableButton>

              {showBreadcrumb && (
                <div className='AssignLoadsMap__filterSection'>
                  <ConcordToolbar
                    isHiddenSearchBar
                    filterOptions={[
                      {
                        label: 'Date',
                        field: 'date',
                        type: EFieldType.dateRange,
                      },
                      {
                        label: 'Time',
                        field: 'time',
                        type: EFieldType.time,
                      },
                    ]}
                    filterData={breadcrumFilterData}
                    onFilterChange={setBreadcrumFilterData}
                  />
                </div>
              )}
            </>
          )}
        </div>
      }
    >
      <div className='AssignLoadsMap__mapContainer'>
        {backendError && (
          <Alert style={{ fontSize: 13 }} variant='danger'>
            {backendError}
          </Alert>
        )}
        <GoogleMap
          bounding={isTruckOnLoad ? boundingDeliveryRoute : truckFleetBounding}
          zoom={load ? 5 : 3}
        >
          {isTruckOnLoad ? (
            renderDeliveryRoutes
          ) : (
            <>
              {truckPickUpTerminal && (
                <TerminalMarker1
                  terminal={truckPickUpTerminal}
                  isShowInfoBox
                  title={
                    isPickUpTheSameParking
                      ? 'Next Pickup and Parking Location'
                      : 'Next Pickup Location'
                  }
                  extraInfo={infoOfPathFromTruckToPickUp}
                />
              )}
              {truckParkTerminal && !isPickUpTheSameParking ? (
                <TerminalMarker1
                  terminal={truckParkTerminal}
                  isShowInfoBox
                  title='Parking Location'
                />
              ) : null}

              {truckGoogleLocation && truckPickUpTerminalLocation ? (
                <MapDirection
                  from={truckPickUpTerminalLocation}
                  to={truckGoogleLocation}
                  color='#1090ff'
                  onChange={info => {
                    setPathInforFromTruckToPickUp(info)
                  }}
                />
              ) : null}
            </>
          )}

          {showBreadcrumb ? (
            <>
              {breadcrumbLoads.map(data => (
                <BreadcrumbDot key={data.id} breadcrumbLoad={data} />
              ))}
              {breadcrumbWaypoints && (
                <MapDirection {...breadcrumbWaypoints} color='#787878' />
              )}
            </>
          ) : null}

          {truckLocation && truckFleet ? (
            <TruckInfoBox truck={truckFleet.truck} location={truckLocation} />
          ) : null}
          {load && truckFleet?.truck ? (
            <OrderCardOnMap
              truck={truckFleet.truck}
              truckLocationUpdatedAt={truckLocation?.updatedAt}
              load={load}
            />
          ) : null}
        </GoogleMap>
      </div>
    </CommonDialogV2>
  )
}

export default AssignLoadsMap
