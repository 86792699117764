import buildObjectName from '~/utils/buildObjectName'
import { useQueryCompanies, useQueryTerminals } from './useQueryData'

export interface IParams {
  sellerSideType: string
  buyerSideType: string
  sellerSideId: any
  buyerSideId: any
}

const useGetHierarchyLabel = () => {
  const { findCompanyById } = useQueryCompanies({})
  const { findTerminalById } = useQueryTerminals()

  const getHierarchyLabel = (params: IParams) => {
    const { sellerSideId, sellerSideType, buyerSideId, buyerSideType } = params

    let sellerLabel = ''
    let buyerLabel = ''

    if (sellerSideType === 'Seller') {
      const obj = findCompanyById(sellerSideId)
      sellerLabel = `${obj?.code} (Seller)`
    } else if (sellerSideType === 'SellerTerminal') {
      const obj = findTerminalById(sellerSideId)
      const company = findCompanyById(obj?.companyId)
      sellerLabel = `${buildObjectName({
        name: company?.code,
        code: obj?.code,
      })} (Seller Terminal)`
    }

    if (buyerSideType === 'Buyer') {
      const obj = findCompanyById(buyerSideId)
      buyerLabel = `${obj?.code} (Buyer)`
    } else if (buyerSideType === 'BuyerTerminal') {
      const obj = findTerminalById(buyerSideId)
      const company = findCompanyById(obj?.companyId)
      buyerLabel = `${company?.code} - ${
        obj?.code || obj?.name
      } (Buyer Terminal)`
    }

    return [sellerLabel, buyerLabel].filter(Boolean).join(' → ')
  }

  return { getHierarchyLabel }
}

export default useGetHierarchyLabel
