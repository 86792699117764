import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { ColorSelector } from '~/components/shared/ConcordForm'

export interface IRTEditCellSingleSelectFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  isMulti?: boolean
}

const RTEditCellColorSelectorField = <TData extends IReusableTableRowData>(
  props: IRTEditCellSingleSelectFieldProps<TData>,
) => {
  const { cell, table } = props

  const {
    getState,
    options: { onCellEditEnd },
    // refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const cellValue = cell.getValue()

  const dropdownFieldProps =
    typeof column.columnDef.editDropdownFieldProps === 'function'
      ? column.columnDef.editDropdownFieldProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const onChange = (event: any, { value, selectedOption }: any) => {
    if (value !== cell.getValue()) {
      if (!isCreating) {
        onCellEditEnd && onCellEditEnd(value, cell, selectedOption)
      }
      saveInputValueToRowCache(value)
      setEditingCell(null)
      // setValue(value)
    }
  }

  return (
    <ColorSelector
      className='RTEditCellColorSelectorField__container'
      value={cellValue as any}
      onChange={onChange}
      styles={{
        menu: (provided: any) => ({
          ...provided,
          minWidth: 120,
        }),
      }}
      {...dropdownFieldProps}
    />
  )
}

export default RTEditCellColorSelectorField
