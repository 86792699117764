import { PropsWithChildren, useRef } from 'react'
import { ControlledMenu, useClick, useMenuState } from '@szhsin/react-menu'

import { ReusableButton } from '~/components/shared'

import { produce } from 'immer'
import ColumnItem from './ColumnItem'
import _ from 'lodash'
import { IReusableButtonProps } from '../ReusableButton/ReusableButton'

export interface IDraggableItemsOverlayProps extends IReusableButtonProps {
  items: Record<string, boolean>
  setItems: React.Dispatch<
    React.SetStateAction<IDraggableItemsOverlayProps['items']>
  >
  disabledFields?: string[]
}

function DraggableItemsOverlay(
  props: PropsWithChildren<IDraggableItemsOverlayProps>,
) {
  const {
    children,
    items,
    setItems,
    disabledFields = [],
    ...buttonProps
  } = props

  const ref = useRef(null)
  const [menuState, toggleMenu] = useMenuState({ transition: true })
  const anchorProps = useClick(menuState.state, toggleMenu)

  const sortedData = Object.keys(items)
    .sort((a, b) => Number(items[b]) - Number(items[a]))
    .reduce((acc: { [key: string]: boolean }, key: string) => {
      acc[key] = items[key]
      return acc
    }, {})

  const onCheck = (key: string) => () => {
    setItems(prev =>
      produce(prev, draft => {
        draft[key] = !draft[key]
      }),
    )
  }

  const onReorder = (currentIndex: number, nextIndex: number) => {
    const currentFields = Object.keys(sortedData)
    const temp = currentFields[currentIndex]
    currentFields[currentIndex] = currentFields[nextIndex]
    currentFields[nextIndex] = temp
    const newColumns: Record<string, boolean> = {}
    currentFields.forEach(field => {
      newColumns[field] = sortedData[field]
    })
    setItems(newColumns)
  }

  return (
    <>
      <ReusableButton
        variant='secondary'
        ref={ref}
        {...anchorProps}
        {...buttonProps}
      >
        {children}
      </ReusableButton>
      <ControlledMenu
        {...menuState}
        menuStyle={{ minWidth: 250 }}
        anchorRef={ref}
        onClose={({ reason }) => {
          if (reason === 'blur') {
            toggleMenu(false)
          }
        }}
      >
        {Object.keys(sortedData).map((key, index) => (
          <ColumnItem
            key={key}
            checked={sortedData[key]}
            label={_.startCase(key)}
            onChange={onCheck(key)}
            index={index}
            onReorder={onReorder}
            canDrag={!disabledFields.includes(key)}
          />
        ))}
      </ControlledMenu>
    </>
  )
}

export default DraggableItemsOverlay
