import { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useMainPage from './hooks'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import { useCompanyViewProvider } from '~/contexts'

import { IonApp, IonPage } from '@ionic/react'
import {
  Header,
  Menu,
  PopupReenableLocationPermission,
  StaticMenu,
} from '~/components/shared'
import LocationTracking from '../../LocationTracking'
import {
  resetReduxStore,
  getUserGroups,
} from '~/redux/actions/userAccessActions'
import lastFetchedAtSlice from '~/redux/reducers/data/lastFetchedAt'
import { IonAlert, IonLoading } from '@ionic/react'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { sessionService } from 'redux-react-session'
import { pushRegisterService } from '~/services/PushRegisterService'

import {
  selectUserGroupsLastFetchedAt,
  selectSessionUser,
  selectSessionChecked,
  selectSessionAuthenticated,
  getSessionState,
  selectUsersDriverFleet,
} from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import clsx from 'clsx'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import { useQueryTruckFleets } from '~/hooks/useQueryData'
import useGeolocation from '~/hooks/useGeolocation'
import { KEYBOARD_CODE } from '~/utils/utils'
import { EKeyStroke, useKeyStrokes } from '~/hooks/useKeyStrokes'
import usePusherDriver from '~/hooks/usePusher/usePusherDriver'

const MainPage = ({ className, children, loading }) => {
  const currentUser = useSelector(selectSessionUser)
  const sessionChecked = useSelector(selectSessionChecked)
  const sessionAuthenticated = useSelector(selectSessionAuthenticated)
  const sessionState = useSelector(getSessionState)
  const lastFetchedAt = useSelector(selectUserGroupsLastFetchedAt)

  const driverFleet = useSelector(selectUsersDriverFleet)

  usePusherDriver()

  const { onOpenCompanyViewDialog } = useCompanyViewProvider()

  const keystrokeAction = useCallback(
    async () => {
      if (window.location.pathname.includes('companies')) {
        return
      }
      onOpenCompanyViewDialog()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useKeyStrokes([
    {
      keyCode: KEYBOARD_CODE.K,
      keystroke: EKeyStroke.metaAndCtrl,
      action: keystrokeAction,
    },
  ])

  const { findTruckFleetById } = useQueryTruckFleets()

  const truckFleetData = findTruckFleetById(driverFleet?.currentTruckFleetId)

  const { geoLocation } = useGeolocation({
    isEnabled: Boolean(truckFleetData?.truck?.gpsInterval),
    interval: truckFleetData?.truck?.gpsInterval
      ? truckFleetData?.truck?.gpsInterval * 1000
      : 60000,
  })

  const dispatch = useDispatch()

  const { shouldTrackLocation, header } = useMainPage()
  const { isMobileScreen } = useBreakpoint()

  const [isOpenUpdatePassword, setIsOpenUpdatePassword] = useState(false)
  const [isLoadingUpdatePwd, setIsLoadingUpdatePwd] = useState(false)

  const handleSubmitLogin = useCallback(async alertData => {
    setIsOpenUpdatePassword(false)
    setIsLoadingUpdatePwd(true)
    try {
      const { password, email } = alertData
      const user = { password, email }
      const response = await apiClient.users.signIn(user)
      sessionService.saveUser(response)
      pushRegisterService.registerPushNotification(user)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages)
      setIsOpenUpdatePassword(true)
    } finally {
      setIsLoadingUpdatePwd(false)
    }
  }, [])

  useEffect(() => {
    if (!lastFetchedAt && currentUser.id) {
      const unix = Math.round(+new Date() / 1000)
      if (currentUser.currentScope) {
        dispatch(getUserGroups())
        dispatch(
          lastFetchedAtSlice.actions.updateLastFetchedAt({
            model: 'userGroups',
            lastFetchedAt: unix,
          }),
        )
      }
    }
  }, [lastFetchedAt, currentUser, dispatch])

  useEffect(() => {
    if (!sessionAuthenticated) {
      dispatch(resetReduxStore())
    }
  }, [sessionAuthenticated, dispatch])

  useEffect(() => {
    if (currentUser?.needToUpdatePassword) {
      setIsOpenUpdatePassword(true)
    }
  }, [currentUser])

  useEffect(() => {
    if (geoLocation && driverFleet?.loadId) {
      apiClient.breadcrumbLoads.create({
        breadcrumbLoad: {
          loadId: driverFleet?.loadId,
          loadTimeId: null,
          eta: null,
          driverFleetId: driverFleet?.id,
          truckFleetId: driverFleet?.currentTruckFleetId,
          locationAttributes: {
            eventType: 'gps_ping',
            source: 'Concord',
            lat: geoLocation.coords.latitude,
            lng: geoLocation.coords.longitude,
            time: geoLocation.timestamp,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoLocation || {}, driverFleet?.loadId])

  return (
    <IonApp className={clsx({ 'no-margin-bottom': isMobileApp() })}>
      <IonPage
        id='menu'
        className={clsx(className, { 'no-margin-bottom': isMobileApp() })}
      >
        <PopupReenableLocationPermission />
        {!loading && sessionChecked && (
          <>
            {isMobileScreen && <Menu session={sessionState} />}
            {!isMobileScreen && <StaticMenu />}

            <Header
              name={currentUser?.firstName}
              session={sessionState}
              {...header}
            />
          </>
        )}
        {children}
        {shouldTrackLocation && <LocationTracking />}

        <IonAlert
          backdropDismiss={false}
          mode='ios'
          isOpen={isOpenUpdatePassword}
          header='Update Password'
          inputs={[
            {
              placeholder: 'Email',
              name: 'email',
              value: currentUser?.email,
              disabled: true,
            },
            {
              placeholder: 'Password',
              name: 'password',
              type: 'password',
            },
          ]}
          buttons={[
            {
              text: 'OK',
              role: 'test',
              handler: handleSubmitLogin,
            },
          ]}
        />

        <IonLoading
          isOpen={isLoadingUpdatePwd}
          mode='ios'
          cssClass='test abc'
        />
      </IonPage>
    </IonApp>
  )
}

export default MainPage
