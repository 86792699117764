const findValidLatLng = (arr: any, position: 'first' | 'last') => {
  if (position === 'first') {
    return (
      arr.find((item: any) => item.lat !== null && item.lng !== null) || null
    )
  } else {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].lat !== null && arr[i].lng !== null) {
        return arr[i]
      }
    }
    return null
  }
}

export default findValidLatLng
