import { useCallback, useMemo, useState } from 'react'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useWindowSize } from 'react-use'

import { IonIcon } from '@ionic/react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import {
  ContainerSearchBar,
  DraggableItemsOverlay,
  GroupIcon,
  ToolTipOverlay,
} from '~/components/shared'
import MultiselectOrderDropDown from '~/components/shared/MultiselectOrderDropdown'
import RenderFilters from '~/components/shared/ContainerFilterV2/RenderFilters'
import FilterModalPopup from '../ContainerFilterV2/FilterModalPopup'
import ClearFiltersButton from '../ContainerFilterV2/ClearFiltersButton'
import { CSVLink } from 'react-csv'

import _ from 'lodash'
import clsx from 'clsx'
import { cogOutline, funnelOutline, cloudDownloadOutline } from 'ionicons/icons'

import './ConcordToolbar.scss'
import { Unless } from 'react-if'

const DEFAULT_SEARCH_BAR_PROPS = {
  searchBarPlaceholder: 'Search...',
  searchBarParent: 'ContainerFilter',
}

function ConcordToolbar(props) {
  const {
    searchBarProps,
    sortOptions,
    columnOptions,
    onSortChange,
    onColumnChange,
    filterOptions,
    filterData,
    onFilterChange,
    className,
    onSearchBarChange,
    searchBarValue,
    renderExtraButtons,
    renderOtherEndButtons,
    exportingData,
    csvFileName,
    pluralModelName,
    isHiddenSearchBar,
    filterSectionWidth,
    style,
    defaultFilterData: defaultFilterDataProp,
    canGroup,
    groupValues,
    setGroupValues,
  } = props

  const isMobile = useIsMobile()
  const windowSize = useWindowSize()

  const [showFilterModal, setShowFilterModal] = useState(false)
  const [defaultSortOptions] = useState(sortOptions)
  const [defaultFilterDataState] = useState(filterData)

  const defaultFilterData = defaultFilterDataProp || defaultFilterDataState

  const isDefaultSortOptions = useMemo(
    () => _.isEqual(defaultSortOptions, sortOptions),
    [defaultSortOptions, sortOptions],
  )

  const isDefaultFilterData = useMemo(
    () => _.isEqual(defaultFilterData, filterData),
    [defaultFilterData, filterData],
  )

  const mapSearchBarProps = useMemo(
    () => _.merge(DEFAULT_SEARCH_BAR_PROPS, searchBarProps),
    [searchBarProps],
  )

  const covertColumnOptions = useMemo(
    () =>
      columnOptions.map(({ label, field, hide, ...props }) => ({
        ...props,
        label,
        sorted: !hide,
        sortField: field,
      })),
    [columnOptions],
  )

  const handleChangeColumn = useCallback(
    newSortOptions => {
      const convertNewColumnOptions = newSortOptions.map(
        ({ label, sorted, sortField, ...props }) => ({
          ...props,
          label,
          field: sortField,
          hide: !sorted,
        }),
      )
      onColumnChange && onColumnChange(convertNewColumnOptions)
    },
    [onColumnChange],
  )

  const onShowFilterModal = useCallback(() => {
    setShowFilterModal(true)
  }, [])

  const onCloseFilterModal = useCallback(() => {
    setShowFilterModal(false)
  }, [])

  const onClearFilter = useCallback(() => {
    onFilterChange && onFilterChange(defaultFilterData)
  }, [defaultFilterData, onFilterChange])

  const calculateFilterSectionWidth = useMemo(() => {
    if (typeof filterSectionWidth === 'number') {
      return filterSectionWidth
    }
    return windowSize.width <= 950 ? 'auto' : windowSize.width - 645
  }, [filterSectionWidth, windowSize.width])

  const renderFilters = useCallback(() => {
    if (filterOptions.length > 0) {
      if (isMobile) {
        return (
          <ToolTipOverlay content='Filters' placement='bottom'>
            <ToggleButton
              size='lg'
              type='radio'
              name='narrow'
              variant='outline-primary'
              onClick={onShowFilterModal}
              className={clsx('ConcordToolbar__verticalFilterButton', {
                active: !isDefaultFilterData,
              })}
            >
              <IonIcon
                icon={funnelOutline}
                className='ConcordToolbar__verticalFilterIcon'
              />
            </ToggleButton>
          </ToolTipOverlay>
        )
      }
      return (
        <>
          <div
            className='container-filters-scroll hide-scrollbar ConcordToolbar__rootHorizontalFilter'
            style={{
              maxWidth: calculateFilterSectionWidth,
            }}
          >
            <div className={'ConcordToolbar__container'}>
              <RenderFilters
                filterData={filterData}
                filterOptions={filterOptions}
                onChange={onFilterChange}
                pluralModelName={pluralModelName}
              />
            </div>
          </div>
          {!isDefaultFilterData && (
            <ClearFiltersButton
              onFiltersReset={onClearFilter}
              label=''
              tooltip='Clear Filters'
            />
          )}
        </>
      )
    }

    return null
  }, [
    filterOptions,
    isMobile,
    isDefaultFilterData,
    filterData,
    onFilterChange,
    onClearFilter,
    onShowFilterModal,
    pluralModelName,
    calculateFilterSectionWidth,
  ])

  const tooltipColumns = useMemo(() => {
    if (covertColumnOptions.length === 0) {
      return ''
    }
    const mapLabel = _(columnOptions)
      .filter(({ hide }) => !hide)
      .map('label')
      .join(', ')

    const totalColumns = columnOptions.length
    const totalSelectedColumns = _(columnOptions)
      .filter(({ hide }) => !hide)
      .size()

    return (
      <div>
        <span>
          <strong>
            COLUMNS - {`${totalSelectedColumns} / ${totalColumns}`}
          </strong>
        </span>
        <br />
        <span>{mapLabel}</span>
      </div>
    )
  }, [columnOptions, covertColumnOptions.length])

  return (
    <>
      <div className={clsx('ConcordToolbar__root', className)} style={style}>
        <Unless condition={isHiddenSearchBar}>
          <ContainerSearchBar
            {...mapSearchBarProps}
            searchBarValue={searchBarValue}
            onSearchBarChange={onSearchBarChange}
          />
        </Unless>
        {renderExtraButtons()}
        <div className='ConcordToolbar__wrapFilterSortAndExtra'>
          <ButtonGroup className='ConcordToolbar__wrapFilterSort'>
            {(sortOptions.length > 0 || covertColumnOptions.length > 0) && (
              <div className='d-flex'>
                {sortOptions.length > 0 && (
                  <MultiselectOrderDropDown
                    options={sortOptions}
                    onChange={onSortChange}
                    isButtonGroup
                    className={clsx('ConcordToolbar__rootSortOptions', {
                      active: !isDefaultSortOptions,
                    })}
                  />
                )}
                {covertColumnOptions.length > 0 && (
                  <MultiselectOrderDropDown
                    options={covertColumnOptions}
                    onChange={handleChangeColumn}
                    isButtonGroup
                    icon={cogOutline}
                    hasDirection={false}
                    className='ConcordToolbar__rootColumnOptions'
                    tooltipProps={{
                      placement: 'bottom',
                      content: tooltipColumns,
                    }}
                  />
                )}

                {canGroup && (
                  <DraggableItemsOverlay
                    items={groupValues}
                    style={{
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                      boxShadow: 'none',
                    }}
                    setItems={setGroupValues}
                  >
                    <GroupIcon color='var(--bs-gray-800)' size={16} />
                  </DraggableItemsOverlay>
                )}

                {exportingData.length > 0 && (
                  <ToolTipOverlay content='Export to CSV' placement='bottom'>
                    <ToggleButton
                      size='lg'
                      type='radio'
                      name='narrow'
                      variant='outline-primary'
                      className='exportButton'
                    >
                      <CSVLink
                        data={exportingData}
                        filename={csvFileName || 'Unknow'}
                        separator=';'
                        target='_blank'
                      >
                        <IonIcon
                          className='Filter__icon'
                          icon={cloudDownloadOutline}
                          color='export'
                          size='small'
                          title=''
                        />
                      </CSVLink>
                    </ToggleButton>
                  </ToolTipOverlay>
                )}
              </div>
            )}
            {renderFilters()}
          </ButtonGroup>
          {renderOtherEndButtons && renderOtherEndButtons()}
        </div>
      </div>
      <FilterModalPopup
        open={showFilterModal}
        onClose={onCloseFilterModal}
        onReset={onClearFilter}
        filterData={filterData}
        filterOptions={filterOptions}
        onChangeFilters={onFilterChange}
      />
    </>
  )
}

// ConcordToolbar.propTypes = {
//   searchBarValue: PropTypes.string,
//   sortOptions: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       sorted: PropTypes.bool,
//       isAsc: PropTypes.bool,
//       sortField: PropTypes.string,
//     }),
//   ),
//   columnOptions: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       field: PropTypes.string,
//       hide: PropTypes.bool,
//     }),
//   ),
//   filterData: PropTypes.object,
//   filterOptions: PropTypes.arrayOf(
//     PropTypes.shape({
//       field: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//       type: PropTypes.oneOf([
//         'dateRange',
//         'multipleSelect',
//         'singleSelect',
//         'rangeNumber',
//       ]).isRequired,
//       options: PropTypes.arrayOf(
//         PropTypes.shape({
//           value: PropTypes.string.isRequired,
//           label: PropTypes.string.isRequired,
//         }),
//       ),
//     }),
//   ),
//   renderExtraButtons: PropTypes.func,
//   exportingData: PropTypes.array,
//   csvFileName: PropTypes.string,

//   onSortChange: PropTypes.func,
//   onSearchBarChange: PropTypes.func,
//   onFilterChange: PropTypes.func,
//   onColumnChange: PropTypes.func,
// }

ConcordToolbar.defaultProps = {
  searchBarProps: DEFAULT_SEARCH_BAR_PROPS,
  sortOptions: [],
  columnOptions: [],
  filterOptions: [],
  filterData: {},
  renderExtraButtons: () => {},
  exportingData: [],
}

export default ConcordToolbar
