import React, { useCallback, useMemo, useState } from 'react'

import { Form } from 'react-bootstrap'
import { ConcordFormLayout } from '~/components/shared'
import ReactSelect from 'react-select'
import { components } from 'react-select'

import './ConcordFormMultipleDropdownSelector.scss'
import { IonSpinner } from '@ionic/react'
import CustomMenuList from '../DropdownV2/components/CustomMenuList'

function ConcordFormMultipleDropdownSelector(props, ref) {
  const {
    options,
    onChange,
    label,
    error,
    value = [],
    name,
    className,
    removable = true,
    isRequired,
    isClearable = true,
    styles,
    isDisabled,
    extraIcons,
    components: extraComponents,
    renderForm,
    ...selectProps
  } = props

  const [inputValue, setInputValue] = useState('')
  const [prevInputValue, setPrevInputValue] = useState('')
  const [formState, setFormState] = useState({
    isOpen: false,
    optionSelected: null,
  })

  const mapValues = useMemo(() => {
    if (value.length) {
      if (typeof value[0] === 'object') {
        return value
      }
    }
    const values = []

    value.forEach(val => {
      const option = options.find(opt => opt.value === val)
      if (option) {
        values.push(option)
      }
    })

    return values
  }, [options, value])

  const handleSelectOption = useCallback(
    (selectedValues, event) => {
      const mapValue = selectedValues.map(({ value }) => value)
      onChange && onChange(event, { value: mapValue, name })
    },
    [name, onChange],
  )

  const onClickCreate = () => {
    setFormState({
      isOpen: true,
      optionSelected: null,
    })
  }

  const onCloseForm = () => {
    setFormState({
      isOpen: false,
      optionSelected: null,
    })
  }

  const isDisabledStyles = useMemo(() => {
    if (isDisabled) {
      return {
        opacity: 0.3,
        userSelect: 'none',
        pointerEvents: 'none',
      }
    }
    return {}
  }, [isDisabled])

  return (
    <ConcordFormLayout
      label={label}
      error={error}
      className={className}
      isRequired={isRequired}
      extraIcons={extraIcons}
    >
      <ReactSelect
        {...selectProps}
        onInputChange={(value, { prevInputValue }) => {
          setInputValue(value)
          setPrevInputValue(prevInputValue)
        }}
        inputValue={inputValue}
        ref={ref}
        options={options}
        classNamePrefix='ConcordFormMultipleDropdownSelector'
        name={name}
        value={mapValues}
        isMulti
        onClickCreate={onClickCreate}
        onChange={handleSelectOption}
        isClearable={isClearable}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: error ? 'red !important' : provided.borderColor,
            ...isDisabledStyles,
          }),
          multiValueRemove: provided => {
            return {
              ...provided,
              display: removable ? 'flex' : 'none',
            }
          },
          clearIndicator: provided => ({
            ...provided,
            display: removable ? 'flex' : 'none',
          }),
          menu: provided => ({
            ...provided,
            zIndex: 1000,
          }),
          ...styles,
        }}
        components={{
          MultiValueRemove: cprops => {
            const { selectProps, data } = cprops
            const { loadingIds = [] } = selectProps
            if (loadingIds.includes(data.value)) {
              return (
                <IonSpinner
                  name='lines-small'
                  style={{
                    width: 20,
                    height: 22,
                    verticalAlign: 'middle',
                  }}
                />
              )
            }
            return <components.MultiValueRemove {...cprops} />
          },
          MenuList: CustomMenuList,
          ...extraComponents,
        }}
      />
      {error && <Form.Text className='error'>{error}</Form.Text>}

      {typeof renderForm === 'function'
        ? renderForm({
            ...formState,
            inputValue,
            prevInputValue,
            setFormState,
            onCloseForm,
          })
        : null}
    </ConcordFormLayout>
  )
}

export default React.forwardRef(ConcordFormMultipleDropdownSelector)
