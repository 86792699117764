const seperateAddress = (
  addressComponents: google.maps.GeocoderAddressComponent[],
) => {
  let street = ''
  let city = ''
  let country = ''
  let state = ''
  let postalCode = ''
  addressComponents.forEach(address => {
    const { long_name, short_name, types } = address

    if (
      (types.includes('locality') || types.includes('neighborhood')) &&
      types.includes('political')
    ) {
      city = long_name
    } else if (types.includes('country') && types.includes('political')) {
      country = short_name
    } else if (
      types.includes('street_number') ||
      types.includes('route') ||
      types.includes('intersection') ||
      types.includes('park') ||
      types.includes('tourist_attraction')
    ) {
      street += ` ${long_name}`
    } else if (types.includes('postal_code')) {
      postalCode = long_name
    } else if (
      types.includes('administrative_area_level_1') &&
      types.includes('political')
    ) {
      state = short_name
    }
  })

  return { street: street.trim(), city, country, state, postalCode }
}

export default seperateAddress
