import { CommonDialogV2, ICommonDialogProps } from '~/components/shared'
import TerminalDetails from './TerminalDetails'
import { useQueryTerminals } from '~/hooks/useQueryData'

import './TerminalContainer.scss'
import { KeyValuePairItem } from '~/components/shared/ConcordForm/FormData/SellerProductForm/KeyValuePairItem'
import { apiClient } from '~/api/ApiClient'

export interface IDialogTerminalDetailsProps extends ICommonDialogProps {
  id: number
}

function DialogTerminalDetails(props: IDialogTerminalDetailsProps) {
  const { id, ...dialogProps } = props

  const {
    terminalsData: [terminalData],
    isLoadingTerminals,
    updateTerminal,
  } = useQueryTerminals({
    id,
  })

  const onUpdateTerminal = async (name: string, value: string) => {
    const response = await apiClient.terminals.update(terminalData?.id, {
      terminal: {
        [name]: value,
      },
    })
    updateTerminal(response.id, response)
  }

  return (
    <CommonDialogV2
      {...dialogProps}
      className='DialogTerminalDetails__container'
      isHiddenOkButton
      loading={isLoadingTerminals}
      fullscreen
      // title={terminalData ? `${terminalData.code} - ${terminalData.name}` : ''}
      title={
        <div>
          <KeyValuePairItem
            label='Code'
            value={terminalData?.code}
            name='code'
            onUpdate={onUpdateTerminal}
          />
          <KeyValuePairItem
            label='Name'
            value={terminalData?.name}
            name='name'
            onUpdate={onUpdateTerminal}
          />
        </div>
      }
    >
      <TerminalDetails id={id} />
    </CommonDialogV2>
  )
}

export default DialogTerminalDetails
