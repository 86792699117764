import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
import {
  useQueryInitialCompanyData,
  useQueryNewAddresses,
} from '~/hooks/useQueryData'

import {
  SearchIcon,
  ToolTipOverlay,
  ConcordFormDropdownV2,
  KeyboardIcon,
  DropdownWithCustomChildren,
  ArrowDropdownIcon,
  TerminalMap,
  LightBulbIcon,
  CloseIcon,
} from '~/components/shared'
import seperateAddress from '~/utils/seperateAddress'
import { Alert, Badge, Button } from 'react-bootstrap'
import tzLookup from 'tz-lookup'
import { components } from 'react-select'

import './styles.scss'
import buildFullAddress from '~/utils/buildFullAddress'

function ConcordFormAddressInput(props, ref) {
  const {
    onChange,
    containerClassName,
    keyword,
    alwaysShowInput,
    isShowedByDefault,
    label,
    onClickKeyboardIcon,
    onClickSearchIcon,
    value: valueProp,
    isCreatable,
    showTimezoneBadge,
    onSelectTimezone,
    timezone,
    showManualAdress,
    ...dropdownProps
  } = props

  const selectRef = useRef()

  const [show, setShow] = useState(true)
  const [isTyping, setIsTyping] = useState(false)
  const [manualAddress, setManualAddress] = useState('')
  const [isSearchingLocationOnMap, setIsSearchingLocationOnMap] =
    useState(false)
  const [objectsOnMap, setObjectsOnMap] = useState({
    location: null,
    coordinates: [],
    address: {},
  })
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const {
    newAddressesDataOptions,
    isNewAddressesDataFetched,
    isNewAddressesDataLoading,
  } = useQueryNewAddresses(
    {
      address: manualAddress,
    },
    { enabled: Boolean(manualAddress) },
  )

  const { timeZoneOptions } = useQueryInitialCompanyData(
    {},
    {
      enabled: Boolean(showTimezoneBadge),
    },
  )

  const {
    value,
    suggestions: { data, loading: isFetchingGoogleData },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 500,
    cache: 6000,
  })

  const timezoneObj = timeZoneOptions.find(({ value }) => value === timezone)

  const mapDataToOptions = useMemo(
    () =>
      data.map(({ description, place_id }) => ({
        label: description,
        value: place_id,
      })),
    [data],
  )

  const isManualSearchingAddressOptsShown = useCallback(
    opts =>
      opts.length === 0 && !isTyping && value.length > 0 && showManualAdress,
    [isTyping, showManualAdress, value.length],
  )

  const selectorOptions =
    manualAddress.length > 0 ? newAddressesDataOptions : mapDataToOptions

  const handleChange = useCallback(
    async ({ action }, { selectedOption }) => {
      if (action === 'create-option' || selectedOption.isNew) {
        onChange(
          { ...selectedOption, isNew: true },
          {
            address: {},
            fullAddress: '',
            location: {},
            placeId: selectedOption.value,
          },
        )
      } else {
        const [result] = await getGeocode({ placeId: selectedOption.value })
        const address = seperateAddress(result.address_components)
        const tz = tzLookup(
          result.geometry.location.lat(),
          result.geometry.location.lng(),
        )

        onChange &&
          onChange(selectedOption, {
            address,
            fullAddress: result.formatted_address,
            location: {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            },
            placeId: selectedOption.value,
          })
        onSelectTimezone && onSelectTimezone(tz)
        setShow(false)
        setMenuIsOpen(false)
      }
    },
    [onChange, onSelectTimezone],
  )

  const onCloseSearchOnMap = () => {
    setIsSearchingLocationOnMap(false)
    setObjectsOnMap({
      location: null,
      coordinates: [],
      address: {},
    })
  }

  useEffect(() => {
    if (typeof keyword === 'string') {
      setValue(keyword)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])

  useEffect(() => {
    if (typeof isShowedByDefault === 'boolean') {
      setShow(isShowedByDefault)
    }
  }, [isShowedByDefault])

  useEffect(() => {
    setIsTyping(false)
  }, [data])

  useEffect(() => {
    if (ref) {
      ref.current = {
        setShow,
        setValue,
        setMenuIsOpen,
        refs: {
          selectRef: selectRef,
        },
      }
    }
  }, [ref, setValue])

  return (
    <div
      className={clsx('ConcordFormAddressInput__container', containerClassName)}
    >
      {!show && !alwaysShowInput && (
        <div className='d-flex flex-row align-items-center gap-2'>
          <div
            className='clickable'
            onClick={() => {
              setShow(true)
              onClickSearchIcon && onClickSearchIcon()
            }}
          >
            <span
              style={{
                fontSize: '13px',
                marginRight: 4,
                verticalAlign: 'middle',
              }}
            >
              {label}
            </span>
            <SearchIcon className='ml-2' />
          </div>
          {showTimezoneBadge && (
            <DropdownWithCustomChildren
              options={timeZoneOptions}
              className='timeZoneOptions no-hover'
              onChange={(event, { selectedOption }) => {
                onSelectTimezone && onSelectTimezone(selectedOption.value)
              }}
            >
              <Badge style={{ padding: 4, fontSize: 11, paddingLeft: 6 }}>
                <span style={{ marginRight: 2 }}>
                  {timezoneObj?.label || timezone || 'Select Timezone'}
                </span>
                <ArrowDropdownIcon size={20} color='white' />
              </Badge>
            </DropdownWithCustomChildren>
          )}
        </div>
      )}
      {(show || alwaysShowInput) &&
        (!isSearchingLocationOnMap ? (
          <ConcordFormDropdownV2
            onInputChange={(inputValue, { action }) => {
              if (
                typeof inputValue === 'string' &&
                ['input-change'].includes(action)
              ) {
                setIsTyping(true)
                setValue(inputValue)
                setManualAddress('')
              }
            }}
            isCreatable={isCreatable}
            isClearable={false}
            inputValue={value}
            options={selectorOptions}
            onChange={handleChange}
            selectRef={selectRef}
            menuIsOpen={menuIsOpen}
            formatCreateLabel={() => 'Keep this Manual Address'}
            filterOption={() => true}
            onMenuOpen={() => {
              setMenuIsOpen(true)
            }}
            onMenuClose={() => {
              setMenuIsOpen(false)
            }}
            onMenu
            noOptionsMessage={() => {
              if (
                isNewAddressesDataFetched &&
                newAddressesDataOptions.length === 0
              ) {
                return 'No addresses found'
              }

              if (!value) {
                return 'Please enter an address'
              }
              if (
                isFetchingGoogleData ||
                isTyping ||
                isNewAddressesDataLoading
              ) {
                return 'Loading...'
              }
              return 'No results found'
            }}
            components={{
              Menu: menuProps => {
                const { selectProps, children } = menuProps

                return (
                  <components.Menu {...menuProps}>
                    {children}
                    {isManualSearchingAddressOptsShown(selectProps.options) && (
                      <>
                        {manualAddress.length === 0 && (
                          <div
                            className='AddressInput__option clickable'
                            style={{
                              color: 'white',
                              backgroundColor: 'var(--ion-color-danger)',
                            }}
                            onClick={() => {
                              setManualAddress(value)
                            }}
                          >
                            Search address manually
                          </div>
                        )}
                      </>
                    )}
                    {value.length > 0 && showManualAdress && (
                      <div
                        className='AddressInput__option clickable'
                        style={{
                          color: 'black',
                          backgroundColor: 'var(--ion-color-warning)',
                          marginBottom: 4,
                        }}
                        onClick={() => {
                          setIsSearchingLocationOnMap(true)
                          if (selectProps.options.length > 0) {
                            setObjectsOnMap(prev => ({
                              ...prev,
                              address: value,
                            }))
                          }
                        }}
                      >
                        Search location on Map
                      </div>
                    )}
                  </components.Menu>
                )
              },
            }}
            onBlur={() => {
              if (typeof valueProp === 'string') {
                setShow(!valueProp)
              } else {
                setShow(false)
              }
            }}
            label={
              <div style={{ display: 'inline-block' }}>
                {label}
                {onClickKeyboardIcon && (
                  <ToolTipOverlay
                    content='Manually Enter Address'
                    placement='top'
                  >
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={onClickKeyboardIcon}
                    >
                      <KeyboardIcon color='white' />
                    </Button>
                  </ToolTipOverlay>
                )}
              </div>
            }
            {...dropdownProps}
          />
        ) : (
          <div className='ConcordFormAddressInput__map'>
            <div style={{ marginBottom: 8 }}>
              <ConcordFormAddressInput
                label='Search something nearby'
                alwaysShowInput
                onChange={(opt, { location }) => {
                  setObjectsOnMap(prev => ({
                    ...prev,
                    location: location,
                    searchValue: opt.label,
                  }))
                }}
                keyword={
                  typeof objectsOnMap.address === 'string'
                    ? objectsOnMap.address
                    : buildFullAddress(objectsOnMap.address)
                }
                extraIcons={[
                  {
                    Icon: <CloseIcon color='var(--ion-color-danger)' />,
                    onClick: onCloseSearchOnMap,
                  },
                ]}
              />
            </div>

            <Alert variant='info' style={{ fontSize: 14 }}>
              <LightBulbIcon color='var(--ion-color-medium)' size={20} />
              <span style={{ verticalAlign: 'middle', marginLeft: 4 }}>
                Click any location on the map to set it as your default location
              </span>
            </Alert>

            <TerminalMap
              zoom={objectsOnMap.location ? 14 : 3}
              location={objectsOnMap.location}
              coordinates={objectsOnMap.coordinates}
              onDrawGeofence={points => {
                setObjectsOnMap(prev => ({
                  ...prev,
                  coordinates: points,
                }))
              }}
              onChangeLocation={(location, address) => {
                setObjectsOnMap(prev => ({
                  ...prev,
                  location,
                  address,
                }))
              }}
            />

            <Button
              style={{
                fontSize: 13,
                width: '100%',
              }}
              disabled={!objectsOnMap.location}
              onClick={() => {
                const tz = tzLookup(
                  objectsOnMap.location.lat,
                  objectsOnMap.location.lng,
                )
                onSelectTimezone && onSelectTimezone(tz)

                onChange &&
                  onChange(
                    {
                      value: buildFullAddress(objectsOnMap.address),
                      label: buildFullAddress(objectsOnMap.address),
                    },
                    objectsOnMap,
                  )
                onCloseSearchOnMap()
              }}
            >
              Add Address
            </Button>
          </div>
        ))}
    </div>
  )
}

export default React.forwardRef(ConcordFormAddressInput)
