import React, { useEffect, useState } from 'react'
import { IonButtons, IonButton, IonIcon, IonGrid, IonText } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { EditableTableRow } from '~/components/shared'
import moment from 'moment'

// Default selected cell
const defaultCell = {
  col: null,
  row: null,
}

/**
 * CompareLine
 * @function
 * @returns {React.Component} - The blue or yellow bill line comparison
 */

export const CompareLine = ({
  parentBillLine,
  billLine,
  className,
  rowIndex,
  saveBillLine,
  showControls,
  matchedFields,
  showAllColumn = true,
  hasUseButton = false,
  onUseBillLine,
  isCompareLine,
  onDeleteBillLine,
  inverted,
  isResolved,
  billLinesColumns,
  isSelecting,
  isSelected,
  onSelect,
  onUnMatchedClick,
  showLeftMargin,
  hideContextMenu,
  hideMatchedIcon,
  boldImportantCols,
  setNewLineItem = null,
  datePickerProps,
}) => {
  const [currentCellValue, setCurrentCellValue] = useState('')
  const [currentCell, setCurrentCell] = useState(defaultCell)
  const [isSubmitting, setIsSubmitting] = useState(false)
  /**
   * @param {Event} - Approve click event
   */

  const onClickApprove = billLineIndex => async () => {
    console.log('Approve', billLineIndex) // eslint-disable-line no-console
  }

  /**
   * @param {} - Cell cancel edit event
   */

  const onCancelValue = () => {
    setCurrentCell(defaultCell)
    setCurrentCellValue('')
  }

  /**
   * @param {} - Cell confirm edit event
   */

  const onConfirmValue = () => setIsSubmitting(true)

  useEffect(() => {
    const updateBillLine = async () => {
      if (!isSubmitting) return

      const { colIndex } = currentCell

      const key = billLinesColumns[colIndex].field

      if (!billLine || !billLinesColumns[colIndex].editable) return

      billLine[key] = currentCellValue

      let requestValue = currentCellValue
      if (key === 'lineDate') {
        requestValue = moment(currentCellValue).format('YYYY-MM-DD')
      }

      await saveBillLine(
        {
          invoiceId: billLine.invoiceId,
          id: billLine.id,
          [key]: requestValue,
        },
        inverted ? !isCompareLine : isCompareLine,
      )
      setIsSubmitting(false)
      onCancelValue()
    }

    updateBillLine()
  }, [
    billLine,
    currentCell,
    currentCellValue,
    saveBillLine,
    isSubmitting,
    isCompareLine,
    inverted,
    billLinesColumns,
  ])

  const isFlag = billLine?.flags?.find(
    ({ flagableId }) => flagableId === billLine.id,
  )

  return (
    <>
      <div className={`BillLinesTable ${className}`}>
        <IonGrid>
          <EditableTableRow
            parentBillLine={parentBillLine}
            datePickerProps={datePickerProps}
            billLine={billLine}
            rowIndex={rowIndex}
            className={`CompareLine${isFlag ? ' --flag' : ''}`}
            onConfirmValue={onConfirmValue}
            currentCellValue={currentCellValue}
            currentCell={currentCell}
            setCurrentCell={setCurrentCell}
            setCurrentCellValue={setCurrentCellValue}
            showAllColumn={showAllColumn}
            matchedFields={matchedFields}
            hasUseButton={hasUseButton}
            onUseBillLine={onUseBillLine}
            isCompareLine={isCompareLine}
            onDelete={onDeleteBillLine}
            inverted={inverted}
            isResolved={isResolved}
            onCancelValue={onCancelValue}
            billLinesColumns={billLinesColumns}
            isSelecting={isSelecting}
            selected={isSelected}
            onSelect={onSelect}
            onUnMatchedClick={onUnMatchedClick}
            showLeftMargin={showLeftMargin}
            hideContextMenu={hideContextMenu}
            hideMatchedIcon={hideMatchedIcon}
            boldImportantCols={boldImportantCols}
            setNewLineItem={setNewLineItem}
          ></EditableTableRow>
        </IonGrid>
      </div>
      {showControls && (
        <IonButtons className='ApprovalControls'>
          <IonButton
            slot='end'
            size='small'
            onClick={onClickApprove(rowIndex)}
            title='Approve'
            color='primary'
          >
            <IonIcon icon={checkmark} />
            &nbsp;
            <IonText>Accept</IonText>
          </IonButton>
          <IonButton
            slot='end'
            size='small'
            onClick={onClickApprove(rowIndex)}
            title='Approve'
            color='orange'
          >
            <IonIcon icon={checkmark} />
            &nbsp;
            <IonText>Accept</IonText>
          </IonButton>
        </IonButtons>
      )}
    </>
  )
}
