import clsx from 'clsx'
import './styles.scss'
import { Form } from 'react-bootstrap'
import { useState } from 'react'

export interface IReusableCardSelectionProps {
  options: {
    label: string
    value: string
    render: () => React.ReactNode
  }[]
}

function ReusableCardSelection(props: IReusableCardSelectionProps) {
  const { options } = props

  const [type, setType] = useState('')

  const onSelectOpt = (value: string) => () => {
    setType(value)
  }

  return (
    <div className='ReusableCardSelection__container'>
      {options.map(({ label, value, render }) => (
        <div
          key={value}
          className={clsx('ReusableCardSelection__radioItem', {
            isSelected: type === value,
          })}
        >
          <div onClick={onSelectOpt(value)}>
            <Form.Check type='radio' label={label} checked={type === value} />
          </div>

          {type === value && <div style={{ marginTop: 4 }}>{render()}</div>}
        </div>
      ))}
    </div>
  )
}

export default ReusableCardSelection
