import { useCallback, useMemo } from 'react'
import {
  useQueryOffdays,
  useQueryStartTimes,
  useQueryTerminals,
  useQueryUsers,
} from '~/hooks/useQueryData'
import useFuzzy from '~/hooks/useFuzzy'
import {
  ConcordToolbar,
  ContainerSearchBar,
  DriverCard,
} from '~/components/shared'
import { ItemTypes } from '~/components/fleet/StartTimes/helpers'
import Skeleton from 'react-loading-skeleton'

import './styles.scss'
import { Alert } from 'react-bootstrap'
import clsx from 'clsx'
import getFilteredData from '~/utils/getFilteredData'
import { EFieldType, EStatus } from '~/types/enums/ECommonEnum'
import buildNestedObject from '~/utils/buildNestedObject'
import { IUserData } from '~/hooks/useQueryData/useQueryUsers/useQueryUsers'
import _ from 'lodash'
import moment from 'moment'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { EDriverVisibilityState } from '../type'
export interface IDriverFleetListProps {
  assignedDriverFleetIds: number[]
  isBannerShown?: boolean
  bannerText?: string
  isListDisabled?: boolean
  date?: Date | string
  sortOptions?: any
  setSortOptions?: any
  filterData: Record<string, any>
  setFilterData: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

function DriverFleetList(props: IDriverFleetListProps) {
  const {
    assignedDriverFleetIds,
    isBannerShown,
    bannerText,
    isListDisabled,
    date,
    sortOptions,
    setSortOptions,
    filterData,
    setFilterData,
  } = props

  const { driverFleetUsers, isLoadingUsersData } = useQueryUsers()
  const { terminalOptions } = useQueryTerminals()

  const { offdaysData, findOffDayByWorkerId, addOffday, updateOffday } =
    useQueryOffdays(
      {
        filters: {
          startDate: {
            startDate: moment(date).startOf('day').toISOString(),
            endDate: moment(date).endOf('day').toISOString(),
          },
        },
      },
      { enabled: Boolean(filterData.offday !== 'disabled' && date) },
    )

  const { startTimesData } = useQueryStartTimes(
    {
      filters: {
        startTime: {
          startDate: moment(date).startOf('day').toISOString(),
          endDate: moment(date).endOf('day').toISOString(),
        },
      },
    } as any,
    { enabled: Boolean(date) },
  )

  const filterDataBuilt = buildNestedObject(
    _.pick(filterData, ['worker.terminalId']),
    true,
  )

  const driverFleetsFiltered: IUserData[] = getFilteredData(driverFleetUsers, {
    ...filterDataBuilt,
    worker: {
      status: EStatus.Active.toLowerCase(),
    },
  })

  const getIsAssignedToSt = useCallback(
    (driverFleet: IDriverFleet | undefined) => {
      const isAssigned = assignedDriverFleetIds.includes(
        driverFleet?.id as number,
      )
      const startTime = startTimesData.find(
        st => st.driverFleetId === driverFleet?.id,
      )
      return isAssigned || Boolean(startTime)
    },
    [assignedDriverFleetIds, startTimesData],
  )

  const availableDriverFleets = useMemo(() => {
    switch (filterData.driver) {
      case EDriverVisibilityState.hide:
        return driverFleetsFiltered.filter(
          ({ driverFleet }) => !getIsAssignedToSt(driverFleet),
        )

      default:
        return driverFleetsFiltered
    }
  }, [driverFleetsFiltered, filterData.driver, getIsAssignedToSt])

  const {
    searchValue,
    onSearch,
    seachedList: searchedDriverList,
  } = useFuzzy(availableDriverFleets, {
    keys: ['person.firstName', 'person.lastName'],
  })

  const sortedDriverList = useMemo(() => {
    const sortingOptions = sortOptions.filter(({ sorted }: any) => sorted)
    if (sortingOptions.length) {
      const fields = sortingOptions.map(({ sortField }: any) => sortField)
      const sortingDirections = sortingOptions.map(({ isAsc }: any) =>
        isAsc ? 'asc' : 'desc',
      )

      return _.orderBy(searchedDriverList, fields, sortingDirections)
    }

    return searchedDriverList
  }, [searchedDriverList, sortOptions])

  const driverSettingOptions = [
    {
      label: 'Hide Drivers when having a start time',
      value: EDriverVisibilityState.hide,
    },
    {
      label: 'Disable Drivers when having a start time',
      value: EDriverVisibilityState.disable,
    },
    {
      label: 'Allow drivers to have multiple start times in a day',
      value: EDriverVisibilityState.multiple,
    },
  ]

  return (
    <div className='DriverFleetList__container'>
      <div className='headerContainer'>
        <ContainerSearchBar
          searchBarParent='searchBar'
          searchBarPlaceholder='Search Drivers'
          searchBarValue={searchValue}
          onSearchBarChange={onSearch}
        />
      </div>

      <div className='driverFleetList'>
        {isBannerShown && (
          <Alert style={{ marginBottom: 8, fontSize: 13 }} variant='danger'>
            {bannerText}
          </Alert>
        )}
        {isLoadingUsersData && (
          <div>
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
          </div>
        )}

        <div
          style={{ marginBottom: 8, width: '100%' }}
          className={clsx({ isDisabled: isListDisabled })}
        >
          <ConcordToolbar
            isHiddenSearchBar
            filterData={filterData}
            filterOptions={[
              {
                label: 'Terminal',
                field: 'worker.terminalId',
                options: terminalOptions,
                type: EFieldType.multipleSelect,
              },
              {
                label: 'Driver',
                field: 'driver',
                type: EFieldType.singleSelect,
                options: driverSettingOptions,
              },
              {
                label: 'Offday',
                field: 'offday',
                type: EFieldType.singleSelect,
                options: [
                  {
                    label: 'Show offday',
                    value: 'showOffDay',
                  },
                  {
                    label: 'Disable',
                    value: 'disabled',
                  },
                  {
                    label: 'Hide worker with offday',
                    value: 'hide',
                  },
                ],
              },
            ]}
            sortOptions={sortOptions}
            onFilterChange={setFilterData}
            onSortChange={setSortOptions}
            filterSectionWidth={370}
          />
        </div>

        <div>
          {sortedDriverList
            .filter(({ worker }) => {
              if (filterData.offday !== 'hide') {
                return true
              }
              return !findOffDayByWorkerId(worker?.id as number)
            })
            .map((user, index) => (
              <div
                key={user.id}
                style={{ marginBottom: 8 }}
                className={clsx({ isDisabled: isListDisabled })}
              >
                <DriverCard
                  index={index + 1}
                  driverFleetId={user.driverFleet?.id as number}
                  dragType={ItemTypes.DRIVER}
                  offdaysData={
                    filterData.offday === 'showOffDay' ? offdaysData : []
                  }
                  startTimesData={startTimesData}
                  className={clsx('DriverFleetList__driverCard', {
                    isDisabled:
                      filterData.driver === EDriverVisibilityState.multiple
                        ? false
                        : getIsAssignedToSt(user.driverFleet),
                  })}
                  afterCreateOffday={addOffday}
                  afterUpdateOffday={offday => {
                    updateOffday(offday.id, offday)
                  }}
                  style={{ borderColor: '#e5e5e5' }}
                  showParkTerminalIfDifferent
                  isShowingDriverName
                  isHiddenStatusTime
                  showStartTimeBadges
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default DriverFleetList
