import { IonText } from '@ionic/react'
import { useEffect, useState } from 'react'
import { apiClient } from '~/api/ApiClient'
import {
  CommonDialogV2,
  CopyIcon,
  ICommonDialogProps,
  NewTabIcon,
  OpenIcon,
  ReusableButton,
} from '~/components/shared'
import { IUserData } from '~/hooks/useQueryData/useQueryUsers/useQueryUsers'
import { INullOrUndefined } from '~/types/models/ICommonModel'
import buildFullName from '~/utils/buildFullName'

export interface IWorkerLinkModalProps extends ICommonDialogProps {
  workerCheckUid?: string | INullOrUndefined
  workerUsers: IUserData[]
}

function WorkerLinkModal(props: IWorkerLinkModalProps) {
  const { workerCheckUid, onClose, workerUsers, ...dialogProps } = props

  const [checkLink, setCheckLink] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [showIframeModal, setShowIframeModal] = useState(false)

  const user = workerUsers.find(
    user => user.worker?.checkUid === workerCheckUid,
  )

  const onCloseModal = () => {
    onClose && onClose()
    setCheckLink('')
  }

  useEffect(() => {
    if (workerCheckUid) {
      setIsLoading(true)
      apiClient.check
        .createWorkerLink({
          worker: {
            checkUid: workerCheckUid,
          },
        })
        .then(response => {
          setCheckLink(response.link)
          setIsLoading(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [workerCheckUid])

  return (
    <>
      <CommonDialogV2
        {...dialogProps}
        isHiddenOkButton
        isLoading={isLoading}
        title={`Employee Onboarding Form for ${buildFullName(user?.person)}`}
        onClose={onCloseModal}
      >
        <div style={{ fontSize: 13 }}>
          <strong>Link: </strong>
          <span
            style={{ wordWrap: 'break-word', maxWidth: 290 }}
            className='hyperLink'
          >
            {checkLink}
          </span>

          {isLinkCopied && (
            <IonText
              style={{ fontSize: 14, fontWeight: 'bold', marginLeft: 4 }}
              color='success'
            >
              Copied
            </IonText>
          )}
        </div>
        <div style={{ marginTop: 4, display: 'flex' }}>
          <ReusableButton
            style={{ width: '100%', padding: 8, marginRight: 8 }}
            variant='secondary'
            tooltipContent='Copy Url'
            isLoading={isLoading}
            onClick={() => {
              navigator.clipboard.writeText(checkLink)
              setIsLinkCopied(true)
              setTimeout(() => {
                setIsLinkCopied(false)
              }, 1500)
            }}
          >
            <CopyIcon color='white' />
          </ReusableButton>

          <ReusableButton
            style={{ width: '100%', padding: 8, marginRight: 8 }}
            tooltipContent='Open New Tab'
            isLoading={isLoading}
            onClick={() => {
              window.open(checkLink, '_blank')
              onCloseModal()
            }}
          >
            <OpenIcon color='white' />
          </ReusableButton>

          <ReusableButton
            style={{ width: '100%', padding: 8 }}
            tooltipContent='Open within Concord'
            isLoading={isLoading}
            onClick={() => {
              setShowIframeModal(true)
            }}
          >
            <NewTabIcon color='white' />
          </ReusableButton>
        </div>
      </CommonDialogV2>
      <CommonDialogV2
        isHiddenOkButton
        size='fullscreen'
        fullscreen
        onClose={() => setShowIframeModal(false)}
        isOpen={showIframeModal}
        title={`Employee Onboarding Form for ${buildFullName(user?.person)}`}
      >
        {showIframeModal && (
          <iframe src={checkLink} width='100%' height='100%' />
        )}
      </CommonDialogV2>
    </>
  )
}

export default WorkerLinkModal
