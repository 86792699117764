import { parseISO, subDays, isValid } from 'date-fns'

function getPreviousDay(
  dateInput: Date | string | null | undefined,
): Date | null {
  if (!dateInput) return null // Handle null or undefined

  let date: Date

  if (dateInput instanceof Date) {
    date = dateInput // Use the provided Date object
  } else if (typeof dateInput === 'string') {
    date = parseISO(dateInput) // Parse ISO or date string
  } else {
    return null // If input is not a valid type, return null
  }

  if (!isValid(date)) return null // Check if parsed date is valid

  return subDays(date, 1) // Subtract one day
}

export default getPreviousDay
