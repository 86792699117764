const styles = {
  arrowButton: {
    position: 'absolute',
    top: '40%',
    zIndex: 999,
  },
  bottomArrowButton: {
    position: 'absolute',
    top: '50%',
    zIndex: 999,
  },
  compareLineContainer: {
    marginTop: -30,
  },
}

export default styles
