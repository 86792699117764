import { useMemo } from 'react'
import { IonIcon } from '@ionic/react'
import { helpCircleOutline } from 'ionicons/icons'
import { ToolTipOverlay } from '~/components/shared'
import clsx from 'clsx'
import { useQueryTerminals } from '~/hooks/useQueryData'
import buildFullAddress from '~/utils/buildFullAddress'

const TerminalDisplay = ({ className, terminalId, onContextMenu }) => {
  const { findTerminalById } = useQueryTerminals()
  const terminal = findTerminalById(terminalId)

  const isNotSet = !terminal

  const isRawData = useMemo(() => {
    if (terminal?.id) {
      return false
    }

    return terminal?.code || terminal?.name
  }, [terminal?.code, terminal?.id, terminal?.name])

  const labelRendered = useMemo(() => {
    if (isNotSet) {
      return (
        <IonIcon
          size='large'
          icon={helpCircleOutline}
          style={{ pointerEvents: 'none' }}
        />
      )
    }

    return terminal?.name
  }, [isNotSet, terminal?.name])

  const tooltipRendered = useMemo(() => {
    if (isNotSet) {
      return 'Not Set'
    }

    return (
      <div>
        <div>Code: {terminal?.code}</div>
        {terminal?.address && (
          <div>Address: {buildFullAddress(terminal?.address)}</div>
        )}
      </div>
    )
  }, [isNotSet, terminal])

  return (
    <>
      <ToolTipOverlay
        placement='right'
        content={tooltipRendered}
        className='pull-content-in-tooltip-left'
      >
        <div
          style={{ fontSize: 16 }}
          className={clsx(className, { rawData: isRawData })}
          onContextMenu={onContextMenu}
        >
          {labelRendered}
        </div>
      </ToolTipOverlay>
    </>
  )
}

export default TerminalDisplay
