import { useMemo } from 'react'
import { useNavigatedMenuItems } from '~/hooks/useNavigatedMenuItems'

import NotFoundPage from '~/containers/NotFoundPage'
import { CompanyHomeContainer } from '../../CompanyHomeContainer'
import { UniversalLoadingPage } from '~/components/shared'

import type { ICompanyViewProps } from './type'

function CompanyView(props: ICompanyViewProps) {
  const { view, isShowCreateButton, onClose, onGoBack, onClickSubpage } = props

  const { myCompanyMenu } = useNavigatedMenuItems()

  const selectedCompanySubPage = useMemo(
    () => myCompanyMenu.subtabs.find(({ value }) => value === view),
    [myCompanyMenu.subtabs, view],
  )

  if (!view) {
    return (
      <CompanyHomeContainer
        onClickSubpage={onClickSubpage}
        onClose={onClose}
        isShowCreateButton={isShowCreateButton}
      />
    )
  }

  if (selectedCompanySubPage) {
    const Component = selectedCompanySubPage.component

    return (
      <Component
        {...selectedCompanySubPage.props}
        fallback={<UniversalLoadingPage />}
      />
    )
  }

  return (
    <NotFoundPage message='Company page is not found!' onGoBack={onGoBack} />
  )
}

export default CompanyView
