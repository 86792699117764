import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { INVOICE_SOURCE } from '~/constants/invoice-type'
import { updateInvoiceUi } from '~/redux/actions/invoiceActions'
import { findInvoiceFromIndex, selectInvoiceUiState } from '~/redux/selectors'

// Controls the radio buttons on the upper right of the invoice show page
const useInvoicePanelControls = () => {
  const dispatch = useDispatch()
  const invoice = useSelector(findInvoiceFromIndex)
  const { currentPanels, displaySummary } = useSelector(selectInvoiceUiState)
  const isActualInvoice = useMemo(
    () => invoice && invoice.source == INVOICE_SOURCE.invoice,
    [invoice],
  )

  const onChangeCurrentPanels = useCallback(
    e => {
      const window = e.detail.value
      let newPanels = []

      if (currentPanels.includes(window)) {
        newPanels = currentPanels.filter(w => w !== window)
      } else {
        newPanels = [...currentPanels, window]
      }
      dispatch(updateInvoiceUi({ currentPanels: newPanels }))
    },
    [currentPanels, dispatch],
  )

  const onToggleSummary = useCallback(() => {
    dispatch(updateInvoiceUi({ displaySummary: !displaySummary }))
  }, [displaySummary, dispatch])

  return {
    invoice,
    currentPanels,
    displaySummary,
    isActualInvoice,
    onChangeCurrentPanels,
    onToggleSummary,
  }
}

export default useInvoicePanelControls
