import { createSelector } from 'reselect'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'
import { arrayToObjectById } from '~/utils/utils'

const selectLoadsDataState = state => state.data.loads
const selectLineItemId = (state, lineItemId) => lineItemId

const selectLoadsUiState = state => state.ui.loads
const selectCompanies = state => state.data.companies
const selectFlags = state => state.data.flags

export const selectLoadFiltersState = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.filters,
)

export const selectFleets = createSelector(
  selectCompanies,

  companies =>
    columnHashToColumnsArray(companies).filter(
      company => company.fleet == 'Yes',
    ),
)

const selectLoadCommonData = createSelector(
  selectCompanies,
  selectFleets,
  (companies, fleets) => {
    return {
      companies: companies,
      fleet: arrayToObjectById(fleets),
      terminals: {},
    }
  },
)

export const selectLoadSearchKey = createSelector(
  selectLoadFiltersState,
  loadFiltersState => loadFiltersState.searchKey,
)

export const selectLoadsColumns = createSelector(
  selectLoadsUiState,
  loadsUiState =>
    loadsUiState.columns
      .filter(c => c.hideable)
      .sort((a, b) => a.displayIndex - b.displayIndex),
)

export const selectLoadsUiSortableColumns = createSelector(
  selectLoadsUiState,
  loadsUiState =>
    loadsUiState.columns
      .filter(c => c.sortable)
      .sort((a, b) => a.sortIndex - b.sortIndex),
)

export const selectLoadsUiShowColumns = createSelector(
  selectLoadsUiState,
  loadsUiState =>
    loadsUiState.columns
      .filter(c => c.show)
      .sort((a, b) => a.displayIndex - b.displayIndex),
)

export const selectLoadListData = createSelector(
  selectLoadsDataState,
  selectLoadCommonData,
  selectFlags,

  loadDataState => {
    const loadData = Object.values(loadDataState)
    return loadData
  },
)

export const selectLoadsPage = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.page,
)

export const selectLoadsCount = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.count,
)

export const selectLoadsStartRange = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.startRange,
)

export const selectLoadDeleteObject = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.deleteObject,
)

export const selectLoadEditValue = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.editLoadValue,
)

export const selectLoadFormData = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.formData,
)

export const selectFiltersConfigured = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.filtersConfigured,
)

export const selectFiltersInitialized = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.filtersInitialized,
)

export const selectLoading = createSelector(
  selectLoadsUiState,
  loadsUiState => loadsUiState.loading,
)

export const selectLoadByIdFactory = () => {
  const selectLoadById = createSelector(
    [selectLoadsDataState, selectLineItemId],

    (loads, id) => {
      return loads[id] || {}
    },
  )

  return selectLoadById
}

export const selectLoadById = createSelector(
  [selectLoadsDataState, selectLineItemId],
  (loads, id) => loads[id] || {},
)

export const selectFirstLoad = createSelector(
  selectLoadsDataState,
  loads => Object.values(loads)[0] || {},
)

export const selectLoadsList = createSelector(selectLoadListData, loads =>
  Object.values(loads),
)

export const selectLoadsHash = createSelector(
  selectLoadListData,
  loads => loads,
)
