import _ from 'lodash'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { apiClient } from '~/api/ApiClient'
import {
  CommonDialogV2,
  ConcordDatePickerWithFormControl,
  ConcordFormStructure,
  ConcordNumberFieldWithFormControl,
  ICommonDialogProps,
} from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import { IHierarchy } from '~/types/models/IHierarchy'
import { IHierarchyRow } from '~/types/models/IHierarchyRow'
import { IPricing } from '~/types/models/IPricing'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { getPricingDate } from '~/utils/formatPricingDate'
import * as Yup from 'yup'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { ICompany } from '~/types/models/ICompany'
import formatDateBasedOnYear from '~/utils/formatDateBasedOnYear'
import { format } from 'date-fns'
import getPreviousDay from '~/utils/getPreviousDay'
import clsx from 'clsx'
import {
  useQueryHierarchies,
  useQueryHierarchyRows,
} from '~/hooks/useQueryData'
import { toast } from 'react-toastify'

import './styles.scss'
import roundNumber from '~/utils/roundNumber'
import { renderQuickEndDateChangeOptions } from '~/utils/renderDateChangeBadges1'

export interface IDialogPriceChangeProps extends ICommonDialogProps {
  pricing?: IPricing
  hierarchiesData?: IHierarchy[]
  hierarchyRowsData?: IHierarchyRow[]
  invoiceId?: number
  useLocalHierarchiesData?: boolean
  afterPriceChanges?: () => void
  formData?: Partial<{ value: any }>
}

function DialogPriceChange(props: IDialogPriceChangeProps) {
  const {
    pricing,
    hierarchiesData: hierarchiesDataProp,
    hierarchyRowsData: hierarchyRowsDataProp,
    invoiceId,
    afterPriceChanges,
    isOpen,
    useLocalHierarchiesData,
    formData,
    ...dialogProps
  } = props

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentPrice = pricing?.amount ? Number(pricing?.amount) : null

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const formRef = useRef<any>()

  const { hierarchyRowsData: localHierarchyRowsData } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableId: pricing ? [pricing.id] : [],
        hierarchableType: EHierarchableType.Pricing,
      },
    },
    { enabled: Boolean(pricing && useLocalHierarchiesData) },
  )

  const hierarchyRowsData = useLocalHierarchiesData
    ? localHierarchyRowsData
    : (hierarchyRowsDataProp as IHierarchyRow[])

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData: localHierarchiesData } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    {
      enabled: pricing && useLocalHierarchiesData && hierarchyIds.length > 0,
    },
  )

  const hierarchiesData = useLocalHierarchiesData
    ? localHierarchiesData
    : (hierarchiesDataProp as IHierarchy[])

  const hierarchyRows = hierarchyRowsData
    .filter(({ hierarchableId }) => hierarchableId === pricing?.id)
    .map(({ hierarchyId }) => hierarchyId)

  const hierarchies = hierarchiesData.filter(({ id }) =>
    hierarchyRows.includes(id),
  )

  const typeOptions = [
    {
      label: 'New Price',
      value: 'New Price',
    },
    {
      label: 'Price Increase %',
      value: '%',
    },
    {
      label: 'Price Increase $',
      value: '$',
    },
  ]

  const schema = Yup.object({
    startDate: Yup.string().required('This field is required!'),
    type: Yup.string()
      .required('This field is required!')
      .typeError('This field is required!'),
    value: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
  })

  const getNewPrice = (type: string, value: number, rounded = true) => {
    if (type && value) {
      let result: number | undefined
      if (type === 'New Price') {
        result = value
        // return roundNumber(value)
      } else if (type === '%') {
        result = Number(currentPrice) * (1 + Number(value) / 100)
        // return roundNumber(newPrice)
      } else if (type === '$') {
        result = Number(currentPrice) + Number(value)
        // return roundNumber(newPrice)
      }

      if (rounded && result) {
        return roundNumber(result)
      }
      return result
    }

    return null
  }

  const roundToNearestHundredth = (num: number) => {
    return Math.round(num * 100) / 100
  }

  const getDifferentPrice = (type: string, value: number) => {
    const newPrice = getNewPrice(type, value)
    if (newPrice && currentPrice) {
      const diffPrice = newPrice - currentPrice

      return diffPrice
    }

    return null
  }

  const getPercentageChange = (type: string, value: number) => {
    const diffPrice = getDifferentPrice(type, value)
    if (diffPrice && currentPrice) {
      const percentage = (diffPrice / currentPrice) * 100
      return roundToNearestHundredth(percentage)
    }

    return null
  }

  const handleSubmit = async (values: any) => {
    setIsLoading(true)
    setError('')
    try {
      const { startDate, endDate, type, value } = values
      const newPrice = getNewPrice(type, value)
      const pricingDateRange = getPricingDate(pricing?.dateRange as string)
      const newDateRange = {
        ...pricingDateRange,
        endDate: moment(startDate).add({ days: -1 }).format('YYYY-MM-DD'),
      }
      const newStartDate = newDateRange.startDate
        ? moment(newDateRange.startDate).format('YYYY-MM-DD')
        : 'Infinity'
      const newEndDate = newDateRange.endDate
        ? moment(newDateRange.endDate).format('YYYY-MM-DD')
        : '-Infinity'
      const newValue = `${newStartDate}...${newEndDate}`
      await apiClient.pricings.update(pricing?.id as number, {
        pricing: {
          dateRange: newValue,
        },
      })
      const response2 = await apiClient.hierarchyRows.create({
        hierarchyRow: {
          hierarchableAttributes: {
            amount: newPrice,
            dateRangeInput: `${moment(startDate).format('YYYY-MM-DD')}...${
              endDate ? moment(endDate).format('YYYY-MM-DD') : '-Infinity'
            }`,
            invoiceId: invoiceId,
            pricingType: pricing?.pricingType as any,
            sellerProductId: pricing?.sellerProductId as number,
          },
          hierarchableType: EHierarchableType.Pricing,
          hierarchyAttributes: hierarchies?.[0]
            ? _.pick(hierarchies?.[0], [
                'allowedHierarchyId',
                'buyerSideId',
                'buyerSideType',
                'companyId',
                'rank',
                'sellerSideId',
                'sellerSideType',
              ])
            : (undefined as any),
          hierarchableId: undefined as any,
        },
      })
      if (response2.errors?.length) {
        setError(response2.errors[0])
      } else {
        afterPriceChanges && afterPriceChanges()
        toast.success(toastMessages.updateSuccess)
      }
    } catch (error) {
      console.log('error', error)
      setError(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setError('')
    }
  }, [isOpen])

  return (
    <CommonDialogV2
      {...dialogProps}
      title='Price Change'
      size='md'
      isHiddenOkButton
      isOpen={isOpen}
    >
      <ConcordFormStructure
        ref={formRef}
        error={error}
        isLoading={isLoading}
        schema={schema}
        formData={formData}
        defaultValues={{
          type: 'New Price',
        }}
        fields={[
          {
            label: 'New Price Start Date',
            name: 'startDate',
            type: EFieldType.date,
            size: 6,
            isRequired: true,
            hint({ watch }) {
              const startDate = watch('startDate', null)
              if (startDate) {
                const formatDate = formatDateBasedOnYear(
                  currentCompany.dateFormat || 'YYYY-MM-dd',
                  startDate,
                )
                const previousDay = getPreviousDay(startDate)
                if (previousDay) {
                  const date = format(previousDay, formatDate)
                  return `Current price will end on ${date}`
                }
              }
            },
          },
          {
            label: 'New Price End Date',
            name: 'endDate',
            type: EFieldType.date,
            size: 6,
            render({ control, name, label }) {
              return (
                <div>
                  <ConcordDatePickerWithFormControl
                    control={control}
                    name={name}
                    label={label}
                  />
                  {renderQuickEndDateChangeOptions({
                    onChange: date =>
                      formRef.current?.setValue('endDate', date),
                  })}
                </div>
              )
            },
          },
          {
            label: 'Select Price Change Type',
            name: 'type',
            type: EFieldType.singleSelect,
            isRequired: true,
            size: 6,
            options: typeOptions,
            onChange() {
              formRef.current?.setValue('value', formData?.value)
            },
          },
          {
            label: '',
            name: 'value',
            size: 6,
            isDisabled: ({ watch }) => !watch('type'),
            render({ control, name, watch }) {
              const type = watch('type', 'New Price')
              const value = watch('value', 0)
              const newPrice = getNewPrice(type, value, false) as number
              const newRoundedPrice = getNewPrice(type, value) as number
              const findType = typeOptions.find(({ value }) => value === type)
              const diffPrice = getDifferentPrice(type, value)
              const percentageChange = getPercentageChange(type, value)
              const decimalScale = type !== '%' ? 2 : undefined
              const fixedDecimalScale = type !== '%' ? true : undefined

              const text =
                newRoundedPrice > newPrice ? 'Rounded up' : 'Rounded down'

              return (
                <ConcordNumberFieldWithFormControl
                  control={control}
                  name={name}
                  label={findType?.label || '-'}
                  isRequired
                  decimalScale={decimalScale}
                  fixedDecimalScale={fixedDecimalScale}
                  hint={
                    <div>
                      {currentPrice ? (
                        <div>
                          Current Price:
                          {formatCurrencyToDollar.format(currentPrice)}
                        </div>
                      ) : null}

                      {diffPrice ? (
                        <div>
                          Difference:{' '}
                          <span
                            className={clsx({
                              'color-red': diffPrice < 0,
                              'color-green': diffPrice > 0,
                            })}
                          >
                            {diffPrice > 0 ? '+' : null}
                            {formatCurrencyToDollar.format(diffPrice)}&nbsp;(
                            {diffPrice > 0 ? '+' : null}
                            {percentageChange}%)
                          </span>
                        </div>
                      ) : null}

                      {newRoundedPrice && (
                        <div>
                          New Price:{' '}
                          {formatCurrencyToDollar.format(newRoundedPrice)}
                          {Number(newPrice) !== Number(newRoundedPrice) ? (
                            <div style={{ fontStyle: 'italic', fontSize: 10 }}>
                              &quot;Calculated value was{' '}
                              <strong>{newPrice}</strong>.
                              <br />
                              {text} to&nbsp;
                              <strong>{newRoundedPrice}</strong>&quot;
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  }
                />
              )
            },
          },
        ]}
        onSubmit={handleSubmit}
      />
    </CommonDialogV2>
  )
}

export default DialogPriceChange
