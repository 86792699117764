import { Body } from '~/components'
import {
  CompanyAvatar,
  ReusableTable,
  DialogBTSPForm,
  PlusIcon,
  ReusableButton,
  ToolTipOverlay,
  DialogAddressForm,
  DialogEmailForm,
  DialogPhoneNumberForm,
  DialogSalesProposalForm,
  EditIcon,
  PdfIcon,
  DialogSellerProductForm,
  DropdownWithCustomChildren,
  RTCell,
  EditableInlineField,
  DialogPricingForm,
  DialogHierarchyRowForm,
  DialogPriceEscalationForm,
  DialogWorkForceForm,
  DialogGroupForm,
  DialogWorkTypeForm,
  DialogPaymentTermForm,
  ButtonDropdown,
  HeaderContent,
  IRTColumnDef,
  CostingBreakdownOverlay,
} from '~/components/shared'
import {
  useQueryAddresses,
  useQueryAllowedHierarchies,
  useQueryBidders,
  useQueryBTSPs,
  useQueryCompanies,
  useQueryEmails,
  useQueryGroups,
  useQueryHierarchyRowsMatches,
  useQueryPhoneNumbers,
  useQuerySalesProposals,
  useQuerySellerProducts,
  useQuerySellerTerminalProducts,
  useQueryTerminals,
  useQueryUoms,
  useQueryUsers,
  useQueryWorkForces,
  useQueryWorkTypes,
} from '~/hooks/useQueryData'
import { useQuerySellerToBuyerTerminals } from '~/hooks/useQueryData/useQuerySellerToBuyerTerminals'
import { useRouter } from '~/hooks/useRouter'
import {
  EFieldType,
  ERTDisplayColumnId,
  EYesNo,
} from '~/types/enums/ECommonEnum'

import './styles.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EAddressableType, EAddressType } from '~/types/enums/EAddress'
import buildFullAddress from '~/utils/buildFullAddress'
import { EEmailableType, EEmailTypes } from '~/types/enums/EEmail'
import { EPhoneableType, EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import { formatPhoneNumber } from '~/utils/utils'
import buildFullName from '~/utils/buildFullName'
import _, { uniq } from 'lodash'
import { format } from 'date-fns'
import { IBuyerTerminalSellerProduct } from '~/types/models/IBuyerTerminalSellerProduct'
import buildObjectName from '~/utils/buildObjectName'
import { Col, Row } from 'react-bootstrap'
import CreateOrEditButton from './CreateOrEditButton'
import { IAddress } from '~/types/models/IAddress'
import { IEmail } from '~/types/models/IEmail'
import { IPhoneNumber } from '~/types/models/IPhoneNumber'
import { ISalesProposal } from '~/types/models/ISalesProposal'
import { useShowingPdfViewer } from '~/hooks/useShowingPdfViewer'
import { ISellerProduct, ISpCosting } from '~/types/models/ISellerProduct'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { DialogAutoChargesForm } from '~/components/shared/ConcordForm/FormData/AutoChargesForm'
import { IAutoCharge } from '~/types/models/IAutoCharge'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { produce } from 'immer'
import { ISellerToBuyerTerminal } from '~/types/models/ISellerToBuyerTerminal'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { NOT_SET } from '~/utils/constants'
import Skeleton from 'react-loading-skeleton'
import { IPricing } from '~/types/models/IPricing'
import { IPriceEscalation } from '~/types/models/IPriceEscalation'
import { useConfirmationProvider } from '~/contexts'
import { IPaymentTerm } from '~/types/models/IPaymentTerm'
import { IonCol, IonRow } from '@ionic/react'
import { replaceUrl } from '~/utils/buildUrl'
import queryString from 'query-string'
import moment from 'moment'
import clsx from 'clsx'

export interface IOpportunitiesShowPageProps {
  hideBody?: boolean
  id?: number
}

function OpportunitiesShowPage(props: IOpportunitiesShowPageProps) {
  const { hideBody, id } = props

  const [btspForm, setBtspForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IBuyerTerminalSellerProduct>,
  })
  const [addressForm, setAddressForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IAddress>,
  })
  const [emailForm, setEmailForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IEmail>,
  })
  const [phoneNumberForm, setPhoneNumberForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IPhoneNumber>,
  })
  const [proposalForm, setProposalForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<ISalesProposal>,
  })
  const [sellerProdForm, setSellerProdForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<ISellerProduct>,
  })
  const [autoChargeForm, setAutoChargeForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IAutoCharge>,
  })
  const [loadingFields, setLoadingFields] = useState<string[]>([])
  const [pricingCreateForm, setPricingCreateForm] = useState({
    isOpen: false,
    formData: undefined as any,
  })
  const [pricingUpdateForm, setPricingUpdateForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IPricing>,
  })
  const [priceEscalationForm, setPriceEscalationForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IPriceEscalation>,
  })
  const [paymentTermForm, setPaymentTermForm] = useState({
    isOpen: false,
    formData: undefined as undefined | Partial<IPaymentTerm>,
  })
  const [spCostingData, setSpCostingData] = useState<ISpCosting[]>([])

  const { confirmation } = useConfirmationProvider()
  const router = useRouter()
  const { onOpenPdfViewer } = useShowingPdfViewer()
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const idData = id || router.params.id

  const bidderIdFromUrl = useMemo(() => {
    const parsedUrl = queryString.parseUrl(router.fullPath)
    return parsedUrl.query?.bidder
  }, [router.fullPath])

  const {
    sellerToBuyerTerminals: [data],
    updateSellerToBuyerTerminals,
    isSellerToBuyerTerminalsFetched,
  } = useQuerySellerToBuyerTerminals(
    {
      id: Number(idData),
    },
    { enabled: Boolean(idData) },
  )

  const { allowedHierarchiesData } = useQueryAllowedHierarchies()
  const { findUserByUserAccessId, userAccessOptions } = useQueryUsers()
  const { findCompanyById, isCompaniesDataFetched } = useQueryCompanies({})
  const {
    findTerminalById,
    isFetchedTerminals,
    sellerTerminalOptions,
    terminalOptions,
  } = useQueryTerminals()
  const { findUomById } = useQueryUoms()
  const { groupOptions, addGroup, updateGroup } = useQueryGroups()
  const { workForceOptions, addWorkForce, updateWorkForce } =
    useQueryWorkForces()
  const { workTypeOptions, addWorkType, updateWorkType } = useQueryWorkTypes()
  const { btspsData, addBtsp, updateBtsp } = useQueryBTSPs(
    {
      filters: {
        buyerTerminalId: data?.buyerTerminalId,
      },
    },
    { enabled: Boolean(data?.buyerTerminalId) },
  )

  const {
    findSellerProductById,
    standardProducts,
    addSellerProduct,
    updateSellerProduct,
    sellerProducts,
    isSellerProductsFetched,
    refetchSellerProductsData,
  } = useQuerySellerProducts()

  const { bidderOptions, isBiddersDataFetching, findBidderById } =
    useQueryBidders(
      {
        filters: {
          sellerToBuyerTerminalId: idData as number,
        },
      },
      { enabled: Boolean(idData) },
    )

  const bidderIds = bidderOptions.map(({ value }) => value)

  const {
    salesProposalsData,
    isSalesProposalsFetching,
    refetchSalesProposals,
  } = useQuerySalesProposals(
    {
      filters: {
        bidderId: (bidderIdFromUrl as any) || bidderIds,
      },
    },
    { enabled: Boolean(bidderIdFromUrl) || bidderIds.length > 0 },
  )

  const renderHeader = useCallback(() => {
    return {
      headerTitle: isBiddersDataFetching ? 'Loading...' : 'Opportunity',
      rightButton: (
        <IonRow>
          <IonCol>
            <ButtonDropdown
              label='Bidder: All'
              popoverClassName='OpportunitiesShowPage__bidderSelector'
              options={[{ value: 'all', label: 'All' }, ...bidderOptions]}
              value={bidderIdFromUrl ? Number(bidderIdFromUrl) : undefined}
              onChange={(event: any, { value }: any) => {
                if (value === 'all') {
                  router.push(router.pathname)
                } else {
                  const url = replaceUrl(router.pathname, {
                    bidder: value,
                  })
                  router.push(url)
                }
              }}
            />
          </IonCol>
        </IonRow>
      ),
    }
  }, [bidderOptions, bidderIdFromUrl, router, isBiddersDataFetching])

  const {
    hierarchableMatches: autoChagesWithHierarchy,
    updateSellerProduct: updateSellerProductWithHierarchy,
    refetchHierarchyRowsMatches,
  } = useQueryHierarchyRowsMatches(
    {
      hierarchyRow: {
        matchingObjectId: data?.id,
        matchingObjectType: 'SellerToBuyerTerminal',
        hierarchableType: 'AutoCharge',
        list: true,
      },
    },
    {
      enabled: Boolean(data),
      additionalKeys: ['hierarchyRowMatches'],
    },
  )

  const sellerProdsOfAutoCharges = useMemo(() => {
    const ids = autoChagesWithHierarchy.map(
      ({ sellerProductId }) => sellerProductId,
    )
    return sellerProducts.filter(({ id }) => ids.includes(id))
  }, [autoChagesWithHierarchy, sellerProducts])

  const allSellerProductIds = uniq(
    [...sellerProdsOfAutoCharges, ...standardProducts].map(({ id }) => id),
  )

  const { sellerTerminalProductsData, isSellerTerminalProductsFetched } =
    useQuerySellerTerminalProducts(
      {
        filters: {
          sellerProductId:
            allSellerProductIds.length > 0 ? allSellerProductIds : undefined,
        },
      },
      { enabled: allSellerProductIds.length > 0 },
    )

  const costingPayload = useMemo(() => {
    const result = sellerTerminalProductsData.map(({ sellerProductId }) => ({
      id: sellerProductId,
      terminalId: data?.sellerTerminalId,
      date: moment().format('YYYY-MM-DD'),
    }))
    return _.uniqBy(result, 'id')
  }, [data?.sellerTerminalId, sellerTerminalProductsData])

  const standardProductsWithoutAutoCharges = standardProducts.filter(
    ({ id }) => !sellerProdsOfAutoCharges.find(sp => sp.id === id),
  )

  const {
    hierarchableMatches: pricingsData,
    isHierarchyRowMatchesFetching,
    refetchHierarchyRowsMatches: refetchPricings,
  } = useQueryHierarchyRowsMatches(
    {
      hierarchyRow: {
        matchingObjectType: bidderIdFromUrl
          ? 'Bidder'
          : 'SellerToBuyerTerminal',
        matchingObjectId: bidderIdFromUrl ? (bidderIdFromUrl as any) : data?.id,
        hierarchableType: 'Pricing',
        sellerProductIds: allSellerProductIds,
      },
    },
    {
      enabled: bidderIdFromUrl
        ? Boolean(
            data?.id &&
              isSellerProductsFetched &&
              allSellerProductIds.length > 0,
          )
        : bidderIds.length > 0,
    },
  )

  const {
    hierarchableMatches: [priceEscalation],
    isHierarchyRowMatchesFetching: isPriceEscalationFetching,
    updateHierarchyRowMatched: updatePriceEscalation,
    refetchHierarchyRowsMatches: refetchPriceEscalation,
  } = useQueryHierarchyRowsMatches(
    {
      hierarchyRow: {
        matchingObjectId: data?.id,
        matchingObjectType: 'SellerToBuyerTerminal',
        hierarchableType: 'PriceEscalation',
      },
    },
    { enabled: Boolean(data) },
  )

  const {
    hierarchableMatches: [paymentTerm],
    isHierarchyRowMatchesFetching: isPaymentTermFetching,
    updateHierarchyRowMatched: updatePaymentTerm,
    refetchHierarchyRowsMatches: refetchPaymentTerm,
  } = useQueryHierarchyRowsMatches(
    {
      hierarchyRow: {
        matchingObjectId: data?.id,
        matchingObjectType: 'SellerToBuyerTerminal',
        hierarchableType: 'PaymentTerm',
      },
    },
    { enabled: Boolean(data) },
  )

  const allowedHierarchy = allowedHierarchiesData.find(
    ({ buyerSideType, sellerSideType }) =>
      sellerSideType === 'Seller' && buyerSideType === 'BuyerTerminal',
  )

  const currentBidder = bidderIdFromUrl
    ? findBidderById(Number(bidderIdFromUrl))
    : undefined

  const user = findUserByUserAccessId(data?.userAccessId)
  const seller = findCompanyById(data?.sellerId)
  const buyerTerminal = findTerminalById(data?.buyerTerminalId)
  const buyer = findCompanyById(
    currentBidder ? currentBidder.buyerId : buyerTerminal?.companyId,
  )
  const sellerTerminal = findTerminalById(data?.sellerTerminalId)

  const buyerTerminalOptions = terminalOptions.filter(({ companyId }) => {
    if (buyer) {
      return companyId === buyer.id
    }
    return []
  })

  const ids = [data?.sellerId, buyer?.id, buyerTerminal?.companyId].filter(
    Boolean,
  )

  const idsWithBuyerTerminal = [
    ...ids,
    sellerTerminal?.id,
    buyerTerminal?.id,
  ].filter(Boolean)

  const { findAddress, addAddress, updateAddress } = useQueryAddresses(
    {
      filters: {
        addressableType: [EAddressableType.company, EAddressableType.terminal],
        addressableId: idsWithBuyerTerminal as number[],
      },
    },
    {
      enabled: Boolean(
        ids.length > 0 && isCompaniesDataFetched && isFetchedTerminals,
      ),
    },
  )

  const { findEmail, addEmail, updateEmail } = useQueryEmails(
    {
      filters: {
        emailableType: [EEmailableType.company],
        emailableId: ids as number[],
      },
    },
    {
      enabled: Boolean(
        ids.length > 0 && isCompaniesDataFetched && isFetchedTerminals,
      ),
    },
  )

  const { findPhoneNumber, addPhoneNumber, updatePhoneNumber } =
    useQueryPhoneNumbers({
      filters: {
        phoneableId: ids as number[],
        phoneableType: [EPhoneableType.company],
      },
    })

  const sellerAddress = findAddress({
    addressableId: seller?.id,
    addressableType: EAddressableType.company,
  })
  const sellerEmail = findEmail({
    emailableType: EEmailableType.company,
    emailableId: seller?.id,
  })
  const sellerPhoneNumber = findPhoneNumber({
    phoneableId: seller?.id,
    phoneableType: EPhoneableType.company,
  })

  const sellerTerminalAddress = findAddress({
    addressableId: sellerTerminal?.id,
    addressableType: EAddressableType.terminal,
  })

  const buyerTerminalAddress = findAddress({
    addressableId: buyerTerminal?.id,
    addressableType: EAddressableType.terminal,
  })
  const buyerEmail = findEmail({
    emailableType: EEmailableType.company,
    emailableId: buyer?.id,
  })
  const buyerPhoneNumber = findPhoneNumber({
    phoneableId: buyer?.id,
    phoneableType: EPhoneableType.company,
  })

  const buyerAddress = findAddress({
    addressableId: buyer?.id,
    addressableType: EAddressableType.company,
  })

  const productTableColumns: IRTColumnDef<ISellerProduct>[] = [
    {
      header: 'Code',
      accessorKey: 'code',
    },

    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Pricing',
      accessorKey: 'pricingId',
      Cell({ row: { original } }) {
        if (isHierarchyRowMatchesFetching) {
          return (
            <div style={{ width: '100%' }}>
              <Skeleton width='100%' height='100%' />
            </div>
          )
        }
        const pricing = pricingsData.find(
          ({ sellerProductId }) => sellerProductId === original.id,
        )

        if (pricing) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span>
                {formatCurrencyToDollar.format(pricing.amount as any)}
              </span>
              <span
                className='clickable'
                style={{
                  marginLeft: 4,
                }}
                onClick={onShowPricingUpdateForm(pricing)}
              >
                <EditIcon color='var(--bs-warning)' />
              </span>
            </div>
          )
        }
        return (
          <span
            className='clickable'
            onClick={onShowPricingCreateForm({
              sellerProductId: original.id,
            })}
          >
            <PlusIcon color='var(--bs-primary)' />
          </span>
        )
      },
    },
    {
      header: 'UOM',
      accessorKey: 'uomId',
      Cell({ cell }) {
        const cellValue = cell.getValue<number | null>()
        if (cellValue) {
          const uom = findUomById(cellValue)
          return uom?.code
        }
        return ''
      },
    },
    {
      header: 'Costing',
      accessorKey: 'costing',
      Header() {
        return `Costing (${moment().format('YYYY-MM-DD')})`
      },
      Cell({ row }) {
        const id = row.original.id
        const cost = spCostingData.find(({ id: spId }) => spId === id)
        return (
          <CostingBreakdownOverlay
            breakdownData={cost?.breakdown || []}
            afterUpdateProduct={() => {
              fetchCosting()
              refetchSellerProductsData()
            }}
          >
            <div className={clsx('w-100', { 'color-red': cost?.cost })}>
              {formatCurrencyToDollar.format(cost?.cost || (0 as any))}
            </div>
          </CostingBreakdownOverlay>
        )
      },
    },
  ]

  const getIsLoading = (field: string) => loadingFields.includes(field)

  const toggleLoadingField = (field: string) => {
    setLoadingFields(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(field)
        if (index === -1) {
          draft.push(field)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }

  const onUpdateOpportunity = useCallback(
    async (field: keyof ISellerToBuyerTerminal, value: any) => {
      toggleLoadingField(field)
      try {
        const response = await apiClient.sellerToBuyerTerminals.update(
          data?.id as number,
          {
            sellerToBuyerTerminal: {
              [field]: value,
            },
          },
        )

        updateSellerToBuyerTerminals(response.id, response)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        toggleLoadingField(field)
      }
    },
    [data?.id, updateSellerToBuyerTerminals],
  )

  const onOpenBtspForm =
    (formData?: Partial<IBuyerTerminalSellerProduct>) => () => {
      setBtspForm({
        isOpen: true,
        formData,
      })
    }

  const onCloseBtspForm = () => {
    setBtspForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const afterCreateBtsp = (item: IBuyerTerminalSellerProduct) => {
    addBtsp(item)
    onCloseBtspForm()
  }

  const afterUpdateBtsp = (item: IBuyerTerminalSellerProduct) => {
    updateBtsp(item.id, item)
    onCloseBtspForm()
  }

  const onCloseAddressForm = () => {
    setAddressForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenAddressForm = (formData?: Partial<IAddress>) => () => {
    setAddressForm({
      isOpen: true,
      formData,
    })
  }

  const onClosePhoneNumberForm = () => {
    setPhoneNumberForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenPhoneNumberForm = (formData?: Partial<IPhoneNumber>) => () => {
    setPhoneNumberForm({
      isOpen: true,
      formData,
    })
  }

  const onCloseEmailForm = () => {
    setEmailForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenEmailForm = (formData?: Partial<IEmail>) => () => {
    setEmailForm({
      isOpen: true,
      formData,
    })
  }

  const onCloseProposalForm = () => {
    setProposalForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenProposalForm = (formData?: Partial<ISalesProposal>) => () => {
    setProposalForm({
      isOpen: true,
      formData,
    })
  }

  const onViewProposalPdf = (proposal: ISalesProposal) => () => {
    onOpenPdfViewer({
      id: proposal.id,
      model: 'SalesProposal',
      urlName: 'pdf',
    })
  }

  const onCloseSellerProdForm = () => {
    setSellerProdForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenSellerProdForm = (formData?: Partial<ISellerProduct>) => () => {
    setSellerProdForm({
      isOpen: true,
      formData,
    })
  }

  const onCloseAutoChargeForm = () => {
    setAutoChargeForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenAutoChargeForm = (formData?: Partial<IAutoCharge>) => () => {
    setAutoChargeForm({
      isOpen: true,
      formData,
    })
  }

  const onClosePriceEscalationForm = () => {
    setPriceEscalationForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenPriceEscalationForm =
    (formData?: Partial<IPriceEscalation>) => () => {
      setPriceEscalationForm({
        isOpen: true,
        formData,
      })
    }

  const onClosePaymentTermForm = () => {
    setPaymentTermForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenPaymentTermForm = (formData?: Partial<IPaymentTerm>) => () => {
    setPaymentTermForm({
      isOpen: true,
      formData,
    })
  }

  const onOpportunityCellEdit = async (
    value: any,
    cell: RTCell<ISellerToBuyerTerminal>,
  ) => {
    const { column, row } = cell
    const columnField = column.id
    const rowData = row.original
    const response = await apiClient.sellerToBuyerTerminals.update(rowData.id, {
      sellerToBuyerTerminal: {
        [columnField]: value,
      },
    })
    updateSellerToBuyerTerminals(response.id, response)
    toast.success(toastMessages.updateSuccess)
  }

  const onClosePricingCreateForm = () => {
    setPricingCreateForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onShowPricingCreateForm = (formData?: Partial<IPricing>) => () => {
    setPricingCreateForm({
      isOpen: true,
      formData,
    })
  }

  const onShowPricingUpdateForm = (formData?: Partial<IPricing>) => () => {
    setPricingUpdateForm({
      isOpen: true,
      formData,
    })
  }

  const onClosePricingUpdateForm = () => {
    setPricingUpdateForm({
      isOpen: false,
      formData: undefined,
    })
  }

  // useDeepCompareEffect(() => {
  //   if (isSellerProductsFetched && isAutoChargesFetched && data) {
  //     fetchPricings(allSellerProductIds)
  //   }
  // }, [
  //   allSellerProductIds,
  //   data?.id,
  //   fetchPricings,
  //   isAutoChargesFetched,
  //   isSellerProductsFetched,
  // ])

  const fetchCosting = async () => {
    const response = await apiClient.sellerProducts.postCosting({
      costing: costingPayload,
    })
    setSpCostingData(response)
  }

  useEffect(() => {
    if (
      isSellerTerminalProductsFetched &&
      isSellerToBuyerTerminalsFetched &&
      costingPayload.length > 0
    ) {
      fetchCosting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    costingPayload.length,
    isSellerTerminalProductsFetched,
    isSellerToBuyerTerminalsFetched,
  ])

  return (
    <>
      <HeaderContent renderContent={renderHeader} />

      <Body hideBody={hideBody}>
        <div className='OpportunitiesShowPage__container'>
          <div className='OpportunitiesShowPage__header'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div className='OpportunitiesShowPage__sellerHeader'>
                <CompanyAvatar company={seller as any} />
                <div style={{ fontSize: 16, margin: '8px 0', fontWeight: 600 }}>
                  {seller?.name} |{' '}
                  <DropdownWithCustomChildren
                    className='make-custom-dropdown-inline no-hover'
                    value={data?.sellerTerminalId}
                    options={[
                      { value: null, label: NOT_SET },
                      ...sellerTerminalOptions,
                    ]}
                    isLoading={getIsLoading('sellerTerminalId')}
                    onChange={(event, { value }) => {
                      onUpdateOpportunity('sellerTerminalId', value)
                    }}
                  >
                    {sellerTerminal ? (
                      <span>{sellerTerminal.code}</span>
                    ) : (
                      <CreateOrEditButton
                        tooltipContent='Add Seller Terminal'
                        canEdit={false}
                        style={{
                          marginBottom: 4,
                        }}
                      />
                    )}
                  </DropdownWithCustomChildren>
                </div>
                <div>
                  <span>
                    <strong>Address</strong>: {buildFullAddress(sellerAddress)}
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(sellerAddress)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenAddressForm({
                      addressableId: seller?.id,
                      addressableType: EAddressableType.company,
                      addressTypes: [EAddressType.office],
                    })}
                    onClickEdit={onOpenAddressForm(sellerAddress)}
                  />
                </div>
                <div>
                  <span>
                    <strong>Phone</strong>:{' '}
                    <a
                      href={`tel:${formatPhoneNumber(
                        sellerPhoneNumber?.number,
                      )}`}
                    >
                      {formatPhoneNumber(sellerPhoneNumber?.number)}
                    </a>
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(sellerPhoneNumber)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenPhoneNumberForm({
                      phoneableId: seller?.id,
                      phoneableType: EPhoneableType.company,
                      phoneTypes: [EPhoneNumberTypes.ap],
                    })}
                    onClickEdit={onOpenPhoneNumberForm(sellerPhoneNumber)}
                  />
                </div>
                <div>
                  <span>
                    <strong>Email</strong>:{' '}
                    <a href={`mailto:${sellerEmail?.email}`}>
                      {sellerEmail?.email}
                    </a>
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(sellerEmail)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenEmailForm({
                      emailableId: seller?.id,
                      emailableType: EEmailableType.company,
                      emailTypes: [EEmailTypes.ap],
                    })}
                    onClickEdit={onOpenEmailForm(sellerEmail)}
                  />
                </div>

                {sellerTerminal && (
                  <div>
                    <span>
                      <strong>Terminal Address</strong>:{' '}
                      {buildFullAddress(sellerTerminalAddress)}
                    </span>
                    <CreateOrEditButton
                      canEdit={Boolean(sellerTerminalAddress)}
                      style={{ marginLeft: 6 }}
                      onClickCreate={onOpenAddressForm({
                        addressableId: sellerTerminal?.id,
                        addressableType: EAddressableType.terminal,
                        addressTypes: [EAddressType.location],
                      })}
                      onClickEdit={onOpenAddressForm(sellerTerminalAddress)}
                    />
                  </div>
                )}

                {user ? (
                  <div>
                    {buildFullName(user?.person)} |
                    <span style={{ margin: '0 2px 0 4px' }}>
                      <a href={`tel:${user?.phoneNumber}`}>
                        {formatPhoneNumber(user?.phoneNumber)}
                      </a>
                    </span>{' '}
                    | <a href={`mailto:${user?.email}`}>{user?.email}</a>
                  </div>
                ) : (
                  <DropdownWithCustomChildren
                    className='make-custom-dropdown-inline no-hover'
                    options={userAccessOptions}
                    onChange={(event, { value }) => {
                      onUpdateOpportunity('userAccessId', value)
                    }}
                    isLoading={getIsLoading('userAccessId')}
                  >
                    <span style={{ fontSize: 12 }}>Set User</span>
                  </DropdownWithCustomChildren>
                )}
              </div>

              <div className='OpportunitiesShowPage__sellerHeader moveToRight'>
                <CompanyAvatar company={buyer as any} />
                <div style={{ fontSize: 16, margin: '8px 0', fontWeight: 600 }}>
                  {buyer?.name} |&nbsp;
                  <DropdownWithCustomChildren
                    className='make-custom-dropdown-inline no-hover'
                    value={data?.buyerTerminalId}
                    options={buyerTerminalOptions}
                    isLoading={getIsLoading('buyerTerminalId')}
                    onChange={(event, { value }) => {
                      onUpdateOpportunity('buyerTerminalId', value)
                    }}
                  >
                    <span>{buyerTerminal?.code}</span>
                  </DropdownWithCustomChildren>
                </div>
                <div>
                  <span>
                    <strong>Address</strong>: {buildFullAddress(buyerAddress)}
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(buyerAddress)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenAddressForm({
                      addressableId: buyer?.id,
                      addressableType: EAddressableType.company,
                      addressTypes: [EAddressType.office],
                    })}
                    onClickEdit={onOpenAddressForm(buyerAddress)}
                  />
                </div>

                <div>
                  <EditableInlineField
                    label='Attention'
                    field='attention'
                    value={data?.attention}
                    placeholder='Add Attention'
                    onChange={({ value }) => {
                      onUpdateOpportunity('attention', value)
                    }}
                  />
                </div>
                <div>
                  <span>
                    <strong>Phone</strong>:{' '}
                    <a
                      href={`tel:${formatPhoneNumber(
                        buyerPhoneNumber?.number,
                      )}`}
                    >
                      {formatPhoneNumber(buyerPhoneNumber?.number)}
                    </a>
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(buyerPhoneNumber)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenPhoneNumberForm({
                      phoneableId: buyer?.id,
                      phoneableType: EPhoneableType.company,
                      phoneTypes: [EPhoneNumberTypes.ap],
                    })}
                    onClickEdit={onOpenPhoneNumberForm(buyerPhoneNumber)}
                  />
                </div>
                <div>
                  <span>
                    <strong>Email</strong>:{' '}
                    <a href={`mailto:${buyerEmail?.email}`}>
                      {buyerEmail?.email}
                    </a>
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(buyerEmail)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenEmailForm({
                      emailableId: buyer?.id,
                      emailableType: EEmailableType.company,
                      emailTypes: [EEmailTypes.ap],
                    })}
                    onClickEdit={onOpenEmailForm(buyerEmail)}
                  />
                </div>
                <div>
                  <span>
                    <strong>Terminal Address</strong>:{' '}
                    {buildFullAddress(buyerTerminalAddress)}
                  </span>
                  <CreateOrEditButton
                    canEdit={Boolean(buyerTerminalAddress)}
                    style={{ marginLeft: 6 }}
                    onClickCreate={onOpenAddressForm({
                      addressableId: buyerTerminal?.id,
                      addressableType: EAddressableType.terminal,
                      addressTypes: [EAddressType.location],
                    })}
                    onClickEdit={onOpenAddressForm(buyerTerminalAddress)}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                fontSize: 13,
                marginTop: 8,
                borderTop: '1px dashed rgb(196, 196, 196)',
                paddingTop: 8,
              }}
            >
              <ReusableTable
                data={data ? [data] : []}
                className='ReusableTable__pdfFormat'
                onCellEditEnd={onOpportunityCellEdit}
                columns={[
                  {
                    header: 'Qty',
                    accessorKey: 'qty',
                    enableEditing: true,
                    editVariant: EFieldType.number,
                  },
                  {
                    header: 'Start Date',
                    accessorKey: 'startDate',
                    enableEditing: true,
                    editVariant: EFieldType.date,
                  },
                  {
                    header: 'End Date',
                    accessorKey: 'endDate',
                    enableEditing: true,
                    editVariant: EFieldType.date,
                  },
                  {
                    header: 'Group',
                    enableEditing: true,
                    accessorKey: 'groupId',
                    editVariant: EFieldType.singleSelect,
                    editSelectOptions: groupOptions,
                    editDropdownFieldProps() {
                      return {
                        showCreateButton: true,
                        showUpdateButtons: true,
                        renderForm({
                          isOpen,
                          optionSelected,
                          onCloseForm,
                        }: any) {
                          return (
                            <DialogGroupForm
                              isOpen={isOpen}
                              onClose={onCloseForm}
                              formData={optionSelected?.item}
                              afterCreate={async data => {
                                addGroup(data)
                                const result = await confirmation({
                                  message:
                                    'Do you want to set this group for this opportunity?',
                                })
                                if (result === EYesNo.Yes) {
                                  onUpdateOpportunity('groupId', data.id)
                                }
                              }}
                              afterUpdate={data => {
                                updateGroup(data.id, data)
                              }}
                            />
                          )
                        },
                      }
                    },
                  },
                  {
                    header: 'Work Force',
                    accessorKey: 'workForceId',
                    enableEditing: true,
                    editSelectOptions: workForceOptions,
                    editVariant: EFieldType.singleSelect,
                    editDropdownFieldProps() {
                      return {
                        showCreateButton: true,
                        showUpdateButtons: true,
                        renderForm({
                          isOpen,
                          optionSelected,
                          onCloseForm,
                        }: any) {
                          return (
                            <DialogWorkForceForm
                              isOpen={isOpen}
                              onClose={onCloseForm}
                              formData={optionSelected?.item}
                              afterCreate={async data => {
                                addWorkForce(data)
                                const result = await confirmation({
                                  message:
                                    'Do you want to set this work force for this opportunity?',
                                })
                                if (result === EYesNo.Yes) {
                                  onUpdateOpportunity('workForceId', data.id)
                                }
                              }}
                              afterUpdate={data => {
                                updateWorkForce(data.id, data)
                              }}
                            />
                          )
                        },
                      }
                    },
                  },
                  {
                    header: 'Work Type',
                    enableEditing: true,
                    accessorKey: 'workTypeId',
                    editSelectOptions: workTypeOptions,
                    editVariant: EFieldType.singleSelect,
                    editDropdownFieldProps() {
                      return {
                        showCreateButton: true,
                        showUpdateButtons: true,
                        renderForm({
                          isOpen,
                          optionSelected,
                          onCloseForm,
                        }: any) {
                          return (
                            <DialogWorkTypeForm
                              isOpen={isOpen}
                              onClose={onCloseForm}
                              formData={optionSelected?.item}
                              afterCreate={async data => {
                                addWorkType(data)
                                const result = await confirmation({
                                  message:
                                    'Do you want to set this work type for this opportunity?',
                                })
                                if (result === EYesNo.Yes) {
                                  onUpdateOpportunity('workTypeId', data.id)
                                }
                              }}
                              afterUpdate={data => {
                                updateWorkType(data.id, data)
                              }}
                            />
                          )
                        },
                      }
                    },
                  },
                  {
                    header: 'Escalation',
                    accessorKey: 'escalation',
                    Cell() {
                      if (isPriceEscalationFetching) {
                        return (
                          <div style={{ width: '100%' }}>
                            <Skeleton width='100%' height='100%' />
                          </div>
                        )
                      }
                      if (priceEscalation) {
                        const priceValue =
                          priceEscalation.escalationType === 'dollar'
                            ? formatCurrencyToDollar.format(
                                Number(priceEscalation.amount),
                              )
                            : `${priceEscalation.amount}%`
                        return (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span>{`${priceValue} ${priceEscalation.escalationCadence}`}</span>
                            <span
                              style={{ marginLeft: 4 }}
                              className='clickable'
                              onClick={onOpenPriceEscalationForm(
                                priceEscalation,
                              )}
                            >
                              <EditIcon color='var(--bs-warning)' />
                            </span>
                          </div>
                        )
                      }

                      return (
                        <span
                          className='clickable'
                          onClick={onOpenPriceEscalationForm()}
                        >
                          <PlusIcon color='var(--bs-primary)' />
                        </span>
                      )
                    },
                  },
                  {
                    header: 'Escalation Date',
                    accessorKey: 'escalationDate',
                    Cell() {
                      if (priceEscalation) {
                        const dateFormatted = format(
                          new Date(priceEscalation?.date),
                          'MMM-d',
                        )
                        return dateFormatted
                      }

                      return ''
                    },
                  },
                  {
                    header: 'Payment Term',
                    accessorKey: 'paymentTerm',
                    Cell() {
                      if (isPaymentTermFetching) {
                        return (
                          <div style={{ width: '100%' }}>
                            <Skeleton width='100%' height='100%' />
                          </div>
                        )
                      }
                      if (paymentTerm) {
                        return (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span>{paymentTerm.name}</span>
                            <span
                              style={{ marginLeft: 4 }}
                              className='clickable'
                              onClick={onOpenPaymentTermForm(paymentTerm)}
                            >
                              <EditIcon color='var(--bs-warning)' />
                            </span>
                          </div>
                        )
                      }
                      return (
                        <span
                          className='clickable'
                          onClick={onOpenPaymentTermForm()}
                        >
                          <PlusIcon color='var(--bs-primary)' />
                        </span>
                      )
                    },
                  },
                ]}
                tableHeight={43 + 51}
              />
            </div>
          </div>

          <div className='OpportunitiesShowPage__body'>
            <div className='OpportunitiesShowPage__tableContainer'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Proposals </h2>
                <ReusableButton
                  style={{ marginLeft: 4, marginBottom: 4 }}
                  tooltipContent='Create Seller Product'
                  onClick={onOpenProposalForm()}
                >
                  <PlusIcon color='white' />
                </ReusableButton>
              </div>
              <ReusableTable
                className='ReusableTable__pdfFormat'
                tableHeight={43 + 40 * salesProposalsData.length}
                enableRowActions
                state={{
                  showProgressBars: isSalesProposalsFetching,
                }}
                displayColumnDefOptions={{
                  [ERTDisplayColumnId.actions]: {
                    minSize: 100,
                    maxSize: 100,
                  },
                }}
                columns={[
                  {
                    header: '#',
                    accessorKey: 'num',
                    minSize: 50,
                    maxSize: 50,
                    Cell({ cell }) {
                      const cellValue = cell.getValue<string | null>()

                      return `${data?.num} - ${cellValue}`
                    },
                  },
                  {
                    header: 'Bidder',
                    accessorKey: 'bidderId',
                    Cell({ cell }) {
                      const bidderId = cell.getValue<number>()
                      const bidder = findBidderById(bidderId)
                      const buyer = findCompanyById(bidder?.buyerId)
                      return buildObjectName(buyer)
                    },
                  },
                  {
                    header: 'Status',
                    accessorKey: 'status',
                    Cell({ cell }) {
                      const cellValue = cell.getValue<string>()
                      return cellValue ? _.startCase(cellValue.toString()) : ''
                    },
                  },
                  {
                    header: 'Created At',
                    id: 'createdAt',
                    accessorFn: row =>
                      row.createdAt
                        ? format(new Date(row.createdAt), 'MMM-d (eee)')
                        : '',
                  },
                ]}
                renderRowActions={({ row }) => {
                  const rowData = row.original

                  return [
                    {
                      icon: <EditIcon color='white' />,
                      color: 'warning',
                      onClick: onOpenProposalForm(rowData),
                    },
                    {
                      icon: <PdfIcon color='white' />,
                      onClick: onViewProposalPdf(rowData),
                      color: 'info',
                    },
                  ]
                }}
                data={salesProposalsData}
              />
            </div>

            <div className='OpportunitiesShowPage__tableContainer'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Buyer Terminal Seller Products </h2>
                <ReusableButton
                  style={{ marginLeft: 4, marginBottom: 4 }}
                  tooltipContent='Create Seller Product'
                  onClick={onOpenBtspForm()}
                >
                  <PlusIcon color='white' />
                </ReusableButton>
              </div>
              <ReusableTable
                className='ReusableTable__pdfFormat'
                enableRowActions
                displayColumnDefOptions={{
                  [ERTDisplayColumnId.actions]: {
                    minSize: 80,
                    maxSize: 80,
                  },
                }}
                renderRowActions={({ row }) => {
                  const rowData = row.original

                  return [
                    {
                      icon: <EditIcon color='white' />,
                      color: 'warning',
                      onClick: onOpenBtspForm(rowData),
                    },
                  ]
                }}
                columns={[
                  {
                    header: 'Buyer Terminal',
                    accessorKey: 'buyerTerminalId',
                    Cell({ cell }) {
                      const value = cell.getValue<number>()
                      const terminal = findTerminalById(value)

                      return (
                        <ToolTipOverlay
                          content={terminal?.name}
                          placement='top'
                        >
                          <span>{terminal?.code}</span>
                        </ToolTipOverlay>
                      )
                    },
                  },
                  {
                    header: 'Seller Product',
                    accessorKey: 'sellerProductId',
                    Cell({ cell }) {
                      const value = cell.getValue<number>()
                      const sellerProd = findSellerProductById(value)
                      return buildObjectName(sellerProd)
                    },
                  },
                ]}
                data={btspsData}
                tableHeight={43 + 40 * btspsData.length}
              />
            </div>

            <div style={{ padding: 0 }}>
              <Row>
                <Col md='6'>
                  <div className='OpportunitiesShowPage__tableContainer'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h2>Standard Seller Products </h2>
                      <ReusableButton
                        style={{ marginLeft: 4, marginBottom: 4 }}
                        tooltipContent='Create Seller Product'
                        onClick={onOpenSellerProdForm()}
                      >
                        <PlusIcon color='white' />
                      </ReusableButton>
                    </div>

                    <ReusableTable
                      className='ReusableTable__pdfFormat'
                      tableHeight={
                        43 + 40 * standardProductsWithoutAutoCharges.length
                      }
                      enableRowActions
                      columns={productTableColumns}
                      data={standardProductsWithoutAutoCharges}
                      displayColumnDefOptions={{
                        [ERTDisplayColumnId.actions]: {
                          minSize: 80,
                          maxSize: 80,
                        },
                      }}
                      renderRowActions={({ row }) => {
                        const rowData = row.original

                        return [
                          {
                            icon: <EditIcon color='white' />,
                            color: 'warning',
                            onClick: onOpenSellerProdForm(rowData),
                          },
                        ]
                      }}
                    />
                  </div>
                </Col>
                <Col md='6'>
                  <div className='OpportunitiesShowPage__tableContainer'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h2>Products of Auto Charges </h2>
                      <ReusableButton
                        style={{ marginLeft: 4, marginBottom: 4 }}
                        tooltipContent='Create Auto Charge'
                        onClick={onOpenAutoChargeForm()}
                      >
                        <PlusIcon color='white' />
                      </ReusableButton>
                    </div>

                    <ReusableTable
                      className='ReusableTable__pdfFormat'
                      tableHeight={43 + 40 * sellerProdsOfAutoCharges.length}
                      columns={productTableColumns}
                      data={sellerProdsOfAutoCharges}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <DialogBTSPForm
          isOpen={btspForm.isOpen}
          formData={
            btspForm.formData || {
              buyerTerminalId: data?.buyerTerminalId,
            }
          }
          onClose={onCloseBtspForm}
          afterCreate={afterCreateBtsp}
          afterUpdate={afterUpdateBtsp}
        />

        <DialogAddressForm
          isOpen={addressForm.isOpen}
          formData={addressForm.formData}
          onClose={onCloseAddressForm}
          afterCreate={(formData: IAddress) => {
            addAddress(formData)
            onCloseAddressForm()
          }}
          afterUpdate={(formData: IAddress) => {
            updateAddress(formData.id, formData)
            onCloseAddressForm()
          }}
        />

        <DialogEmailForm
          isOpen={emailForm.isOpen}
          formData={emailForm.formData}
          onClose={onCloseEmailForm}
          afterCreate={(formData: IEmail) => {
            addEmail(formData)
            onCloseEmailForm()
          }}
          afterUpdate={(formData: IEmail) => {
            updateEmail(formData.id, formData)
            onCloseEmailForm()
          }}
        />

        <DialogPhoneNumberForm
          isOpen={phoneNumberForm.isOpen}
          formData={phoneNumberForm.formData}
          onClose={onClosePhoneNumberForm}
          afterCreate={(formData: IPhoneNumber) => {
            addPhoneNumber(formData)
            onClosePhoneNumberForm()
          }}
          afterUpdate={(formData: IPhoneNumber) => {
            updatePhoneNumber(formData.id, formData)
            onClosePhoneNumberForm()
          }}
        />

        <DialogSalesProposalForm
          isOpen={proposalForm.isOpen}
          onClose={onCloseProposalForm}
          formData={
            proposalForm.formData ||
            ({
              bidderId: bidderIdFromUrl
                ? typeof bidderIdFromUrl === 'string'
                  ? Number(bidderIdFromUrl)
                  : bidderIdFromUrl
                : undefined,
            } as any)
          }
          opportunityId={
            idData
              ? typeof idData === 'string'
                ? Number(idData)
                : idData
              : undefined
          }
          afterCreate={() => {
            refetchSalesProposals()
            onCloseProposalForm()
          }}
          afterUpdate={() => {
            refetchSalesProposals()
            onCloseProposalForm()
          }}
        />

        <DialogSellerProductForm
          isOpen={sellerProdForm.isOpen}
          formData={
            sellerProdForm.formData || {
              standardProduct: true,
              sellerId: currentCompany.id,
            }
          }
          onClose={onCloseSellerProdForm}
          afterCreate={formData => {
            addSellerProduct(formData)
            onCloseSellerProdForm()
          }}
          afterUpdate={formData => {
            updateSellerProduct(formData.id, formData)
            updateSellerProductWithHierarchy(formData.id, formData)
            onCloseSellerProdForm()
          }}
        />

        <DialogAutoChargesForm
          isOpen={autoChargeForm.isOpen}
          onClose={onCloseAutoChargeForm}
          formData={
            autoChargeForm.formData ||
            ({
              hierarchiesAttributes: [
                {
                  allowedHierarchyId: allowedHierarchy?.id,
                  buyerSideType: 'BuyerTerminal',
                  sellerSideType: 'Seller',
                  buyerSideId: data?.buyerTerminalId,
                  sellerSideId: data?.sellerId,
                  canDelete: false,
                },
              ],
            } as any)
          }
          afterCreate={() => {
            refetchHierarchyRowsMatches()
          }}
        />

        <DialogHierarchyRowForm
          isOpen={pricingCreateForm.isOpen}
          onClose={onClosePricingCreateForm}
          formData={
            {
              hierarchableType: 'Pricing',
              hierarchyAttributes: {
                allowedHierarchyId: allowedHierarchy?.id,
                sellerSideType: 'Seller',
                buyerSideType: 'BuyerTerminal',
                sellerSideId: data?.sellerId,
                buyerSideId: data?.buyerTerminalId,
              },
              hierarchableAttributes: {
                sellerProductId: pricingCreateForm.formData?.sellerProductId,
              },
            } as any
          }
          afterCreate={() => {
            refetchPricings()
            onClosePricingCreateForm()
          }}
        />

        <DialogPricingForm
          isOpen={pricingUpdateForm.isOpen}
          formData={pricingUpdateForm.formData}
          onClose={onClosePricingUpdateForm}
          afterUpdate={() => {
            refetchPricings()
            onClosePricingUpdateForm()
          }}
        />

        <DialogPriceEscalationForm
          isOpen={priceEscalationForm.isOpen}
          onClose={onClosePriceEscalationForm}
          formData={
            priceEscalationForm.formData ||
            ({
              hierarchiesAttributes: [
                {
                  allowedHierarchyId: allowedHierarchy?.id,
                  buyerSideType: 'BuyerTerminal',
                  sellerSideType: 'Seller',
                  buyerSideId: data?.buyerTerminalId,
                  sellerSideId: data?.sellerId,
                  canDelete: false,
                },
              ],
            } as any)
          }
          afterUpdate={data => {
            updatePriceEscalation(data.id, data)
            onClosePriceEscalationForm()
          }}
          afterCreate={() => {
            refetchPriceEscalation()
            onClosePriceEscalationForm()
          }}
        />

        <DialogPaymentTermForm
          isOpen={paymentTermForm.isOpen}
          onClose={onClosePaymentTermForm}
          formData={
            paymentTermForm.formData ||
            ({
              hierarchiesAttributes: [
                {
                  allowedHierarchyId: allowedHierarchy?.id,
                  buyerSideType: 'BuyerTerminal',
                  sellerSideType: 'Seller',
                  buyerSideId: data?.buyerTerminalId,
                  sellerSideId: data?.sellerId,
                  canDelete: false,
                },
              ],
            } as any)
          }
          afterUpdate={data => {
            updatePaymentTerm(data.id, data)
            onClosePaymentTermForm()
          }}
          afterCreate={() => {
            refetchPaymentTerm()
            onClosePaymentTermForm()
          }}
        />
      </Body>
    </>
  )
}

export default OpportunitiesShowPage
