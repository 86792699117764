import { useCallback, useState } from 'react'
import { useController, Control } from 'react-hook-form'
import Select, { Props, StylesConfig } from 'react-select'
import { IonLabel } from '@ionic/react'

import { DialogSellerProductForm } from '../ConcordForm'

import { CreateButton } from '../ReactSelect/components/CreateButton'
import { EOrderType } from '~/types/enums/ESellerProduct'
import { ISellerProduct } from '~/types/models/ISellerProduct'
import { useSellerProductsHierarchyOptions } from '~/hooks/useQueryData'
import { ICommonOption } from '~/types/models/ICommonModel'
import { EditOption } from '../ReactSelect/components/EditOption'

import '~/components/shared/FloatingForm/styles.scss'
import clsx from 'clsx'

const selectErrorStyle: StylesConfig = {
  control: base => ({
    ...base,
    borderColor: 'red',
  }),
  placeholder: base => ({
    ...base,
    color: 'red',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999999,
  }),
}

const selectStyle: StylesConfig = {
  control: base => ({
    ...base,
    color: 'black',
    backgroundColor: '#f7f7f7',
    borderWidth: '0px',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999999,
  }),
}

interface IProductSelectProps extends Props {
  buyerId?: number
  sellerId: number
  buyerTerminalId?: number
  sellerTerminalId?: number
  orderType?: EOrderType[]
  controlName: string
  control: Control
  customStyles?: StylesConfig
  onChange?: (value: any) => void
}

interface IShowProductForm {
  isOpen: boolean
  defaultValues?: Partial<ISellerProduct>
  shouldHideOptionalFields?: boolean
}

export const ProductSelect = ({
  buyerId,
  sellerId,
  buyerTerminalId,
  sellerTerminalId,
  orderType,
  controlName,
  control,
  customStyles,
  isDisabled,
  isOptionDisabled,
  onChange: onChangeProp,
  ...selectProps
}: IProductSelectProps) => {
  const {
    field: { onChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name: controlName,
    control,
  })
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const selectDisplay = error ? selectErrorStyle : selectStyle

  const [productForm, setProductForm] = useState<IShowProductForm>({
    isOpen: false,
  })

  const {
    sellerProductOptions: options,
    addAllTerminalsSellerProduct,
    isLoading: isLoadingOptions,
    findSellerProductById,
    updateSellerProduct,
  } = useSellerProductsHierarchyOptions(
    {
      buyerId,
      sellerId,
      buyerTerminalId,
      sellerTerminalId,
    },
    {
      enabled:
        Boolean(isMenuOpen) ||
        (Boolean(buyerId) &&
          Boolean(sellerId) &&
          Boolean(buyerTerminalId) &&
          Boolean(sellerTerminalId)),
    },
  )

  const onClickCreateProduct = useCallback(
    () =>
      setProductForm({
        isOpen: true,
        defaultValues: { sellerId },
        shouldHideOptionalFields: true,
      }),
    [sellerId],
  )

  const onClickEditSellerProduct = useCallback(
    (sellerProductId: number) => {
      setProductForm({
        isOpen: true,
        defaultValues: findSellerProductById(sellerProductId),
      })
    },
    [findSellerProductById],
  )

  const onCreateSellerProduct = useCallback(
    async (sp: ISellerProduct) => {
      onChange(sp.id)
      setProductForm({ isOpen: false })
      if (sp.allowToAllTerminals) {
        addAllTerminalsSellerProduct(sp)
      }
    },
    [addAllTerminalsSellerProduct, onChange],
  )

  return (
    <div className={clsx('FloatingSelectV2_root', { isDisabled })}>
      <IonLabel className='FloatingSelectV2_text'>Product</IonLabel>

      <Select
        className='d-flex'
        options={options}
        placeholder='Select product'
        components={{
          MenuList: CreateButton(onClickCreateProduct),
          Option: EditOption(onClickEditSellerProduct),
          IndicatorSeparator: null,
        }}
        styles={customStyles || selectDisplay}
        onBlur={onBlur}
        isSearchable
        isDisabled={isDisabled}
        onChange={(newValue: unknown) => {
          const selectedOption = newValue as ICommonOption
          onChange(selectedOption.value)
        }}
        // value={selectedOption}
        isLoading={isLoadingOptions}
        openMenuOnFocus
        ref={ref}
        menuPosition='fixed'
        isOptionDisabled={isOptionDisabled}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        {...selectProps}
      />

      <DialogSellerProductForm
        isOpen={productForm?.isOpen}
        onClose={() => setProductForm({ isOpen: false })}
        afterCreate={onCreateSellerProduct}
        afterUpdate={sp => updateSellerProduct(sp.id, sp)}
        formData={productForm?.defaultValues}
        shouldHideOptionalFields={productForm.shouldHideOptionalFields}
      />
    </div>
  )
}

export default ProductSelect
