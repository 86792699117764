import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetLoadsParams, IGetLoadsResponse } from '~/types/models/ILoad'
import useModifyLoads from './useModifyLoads'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryLoads = (
  params: Partial<IGetLoadsParams> & { id?: number } = {},
  options?: Partial<UseQueryOptions<IGetLoadsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched, isFetching } = useQuery({
    queryKey: [
      'loads',
      sessionUser?.id,
      buildGetUrl(apiClient.loads.endpoint, params, {
        // includeUndefined: true
      }),
    ],
    async queryFn() {
      if (options?.enabled === false) {
        return {
          loads: [],
          count: 1,
          page: 1,
          perPage: 25,
          pageCount: 1,
          countOnPage: 1,
          startRange: 1,
          endRange: 1,
        }
      }
      if (params?.id) {
        const load = await apiClient.loads.getById(params.id)
        return {
          loads: [load],
          count: 1,
          page: 1,
          perPage: 25,
          pageCount: 1,
          countOnPage: 1,
          startRange: 1,
          endRange: 1,
        }
      }
      return apiClient.loads.get(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const loadsData = useMemo(() => data?.loads || [], [data?.loads])

  const otherParamsOfGettingLoads = useMemo(() => {
    if (data) {
      const { loads, ...rest } = data
      return rest
    }

    return null
  }, [data])

  const findLoadById = (id: number | null | undefined) =>
    loadsData.find(l => l.id === id)

  const { addLoad, updateLoad, removeLoad } = useModifyLoads(params)

  return {
    loadsData,
    isLoadingLoadsData: isLoading,
    otherParamsOfGettingLoads,
    refetchLoadsData: refetch,
    isLoadsDataFetched: isFetched,
    isLoadsDataFetching: isFetching,
    addLoad,
    updateLoad,
    removeLoad,
    findLoadById,
  }
}

export default useQueryLoads
