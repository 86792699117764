import { useMemo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import moment from 'moment'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'

import {
  InvoiceBodyHeaderSellerCol,
  InvoiceBodyHeaderBuyerCol,
  InvoiceBodyHeaderStatusTerminalCol,
} from './InvoiceBodyHeaderColumns'
import {
  ConcordFormDateRangePicker,
  DialogTaxForm,
  DialogTerminalForm,
  DropdownWithCustomChildren,
  EditIcon,
  PlusIcon,
  ReusableButton,
  ReusableDatePicker,
} from '~/components/shared'
import TerminalDisplay from './InvoiceBodyHeaderColumns/TerminalDisplay'
import { NOT_SET_OPTION } from '~/utils/constants'
import { selectBillLinesFilters } from '~/redux/selectors'
import Table from 'react-bootstrap/Table'

import './InvoiceBodyHeader.scss'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import {
  useQueryAllowedHierarchies,
  useQueryBillLines,
  useQueryHierarchyRowsMatches,
  useQueryInvoices,
  useQueryTerminals,
  useQueryUoms,
} from '~/hooks/useQueryData'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import BillLinesTableRowModal from '../BillLinesTableRowModal'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import Skeleton from 'react-loading-skeleton'
import buildObjectName from '~/utils/buildObjectName'
import RCTooltip from 'rc-tooltip'
import { ETaxType } from '~/types/enums/ETax'

const InvoiceBodyHeader = ({ invoice, isResolved, subComponent }) => {
  const {
    buyerTerminalsData,
    sellerTerminalsData,
    updateTerminal,
    addTerminal,
    findTerminalById,
  } = useQueryTerminals()

  const { getDateTimeFormat } = useDateTimeFormat()

  // const { hierarchableMatches } = useQueryHierarchyRowsMatches({
  //   hierarchyRow: {
  //     matchingObjectType: 'Invoice',
  //     matchingObjectId: invoice?.id,
  //     hierarchableType: 'Tax',
  //   },
  // })

  const [showRefTerminalModal, setShowRefTerminalModal] = useState(false)
  const [modalMessage, setModalMessage] = useState({
    field: '',
    selected_name: '',
    selected_code: '',
    selectedId: null,
    currentLabel: '',
    currentCode: null,
    billLine: {},
  })
  const [isDateRangeUpdating, setIsDateRangeUpdating] = useState(false)
  const [isDueDateUpdating, setIsDueDateUpdating] = useState(false)
  const [terminalForm, setTerminalForm] = useState({
    isOpen: false,
    formData: undefined,
  })
  const [taxForm, setTaxForm] = useState({
    isOpen: false,
    formData: undefined,
  })

  const filterData = useSelector(selectBillLinesFilters)

  const { currentUom } = useQueryUoms()

  const { hierarchableMatches: taxesData, refetchHierarchyRowsMatches } =
    useQueryHierarchyRowsMatches({
      hierarchyRow: {
        matchingObjectType: 'Invoice',
        matchingObjectId: invoice?.id,
        hierarchableType: 'Tax',
        list: true,
      },
    })

  const { allowedHierarchiesData } = useQueryAllowedHierarchies()
  const allowedHierarchy = allowedHierarchiesData.find(
    ({ buyerSideType, sellerSideType }) =>
      sellerSideType === 'Seller' && buyerSideType === 'BuyerTerminal',
  )

  const totalOfTaxPercentage = useMemo(() => {
    return taxesData.reduce((acc, tax) => {
      if (tax.taxType === ETaxType.percentage) {
        return acc + Number(tax.taxValue || 0)
      }
      return acc
    }, 0)
  }, [taxesData])

  const totalOfTaxFlatFee = useMemo(() => {
    return taxesData.reduce((acc, tax) => {
      if (tax.taxType === ETaxType.flatFee) {
        return acc + Number(tax.taxValue || 0)
      }
      return acc
    }, 0)
  }, [taxesData])

  const totalOfTaxes = useMemo(() => {
    const labels = []
    if (totalOfTaxPercentage > 0) {
      labels.push(`${totalOfTaxPercentage}%`)
    }
    if (totalOfTaxFlatFee > 0) {
      labels.push(formatCurrencyToDollar.format(totalOfTaxFlatFee))
    }
    return labels.join(' + ')
  }, [totalOfTaxFlatFee, totalOfTaxPercentage])

  const { billLinesData } = useQueryBillLines(
    {
      filters: {
        ...filterData,
        invoiceId: invoice?.id,
      },
      perPage: 1000,
    },
    { enabled: Boolean(invoice?.id) },
  )

  const { updateInvoice } = useQueryInvoices(
    {
      id: invoice.id,
    },
    { enabled: Boolean(invoice?.id) },
  )

  const buyerTerminalOptions = useMemo(() => {
    const options = buyerTerminalsData
      .filter(({ companyId }) => companyId === invoice?.buyerId)
      .map(terminal => {
        return {
          value: terminal.id,
          label: buildObjectName(terminal),
          code: terminal.code,
          name: terminal.name,
          item: terminal,
        }
      })
    return [NOT_SET_OPTION, ...options]
  }, [buyerTerminalsData, invoice?.buyerId])

  const sellerTerminalOptions = useMemo(() => {
    const options = sellerTerminalsData.map(terminal => {
      return {
        value: terminal.id,
        label: buildObjectName(terminal),
        code: terminal.code,
        name: terminal.name,
        item: terminal,
      }
    })
    return [NOT_SET_OPTION, ...options]
  }, [sellerTerminalsData])

  const onCloseTaxForm = () => {
    setTaxForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onOpenTaxForm = formData => () => {
    setTaxForm({
      isOpen: true,
      formData,
    })
  }

  const renderTerminalForm = ({ isOpen, optionSelected, onCloseForm }) => (
    <DialogTerminalForm
      isOpen={isOpen}
      formData={optionSelected?.item}
      onClose={onCloseForm}
      afterUpdate={terminal => {
        updateTerminal(terminal.id, terminal)
        onCloseForm()
      }}
      afterCreate={terminal => {
        addTerminal(terminal)
        onCloseForm()
      }}
    />
  )

  const onChangeTerminal = useCallback(
    type =>
      (event, { selectedOption }) => {
        const field =
          type === 'sellerTerminal' ? 'sellerTerminalId' : 'buyerTerminalId'
        if (selectedOption.value) {
          const options =
            type === 'sellerTerminal' ? sellerTerminalsData : buyerTerminalsData
          const currentTerminal = options.find(
            terminal => terminal.id === invoice[field],
          )
          const currentLabel = currentTerminal?.name
          const selectedId = selectedOption?.value
          const selected_name = selectedOption?.name
          const selected_code = selectedOption?.code
          setModalMessage({
            field:
              type === 'sellerTerminal' ? 'Seller Terminal' : 'Buyer Terminal',
            selected_name: selected_name,
            selected_code: selected_code,
            selectedId: selectedId,
            currentLabel: currentLabel,
            billLine: {
              sellerId: invoice.sellerId,
              id: invoice.id,
            },
          })
          setShowRefTerminalModal(true)
        }

        const newInvoice = produce(invoice, draft => {
          draft[field] = selectedOption.value
        })
        updateInvoice(newInvoice.id, newInvoice)
      },
    [buyerTerminalsData, invoice, sellerTerminalsData, updateInvoice],
  )

  const onOpenTerminalForm = terminalId => event => {
    event.preventDefault()
    const terminal = findTerminalById(terminalId)
    setTerminalForm({
      isOpen: true,
      formData: terminal,
    })
  }

  const onCloseTerminalForm = () => {
    setTerminalForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const onUpdateDateRange = async date => {
    setIsDateRangeUpdating(true)
    try {
      const response = await apiClient.invoices.update(invoice.id, {
        startDate: moment(date.startDate).format('YYYY-MM-DD'),
        endDate: moment(date.endDate).format('YYYY-MM-DD'),
      })
      updateInvoice(response.id, response)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setIsDateRangeUpdating(false)
    }
  }

  const onUpdateDate = async date => {
    setIsDueDateUpdating(true)
    try {
      const response = await apiClient.invoices.update(invoice.id, {
        dueDate: moment(date).format('YYYY-MM-DD'),
      })
      updateInvoice(response.id, response)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setIsDueDateUpdating(false)
    }
  }

  const overlayElement = (
    <div style={{ fontSize: 13, fontWeight: 600 }}>
      <div className='InvoiceBodyHeader__sellerInfo'>
        <div>{`Line items: ${billLinesData.length}`}</div>
        <div>
          {invoice?.asp && `ASP: ${parseFloat(invoice.asp).toFixed(2)}`}
        </div>
        {invoice?.sub && (
          <div>Sub total: {formatCurrencyToDollar.format(invoice.sub)}</div>
        )}
      </div>
      <div>
        <div style={{ fontSize: 14 }}>
          Taxes: {formatCurrencyToDollar.format(invoice?.tax)}{' '}
          <Badge>{taxesData.length}</Badge>
        </div>
        {taxesData.length > 0 && (
          <ul>
            {taxesData.map(tax => (
              <li key={tax.id}>
                {tax.name}:{' '}
                {tax.taxType === ETaxType.flatFee
                  ? formatCurrencyToDollar.format(tax.taxValue)
                  : `${tax.taxValue}%`}
                <span
                  style={{ marginLeft: 8 }}
                  className='clickable'
                  onClick={onOpenTaxForm(tax)}
                >
                  <EditIcon color='var(--bs-warning)' />
                </span>
              </li>
            ))}
            {taxesData.length > 1 && (
              <>
                <hr />
                <li>Total: {totalOfTaxes}</li>
              </>
            )}
          </ul>
        )}
        <ReusableButton tooltipContent='Add Tax' onClick={onOpenTaxForm()}>
          <PlusIcon color='white' />
          <span
            style={{ marginLeft: 2, verticalAlign: 'middle', fontSize: 13 }}
          >
            Tax
          </span>
        </ReusableButton>
      </div>
    </div>
  )

  return (
    <>
      <div className='InvoiceBodyHeader_header'>
        <Table size='lg' style={{ marginBottom: 0 }}>
          <tbody>
            <tr>
              <td>
                <InvoiceBodyHeaderSellerCol
                  invoice={invoice}
                  className='InvoiceBodyHeader__item'
                />
              </td>
              <td className='InvoiceBodyHeader_section'>
                <DropdownWithCustomChildren
                  options={sellerTerminalOptions}
                  value={invoice?.sellerTerminalId}
                  onChange={onChangeTerminal('sellerTerminal')}
                  isReadOnly={isResolved}
                  className='no-hover'
                  showCreateButton
                  showUpdateButtons
                  renderForm={renderTerminalForm}
                >
                  <TerminalDisplay
                    terminalId={invoice.sellerTerminalId}
                    className='InvoiceBodyHeaderStatusTerminalCol__terminalDisplay'
                    onContextMenu={onOpenTerminalForm(invoice.sellerTerminalId)}
                  />
                </DropdownWithCustomChildren>
              </td>
              <td>
                <InvoiceBodyHeaderBuyerCol
                  invoice={invoice}
                  className='InvoiceBodyHeader__item'
                />
              </td>
              <td className='InvoiceBodyHeader_section'>
                <DropdownWithCustomChildren
                  options={buyerTerminalOptions}
                  value={invoice?.buyerTerminalId}
                  onChange={onChangeTerminal('buyerTerminal')}
                  isReadOnly={isResolved}
                  className='no-hover'
                  showCreateButton
                  showUpdateButtons
                  renderForm={renderTerminalForm}
                >
                  <TerminalDisplay
                    terminalId={invoice.buyerTerminalId}
                    className='InvoiceBodyHeaderStatusTerminalCol__terminalDisplay'
                    onContextMenu={onOpenTerminalForm(invoice.buyerTerminalId)}
                  />
                </DropdownWithCustomChildren>
              </td>
              <td className='text-center'>
                <InvoiceBodyHeaderStatusTerminalCol
                  invoice={invoice}
                  isResolved={isResolved}
                  className=''
                />
              </td>
              <RCTooltip placement='bottom' overlay={overlayElement}>
                <td
                  className='InvoiceBodyHeader_section text-center'
                  style={{ paddingTop: 8 }}
                >
                  <tr>
                    <div className='InvoiceBodyHeader__sellerInfo'>
                      Qty: {Number(invoice?.qty || 0)} {currentUom?.code} |{' '}
                      {invoice?.loadCount}
                      &nbsp;LD
                      {`${invoice?.loadCount > 0 ? 's' : ''}`}
                    </div>
                  </tr>
                  <tr style={{ paddingTop: 8 }}>
                    <div className='InvoiceBodyHeader__sellerInfo'>
                      Total: {formatCurrencyToDollar.format(invoice?.total)} |
                      Tax: {formatCurrencyToDollar.format(invoice?.tax)}
                    </div>
                  </tr>
                </td>
              </RCTooltip>

              <td
                className='InvoiceBodyHeader_section text-center'
                style={{ paddingTop: 8 }}
              >
                <tr>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        <div className='InvoiceBodyHeader__sellerInfo'>
                          {invoice?.startDate
                            ? getDateTimeFormat(
                                invoice?.startDate,
                                'ddd, MMM-DD, YYYY',
                              )
                            : '-/-'}{' '}
                          to{' '}
                          {invoice?.endDate
                            ? getDateTimeFormat(
                                invoice?.endDate,
                                'ddd, MMM-DD, YYYY',
                              )
                            : '-/-'}
                        </div>
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span className='InvoiceBodyHeader__dateRangeLabel'>
                        Date range:{' '}
                      </span>
                      {isDateRangeUpdating ? (
                        <Skeleton width={100} />
                      ) : (
                        <ConcordFormDateRangePicker
                          date={{
                            startDate: invoice?.startDate
                              ? moment(invoice?.startDate).toDate()
                              : null,
                            endDate: invoice?.endDate
                              ? moment(invoice?.endDate).toDate()
                              : null,
                          }}
                          onChange={onUpdateDateRange}
                          className='InvoiceBodyHeader__dateRangeSelector'
                        />
                      )}
                    </div>
                  </OverlayTrigger>
                </tr>
                <tr>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        <div className='InvoiceBodyHeader__sellerInfo'>
                          {moment(invoice?.dueDate).format('ddd, MMM-DD, YYYY')}
                        </div>
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        className='InvoiceBodyHeader__dateRangeLabel'
                        style={{ minWidth: 65 }}
                      >
                        Due date:{' '}
                      </span>
                      {isDueDateUpdating ? (
                        <Skeleton width={100} />
                      ) : (
                        <ReusableDatePicker
                          value={invoice?.dueDate}
                          onChange={onUpdateDate}
                          className='InvoiceBodyHeader__dateSelector'
                          showIcon={false}
                        />
                      )}
                    </div>
                  </OverlayTrigger>
                </tr>
              </td>
              {subComponent && (
                <td className='InvoiceBodyHeader_section text-end'>
                  {subComponent}
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
      <BillLinesTableRowModal
        showRefTerminalModal={showRefTerminalModal}
        setRefTerminalModal={setShowRefTerminalModal}
        modalMessage={modalMessage}
      />

      <DialogTerminalForm
        isOpen={terminalForm.isOpen}
        onClose={onCloseTerminalForm}
        formData={terminalForm.formData}
        afterUpdate={terminalUpdated => {
          updateTerminal(terminalUpdated.id, terminalUpdated)
          onCloseTerminalForm()
        }}
      />

      <DialogTaxForm
        isOpen={taxForm.isOpen}
        formData={
          taxForm.formData || {
            taxType: ETaxType.percentage,
            hierarchiesAttributes: [
              {
                allowedHierarchyId: allowedHierarchy?.id,
                buyerSideType: 'BuyerTerminal',
                sellerSideType: 'Seller',
                buyerSideId: invoice?.buyerTerminalId,
                sellerSideId: invoice?.sellerId,
                canDelete: false,
              },
            ],
          }
        }
        onClose={onCloseTaxForm}
        afterCreate={() => {
          refetchHierarchyRowsMatches()
          onCloseTaxForm()
        }}
        afterUpdate={() => {
          refetchHierarchyRowsMatches()
          onCloseTaxForm()
        }}
      />
    </>
  )
}

export default InvoiceBodyHeader
