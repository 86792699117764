import { CommonDialogV2, ICommonDialogProps } from '../CommonDialogV2'
import DocumentsMiniTable, {
  IDocumentsMiniTableProps,
} from './DocumentsMiniTable'

export interface IDialogDocumentsMiniTableProps
  extends IDocumentsMiniTableProps,
    ICommonDialogProps {}

function DialogDocumentsMiniTable(props: IDialogDocumentsMiniTableProps) {
  const { docTypeId, docType, formData, ...dialogProps } = props

  return (
    <CommonDialogV2 {...dialogProps} size='xl' isHiddenOkButton>
      <DocumentsMiniTable
        docTypeId={docTypeId}
        docType={docType}
        formData={formData}
      />
    </CommonDialogV2>
  )
}

export default DialogDocumentsMiniTable
