import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IBidder, IBidderGettingListParams } from '~/types/models/IBidder'

const useModifyBidders = (params: IBidderGettingListParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'bidders',
      sessionUser?.id,
      buildGetUrl(apiClient.bidders.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addBidder = useCallback(
    (data: IBidder) => {
      queryClient.setQueryData<IBidder[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateBidder = useCallback(
    (id: number, data: IBidder) => {
      queryClient.setQueryData<IBidder[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeBidder = useCallback(
    (id: number) => {
      queryClient.setQueryData<IBidder[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    updateBidder,
    addBidder,
    removeBidder,
  }
}

export default useModifyBidders
