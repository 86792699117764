import { useMemo } from 'react'
import Form from 'react-bootstrap/Form'
import {
  useQueryCompanies,
  useQuerySellerProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'

const SelectItemId = ({ itemType, itemId, disabled, onChange }) => {
  const { buyerTerminalsData, sellerTerminalsData } = useQueryTerminals()

  const { sellerCompanies } = useQueryCompanies({})
  const { options: sellerProductOptions } = useQuerySellerProducts()
  const options = useMemo(() => {
    switch (itemType) {
      case 'Seller':
        return sellerCompanies.map(s => ({ value: s.id, label: s.name }))
      case 'Seller Product':
        return sellerProductOptions
      case 'Buyer Terminal':
        return buyerTerminalsData.map(s => ({ value: s.id, label: s.name }))
      case 'Seller Terminal':
        return sellerTerminalsData.map(s => ({ value: s.id, label: s.name }))
      default:
        return []
    }
  }, [
    itemType,
    sellerCompanies,
    sellerProductOptions,
    buyerTerminalsData,
    sellerTerminalsData,
  ])

  return (
    <Form.Group className='m-3' controlId='item_id'>
      <Form.Label>{itemType}</Form.Label>
      <Form.Select
        name='itemId'
        size='lg'
        value={itemId}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
      >
        {options?.map(o => (
          <option value={o.value} key={`item-option-${o.value}`}>
            {o.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default SelectItemId
