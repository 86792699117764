import moment from 'moment'

const getDateLabelWhenExport = date => {
  if (date && date.startDate && date.endDate) {
    const st = moment(date?.startDate).format('YYYY-MM-DD')
    const et = moment(date?.endDate).format('YYYY-MM-DD')

    if (st === et) {
      return st
    }

    return `${st}_${et}`
  }
  return undefined
}

export default getDateLabelWhenExport
