import { useState, useRef } from 'react'
import { IonIcon } from '@ionic/react'
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons'
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SelectSearchDropdown } from '~/components/shared'
import { toast } from 'react-toastify'
import { updateBillLine } from '~/redux/actions/invoiceActions'
import { useDispatch } from 'react-redux'
import { selectInvoiceCompareId } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

const CostCodeCol = ({
  children,
  onChangeDropdown,
  rowIndex,
  buyerTerminals,
  billLine,
  getBuyerTerminalName,
  sellerProducts,
  getSellerProductName,
  productGroupsList,
}) => {
  const [show, setShow] = useState(false)
  const companyId = useSelector(state => state.session.user.company.id)
  const dispatch = useDispatch()
  const compareId = useSelector(selectInvoiceCompareId)

  const saveBillLine = productGroupId =>
    dispatch(
      updateBillLine(
        {
          invoiceId: billLine.invoiceId,
          id: billLine.id,
          productGroupId: productGroupId,
          compareId,
        },
        true,
      ),
    )

  const handleOnMouseEnter = () => {
    setShow(true)
  }
  const handleOnMouseLeave = () => {
    setShow(false)
  }
  const ref = useRef(null)

  const updateProductGroup = data => {
    const req = { productGroupId: data.value }
    apiClient.sellerProducts
      .update(billLine?.sellerProductId, req)
      .catch(() => toast.error('Error updating product category.'))
    saveBillLine(data.value)
  }

  const popover = (
    <Popover
      //id='popover-positioned-bottom'
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Popover.Header>
        {"Cost code couldn't be automatically set"}
      </Popover.Header>
      <Popover.Body>
        <ListGroup variant='flush' style={{ fontSize: 14 }}>
          <ListGroup.Item>
            <IonIcon
              icon={
                billLine?.buyerTerminalId
                  ? checkmarkCircleOutline
                  : closeCircleOutline
              }
              color={billLine.buyerTerminalId ? 'primary' : 'danger'}
              style={{ verticalAlign: 'baseline' }}
            />
            &nbsp;Buyer Terminal:&nbsp;
            <SelectSearchDropdown
              style={{ display: 'inline-block' }}
              value={buyerTerminals}
              currentValue={billLine.buyerTerminalId}
              onChange={onChangeDropdown({ rowIndex: rowIndex, colIndex: 10 })}
              subComponent={
                <div style={{ cursor: 'pointer' }}>
                  {getBuyerTerminalName(billLine.buyerTerminalId) || 'Not Set'}
                </div>
              }
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <IonIcon
              icon={
                billLine?.sellerProductId
                  ? checkmarkCircleOutline
                  : closeCircleOutline
              }
              color={billLine?.sellerProductId ? 'primary' : 'danger'}
              style={{ verticalAlign: 'baseline' }}
            />
            &nbsp;Product:&nbsp;
            <SelectSearchDropdown
              style={{ display: 'inline-block' }}
              value={sellerProducts}
              currentValue={billLine.sellerProductId}
              onChange={onChangeDropdown({ rowIndex: rowIndex, colIndex: 3 })}
              subComponent={
                <div style={{ cursor: 'pointer' }}>
                  {getSellerProductName(billLine.sellerProductId) || 'Not Set'}
                </div>
              }
            />
          </ListGroup.Item>

          {billLine.sellerProductId && (
            <ListGroup.Item>
              <IonIcon
                icon={
                  billLine?.productGroupId
                    ? checkmarkCircleOutline
                    : closeCircleOutline
                }
                color={billLine?.productGroupId ? 'primary' : 'danger'}
                style={{ verticalAlign: 'baseline' }}
              />
              &nbsp;Product Category:&nbsp;
              <SelectSearchDropdown
                style={{ display: 'inline-block' }}
                value={productGroupsList}
                currentValue={billLine.productGroupId}
                onChange={updateProductGroup}
                subComponent={
                  <div style={{ cursor: 'pointer' }}>
                    {productGroupsList.find(
                      p => p.value == billLine.productGroupId,
                    )?.label || 'Not Set'}
                  </div>
                }
              />
            </ListGroup.Item>
          )}

          {billLine?.buyerTerminalId &&
            billLine?.sellerProductId &&
            billLine?.productGroupId &&
            !billLine.costCode && (
              <ListGroup.Item>
                <IonIcon
                  icon={
                    billLine?.costCodeId
                      ? checkmarkCircleOutline
                      : closeCircleOutline
                  }
                  color={billLine?.costCodeId ? 'primary' : 'danger'}
                  style={{ verticalAlign: 'sub' }}
                />
                &nbsp;Cost Code not found:&nbsp;
                <Link to={`/companies/${companyId}?view=cost_codes`}>
                  Click to set.
                </Link>
              </ListGroup.Item>
            )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      {billLine.costCodeId ? (
        children
      ) : (
        <OverlayTrigger
          show={show}
          //container={ref}
          overlay={popover}
        >
          <div
            style={{ width: '100%' }}
            ref={ref}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            {children}
          </div>
        </OverlayTrigger>
      )}
    </>
  )
}

export default CostCodeCol
