import { Dropdown } from 'react-bootstrap'
import ReusableTimeRange, {
  IReusableTimeRangeProps,
  IReusableTimeRangeValue,
} from './ReusableTimeRange'
import { IonButton, IonButtons, IonLabel } from '@ionic/react'

import './styles.scss'
import { useMemo, useState } from 'react'
import moment from 'moment'

export interface IReusableTimeRangeInFilterProps
  extends IReusableTimeRangeProps {
  label: string
}

function ReusableTimeRangeInFilter(props: IReusableTimeRangeInFilterProps) {
  const { label, value, onChange, ...restProps } = props

  const [showDropdown, setShowDropdown] = useState(false)

  const isEmpty = Object.keys(value || {}).length === 0

  const labelValue = useMemo(() => {
    if (isEmpty) {
      return 'All'
    }

    const st = value.startTime
      ? moment(value.startTime).format('H:mm')
      : '--:--'
    const et = value.endTime ? moment(value.endTime).format('H:mm') : '--:--'
    return [st, et].join(' → ')
  }, [isEmpty, value])

  const onChangeTimeRange = (time: IReusableTimeRangeValue, field: string) => {
    onChange && onChange(time, field)
    if (field === 'endTime' && time.startTime && time.endTime) {
      setShowDropdown(false)
    }
  }

  return (
    <Dropdown
      className='ReusableTimeRangeInFilter__container'
      show={showDropdown}
      onToggle={setShowDropdown}
    >
      <Dropdown.Toggle
        variant='link'
        size='lg'
        role='menu'
        bsPrefix='p-0'
        style={{ textDecoration: 'none' }}
      >
        <IonButtons>
          <IonButton fill='clear' color={isEmpty ? 'medium' : 'primary'}>
            <IonLabel
              style={{
                fontWeight: 'bold',
                fontSize: 'small',
                textTransform: 'uppercase',
              }}
            >
              {label}:&nbsp;
            </IonLabel>
            <IonLabel>{labelValue}</IonLabel>
          </IonButton>
        </IonButtons>
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{ maxWidth: 200, border: '1px solid var(--bs-gray-500)' }}
      >
        <ReusableTimeRange
          {...restProps}
          style={{
            borderWidth: 0,
          }}
          value={value}
          onChange={onChangeTimeRange}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ReusableTimeRangeInFilter
