import { EFieldType } from '~/types/enums/ECommonEnum'
import './styles.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DropdownWithCustomChildren } from '../ConcordForm'
import { ICommonOption } from '~/types/models/ICommonModel'
import { CheckMarkIcon, CloseIcon } from '../SvgIcons'
import { ReusableDatePicker } from '../ReusableDatePicker'
import NumericFormat from 'react-number-format'

export interface IEditableInlineFieldProps {
  label: string
  value: any
  type?: EFieldType
  field: string
  onChange?: (params: {
    label: string
    value: any
    type?: EFieldType
    field: string
  }) => void
  options?: ICommonOption[]
  placeholder?: string
}

function EditableInlineField(props: IEditableInlineFieldProps) {
  const { label, value, type, options, field, placeholder, onChange } = props

  const [isEditing, setIsEditing] = useState(false)
  const [localValue, setLocalValue] = useState<any>(null)

  const onShowInputField = () => {
    setIsEditing(true)
  }

  const onSubmitChange = useCallback(() => {
    onChange &&
      onChange({
        label,
        value: localValue,
        type,
        field,
      })
    setIsEditing(false)
  }, [field, label, localValue, onChange, type])

  const renderField = useMemo(() => {
    switch (type) {
      case EFieldType.date:
        return (
          <ReusableDatePicker
            value={localValue}
            showIcon={false}
            placeholder=''
            onChange={newValue => {
              setLocalValue(newValue)
            }}
          />
        )

      case EFieldType.number:
        return (
          <NumericFormat
            autoFocus
            value={localValue}
            onValueChange={({ value }) => {
              setLocalValue(value)
            }}
          />
        )

      default:
        return (
          <input
            autoFocus
            value={localValue}
            onChange={event => {
              const newValue = event.target.value
              setLocalValue(newValue)
            }}
          />
        )
    }
  }, [localValue, type])

  const renderEditingField = useMemo(() => {
    switch (type) {
      case EFieldType.singleSelect:
        const opt = (options || []).find(opt => opt.value === value)
        return (
          <DropdownWithCustomChildren
            options={options || []}
            className='make-custom-dropdown-inline no-hover'
            value={value}
            onChange={(event, { value }) => {
              onChange &&
                onChange({
                  label,
                  value: value,
                  type,
                  field,
                })
            }}
          >
            {opt ? (
              opt?.label
            ) : placeholder ? (
              <span style={{ color: 'var(--bs-gray-500)', opacity: 0.8 }}>
                {placeholder}
              </span>
            ) : (
              '-'
            )}
          </DropdownWithCustomChildren>
        )

      default:
        return isEditing ? (
          <div className='EditableInlineField__inputFieldContainer'>
            {renderField}
            <span className='clickable' onClick={onSubmitChange}>
              <CheckMarkIcon color='var(--bs-success)' />
            </span>
            <span
              className='clickable'
              onClick={() => {
                setLocalValue(value)
                setIsEditing(false)
              }}
            >
              <CloseIcon color='var(--bs-danger)' size={16} />
            </span>
          </div>
        ) : (
          <span onClick={onShowInputField}>
            {localValue ? (
              localValue
            ) : placeholder ? (
              <span style={{ color: 'var(--bs-gray-500)', opacity: 0.8 }}>
                {placeholder}
              </span>
            ) : (
              '-'
            )}
          </span>
        )
    }
  }, [
    field,
    isEditing,
    label,
    localValue,
    onChange,
    onSubmitChange,
    options,
    renderField,
    type,
    value,
    placeholder,
  ])

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <div className='EditableInlineField__container'>
      <span className='EditableInlineField__label'>{label}: </span>
      {renderEditingField}
    </div>
  )
}

export default EditableInlineField
