import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import {
  IBilllineTax,
  IBillLineTaxGetListParams,
} from '~/types/models/IBillLineTax'

const useQueryBillLineTaxes = (
  params: IBillLineTaxGetListParams,
  options?: Partial<UseQueryOptions<IBilllineTax[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: [
      'billLineTaxes',
      sessionUser?.id,
      buildGetUrl(apiClient.billLineTaxes.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.billLineTaxes.get(params)
      return response.billLineTaxes
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const billLineTaxes = useMemo(() => data || [], [data])

  return {
    billLineTaxes,
    isBillLineTaxesLoading: isLoading,
    isBillLineTaxesFetched: isFetched,
    isBillLineTaxesFetching: isFetching,
  }
}

export default useQueryBillLineTaxes
