/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { people } from 'ionicons/icons'
import {
  DOCUMENT_STATUSES,
  DOCUMENT_DOC_TYPES,
  DOCUMENT_ACCESS_TYPES,
  DOCUMENT_FILE_TYPES,
} from '~/components/company/DocumentList/helpers'

import {
  DEFAULT_PRIMARY_PARSING_STATUSES,
  FLAG_FIELDS,
  HITL_STATUS_FIELDS,
} from '~/constants/loads/filters'

import {
  selectMyCurrentCompany,
  selectIsScopeBuyer,
  selectDriverFleetsOptions,
  selectCommonTagsOptions,
  selectCommonFleetOptions,
  selectCurrentScope,
} from '~/redux/selectors'
import _ from 'lodash'

import {
  moduleName,
  FILTER_OPTION_TYPES,
  FLAG_OPTIONS,
  MATCH_OPTIONS,
} from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'
import {
  defaultStatuses,
  plusFilterOptions,
} from '~/components/fleet/constants'
import { useQueryInvoicesEnums, useQuerySellerProducts } from '../useQueryData'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'

export const documentTypes = [
  { label: 'Load', value: 'Load' },
  { label: 'Invoice', value: 'Invoice' },
]

const dashboardModelTypes = [
  { label: 'Documents', value: 'document' },
  { label: 'Loads', value: 'load' },
  { label: 'Invoices', value: 'invoice' },
]

export const loadTypes = [
  { label: 'Impira', value: 'Impira' },
  { label: 'CaptureFast', value: 'CaptureFast' },
  { label: 'AWS', value: 'AWS' },
  { label: 'HITL', value: 'HITL' },
  { label: 'Veryfi', value: 'Veryfi' },
  { label: 'Butler', value: 'butler' },
]

export const allocateValue = [
  { label: 'Qty', value: 'Qty' },
  { label: 'Load', value: 'Load' },
]

export const modelOptions = [
  { label: 'Load', value: 'Load' },
  { label: 'Invoice', value: 'Invoice' },
  { label: 'BillLine', value: 'BillLine' },
]

export const itemType = [
  { label: 'materials', value: 0 },
  { label: 'delivery', value: 1 },
  { label: 'materials_delivery', value: 2 },
]

export const yesNoOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

const useFilterOptions = filterModuleName => {
  const { sellerProductOptions, refetchSellerProductsData } =
    useQuerySellerProducts({}, { enabled: false })

  const sellerOptions = []
  const buyerOptions = []
  const myCompany = useSelector(selectMyCurrentCompany)
  const currentScope = useSelector(selectCurrentScope)
  const isBuyer = useSelector(selectIsScopeBuyer)
  const driverOptions = useSelector(selectDriverFleetsOptions)
  const bspList = []

  const tagOptions = useSelector(selectCommonTagsOptions)
  const companyFleets = useSelector(selectCommonFleetOptions)

  const [fleetOptions, setFleetOptions] = useState([])
  const [sellerTerminalOptions, setSellerTerminalOptions] = useState([])

  const companyOptions = useMemo(
    () => _.merge(sellerOptions, buyerOptions),
    [buyerOptions, sellerOptions],
  )

  const getFleets = useCallback(async () => {
    try {
      const { companies } = await apiClient.companies.get({
        filters: {
          fleet: 'Yes',
        },
      })
      const data = companies
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setFleetOptions(data)
    } catch {
      console.log('error')
    }
  }, [])

  const getSellerTerminals = useCallback(async () => {
    if (sellerTerminalOptions.length > 0) {
      return
    }
    try {
      const sellerIds = sellerOptions.map(({ value }) => value)
      const companyId = sellerIds.length > 0 ? sellerIds : [myCompany.id]

      const sellerTerminal = await apiClient.terminals.get({
        filters: { companyId },
        all: true,
      })
      const sortList = sellerTerminal
        .map(c => ({
          value: c.id,
          label: `${c.code} - ${c.name}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setSellerTerminalOptions(sortList)
    } catch (error) {
      console.log('error', error)
    }
  }, [sellerTerminalOptions.length, sellerOptions, myCompany.id])

  const salesProposalStatuses = useMemo(
    () => [
      {
        label: 'In progress',
        value: 'in_progress',
      },
      {
        label: 'Sent',
        value: 'sent',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Rejected',
        value: 'rejected',
      },
    ],
    [],
  )

  const documentFilters = useMemo(
    () => [
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Buyer Terminal',
        field: 'buyerTerminalId',
        type: 'multipleSelect',
        options: [],
        showNotSetOption: true,
      },
      {
        label: 'Uploaded By',
        field: 'accessType',
        type: 'singleSelect',
        icon: people,
        options: DOCUMENT_ACCESS_TYPES,
      },
      {
        label: 'Status',
        field: 'status',
        type: 'multipleSelect',
        options: DOCUMENT_STATUSES,
      },
      {
        label: 'Doc Type',
        field: 'docType',
        type: 'multipleSelect',
        options: DOCUMENT_DOC_TYPES,
      },
      {
        label: 'File Types',
        field: 'fileType',
        type: 'multipleSelect',
        showNotSetOption: true,
        options: DOCUMENT_FILE_TYPES,
      },
      {
        label: 'Sellers',
        field: 'sellerId',
        type: 'multipleSelect',
        showNotSetOption: true,
        options: sellerOptions,
      },
      {
        label: 'Tags',
        field: 'tagIds',
        type: 'multipleSelect',
        options: tagOptions,
      },
    ],
    [sellerOptions, tagOptions],
  )

  const zonesFilters = useMemo(
    () => [
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
    ],
    [],
  )

  const sellerToBuyerTerminalsFilters = useMemo(
    () => [
      {
        label: 'Work type',
        field: 'workTypeId',
        type: FILTER_OPTION_TYPES.multipleSelect,
        options: [],
      },
      {
        label: 'Date',
        field: 'date',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Work force',
        field: 'workForceId',
        type: FILTER_OPTION_TYPES.multipleSelect,
        options: [],
      },
    ],
    [],
  )

  const loadsFilters = useMemo(() => {
    return [
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Buyer Terminal',
        field: 'buyerTerminalId',
        type: 'multipleSelect',
        options: [],
        showNotSetOption: true,
      },
      {
        label: 'Date',
        field: 'date',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Flag Status',
        field: 'flags',
        type: 'singleSelect',
        options: FLAG_FIELDS,
      },
      {
        label: 'Status',
        field: 'primaryParserStatus',
        type: 'multipleSelect',
        options: DEFAULT_PRIMARY_PARSING_STATUSES,
        sortable: false,
      },
      {
        label: 'Hitl Status',
        field: 'hitlStatus',
        type: 'multipleSelect',
        options: HITL_STATUS_FIELDS,
      },
      {
        label: 'Seller',
        field: 'sellerId',
        type: 'multipleSelect',
        options: sellerOptions,
        showNotSetOption: true,
      },
      {
        label: 'Fleet',
        field: 'fleetId',
        type: 'multipleSelect',
        options: fleetOptions,
        onLoadFilter: getFleets,
      },
      {
        label: 'Seller Terminal',
        field: 'sellerTerminalId',
        type: 'multipleSelect',
        options: sellerTerminalOptions,
        onLoadFilter: getSellerTerminals,
      },
      {
        label: 'Product',
        field: 'sellerProductId',
        type: 'multipleSelect',
        options: sellerProductOptions,
        onLoadFilter: refetchSellerProductsData,
      },
      {
        label: 'Seller Product',
        field: 'sellerProductFilter',
        type: 'multipleSelect',
        options: sellerProductOptions,
      },
      {
        label: 'Quantity',
        field: 'qty',
        type: 'rangeNumber',
      },
      {
        label: 'Tags',
        field: 'tagIds',
        type: 'multipleSelect',
        options: tagOptions,
      },
    ]
  }, [
    fleetOptions,
    getFleets,
    getSellerTerminals,
    sellerOptions,
    sellerProductOptions,
    sellerTerminalOptions,
    tagOptions,
  ])

  const {
    invoiceStatusOptions,
    invoiceSellerStatusOptions,
    invoicePrimaryParsingStatusOptions,
    invoiceHitlStatusOptions,
    invoiceCategoryOptions,
  } = useQueryInvoicesEnums()

  const invoiceFilters = useMemo(
    () => [
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'End Date',
        field: 'date',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Sellers',
        field: 'sellerId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Buyer Terminals',
        field: 'buyerTerminalId',
        options: [],
        type: 'multipleSelect',
        showNotSetOption: true,
      },
      {
        label: 'Flag Status',
        field: 'flags',
        type: 'singleSelect',
        options: FLAG_FIELDS,
      },
      {
        label: 'Status',
        field: 'status',
        options: invoiceStatusOptions,
        type: 'multipleSelect',
        isHidden: currentScope === EScope.seller,
      },
      {
        label: 'Seller Status',
        field: 'sellerStatus',
        type: EFieldType.multipleSelect,
        options: invoiceSellerStatusOptions,
        isHidden: currentScope !== EScope.seller,
      },
      {
        label: 'Type',
        field: 'type',
        options: invoiceCategoryOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Parser Status',
        field: 'parserStatus',
        options: invoicePrimaryParsingStatusOptions,
        type: 'multipleSelect',
      },
      {
        label: 'HITL Status',
        field: 'hitlStatus',
        options: invoiceHitlStatusOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Total',
        field: 'total',
        type: 'rangeNumber',
      },
      {
        label: 'Quantity',
        field: 'qty',
        type: 'rangeNumber',
      },
      {
        label: 'ASP',
        field: 'asp',
        type: 'rangeNumber',
      },
      {
        label: 'Load Count',
        field: 'loadCount',
        type: 'rangeNumber',
      },
      {
        label: 'Tags',
        field: 'tagIds',
        type: 'multipleSelect',
        options: tagOptions,
      },
    ],
    [sellerOptions, tagOptions],
  )

  const billLineFilters = useMemo(
    () => [
      {
        label: 'Date',
        field: 'date',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Buyer Terminal',
        field: 'buyerTerminalId',
        type: 'multipleSelect',
        options: [],
      },
      {
        label: 'Product',
        field: 'sellerProductId',
        type: 'multipleSelect',
        options: bspList,
      },
      {
        label: 'Quantity',
        field: 'qty',
        type: 'rangeNumber',
      },
      {
        label: 'Price',
        field: 'price',
        type: 'rangeNumber',
      },
      {
        label: 'Total',
        field: 'total',
        type: 'rangeNumber',
      },
      {
        label: 'Tax',
        field: 'tax',
        type: 'rangeNumber',
      },
      {
        label: 'EXT. Price',
        field: 'extPrice',
        type: 'rangeNumber',
      },
      {
        label: 'Tags',
        field: 'tagIds',
        type: 'multipleSelect',
        options: tagOptions,
      },
      {
        label: 'Flag',
        field: 'flag',
        type: 'multipleSelect',
        options: FLAG_OPTIONS,
      },
      {
        label: 'matching',
        field: 'matching',
        type: 'multipleSelect',
        options: MATCH_OPTIONS,
      },
    ],
    [bspList, tagOptions],
  )

  const billLineSearchFilters = useMemo(
    () => [
      {
        label: 'Date',
        field: 'date',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Buyer Terminal',
        field: 'buyerTerminalId',
        type: 'multipleSelect',
        options: [],
      },
      {
        label: 'Product',
        field: 'sellerProductId',
        type: 'multipleSelect',
        options: bspList,
      },
    ],
    [bspList],
  )

  const dashboardFilters = useMemo(
    () => [
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Showing',
        field: 'modelType',
        type: 'singleSelect',
        options: dashboardModelTypes,
      },
    ],
    [],
  )

  const orderTrackingFilters = useMemo(
    () => [
      {
        label: 'Date',
        field: 'startTime',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Buyer Terminal',
        field: 'buyerTerminalId',
        type: 'multipleSelect',
        options: [],
      },
      {
        label: 'Seller Terminal',
        field: 'sellerTerminalId',
        type: 'multipleSelect',
        options: [],
      },
      {
        label: 'Fleet',
        field: 'fleetId',
        type: 'multipleSelect',
        options: companyFleets,
      },
      {
        label: 'Buyer',
        field: 'buyerId',
        type: 'multipleSelect',
        options: buyerOptions,
      },
      {
        label: 'Seller',
        field: 'sellerId',
        type: 'multipleSelect',
        options: sellerOptions,
      },
      {
        label: 'Status',
        field: 'status',
        type: 'multipleSelect',
        options: defaultStatuses,
      },
      {
        label: 'Qty',
        field: 'qty',
        type: 'rangeNumber',
      },
      {
        label: 'Plus',
        field: 'plus',
        type: 'multipleSelect',
        options: plusFilterOptions,
      },
      {
        label: 'Seller Product',
        field: 'sellerProductId',
        type: 'multipleSelect',
        options: sellerProductOptions,
      },
      {
        label: 'Schedule #',
        field: 'scheduleNumber',
        type: 'rangeNumber',
      },
      {
        label: 'Color',
        field: 'color',
        type: 'multipleSelect',
        options: [],
      },
      {
        label: 'Spacing',
        field: 'spacing',
        type: 'rangeNumber',
      },
    ],
    [companyFleets, buyerOptions, sellerOptions, sellerProductOptions],
  )

  const companyParserFilters = useMemo(
    () => [
      {
        label: 'Document Type',
        field: 'documentType',
        options: documentTypes,
        type: 'multipleSelect',
      },
      {
        label: 'Parser',
        field: 'parser',
        options: _.orderBy(loadTypes, 'label'),
        type: 'multipleSelect',
      },
      {
        label: 'Internal use only',
        field: 'internalUseOnly',
        options: yesNoOptions,
        type: 'singleSelect',
      },
    ],
    [],
  )

  const companyAutoChargeFilters = useMemo(
    () => [
      {
        label: 'Sellers',
        field: 'sellerId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Allocated Per',
        field: 'allocatedPer',
        options: allocateValue,
        type: 'multipleSelect',
      },
      {
        label: 'Application Level',
        field: 'applicationLevel',
        options: documentTypes,
        type: 'multipleSelect',
      },
    ],
    [sellerOptions],
  )

  const companyBtfStpFilters = useMemo(
    () => [
      {
        label: 'Sellers',
        field: 'sellerId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
    ],
    [sellerOptions],
  )

  const companyEmailParserFilters = useMemo(
    () => [
      {
        label: 'Sellers',
        field: 'sellerId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Doc Type',
        field: 'docTypeValue',
        options: documentTypes,
        type: 'multipleSelect',
      },
    ],
    [sellerOptions],
  )

  const companyDataCorrectionMethods = useMemo(
    () => [
      {
        label: 'Sellers',
        field: 'companyId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
    ],
    [sellerOptions],
  )

  const companyParserColumnMappings = useMemo(
    () => [
      {
        label: 'Company',
        field: 'companyId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Type',
        field: 'model',
        options: modelOptions,
        type: 'multipleSelect',
      },
    ],
    [sellerOptions],
  )

  const companyFixedPrices = useMemo(
    () => [
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Sellers',
        field: 'sellers',
        options: sellerOptions,
        type: 'multipleSelect',
      },
      {
        label: 'Buyer Terminal',
        field: 'buyerTerminal',
        options: [],
        type: 'multipleSelect',
      },
      {
        label: 'Seller Terminal',
        field: 'sellerTerminal',
        options: sellerTerminalOptions,
        type: 'multipleSelect',
        onLoadFilter: getSellerTerminals,
      },
      {
        label: 'Item Type',
        field: 'itemType',
        options: itemType,
        type: 'multipleSelect',
      },
      {
        label: 'Price',
        field: 'price',
        type: 'rangeNumber',
      },
      {
        label: 'Flat rate',
        field: 'flatRate',
        type: 'rangeNumber',
      },
    ],
    [getSellerTerminals, sellerOptions, sellerTerminalOptions],
  )

  const companyProductFilters = useMemo(
    () => [
      {
        label: 'Sellers',
        field: 'sellerId',
        options: sellerOptions,
        type: 'multipleSelect',
      },
    ],
    [sellerOptions],
  )

  const companySellerProductFilters = useMemo(
    () => [
      {
        label: 'Sellers',
        field: 'sellerId',
        options: [],
        type: 'multipleSelect',
      },
    ],
    [],
  )

  const companyTerminalFilters = useMemo(
    () => [
      {
        label: 'Companies',
        field: 'companyId',
        options: companyOptions,
        type: 'multipleSelect',
        showNotSetOption: true,
      },
    ],
    [companyOptions],
  )

  const companyReferenceNameFilters = useMemo(
    () => [
      {
        label: isBuyer ? 'Seller' : 'Buyer',
        field: isBuyer ? 'sellerId' : 'buyerId',
        options: isBuyer ? sellerOptions : buyerOptions,
        type: 'multipleSelect',
      },
    ],
    [buyerOptions, isBuyer, sellerOptions],
  )

  const salesProposalsFilters = useMemo(
    () => [
      {
        label: 'Buyers',
        field: 'buyerId',
        options: buyerOptions,
        type: 'multipleSelect',
        showNotSetOption: true,
      },
      {
        label: 'Seller Terminals',
        field: 'sellerTerminalId',
        options: [],
        type: 'multipleSelect',
        showNotSetOption: true,
      },
      {
        label: 'Buyer Terminals',
        field: 'buyerTerminalId',
        options: [],
        type: 'multipleSelect',
        showNotSetOption: true,
      },
      {
        label: 'Status',
        field: 'status',
        options: salesProposalStatuses,
        type: 'multipleSelect',
      },
    ],
    [buyerOptions, salesProposalStatuses],
  )

  const startTimeFilters = useMemo(
    () => [
      {
        label: 'Start Time',
        field: 'startTime',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Driver',
        field: 'driverId',
        type: 'multipleSelect',
        options: driverOptions,
      },
      {
        label: 'Terminal',
        field: 'terminalId',
        type: 'multipleSelect',
        options: [],
      },
      {
        label: 'Color',
        field: 'color',
        type: 'multipleSelect',
        options: [],
        showNotSetOption: true,
      },
      {
        label: 'Seen At',
        field: 'seenAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Confirmed At',
        field: 'confirmedAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Created At',
        field: 'createdAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Updated At',
        field: 'updatedAt',
        type: FILTER_OPTION_TYPES.dateRange,
      },
    ],
    [driverOptions],
  )

  const shiftsFilters = useMemo(
    () => [
      {
        label: 'Clock in',
        field: 'clockIn',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Clock out',
        field: 'clockOut',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Driver Fleet',
        field: 'driverFleetId',
        type: 'multipleSelect',
        options: driverOptions,
      },
    ],
    [driverOptions],
  )

  const paymentsFilters = useMemo(
    () => [
      {
        label: 'Date',
        field: 'date',
        type: FILTER_OPTION_TYPES.dateRange,
      },
      {
        label: 'Buyer',
        field: 'buyerId',
        type: FILTER_OPTION_TYPES.multipleSelect,
        options: buyerOptions,
      },
      {
        label: 'Seller',
        field: 'sellerId',
        type: FILTER_OPTION_TYPES.multipleSelect,
        options: sellerOptions,
      },
    ],
    [buyerOptions, sellerOptions],
  )

  const getFilteOptions = useCallback(
    key => {
      const filters = {
        documents: documentFilters,
        loads: loadsFilters,
        invoices: invoiceFilters,
        billLines: billLineFilters,
        billLineSearch: billLineSearchFilters,
        dashboard: dashboardFilters,
        orderTracking: orderTrackingFilters,
        salesProposals: salesProposalsFilters,
        startTimes: startTimeFilters,
        zones: zonesFilters,
        sellerToBuyerTerminals: sellerToBuyerTerminalsFilters,
        [moduleName.company.parsers]: companyParserFilters,
        [moduleName.company.autoCharges]: companyAutoChargeFilters,
        [moduleName.company.btfstps]: companyBtfStpFilters,
        [moduleName.company.emailParsers]: companyEmailParserFilters,
        [moduleName.company.dataCorrectionMethods]:
          companyDataCorrectionMethods,
        [moduleName.company.parserColumnMappings]: companyParserColumnMappings,
        [moduleName.company.fixedPrices]: companyFixedPrices,
        [moduleName.company.products]: companyProductFilters,
        [moduleName.company.sellerProducts]: companySellerProductFilters,
        [moduleName.company.terminals]: companyTerminalFilters,

        [moduleName.company.referenceNames]: companyReferenceNameFilters,
        shifts: shiftsFilters,
        payments: paymentsFilters,
        default: [],
      }

      return filters[key] || filters.default
    },
    [
      documentFilters,
      loadsFilters,
      invoiceFilters,
      billLineFilters,
      billLineSearchFilters,
      dashboardFilters,
      orderTrackingFilters,
      salesProposalsFilters,
      startTimeFilters,
      zonesFilters,
      companyParserFilters,
      companyAutoChargeFilters,
      companyBtfStpFilters,
      companyEmailParserFilters,
      companyDataCorrectionMethods,
      companyParserColumnMappings,
      companyFixedPrices,
      companyProductFilters,
      companySellerProductFilters,
      companyTerminalFilters,
      companyReferenceNameFilters,
      sellerToBuyerTerminalsFilters,
      shiftsFilters,
      paymentsFilters,
    ],
  )

  const filterColumns = useMemo(() => {
    return getFilteOptions(filterModuleName)
  }, [getFilteOptions, filterModuleName])

  return {
    getFilteOptions,
    filterColumns,
    filterOptions: filterColumns,
  }
}

export default useFilterOptions
