import { useMemo } from 'react'

import { KeyValueList } from '~/components/shared'

const BillLineOcrList = props => {
  const { ocrData } = props

  const rowItems = useMemo(
    () => [
      {
        label: 'Energy Fees',
        field: 'energyFees',
      },
      {
        label: 'Ext Price',
        field: 'extPrice',
        type: 'number',
      },
      {
        label: 'Freight Ext Price',
        field: 'freightExtPrice',
      },
      {
        label: 'Freight Rate',
        field: 'freightRate',
      },
      {
        label: 'Other Charges',
        field: 'otherCharges',
      },
      {
        label: 'Price',
        field: 'price',
      },
      {
        label: 'Product Name',
        field: 'productName',
      },
      {
        label: 'Quantity',
        field: 'qty',
      },
      {
        label: 'Seller Terminal Code',
        field: 'sellerTerminalCode',
      },
      {
        label: 'Load Date',
        field: 'date',
      },
      {
        label: 'Ticket #',
        field: 'ticketNumber',
      },
      {
        label: 'Total Ext Price',
        field: 'totalExtPrice',
      },
    ],
    [],
  )

  return (
    <div>
      <KeyValueList rowData={ocrData} rowItems={rowItems} editable={false} />
    </div>
  )
}

export default BillLineOcrList
