import { useMemo } from 'react'

import { apiClient } from '~/api/ApiClient'
import { ExtrasFormFieldArray } from './ExtrasFormFieldArray'
import { useQuery } from 'react-query'
import { buildGetUrl } from '~/utils/buildUrl'

export const ExtrasForm = ({ prefix = '', autoExtrasParams, ...props }) => {
  const canFetchAutoExtras = useMemo(() => {
    try {
      const {
        order: { buyerId, buyerTerminalId, schedulesAttributes, sellerId },
      } = autoExtrasParams

      const schedulesHasData = schedulesAttributes.every(
        ({ qty, sellerTerminalId, startTime }) =>
          Boolean(qty && sellerTerminalId && startTime),
      )

      return Boolean(buyerId && buyerTerminalId && sellerId && schedulesHasData)
    } catch (error) {
      return false
    }
  }, [autoExtrasParams])

  const { data, isFetching } = useQuery(
    {
      queryKey: [
        'auto_extras/new',
        buildGetUrl(`${apiClient.autoExtras.endpoint}/new`, autoExtrasParams, {
          includeUndefined: true,
        }),
      ],
      async queryFn() {
        if (canFetchAutoExtras) {
          const response = await apiClient.autoExtras.new(autoExtrasParams)
          return response
        }
        return {
          autoExtras: [],
        }
      },
    },
    { enabled: canFetchAutoExtras, staleTime: 240000 },
  )

  const autoExtras = data?.autoExtras || []

  return (
    <ExtrasFormFieldArray
      prefix={prefix}
      autoExtras={autoExtras}
      loading={isFetching}
      {...props}
    />
  )
}
