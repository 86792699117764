import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const FileIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path fill={color} d='M7 0H2v16h12V7H7V0Z' />
      <path fill={color} d='M9 0v5h5L9 0Z' />
    </svg>
  )
}

export default FileIcon
