import {
  useSellerProductsHierarchyOptions,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { IBillLine } from '~/types/models/IBillLine'
import { IInvoice } from '~/types/models/IInvoice'
import BillLinesTable from '../BillLinesTable'
import { useCallback, useMemo, useState } from 'react'
import { ColumnFiltersState } from '@tanstack/react-table'

import './styles.scss'
import { LinkIcon } from '~/components/shared'
import {
  EFieldType,
  ERTDisplayColumnId,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { useConfirmationProvider } from '~/contexts'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import getIsInvoiceEditingDisabled from '~/utils/getIsInvoiceEditingDisabled'

export interface IMatchingBillLinesTableProps {
  invoice: IInvoice
  billLine: IBillLine
  afterMatchingBillLines: (
    newBillLine: IBillLine,
    billLineSelected: IBillLine,
  ) => void
}

function MatchingBillLinesTable(props: IMatchingBillLinesTableProps) {
  const { invoice, billLine, afterMatchingBillLines } = props

  const [isLoading, setIsLoading] = useState(false)

  const { buyerTerminalOptions } = useQueryTerminals()

  const { confirmation } = useConfirmationProvider()

  const isInvoiceApproved = getIsInvoiceEditingDisabled(invoice)

  const { sellerProductOptions } = useSellerProductsHierarchyOptions({
    buyerId: billLine.buyerId,
    buyerTerminalId: billLine.buyerTerminalId,
    sellerId: billLine.sellerId,
    sellerTerminalId: billLine.sellerTerminalId,
  })

  const onMatchBillLine = useCallback(
    (billLineSelected: IBillLine) => async () => {
      const result = await confirmation({
        message: 'Are you sure you want to match these two bill lines?',
      })
      if (result === EYesNo.Yes) {
        setIsLoading(true)
        try {
          const [
            { billLine: billLineUpdated },
            { billLine: nextBillLineSelected },
          ] = await Promise.all([
            apiClient.billLines.update(billLine.id, {
              billLineId: billLineSelected.id,
            }),
            apiClient.billLines.update(billLineSelected.id, {
              billLineId: billLine.id,
            }),
          ])
          afterMatchingBillLines(billLineUpdated, nextBillLineSelected)
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [billLine.id, confirmation],
  )

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'buyerTerminalId',
      value: billLine.buyerTerminalId ? [billLine.buyerTerminalId] : [],
    },
    {
      id: 'sellerProductId',
      value: billLine.sellerProductId ? [billLine.sellerProductId] : [],
    },
    {
      id: 'unmatchedFilter',
      value: true,
    },
    {
      id: 'fromLoads',
      value: true,
    },
  ])

  const filterState = useMemo(() => {
    const filter: Record<string, any> = {}
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  return (
    <BillLinesTable
      className='MatchingBillLinesTable__container'
      invoice={invoice}
      queryParams={{
        filters: {
          buyerId: invoice.buyerId,
          sellerId: invoice.sellerId,
          ...filterState,
        },
      }}
      tableHeight={650}
      manualFiltering
      enableRowSelection={false}
      enableTopToolbar
      enableTableFooter={false}
      enableRowActions={!isInvoiceApproved}
      state={{
        columnFilters,
        showProgressBars: isLoading,
      }}
      renderRowActions={({ row }) => [
        {
          icon: <LinkIcon color='white' />,
          tooltipProps: {
            content: 'Use this bill line',
            placement: 'top',
          },
          onClick: onMatchBillLine(row.original),
          isDisabled: isLoading,
        },
      ]}
      displayColumnDefOptions={{
        [ERTDisplayColumnId.actions]: {
          size: 60,
        },
      }}
      filterOptions={[
        {
          label: 'Date',
          field: 'lineDate',
          type: EFieldType.dateRange,
        },
        {
          label: 'Buyer Terminal',
          field: 'buyerTerminalId',
          type: EFieldType.multipleSelect,
          options: [
            {
              label: 'Not set',
              value: 'null',
            },
            ...buyerTerminalOptions,
          ],
        },
        {
          label: 'Product',
          field: 'sellerProductId',
          type: EFieldType.multipleSelect,
          options: sellerProductOptions,
        },
      ]}
      onColumnFiltersChange={setColumnFilters}
    />
  )
}

export default MatchingBillLinesTable
