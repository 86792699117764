import React, { useEffect, useState } from 'react'

import './styles.scss'
import {
  CheckMarkIcon,
  CloseIcon,
  CommonDialogV2,
  DeleteIcon,
  DialogDocumentForm,
  DocumentPlusIcon,
  DocumentsMiniTable,
  EditIcon,
  FileIcon,
  FolderIcon,
  FolderOpenIcon,
  FolderPlusIcon,
  PdfIcon,
  ReusableButton,
  ReusableCardSelection,
  ToolTipOverlay,
} from '~/components/shared'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import Tree from 'rc-tree'
import 'rc-tree/assets/index.css'
import { useConfirmationProvider } from '~/contexts'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { Alert, Form } from 'react-bootstrap'
import { IDocument } from '~/types/models/IDocument'
import _ from 'lodash'

export interface IReusableFolderViewProps {
  canDelete?: boolean
  canSelect?: boolean
  canCreateDocument?: boolean
  selectedKeys?: React.Key[]
  setSelectedKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>
  hiddenIds?: React.Key[]
}

const findParentKey = (
  nodes: any[],
  childKey: string,
  parentKey: string | null = null,
): string | null => {
  for (const node of nodes) {
    if (node.key === childKey) {
      return parentKey
    }
    if (node.children) {
      const result = findParentKey(node.children, childKey, node.key)
      if (result) {
        return result
      }
    }
  }
  return null
}

export const updateNodeChildren = (
  nodes: any[],
  key: string,
  children: any[],
): any[] => {
  return nodes.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children: _.uniqBy(
          node.children ? [...node.children, ...children] : children,
          'key', // Ensure uniqueness based on the `key` property
        ),
      }
    }
    if (node.children) {
      return {
        ...node,
        children: updateNodeChildren(node.children, key, children),
      }
    }
    return node
  })
}

function ReusableFolderView(props: IReusableFolderViewProps, ref: any) {
  const {
    canDelete = true,
    canSelect = false,
    canCreateDocument = true,
    selectedKeys: externalSelectedKeys,
    setSelectedKeys: setExternalSelectedKeys,
    hiddenIds = [],
  } = props

  const [treeData, setTreeData] = useState<any>([])
  const [expandedKeys, setExpandedKeys] = useState<string[]>(['0'])
  const [editingKey, setEditingKey] = useState<string | null>(null)
  const [tempTitle, setTempTitle] = useState<string>('')
  const [isDocEditing, setIsDocEditing] = useState(false)
  const [additionalDeleteModal, setAdditionalDeleteModal] = useState({
    isOpen: false,
    selectedId: undefined as number | undefined,
    error: '',
  })
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
  const [folderIdsSelected, setFolderIdsSelected] = useState<React.Key[]>([])
  const [isMovingFolders, setIsMovingFolders] = useState(false)
  const [loadedKeys, setLoadedKeys] = useState<React.Key[]>([])
  const [isDeletingDocuments, setIsDeletingDocuments] = useState(false)
  const [documentForm, setDocumentForm] = useState({
    isOpen: false,
    formData: undefined as Partial<IDocument> | undefined,
  })

  const { confirmation } = useConfirmationProvider()

  const handleEditClick = (
    key: string,
    currentTitle: string,
    isDocument: boolean,
  ) => {
    setEditingKey(key)
    setTempTitle(currentTitle)
    setIsDocEditing(isDocument)
  }

  const handleSaveTitle = async (key: string) => {
    try {
      const isNewFolder = (key || '').toString().startsWith('temp-')

      if (isNewFolder) {
        let parentKey = findParentKey(treeData, key)
        if (parentKey === '0') {
          parentKey = null
        }

        const response = await apiClient.folders.create({
          name: tempTitle,
          folderId: parentKey,
        })

        const newTreeData = [...treeData]
        const updateNodeData = (nodes: any[]) => {
          nodes.forEach(node => {
            if (node.key === key) {
              node.key = response.id
              node.metadata = { folder: response }
              node.title = response.name
            } else if (node.children) {
              updateNodeData(node.children)
            }
          })
        }
        updateNodeData(newTreeData)
        setTreeData(newTreeData)

        toast.success('Folder created successfully')
      } else {
        if (isDocEditing) {
          apiClient.documents.update(key as any, { name: tempTitle })
        } else {
          apiClient.folders.update(key as any, { name: tempTitle })
        }

        const newTreeData = [...treeData]
        const updateNodeTitle = (nodes: any[]) => {
          nodes.forEach(node => {
            if (node.key === key) {
              node.title = tempTitle
            } else if (node.children) {
              updateNodeTitle(node.children)
            }
          })
        }
        updateNodeTitle(newTreeData)
        setTreeData(newTreeData)

        toast.success('Folder updated successfully')
      }

      setEditingKey(null)
      setTempTitle('')
    } catch (error) {
      toast.error('Error saving folder')
      console.error('Error saving folder:', error)
    }
  }

  const handleAddFolder = (parentKey: string) => {
    const newFolderKey = `temp-${Date.now()}`
    const newFolder = {
      title: 'New Folder',
      key: newFolderKey,
      children: [],
      isLeaf: false,
      metadata: {
        folder: {
          name: 'New Folder',
        },
      },
    }

    const newTreeData = [...treeData]
    const addFolderToParent = (nodes: any[]) => {
      nodes.forEach(node => {
        if (node.key === parentKey) {
          node.children = [...(node.children || []), newFolder]
          node.children = sortNodes(node.children)
        } else if (node.children) {
          addFolderToParent(node.children)
        }
      })
    }
    addFolderToParent(newTreeData)
    setTreeData(newTreeData)
    setExpandedKeys(prev => [...prev, parentKey])

    setEditingKey(newFolderKey)
    setTempTitle('New Folder')
  }

  const handleCancelEdit = () => {
    const isNewFolder = (editingKey || '').toString()?.startsWith('temp-')

    if (isNewFolder) {
      const newTreeData = [...treeData]
      const removeNode = (nodes: any[]) => {
        return nodes.filter(node => {
          if (node.key === editingKey) {
            return false
          }
          if (node.children) {
            node.children = removeNode(node.children)
          }
          return true
        })
      }
      const updatedTreeData = removeNode(newTreeData)
      setTreeData(updatedTreeData)
    }

    setEditingKey(null)
    setTempTitle('')
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e?.stopPropagation()
    setTempTitle(e.target.value)
  }

  const handleDelete = async (key: string, isDocument: boolean) => {
    try {
      const result = await confirmation({
        message: 'Are you sure you want to delete this item?',
      })

      if (result === EYesNo.Yes) {
        if (isDocument) {
          apiClient.documents.delete(key as any)
        } else {
          const response = await apiClient.folders.delete(key as any)
          if (response.errors?.length) {
            setAdditionalDeleteModal({
              isOpen: true,
              selectedId: key as any,
              error: response.errors[0],
            })
            return
          }
        }
        const newTreeData = [...treeData]
        const removeNode = (nodes: any[]) => {
          return nodes.filter(node => {
            if (node.key === key) {
              return false
            }
            if (node.children) {
              node.children = removeNode(node.children)
            }
            return true
          })
        }
        const updatedTreeData = removeNode(newTreeData)
        setTreeData(updatedTreeData)

        toast.success('Item deleted successfully')
      }
    } catch (error) {
      toast.error('Error deleting item')
      console.error('Error deleting item:', error)
    }
  }

  const fetchFoldersData = async (folderId: number | 'null') => {
    try {
      const response = await apiClient.folders.get({
        filters: {
          folderId,
        },
      })
      return response.folders
    } catch (error) {
      toast.error('Error fetching folders data')
      console.error('Error fetching folders data:', error)
      return []
    }
  }

  // const fetchDocumentsData = async (folderId: React.Key | 'null') => {
  //   try {
  //     const response = await apiClient.documents.get({
  //       filters: {
  //         folderId: folderId === 'null' ? 'null' : Number(folderId),
  //       },
  //     })
  //     return response.documents
  //   } catch (error) {
  //     toast.error('Error fetching documents data')
  //     console.error('Error fetching documents data:', error)
  //     return []
  //   }
  // }

  const sortNodes = (nodes: any[]) => {
    return nodes.sort((a, b) => {
      if (a.isLeaf !== b.isLeaf) {
        return a.isLeaf ? 1 : -1
      }
      return a.title.localeCompare(b.title)
    })
  }

  const filterHiddenNodes = (nodes: any[]): any[] => {
    return nodes
      .filter(node => !hiddenIds.includes(node.key))
      .map(node => ({
        ...node,
        children: node.children ? filterHiddenNodes(node.children) : [],
      }))
  }

  const onLoadData = async (treeNode: any) => {
    const { eventKey } = treeNode.props
    setLoadedKeys(prev => [...prev, eventKey])

    try {
      const [foldersResponse] = await Promise.all([
        fetchFoldersData(eventKey),
        // fetchDocumentsData(eventKey),
      ])

      const folders = foldersResponse.map(folder => ({
        title: folder.name,
        key: folder.id,
        children: [],
        metadata: {
          folder,
        },
      }))

      // const documents = documentsResponse.map(document => ({
      //   title: document.name,
      //   key: document.id,
      //   isLeaf: true,
      //   metadata: {
      //     document,
      //   },
      // }))

      const sortedChildren = sortNodes([...folders])

      const newTreeData = updateNodeChildren(treeData, eventKey, sortedChildren)
      const filteredTreeData = filterHiddenNodes(newTreeData)
      setTreeData(filteredTreeData)
    } catch (error) {
      toast.error('Error loading data for the node')
      console.error('Error loading data:', error)
    }
  }

  const onCloseDocumentForm = () => {
    setDocumentForm({
      isOpen: false,
      formData: undefined,
    })
  }

  const handleExpand = (keys: any[]) => {
    setExpandedKeys(keys)
  }

  const handleTitleClick = (key: string) => {
    setExpandedKeys(prev =>
      prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key],
    )
  }

  const initializeTreeData = async (callback?: any) => {
    const [foldersResponse] = await Promise.all([
      fetchFoldersData('null'),
      // fetchDocumentsData('null'),
    ])

    const folders = foldersResponse.map(folder => ({
      title: folder.name,
      key: folder.id,
      children: [],
      metadata: {
        folder,
      },
    }))
    // const documents = documentsResponse.map(document => ({
    //   title: document.name,
    //   key: document.id,
    //   isLeaf: true,
    //   metadata: {
    //     document,
    //   },
    // }))

    const sortedChildren = sortNodes([...folders])

    const initialTreeData = [
      {
        title: 'Concord',
        key: '0',
        children: sortedChildren,
      },
    ]

    const filteredTreeData = filterHiddenNodes(initialTreeData)

    setTreeData(filteredTreeData)
    setLoadedKeys([])
    callback && callback()
  }

  const onCloseAddtionalDeleteModal = () => {
    setAdditionalDeleteModal({
      isOpen: false,
      selectedId: undefined,
      error: '',
    })
    setFolderIdsSelected([])
  }
  const onMoveFolders = async () => {
    setIsMovingFolders(true)
    try {
      await apiClient.folders.move({
        originalFolderId: additionalDeleteModal.selectedId as any,
        newFolderId: folderIdsSelected[0] as any,
      })
      await apiClient.folders.delete(additionalDeleteModal.selectedId as any)
      toast.success('Folders moved successfully')
      initializeTreeData()
      onCloseAddtionalDeleteModal()
    } catch (error) {
      console.log('error', error)
      toast.error('Error moving folders')
    } finally {
      setIsMovingFolders(false)
    }
  }

  const onDeleteAllDocuments = async () => {
    const result = await confirmation({
      message:
        'When you delete all documents, you will not be able to recover them.',
      header: 'Are you sure you want to delete all documents?',
    })
    if (result === EYesNo.Yes) {
      setIsDeletingDocuments(true)
      try {
        await apiClient.folders.deleteAll({
          originalFolderId: additionalDeleteModal.selectedId as any,
        })
        await apiClient.folders.delete(additionalDeleteModal.selectedId as any)
        initializeTreeData()
        onCloseAddtionalDeleteModal()
      } catch (error) {
        toast.error('Error deleting documents')
      } finally {
        setIsDeletingDocuments(false)
      }
    }
  }

  useEffect(() => {
    initializeTreeData(() => {
      setExpandedKeys(['0'])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ref) {
      ref.current = {
        treeData,
      }
    }
  }, [ref, treeData])

  return (
    <div className='ReusableFolderView__container'>
      <Tree
        checkable={false}
        selectable={canSelect}
        expandedKeys={expandedKeys}
        selectedKeys={externalSelectedKeys || selectedKeys}
        loadedKeys={loadedKeys}
        onExpand={handleExpand}
        loadData={onLoadData}
        onSelect={setExternalSelectedKeys || setSelectedKeys}
        treeData={treeData}
        switcherIcon={null}
        icon={treeProps => {
          if (treeProps.isLeaf) {
            if (treeProps.data?.metadata?.document?.fileType === 'pdf') {
              return <PdfIcon />
            }
            return <FileIcon />
          }
          if (treeProps.expanded) {
            return <FolderOpenIcon />
          }
          return <FolderIcon />
        }}
        titleRender={node => {
          const isEditing = editingKey === node.key
          const isDocument = Boolean(node?.metadata?.document)

          return (
            <div
              style={{
                cursor: 'pointer',
                verticalAlign: 'middle',
                display: 'inline-block',
              }}
            >
              {isEditing ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Control
                    type='text'
                    value={tempTitle}
                    onChange={handleTitleChange}
                    autoFocus
                    style={{
                      marginRight: 8,
                      padding: 4,
                      fontSize: '14px',
                      boxShadow: 'none',
                    }}
                  />
                  <span
                    onClick={event => {
                      event.stopPropagation()
                      handleSaveTitle(node.key as string)
                    }}
                  >
                    <CheckMarkIcon color='var(--bs-success)' />
                  </span>
                  <span
                    onClick={event => {
                      event.stopPropagation()
                      handleCancelEdit()
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    <CloseIcon size={16} color='var(--bs-danger)' />
                  </span>
                </div>
              ) : (
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className='treeNodeButtons'
                >
                  <span
                    onClick={event => {
                      event.stopPropagation()
                      handleTitleClick(node.key as string)
                    }}
                  >
                    {node.title}
                  </span>

                  {Number(node.key) === 0 ? null : (
                    <>
                      <ToolTipOverlay placement='top' content='Edit'>
                        <span
                          onClick={event => {
                            event.stopPropagation()
                            handleEditClick(
                              node.key as string,
                              node.title as string,
                              isDocument,
                            )
                          }}
                          style={{ marginLeft: 8 }}
                        >
                          <EditIcon size={14} color='orange' />
                        </span>
                      </ToolTipOverlay>

                      {canDelete && (
                        <ToolTipOverlay placement='top' content='Delete'>
                          <span
                            onClick={event => {
                              event.stopPropagation()
                              handleDelete(node.key as string, isDocument)
                            }}
                            style={{ marginLeft: 8 }}
                          >
                            <DeleteIcon size={16} color='var(--bs-danger)' />
                          </span>
                        </ToolTipOverlay>
                      )}
                    </>
                  )}
                  {!node.isLeaf && (
                    <>
                      <ToolTipOverlay placement='top' content='Add Folder'>
                        <span
                          onClick={event => {
                            event.stopPropagation()
                            handleAddFolder(node.key as string)
                          }}
                          style={{ marginLeft: 8 }}
                          className='clickable'
                        >
                          <FolderPlusIcon color='var(--bs-dark)' size={16} />
                        </span>
                      </ToolTipOverlay>

                      {canCreateDocument && (
                        <ToolTipOverlay placement='top' content='Add Document'>
                          <span
                            style={{ marginLeft: 8 }}
                            className='clickable'
                            onClick={() => {
                              setDocumentForm({
                                isOpen: true,
                                formData: {
                                  folderId: node.key,
                                },
                              })
                            }}
                          >
                            <DocumentPlusIcon color='var(--bs-gray-800)' />
                          </span>
                        </ToolTipOverlay>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )
        }}
      />

      <CommonDialogV2
        isOpen={additionalDeleteModal.isOpen}
        onClose={onCloseAddtionalDeleteModal}
        size='lg'
        backdrop='static'
        isHiddenHeader
      >
        {additionalDeleteModal.error && (
          <Alert style={{ fontSize: 13 }} variant='danger'>
            {additionalDeleteModal.error}
          </Alert>
        )}
        <ReusableCardSelection
          options={[
            {
              label: 'View Docs',
              value: 'view',
              render() {
                return (
                  <DocumentsMiniTable
                    folderId={additionalDeleteModal.selectedId}
                    canCreate={false}
                  />
                )
              },
            },
            {
              label: 'Move to a different folder',
              value: 'move',
              render() {
                return (
                  <div>
                    <ReusableFolderView
                      canDelete={false}
                      canSelect
                      selectedKeys={folderIdsSelected}
                      setSelectedKeys={setFolderIdsSelected}
                      hiddenIds={[additionalDeleteModal.selectedId as any]}
                    />
                    {folderIdsSelected.length > 0 && (
                      <ReusableButton
                        style={{ fontSize: 13, marginTop: 8 }}
                        isLoading={isMovingFolders}
                        onClick={onMoveFolders}
                      >
                        Move all its sub-folders and documents to this folder
                      </ReusableButton>
                    )}
                  </div>
                )
              },
            },
            {
              label: 'Delete All Documents',
              value: 'delete',
              render() {
                return (
                  <ReusableButton
                    style={{ fontSize: 13, marginTop: 8 }}
                    isLoading={isDeletingDocuments}
                    onClick={onDeleteAllDocuments}
                  >
                    Submit
                  </ReusableButton>
                )
              },
            },
          ]}
        />
      </CommonDialogV2>

      <DialogDocumentForm
        isOpen={documentForm.isOpen}
        formData={{
          folderId:
            Number(documentForm.formData?.folderId) == 0
              ? null
              : (documentForm.formData?.folderId as any),
        }}
        onClose={onCloseDocumentForm}
        afterCreate={async newDocuments => {
          const documents = newDocuments.map(document => ({
            title: document.name,
            key: document.id,
            isLeaf: true,
            metadata: {
              document,
            },
          }))

          const newTreeData = updateNodeChildren(
            treeData,
            documentForm.formData?.folderId as any,
            [...documents],
          )
          setTreeData(newTreeData)
          setExpandedKeys(prev => [
            ...prev,
            documentForm.formData?.folderId as any,
          ])
          onCloseDocumentForm()
        }}
      />
    </div>
  )
}

export default React.forwardRef(ReusableFolderView)
