import React, { useRef } from 'react'
import { ReusableTimeField } from '~/components/shared'

import './styles.scss'

type IReusableTimeValue = Date | string | null | undefined
export type IReusableTimeRangeValue = {
  startTime: IReusableTimeValue
  endTime: IReusableTimeValue
}

export interface IReusableTimeRangeProps {
  value: IReusableTimeRangeValue
  onChange: (value: IReusableTimeRangeValue, field: string) => void
  style?: React.CSSProperties
}

function ReusableTimeRange(props: IReusableTimeRangeProps) {
  const { value, onChange, style } = props

  const inputRef = useRef<any>()
  const secondInputRef = useRef<any>()

  return (
    <div className='ReusableTimeRange__container' style={style}>
      <ReusableTimeField
        showIcon={false}
        placeholder='--:--'
        ref={inputRef}
        value={value?.startTime}
        maxTime={value?.endTime as any}
        onChange={time => {
          onChange(
            {
              ...value,
              startTime: time,
            },
            'startTime',
          )
          inputRef.current?.setBlur()
          secondInputRef.current?.setFocus()
        }}
      />
      <span>→</span>
      <ReusableTimeField
        showIcon={false}
        placeholder='--:--'
        ref={secondInputRef}
        value={value?.endTime}
        minTime={value?.startTime as any}
        onChange={time => {
          onChange(
            {
              ...value,
              endTime: time,
            },
            'endTime',
          )
        }}
      />
    </div>
  )
}

export default ReusableTimeRange
