import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePersistForm } from '~/hooks/usePersistForm'
import { useSelector } from 'react-redux'
import {
  FormProvider,
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form'

import { Alert, Button } from 'react-bootstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import { produce } from 'immer'

import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectOrderTrackingFiltersLite,
  selectUserAccess,
} from '~/redux/selectors'

import { defaultStatuses as statusOptions } from '~/components/fleet/constants'
import Loading from '../Loading'
import { Unless } from 'react-if'

import { ScheduleLoadsFields } from '../ScheduleLoads/ScheduleLoadsFields'
import { ProductSelect } from '../ProductSelect/ProductSelect'
import { apiClient } from '~/api/ApiClient'
import { FloatingInput } from '../FloatingForm/Input'
import { EditTerminalSection } from './EditTerminalSection'
import { FloatingSelectV2 } from '../FloatingForm/FloatingSelectV2'
import { ToolTipOverlay } from '../ToolTipOverlay'
import { EOrderType } from '~/types/enums/ESellerProduct'
import { CustomScheduleStatusOption, DialogTerminalForm } from '../ConcordForm'
import DialogCompanyForm from '../ConcordForm/FormData/CompanyForm/DialogCompanyForm'
import { EditAvatarOption } from '../ReactSelect/components/EditAvatarOption'
import { EditOption } from '../ReactSelect/components/EditOption'
import { CreateButton } from '../ReactSelect/components/CreateButton'
import { ExtrasForm } from './Extras/ExtrasForm'
import { BucketIcon, EditIcon } from '../SvgIcons'
import {
  useQueryBuyerSellers,
  useQueryCompanies,
  useQuerySchedules,
  useQueryTerminals,
} from '~/hooks/useQueryData'

import clsx from 'clsx'
import './OrderForm.scss'
import _ from 'lodash'
import isDateInRange from '~/utils/isDateInRange'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'
import buildObjectName from '~/utils/buildObjectName'
import { COLOR_OPTIONS } from '../ConcordForm/ColorSelector/colorOptions'
import CustomColorOption from '../ConcordForm/ColorSelector/CustomColorOption'
import CustomColorSingleValue from '../ConcordForm/ColorSelector/CustomColorSingleValue'
import CustomColorMenu from '../ConcordForm/ColorSelector/CustomColorMenu'
import CustomColorMenuList from '../ConcordForm/ColorSelector/CustomColorMenuList'
import { NOT_SET_OPTION } from '~/utils/constants'
import { EYesNo } from '~/types/enums/ECommonEnum'
import moment from 'moment'

const schema = yup.object({
  buyerId: yup.number().required('Buyer required!'),
  sellerId: yup.number().required('Seller required!'),
  sellerTerminalId: yup.number().optional().nullable(),
  buyerTerminalId: yup.number().required('Buyer terminal required!'),
  fleetId: yup.number().optional().nullable(),
  sellerProductId: yup.number().required('Product required!'),
  status: yup.string().required('Status required!'),
  color: yup.string().nullable(),
  poNum: yup.string().nullable(),
  locationId: yup.number(),
  notesAttributes: yup
    .array()
    .of(yup.object().shape({ note: yup.string().nullable() })),
  schedulesAttributes: yup.array().of(
    yup.object().shape(
      {
        qty: yup.number().when('lds', {
          is: lds => !lds || lds?.length === 0,
          then: schema => schema.required('QTY required!'),
          otherwise: schema => schema,
        }),
        lds: yup.number().when('qty', {
          is: qty => _.isNil(qty),
          then: schema => schema.required('LDs required!'),
          otherwise: schema => schema,
        }),
        loadSize: yup
          .number()
          .required('LD size required!')
          .positive('Load size must be greater than 0.'),
        date: yup.date().required('Date required!'),
        startTime: yup.string().required('Start time required!'),
        spacing: yup.number().required('Spacing required!'),
        sellerTerminalId: yup
          .number()
          .test(
            'seller-terminal-id-required',
            'Seller terminal required!',
            (value, context) => context.from[1].value.sellerTerminalId || value,
          ),
        plus: yup.boolean(),
        fleetId: yup
          .number()
          .test(
            'fleet-required',
            'Fleet required!',
            (value, context) => context.from[1].value.fleetId || value,
          ),
        notesAttributes: yup
          .array()
          .of(yup.object().shape({ note: yup.string().nullable() })),
      },
      [['qty', 'lds']],
    ),
  ),
})

const FORM_DATA_KEY = 'orderForm'

const COMPANY = {
  buyer: 'buyer',
  seller: 'seller',
  fleet: 'fleet',
}

const selectGreenStyle = {
  control: base => ({
    ...base,
    borderColor: '#2dd55b',
  }),
}

const OrderForm = (props, ref) => {
  const {
    closeForm,
    className,
    isHiddenScheduleForm,
    isHiddenButtons,
    isHiddenOrderForm,
    isReadOnly,
    defaultValues,
    onChange,
    isShowScheduleLoadsByDefault,
    isHiddenAddScheduleButton,
    isHiddenScheduleStatus = true,
    setFormSummary,
    hiddenButtons = [],
    hiddenFields = [],
    afterCreate,
  } = props

  const [loading, setLoading] = useState(false)
  const [showOrderExtras, setShowOrderExtras] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [companyForm, setCompanyForm] = useState({ isOpen: false })
  const [terminalForm, setTerminalForm] = useState({ isOpen: false })
  const [isFetchingScheduleLoads, setIsFetchingScheduleLoads] = useState(false)
  const [backendError, setBackendError] = useState('')

  const currentScope = useSelector(selectCurrentScope)
  const currentCompany = useSelector(selectMyCurrentCompany)
  const { id: userAccessId } = useSelector(selectUserAccess)

  const { buyerSellersData, addBuyerSeller } = useQueryBuyerSellers()

  const filtersLite = useSelector(selectOrderTrackingFiltersLite)

  const {
    buyerCompanyOptions,
    sellerCompanyOptions,
    fleetCompanyOptions,
    companiesObj,
    addCompany,
  } = useQueryCompanies()

  const { findTerminalById, terminalsData, updateTerminal, addTerminal } =
    useQueryTerminals()

  const { addSchedule } = useQuerySchedules({
    filters: _.omit(filtersLite, 'view'),
    hasLoads: true,
    hasNotes: true,
  })

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: {
      status: 'Will Call',
      [`${currentScope}Id`]: currentCompany?.id,
      notesAttributes: [
        {
          note: '',
          notableType: 'Order',
          companyId: currentCompany?.id,
          userAccessId,
        },
      ],
      schedulesAttributes: [
        {
          date: new DateTime.now()
            .plus({ days: 1 })
            .set({
              hour: 12,
              minute: 0,
              second: 0,
            })
            .toISODate(),
          startTime: new DateTime.now()
            .plus({ days: 1 })
            .set({
              hour: 12,
              minute: 0,
              second: 0,
            })
            .toLocaleString(DateTime.TIME_24_SIMPLE),
          plus: false,
          notesAttributes: [
            {
              note: '',
              notableType: 'Schedule',
              companyId: currentCompany?.id,
              userAccessId,
            },
          ],
        },
      ],
    },
    resolver: yupResolver(schema),
  })

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    setFocus,
    watch,
    reset,
  } = formMethods

  usePersistForm({ value: getValues(), localStorageKey: FORM_DATA_KEY })

  const buyerId = useWatch({ control, name: 'buyerId' })
  const sellerId = useWatch({ control, name: 'sellerId' })
  const fleetId = useWatch({ control, name: 'fleetId' })
  const buyerTerminalId = useWatch({ control, name: 'buyerTerminalId' })
  const sellerTerminalId = useWatch({ control, name: 'sellerTerminalId' })
  const id = useWatch({ control, name: 'id' })
  const sellerProductId = useWatch({
    control,
    name: 'sellerProductId',
  })

  const extras = useWatch({
    control,
    name: 'orderExtrasAttributes',
  })
  const scheduleAttributes = useWatch({
    control,
    name: 'schedulesAttributes',
  })

  const {
    fields: scheduleFields,
    append: appendScheduleField,
    remove: removeScheduleField,
  } = useFieldArray({
    control,
    name: 'schedulesAttributes',
  })

  const {
    field: { onChange: onChangeNotes },
  } = useController({
    name: 'notesAttributes.0.note',
    control,
    defaultValue: '',
  })

  const schedulesAttributes = useWatch({ control, name: 'schedulesAttributes' })

  function findMinISODateTime(items) {
    if (!items.length) return null

    return items.reduce((min, curr) => {
      const minMoment = moment(
        `${min.date} ${min.startTime}`,
        'YYYY-MM-DD HH:mm',
      )
      const currMoment = moment(
        `${curr.date} ${curr.startTime}`,
        'YYYY-MM-DD HH:mm',
      )
      return currMoment.isBefore(minMoment) ? curr : min
    })
  }

  const minStartTime = useMemo(() => {
    try {
      const { date, startTime } = findMinISODateTime(scheduleAttributes)
      const [hours, minutes] = startTime.split(':')
      return moment(date)
        .set({
          hours,
          minutes,
        })
        .toISOString()
    } catch (error) {
      return ''
    }
  }, [scheduleAttributes])

  const sumQtyOfSchedules = scheduleAttributes.reduce(
    (total, { qty }) => (total += Number(qty || 0)),
    0,
  )

  const appendSchedule = useCallback(() => {
    const lastSchedule = schedulesAttributes[schedulesAttributes.length - 1]
    appendScheduleField(lastSchedule)
  }, [appendScheduleField, schedulesAttributes])

  const deleteSchedule = useCallback(
    index => removeScheduleField(index),
    [removeScheduleField],
  )

  const totalSchedules = useMemo(
    () => schedulesAttributes?.length || 0,
    [schedulesAttributes],
  )

  const totalQty = useMemo(() => {
    return schedulesAttributes.reduce(
      (acc, schedule) => acc + parseFloat(schedule?.qty || 0),
      0,
    )
  }, [schedulesAttributes])

  const totalLds = useMemo(() => {
    return schedulesAttributes.reduce((acc, schedule) => {
      return acc + parseInt(schedule.scheduleLoadsAttributes?.length || 0)
    }, 0)
  }, [schedulesAttributes])

  const totalPlusLds = useMemo(() => {
    return schedulesAttributes.reduce((acc, schedule) => {
      return acc + (schedule.plus ? 1 : 0)
    }, 0)
  }, [schedulesAttributes])

  const isUpdating = useMemo(() => Boolean(id), [id])

  const buyerTerminal = useMemo(
    () => findTerminalById(buyerTerminalId),
    [buyerTerminalId, findTerminalById],
  )

  const buyerTerminalOptions = useMemo(() => {
    if (buyerId) {
      return terminalsData
        .filter(
          ({ companyId, terminalType }) =>
            companyId === buyerId && ['buyer', 'both'].includes(terminalType),
        )
        .map(({ id, code, name }) => ({
          value: id,
          label: buildObjectName({ code, name }),
        }))
    }
    return []
  }, [buyerId, terminalsData])

  const sellerTerminalOptions = useMemo(() => {
    if (sellerId) {
      return terminalsData
        .filter(
          ({ companyId, terminalType }) =>
            companyId === sellerId && ['seller', 'both'].includes(terminalType),
        )
        .map(({ id, code, name }) => ({
          value: id,
          label: buildObjectName({ code, name }),
        }))
    }
    return []
  }, [sellerId, terminalsData])

  const submitOrder = async data => {
    setLoading(true)
    setBackendError('')
    try {
      const parsedData = produce(data, draft => {
        if (draft.notesAttributes[0].note === '') {
          draft.notesAttributes = [] //don't create an empty note
        }
        draft.schedulesAttributes.forEach((_schedule, index) => {
          draft.schedulesAttributes[index].status = draft.status //pull status down to each schedule
          draft.schedulesAttributes[index].scheduleNumber = index + 1
          draft.schedulesAttributes[index].color = draft.color

          const [hours, minutes] = //date + time = startTime
            draft.schedulesAttributes[index].startTime.split(':')
          const joinedDateTime = new DateTime.fromJSDate(
            draft.schedulesAttributes[index].date,
          )
            .set({
              hour: hours,
              minute: minutes,
            })
            .toISO()

          draft.schedulesAttributes[index].startTime = joinedDateTime

          if (!draft.schedulesAttributes[index].sellerTerminalId) {
            draft.schedulesAttributes[index].sellerTerminalId =
              draft.sellerTerminalId
          }
          if (!draft.schedulesAttributes[index].fleetId) {
            draft.schedulesAttributes[index].fleetId = draft.fleetId
          }
          if (draft.schedulesAttributes[index].notesAttributes[0].note === '') {
            draft.schedulesAttributes[index].notesAttributes = [] //don't create an empty note
          }
        })
      })

      const { errors, order } = await apiClient.orders.create({
        order: parsedData,
      })
      if (order.id) {
        afterCreate && afterCreate(order)
        const { schedules } = await apiClient.schedules.get({
          filters: {
            orderId: order.id,
          },
        })

        schedules
          .filter(({ startTime }) => {
            if (
              filtersLite.startTime.startDate &&
              filtersLite.startTime.endDate
            ) {
              const isWithinDate = isDateInRange(
                startTime,
                filtersLite.startTime.startDate,
                filtersLite.startTime.endDate,
              )
              return isWithinDate
            }
            return true
          })
          .forEach(schedule => {
            addSchedule({
              ...schedule,
              loads: [],
              notes: [],
            })
          })
        toast.success('Order created!')
        localStorage.removeItem(FORM_DATA_KEY)
        closeForm && closeForm()
      } else {
        setBackendError(errors[0])
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const productOrderTypes = useMemo(
    () => [EOrderType.primary, EOrderType.both],
    [],
  )

  const onClickCreateBuyerTerminal = useCallback(
    (name = null) =>
      setTerminalForm({
        isOpen: true,
        formData: { companyId: buyerId, name },
        isBuyer: true,
        shouldHideOptionalFields: true,
      }),
    [buyerId],
  )

  const onClickCreateCompany = useCallback(
    companyType =>
      (name = null) => {
        setCompanyForm({
          isOpen: true,
          formData: {
            [companyType]: EYesNo.Yes,
            name,
          },
          shouldHideOptionalFields: true,
        })
      },
    [],
  )

  const onClickEditCompany = useCallback(
    companyId => {
      setCompanyForm({ isOpen: true, formData: companiesObj[companyId] })
    },
    [companiesObj],
  )

  const onClickEditTerminal = useCallback(
    terminalId => {
      setTerminalForm({ isOpen: true, formData: findTerminalById(terminalId) })
    },
    [findTerminalById],
  )

  const setLocationId = ({ id }) => setValue('locationId', id)

  const onChangeFields = useCallback(
    ({ field, value }) => {
      const formValues = getValues()
      setValue(field, value)
      onChange && onChange(formValues, { field, value })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValues, onChange],
  )

  const onCreateCompany = useCallback(
    company => {
      if (companyForm.formData.buyer) setValue('buyerId', company.id)
      if (companyForm.formData.seller) setValue('sellerId', company.id)
      if (companyForm.formData.fleet) setValue('fleetId', company.id)
      addCompany(company)
      setCompanyForm({ isOpen: false })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, companyForm.formData],
  )

  const onCreateTerminal = useCallback(
    terminal => {
      if (terminal.companyId === sellerId) {
        setValue('sellerTerminalId', terminal.id)
      } else if (terminal.companyId === buyerId) {
        setValue('buyerTerminalId', terminal.id)
      }
      addTerminal(terminal)
      setTerminalForm({ isOpen: false })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buyerId, sellerId],
  )

  const isOverridden = useCallback(
    field => schedulesAttributes.some(s => s[field]),
    [schedulesAttributes],
  )

  const isBSPOverridden = useMemo(
    () => isOverridden('sellerProductId'),
    [isOverridden],
  )
  const isColorOverridden = useMemo(() => isOverridden('color'), [isOverridden])
  const isStatusOverridden = useMemo(
    () => isOverridden('status'),
    [isOverridden],
  )
  const isSellerTerminalOverridden = useMemo(
    () => isOverridden('sellerTerminalId'),
    [isOverridden],
  )

  const onClickCreateSellerTerminal = useCallback(
    (name = null) =>
      setTerminalForm({
        isOpen: true,
        formData: {
          companyId: sellerId,
          name,
        },
        shouldHideOptionalFields: true,
      }),
    [sellerId],
  )

  const onClickEditSellerTerminal = useCallback(
    terminalId =>
      setTerminalForm({
        isOpen: true,
        formData: findTerminalById(terminalId),
      }),
    [findTerminalById],
  )

  const createBuyerSeller = async ({ buyerId, sellerId }) => {
    const newBuyerSeller = await apiClient.buyerSellers.create({
      buyerId,
      sellerId,
    })
    addBuyerSeller(newBuyerSeller)
  }

  //on buyer change, reset terminal + product, focus terminal
  useEffect(() => {
    if (!buyerId) {
      setTimeout(() => {
        setFocus('buyerId')
      }, 300)
    } else if (buyerId && !isUpdating) {
      setValue('buyerTerminalId', '')
      setValue('sellerProductId', '')
      setFocus('buyerTerminalId')
    }
  }, [buyerId, isUpdating, setValue, setFocus])

  useEffect(() => {
    if (sellerId && !isUpdating && buyerId && buyerTerminalId) {
      setValue('sellerTerminalId', '')
      setValue('sellerProductId', '')
      setFocus('sellerTerminalId')
    }
  }, [sellerId, isUpdating, setValue, setFocus, buyerId, buyerTerminalId])

  useEffect(() => {
    if (!isUpdating && sellerTerminalId) {
      setFocus('sellerProductId')
    }
  }, [isUpdating, sellerTerminalId, setFocus])

  useEffect(() => {
    if (!isUpdating && sellerProductId) {
      setFocus('fleetId')
    }
  }, [isUpdating, sellerProductId, setFocus])

  useEffect(() => {
    if (!isUpdating && fleetId) {
      setFocus('status')
    }
  }, [isUpdating, setFocus, fleetId])

  useEffect(() => {
    let data
    if (defaultValues) {
      data = { ...defaultValues }
    } else {
      try {
        const jsonData = JSON.parse(localStorage.getItem(FORM_DATA_KEY))
        data = { ...jsonData }
      } catch (err) {
        console.log(err)
      }
    }

    const keys = Object.keys(data || {})

    if (keys.length) {
      keys.forEach(key => {
        setValue(key, data[key])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  useEffect(() => {
    setFormSummary &&
      setFormSummary({
        schedules: totalSchedules,
        qty: totalQty,
        lds: totalLds - totalPlusLds,
        plus: totalPlusLds,
        onClickCreate: appendSchedule,
      })
  }, [
    setFormSummary,
    totalQty,
    totalLds,
    totalSchedules,
    totalPlusLds,
    appendSchedule,
  ])

  useEffect(() => {
    if (buyerId && sellerId) {
      const buyerSeller = Object.values(buyerSellersData).find(
        bs => bs.buyerId === buyerId && bs.sellerId === sellerId,
      )
      if (!buyerSeller) {
        createBuyerSeller({ buyerId, sellerId })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId, sellerId, buyerSellersData])

  useEffect(() => {
    const params = {
      getValues,
      setValue,
      watch,
      reset,
      handleSubmit,
    }
    if (typeof ref === 'function') {
      ref(params)
    } else if (ref !== null) {
      ref.current = params
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handleSubmit])

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault()
  }

  return (
    <div className={className}>
      {loading && <Loading />}

      <FormProvider {...formMethods}>
        <div className='formContainer'>
          {backendError && (
            <Alert style={{ fontSize: 13 }} variant='danger'>
              {backendError}
            </Alert>
          )}
          <form
            onSubmit={handleSubmit(submitOrder)}
            onKeyDown={e => checkKeyDown(e)}
            className='fs-5'
          >
            <Unless condition={isHiddenOrderForm}>
              <div className='d-flex flex-wrap gap-1 mb-2 align-items-center'>
                <Unless condition={hiddenFields.includes('buyerId')}>
                  <FloatingSelectV2 //buyer code
                    control={control}
                    name='buyerId'
                    label='Buyer'
                    options={buyerCompanyOptions}
                    components={{
                      MenuList: CreateButton(
                        onClickCreateCompany(COMPANY.buyer),
                      ),
                      Option: EditAvatarOption(onClickEditCompany),
                    }}
                    isDisabled={isUpdating}
                    onChange={value => {
                      onChangeFields({ field: 'buyerId', value })
                    }}
                  />
                </Unless>

                <Unless condition={hiddenFields.includes('buyerTerminalId')}>
                  <FloatingSelectV2
                    control={control}
                    name='buyerTerminalId'
                    label='Buyer Terminal'
                    options={buyerTerminalOptions}
                    components={{
                      MenuList: CreateButton(onClickCreateBuyerTerminal),
                      Option: EditOption(onClickEditTerminal),
                    }}
                    isDisabled={isUpdating || !buyerId}
                    styles={{
                      menu: base => ({
                        ...base,
                        minWidth: 200,
                      }),
                    }}
                    onChange={value => {
                      onChangeFields({ field: 'buyerTerminalId', value })
                    }}
                  />
                </Unless>

                {buyerTerminal && (
                  <ToolTipOverlay content='Edit buyer terminal' placement='top'>
                    <div
                      className='d-flex align-items-center mx-2'
                      onClick={() => setShowMap(!showMap)}
                      tabIndex={-1}
                    >
                      <EditIcon className={clsx({ isDisabled: isUpdating })} />
                    </div>
                  </ToolTipOverlay>
                )}

                <Unless condition={hiddenFields.includes('sellerId')}>
                  <FloatingSelectV2
                    control={control}
                    name='sellerId'
                    label='Seller'
                    options={sellerCompanyOptions}
                    components={{
                      MenuList: CreateButton(
                        onClickCreateCompany(COMPANY.seller),
                      ),
                      Option: EditAvatarOption(onClickEditCompany),
                    }}
                    className={'ms-auto'}
                    isDisabled={isUpdating}
                    onChange={value => {
                      onChangeFields({ field: 'sellerId', value })
                    }}
                  />
                </Unless>

                <Unless condition={hiddenFields.includes('poNum')}>
                  <FloatingInput
                    errors={errors}
                    control={control}
                    inputType='number'
                    label='PO #'
                    name='poNum'
                    onChange={value => {
                      onChangeFields({ field: 'poNum', value })
                    }}
                  />
                </Unless>

                {!hiddenFields.includes('notes') && (
                  <NoteButton
                    onSave={onChangeNotes}
                    canEdit
                    saveButtonText='Save Order Note'
                    renderNoteTooltip={({ note }) =>
                      `Order Note - Order notes apply to all schedules\n ${note}`
                    }
                    style={{
                      backgroundColor: '#343a40',
                      borderColor: '#343a40',
                    }}
                  />
                )}
              </div>
              {showMap && (
                <EditTerminalSection
                  terminal={buyerTerminal}
                  onComplete={() => setShowMap(false)}
                  onCreateLocation={setLocationId}
                  onClickClose={() => setShowMap(false)}
                />
              )}

              <div className='d-flex flex-wrap gap-1 mb-2 align-items-center'>
                {!hiddenFields.includes('sellerTerminalId') && (
                  <FloatingSelectV2
                    control={control}
                    name='sellerTerminalId'
                    label='Seller Terminal'
                    options={sellerTerminalOptions}
                    onChange={value => {
                      onChangeFields({ field: 'sellerTerminalId', value })
                    }}
                    components={{
                      MenuList: CreateButton(onClickCreateSellerTerminal),
                      Option: EditOption(onClickEditSellerTerminal),
                    }}
                    isDisabled={!sellerId}
                    styles={{
                      ...(isSellerTerminalOverridden ? selectGreenStyle : {}),
                      menu: base => ({
                        ...base,
                        minWidth: 200,
                      }),
                    }}
                  />
                )}

                <ProductSelect
                  controlName='sellerProductId'
                  placeholder='Select Product'
                  buyerId={buyerId}
                  sellerId={sellerId}
                  buyerTerminalId={buyerTerminalId}
                  sellerTerminalId={sellerTerminalId}
                  control={control}
                  orderType={productOrderTypes}
                  disabled={!(buyerId && sellerId)}
                  customStyles={{
                    ...(isBSPOverridden ? selectGreenStyle : {}),
                    menu: base => ({
                      ...base,
                      minWidth: 200,
                    }),
                  }}
                />
                {/* <Unless condition={isUpdating}>
                  <FloatingSelectV2
                    control={control}
                    orderType={productOrderTypes}
                    disabled={!(buyerId && sellerId)}
                    customStyles={{
                      ...(isBSPOverridden ? selectGreenStyle : {}),
                      menu: base => ({
                        ...base,
                        minWidth: 200,
                      }),
                    }}
                  />
                </Unless> */}

                {!hiddenFields.includes('fleetId') && (
                  <FloatingSelectV2
                    control={control}
                    name='fleetId'
                    label='Fleet'
                    options={fleetCompanyOptions}
                    components={{
                      Option: EditAvatarOption(onClickEditCompany),
                      MenuList: CreateButton(
                        onClickCreateCompany(COMPANY.fleet),
                      ),
                    }}
                    styles={{
                      ...(isSellerTerminalOverridden ? selectGreenStyle : {}),
                      menu: base => ({
                        ...base,
                        minWidth: 200,
                      }),
                    }}
                    onChange={value => {
                      onChangeFields({ field: 'fleetId', value })
                    }}
                  />
                )}

                {!hiddenFields.includes('status') && (
                  <Unless condition={isUpdating}>
                    <FloatingSelectV2
                      control={control}
                      name='status'
                      label='Status'
                      placeholder='Select Status'
                      options={statusOptions}
                      onChange={value => {
                        onChangeFields({ field: 'status', value })
                      }}
                      styles={{
                        ...(isStatusOverridden ? selectGreenStyle : {}),
                        option: provided => ({
                          ...provided,
                          backgroundColor: 'red',
                          padding: 0,
                        }),
                        menuList: provided => ({
                          ...provided,
                          paddingTop: 0,
                          paddingBottom: 0,
                          maxHeight: 200,
                        }),
                      }}
                      components={{ Option: CustomScheduleStatusOption }}
                    />
                  </Unless>
                )}

                {!hiddenFields.includes('color') && (
                  <FloatingSelectV2
                    control={control}
                    name='color'
                    label='Color'
                    placeholder='Color'
                    options={[NOT_SET_OPTION, ...COLOR_OPTIONS]}
                    styles={{
                      ...(isColorOverridden ? selectGreenStyle : {}),
                    }}
                    components={{
                      Option: CustomColorOption,
                      SingleValue: CustomColorSingleValue,
                      Menu: CustomColorMenu,
                      MenuList: CustomColorMenuList,
                    }}
                    tabindex='-1'
                    onChange={value => {
                      onChangeFields({ field: 'color', value })
                    }}
                  />
                )}
              </div>
            </Unless>

            <Unless condition={isHiddenScheduleForm}>
              <ScheduleLoadsFields
                fields={scheduleFields}
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                selectFleetComponents={{
                  MenuList: CreateButton(onClickCreateCompany(COMPANY.fleet)),
                }}
                isReadOnly={isReadOnly}
                onChange={onChangeFields}
                isShowScheduleLoadsByDefault={isShowScheduleLoadsByDefault}
                isHiddenAddScheduleButton={isHiddenAddScheduleButton}
                isHiddenScheduleStatus={isHiddenScheduleStatus}
                appendSchedule={appendSchedule}
                deleteSchedule={deleteSchedule}
                hiddenButtons={hiddenButtons}
                setIsFetchingScheduleLoads={setIsFetchingScheduleLoads}
                sellerTerminalOptions={sellerTerminalOptions}
              />
            </Unless>

            <ExtrasForm
              autoExtrasParams={{
                objectType: 'order',
                order: {
                  buyerTerminalId,
                  sellerId,
                  buyerId,
                  schedulesAttributes: [
                    {
                      startTime: minStartTime,
                      sellerTerminalId,
                      qty: sumQtyOfSchedules,
                    },
                  ],
                },
              }}
              name='Order'
              show={showOrderExtras}
              className='border border-light rounded'
            />

            <Unless condition={isHiddenButtons}>
              <div className='d-grid gap-2 mt-2'>
                <Button
                  variant='success'
                  size='lg'
                  className='d-flex align-items-center justify-content-center'
                  onClick={() => setShowOrderExtras(!showOrderExtras)}
                >
                  <BucketIcon color='#fff' className='me-1' /> Order Extras:{' '}
                  {extras?.length}
                </Button>

                <Button
                  size='lg'
                  type='submit'
                  variant={!isValid ? 'secondary' : 'primary'}
                  disabled={isFetchingScheduleLoads || loading}
                >
                  Submit
                </Button>
              </div>
            </Unless>
          </form>

          <DialogCompanyForm
            isOpen={companyForm.isOpen}
            formData={companyForm?.formData}
            onClose={() => setCompanyForm({ isOpen: false })}
            afterCreate={onCreateCompany}
          />

          <DialogTerminalForm
            isOpen={terminalForm.isOpen}
            formData={terminalForm?.formData}
            shouldHideOptionalFields={terminalForm.shouldHideOptionalFields}
            onClose={() => setTerminalForm({ isOpen: false })}
            afterCreate={onCreateTerminal}
            afterUpdate={terminal => {
              updateTerminal(terminal.id, terminal)
              setTerminalForm({ isOpen: false })
            }}
          />
        </div>
      </FormProvider>
    </div>
  )
}
export default React.forwardRef(OrderForm)
