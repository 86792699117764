import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { INVOICE_PANELS } from '~/constants/invoice-type'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { updateInvoiceBillLine } from '~/redux/actions/invoiceActions'
import { invoiceUiSlice as invoiceSliceUI } from '~/redux/reducers/ui/invoice'
import { selectInvoiceUiState } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

const useInvoiceBodyPanels = (invoice, isSplitView, splitCompareRef) => {
  const dispatch = useDispatch()
  const { currentPanels } = useSelector(selectInvoiceUiState)
  const [isLoading, setIsLoading] = useState(false)

  const showApplyToOtherTables =
    isSplitView ||
    (currentPanels.includes(INVOICE_PANELS.INVOICE) &&
      currentPanels.includes(INVOICE_PANELS.COMPARISON)) ||
    (currentPanels.includes(INVOICE_PANELS.TICKETS) &&
      currentPanels.includes(INVOICE_PANELS.COMPARISON))

  const onShowCompareBillLineDetail = useCallback(
    billLine => {
      splitCompareRef && splitCompareRef.current.onShowBillLine(billLine)
    },
    [splitCompareRef],
  )

  const onDeleteBillLine = useCallback(
    async billLine => {
      if (!invoice) return
      const result = await apiClient.billLines.delete(billLine.id)
      if (result) {
        toast.success(toastMessages.deleteSuccess)
      } else {
        toast.error('There was an issue tring to delete.')
      }
    },
    [invoice],
  )

  const useBillLine = useCallback(
    (billLine, isCompareLine, matchedFields) => {
      const matchedFieldsNeedUpdate = isCompareLine
        ? matchedFields
        : billLine.matchedFields
      const payload = {}
      for (const line of matchedFieldsNeedUpdate) {
        if (!line.matched) payload[line.field] = billLine[line.field] || '0'
        else continue
      }
      if (_.isEmpty(payload)) return
      if (!billLine.billLineId) return
      dispatch(
        updateInvoiceBillLine(
          billLine.billLineId,
          payload,
          invoice,
          !isCompareLine,
        ),
      )
    },
    [dispatch, invoice],
  )

  const onUnMatchedClick = useCallback(
    async value => {
      setIsLoading(true)
      const response = await apiClient.billLines.getSuggestMatch(value.id)
      const fuzzyMatches = response?.fuzzyMatches?.map(v => {
        v[0].matchingScore = v[1]
        v[0].proximityScore = v[2]

        return v[0]
      })

      dispatch(
        invoiceSliceUI.actions.updateUi({
          currentInvoice: [value],
          matchItems: fuzzyMatches,
          showSuggestModal: true,
        }),
      )
      setIsLoading(false)
    },
    [dispatch],
  )

  return {
    currentPanels,
    isLoading,
    showApplyToOtherTables,
    onDeleteBillLine,
    onShowCompareBillLineDetail,
    onUnMatchedClick,
    useBillLine,
  }
}

export default useInvoiceBodyPanels
