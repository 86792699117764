import { DraggableItem } from './DraggableItem'
import { useMemo } from 'react'
import { produce } from 'immer'

export interface IDraggableItemProps {
  label: string
  name: string
  checked: boolean
}

export interface IDraggableItemsProps {
  items: IDraggableItemProps[]
  disabledFields?: string[]
  setItems: React.Dispatch<React.SetStateAction<IDraggableItemProps[]>>
}

function DraggableItems(props: IDraggableItemsProps) {
  const { disabledFields = [], items, setItems } = props

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => (b.checked ? 1 : 0) - (a.checked ? 1 : 0))
  }, [items])

  return (
    <div>
      {sortedItems.map((item, index) => (
        <DraggableItem
          key={item.name}
          label={item.label}
          index={index}
          checked={item.checked}
          canDrag={!disabledFields.includes(item.name)}
          onChange={event => {
            setItems(prev =>
              produce(prev, draft => {
                const i = draft.findIndex(i => i.name === item.name)
                if (i !== -1) {
                  draft[i].checked = event.target.checked
                }
              }),
            )
          }}
          onReorder={(currentIndex, nextIndex) => {
            setItems(prev =>
              produce(prev, draft => {
                const temp = draft[currentIndex]
                draft[currentIndex] = draft[nextIndex]
                draft[nextIndex] = temp
              }),
            )
          }}
        />
      ))}
    </div>
  )
}

export default DraggableItems
