import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import type {
  INewSellerProductParams,
  INewSellerProductResponse,
} from '~/types/models/ISellerProduct'
import type { IUser } from '~/types/models/IUser'
import useModifySellerProductsNew from './useModifySellerProductsNew'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQuerySellerProductsNew = (
  params: INewSellerProductParams = {},
  options?: Partial<
    UseQueryOptions<INewSellerProductResponse> & { additionalKeys?: string[] }
  >,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const narrowParams = useMemo(() => {
    const { buyerId, sellerId, buyerTerminalId, sellerTerminalId } = params

    const parsedParams = {
      ...(buyerTerminalId ? { buyerTerminalId } : buyerId ? { buyerId } : {}),
      ...(sellerTerminalId
        ? { sellerTerminalId }
        : sellerId
        ? { sellerId }
        : {}),
    }

    return parsedParams
  }, [params])

  const queryKey = useMemo(
    () => ['sellerProductsNew', sessionUser?.id, params || []],
    [sessionUser?.id, params],
  )

  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiClient.sellerProducts.new({
        sellerProduct: narrowParams,
      })
      return res
    },
    ...DEFAULT_QUERY_OPTIONS,
    enabled: Object.keys(narrowParams).length > 0,
    ...options,
  })

  const {
    deleteSellerProduct,
    updateSellerProduct,
    // addSellerProduct,//needs logic for which hierarchy key
  } = useModifySellerProductsNew(params)

  return {
    sellerProducts: data,
    // sellerProductOptions,
    // findSellerProductById,
    isLoadingSellerProducts: isLoading,
    deleteSellerProduct,
    updateSellerProduct,
    // addSellerProduct,
    refetchSellerProductsData: refetch,
    isSellerProductsFetched: isFetched,
  }
}

export default useQuerySellerProductsNew
