import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from '~/hooks/useRouter'
import { useDeepCompareEffect } from 'react-use'

import { IonApp, IonPage } from '@ionic/react'

import { selectCurrentScope, selectIsSigningIn } from '~/redux/selectors'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import useStoreGeneral from '~/hooks/useStoreGeneral'
import { EScope, ETableName } from '~/types/enums/ECommonEnum'
import { useQueryUseTableConfigurations } from '~/hooks/useQueryData'
import { UniversalLoadingPage } from '../UniversalLoadingPage'

const AuthenticationPage = ({ children, className }) => {
  const { authenticated } = useSelector(state => state.session)
  const isSigningIn = useSelector(selectIsSigningIn)

  const currentScope = useSelector(selectCurrentScope)

  const {
    filterConfigurations,
    isUserTableConfigurationsFetching,
    isUserTableConfigurationsFetched,
  } = useQueryUseTableConfigurations({
    tableName: ETableName.generalPreferences,
  })

  const router = useRouter()
  const { localStoredData } = useStoreGeneral()

  const isCheckAuthenticated = useMemo(() => {
    if (isMobileApp()) {
      if (localStoredData.isUsingBiometric) {
        return isSigningIn
      }

      return true
    }

    return authenticated
  }, [authenticated, isSigningIn, localStoredData])

  useDeepCompareEffect(() => {
    if (isCheckAuthenticated && isUserTableConfigurationsFetched) {
      if (router.query.back_url) {
        const url = decodeURIComponent(router.query.back_url)
        router.push(url)
      } else {
        const defaultPageBasedOnScope =
          currentScope === EScope.buyer
            ? '/documents'
            : currentScope === EScope.driverFleet
            ? '/start_times'
            : '/dispatch_dashboard'
        router.push(
          filterConfigurations?.defaultPage || defaultPageBasedOnScope,
        )
      }
    }
  }, [
    isUserTableConfigurationsFetched,
    isCheckAuthenticated,
    currentScope,
    router.query.back_url,
  ])

  if (isUserTableConfigurationsFetching) {
    return (
      <IonApp>
        <UniversalLoadingPage isWrappedByBody />
      </IonApp>
    )
  }

  return <IonPage className={className}>{children}</IonPage>
}

export default AuthenticationPage
