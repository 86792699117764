import { useState, useCallback } from 'react'
import { Container, Row, Col, Form, Badge } from 'react-bootstrap'
import HighLightText from '~/components/company/DocumentList/DialogEmailContent/HighLightText'
import { REGEX_SPLIT_REGEX_TO_STRING } from '~/utils/constants'
import { Button } from '~/components/shared'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import './SellerRegexForm.scss'
import { apiClient } from '~/api/ApiClient'
import { useDeepCompareEffect } from 'react-use'
import { useQueryCompanies } from '~/hooks/useQueryData'

const SellerRegexForm = ({ doc }) => {
  const [regex, setRegex] = useState({ expression: '', suffix: '', id: null })
  const [loading, setLoading] = useState(false)
  const [isEdited, setIsEdited] = useState(false)

  const { findCompanyById } = useQueryCompanies({})

  const seller = findCompanyById(doc?.sellerId)

  const [countMatchedText, setCountMatchedText] = useState(0)

  useDeepCompareEffect(() => {
    if (doc) {
      setLoading(true)
      const filters = { sellerId: doc.sellerId, buyerId: doc.buyerId }
      apiClient.buyerSellerRegexps
        .get({ filters })
        .then(({ buyerSellerRegexps }) => {
          const { sellerNameRegexp, id } = buyerSellerRegexps[0] || {}
          if (sellerNameRegexp) {
            const [, , regexStr, flags] = sellerNameRegexp.match(
              REGEX_SPLIT_REGEX_TO_STRING,
            )
            setRegex({ expression: regexStr, id, suffix: flags })
          }
        })
        .catch(() => {
          setRegex({ expression: '', suffix: '', id: null })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [doc])

  const onSubmitRegex = async e => {
    e.preventDefault()
    const req = {
      sellerNameRegexp: regexString,
      sellerId: doc.sellerId,
      buyerId: doc.buyerId,
    }
    setLoading(true)
    try {
      if (regex.id) {
        const response = await apiClient.buyerSellerRegexps.update(
          regex.id,
          req,
        )
        if (response.id) {
          toast.success(toastMessages.updateSuccess)
          setIsEdited(false)
        } else {
          toast.error(toastMessages.updateError)
        }
      } else {
        const response = await apiClient.buyerSellerRegexps.create(req)
        if (response.id) {
          toast.success(toastMessages.createSuccess)
          setIsEdited(false)
        } else {
          toast.error(toastMessages.createError)
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const wordBlocksText = doc?.ocrJson?.wordBlocks?.map(wb => wb.text).join(' ')
  const regexString = `/${regex.expression}/${regex.suffix}`

  const onCountMatchedString = useCallback(matchedText => {
    setCountMatchedText(matchedText)
  }, [])

  return (
    <Container>
      <Form onSubmit={onSubmitRegex}>
        <Row className='mb-3'>
          <Col sm={8}>
            <Form.Group>
              <Form.Label size='lg'>{seller?.name} regex:</Form.Label>
              <div className='SellerRegexForm__regexInput'>
                <Form.Control
                  size='lg'
                  type='text'
                  value={regex.expression}
                  onChange={e => {
                    setRegex({ ...regex, expression: e.target.value })
                    setIsEdited(true)
                  }}
                />
                {countMatchedText > 0 && (
                  <Badge
                    bg='danger'
                    className='SellerRegexForm__badgeCountingMatchedText'
                  >
                    {countMatchedText}
                  </Badge>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Search Type:</Form.Label>
              <Form.Select
                size='lg'
                value=''
                onChange={e => setRegex({ ...regex, suffix: e.target.value })}
              >
                <option value=''>Case Sensitive</option>
                <option value='i'>Case Insensitive</option>
                <option value='m'>Make dot match newlines</option>
                <option value='x'>Ignore whitespace in regex</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Button
          type='submit'
          className='mb-3'
          label='Submit'
          size='small'
          loading={loading}
          disabled={!isEdited}
        >
          Submit
        </Button>
      </Form>
      <Row className='SellerRegexForm__sellerRegexFormText'>
        <HighLightText
          regex={regexString}
          className='SellerRegexForm__highlightText'
          onCountMatchedString={onCountMatchedString}
        >
          {wordBlocksText}
        </HighLightText>
      </Row>
    </Container>
  )
}

export default SellerRegexForm
