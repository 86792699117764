import React, { useState, useEffect, useCallback } from 'react'
import {
  IonModal,
  IonImg,
  IonContent,
  IonButton,
  IonIcon,
  IonLabel,
  IonCheckbox,
  IonGrid,
  IonRow,
} from '@ionic/react'
import SplitPane from 'react-split-pane'
import {
  getCurrentLineByKey,
  getNextLineByKey,
  getPreviousLineByKey,
  groupBillLinesByKey,
  getBillLinesByKey,
  getLoadImage,
} from './helpers'
import { CompareLine } from '../CompareLine'
import { DocumentView } from '~/components/shared'
import {
  arrowBack,
  arrowForward,
  playForwardCircleOutline,
  playBackCircleOutline,
} from 'ionicons/icons'
import styles from './styles'
import {
  BILL_LINES_COLUMNS,
  UPDATE_BILL_LINES_COLUMNS,
  SORT_OPTIONS_FOR_COMPARE_INVOICE,
  INVOICE_SOURCE,
} from '~/constants/invoice-type'
import { NewTableRow } from '~/components/shared'
import { updateInvoiceBillLines } from '~/redux/actions/invoiceActions'
import { useDispatch, useSelector } from 'react-redux'
import { selectBillLines } from '~/redux/selectors'
import * as _ from 'lodash'
import LoadPrint from '~/components/order/LoadPrint'
import { apiClient } from '~/api/ApiClient'

const SplitBillLine = ({
  splitRef,
  invoice,
  saveBillLine,
  isResolved,
  isCompareInvoice,
  billLinesSort = SORT_OPTIONS_FOR_COMPARE_INVOICE,
}) => {
  const invoiceBillLines = useSelector(selectBillLines)
  const { billLinesColumn } = useSelector(state => state.app)
  const [showPopOver, setShowPopOver] = useState(false)
  const [billLines, setBillLines] = useState([])
  const [currentBillLine, setCurrentBillLine] = useState()
  const [groupedBillLines, setGroupedBillLines] = useState([])
  const [loadImage, setLoadImage] = useState()
  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedBillLineIds, setSelectedBillLineIds] = useState([])
  const [actions, setActions] = useState({
    canBack: false,
    canNext: false,
  })
  const [billLinesSortOption, setBillLinesSortOption] = useState([])
  const dispatch = useDispatch()
  const [isShowInvoice, setShowInvoice] = useState(true)
  const [billLineCount, setBillLineCount] = useState(0)
  const [billLinesColumns, setBillLinesColumns] = useState(
    billLinesColumn?.comparison_invoices_columns || BILL_LINES_COLUMNS,
  )
  const [document, setDocument] = useState(false)

  const [showToggleOption, setShowToggleOption] = useState(false)
  const [currentLineIndex, setCurrentLineIndex] = useState(0)
  const [browsedIds, setBrowsedIds] = useState([])
  const [totalTicketIds, setTotalTicketIds] = useState([])

  const invoiceSource = invoice?.source

  const onPreviousLine = () => {
    const { actions, billLine, loadIndex } = getPreviousLineByKey(
      currentBillLine,
      groupedBillLines,
    )
    setCurrentLineIndex(loadIndex + 1)
    setActions(actions)
    setCurrentBillLine(billLine)
    const byKey = isCompareInvoice ? 'ticketNum' : 'loadId'
    const lastBrowsedIds = browsedIds[browsedIds.length - 1]
    if (lastBrowsedIds != billLine[byKey]) {
      browsedIds.splice(browsedIds.length - 1, 1)
      setBrowsedIds(browsedIds)
    }
  }

  const onNextLine = () => {
    const byKey = isCompareInvoice ? 'ticketNum' : 'loadId'
    const { actions, billLine, loadIndex } = getNextLineByKey(
      currentBillLine,
      groupedBillLines,
    )
    if (!browsedIds.includes(billLine[byKey])) {
      browsedIds.push(billLine[byKey])
      setBrowsedIds(browsedIds)
    }
    setCurrentLineIndex(loadIndex + 1)
    setActions(actions)
    setCurrentBillLine(billLine)
  }

  const onPreviousLoad = () => {
    const { actions, billLine, loadIndex } = getPreviousLineByKey(
      currentBillLine,
      groupedBillLines,
      true,
      browsedIds,
    )
    setCurrentLineIndex(loadIndex + 1)
    setActions(actions)
    setCurrentBillLine(billLine)
    const byKey = isCompareInvoice ? 'ticketNum' : 'loadId'
    const lastBrowsedIds = browsedIds[browsedIds.length - 1]
    if (lastBrowsedIds != billLine[byKey]) {
      browsedIds.splice(browsedIds.length - 1, 1)
      setBrowsedIds(browsedIds)
    }
  }

  const onNextLoad = () => {
    const byKey = isCompareInvoice ? 'ticketNum' : 'loadId'
    const { actions, billLine, loadIndex } = getNextLineByKey(
      currentBillLine,
      groupedBillLines,
      byKey,
      true,
      browsedIds,
    )
    if (!browsedIds.includes(billLine[byKey])) {
      browsedIds.push(billLine[byKey])
      setBrowsedIds(browsedIds)
    }
    setCurrentLineIndex(loadIndex + 1)
    setActions(actions)
    setCurrentBillLine(billLine)
  }

  useEffect(() => {
    setBillLinesColumns(billLinesColumn?.comparison_invoices_columns)
  }, [billLinesColumn])

  useEffect(() => {
    if (invoice && currentBillLine) {
      const billLineUpdate = invoiceBillLines.filter(
        line => line?.id === currentBillLine?.id,
      )[0]
      const _billLines = getBillLinesByKey(
        billLineUpdate || currentBillLine,
        invoice,
        isCompareInvoice ? 'ticketNum' : 'loadId',
        invoiceBillLines,
      )

      let compareCount = _.reduce(
        _billLines,
        (prev, cur) => {
          return prev + cur.compareLines?.length
        },
        0,
      )
      setBillLineCount(compareCount + _billLines.length)
      setBillLines(_billLines)
      setCurrentBillLine(billLineUpdate)
      const _loadImage = getLoadImage(
        billLineUpdate || currentBillLine,
        invoice,
        isCompareInvoice,
      )
      if (!_loadImage) {
        setShowInvoice(true)
      }

      setLoadImage(_loadImage)
    }
  }, [invoice, currentBillLine, isCompareInvoice, invoiceBillLines])

  useEffect(() => {
    setBillLinesSortOption(billLinesSort)
  }, [billLinesSort])

  useEffect(() => {
    if (invoice) {
      var sortOptions = []
      if (Object.keys(billLinesSortOption).length > 0) {
        sortOptions = billLinesSortOption.invoiceSortValue.filter(
          bill => bill.sorted,
        )

        // const ticketNumIndex = sortOptions.findIndex(option => option.sortField == 'ticketNum')
        // if (ticketNumIndex) {
        //   const defaultDate = new Date().toISOString()
        //   sortOptions.splice(ticketNumIndex, 0, { label: 'load', sortField: 'loadId', sorted: true, hidable: true, isAsc: true, updatedAt: defaultDate });
        // }
      }

      const groupedLines = groupBillLinesByKey(
        invoiceBillLines,
        isCompareInvoice ? 'ticketNum' : 'loadId',
        sortOptions,
      )

      setGroupedBillLines(groupedLines)
      setActions({
        canBack: false,
        canNext: groupedLines.length > 1,
      })
      setDocument(invoice.proveUrl)
      // setShowToggleOption(false)
      if (invoice.compareInvoice) setShowToggleOption(true)
      // else {
      setShowInvoice(false)
      setShowToggleOption(true)
      // }
    }
  }, [invoice, isCompareInvoice, billLinesSortOption, invoiceBillLines])

  const onToggleInvoice = useCallback(() => {
    setShowInvoice(prev => !prev)
  }, [])

  const onChangeSize = useCallback(() => {
    //TODO: Check size to toggle invoce
  }, [])

  const reloadData = useCallback(async () => {
    if (!invoice) return
    const newInvoice = await apiClient.invoices.refresh(invoice.id)
    if (newInvoice.invoice) setDocument(newInvoice.invoice.invoiceProve)
  }, [invoice])

  //this function will be called via splitRef
  const onShowBillLine = useCallback(
    selectedLine => {
      const { actions, billLine, loadIndex } = getCurrentLineByKey(
        selectedLine,
        groupedBillLines,
      )
      var pushedFlag = true
      for (const item of groupedBillLines) {
        if (!browsedIds.includes(item.primaryKey) && pushedFlag) {
          browsedIds.push(item.primaryKey)
          setBrowsedIds(browsedIds)
        }
        if (item.secondaryKey == billLine.id) {
          pushedFlag = false
        }
        if (!totalTicketIds.includes(item.primaryKey)) {
          totalTicketIds.push(item.primaryKey)
          setTotalTicketIds(totalTicketIds)
        }
      }
      setActions(actions)
      setCurrentBillLine(billLine)
      setShowPopOver(true)
      setCurrentLineIndex(loadIndex + 1)
    },
    [groupedBillLines, browsedIds, totalTicketIds],
  )

  useEffect(() => {
    if (!showPopOver) {
      setBrowsedIds([])
    }
  }, [showPopOver])

  const onHide = useCallback(() => {
    setIsUpdating(false)
    setShowPopOver(false)
  }, [])

  useEffect(() => {
    if (splitRef) {
      splitRef.current = {
        onShowBillLine,
        onHide,
      }
    }
  }, [splitRef, onShowBillLine, onHide])

  const onUpdateAllBillLine = useCallback(
    async data => {
      const updatedIds = []
      const _newBillLines = billLines.map(line => {
        const shouldUpdate =
          selectedBillLineIds.length == 0 ||
          selectedBillLineIds.includes(line.id)
        if (shouldUpdate) {
          updatedIds.push(line.id)
        }

        return shouldUpdate
          ? {
              ...line,
              ...data,
            }
          : line
      })
      setBillLines?.(_newBillLines)
      await dispatch(
        updateInvoiceBillLines(updatedIds, data, invoice, isCompareInvoice),
      )
      setIsUpdating(false)
      setSelectedBillLineIds([])
    },
    [billLines, selectedBillLineIds, dispatch, invoice, isCompareInvoice],
  )

  const onCancelUpdateBillLine = useCallback(() => {
    setIsUpdating(false)
  }, [])

  const onSelectAll = useCallback(() => {
    if (selectedBillLineIds.length === billLineCount) {
      setSelectedBillLineIds([])
    } else {
      let selectedLines = _.reduce(
        billLines,
        (prev, cur) => {
          return [...prev, ...cur.compareLines.map(line => line.id)]
        },
        [],
      )
      selectedLines = [...selectedLines, ...billLines.map(line => line.id)]
      setSelectedBillLineIds(selectedLines)
    }
  }, [selectedBillLineIds, billLineCount, billLines])

  const onSelect = useCallback(
    billLine => {
      if (!selectedBillLineIds.includes(billLine.id)) {
        setSelectedBillLineIds([...selectedBillLineIds, billLine.id])
      } else {
        setSelectedBillLineIds([
          ...selectedBillLineIds.filter(id => id != billLine.id),
        ])
      }
    },
    [selectedBillLineIds],
  )

  return (
    <IonModal
      isOpen={showPopOver}
      canDismiss={true}
      onDidDismiss={onHide}
      id='InvoicesEyeModal'
      cssClass='fullOverView'
      style={{
        width: window.innerWidth - 100,
        height: window.innerHeight - 100,
      }}
    >
      {actions.canBack && (
        <IonButton
          size='small'
          color='dark'
          style={{
            ...styles.arrowButton,
            left: 0,
          }}
          onClick={onPreviousLine}
          title='Previous bill line'
        >
          <IonIcon icon={arrowBack} />
        </IonButton>
      )}
      {actions.canBack && (
        <IonButton
          size='small'
          color='dark'
          style={{
            ...styles.bottomArrowButton,
            left: 0,
          }}
          onClick={onPreviousLoad}
          title='Previous bill line'
        >
          <IonIcon icon={playBackCircleOutline} />
        </IonButton>
      )}
      {actions.canNext && (
        <IonButton
          size='small'
          color='dark'
          style={{
            ...styles.arrowButton,
            right: 0,
          }}
          onClick={onNextLine}
          title='Next bill line'
        >
          <IonIcon icon={arrowForward} />
        </IonButton>
      )}
      {actions.canNext && (
        <IonButton
          size='small'
          color='dark'
          style={{
            ...styles.bottomArrowButton,
            right: 0,
          }}
          onClick={onNextLoad}
          title='Next bill line'
        >
          <IonIcon icon={playForwardCircleOutline} />
        </IonButton>
      )}
      <IonButton
        size='small'
        color='danger'
        slot='end'
        onClick={onHide}
        title='Close Modal'
        style={{
          ...styles.arrowButton,
          top: 0,
          right: 0,
        }}
      >
        X
      </IonButton>
      <SplitPane
        split='horizontal'
        defaultSize='75%'
        minSize={300}
        maxSize={-150}
        primary='first'
      >
        <SplitPane
          split='vertical'
          defaultSize={!loadImage ? '100%' : '50%'}
          minSize={300}
          onChange={onChangeSize}
          primary='first'
        >
          {invoiceSource === INVOICE_SOURCE.load && (
            <LoadPrint load={loadImage} />
          )}
          {loadImage && (
            <IonImg
              style={{ height: 600, pointerEvents: 'none' }}
              src={loadImage}
            />
          )}

          {(isShowInvoice || !loadImage) && (
            <DocumentView
              contentHeight={600}
              document={document}
              onReloadData={reloadData}
            />
          )}
        </SplitPane>
        <IonContent style={{ overflow: 'visible' }}>
          <div
            className='pt-3'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              className='ion-text-start'
              style={{ marginLeft: 30, fontSize: 14 }}
            >
              {groupedBillLines.length > 0 && (
                <>
                  <b>Bill Line:</b> {currentLineIndex} of{' '}
                  {groupedBillLines.length}
                </>
              )}{' '}
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>| </span>
              {totalTicketIds.length > 0 && (
                <>
                  <b>Ticket:</b> {browsedIds.length} of {totalTicketIds.length}
                </>
              )}
            </div>
            {showToggleOption && loadImage && (
              <div className='ion-text-end'>
                <IonButton fill='clear' onClick={onToggleInvoice}>
                  <IonCheckbox checked={isShowInvoice} />
                  <IonLabel
                    style={{
                      textTransform: 'capitalize',
                      paddingLeft: '10px',
                    }}
                  >
                    Show Invoice
                  </IonLabel>
                </IonButton>
              </div>
            )}
          </div>
          {billLines.map((billLine, index) => (
            <div key={index} style={styles.compareLineContainer}>
              <CompareLine
                billLine={billLine}
                className='--primary'
                rowIndex={index}
                saveBillLine={saveBillLine}
                isResolved={isResolved}
                billLinesColumns={billLinesColumns}
                isSelecting={isUpdating}
                isSelected={selectedBillLineIds.includes(billLine.id)}
                onSelect={onSelect}
                datePickerProps={{
                  portalId: 'InvoicesEyeModal',
                }}
              />
              {billLine.compareLines.map((cBillLine, index) => (
                <CompareLine
                  billLine={cBillLine}
                  key={`${index}-comp`}
                  rowIndex={index}
                  className='--caution'
                  saveBillLine={saveBillLine}
                  isResolved={isResolved}
                  billLinesColumns={billLinesColumns}
                  isSelecting={isUpdating}
                  isSelected={selectedBillLineIds.includes(cBillLine.id)}
                  onSelect={onSelect}
                  datePickerProps={{
                    portalId: 'InvoicesEyeModal',
                  }}
                />
              ))}
            </div>
          ))}
          <div className={'BillLinesTable'} style={{ border: 'none' }}>
            <IonGrid>
              <IonRow>
                <IonButton
                  className={`${!isUpdating ? '' : 'ion-hide'}`}
                  style={{ flex: 1, margin: '12px 0' }}
                  fill='solid'
                  color='dark'
                  onClick={() => {
                    setIsUpdating(true)
                  }}
                >
                  {selectedBillLineIds.length > 0
                    ? 'Update selected bill lines'
                    : 'Update Bill Lines'}
                </IonButton>
                <NewTableRow
                  // showAllColumn={showAllColumn}
                  billLinesColumns={UPDATE_BILL_LINES_COLUMNS}
                  // onBillLineHover={onBillLineHover}
                  // onCellHover={onCellHover}
                  // cellHovered={cellHovered}
                  className={`${!isUpdating ? 'ion-hide' : ''}`}
                  isSelectedAll={selectedBillLineIds.length === billLineCount}
                  selectedBillLines={selectedBillLineIds}
                  isSelecting={isUpdating}
                  onSelectAll={onSelectAll}
                  onUpdate={onUpdateAllBillLine}
                  onCancel={onCancelUpdateBillLine}
                />
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </SplitPane>
    </IonModal>
  )
}

export default SplitBillLine
