const getIsGroupedOptions = (options: any) => {
  if (!Array.isArray(options) || options.length === 0) {
    return false
  }

  const firstItem = options[0]

  if ('value' in firstItem) {
    return false
  } else if ('options' in firstItem && Array.isArray(firstItem.options)) {
    return true
  } else {
    return false
  }
}

export default getIsGroupedOptions
