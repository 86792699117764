import React, { useCallback, useMemo, useState } from 'react'
import { useQueryEmails } from '~/hooks/useQueryData'

import {
  DialogEmailForm,
  EditIcon,
  EmailIcon,
  PlusIcon,
  ReusableButton,
  SentEmailsDialog,
} from '~/components/shared'
import Tooltip from 'rc-tooltip'

import { EEmailableType, EEmailTypes } from '~/types/enums/EEmail'
import { Alert, Badge, ButtonGroup } from 'react-bootstrap'
import { IonProgressBar, IonSpinner } from '@ionic/react'

import './styles.scss'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { IInvoice } from '~/types/models/IInvoice'
import formatDateBasedOnYear from '~/utils/formatDateBasedOnYear'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { ICompany } from '~/types/models/ICompany'
import { format } from 'date-fns'
import { useCompanyFormContext } from '~/contexts'
import { IEmail } from '~/types/models/IEmail'

export interface IInvoiceEmailButtonProps {
  style?: React.CSSProperties
  afterSendEmail?: (invoice: IInvoice) => void
  variant?: string
  invoice: IInvoice
}

function InvoiceEmailButton(props: IInvoiceEmailButtonProps) {
  const { style, variant = 'info', invoice, afterSendEmail } = props

  const [visible, setVisible] = useState(false)
  const [isEmailing, setIsEmailing] = useState(false)
  const [showSentEmailsDialog, setShowSentEmailsDialog] = useState(false)
  const [isFetchingCompany, setIsFetchingCompany] = useState(false)
  const [emailForm, setEmailForm] = useState({
    isOpen: false,
    formData: undefined as Partial<IEmail> | undefined,
  })

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { onOpenCompanyForm } = useCompanyFormContext()

  const companyDateFormat = useMemo(
    () =>
      formatDateBasedOnYear(
        currentCompany.dateFormat || 'MMM-d yyyy (eee)',
        invoice.emailAt,
      ),
    [currentCompany.dateFormat, invoice.emailAt],
  )

  const {
    emailsData,
    isEmailsFetching,
    isLoadingEmailsData,
    isEmailsFetched,
    refetchEmailsData,
    updateEmail,
  } = useQueryEmails(
    {
      filters: {
        emailableId: invoice.buyerId,
        emailableType: EEmailableType.company,
      },
    },
    { enabled: visible },
  )

  const apEmail = emailsData.find(({ emailTypes }) =>
    (emailTypes as EEmailTypes[]).includes(EEmailTypes.ap),
  )

  const otherEmails = emailsData.filter(({ id }) => id !== apEmail?.id)

  const onOpenCompanyUpdateForm = useCallback(
    (extraProps?: any) => async () => {
      setIsFetchingCompany(true)
      try {
        const response = await apiClient.companies.getById(invoice.buyerId)
        onOpenCompanyForm({
          formData: response,
          groupsShownByDefault: ['contactInfo'],
          afterModifyEmails() {
            refetchEmailsData()
          },
          ...extraProps,
        })
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsFetchingCompany(false)
      }
    },
    [invoice.buyerId, onOpenCompanyForm, refetchEmailsData],
  )

  const emailsDataOrdered = useMemo(() => {
    if (apEmail) {
      return [apEmail, ...otherEmails]
    }
    return otherEmails
  }, [apEmail, otherEmails])

  const onCloseEmailForm = useCallback(() => {
    setEmailForm({ isOpen: false, formData: undefined })
  }, [])

  const onOpenEmailForm = useCallback(
    (formData?: Partial<IEmail>) => () => {
      setEmailForm({ isOpen: true, formData })
    },
    [],
  )

  const renderEmails = useMemo(() => {
    if (invoice.emailAt) {
      const date = new Date(invoice.emailAt)
      return (
        <div>
          <ButtonGroup>
            <ReusableButton
              onClick={onOpenCompanyUpdateForm({
                emailForm: {
                  show: true,
                },
              })}
              size='sm'
              isDisabled={isFetchingCompany}
            >
              <PlusIcon color='white' />
            </ReusableButton>

            <ReusableButton
              variant='warning'
              onClick={onOpenCompanyUpdateForm()}
              size='sm'
            >
              <EditIcon color='white' size={12} />
            </ReusableButton>
          </ButtonGroup>

          <div>
            Emailed At: {format(date, companyDateFormat)}&nbsp;
            {format(date, 'H:m:ss')}
          </div>
          <div>
            {emailsDataOrdered.map(email => (
              <div className='InvoiceEmailButton__emailItem' key={email.id}>
                <a href={`mailto:${email.email}`}>{email.email}</a>
                <div>
                  {(email.emailTypes as EEmailTypes[]).map(type => (
                    <Badge key={type} style={{ marginRight: 4 }}>
                      {EEmailTypes[type]}
                    </Badge>
                  ))}
                  <span className='clickable' onClick={onOpenEmailForm(email)}>
                    <EditIcon color='var(--bs-orange)' />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }

    if (emailsDataOrdered.length === 0 && isEmailsFetched) {
      return (
        <Alert style={{ fontSize: 13, marginBottom: 0 }} variant='warning'>
          No Emails Found!{' '}
          {isFetchingCompany ? (
            <IonSpinner
              name='lines-small'
              style={{ verticalAlign: 'middle' }}
            />
          ) : (
            <span
              className='hyperLink'
              onClick={onOpenCompanyUpdateForm({
                emailForm: {
                  show: true,
                },
              })}
            >
              Create one
            </span>
          )}
        </Alert>
      )
    }

    return (
      <div>
        {!isEmailsFetching && (
          <ButtonGroup>
            <ReusableButton
              onClick={onOpenCompanyUpdateForm({
                emailForm: {
                  show: true,
                },
              })}
              size='sm'
              isDisabled={isFetchingCompany}
            >
              <PlusIcon color='white' />
            </ReusableButton>

            <ReusableButton
              variant='warning'
              onClick={onOpenCompanyUpdateForm()}
              size='sm'
            >
              <EditIcon color='white' size={12} />
            </ReusableButton>
          </ButtonGroup>
        )}
        {emailsDataOrdered.map(email => (
          <div className='InvoiceEmailButton__emailItem' key={email.id}>
            <a href={`mailto:${email.email}`}>{email.email}</a>
            <div>
              {(email.emailTypes as EEmailTypes[]).map(type => (
                <Badge key={type} style={{ marginRight: 4 }}>
                  {EEmailTypes[type]}
                </Badge>
              ))}
              <span className='clickable' onClick={onOpenEmailForm(email)}>
                <EditIcon color='var(--bs-orange)' />
              </span>
            </div>
          </div>
        ))}
      </div>
    )
  }, [
    companyDateFormat,
    emailsDataOrdered,
    invoice.emailAt,
    isEmailsFetched,
    isFetchingCompany,
    onOpenCompanyUpdateForm,
    isEmailsFetching,
    onOpenEmailForm,
  ])

  const onSendEmail = useCallback(async () => {
    setIsEmailing(true)
    try {
      const res = await apiClient.emailInvoices.create({
        invoiceIds: [invoice.id],
      })
      if (res.message === 'Email sent') {
        const invoiceData = await apiClient.invoices.getById(invoice.id)
        toast.success(res.message)
        afterSendEmail && afterSendEmail(invoiceData)
      } else {
        toast.error(res.message)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsEmailing(false)
    }
  }, [afterSendEmail, invoice.id])

  const onOpenSentEmailsDialog = useCallback(() => {
    setShowSentEmailsDialog(true)
  }, [])

  return (
    <>
      <Tooltip
        overlay={
          <div>
            {isEmailsFetching || isLoadingEmailsData ? (
              <IonProgressBar type='indeterminate' />
            ) : null}
            {renderEmails}
          </div>
        }
        placement='right'
        onVisibleChange={setVisible}
        visible={visible}
        overlayClassName='InvoiceEmailButton__overlay'
      >
        <ReusableButton
          isLoading={isEmailing}
          onClick={invoice.emailAt ? onOpenSentEmailsDialog : onSendEmail}
          style={style}
          variant={variant}
        >
          <EmailIcon color='white' />
        </ReusableButton>
      </Tooltip>

      <SentEmailsDialog
        isOpen={showSentEmailsDialog}
        onClose={() => {
          setShowSentEmailsDialog(false)
        }}
        emailableId={invoice?.id}
        companyId={invoice?.buyerId}
        emailableType='Invoice'
      />

      <DialogEmailForm
        isOpen={emailForm.isOpen}
        formData={emailForm.formData}
        afterUpdate={(email: IEmail) => {
          updateEmail(email.id, email)
          onCloseEmailForm()
        }}
        onClose={onCloseEmailForm}
      />
    </>
  )
}

export default InvoiceEmailButton
