import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import {
  useQueryEmails,
  useQueryEnums,
  useQueryPhoneNumbers,
} from '~/hooks/useQueryData'

import {
  ICreateCheckCompanyFormValues,
  IDialogCreateCheckCompanyProps,
} from './type'
import {
  AddressForm,
  ConcordFormAddressInput,
  ConcordFormDropdownV2,
  EmailForm,
  IConcordFormField,
  PhoneNumberForm,
} from '~/components/shared'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { IAddressInputOnChangeParams } from '~/components/shared/ConcordForm/AddressInput/type'
import { useQueryAddresses } from '~/hooks/useQueryData'
import {
  CHECK_COMPANY_BUSINESS_TYPE_OPTIONS,
  CHECK_COMPANY_INDUSTRY_TYPE_OPTIONS,
  CHECK_COMPANY_PAY_FREQUENCY_OPTIONS,
  CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
} from '~/utils/constants'
import { EAddressTypeStr, EAddressableType } from '~/types/enums/EAddress'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { INullOrUndefined } from '~/types/models/ICommonModel'
import { apiClient } from '~/api/ApiClient'
import { IAddress } from '~/types/models/IAddress'
import * as Yup from 'yup'
import moment from 'moment'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import {
  EPhoneNumberTypes,
  EPhoneNumberTypesString,
  EPhoneableType,
} from '~/types/enums/EPhoneNumber'
import { IPhoneNumber } from '~/types/models/IPhoneNumber'
import { addCircleOutline, closeOutline, pencil } from 'ionicons/icons'
import areAddressesEqual from '~/utils/areAddressesEqual'
import {
  ECheckCompanyIndustryType,
  ECheckCompanyProcessingPeriod,
  ECheckPayFrequency,
} from '~/types/enums/ECheck'
import buildFullAddress from '~/utils/buildFullAddress'
import { EEmailableType, EEmailTypes } from '~/types/enums/EEmail'
import { IEmail } from '~/types/models/IEmail'

const DEFAULT_FORM_VALUES: ICreateCheckCompanyFormValues = {
  legalName: '',
  tradeName: '',
  industryType:
    ECheckCompanyIndustryType.generalConstructionOrGeneralContracting,
  payFrequency: ECheckPayFrequency.biweekly,
  processingPeriod: ECheckCompanyProcessingPeriod.oneDay,
  startDate: '',
  feinId: '',
  email: '',
  phone: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
}

const useDialogCreateCheckCompany = (props: IDialogCreateCheckCompanyProps) => {
  const {
    isOpen,
    isHiddenCloseButton,
    formValues,
    onClose,
    afterCreateCheckCompany,
    afterUpdateCheckCompany,
  } = props

  const [selectedCurrentAddressId, setSelectedCurrentAddressId] =
    useState<number | INullOrUndefined>(null)
  const [isCreatingAddress, setIsCreatingAddress] = useState(false)
  const [checkCompanyFormValues, setCheckCompanyFormValues] =
    useState(DEFAULT_FORM_VALUES)
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatingPhone, setIsCreatingPhone] = useState(false)
  const [isCreatingEmail, setIsCreatingEmail] = useState(false)
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)
  const [backendError, setBackendError] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const isUpdating = useMemo(() => Boolean(formValues?.id), [formValues?.id])

  const {
    addressesData,
    addressOptions,
    addAddress,
    hrAddress,
    refetchQueryAddresses,
    removeAddress,
    updateAddress: updateAddressInQueryData,
  } = useQueryAddresses(
    {
      filters: {
        addressableType: EAddressableType.company,
        addressableId: currentCompany?.id as number,
      },
    },
    { enabled: Boolean(currentCompany.id) },
  )

  const selectedCurrentAddress = useMemo(() => {
    if (selectedCurrentAddressId) {
      return addressesData.find(({ id }) => selectedCurrentAddressId === id)
    }
    return undefined
  }, [addressesData, selectedCurrentAddressId])

  const { companyEmailOptions, emailsData, addEmail } = useQueryEmails({
    filters: {
      emailableId: currentCompany?.id,
      emailableType: EEmailableType.company,
    },
  })

  const { getTypeLabels, convertTypesToString } = useQueryEnums({
    field: 'addressTypes',
    model: 'addresses',
  })

  const {
    phoneNumberOptions,
    defaultCompanyPhoneNumber,
    phoneNumbersData,
    hrPhoneNumber,
    convertPhoneTypeFromEnumToString,
    addPhoneNumber,
    updatePhoneNumber,
    removePhoneNumber,
  } = useQueryPhoneNumbers(
    {
      filters: {
        phoneableId: currentCompany?.id as number,
        phoneableType: EPhoneableType.company,
      },
    },
    { enabled: Boolean(currentCompany.id) },
  )

  const onToggleCreateAddress = useCallback(() => {
    setIsCreatingAddress(prev => !prev)
  }, [])

  const schema = useMemo(
    () =>
      Yup.object({
        legalName: Yup.string().required('Legal name is required'),
        tradeName: Yup.string().required('Trade name is required'),
        businessType: Yup.string().required('Business type is required'),
        industryType: Yup.string().required('Industry type is required'),
        payFrequency: Yup.string().required('Pay frequency is required'),
        startDate: Yup.string()
          .required('Start date is required!')
          .transform(value => {
            return value ? moment(value).format('YYYY-MM-DD') : ''
          }),
        address: Yup.object({
          line1: Yup.string().required('Line is required!'),
          line2: Yup.string().nullable(),
          city: Yup.string().required('City is required!'),
          state: Yup.string().required('State is required!'),
          postalCode: Yup.string().required('Postal code is required!'),
          country: Yup.string().required('Country is required!'),
        }),
      }),
    [],
  )

  const isHiddenAddressField = useCallback(() => {
    return !selectedCurrentAddressId
  }, [selectedCurrentAddressId])

  const formFields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'legalName',
        label: 'Legal Name',
        isRequired: true,
        size: 6,
      },
      {
        name: 'tradeName',
        label: 'Trade Name',
        isRequired: true,
        size: 6,
      },
      {
        name: 'email',
        label: 'Email',
        size: 6,
        type: EFieldType.singleSelect,
        options: companyEmailOptions,
        extraIcons: [
          {
            icon: addCircleOutline,
            color: 'concord',
            onClick() {
              setIsCreatingEmail(prev => !prev)
            },
          },
        ],
      },
      {
        name: 'phone',
        label: 'Phone',
        size: 6,
        type: EFieldType.singleSelect,
        options: phoneNumberOptions,
        extraIcons: [
          {
            icon: addCircleOutline,
            color: 'concord',
            onClick() {
              setIsCreatingPhone(prev => !prev)
            },
          },
        ],
      },
      {
        label: '',
        name: 'createNewPhoneNumber',
        isHidden: !isCreatingPhone,
        render({ setValue }) {
          return (
            <div className='CreateCheckCompanyDialog__phoneNumberForm'>
              <PhoneNumberForm
                isHiddenSearch
                submitText='Create'
                cancelText='Close'
                phoneNumberData={phoneNumbersData}
                isPhoneableTypeReadOnly
                defaultValues={{
                  phoneableType: EPhoneableType.company,
                  number: '',
                  phoneTypes: [],
                }}
                onCancel={() => {
                  setIsCreatingPhone(false)
                }}
                afterCreate={(phoneNumber: IPhoneNumber) => {
                  setIsCreatingPhone(false)
                  addPhoneNumber(phoneNumber)
                  setValue('phone', phoneNumber.id)
                }}
              />
            </div>
          )
        },
      },
      {
        label: '',
        name: 'createNewEmail',
        isHidden: !isCreatingEmail,
        render({ setValue }) {
          return (
            <div className='CreateCheckCompanyDialog__phoneNumberForm'>
              <EmailForm
                isHiddenSearch
                submitText='Create'
                cancelText='Close'
                companyEmailsData={emailsData}
                formData={{
                  email: '',
                  emailTypes: [EEmailTypes[EEmailTypes.hr]],
                }}
                onCancel={() => {
                  setIsCreatingEmail(false)
                }}
                afterCreate={(email: IEmail) => {
                  setIsCreatingEmail(false)
                  addEmail(email)
                  setValue('email', email.id)
                }}
              />
            </div>
          )
        },
      },
      {
        name: 'businessType',
        label: 'Business Type',
        isRequired: true,
        size: 6,
        options: CHECK_COMPANY_BUSINESS_TYPE_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'industryType',
        label: 'Industry Type',
        isRequired: true,
        size: 6,
        options: CHECK_COMPANY_INDUSTRY_TYPE_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'website',
        label: 'Website',
        size: 12,
      },
      {
        name: 'payFrequency',
        label: 'Pay Frequency',
        isRequired: true,
        size: 6,
        options: CHECK_COMPANY_PAY_FREQUENCY_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        name: 'processingPeriod',
        label: 'Processing Period',
        size: 6,
        options: CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS,
        type: EFieldType.singleSelect,
      },
      {
        label: 'Start date',
        name: 'startDate',
        size: 6,
        isRequired: true,
        type: EFieldType.date,
        minDate: moment().format('YYYY-MM-DD'),
      },
      {
        label: 'Fein #',
        name: 'feinId',
        size: 6,
        placeholder: 'Ex: 12-3456789',
      },
      {
        label: 'Search address',
        name: 'searchAddress',
        size: 12,
        isHidden: !isUpdatingAddress,
        render({ label, setValue }) {
          return (
            <ConcordFormAddressInput
              label={label}
              extraIcons={[
                {
                  icon: closeOutline,
                  onClick() {
                    setIsUpdatingAddress(false)
                    setValue('address', {
                      line1: selectedCurrentAddress?.street,
                      city: selectedCurrentAddress?.city,
                      state: selectedCurrentAddress?.state,
                      postalCode: selectedCurrentAddress?.zip,
                      country: selectedCurrentAddress?.country,
                    })
                  },
                  color: 'danger',
                },
              ]}
              onChange={(
                event: unknown,
                { address }: IAddressInputOnChangeParams,
              ) => {
                const { city, country, postalCode, state, street } = address
                setValue('address', {
                  line1: street,
                  city,
                  state,
                  postalCode,
                  country,
                })
              }}
            />
          )
        },
      },
      {
        label: 'Company address',
        name: 'currentAddresses',
        size: 12,
        isHidden: isUpdatingAddress || addressOptions.length === 0,
        render({ label, setValue, errors }) {
          const hasAddressError =
            Boolean(errors?.address) && !selectedCurrentAddressId

          return (
            <ConcordFormDropdownV2
              label={label}
              isHiddenCreateIcon={false}
              onClickCreateIcon={onToggleCreateAddress}
              options={addressOptions}
              error={hasAddressError ? 'Address is required!' : ''}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value={selectedCurrentAddressId as any}
              hint={
                selectedCurrentAddress
                  ? `Type: ${getTypeLabels(
                      selectedCurrentAddress?.addressTypes,
                    )}`
                  : ''
              }
              onChange={(event, { selectedOption, value }) => {
                const { address } = selectedOption
                setSelectedCurrentAddressId(value)
                setValue('address', {
                  line1: address.street,
                  city: address.city,
                  state: address.state,
                  postalCode: address.zip,
                  country: address.country,
                  line2: address.address2,
                })
              }}
              extraIcons={[
                {
                  icon: pencil,
                  onClick() {
                    setIsUpdatingAddress(true)
                  },
                  tooltipProps: {
                    content: 'Edit address',
                    placement: 'top',
                  },
                  isHidden: !selectedCurrentAddressId,
                },
              ]}
            />
          )
        },
      },
      {
        label: '',
        name: 'createNewAddress',
        isHidden:
          addressOptions.length === 0
            ? false
            : !isCreatingAddress || isUpdatingAddress,
        render({ setValue, errors }) {
          const hasAddressError =
            Boolean(errors?.address) && !selectedCurrentAddressId
          return (
            <div className='CreateCheckEmployeeDialog__addressForm'>
              {hasAddressError && (
                <div className='error' style={{ margin: '8px 12px 0' }}>
                  Address is required!
                </div>
              )}
              <AddressForm
                isHiddenSearch
                submitText='Create'
                cancelText='Close'
                addressableType={EAddressableType.company}
                addressableId={currentCompany?.id}
                addressData={addressesData}
                onCancel={onToggleCreateAddress}
                afterCreate={(address: IAddress) => {
                  setIsCreatingAddress(false)
                  addAddress(address)
                  setSelectedCurrentAddressId(address.id)
                  setValue('address', {
                    line1: address.street,
                    city: address.city,
                    state: address.state,
                    postalCode: address.zip,
                    country: address.country,
                    line2: address.address2,
                  })
                }}
              />
            </div>
          )
        },
      },
      {
        label: 'Line 1',
        name: 'address.line1',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        isHidden: isHiddenAddressField,
      },
      {
        label: 'Line 2',
        name: 'address.line2',
        size: 6,
        isDisabled: !isUpdatingAddress,
        isHidden: isHiddenAddressField,
      },
      {
        label: 'City',
        name: 'address.city',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        isHidden: isHiddenAddressField,
      },
      {
        label: 'State',
        name: 'address.state',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        isHidden: isHiddenAddressField,
      },
      {
        label: 'Postal Code',
        name: 'address.postalCode',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        isHidden: isHiddenAddressField,
      },
      {
        label: 'Country',
        name: 'address.country',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        isHidden: isHiddenAddressField,
      },
    ],
    [
      companyEmailOptions,
      phoneNumberOptions,
      isCreatingPhone,
      isCreatingEmail,
      isUpdatingAddress,
      addressOptions,
      isCreatingAddress,
      isHiddenAddressField,
      phoneNumbersData,
      addPhoneNumber,
      emailsData,
      addEmail,
      selectedCurrentAddress,
      selectedCurrentAddressId,
      onToggleCreateAddress,
      currentCompany?.id,
      addressesData,
      addAddress,
      getTypeLabels,
    ],
  )

  const getSetupCheckCompany = useCallback(async () => {
    setIsLoading(true)
    try {
      const { email, legalName, payFrequency, startDate, tradeName } =
        await apiClient.check.setupCompany()

      const defaultEmail = emailsData.find(item => item.email === email)

      const payload = {
        ...DEFAULT_FORM_VALUES,
        email: defaultEmail?.id as number,
        legalName,
        payFrequency,
        phone: defaultCompanyPhoneNumber?.id as number,
        startDate,
        tradeName,
        businessType: currentCompany?.corpType as any,
      }

      if (hrAddress) {
        payload.address = {
          city: hrAddress.city,
          country: hrAddress.country,
          line1: hrAddress.street,
          postalCode: hrAddress.zip,
          state: hrAddress.state,
          line2: hrAddress.address2,
        }
        setSelectedCurrentAddressId(hrAddress.id)
        setIsUpdatingAddress(false)
      }

      setCheckCompanyFormValues(payload)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }, [
    defaultCompanyPhoneNumber?.id,
    emailsData,
    hrAddress,
    currentCompany?.corpType,
  ])

  const createHrAddress = useCallback(
    (formValues: ICreateCheckCompanyFormValues) => {
      const { address } = formValues
      const payload = {
        street: address.line1,
        city: address.city,
        state: address.state,
        zip: address.postalCode,
        country: address.country,
        addressTypes: [EAddressTypeStr.hr],
        addressableType: EAddressableType.company,
        addressableId: currentCompany.id,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return apiClient.addresses.create(payload as any)
    },
    [currentCompany?.id],
  )

  const updateAddress = useCallback(
    async (formValues: ICreateCheckCompanyFormValues) => {
      if (selectedCurrentAddress) {
        const { address } = formValues
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: any = {
          street: address.line1,
          city: address.city,
          state: address.state,
          zip: address.postalCode,
          country: address.country,
        }

        return apiClient.addresses.update(selectedCurrentAddress.id, payload)
      }
      return Promise.resolve()
    },
    [selectedCurrentAddress],
  )

  const createOrUpdateAddress = useCallback(
    async (formValues: ICreateCheckCompanyFormValues) => {
      if (selectedCurrentAddress) {
        if (hrAddress && hrAddress.id !== selectedCurrentAddress.id) {
          await apiClient.addresses.delete(hrAddress.id)
          removeAddress(hrAddress.id)
          let addressTypes: string[] = [EAddressTypeStr.hr]
          if (selectedCurrentAddress.addressTypes) {
            addressTypes = convertTypesToString(
              selectedCurrentAddress?.addressTypes,
            )
            addressTypes.push(EAddressTypeStr.hr)
          }
          const response = await apiClient.addresses.update(
            selectedCurrentAddress.id,
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              addressTypes: addressTypes as any,
            },
          )
          updateAddressInQueryData(response.id, response)
        }

        if (
          !areAddressesEqual(
            {
              ...selectedCurrentAddress,
              postalCode: selectedCurrentAddress.zip,
              line1: selectedCurrentAddress.street,
            },
            formValues.address,
          )
        ) {
          await updateAddress(formValues)
        }
      } else {
        await createHrAddress(formValues)
      }
      refetchQueryAddresses()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createHrAddress,
      hrAddress,
      refetchQueryAddresses,
      selectedCurrentAddress,
      updateAddress,
    ],
  )

  const updatePhoneNumberTypes = useCallback(
    async (formValues: ICreateCheckCompanyFormValues) => {
      const phone = phoneNumbersData.find(({ id }) => id === formValues.phone)
      if (
        phone &&
        !(phone.phoneTypes as EPhoneNumberTypes[]).includes(
          EPhoneNumberTypes.hr,
        )
      ) {
        if (hrPhoneNumber && hrPhoneNumber.id !== phone.id) {
          await apiClient.phoneNumbers.delete(hrPhoneNumber.id)
          removePhoneNumber(hrPhoneNumber.id)
        }
        const phoneTypes = phone.phoneTypes.map(type =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          convertPhoneTypeFromEnumToString(type as any),
        )
        phoneTypes.push(EPhoneNumberTypesString.hr)
        const response = await apiClient.phoneNumbers.update(phone.id, {
          phoneTypes,
        })
        updatePhoneNumber(response.id, response)
      }
    },
    [
      phoneNumbersData,
      hrPhoneNumber,
      updatePhoneNumber,
      removePhoneNumber,
      convertPhoneTypeFromEnumToString,
    ],
  )

  const createCheckCompany = useCallback(
    async (formValues: ICreateCheckCompanyFormValues) => {
      const response = await apiClient.check.createCompany({
        company: formValues,
      })
      if (response.id) {
        afterCreateCheckCompany && afterCreateCheckCompany(formValues)
      } else {
        const { error } = response
        setBackendError(error?.inputErrors?.[0]?.message || error?.message)
      }
    },
    [afterCreateCheckCompany],
  )

  const updateCheckCompany = useCallback(
    async (formValues: ICreateCheckCompanyFormValues) => {
      if (formValues.id) {
        const response = await apiClient.check.updateCompany(formValues.id, {
          company: formValues,
        })
        if (response.id) {
          afterUpdateCheckCompany && afterUpdateCheckCompany(formValues)
        } else {
          const { error } = response
          setBackendError(error?.inputErrors?.[0]?.message || error?.message)
        }
      }
    },
    [afterUpdateCheckCompany],
  )

  const onSubmitForm = useCallback(
    async (formValues: ICreateCheckCompanyFormValues) => {
      setIsLoading(true)
      setBackendError('')
      try {
        await Promise.all([
          createOrUpdateAddress(formValues),
          updatePhoneNumberTypes(formValues),
        ])
        const phone = phoneNumbersData.find(({ id }) => id === formValues.phone)
        const email = emailsData.find(
          ({ id }) => id === (formValues.email as any),
        )
        const payload = { ...formValues }
        if (phone) {
          payload.phone = phone.number
        }
        if (email) {
          payload.email = email.email
        }
        if (isUpdating) {
          await updateCheckCompany(payload)
        } else {
          await createCheckCompany(payload)
        }
      } catch (error) {
        console.log('error', error)
        setBackendError(error ? error.toString() : '')
      } finally {
        setIsLoading(false)
      }
    },
    [
      createCheckCompany,
      createOrUpdateAddress,
      isUpdating,
      phoneNumbersData,
      updateCheckCompany,
      updatePhoneNumberTypes,
      emailsData,
    ],
  )

  const setFormValuesData = useCallback(() => {
    if (formValues) {
      const fullAddress = buildFullAddress({
        city: formValues.address.city,
        country: formValues.address.country,
        line1: formValues.address.line1,
        postalCode: formValues.address.postalCode,
        state: formValues.address.state,
      })
      formRef.current?.reset(formValues)
      const findAddress = addressesData.find(address => {
        const fullAddress1 = buildFullAddress({
          city: address.city,
          country: address.country,
          line1: address.street,
          postalCode: address.zip,
          state: address.state,
        })
        return fullAddress1 === fullAddress
      })
      if (findAddress) {
        setSelectedCurrentAddressId(findAddress.id)
      }
    }
  }, [formValues, addressesData])

  useEffect(() => {
    if (isOpen) {
      if (formValues?.id) {
        setFormValuesData()
      } else {
        getSetupCheckCompany()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, defaultCompanyPhoneNumber?.id, hrAddress, formValues])

  useUpdateEffect(() => {
    if (typeof props.isLoading === 'boolean') {
      setIsLoading(props.isLoading)
    }
  }, [props.isLoading])

  return {
    isOpen,
    formFields,
    checkCompanyFormValues,
    schema,
    isLoading,
    isHiddenCloseButton,
    onSubmitForm,
    onClose,
    formRef,
    isUpdating,
    backendError,
  }
}

export default useDialogCreateCheckCompany
