import React, { useCallback, useRef } from 'react'
import { ConcordFormStructure, PlusIcon } from '~/components/shared'

import * as Yup from 'yup'
import { useQueryAllowedHierarchies } from '~/hooks/useQueryData'
import { IAllowedHierarchy } from '~/types/models/IAllowedHierarchy'
import {
  IHierarchyFormData,
  IHierarchyFormValues,
} from '~/types/models/IHierarchy'
import HierarchyFields from './HierarchyFields'
import { produce } from 'immer'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import { apiClient } from '~/api/ApiClient'
import _ from 'lodash'

export interface IHierarchyFormProps {
  item?: {
    sellerId?: number | null
    sellerTerminalId?: number | null
    buyerId?: number | null
    buyerTerminalId?: number | null
  }
  style?: React.CSSProperties
  className?: string
  isUpdating?: boolean
}

function HierarchyForm(props: IHierarchyFormProps, ref: any) {
  const { item, style, className } = props

  const formRef = useRef<any>()

  const { findAllowedHierarchyById } = useQueryAllowedHierarchies()

  const getIsFieldRequired = useCallback(
    (
      allowedHierarchy: IAllowedHierarchy | undefined,
      field: keyof IAllowedHierarchy,
    ) => {
      if (allowedHierarchy) {
        const sideType = allowedHierarchy[field]
        // if (item) {
        //   const value = (item as any)[getField(sideType) as any]
        //   return Boolean(value)
        // }
        return Boolean(sideType)
      }
      return false
    },
    [],
  )

  const schema = Yup.object({
    hierarchiesAttributes: Yup.array().of(
      Yup.object({
        allowedHierarchyId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
        sellerSideType: Yup.string()
          .nullable()
          .test(
            'required',
            'This field is required',
            function (value, { parent }) {
              if (!value) {
                if (parent.allowedHierarchyId) {
                  const allowedHierarchy = findAllowedHierarchyById(
                    parent.allowedHierarchyId,
                  )
                  if (allowedHierarchy) {
                    return !allowedHierarchy?.sellerSideType
                  }
                  return true
                }
                return false
              }

              return true
            },
          ),
        buyerSideType: Yup.string()
          .nullable()
          .test(
            'required',
            'This field is required',
            function (value, { parent }) {
              if (!value) {
                if (parent.allowedHierarchyId) {
                  const allowedHierarchy = findAllowedHierarchyById(
                    parent.allowedHierarchyId,
                  )
                  if (allowedHierarchy) {
                    return !allowedHierarchy?.buyerSideType
                  }
                  return true
                }
                return false
              }

              return true
            },
          ),
        sellerSideId: Yup.number()
          .nullable()
          .test(
            'required',
            'This field is required',
            function (value, { parent }) {
              const allowedHierarchy = findAllowedHierarchyById(
                parent.allowedHierarchyId,
              )
              const isRequired = getIsFieldRequired(
                allowedHierarchy,
                'sellerSideType',
              )
              if (isRequired) {
                return Boolean(value)
              }
              return true
            },
          ),
        buyerSideId: Yup.number()
          .nullable()
          .test(
            'required',
            'This field is required',
            function (value, { parent }) {
              const allowedHierarchy = findAllowedHierarchyById(
                parent.allowedHierarchyId,
              )
              const isRequired = getIsFieldRequired(
                allowedHierarchy,
                'buyerSideType',
              )
              if (isRequired) {
                return Boolean(value)
              }
              return true
            },
          ),
      }),
    ),
  })

  const createOrUpdateHierarchy = useCallback(
    async (formValues: {
      hierarchableType: EHierarchableType
      hierarchableId: number
    }) => {
      const hierarchyPayload =
        formRef.current?.getValues() as IHierarchyFormData

      const apiCalls = hierarchyPayload.hierarchiesAttributes.map(payload => {
        if (payload.id) {
          return apiClient.hierarchies.update(payload.id, {
            hierarchy: _.omit(payload, ['canDelete']),
          })
        }
        return apiClient.hierarchyRows.create({
          hierarchyRow: {
            hierarchableType: formValues.hierarchableType,
            hierarchableId: formValues.hierarchableId,
            hierarchyAttributes: _.omit(payload, ['canDelete']),
          } as any,
        })
      })

      const responses = await Promise.all(apiCalls)
      let backendErrors = [] as { index: number; error: string }[]
      let successResIndex = [] as { id: number; index: number }[]
      responses.forEach(({ errors, id, hierarchyId }, index) => {
        if (errors.length > 0) {
          backendErrors.push({
            index,
            error: errors[0],
          })
        } else {
          successResIndex.push({ index, id: hierarchyId || id })
        }
      })

      const { hierarchiesAttributes: currentHierarchiesAttributes = [] } =
        formRef.current?.getValues() || {}
      formRef.current?.setValue(
        'hierarchiesAttributes',
        produce(currentHierarchiesAttributes, (draft: any) => {
          successResIndex.forEach(({ index, id }) => {
            draft[index].id = id
          })
        }),
      )

      backendErrors.forEach(({ index, error }) => {
        formRef.current?.setError(
          `hierarchiesAttributes.${index}.buyerSideId`,
          {
            message: error,
          },
        )
        formRef.current?.setError(
          `hierarchiesAttributes.${index}.sellerSideId`,
          {
            message: error,
          },
        )
      })

      return {
        backendErrors,
      }
    },
    [],
  )

  const onChangeHierarchyAttributes =
    (index: number) => (hierarchyValues: Partial<IHierarchyFormValues>) => {
      const { hierarchiesAttributes } = formRef.current?.getValues() as any
      formRef.current?.setValue(
        'hierarchiesAttributes',
        produce(hierarchiesAttributes, (draft: any) => {
          draft[index] = {
            ...draft[index],
            ...hierarchyValues,
          }
        }),
      )
      formRef.current?.clearErrors([`hierarchiesAttributes.${index}`])
    }

  const onDeleteRawHierarchy = (index: number | undefined) => {
    const { hierarchiesAttributes } = formRef.current?.getValues() as any
    if (hierarchiesAttributes?.length === 1) {
      formRef.current?.setValue('hierarchiesAttributes', [])
    } else {
      const result = produce(hierarchiesAttributes, (draft: any) => {
        if (index) {
          draft.splice(index, 1)
        }
      })
      formRef.current?.setValue('hierarchiesAttributes', result)
    }
  }

  const onAddHierarchyAttribute = () => {
    const { hierarchiesAttributes } = formRef.current?.getValues() as any

    formRef.current?.setValue('hierarchiesAttributes', [
      ...hierarchiesAttributes,
      {},
    ])
  }

  return (
    <div
      style={{ position: 'relative', border: '1px solid #e5e5e5', padding: 8 }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <h4 style={{ marginBottom: 0 }}>Hierarchy Rows</h4>
        <span
          style={{ marginLeft: 8, verticalAlign: 'middle' }}
          onClick={onAddHierarchyAttribute}
        >
          <PlusIcon className='clickable' />
        </span>
      </div>
      <ConcordFormStructure
        style={{ paddingTop: 0, ...style }}
        isHiddenCancelButton
        isHiddenSubmitButton
        className={className}
        ref={node => {
          formRef.current = node
          if (ref) {
            ref.current = {
              ...node,
              createOrUpdateHierarchy,
            }
          }
        }}
        schema={schema}
        defaultValues={{
          hierarchiesAttributes: [],
        }}
        fields={[
          {
            name: 'hierarchiesAttributes',
            render({ watch, name, errors }) {
              const hierarchiesAttributes: IHierarchyFormValues[] = watch(
                name,
                [],
              )

              const error =
                Object.keys(errors || {}).length > 0 ? errors?.[name] : []

              return (
                <div>
                  {hierarchiesAttributes.map((data, index) => (
                    <HierarchyFields
                      key={index}
                      label='Hierarchy'
                      index={index}
                      canDelete={!data.id}
                      {...data}
                      onChange={onChangeHierarchyAttributes(index)}
                      onDelete={({ index }) => onDeleteRawHierarchy(index)}
                      error={Array.isArray(error) ? error[index] : {}}
                      hierarchyItem={item}
                    />
                  ))}
                </div>
              )
            },
          },
        ]}
      />
    </div>
  )
}

export default React.forwardRef(HierarchyForm)
