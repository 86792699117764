import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBundles from './useModifyBundles'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IBundle, IGetBundlesParams } from '~/types/models/IBundle'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryBundles = (
  params: IGetBundlesParams,
  options?: Partial<UseQueryOptions<IBundle[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'bundles',
      sessionUser?.id,
      buildGetUrl(apiClient.bundles.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.bundles.get(params)
      return response.bundles
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const bundlesData = useMemo(() => data || [], [data])

  const bundleOptions = useMemo(
    () =>
      bundlesData.map(({ num, name, id }) => ({
        label: [num, name].filter(text => text).join(' - '),
        value: id,
      })),
    [bundlesData],
  )

  const { addBundle, updateBundle, removeBundle } = useModifyBundles(params)

  return {
    bundlesData,
    bundleOptions,
    isLoadingBundles: isLoading,
    isBundlesFetched: isFetched,
    addBundle,
    updateBundle,
    removeBundle,
  }
}

export default useQueryBundles
